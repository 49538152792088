import {Component,Input,OnInit} from "@angular/core";
import {ControlContainer,NgForm} from "@angular/forms";
import {Field,FieldContainer} from "@domain/settings/field";
import {PatternService} from "@share/component/pattern/pattern.service";

/**
 * Gère les items de la zone prédéfinie
 */
@Component({
	host: {'data-test-id': 'zone-predefinie-item'},
	selector: "zone-predefinie-item",
	templateUrl: "./zone-predefinie-item.component.html",
	viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class ZonePredefinieItemComponent implements OnInit {
	/* Les paramètres du field */
	@Input() fieldParam: Field;

	/** Objet contenant les champs fields */
	@Input() fieldContainer: FieldContainer;

	/** Numéro du field en cours */
	position: string;

	constructor(private patternService: PatternService) {}

	ngOnInit(): void {
		//Le dernier char est la position du field
		//On récupère cette position
		this.position = this.fieldParam.champ.substring(this.fieldParam.champ.length - 1);
	}

	/**
	 * Récupération de l'expression régulière liée au masque
	 */
	getPatternFor(field: Field): string {
		return this.patternService.getPatternForField(field);
	}
}
