import {Component,OnDestroy,OnInit} from '@angular/core';
import {ActivatedRoute,NavigationStart,Router} from "@angular/router";
import {DematService} from "@services/admin/parametre/demat/demat.service";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {TranslateService} from "@ngx-translate/core";
import {filter} from "rxjs/operators";
import {Subscription} from "rxjs";
import {Location} from "@angular/common";

/**
 * Composant de gestion du détail d'archivage d'un lot.
 *
 * @author François Turin
 * @date 31/08/2023
 */
@Component({
	host: {'data-test-id': 'lot-archivage-detail'},
	templateUrl: './archivage-lot.component.html'
})
export class ArchivageLotComponent implements OnInit,OnDestroy {
	/** Id du lot */
	idLot: number;

	/** Liste des différents onglets disponibles dans la page */
	listeTabItems: Array<PageHeaderItem>;

	/** Référence vers l'enum pour l'utiliser dans le template */
	Onglet = Onglets;

	/** Onglet sélectionné */
	private _selectedItem: PageHeaderItem = null;

	private subscription: Subscription;

	constructor(private router: Router,
				private route: ActivatedRoute,
				private location: Location,
				private translateService: TranslateService,
				private dematService: DematService) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Ecoute des évènements back du browser
		this.subscription = this.router.events
			.pipe(filter(event => {
				return event instanceof NavigationStart
			}))
			.subscribe((event: NavigationStart) => {
				if (event.navigationTrigger === "popstate") {
					//Si c'est un back du browser, on change le flag pour que la liste des lots puisse s'afficher
					this.dematService.isInDetail = false;
				}
			});

		//Liste des onglets
		this.listeTabItems = [
			{
				code: Onglets.ARCHIVAGE,
				libelle: this.translateService.instant('admin.parametres.demat.archivage.detail.onglets.archivage'),
			}
		];

		//Sélection de l'onglet
		this.selectedItem = this.listeTabItems[0];

		//Récupération de l'Id du lot dans l'URL
		this.idLot = Number.parseInt(this.route.snapshot.url.toString());

		//Si on affiche ce composant, c'est qu'on est dans le détail d'archivage d'un lot
		this.dematService.isInDetail = true;
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		this.subscription.unsubscribe();

		//On quitte la page de détail
		this.dematService.isInDetail = false;
	}

	get selectedItem(): PageHeaderItem {
		return this._selectedItem;
	}

	set selectedItem(item: PageHeaderItem) {
		//Edition de la variable privée
		this._selectedItem = item;
	}

	/**
	 * Changement d'onglet
	 *
	 * @param selectedItem Onglet sélectionné
	 */
	onSelectedTabChange(selectedItem: PageHeaderItem) {
		//Mise à jour de l'onglet sélectionné
		this.selectedItem = selectedItem;
	}

	/**
	 * Retourne sur la liste des lots.
	 */
	goBack() {
		this.dematService.isInDetail = false;
		this.location.back();
	}
}

/**
 * Enum pour les noms des différents onglets de la page de détail d'archivage d'un lot.
 */
export enum Onglets {
	ARCHIVAGE = "Archivage",
	ERREURS = "Erreurs"
}
