import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {TypeFacture} from "@domain/facture/type-facture";
import {NiveauAlerte} from "@domain/common/alerte/alerte";
import {UserDto} from "@domain/user/user-dto";
import {Od} from "@domain/od/od";
import {TypeCharge} from "@domain/prestation/type-charge";
import {FactureTypeAnomalie} from "@domain/facture/facture-type-anomalie";
import {ReleveFactureService} from "@components/facture/releve-facture.service";
import {finalize} from "rxjs/operators";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {FactureErreurImport} from "@domain/facture/facture-erreur-import";
import {Fournisseur} from "@domain/facture/fournisseur";

/**
 * Composant de l'affichage du détail d'une anomalie (rejet lors de l'import)
 *
 * @author Laurent Convert
 * @date 06/07/2023
 */
@Component({
	host: {'data-test-id': 'facture-anomalie-detail'},
	selector: 'facture-anomalie-detail',
	templateUrl: './facture-anomalie-detail.component.html',
	styles: [':host ::ng-deep #ci-collab autocomplete { margin-right: 24px; }']
})
export class FactureAnomalieDetailComponent implements OnInit {
	readonly CODE_ERROR_REINTEGRATION = 901;

	/* Déclaration pour accès dans le template */
	TypeFacture = TypeFacture;
	NiveauAlerte = NiveauAlerte;

	/** Indicateur de modification possible */
	canModifier: boolean;

	/** Indicateur de correction possible de la mission ou du collaborateur */
	isCorrectionMissionOuCollabPossible: boolean;
	/** Indicateur de correction possible du type de charge */
	isCorrectionTypeChargePossible: boolean;

	/** Indicateur de chargement de l'objet en cours */
	isLoading: boolean;
	/** Indicateur d'enregistrement en cours */
	isSaving: boolean;
	/** Indicateur de (dé)masquage en cours */
	isHiding: boolean;

	/** Indicateur de traitement back en cours */
	get isProcessing(): boolean {
		return this.isHiding || this.isSaving;
	}

	/** Indicateur de la nécessité de recharger la liste dans le parent à la fermeture de la popin */
	reloadListeParent: boolean = false;

	/** Ligne de facture en anomalie (rejet) */
	factureErreur: FactureErreurImport;

	/** Collaborateur associé */
	collaborateur: UserDto;

	/** Od associé */
	od: Od;

	/** Type charge associé */
	typeCharge: TypeCharge;

	/** Fournisseur associé */
	fournisseur: Fournisseur;

	/**
	 * Constructeur
	 *
	 * @param data Données transmises à la popup
	 * @param releveFactureService Service de gestion des relevés de facture
	 * @param toastrService Service de notification
	 * @param translateService Service de traduction
	 * @param matDialogRef Référence à la popup elle-même
	 */
	constructor(@Inject(MAT_DIALOG_DATA) public data: {idErreurImport: number},
				private releveFactureService: ReleveFactureService,
				private toastrService: ToastrService,
				private translateService: TranslateService,
				private matDialogRef: MatDialogRef<FactureAnomalieDetailComponent,boolean>) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		this.collaborateur = null;
		this.od = null;
		this.typeCharge = null;
		this.fournisseur = null;

		//Début du chargement
		this.isLoading = true;

		//Chargement
		this.releveFactureService.loadErreur(this.data.idErreurImport)
			.pipe(finalize(() => this.isLoading = false))
			.subscribe(result => {
				//Initialisation
				this.init(result);
			});
	}

	/**
	 * Initialisation
	 *
	 * @param data Données provenant du back contenent l'erreur, l'od et le fournisseur
	 */
	private init(data: {factureErreur: FactureErreurImport,fournisseur: Fournisseur,od: Od,collaborateur: UserDto}) {
		this.factureErreur = new FactureErreurImport(data.factureErreur);
		this.od = data.od ? new Od(data.od) : null;
		this.collaborateur = data.collaborateur ? new UserDto(data.collaborateur) : null;
		this.fournisseur = data.fournisseur ? new Fournisseur(data.fournisseur) : null;

		//Mise à jour de l'indicateur de modification possible
		this.canModifier = this.factureErreur?.listeAlertes?.niveau === NiveauAlerte.WARNING;

		//Mise à jour de l'indicateur de correction de la mission ou du collaborateur
		this.isCorrectionMissionOuCollabPossible = [
				 FactureTypeAnomalie.ODINEX_AND_MATOK
				,FactureTypeAnomalie.MATINEX_AND_ODOK
				,FactureTypeAnomalie.MATINEX_AND_OD_NOT_USED
				,FactureTypeAnomalie.COLLABNOTDEFINED
				,FactureTypeAnomalie.ODMATINEX
				,FactureTypeAnomalie.ODINEX_AND_MAT_NOT_USED
				,FactureTypeAnomalie.ERR_BAD_OD_4_FACTURE
				,FactureTypeAnomalie.COHODMAT
			].map(err => err.valueOf()).includes(this.factureErreur.numError.valueOf());

		//Mise à jour de l'indicateur de correction du type charge
		this.isCorrectionTypeChargePossible = [FactureTypeAnomalie.NOCHARGE.valueOf()].includes(this.factureErreur.numError.valueOf());
	}

	/**
	 * Handler déclenché lors de la sélection d'un collaborateur
	 */
	userChanged(): void {
		//Vérification de la sélection
		if (this.collaborateur) {
			//Récupération du matricule
			this.factureErreur.refCollab = this.collaborateur.matricule;

			//Reset :) de la mission si le collaborateur ne correspond plus
			if (this.od?.user?.idUser !== this.collaborateur.idUser) {
				this.od = null;
			}
		} else {
			//Pas de sélection : reset :)
			this.factureErreur.refCollab = null;
		}
	}

	/**
	 * Handler déclenché lors de la sélection de la mission
	 */
	odChanged(): void {
		//Vérification de la sélection
		if (this.od) {
			//Mise à jour de l'anomalie depuis la mission sélectionnée
			this.factureErreur.idOd = this.od.idOd;
			this.factureErreur.idTypeEntite = this.od.typeEntite?.idTypeEntite;
			this.factureErreur.refCollab = this.od.user?.matricule;

			//Mise à jour du collaborateur sélectionné depuis la mission sélectionnée
			this.collaborateur = this.od.user;
		} else {
			//Pas de sélection : reset :)
			this.factureErreur.idOd = null;
			this.factureErreur.idTypeEntite = null;
		}
	}

	/**
	 * Handler déclenché lors de la sélection d'un type charge
	 */
	typeChargeChanged(): void {
		//Mise à jour de l'anomalie
		this.factureErreur.typeCharge = this.typeCharge?.code;
	}

	/**
	 * Enregistrement pour correction et ré-intégration
	 */
	save(): void {
		//Début de l'enregistrement
		this.isSaving = true;

		//Enregistrement
		this.releveFactureService.correctError(this.factureErreur)
			.pipe(finalize(() => this.isSaving = false))
			.subscribe(result => {
				//Vérification du résultat
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Message de succès
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Enregistrement OK : fermeture de la popin
					this.matDialogRef.close(true);
				} else if (result?.codeErreur === this.CODE_ERROR_REINTEGRATION) {
					//Message pour avertir que la facture n'a pas pu être intégrée suite à la correction
					this.toastrService.warning(this.translateService.instant('facture.releve.anomalie.correction.erreur.errorReintegration'));

					//L'anomalie a changé, il faudra recharger la liste lors de la fermeture de la popin
					this.reloadListeParent = true;

					//Mise à jour de l'anomalie courante
					this.factureErreur = new FactureErreurImport(result.data.factureErreur);

					//Ré-initialisation des différents indicateurs
					this.init(result.data);
				} else {
					//Message d'erreur
					TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);
				}
			}, () => {
				//Message d'erreur
				TypeCodeErreur.showError(TypeCodeErreur.ERROR_SAVE,this.translateService,this.toastrService);
			});
	}

	/**
	 * Masquage / démasquage de l'anomalie
	 *
	 * @param masquer True : anomalie masquée. False : anomalie démasquée
	 */
	masquer(masquer: boolean): void {
		//Début de l'enregistrement
		this.isHiding = true;

		//Enregistrement
		this.releveFactureService.hideError(masquer,[this.factureErreur.idErreurImport])
			.pipe(finalize(() => this.isHiding = false))
			.subscribe(res => {
				//Vérification du résultat
				if (res?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Affichage du message de succès
					this.toastrService.success(this.translateService.instant('global.success.generic'));

					//Enregistrement OK : fermeture de la popin
					this.matDialogRef.close(true);
				} else {
					//Message d'erreur
					TypeCodeErreur.showError(res?.codeErreur,this.translateService,this.toastrService);
				}
			}, () => {
				//Message d'erreur
				TypeCodeErreur.showError(TypeCodeErreur.ERROR_SAVE,this.translateService,this.toastrService);
			});
	}

}