<mat-form-field class="d-flex">
    <mat-label *ngIf="!!label" [ngClass]="{ 'required': required }">{{label | translate}}</mat-label>
    <mat-date-range-input
            [rangePicker]="$any(picker)"
            [comparisonStart]="rangeDeb"
            [comparisonEnd]="rangeEnd"
            [min]="minDate"
            [max]="maxDate"
            [disabled]="disabled">
        <input matStartDate [(ngModel)]="maDate" (dateChange)="dateChange.emit($event.value);" [required]="required" [disabled]="disabled">
        <input matEndDate [disabled]="disabled">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker [startAt]="dateStart" [disabled]="disabled"></mat-date-range-picker>
</mat-form-field>
