import {Component,Input,OnChanges,OnInit,SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TypeZoneUtilisateur,ZoneUtilisateur} from '../../domain/zone-utilisateur/zone-utilisateur';
import {ZoneUtilisateurSaisie} from '../../domain/zone-utilisateur/zone-utilisateur-saisie';
import {ZoneUtilisateurModel} from './zone-utilisateur-model';
import {ZUReferentiel} from '../../domain/zone-utilisateur/zuReferentiel';

@Component({
	selector: 'zone-utilisateur',
	templateUrl: './zone-utilisateur.component.html',
	exportAs: "zu"
})
/**
 * Composant affichant les zones utilisateurs sur les objets
 */
export class ZoneUtilisateurComponent implements OnInit, OnChanges  {

	/** Liste des zones utilisateurs disponibles */
	@Input() listeZus?: ZoneUtilisateur[];

	/** Liste des zones utilisateurs saisies */
	@Input() listeZuSaisies?: ZoneUtilisateurSaisie[];

	/** Composant désactivé ou non */
	@Input() readonly: boolean = true;

	/** Le modèle peut changer de valeur pour initialiser une autre liste en conservant le modèle */
	@Input() canListeZuChange = false;

	/** Le composant est initialisé dans un premier temps sans ZU, qui sont chargées plus tard */
	@Input() canListeZuLoadAsync = false;
	isLoaded: boolean = false;

	/** Modèle utilisé pour l'affichage du composant, hérite de zone utilisateur */
	listeZuModels: ZoneUtilisateurModel[] = [];

	/** Type de champ des zones utilisateurs */
	public TypeZoneUtilisateur = TypeZoneUtilisateur;

	/**
	 * Constructeur
	 */
	constructor(private dialog: MatDialog) { }
	
	ngOnInit(): void {
		this.initZoneUtilisateur();
	}

	ngOnChanges(changes: SimpleChanges): void {	
		//On réactualise les zones utilisateurs si les données sont modifiées
		if (this.canListeZuChange) {
			this.initZoneUtilisateur();
		//On charge les zones utilisateurs une seule fois quand on a des zones utilisateurs
		} else if (this.canListeZuLoadAsync && this.listeZus != null && !this.isLoaded) {
			this.initZoneUtilisateur();
			this.isLoaded = true;
		}
	}

	/**
	 * Initialisation des zones utilisateurs
	 */
	private initZoneUtilisateur() {		
		//Pas de ZU, on n'initialise pas le model
		if (this.listeZus === undefined || this.listeZus === null) { return; }		

		//Initialise listeZuModels à partir de la liste listeZus_
		this.listeZuModels = this.listeZus.map((zu: ZoneUtilisateur) => new ZoneUtilisateurModel(zu));

		//Pas de ZUsaisies, on n'initialise pas les valeurs saisies
		if (this.listeZuSaisies === undefined || this.listeZuSaisies === null) { return; }

		//Itération sur les zones utilisateurs
		this.listeZuModels.forEach((zuModel: ZoneUtilisateurModel) => {
			//Regroupe les saisies réalisées par zone utilisateur
			const listeZuSaisiesByZu = this.listeZuSaisies.filter((zuSaisies: ZoneUtilisateurSaisie) => zuModel.idZU == zuSaisies.zu.idZU);

			zuModel.listeZUSelects = [];

			//Intègre les éléments des saisies directements dans le modèle zone utilisateur
			listeZuSaisiesByZu.forEach(zuSaisie => {
				if (zuModel.typeChamp === TypeZoneUtilisateur.CHECKBOX) {
					//champ case à cocher
					zuModel.saisieCheck = zuSaisie.booleen ?? zuSaisie.valeur;
				} else if (zuModel.typeChamp === TypeZoneUtilisateur.DATE) {
					//champ date
					zuModel.saisieDate = zuSaisie.date ?? zuSaisie.valeur;
				} else if (zuModel.typeChamp === TypeZoneUtilisateur.HEURE) {
					//champ heure
					zuModel.saisieHeure = zuSaisie.heure ?? zuSaisie.valeur;
				} else if (zuModel.typeChamp === TypeZoneUtilisateur.MONTANT) {
					//champ montant
					zuModel.saisieMontant = zuSaisie.montant ?? zuSaisie.valeur;
				} else if (zuModel.typeChamp === TypeZoneUtilisateur.TEXTE) {
					//champ texte
					zuModel.saisieTexte = zuSaisie.texte ?? zuSaisie.valeur;					
				} else if ([TypeZoneUtilisateur.LISTE_UNIQUE, TypeZoneUtilisateur.LISTE_UNIQUE_AUTRE,
							TypeZoneUtilisateur.LISTE_MULTIPLE, TypeZoneUtilisateur.LISTE_MULTIPLE_AUTRE].includes(zuModel.typeChamp)) {
					//champ select et multi-select
					if (zuSaisie.zuReferentiel) {
						//saisie pour une valeur référentiel
						if ([TypeZoneUtilisateur.LISTE_MULTIPLE, TypeZoneUtilisateur.LISTE_MULTIPLE_AUTRE].includes(zuModel.typeChamp)) {
							zuModel.listeZUSelects.push(zuSaisie.zuReferentiel);
						} else if ([TypeZoneUtilisateur.LISTE_UNIQUE, TypeZoneUtilisateur.LISTE_UNIQUE_AUTRE].includes(zuModel.typeChamp)) {
							zuModel.zuSelect = zuSaisie.zuReferentiel;
						}
					} else {
						//saisie pour une valeur autre
						//on crée cette valeur dans la liste
						zuModel.autreSelect = {
							idZURef: -1, // la valeur n'est pas utilisée par le custom input
							libelle: zuSaisie.texte, // valeur affiché dans la liste
							isActif: true, // la valeur n'est pas utilisée par le custom input
							selected: true, // la valeur n'est pas utilisée par le custom input
							idZuSaisie: -1 // la valeur n'est pas utilisée par le custom input
						};

						//On ajoute la ou les valeurs sélectionnés pour la liste
						if ([TypeZoneUtilisateur.LISTE_MULTIPLE, TypeZoneUtilisateur.LISTE_MULTIPLE_AUTRE].includes(zuModel.typeChamp)) {
							zuModel.listeZUSelects.push(zuModel.autreSelect);
						} else if ([TypeZoneUtilisateur.LISTE_UNIQUE, TypeZoneUtilisateur.LISTE_UNIQUE_AUTRE].includes(zuModel.typeChamp)) {
							zuModel.zuSelect = zuModel.autreSelect;
						}
					}
				}
			});
		});
	}

	/**
	 * Récupération de la liste des zones utilisateurs avec les saisies de l'utilisateur
	 * @returns la liste des zones utilisateurs
	 */
	public getListeZoneUtilisateursBeforeSave() {
		if (this.listeZuModels === null || this.listeZuModels === undefined){
			return [];
		}

		//Pour les customs input on doit retravailler les données
		this.listeZuModels.forEach((zuModel: ZoneUtilisateurModel) => {
			if ([TypeZoneUtilisateur.LISTE_MULTIPLE, TypeZoneUtilisateur.LISTE_MULTIPLE_AUTRE].includes(zuModel.typeChamp)) {
				//Reset de la saisie "autre" qui sera récupéré ensuite le cas échéant
				zuModel.saisieAutre = false;
				zuModel.saisieTexte = null;
				zuModel.idSaisieRef = null;
				
				//multi-select, on a plusieurs valeurs à ajouter
				zuModel.listeZUSelects.forEach(zuSelect => {
					const referentiel = zuModel.listeReferentiel.find((referentiel: ZUReferentiel) => referentiel.idZURef === zuSelect.idZURef);
					if (referentiel === undefined) {
						//saisie autre assignée directement à l'objet zone utilisateur 
						zuModel.saisieAutre = true;
						zuModel.saisieTexte = zuSelect.libelle;
					} else {
						//saisie reférentiel indiquée comme sélectionnée
						referentiel.selected = true;
					}
				});
			} else if ([TypeZoneUtilisateur.LISTE_UNIQUE, TypeZoneUtilisateur.LISTE_UNIQUE_AUTRE].includes(zuModel.typeChamp)) {
				//Reset de la saisie "autre" qui sera récupéré ensuite le cas échéant
				zuModel.saisieAutre = false;
				zuModel.saisieTexte = null;
				zuModel.idSaisieRef = null;
				
				if (zuModel.zuSelect !== null && zuModel.zuSelect !== undefined) {
					//select on a une seule valeur à ajouter
					const referentiel = zuModel.listeReferentiel.find((referentiel: ZUReferentiel) => referentiel.idZURef === zuModel.zuSelect.idZURef);
					if (referentiel === undefined) {
						//saisie autre assignée directement à l'objet zone utilisateur
						zuModel.saisieAutre = true;
						zuModel.saisieTexte = zuModel.zuSelect.libelle;
					} else {
						//ajoute l'id de la saisie référentiel
						zuModel.idSaisieRef = referentiel.idZURef;
					}
				}
			}
		});

		return this.listeZuModels.map(zu => zu.toZoneUtilisateur());
	}

	/**
	 * Vérification du formulaire de la zone utilisateur
	 * @returns true si le formulaire est valide
	 */
	public isValid(): boolean {
		for (const zuModel of this.listeZuModels) {
			if(zuModel.obligatoire) {
				if (zuModel.typeChamp === TypeZoneUtilisateur.CHECKBOX) {
					//Champ case à cocher
					if (!zuModel.saisieCheck) {
						return false;
					}
				} else if (zuModel.typeChamp === TypeZoneUtilisateur.DATE) {
					//Champ date
					if (!zuModel.saisieDate) {
						return false;
					}
				} else if (zuModel.typeChamp === TypeZoneUtilisateur.HEURE) {
					//Champ heure
					if (!zuModel.saisieHeure) {
						return false;
					}
				} else if (zuModel.typeChamp === TypeZoneUtilisateur.MONTANT) {
					//Champ montant
					if (!zuModel.saisieMontant || !Number(zuModel.saisieMontant) || zuModel.saisieMontant === 0) {
						return false;
					}
				} else if (zuModel.typeChamp === TypeZoneUtilisateur.TEXTE) {
					//Champ texte
					if (!zuModel.saisieTexte) {
						return false;
					}
				} else if ([TypeZoneUtilisateur.LISTE_MULTIPLE, TypeZoneUtilisateur.LISTE_MULTIPLE_AUTRE].includes(zuModel.typeChamp)) {
					//Champs multi-select et multi-select autre
					if (!zuModel.listeZUSelects || zuModel.listeZUSelects.length === 0) {
						return false;
					}
				} else if ([TypeZoneUtilisateur.LISTE_UNIQUE, TypeZoneUtilisateur.LISTE_UNIQUE_AUTRE].includes(zuModel.typeChamp)) {
					//Liste et liste autre
					if (!zuModel.zuSelect) {
						return false;
					}
				}
			}
		}	
		//tout est valide on renvoie true
		return true;
	}

	/**
	 * Renvoie la taille de la colonne selon la présence d'un tooltip
	 * @param zu ZoneUtilisateurModel
	 * @returns la taille de la colonne
	 */
	public getColSize(zu: ZoneUtilisateurModel): number {
		return zu.description ? 7 : 8;
	}
}