<mat-card>
    <mat-card-title>
        <span [translate]="'admin.ongletOutils.cadreMouvementWf.titre'"></span>
    </mat-card-title>
    <mat-card-content>
        <div class="listview">
            <div class="listview-body">
                <div class="listview-item clickable" (click)="showPopupMouvementWf()">
                    <div class="list-view-item-hover">
                        <div class="lvi-content">
                            <div class="avatar"><i class="material-symbols-rounded">step</i></div>
                            <div class="lvi-body"><div class="title"><a [translate]="'admin.ongletOutils.cadreMouvementWf.modifierStatut'"></a></div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>