import {Component,EventEmitter,Input,OnInit,Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';

import {AnalytiqueService} from '@components/analytique/analytique.service';
import {Analytique,AnalytiqueSaisie} from './analytique';
import {SettingsGlobalState} from '../../domain/settings/settings';
import {AppState} from '../../domain/appstate';
import {User} from '../../domain/user/user';
import {TypeEntite} from "../../domain/typeentite/typeEntite";
import {AnalytiquePopinComponent} from './Popin/analytique-popin.component';

@Component({
    selector: 'analytique',
    templateUrl: './analytique.component.html',
    exportAs: 'analytique'
})
export class AnalytiqueComponent implements OnInit {
    /** Liste des répartitions */
    @Input() listeAnalytiques: Array<Analytique>;

    /** Id du MIB (Mois d'Imputation Budgétaire) */
    @Input() idMIB?: number;

    /** Précision pour la saisie de la ventilation */
    @Input() precision?: number;

    /** Type entité */
    @Input() typeEntite: TypeEntite;

    /** Paramétrage */
    @Input() settings: SettingsGlobalState;
    
    /** Indicateur de modification */
    @Input() canModifier: boolean;

    /** Indicateur du droit de saisie */
    @Input() canSaisie: boolean;

    /** Nombre de préférence anlytique */
    @Input() preferenceAnalytiqueCount: number;

    /** Id du propriétaire de l'objet */
    @Input() idCollab: number;

    /** Indique si l'analytique est utilisée dans le menu admin */
    @Input() isAdmin = false;

    /** Indique si on doit afficher les onglets */
    @Input() isShowTab = true;

    /** Evènement pour supprimer directement l'analytique */
    @Output() deleteAnalytique: EventEmitter<number> = new EventEmitter<number>();

    /** Evènement déclenché lors de la validation/Suppression d'une répartition */
    @Output() onModification: EventEmitter<void> = new EventEmitter<void>();

    /** Utilisateur */
    private user: User;

    /**
     * Constructeur
     */
    constructor(
        private store: Store<AppState>,
        private analytiqueService: AnalytiqueService,
        private matDialog: MatDialog
    ) { }

    /**
     * Initialisation du composant 
     */
    ngOnInit() {
        //Sélection de l'utilisateur
        this.store.select(state => state.session?.user).subscribe(user => this.user = user);
    }

    /**
     * Ajout/modification d'une ligne de répartition analytique
     *
     * @param analytique Objet analytique
     * @param idxAnalytique Index de l'objet analytique dans la collection
     */
    showLigne(analytique?: Analytique,idxAnalytique?: number) {
        //On affiche la saisie si on peut modifier l'analytique
        const isShowSaisie = this.canModifier && this.canSaisie;
        //On affiche la sélection si on ne peut pas modifier et qu'on a au moins 2 préférences ou qu'on peut modifier avec au moins une préférence
        const isShowSelect = this.canModifier
                        && ((this.canSaisie && this.preferenceAnalytiqueCount >= 1) || this.preferenceAnalytiqueCount >= 2);
                                

        //Affichage de la popup
        this.matDialog.open(AnalytiquePopinComponent,{
            data: {
                idMIB: this.idMIB ?? 0,
                listeAnalytique: this.listeAnalytiques,
                idCollab: this.idCollab,
                analytique: analytique ? Object.assign({},analytique) : {
                    pourcentage: Number(100 - (this.listeAnalytiques?.reduce((total,a) => total + a.pourcentage,0.0) || 0.0)).toFixed(2),
                    orga: {
                        idOrga: this.user.service.idOrga,
                        numService: this.user.service.numServ,
                        libelle: this.user.service.libelle,
                        code: this.user.service.code
                    },
                },
                idxAnalytique,
                typeEntite: this.typeEntite,
                settings: this.settings,
                precision: this.precision,
                isShowSaisie: isShowSaisie,
                isShowSelect: isShowSelect,
                isShowVentilation: !this.isAdmin,
                isShowTab: this.isShowTab
            }
        }).afterClosed().subscribe({
            next: (updatedAnalytique: AnalytiqueSaisie) => {
                //Vérification de la ligne saisie
                if (updatedAnalytique) {
                    //L'analytique a été modifié/créé
                    updatedAnalytique.isUpdated = true;

                    //Vérification de la suppression
                    if (!updatedAnalytique.isRemoved) {
                        //Vérification du mode
                        if (updatedAnalytique.isSelection) {
                            //On vide le tableau en gardant la référence de celui-ci
                            this.listeAnalytiques.length = 0;
                            //On intègre l'analytique sélectionné
                            this.listeAnalytiques.push(updatedAnalytique);
                        } else if (analytique) {
                            //Mise à jour
                            this.listeAnalytiques[idxAnalytique] = updatedAnalytique;
                        } else {
                            //Ajout de la ligne
                            this.listeAnalytiques.push(updatedAnalytique);

                            //Vérification du nombre de lignes
                            if (this.listeAnalytiques.length == 2) {
                                //Mise à jour de la première ligne
                                this.listeAnalytiques[0].pourcentage = Number((100.0 - updatedAnalytique.pourcentage).toFixed(2));
                            }
                        }
                    } else {
                        //Si on a à une action en cas de suppression, on l'émet
                        this.deleteAnalytique.emit(this.listeAnalytiques[idxAnalytique].id);

                        //Suppression de la ligne
                        this.listeAnalytiques.splice(idxAnalytique,1);
                    }

                    //Emission de l'évènement de validation de la popin
                    this.onModification.emit();
                }
            }
        });
    }

    /**
     * Vérification de la validité de la répartition analytique
     */
    public isValid() {
        //Si admin
        if (this.isAdmin) {
            //C'est bon pas besoin de contrôler la validation de la répartition
            return true;
        }

        //Vérification de la validité de la répartition analytique
        return this.analytiqueService.isValid(this.listeAnalytiques,this.typeEntite,this.settings);
    }

    /**
     * Récupération d'un paramétrage analytique
     */
    getFieldParam(code: string): any {
        //Récupération du champ
        return this.settings?.listeFieldParams?.find(p => p.champ == code);
    }
}
