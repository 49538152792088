import {Component, Input} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {
	PopupMouvementWfComponent
} from "@share/component/outils/mouvement-wf/popup-mouvement-wf/popup-mouvement-wf.component";
import {TypePortee} from "@domain/workflow/workflow";

/**
 * Composant du cadre mouvement workflow de l'onglet outils - admin
 *
 * @author Angeline Ha
 * @date 30/08/2023
 */
@Component({
	selector: 'mouvement-wf',
	templateUrl: './mouvement-wf.component.html'
})
export class MouvementWfComponent {

	/** Type de l'objet workflow */
	@Input() typePortee: TypePortee;

	/**
	 * Constructeur
	 *
	 * @param matDialog Boîte de dialogue pour lancer un mouvement workflow
	 */
	constructor(private matDialog: MatDialog) {
	}

	/**
	 * Affichage de la popup pour effectuer un mouvement workflow
	 */
	showPopupMouvementWf() {
		this.matDialog.open(PopupMouvementWfComponent, {
			data: {
				typePortee: this.typePortee
			}
		})
	}
}