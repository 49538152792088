<mat-vertical-stepper #stepper (animationDone)="addEtape();">
    <mat-step *ngFor="let etape of tournee?.listeEtapes; let index = index" [hasError]="stepForm.invalid" [errorMessage]="getErrorMessage(stepForm)">
            <form autocomplete="off" #stepForm="ngForm">
                <ng-template matStepLabel>
                    <strong>{{ getStepperTitle(etape) }}</strong><br>
                    <span>{{ getStepperTitleBody(etape) }}</span>
                </ng-template>

                <stepper-tournee-item #stepperCustom
                        [etape]="etape"
                        [beforeEtapeheureFin]="getHeureFinEtapePrecedente(index)"
                        [unite]="tournee.unite"
                        [isFirstEtape]="index==0"
                        (localisationArriveChange)="onLocalisationArriveeChange($event,index)"
                        (heureDebutChange)="onHeureDebutChange($event,index)"
                        (heureFinChange)="onHeureFinChange($event,index)"
                        [etapesPersonnelles]="etapesPersonnelles"
                        [gestionTempsDetaillee]="gestionTempsDetaillee"
                        [quantiteModifiable]="quantiteModifiable"
                        [index]="index"
                ></stepper-tournee-item>

                <div mat-dialog-actions align="end">
                    <button mat-stroked-button color="warn" (click)="removeEtape(index)" *ngIf="index > 0">
                        <span translate>global.actions.supprimer</span>
                    </button>
                </div>
            </form>

     </mat-step>

    <mat-step #stepAjout state="ajout">
        <ng-template matStepLabel>
            <div><a translate>ndf.frais.ajouterTournee.ajouterEtape</a></div>
        </ng-template>
    </mat-step>

    <!-- Surcharge des icônes -->
    <ng-template matStepperIcon="ajout">
        <mat-icon>add</mat-icon>
    </ng-template>
</mat-vertical-stepper>

<!-- Champs heure début et fin-->
<form autocomplete="off" #heureForm="ngForm" *ngIf="hasShowHeureGlobale()">
    <div class="row" *ngIf="!isGestionHeureGlobalReadOnly()">
        <custom-input
                ngDefaultControl
                customType="heure"
                [libelle]="'ndf.frais.ajouterTournee.debutTournee' | translate"
                optionDisplay="libelle"
                [(ngModel)]="tournee.heureDebut"
                lCol="3" rCol="2"
                name="heureTourneeDebut"
                [required]="true"
        ></custom-input>
        <custom-input
                ngDefaultControl
                customType="heure"
                [libelle]="'ndf.frais.ajouterTournee.finTournee' | translate"
                optionDisplay="libelle"
                [(ngModel)]="tournee.heureFin"
                lCol="3" rCol="2"
                name="heureTourneeFin"
                [required]="true"
        ></custom-input>
    </div>
    <div class="row" *ngIf="isGestionHeureGlobalReadOnly()">
        <label class="col-md-push-1 col-md-3">
            <span translate>ndf.frais.ajouterTournee.debutTournee</span>
        </label>
        <div class='col-md-2'>
            {{ tournee.heureDebut !== null && tournee.heureDebut !== '' ? tournee.heureDebut : '-' }}
        </div>
        <label class="col-md-3">
            <span translate>ndf.frais.ajouterTournee.finTournee</span>
        </label>
        <div class='col-md-2'>
            {{ tournee.heureFin !== null && tournee.heureFin !== '' ? tournee.heureFin : '-' }}
        </div>
    </div>
</form>


