import {Component,Inject,OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {ProfilService} from "../../profil.service";
import {ToastrService} from "ngx-toastr";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Adresse,AdresseListItem} from "@domain/profil/adresse";
import {SettingsGlobalState} from "@domain/settings/settings";
import {filter, finalize, first} from "rxjs/operators";
import {TypeCodeErreur} from "@domain/common/http/result";
import {TypeEntiteParamPRF} from '@domain/typeentite/typeEntiteParam';
import {TypePortee} from '@domain/workflow/workflow';
import {TypeLieu} from '@domain/lieu/typeLieu';
import {LieuService} from "../../../lieu/lieu.service";
import {LieuProfil} from "@domain/lieu/lieuProfil";

/**
 * Composant gérant l'ajout d'adresse
 */
@Component({
    host: {'data-test-id': 'profil-donnees-add-adresse'},
    templateUrl: './profil-donnees-add-adresse.component.html'
})
export class ProfilDonneesAddAdresseComponent implements OnInit {
    /** Déclaration pour accès direct depuis le template */
    TypePortee = TypePortee;

    /** Indicateur de sauvegarde */
    isSaving: boolean = false;

    /** Indicateur de suppression */
    isDeleting: boolean = false;

    /** Liste des types d'adresse pour le select */
    listeTypeAdresse: Array<any> = [
        {type: "PERSONNELLE", libelle: this.translateService.instant("profilUser.adresses.PERSONNELLE")},
        {type: "PROFESSIONNELLE", libelle: this.translateService.instant("profilUser.adresses.PROFESSIONNELLE")}
    ];

    /** Localisation de l'adresse */
    localisation: LieuProfil;

    /** Défini le type d'entité profil */
    typeEntiteParam: TypeEntiteParamPRF = {
        idTypeEntite: 0, //Cet élément n'est pas utilisé pour le profil
        typeTypeEntite: TypePortee.PRF
    }

    /**
     * Constructeur
     *
     * @param translateService service de traduction
     * @param confirmService service de confirmation
     * @param profilService service pour la gestion du profil
     * @param toastrService service de toast d'alerte
     * @param lieuService service de lieu
     * @param matDialogRef service de référence de la popup
     * @param data données transmises à la popup
     */
    constructor(
        private translateService: TranslateService,
        private confirmService: ConfirmService,
        private profilService: ProfilService,
        private toastrService: ToastrService,
        private lieuService: LieuService,
        private matDialogRef: MatDialogRef<ProfilDonneesAddAdresseComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            adresse: AdresseListItem,
            listeAdresse: Array<AdresseListItem>,
            settings: SettingsGlobalState,
            idPortee: TypePortee,
            typeLieu: TypeLieu,
            idUser?: number
        }
    ) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        if (this.data.adresse?.idLocalisation) {
            this.localisation = {
                ...this.data.adresse,
                idLocalisation: this.data.adresse.idLocalisation,
                adresse: this.lieuService.formatAdressetoString(this.data.adresse)
            };
        }
        if (!this.data.adresse.type) {
            this.data.adresse.type = this.listeTypeAdresse[0].type;
        }
        //Création
        if (this.data.adresse.id == null) {
            //Actif true par défaut
            this.data.adresse.actif = true;
        }
    }

    /**
     * Supression de l'adresse
     */
    deleteAdresse() {
        //Confirmation auprès de l'utilisateur sur la suppression d'une adresse
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe({
                next: () => {
                    this.isDeleting = true;
                    this.profilService.deleteAdresse(this.data.adresse,this.data.idUser).pipe(first()).subscribe({
                        next: result => {
                            //Message d'information
                            this.toastrService.success(this.translateService.instant('global.success.suppression'));

                            //Fermeture de l'écran
                            this.matDialogRef.close(true);
                        }
                    });
                }
            });
    }

    /**
     * Sauvegarde de l'adresse
     */
    save() {
        //Vérification de la présence d'une adresse du même type active (si adresse courante active, sinon inutile)
        if (this.data.adresse.actif && this.data.listeAdresse.some(adr => adr.actif && adr.type == this.data.adresse.type && adr.idAdresse != this.data.adresse.idAdresse)) {
            //Confirmation auprès de l'utilisateur sur la création d'une adresse inactive
            this.confirmService.showConfirm(this.translateService.instant('profilUser.adresses.add.dejaActif'))
                .pipe(filter(isConfirmed => isConfirmed))
                .subscribe({
                    next: () => {
                        this.executeSave();
                    }
                });
        } else {
           this.executeSave();
        }
    }

    /**
     * Fonction permettant d'effectuer la sauvegarde via le service
     */
    executeSave(){
        //Indicateur de sauvegarde
        this.isSaving = true;

        //Copie des informations de l'adresse d'origine
        let adresseToSave: Adresse = {...this.data.adresse};

        //Mise à jour du lien avec la localisation
        adresseToSave.idLocalisation = this.localisation ? this.localisation.idLocalisation : 0;

        //Sauvegarde de l'adresse
        this.profilService.saveAdresse(adresseToSave,this.data.idUser).pipe(first(),finalize(() => this.isSaving = false)).subscribe({
            next: result => {
                if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                    //Message d'information
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                    //Fermeture de l'écran
                    this.matDialogRef.close(true);
                } else {
                    this.toastrService.success(this.translateService.instant('global.error.enregistrement'));
                }
            },
            error: () => {
                this.toastrService.success(this.translateService.instant('global.error.enregistrement'));
            }
        });
    }

    /**
     * Met à jour la localisation de l'adresse avec celle choisie
     *
     * @param localisation localisation de l'adresse sélectionnée
     */
    setLocalisation(localisation: LieuProfil) {
        this.localisation = localisation;
    }
}
