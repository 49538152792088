import {Component,Input} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {StatutArchivage} from "@domain/comptabilite/statut-archivage";
import {NdfDemat} from "@domain/demat/ndf-demat";
import {environment} from "@environments/environment";

/**
 * Composant d'affichage d'une NDF d'un lot.
 *
 * @author François Turin
 * @date 31/08/2023
 */
@Component({
	host: {'data-test-id': 'ndf-demat-list-item'},
	templateUrl: './ndf-demat-list-item.component.html'
})
export class NdfDematListItemComponent extends ListViewItem<NdfDemat> {
	protected readonly StatutArchivage = StatutArchivage;

	/** NDF courante */
	@Input() data: NdfDemat;

	/**
	 * URL vers le téléchargment du fichier d'archive.
	 */
	get lienArchive(): string {
		return `${environment.baseUrl}/controller/Demat/downloadArchive?idNdf=${this.data.idNdf}`;
	}
}
