import {Filter} from "./filter";

/**
 * Classe des filtres du searchspec avec uniquement les champs utiles au back
 */
export class FilterDTO {
    clef: string;
    valeur: string;
    listeObjects: any[];
    dateDebut: Date;
    dateFin: Date;
    ignoreHeures: boolean;
    min: number;
    max: number;
    type: string;
    typeComparaison: string;

    constructor(filter: Filter) {
        this.clef = filter.clef;
        this.valeur = filter.valeur;
        this.listeObjects = filter.listeObjects;
        this.dateDebut = filter.dateDebut;
        this.dateFin = filter.dateFin;
        this.ignoreHeures = filter.ignoreHeures;
        this.min = filter.min;
        this.max = filter.max;
        this.type = filter.type;
        this.typeComparaison = filter.typeComparaison;
    }
}
