<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'warning': data.niveauAlerte === NiveauAlerte.WARNING,'danger': data.niveauAlerte === NiveauAlerte.ERROR }">
        <i class="material-icons">mail_outline</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="onSelect()">{{ getTitle() }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.entreprise.analytique.enveloppes.reference'"></strong>
                <span>{{ data.reference || ('admin.entreprise.analytique.enveloppes.aucune' | translate) }}</span>
            </li>
            <li *ngIf="data.lastMontant?.exercice?.libelle">
                <strong [translate]="'admin.entreprise.analytique.enveloppes.exercice'"></strong>
                <span>{{ data.lastMontant.exercice.libelle }}</span>
            </li>
            <li *ngIf="data.lastMontant?.dateDebut">
                <strong [translate]="'admin.entreprise.analytique.enveloppes.debut'"></strong>
                <span>{{ data.lastMontant.dateDebut | date: 'shortDate' }}</span>
            </li>
            <li *ngIf="data.lastMontant?.dateFin">
                <strong [translate]="'admin.entreprise.analytique.enveloppes.fin'"></strong>
                <span>{{ data.lastMontant.dateFin | date: 'shortDate' }}</span>
            </li>
            <li *ngIf="data.lastMontant?.montant !== null">
                <strong [translate]="'admin.entreprise.analytique.enveloppes.montantTotal'"></strong>
                <span>{{ data.lastMontant.montant | montant:data.lastMontant.codeDevise }}</span>
            </li>
            <li *ngIf="data.lastMontant?.montantEngage !== null">
                <strong [translate]="'admin.entreprise.analytique.enveloppes.montantEngage'"></strong>
                <span>{{ data.lastMontant.montantEngage | montant:data.lastMontant.codeDevise }}</span>
            </li>
            <li *ngIf="data.lastMontant?.montantDisponible !== null">
                <strong [translate]="'admin.entreprise.analytique.enveloppes.montantDisponible'"></strong>
                <span>{{ data.lastMontant.montantDisponible | montant:data.lastMontant.codeDevise }}</span>
            </li>
            <li *ngIf="data.niveauAlerte !== NiveauAlerte.NO_CONTROL" class="clickable" [ngClass]="{ 'back-warning': data.niveauAlerte === NiveauAlerte.WARNING, 'back-danger': data.niveauAlerte === NiveauAlerte.ERROR }" (click)="showAlerte()">
                <strong [translate]="'workflow.alerte.title'"></strong>
                <span [translate]="'workflow.alerte.niveau.' + data.niveauAlerte"></span>
            </li>
        </ul>
    </div>
</div>