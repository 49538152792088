import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {Result} from "../../domain/common/http/result";
import {Status, Vehicule} from "../../domain/vehicule/vehicule";
import {TypeDocument} from "../../domain/vehicule/typeDocument";
import {SettingsVehiculeState} from "../../domain/settings/settings";
import {AlertesVehicule} from "../../domain/vehicule/alertesVehicule";
import * as moment from "moment";
import {ListeAlertes} from "../../domain/common/alerte/listeAlertes";
import {TranslateService} from "@ngx-translate/core";
import {Alerte, NiveauAlerte} from "../../domain/common/alerte/alerte";
import {UserPipe} from "../../share/pipe/user.pipe";
import {DatePipe} from "@angular/common";
import {PuissanceFiscale} from "../../domain/vehicule/puissancefiscale";
import {map} from "rxjs/operators";

@Injectable()
/**
 * Service de gestion des véhicules
 */
export class VehiculeService {
    /**
     * Constructeur
     */
    constructor(private http: HttpClient,
                private translateService: TranslateService,
                private userPipe: UserPipe,
                private datePipe: DatePipe) {
    }

    /**
     * Chargement d'un véhicule et des paramètres des véhicules
     * @param idPa Identifiant du véhicule à charger
     */
    loadVehicule(idPa: number): Observable<Result> {
        //Récupération du véhicule
        return this.http.get<Result>(`${environment.baseUrl}/controller/ProfilUser/consultVehicule/${idPa}`);
    }

    /**
     * Chargement de la carte grise d'un véhicule
     * @param idCarteGrise Identifiant de la carte grise
     */
    loadVehiculeCarteGrise(idCarteGrise: number): Observable<Result> {
        const params: URLSearchParams = new URLSearchParams();

        //Ajout des paramètres
        params.append('idVehiculeCarteGrise', idCarteGrise.toString());

        //Récupération de la carte grise du véhicule
        return this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/loadVehiculeCarteGrise`, params.toString(),{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        });
    }

    /**
     * Chargement de plaque d immatriculation non obfusquee du vehicule
     * @param idVehicule Identifiant du vehicule
     */
    getPlaqueImmatriculationVehiculeNonObfusquee(idVehicule: number): Observable<Result> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/Vehicule/${idVehicule}/getPlaqueImmatriculationVehiculeNonObfusquee`);
    }

    /**
     * Chargement du certificat d'assurance du véhicule
     * @param idAssurance Identifiant du certificat d'assurance
     */
    loadVehiculeAssurance(idAssurance: number): Observable<Result> {
        const params: URLSearchParams = new URLSearchParams();

        //Ajout des paramètres
        params.append('idVehiculeAssurance', idAssurance.toString());

        //Récupération de l'assurance du véhicule
        return this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/loadVehiculeAssurance`, params.toString(),{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        });
    }

    /**
     * Chargement de l'autorisation de circuler du véhicule
     * @param idAutorisation Identifiant de l'autorisation de circuler
     */
    loadVehiculeAutorisation(idAutorisation: number): Observable<Result> {
        const params: URLSearchParams = new URLSearchParams();

        //Ajout des paramètres
        params.append('idVehiculeAutorisation', idAutorisation.toString());

        //Récupération de l'assurance du véhicule
        return this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/loadVehiculeAutorisation`, params.toString(),{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        });
    }

    /**
     * Sauvegarde d'un document rattaché à un véhicule
     * @param document Document à sauvegarder
     * @param type Type de document
     */
    saveVehiculeDocument(document: any, type: TypeDocument): Observable<Result> {
        let url;
        //Choix de l'url en fonction du type de document
        switch (type) {
            case TypeDocument.ASSURANCE:
                url = `${environment.baseUrl}/controller/ProfilUser/saveVehiculeAssurance`;
                break;
            case TypeDocument.AUTORISATION:
                url = `${environment.baseUrl}/controller/ProfilUser/saveVehiculeAutorisation`;
                break;
            case TypeDocument.CARTE_GRISE:
                url = `${environment.baseUrl}/controller/ProfilUser/saveVehiculeCarteGrise`;
                break;
            default:
                return null;
        }
        return this.http.put<Result>(url, document);
    }

    /**
     * Suppression de l'autorisation de circuler
     * @param idVehiculeAutorisation Identifiant de l'autorisation de circuler à supprimer
     */
    deleteVehiculeAutorisation(idVehiculeAutorisation: number): Observable<Result> {
        const params: URLSearchParams = new URLSearchParams();

        //Ajout des paramètres
        params.append('idVehiculeAutorisation', idVehiculeAutorisation.toString());
        return this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/deleteVehiculeAutorisation`, params.toString(),{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        });
    }

    /**
     * Suppression de la carte grise
     * @param idVehiculeCarteGrise Identifiant de la carte grise à supprimer
     */
    deleteVehiculeCarteGrise(idVehiculeCarteGrise: number): Observable<Result> {
        const params: URLSearchParams = new URLSearchParams();

        //Ajout des paramètres
        params.append('idVehiculeCarteGrise', idVehiculeCarteGrise.toString());

        return this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/deleteVehiculeCarteGrise`, params.toString(),{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        });
    }

    /**
     * Suppression du certificat d'assurance
     * @param idVehiculeAssurance Identifiant du certificat d'assurance à supprimer
     */
    deleteVehiculeAssurance(idVehiculeAssurance: number): Observable<Result> {
        const params: URLSearchParams = new URLSearchParams();

        //Ajout des paramètres
        params.append('idVehiculeAssurance', idVehiculeAssurance.toString());
        return this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/deleteVehiculeAssurance`, params.toString(),{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        });
    }

    /**
     * Suppression du véhicule
     * @param idPa Identifiant du véhicule à supprimer
     */
    deleteVehicule(idPa: number): Observable<Result> {
        const params: HttpParams = new HttpParams()
            .append('idPa', idPa.toString(10));

        return this.http.delete<Result>(`${environment.baseUrl}/controller/ProfilUser/deleteVehicule`,{
            params: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        });
    }

    /**
     * Sauvegarde du véhicule
     * @param vehicule Vehicule à sauvegarder
     * @param envoiMail Envoyer les notifications (true par défaut)
     */
    save(vehicule: Vehicule,envoiMail: boolean = true): Observable<Result> {
        //Ajout des paramètres
        const params: HttpParams = new HttpParams()
            .append('envoiMail', envoiMail.toString());

        //Requête
        return this.http.put<Result>(`${environment.baseUrl}/controller/ProfilUser/saveVehicule`, vehicule, {
            params: params
        });
    }

    /**
     * Changement du statut du véhicule
     * @param idPa Identifiant du véhicule
     * @param statut Numéro du nouveau statut
     * @param motif Motif de rejet du véhicule lorsqu'il est rejeté
     */
    changeStatutVehicule(idPa: number, statut: Status, motif?: string): Observable<Result> {
        let params: HttpParams;
        let body: string = motif ?? '';

        //Ajout des paramètres
        params = new HttpParams()
            .append('idPa', idPa.toString())
            .append('statut', statut.toString(10));

        return this.http.put<Result>(`${environment.baseUrl}/controller/Vehicule/changeStatutVehicule`,body,{
            params: params
        });
    }

    /**
     * Permet de tester si les dates de début/fin d'un document comprennent la date courante
     * @param obj Document
     */
    isDocumentActif(obj: any) {
        if (obj.dateImmatriculation) {
            return moment().isSameOrAfter(obj.dateImmatriculation,'day');
        } else {
            return moment().isBetween(obj.dateDebut,obj.dateFin,'day','[]');
        }
    }

    /**
     * Construit et retourne l'objet récapitulatif des alertes du véhicule
     *
     * @param vehicule Le véhicule
     * @param settings Le paramétrage des véhicules
     */
    buildAlertes(vehicule: Vehicule,settings: SettingsVehiculeState): AlertesVehicule {
        vehicule.alertes = vehicule.alertes ?? new AlertesVehicule();

        const momentNow = moment();

        vehicule.alertes.isSoonToExpireAssurance = settings.profilAlerteConfig?.alerteEcheanceActif && vehicule.listeAssurance?.filter(doc => momentNow.isBefore(moment(doc.dateFin)) && momentNow.add(settings.profilAlerteConfig.delaiAlerteEcheance, 'days').isAfter(moment(doc.dateFin))).length > 0;
        vehicule.alertes.isSoonToExpireAutorisation = settings.profilAlerteConfig?.alerteEcheanceActif && vehicule.listeAutorisation?.filter(doc => momentNow.isBefore(moment(doc.dateFin)) && momentNow.add(settings.profilAlerteConfig.delaiAlerteEcheance, 'days').isAfter(moment(doc.dateFin))).length > 0;
        vehicule.alertes.isMissingAssurance = settings.assuranceObligatoire && vehicule.listeAssurance?.filter(doc => this.isDocumentActif(doc)).length == 0;
        vehicule.alertes.isMissingCarteGrise = settings.carteGriseObligatoire && vehicule.listeCarteGrise?.filter(doc => this.isDocumentActif(doc)).length == 0;
        vehicule.alertes.isMissingAutorisation = settings.autorisationActif && vehicule.listeAutorisation?.length > 0 && vehicule.listeAutorisation.filter(doc => this.isDocumentActif(doc)).length == 0;
        vehicule.alertes.isRejet = vehicule.statut == Status.STATUT_REFUSE;
        vehicule.alertes.toListeAlertes = this.buildListeAlertes.bind(this,vehicule);

        return vehicule.alertes;
    }

    /**
     * Construit et retourne la liste des alertes à partir de l'objet récapitulatif des alertes du véhicule
     *
     * @param vehicule Le véhicule
     */
    buildListeAlertes(vehicule: Vehicule): ListeAlertes {
        //Vérification de l'objet récapitulatif des alertes
        if (!vehicule.alertes) {
            throw("L'objet Alertes n'a pas été initialisé sur le véhicule !");
        }

        const listeAlertes = new ListeAlertes();

        //Pas d'assurance active
        if (vehicule.alertes?.isMissingAssurance) {
            listeAlertes.add(new Alerte({
                niveau: NiveauAlerte.ERROR,
                titre: this.translateService.instant('vehicule.liste.alerte.assuranceTitle'),
                message: this.translateService.instant('vehicule.liste.alerte.assuranceBody')
            }));
        }

        //Pas de carte grise active
        if (vehicule.alertes?.isMissingCarteGrise) {
            listeAlertes.add(new Alerte({
                niveau: NiveauAlerte.ERROR,
                titre: this.translateService.instant('vehicule.liste.alerte.carteGriseTitle'),
                message: this.translateService.instant('vehicule.liste.alerte.carteGriseBody')
            }));
        }

        //Pas d'autorisation active
        if (vehicule.alertes?.isMissingAutorisation) {
            listeAlertes.add(new Alerte({
                niveau: NiveauAlerte.ERROR,
                titre: this.translateService.instant('vehicule.liste.alerte.autorisationTitle'),
                message: this.translateService.instant('vehicule.liste.alerte.autorisationBody')
            }));
        }

        //Traitement de l'alerte spéciale dans le cas d'un véhicule rejeté
        if (vehicule.alertes?.isRejet) {
            listeAlertes.add(new Alerte({
                niveau: NiveauAlerte.ERROR,
                titre: this.translateService.instant('vehicule.liste.alerte.rejet',{
                    date: this.datePipe.transform(vehicule.vehiculeApprobation?.dateAction,'shortDate'),
                    collab: this.userPipe.transform(vehicule.vehiculeApprobation?.user)
                }),
            message: vehicule.vehiculeApprobation?.motif}));
        }

        return listeAlertes;
    }

    /**
     * Retourne la liste des puissances fiscales
     */
    getListePuissanceVehicule(): Observable<Array<PuissanceFiscale>> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/listePuissanceVehicule`, null)
            .pipe(map(result => result.data?.listePuissance));
    }

}
