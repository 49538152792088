<mat-card>
    <mat-card-title>
            <span [translate]="'admin.maintenance.migrations.onglets.parametres.title'"></span>
    </mat-card-title>
    <mat-card-content>
        <form #form="ngForm">
            <fieldset>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="contactAdmin"
                                  name="contactAdmin"
                                  [(ngModel)]="maintenanceParam.emailContactAdmin"
                                  customType="input"
                                  libelle="admin.maintenance.migrations.onglets.parametres.parametresCard.contactAdmin"
                                  [postTooltip]="'admin.maintenance.migrations.onglets.parametres.parametresCard.tooltipAdresses' | translate"
                                  [required]="true">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="migrationsAuto"
                                  name="migrationsAuto"
                                  lCol="3" rCol="3"
                                  [ngModel]="maintenanceParam.migrationAuto"
                                  [(customModel)]="maintenanceParam.migrationAuto"
                                  customType="checkbox"
                                  libelle="admin.maintenance.migrations.onglets.parametres.parametresCard.migrationsAuto">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="contactNotilus"
                                  name="contactNotilus"
                                  [(ngModel)]="maintenanceParam.emailContactNotilus"
                                  customType="input"
                                  libelle="admin.maintenance.migrations.onglets.parametres.parametresCard.contactNotilus"
                                  [postTooltip]="'admin.maintenance.migrations.onglets.parametres.parametresCard.tooltipAdresses' | translate"
                                  [required]="true">
                    </custom-input>
                </div>
            </fieldset>
        </form>
    </mat-card-content>
</mat-card>
<floating-button [listeActions]="listeActions"></floating-button>
