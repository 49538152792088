<div
    *ngIf="appStatut?.applicationStatut?.messagePrincipal || synchroWfStatut?.messagePrincipal"
    class="statut-container"
    [class.locked]="appStatut?.applicationStatut?.statut == KeyStatutApplication.LOCKED">
    <mat-icon>error_outline</mat-icon>
    <div *ngIf="appStatut?.applicationStatut?.messagePrincipal" class="statut-message" (click)="goToMaintenance()">
        <div class="message-principal">{{ appStatut.applicationStatut.messagePrincipal | translate }}</div>
        <div>{{ appStatut.applicationStatut.messageSecondaire | translate }}</div>
        <mat-progress-bar
                *ngIf="appStatut?.applicationStatut?.statut != KeyStatutApplication.LOCKED"
                class="statut-bar"
                mode="buffer"
                [value]="appStatut?.progression">
        </mat-progress-bar>
    </div>
    <div *ngIf="synchroWfStatut?.messagePrincipal" class="statut-message" (click)="handleSynchroWFClick()">
        <div class="message-principal">{{ synchroWfStatut.messagePrincipal | translate }}</div>
        <div>{{ synchroWfStatut.messageSecondaire | translate }}</div>
        <mat-progress-bar
                class="statut-bar"
                [mode]="modeProgressBarWf"
                [bufferValue]="100"
                [value]="progressionWf">
        </mat-progress-bar>
    </div>
</div>
