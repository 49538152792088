/**
 * Énumération des différents types de référentiels
 *
 * @author Laurent Convert
 * @date 25/04/2023
 */
export enum TypeReferentiel {
	TYPE_PRESTATION = 'D',
	FAMILLE = 'F',
	INDEMNITE = 'I',
	LIASSE = 'L',
	MOTS_CLEFS = 'M',
	RUBRIQUE = 'R',
	NS_UNITE_DISTANCE = 'Distance',
	NS_UNITE = 'Unite'
}
