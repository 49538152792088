import {TypePortee} from "./workflow";
import {ListeAlertes} from "../common/alerte/listeAlertes";
import {MapAction} from "./mapAction";
import {Analytique} from "@components/analytique/analytique";
import {TypeEntite} from "../typeentite/typeEntite";
import {TranslateService} from "@ngx-translate/core";
import {WorkflowHistoriqueAction} from "@domain/chainage/workflowHistorique";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";

/**
 * Décrit les méthodes en commun des objets OD/OMP/NDF etc.
 * Utilisé dans le composant objet-workflow-generic.component.ts
 */
export abstract class AbstractObjetWorkflow {
    /* Dernière action workflow effectuée sur l'objet */
    private _lastWorkflowHistoriqueAction: WorkflowHistoriqueAction;
    get lastWorkflowHistoriqueAction(): WorkflowHistoriqueAction { return this._lastWorkflowHistoriqueAction; }
    set lastWorkflowHistoriqueAction(value: WorkflowHistoriqueAction) {
        //MAJ de l'action
        this._lastWorkflowHistoriqueAction = value;
        //S'il y a un motif
        if (value?.motif) {
            //Recherche des actions Rejeter, Invalider, Annuler
            if (value.idAction == 2 || value.idAction == 3 || value.idAction == 4) {
                //Création de l'alerte rouge
                this._lastWorkflowHistoriqueActionAlerte = new Alerte({
                    niveau: NiveauAlerte.ERROR,
                    titre: value.libelleAction,
                    message: ((value.prenom ? value.prenom : "") + (value.prenom && value.nom ? " " : "")
                        + (value.nom ? value.nom : "") + " : " + value.motif)
                });
            }
            //Recherche des actions Valider, Emettre, Archiver, Clôturer
            else if (value.idAction == 1 || value.idAction == 7 || value.idAction == 8 || value.idAction == 20) {
                //Création de l'alerte verte
                this._lastWorkflowHistoriqueActionAlerte = new Alerte({
                    niveau: NiveauAlerte.NO_CONTROL,
                    titre: value.libelleAction,
                    message: ((value.prenom ? value.prenom : "") + (value.prenom && value.nom ? " " : "")
                        + (value.nom ? value.nom : "") + " : " + value.motif)
                });
            }
            //Sinon
            else {
                //Reset de l'alerte
                this._lastWorkflowHistoriqueActionAlerte = undefined;
            }
        }
        //Sinon
        else {
            //Reset de l'alerte
            this._lastWorkflowHistoriqueActionAlerte = undefined;
        }
    }

    /* Alerte liée au motif de la dernière action workflow */
    private _lastWorkflowHistoriqueActionAlerte: Alerte;
    get lastWorkflowHistoriqueActionAlerte(): Alerte { return this._lastWorkflowHistoriqueActionAlerte; }

    abstract getId(): number;
    abstract getPortee(): TypePortee;
    abstract getListeAlertes(): ListeAlertes;
    getListeVentilation(): Array<Analytique> { return undefined; };
    getTypeEntite(): TypeEntite { return undefined; };
    abstract getMapAction(): MapAction;
    abstract getCollaborateur(): String;
    abstract getRoles(): string;
    abstract getRolesCount(): number;
    abstract getIdCollab(): number;
    abstract getLibelleObjet(translateService: TranslateService): string;
    abstract isValid(): boolean;

    abstract setListeAlertes(listeAlertes: ListeAlertes): void;

    canModifier(): boolean { return this.getMapAction()?.canModifier?.possible == true; }
    canCompleter(): boolean { return this.getMapAction()?.canCompleter?.possible == true; }
    canRejeter(): boolean { return this.getMapAction()?.canRejeter?.possible == true; }
    
    hasChilds(): boolean { return false; }
}