<div class="with-floating-button">
    <div class="content" *ngIf="lot && !isLoading">
        <page-header [title]="lot.idLot === 0 ? ('lot.titleCreation' | translate) : ('lot.title' | translate:{ numero: lot.idLot })"
            [listeItems]="listeTabItems"
            [extraInfo]="getStatutArchivage()"
            [statutArchivage]="lot.statutArchivage"
            [settings]="settings"
            (onSelectedItemChange)="onSelectedItemChange($event)"
            (onGoBack)="onGoBack()">
        </page-header>

        <div [hidden]="selectedItem?.code !== 'GENERALITES'">
            <lot-generalites [lot]="lot" [settings]="settings" [canModifier]="canModifier"></lot-generalites>
        </div>
        <div [hidden]="selectedItem?.code !== 'NDF'">
            <list-view [liste]="listeNDF" *ngIf="listeNDF"></list-view>
            <list-view #listeViewNDFCreation [liste]="listeNDFCreation" *ngIf="listeNDFCreation"></list-view>
        </div>
        <div [hidden]="selectedItem?.code !== 'AVANCE'">
            <list-view [liste]="listeAV" *ngIf="listeAV"></list-view>
            <list-view #listeViewAVCreation [liste]="listeAVCreation" *ngIf="listeAVCreation"></list-view>
        </div>
        <div [hidden]="selectedItem?.code !== 'FACTURE'">
            <list-view [liste]="listeFC" *ngIf="listeFC"></list-view>
            <list-view #listeViewFCCreation [liste]="listeFCCreation" *ngIf="listeFCCreation"></list-view>
        </div>
    </div>
    <floating-button [listeActions]="listeActions" *ngIf="lot"></floating-button>
    <please-wait class="content" *ngIf="!lot || isLoading"></please-wait>
</div>
