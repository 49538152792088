import {Component,Input} from "@angular/core";
import {ControlContainer,NgForm} from "@angular/forms";
import {Langue} from "@domain/admin/bibliotheque/internationalisation/langue";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TranslateService} from "@ngx-translate/core";
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {Sorting} from "@domain/common/list-view/sorting";
import {LibelleItemComponent} from "@components/admin/bibliotheque/internationalisation/langue-infos/libelles/libelle-item.component";
import {Traduction} from "@domain/admin/bibliotheque/internationalisation/traduction";
import {MatDialog} from "@angular/material/dialog";
import {PopupLibelleComponent} from "@components/admin/bibliotheque/internationalisation/langue-infos/libelles/popup-libelle.component";
import {Page} from "@domain/common/http/list-result";

/**
 * Composant d'affichage des informations du cadre "langue"
 */
@Component({
    host: {'data-test-id': 'langue-infos-libelle'},
    selector: 'langue-infos-libelle',
    templateUrl: './langue-infos-libelle.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class LangueInfosLibelleComponent {
    /** Langue sélectionnée */
    @Input() langue: Langue;

    /** Liste des libellés, pour utiliser le composant ListViewComponent */
    liste: ListView<Traduction, LibelleItemComponent>;

    constructor(private confirmService: ConfirmService, private translateService: TranslateService, protected matDialog: MatDialog) {}

    ngOnInit() {
        //Création de la liste des langues
        this.liste = new ListView<Traduction, LibelleItemComponent>({
            uri: `/controller/Traduction/listeTraduction/${this.langue.code}`,
            title: this.translateService.instant('admin.bibliotheque.internationalisation.onglets.libelles'),
            component: LibelleItemComponent,
            extraOptions: {
                onSelect: this.onSelect.bind(this)
            },
            mapResult: (page: Page<Traduction>) => {
                //Typage des éléments de la liste
                if (page?.listeResultats) {
                    page.listeResultats = page.listeResultats.map(r => {
                        return new Traduction(r);
                    });
                }

                //Retour
                return page as Page<Traduction>;
            },
            isFilter: true,
            defaultOrder: 'cleTraduction.cle',
            listeFilters: [
                {
                    clef: 'cleTraduction.cle',
                    title: this.translateService.instant('admin.bibliotheque.internationalisation.libelleFiltres.cleTraduction'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'messageStandard',
                    title: this.translateService.instant('admin.bibliotheque.internationalisation.libelleFiltres.standard'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'messageCustom',
                    title: this.translateService.instant('admin.bibliotheque.internationalisation.libelleFiltres.custom'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: '#_personnalise',
                    title: this.translateService.instant('admin.bibliotheque.internationalisation.libelleFiltres.personnalise'),
                    type: TypeFilter[TypeFilter.BOOLEAN],
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL]
                },
            ]
        });

        //Définition des colonnes de tri
        this.liste.columns = [
            { key: 'cleTraduction.cle', title: 'admin.bibliotheque.internationalisation.libelleFiltres.cleTraduction' },
            { key: 'messageStandard', title: 'admin.bibliotheque.internationalisation.libelleFiltres.standard' },
            { key: 'messageCustom', title: 'admin.bibliotheque.internationalisation.libelleFiltres.custom' },
        ];

        //Ajout du tri de la liste selon l'ordre voulu
        this.liste.sorting = new Sorting(this.liste.columns, "cleTraduction.cle");
    }

    /**
     * Méthode appelée lors de la sélection d'un libellé
     *
     * @param traduction Libellé sélectionné
     */
    onSelect(traduction: Traduction) {
        return this.matDialog.open(PopupLibelleComponent, {
                data: {
                    traduction
                },
                minWidth: 1000,
                maxWidth: 1200
            },
        );
    }
}
