import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AppState } from "@domain/appstate";
import { ListView, TypeComparaison, TypeFilter } from "@domain/common/list-view";
import { Lot } from "@domain/comptabilite/lot";
import { TypePortee } from "@domain/workflow/workflow";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as settingsActions from "@reducers/settings";
import { BehaviorSubject } from "rxjs";
import { FloatingButtonAction, TypeAction } from "@share/component/floating-button/floating-button";
import { LotListItemComponent } from "./lot-list-item.component";

@Component({
    templateUrl: './lot-list.component.html'
})
/**
 * Composant d'affichage de lot comptable en liste
 */
export class LotListComponent implements OnInit {

    /** Liste des lots */
    liste: ListView<Lot, LotListItemComponent>;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    constructor(
        private translateService: TranslateService,
        private store: Store<AppState>,
        private router: Router
    ) {
    }

    async ngOnInit() {
        //Chargement du paramétrage
        this.store.dispatch({
            type: settingsActions.LOAD_SETTINGS,
            payload: TypePortee.CPT
        });

        //Définition de la liste
        this.liste = new ListView<Lot, LotListItemComponent>({
            uri: `/controller/Lot/filtreLots`,
            title: this.translateService.instant('lot.liste.title'),
            component: LotListItemComponent,
            isFilter: true,
            defaultOrder: '-idLot',
            listeFilters: [
            {
                clef: 'idLot',
                isDefault: true,
                typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                title: this.translateService.instant('lot.filtres.identifiant')
            },{
                title: this.translateService.instant("lot.filtres.libelle"),
                clef: "libelle",
                typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                isDefault: true
            },{
                title: this.translateService.instant("lot.filtres.montant"),
                clef: "montant",
                type: TypeFilter[TypeFilter.DECIMAL],
                isDefault: true
            },{
                clef: 'nbNdf',
                typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                title: this.translateService.instant('lot.filtres.nbNote')
            },{
                clef: 'nbAvance',
                typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                title: this.translateService.instant('lot.filtres.nbAvance')
            },{
                clef: 'nbFacture',
                typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                title: this.translateService.instant('lot.filtres.nbFacture')
            },{
                clef: 'periode.libelle',
                typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                title: this.translateService.instant('lot.filtres.periode')
            },{
                clef: 'date',
                type: TypeFilter[TypeFilter.DATE],
                typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                title: this.translateService.instant('lot.filtres.date')
            }]
        });
        // Pour faire en sorte que la liste soit filtrable via du CONTAINS
        this.liste.className = 'LotListComponent';
        this.liste.isPersistFilters = true;
        this.liste.columns = [
            { key: 'idLot', title: 'lot.liste.columns.idLot' },
            { key: 'libelle', title: 'lot.liste.columns.libelle' },
            { key: 'montant', title: 'lot.liste.columns.montant' },
            { key: 'nbNdf', title: 'lot.liste.columns.nbNdf' },
            { key: 'nbAvance', title: 'lot.liste.columns.nbAvance' },
            { key: 'nbFacture', title: 'lot.liste.columns.nbFacture' },
            { key: 'periode.libelle', title: 'lot.liste.columns.libellePeriode' },
            { key: 'date', title: 'lot.liste.columns.date' },
        ];
        //Définition de la liste des actions
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-ajouter',
            libelle: 'global.actions.creer',
            doAction: () => this.createLot(),
            isVisible: () => true
        }]);
    }

    /**
     * Création d'un lot
     */
    private createLot() {
        this.router.navigate(['Lot', 0]);
    }
}