import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SettingsAVState} from "../../../domain/settings/settings";
import {Router} from "@angular/router";
import {MatSelectChange} from "@angular/material/select";
import {finalize, first} from "rxjs/operators";
import {SettingsService} from "../../settings/settings.service";
import {NgForm} from "@angular/forms";
import {Avance} from "../../../domain/avance/avance";
import {ZoneUtilisateurComponent} from '../../zone-utilisateur/zone-utilisateur.component';
import {AvanceService} from "../avance.service";
import {CompteBancaire} from "../../../domain/comptabilite/compte-bancaire";
import { NiveauAlerte } from '@domain/common/alerte/alerte';

/**
 * Composant d'affichage de l'onglet Généralités des avances
 */
@Component({
    host: {'data-test-id': 'avance-generalites'},
    selector: 'avance-generalites',
    templateUrl: './avance-generalites.component.html',
    styleUrls: ['./avance-generalites.component.scss']
})
export class AvanceGeneralitesComponent implements OnInit {
    //Constantes
    NiveauAlerte = NiveauAlerte;

    /** Avance */
    @Input() avance: Avance;

    /** Indicateur de mise à jour possible */
    @Input() canModifier: boolean;

    /** Paramétrage de l'avance */
    @Input() settings: SettingsAVState;

    /** Comptes bancaires du collab */
    listeComptesBancaires: Array<CompteBancaire>;

    /** Formulaire de l'avance */
    @ViewChild('form') form: NgForm;

    /** Composant enfant zone utilisateur */
    @ViewChild(ZoneUtilisateurComponent) childZoneUtilisateurComponent: ZoneUtilisateurComponent;

    /** Indicateur de chargement du montant de l'avance (montant calculé depuis le back dans le cas d'un lien avec un OD) */
    isMontantLoading: boolean = false;

    /**
     * Champ pour la selection de devise
     */
    deviseAvance: any;

    /**
     * Constructeur
     */
    constructor(private router: Router,private settingsService: SettingsService,private avanceService:AvanceService) {
    }

    /**
     * Comparaison de comptes bancaires
     */
    compareComptesBancaires(c1: any, c2: any): boolean {
        //Comparaison des identifiants
        return c1?.idCompte && c2?.idCompte && c1.idCompte == c2.idCompte;
    }

    /**
     * Comparaison de devises
     */
    compareDevises(d1: any, d2: any): boolean {
        //Comparaison des codes
        return d1?.code && d2?.code && d1.code == d2.code;
    }

    /**
     * Initialisation
     */
    ngOnInit() {
        this.deviseAvance = {...this.avance.devise};

        //Chargement des comptes bancaires du collab
        this.avanceService.listeComptesPourAvance(this.avance.user.idUser)
            .pipe(first())
            .subscribe(data => {
                this.listeComptesBancaires = data.comptes;

                //Vérification du compte bancaire
                if (this.avance && this.listeComptesBancaires?.length && !this.avance.compteCollaborateur) {
                    //Mise à jour du compte bancaire
                    this.avance.compteCollaborateur = this.listeComptesBancaires.find(c => c.defaut);
                } else if (this.avance.idAvance == 0) {
                    this.avance.nature = "ESPECE";
                }
            });

        //Vérification du mode création avec lien sur un OD
        if (this.avance.idAvance == 0 && this.avance.idOd) {
            //Début du chargement du plafond
            this.isMontantLoading = true;

            //Récupération du plafond de l'avance
            this.avanceService.getPlafondAvance(this.avance.idOd,this.avance.compteCollaborateur?.devise)
                .pipe(first(),finalize(() => { this.isMontantLoading = false; }))
                .subscribe(data => {
                    //Vérification des données
                    if (data && (data.plafondAvance || data.plafondAvance === 0)) {
                        //Mise à jour du montant avec le plafond
                        this.avance.montant = data.plafondAvance;

                        //Calcul de la contrepartie
                        if (this.avance.compteCollaborateur) {
                            //On force la nature sur Virement
                            this.avance.nature = "VIREMENT";

                            //Calcul dans la devise du compte bancaire
                            this.changeDeviseToCompteDevise();
                        } else {
                            //Calcul dans la devise de l'avance
                            this.calculerMontantContrepartie(this.deviseAvance?.code);
                        }
                    }
                });
        }

        //Vérification de la devise
        if (this.avance && this.settings?.listeDevises?.length && !this.avance.devise) {
            //Mise à jour des devises
            this.avance.devise = this.settings.listeDevises.find(value => value.code == this.settings.deviseEntreprise);
            this.deviseAvance = {...this.avance.devise};
        }

        //Si on est en création on récupère les zones utilisateurs
        if (this.avance.idAvance == 0) {
            this.avanceService.loadZUAvance().pipe(first()).subscribe({
                next: result => {
                    this.avance.listeZU = result.data.listeZU;
                }
            });
        }
    }

    /**
     * Navigation vers l'OD de l'avance
     * @param od
     */
    navigateToOd(od: any) {
        //Navigation vers l'od
        this.router.navigate(['OD', od.idOd]);
    }

    /**
     * Calcul du montant de la contrepartie en fonction de la devise
     * @param codeDevise Devise a appliquer au montant
     */
    calculerMontantContrepartie(codeDevise: string) {
        //Si on n'a pas de montant ou que ce n'est pas un nombre, on ne fait pas le calcule
        if(this.avance.montant == null || isNaN(Number(this.avance.montant))){
            return;
        }
        
        if (codeDevise != this.avance.devise?.code) {
            //Recherche du cours de la devise
            this.settingsService.findCoursForDevise({
                codeDevise: codeDevise,
                date: this.avance.dateSaisie,
                coursDeviseBase: 1
            }).pipe(first()).subscribe(cours => {
                //Mise à jour du cours et du montant de la contrepartie
                this.avance.taux = cours;
                this.avance.montantContrepartie = this.avance.montant * cours;
            });
        } else {
            //Mise à jour du cours et du montant de la contrepartie
            this.avance.montantContrepartie = this.avance.montant * this.avance.taux;
        }
    }

     /**
     * Event de modification du montant
     */
    onMontantChanged() {
        this.calculerMontantContrepartie(this.avance.devise.code);
    }

    /**
     * Changement de devise
     */
    onDeviseChanged(change: MatSelectChange) {
        //Vérification de la devise
        this.calculerMontantContrepartie(change.value.code);
        this.avance.devise = change.value;
    }

    /**
     * Changement de nature
     */
    onNatureChanged(change: MatSelectChange) {
        //Vérification de la nature
        if (change.value == "VIREMENT") {
            this.changeDeviseToCompteDevise()
        }
    }

    /**
     * Changement de la devise de l'avance pour la devise du compte sélectionné
     */
    changeDeviseToCompteDevise() {
        this.calculerMontantContrepartie(this.avance.compteCollaborateur.devise);
        this.deviseAvance = this.settings.listeDevises.filter(value => value.code == this.avance.compteCollaborateur.devise)[0];
        this.avance.devise = {...this.deviseAvance};
    }

    /**
     * Met à jour le dto avant la sauvegarde
     */
     public beforeSave(): void { 
        //Appelle le composant enfant zone utilisateur pour intégrer les données du formulaire zone utilisateur
        this.avance.listeZU = this.childZoneUtilisateurComponent.getListeZoneUtilisateursBeforeSave();
    }

    public isValid(): boolean {
        return this.childZoneUtilisateurComponent.isValid();
    }
}
