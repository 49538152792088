<h1 mat-dialog-title>
    <span [translate]="'admin.entreprise.utilisateurs.detail.filtres.createFiltrePopin.title.' + data.fonction"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form #form="ngForm">
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="type"
                    name="type"
                    customType="select"
                    [(ngModel)]="selectedType"
                    libelle="admin.entreprise.utilisateurs.detail.filtres.createFiltrePopin.type"
                    [selectOptions]="listeTypeFiltre"
                    optionValue="code"
                    optionDisplay="libelle"
                    [required]="true"
                    [disabled]="isPending"
                    (onChange)="onSelectedTypeChange()">
            </custom-input>
            <custom-input
                    *ngIf="selectedType"
                    ngDefaultControl
                    id="filter"
                    name="filter"
                    customType="autocomplete"
                    [(ngModel)]="selectedFiltre"
                    libelle="admin.entreprise.utilisateurs.detail.filtres.createFiltrePopin.valeur"
                    [autocompleteType]="getAutocompleteType()"
                    [idName]="getIdName()"
                    [required]="true"
                    [disabled]="isPending">
            </custom-input>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="save()" [disabled]="form.invalid || isPending">
        <span *ngIf="!isPending" [translate]="'global.actions.enregistrer'"></span>
        <mat-spinner *ngIf="isPending" class="m-5" diameter="28"></mat-spinner>
    </button>
</div>