import {Component, Input, ViewChild} from '@angular/core';
import {SettingsGlobalState} from "../../../domain/settings/settings";
import {Lot} from "../../../domain/comptabilite/lot";
import {NgForm} from "@angular/forms";

@Component({
    selector: 'lot-generalites',
    templateUrl: './lot-generalite.component.html',
    styleUrls: ['./lot-generalite.component.scss']
})
/**
 * Composant d'affichage des généralités pour les lots comptables
 */
export class LotGeneraliteComponent {
    /** Lot comptable */
    @Input() lot: Lot;

    /** Paramétrage */
    @Input() settings: SettingsGlobalState;

    /** Flag déterminant si l'objet est modifiable */
    @Input() canModifier: boolean;

    /** Formulaire de l'avance */
    @ViewChild('form') form: NgForm;

    /** Indique si le détail de la ligne est affiché */
    isOuvert: boolean = true;

    constructor() { }

    /**
     * Comparaison de périodes
     */
    comparePeriodes(p1: any, p2: any): boolean {
        //Comparaison des identifiants
        return p1?.idPeriode && p2?.idPeriode && p1.idPeriode == p2.idPeriode;
    }

    /**
     * Ouvre / Ferme le détail de la ligne
     */
     toggleDetail() {
        this.isOuvert = !this.isOuvert;
    }
}
