import { ListItem } from "../common/list-view/list-item";

/**
 * DTO Population
 */
export class Population implements ListItem {

    idPopulation: number;
    libelle: string;

    /**
     * Constructeur
     *
     * @param idPopulation
     * @param libelle
     */
    constructor(idPopulation: number,libelle: string) {
        this.idPopulation = idPopulation;
        this.libelle = libelle;
    }

    getKey(): number {
        return this.idPopulation;
    }
}
