<div class="with-floating-button">
    <div class="content">
        <page-header [title]="'admin.parametres.demat.archivage.detail.title' | translate:{idLot: this.idLot}"
                     [listeItems]="listeTabItems" (onSelectedItemChange)="onSelectedTabChange($event)" (onGoBack)="goBack()" >
        </page-header>

        <div [hidden]="selectedItem?.code !== Onglet.ARCHIVAGE">
            <historique-archivage [idLot]="idLot"></historique-archivage>
        </div>
    </div>
</div>
