import {AfterContentChecked,AfterViewInit,Component,EventEmitter,Inject,Input,OnInit,Output,QueryList,ViewChild,ViewChildren} from '@angular/core';
import {SynchroSBTConfigUser} from "@domain/voyage/travel/synchro-sbt-config-user";
import {TranslateService} from "@ngx-translate/core";
import {TypeAiguillage,TypeAiguillageLiteral,TypeNature} from "@domain/voyage/travel/constants";
import {ODService} from "@components/od/od.service";
import {finalize,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {STEPPER_GLOBAL_OPTIONS} from "@angular/cdk/stepper";
import {MatStep,MatVerticalStepper} from "@angular/material/stepper";
import {DOCUMENT} from "@angular/common";
import {NgForm} from "@angular/forms";
import {ClasseEtape,FournisseurEtape,IdOptionEtape,Motif,OptionEtape,SaisieEtapeDTO,TypeConvenance,TypeDepart,TypeVehicule} from "@domain/travel/saisie-etape-dto";
import {Od} from '@domain/od/od';
import * as moment from "moment";
import {max,min} from "moment";
import {TypePortee} from "@domain/workflow/workflow";
import {SettingsODState} from "@domain/settings/settings";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {ToastrService} from "ngx-toastr";
import {TypePresta} from "@domain/travel/type-presta";
import {TypeChampsCarte} from "@domain/profil/typeChampsCarte";
import {Observable,Subject} from "rxjs";
import {GeographieView} from "@domain/geographie/geographieView";
import {DateUtilsService} from "@share/utils/date-utils/date-utils.service";
import {MapAction} from "@domain/workflow/mapAction";
import {NiveauNature} from "@components/od/detail/voyage/od-voyage.service";

/**
 * Composant d'ajout de trajets dans un OD
 *
 * @author Laurent SCIMIA
 * @date 22/11/2021
 */
@Component({
    host: {'data-test-id': 'voyage-popup-trajet'},
    selector: 'voyage-popup-trajet',
    templateUrl: './od-voyage-travel-popup-trajet.component.html',
    styles: ['.bouton-droite-gauche {justify-content: space-between}'],
    providers: [
        {
            //On ajoute ce Provider pour permettre la customisation du stepper
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: {
                displayDefaultIndicatorType: false,//Permet de customiser les icônes
                showError: true//Permet de gérer les erreurs sur les steps
            }
        }
    ]
})
export class OdVoyageTravelPopupTrajetComponent implements OnInit, AfterContentChecked, AfterViewInit {
    /* Accès aux énumérations */
    TypeAiguillage = TypeAiguillage;
    TypeNature = TypeNature;
    TypePresta = TypePresta;
    TypeChampsCarte = TypeChampsCarte;
    TypeDepart = TypeDepart;

    /** Indique si on peut modifier le trajet */
    @Input()
    canModifier?: boolean = true;

    /** Liste des types de départ pour l'autocomplete */
    listeTypeDepart: { valeur: number, libelle: string }[];
    /** Liste des types de retour pour l'autocomplete */
    listeTypeRetour: { valeur: number, libelle: string }[];

    /** Liste des types de document disponibles à la création */
    listeTypeDocument: { valeur: TypePresta, libelle: string }[];

    /** Liste des fournisseurs pour l'autocomplete */
    listeFournisseurs: FournisseurEtape[];

    /** Liste des classes pour l'autocomplete */
    listeClasses: ClasseEtape[];

    /** Liste des options de trajet à afficher en fonction du type de trajet */
    listeOptionsTrajet: Array<OptionEtape>;

    /** Accès direct au stepper */
    @ViewChild('stepper')
    stepper: MatVerticalStepper;

    /** Accès direct à la step d'ajout de trajet */
    @ViewChild('stepAjout')
    stepAjout: MatStep;

    /** Accès direct à tous les formulaires des étapes */
    @ViewChildren('etapeForm') listeEtapesForm: QueryList<NgForm>;

    /** Liste des étapes */
    listeEtapes: SaisieEtapeDTO[] = [];

    /** Tag permettant de savoir si on a demandé l'ajout d'une étape */
    hasClick: boolean = false;

    /** Tag permettant de savoir si l'enregistrement est en cours */
    isSaving: boolean;

    /** Tag permettant de savoir si la réservation est en cours */
    isBookingInProgress: boolean;

    /** Tag permettant de savoir s'il est temps de vérifier la validité du formulaire */
    isTimeToCheck;

    /** Devise utilisée par le travel */
    @Input()
    devise: string;

    /** Etape à visualiser (dans le cas où on a cliqué sur une étape déjà existante, sinon null */
    @Input() etapeExistante: SaisieEtapeDTO;

    /** Backup de l'origine de l'étape pour différencier une nouvelle étape dont l'origine ne serait pas encore renseignée
     * et une étape existante où l'origine serait existante mais ne correspondrait pas à une bonne géographie */
    origineBackup: GeographieView;

    /** Backup de la destination de l'étape pour différencier une nouvelle étape dont la destination ne serait pas encore renseignée
     * et une étape existante où la destination serait existante mais ne correspondrait pas à une bonne géographie */
    destinationBackup: GeographieView;

    /** SBT utilisé */
    sbt: SynchroSBTConfigUser;

    /** Regroupement par nature des SBT */
    @Input()
    niveauNature: NiveauNature;

    /** L'OD associé au trajet */
    @Input()
    od: Od;

    /** Observable qui indique si une synchro du profil connexion est en cours */
    @Input()
    synchroProfilEnCours: Observable<boolean>;

    /** Événement de retour arrière */
    @Output()
    retourArriere = new EventEmitter<void>();

    /** Liste de toutes les options possibles */
    @Input()
    listeOptionMotifs?: Array<Motif>;

    /** Évènement d'enregistrement des étapes */
    @Output()
    enregistrementEffectue = new EventEmitter<number[]>();

    /** Liste des options à afficher en fonction du contexte */
    listeOptionsAfficher?: Array<Motif>;

    /** Settings de l'OD */
    settings: SettingsODState;

    /** Classe par défaut */
    classeDefaut: ClasseEtape = null;

    //Subject pour renseigner automatiquement la destination
    listeAutocompleteDestination$: Map<number, Subject<GeographieView>> = new Map<number, Subject<GeographieView>>();

    /** Indique s'il y a une proposition ou non */
    @Input()
    hasProposition: boolean;

    /** Indique s'il y a déjà une étape ou non */
    @Input()
    hasEtape: boolean;

    /** Segments de WF du DV */
    @Input()
    dvActions: MapAction;

    /** Indique si le profil voyageur est valide */
    @Input() isProfilVoyageurValide: boolean;

    /** Map des fournisseurs disponibles par nature (plusieurs natures en avion/train) */
    mapFournisseurs: Map<TypeNature, FournisseurEtape[]> = new Map<TypeNature, FournisseurEtape[]>();

    /** Map des classes disponibles par nature (plusieurs natures en avion/train) */
    mapClasses: Map<TypeNature, ClasseEtape[]> = new Map<TypeNature, ClasseEtape[]>();

    /** Constructeur */
    constructor(private translateService: TranslateService,
                private odService: ODService,
                private store: Store<AppState>,
                private toastr: ToastrService,
                private dateUtilsService: DateUtilsService,
                @Inject(DOCUMENT) private document: Document) {
    }

    /** Après la vérification du contenu */
    ngAfterContentChecked(): void {
        //Si on a cliqué sur le bouton pour ajouter une étape
        if (this.hasClick) {
            //On récupère la liste des mat-step
            const steps = this.document.querySelectorAll('div .mat-step');

            //S'il y a autant de mat-step que d'étapes, c'est que l'ajout a enfin eu lieu côté material
            if ((steps.length - 1) == this.listeEtapes.length) {
                //On peut diriger sur la step qui vient d'être créée sans se prendre une erreur angular sur le lifecycle
                this.stepper.next();

                //On retire le tag du click vu que c'est bon, on a géré la sauce
                this.hasClick = false;
            }
        }
    }

    /**
     * Après l'initialisation de la vue
     */
    ngAfterViewInit() {
        //On indique qu'on a fini l'initialisation de la vue et qu'on peut enfin vérifier la validité des formulaires
        setTimeout(() => this.isTimeToCheck = true);
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //On récupère le SBT principal du regroupement
        this.sbt = this.niveauNature.getSbtPrincipal();

        //Sélection du paramétrage
        this.store.select(state => state.settings?.[TypePortee.OD]).subscribe(settings => this.settings = settings);

        //Si on ouvre une étape existante
        if (this.etapeExistante) {
            this.origineBackup = this.etapeExistante.origine ? { ...this.etapeExistante.origine} : null;
            this.destinationBackup = this.etapeExistante.destination ? { ...this.etapeExistante.destination} : null;

            //On ajoute l'étape à la liste des étapes
            this.pushEtape(this.etapeExistante);

            //On calcule les convenances disponibles
            this.updateConvenanceEtNuitees(this.etapeExistante, true);
            this.updateConvenanceEtNuitees(this.etapeExistante, false);
        } else {
            //Si on est sur une nouvelle étape, on initialise la liste
            this.pushNewEtape(this.od.heureDepart, this.od.heureRetour);
        }

        //On récupère la liste des options à afficher en fonction du type de véhicule sélectionné (si véhicule)
        this.listeOptionsAfficher = this.listeOptionMotifs?.filter(opt => opt.typeVehicule == TypeVehicule.getFromTypeNature(this.niveauNature.nature));

        //On initialise l'autocomplete des types de départ
        this.listeTypeDepart = [{
            libelle: this.translateService.instant('od.voyage.travel.departLe'),
            valeur: TypeDepart.DEPART
        }, {
            libelle: this.translateService.instant('od.voyage.travel.arriveLe'),
            valeur: TypeDepart.ARRIVEE
        }];

        this.listeTypeRetour = [{
            libelle: this.translateService.instant('global.aucun'),
            valeur: TypeDepart.AUCUN
        }, {
            libelle: this.translateService.instant('od.voyage.travel.departLe'),
            valeur: TypeDepart.DEPART
        }, {
            libelle: this.translateService.instant('od.voyage.travel.arriveLe'),
            valeur: TypeDepart.ARRIVEE
        }];

        this.listeTypeDocument = [{
            valeur: TypePresta.AVION,
            libelle: this.translateService.instant(TypePresta.cleTraduction(TypePresta.AVION))
        }, {
            valeur: TypePresta.TRAIN,
            libelle: this.translateService.instant(TypePresta.cleTraduction(TypePresta.TRAIN))
        }, {
            valeur: TypePresta.HOTEL,
            libelle: this.translateService.instant(TypePresta.cleTraduction(TypePresta.HOTEL))
        }, {
            valeur: TypePresta.VOITURE_DE_LOCATION,
            libelle: this.translateService.instant(TypePresta.cleTraduction(TypePresta.VOITURE_DE_LOCATION))
        }, {
            valeur: TypePresta.DOCUMENT,
            libelle: this.translateService.instant(TypePresta.cleTraduction(TypePresta.DOCUMENT))
        }, {
            valeur: TypePresta.AUTRE,
            libelle: this.translateService.instant(TypePresta.cleTraduction(TypePresta.AUTRE))
        }];

        //S'il y a possibilité de OFFLINE
        if (this.niveauNature.listeSbt.some(sbt => sbt.typeAiguillage === TypeAiguillage.OFFLINE)) {

            //Initialisation des options de trajet en fonction de sa nature
            if ([TypeNature.TRAIN, TypeNature.AVION, TypeNature.AVION_TRAIN].includes(this.niveauNature.nature)) {
                //Options de trajet disponibles pour les avions et trains
                this.listeOptionsTrajet = [{
                    id: IdOptionEtape.TRAJET_DIRECT,
                    libelle: this.translateService.instant('od.voyage.travel.trajetDirect')
                }, {
                    id: IdOptionEtape.TOUS_TRANSPORTS,
                    libelle: this.translateService.instant('od.voyage.travel.tousTransports')
                }];
            } else if (this.niveauNature.nature === TypeNature.HEBERGEMENT) {
                //Options de trajet disponibles pour les hébergements
                this.listeOptionsTrajet = [{
                    id: IdOptionEtape.RESTAURANT,
                    libelle: this.translateService.instant('od.voyage.travel.restaurant')
                }, {
                    id: IdOptionEtape.ARRIVEE_TARDIVE,
                    libelle: this.translateService.instant('od.voyage.travel.arriveeTardive')
                }, {
                    id: IdOptionEtape.PARKING,
                    libelle: this.translateService.instant('od.voyage.travel.parking')
                }, {
                    id: IdOptionEtape.PETIT_DEJ,
                    libelle: this.translateService.instant('od.voyage.travel.petitDej')
                }];
            } else if (this.niveauNature.nature === TypeNature.VOITURE_DE_LOCATION) {
                //Options de trajet disponibles pour les véhicules
                this.listeOptionsTrajet = [{
                    id: IdOptionEtape.GPS,
                    libelle: this.translateService.instant('od.voyage.travel.gps')
                }, {
                    id: IdOptionEtape.BOITE_AUTO,
                    libelle: this.translateService.instant('od.voyage.travel.boiteAuto')
                }];
            }

            //Si on a une étape en input et qu'elle a des options actives
            if (this.etapeExistante?.options?.length > 0) {
                //On récupère l'option dans la liste des options
                this.listeEtapes[0].options.forEach(o => {
                    //On récupère le libellé à afficher
                    o.libelle = this.listeOptionsTrajet.find(ot => ot.id == o.id)?.libelle;
                });
            }

            //On charge les infos spécifiques au SBT
            this.loadSpecifSbt();
        }
    }

    /**
     * Chargement des informations spécifiques au SBT
     */
    loadSpecifSbt() {
        //On récupère la liste des fournisseurs pour la nature
        this.listeFournisseurs = this.mapFournisseurs.get(this.sbt.idNature);

        //Si on n'a pas la liste
        if (this.listeFournisseurs == null) {
            //On charge la liste des fournisseurs
            this.odService.loadFournisseurs(TypeNature[this.sbt.idNature]).pipe(first()).subscribe((result: Result) => {
                this.listeFournisseurs = result.data.listeFournisseurs;
                this.mapFournisseurs.set(this.sbt.idNature, this.listeFournisseurs);
            });
        }

        //on Récupère la liste des classes
        this.listeClasses = this.mapClasses.get(this.sbt.idNature);
        //Si on n'a pas la liste
        if (this.listeClasses == null) {
            //On charge la liste des classes
            this.odService.loadClasses(TypeNature[this.sbt.idNature]).pipe(first()).subscribe((result: Result) => {
                this.listeClasses = result.data.listeClasses;

                this.mapClasses.set(this.sbt.idNature, this.listeClasses);

                //Si on est en offline sur de l'avion ou du train, on récupère la classe par défaut
                if (this.listeClasses?.length > 0 && this.sbt.typeAiguillage === TypeAiguillage.OFFLINE && [TypeNature.AVION, TypeNature.TRAIN].includes(this.sbt.idNature)) {
                    this.classeDefaut = this.listeClasses[0];

                    //Si on n'est pas sur de la visualisation d'étape existante
                    if (!this.etapeExistante) {
                        //On s'assure que toutes les étapes déjà créées ont bien la bonne classe par défaut
                        this.listeEtapes.forEach(e => e.classe = this.classeDefaut);
                    }
                }
            });
        } else {
            //Si on est en offline sur de l'avion ou du train, on récupère la classe par défaut
            if (this.listeClasses?.length > 0 && this.sbt.typeAiguillage === TypeAiguillage.OFFLINE && [TypeNature.AVION, TypeNature.TRAIN].includes(this.sbt.idNature)) {
                this.classeDefaut = this.listeClasses[0];

                //Si on n'est pas sur de la visualisation d'étape existante
                if (!this.etapeExistante) {
                    //On s'assure que toutes les étapes déjà créées ont bien la bonne classe par défaut
                    this.listeEtapes.forEach(e => e.classe = this.classeDefaut);
                }
            }
        }
    }

    /**
     * Méthode d'ajout d'une étape à la liste des étapes
     *
     * @param etape Étape existante à ajouter
     */
    pushEtape(etape: SaisieEtapeDTO) {
        //On l'ajoute à la liste des étapes
        this.listeEtapes.push(etape);

        //Pour les voitures de location
        if (this.niveauNature.nature === TypeNature.VOITURE_DE_LOCATION) {
            //On initialise le subject qui permet de gérer la completion automatique de la destination
            this.listeAutocompleteDestination$.set(this.listeEtapes.length - 1, new Subject<GeographieView>());
        }

        //On précise que maintenant il y a une étape
        this.hasEtape = true;
    }

    /**
     * Méthode de création d'une étape ET ajout de celle-ci à la liste des étapes
     *
     * @param heureDepart Heure à mettre pour la création d'une étape
     */
    pushNewEtape(heureDepart: string = null, heureRetour: string = null) {
        //Si on est sur une nouvelle étape, on la crée puis l'ajoute à la liste
        this.pushEtape(new SaisieEtapeDTO(this.sbt, moment(this.od.depart_le), moment(this.od.retour_le), heureDepart, heureRetour, this.classeDefaut, !this.hasEtape));
    }

    /** Méthode pour revenir à la popup de sélection du type de trajet */
    goBack() {
        this.retourArriere.emit();
    }

    /** Méthode d'ajout d'une étape dans les steps */
    addEtape() {
        //On vérifie que le clic a été fait sur le step d'ajout d'une étape
        if (this.stepper.selected.state == 'ajout') {
            //On va sélectionner le step précédent sinon on a un problème d'index (vu qu'ils vont être recalculés)
            this.stepper.previous();

            //On marque le step d'ajout d'étape comme intacte (pour garder le logo)
            this.stepAjout.interacted = false;

            //On ajoute une étape
            this.pushNewEtape();

            //On indique qu'on a cliqué pour ajouter une étape (la gestion continue dans le ngAfterContentChecked)
            this.hasClick = true;

            //Si la première étape avait déclaré une convenance retour ou aller/retour
            if (this.listeEtapes[0].typeConvenance.valeur == TypeConvenance.AllerRetour || this.listeEtapes[0].typeConvenance.valeur == TypeConvenance.Retour) {
                //On l'enlève, car on ne gère pas les retours en multi-étape
                this.listeEtapes[0].typeConvenance = null;
            }

            //Si on n'est pas sur une voiture de location ni un hébergement
            if (![TypeNature.VOITURE_DE_LOCATION, TypeNature.HEBERGEMENT].includes(this.niveauNature.nature)) {
                //On retire l'éventuelle date de retour (impossible en multi-étape).
                this.listeEtapes[0].jourDepartRetour = null;
                this.listeEtapes[0].heureDepartRetour = null;
            }

            //On s'assure qu'il n'y ait plus de convenance possible sur le retour
            this.updateConvenanceEtNuitees(this.listeEtapes[0], false);
        }
    }

    /** Méthode de suppression d'une étape dans le stepper */
    removeEtape(index: number) {
        this.listeEtapes.splice(index, 1);
        this.stepper.previous();
    }


    /**
     * Calcule le message d'erreur d'une étape
     *
     * @param etapeForm Formulaire de l'étape concernée
     */
    getErrorMessage(etapeForm: NgForm): string {
        let listeErreurs: string[] = [];

        //Si on a bien le contrôle du formulaire
        if (etapeForm?.controls) {

            //On parcourt tous les champs du formulaire
            for (const name in etapeForm.controls) {

                //Si le champ est invalide
                if (etapeForm.controls[name].invalid) {
                    //Traitement en fonction du champ en erreur
                    switch (name) {
                        case 'origine' :
                            listeErreurs.push(this.translateService.instant('od.voyage.travel.origine'));
                            break;
                        case 'destination' :
                            listeErreurs.push(this.translateService.instant('od.voyage.travel.destination'));
                            break;
                        case 'jourDepartAller' :
                            if (etapeForm.controls['typeDepartAller']) {
                                if (etapeForm.controls['typeDepartAller'].value == this.listeTypeDepart[1].valeur) {
                                    listeErreurs.push(this.translateService.instant('od.voyage.travel.jourArrivee'));
                                } else {
                                    listeErreurs.push(this.translateService.instant('od.voyage.travel.jourDepart'));
                                }
                            } else {
                                if (this.niveauNature.nature === TypeNature.VOITURE_DE_LOCATION) {
                                    listeErreurs.push(this.translateService.instant('od.voyage.travel.jourPriseEnCharge'));
                                } else if (this.niveauNature.nature === TypeNature.HEBERGEMENT) {
                                    listeErreurs.push(this.translateService.instant('od.voyage.travel.jourArrivee'));
                                } else {
                                    //On renvoie le nom du champ avec une majuscule en première lettre
                                    listeErreurs.push(name[0].toUpperCase() + name.slice(1));
                                }
                            }
                            break;
                        case 'jourDepartRetour' :
                            if (this.niveauNature.nature === TypeNature.VOITURE_DE_LOCATION) {
                                listeErreurs.push(this.translateService.instant('od.voyage.travel.jourRestitution'));
                            } else {
                                //On renvoie le nom du champ avec une majuscule en première lettre
                                listeErreurs.push(name[0].toUpperCase() + name.slice(1));
                            }
                            break;
                        case 'heureDepartAller' :
                            if (etapeForm.controls['typeDepartAller']) {
                                if (etapeForm.controls['typeDepartAller'].value == this.listeTypeDepart[1].valeur) {
                                    listeErreurs.push(this.translateService.instant('od.voyage.travel.heureArrivee'));
                                } else {
                                    listeErreurs.push(this.translateService.instant('od.voyage.travel.heureDepart'));
                                }
                            } else {
                                if (this.niveauNature.nature === TypeNature.VOITURE_DE_LOCATION) {
                                    listeErreurs.push(this.translateService.instant('od.voyage.travel.heurePriseEnCharge'));
                                } else if (this.niveauNature.nature === TypeNature.HEBERGEMENT) {
                                    listeErreurs.push(this.translateService.instant('od.voyage.travel.heureArrivee'));
                                } else {
                                    //On renvoie le nom du champ avec une majuscule en première lettre
                                    listeErreurs.push(name[0].toUpperCase() + name.slice(1));
                                }
                            }
                            break;
                        case 'heureDepart' :
                            if (this.niveauNature.nature === TypeNature.VOITURE_DE_LOCATION) {
                                listeErreurs.push(this.translateService.instant('od.voyage.travel.HeureRestitution'));
                            } else {
                                //On renvoie le nom du champ avec une majuscule en première lettre
                                listeErreurs.push(name[0].toUpperCase() + name.slice(1));
                            }
                            break;
                        default:
                            //On renvoie le nom du champ avec une majuscule en première lettre
                            listeErreurs.push(name[0].toUpperCase() + name.slice(1));
                            break;
                    }
                }
            }

            //On concatène tout dans une string et on renvoie l'erreur
            return this.translateService.instant('od.voyage.travel.champsErreur') + listeErreurs.join(', ');
        }

        //Pas de formulaire pas d'erreur, pas d'erreur pas de message
        return '';
    }

    /** Enregistrement des étapes */
    enregistrerEtapes(redirectToSbt: boolean = false) {
        if (redirectToSbt) {
            this.isBookingInProgress = true;
        } else {
            this.isSaving = true;
        }

        this.odService.saveListeEtapes(this.listeEtapes, this.od.idOd).pipe(first(), finalize(() => {
            this.isBookingInProgress = false;
            this.isSaving = false;
        })).subscribe((result: Result) => {
            let listeIdEtapesOnline: number[] = [];

            if (result.codeErreur == 0) {
                //Si on enregistre une étape ONLINE
                if (this.listeEtapes[0].sbt.typeAiguillage === TypeAiguillage.ONLINE) {
                    //On s'assure que l'aiguillage de l'OD soit bien ONLINE
                    this.od.aiguillage = TypeAiguillageLiteral.ONLINE;

                    //S'il n'y a qu'une seule étape
                    if (redirectToSbt && this.listeEtapes.length === 1) {
                        //Redirection sur le SBT
                        listeIdEtapesOnline = result.data.listeIdEtapes;
                    }
                } else {
                    //On s'assure que l'aiguillage de l'OD soit bien OFFLINE
                    this.od.aiguillage = TypeAiguillageLiteral.OFFLINE;
                }

                this.enregistrementEffectue.emit(listeIdEtapesOnline);
            } else {
                TypeCodeErreur.showError(result.codeErreur, this.translateService, this.toastr);
            }
        });
    }

    /** Indique s'il y a au moins un formulaire d'étape invalide */
    isFormInvalid(): boolean {
        //On parcourt tous les formulaires d'étape
        for (const etape of this.listeEtapesForm) {
            //Si on a trouvé un formulaire invalide
            if (etape.invalid) {
                //On renvoie que c'est invalide
                return true;
            }
        }

        //Ici, on a validé que tous les formulaires sont valides (donc pas invalides)
        return false;
    }

    /**
     * Mise à jour de la liste des convenances d'une étape.
     * Recalcule également le nombre max de nuitées.
     *
     * @param etape     Etape à vérifier
     * @param isDepart  true si c'est la date du départ qui est à vérifier, sinon c'est la date de retour
     */
    updateConvenanceEtNuitees(etape: SaisieEtapeDTO, isDepart: boolean) {
        //Date à vérifier pour la convenance
        let date: moment.Moment;
        //Convenance principale en fonction d'aller ou retour
        let convenance: TypeConvenance;
        //Heure pour vérification de la convenance
        let heure: string;

        if (!this.isTimeToCheck) {
            return;
        }

        //Récupération des informations à vérifier/mettre à jour en fonction du départ ou du retour
        if (isDepart) {
            date = etape.jourDepartAller;
            convenance = TypeConvenance.Aller;
            heure = etape.heureDepartAller;
        } else {
            date = etape.jourDepartRetour;
            convenance = TypeConvenance.Retour;
            heure = etape.heureDepartRetour;
        }

        //La convenance ce n'est pas pour tout le monde !
        if ([TypeNature.AVION, TypeNature.TRAIN, TypeNature.AVION_TRAIN].includes(etape.nature)) {
            //Si on est hors des dates de mission
            if (this.isHorsMission(date, heure)) {
                //On met à jour la gestion des convenances
                etape.updateGestionConvenance(isDepart, true);

                //S'il n'y a pas déjà dans la liste la possibilité de faire la convenance
                etape._listeConvenances.add(TypeConvenance.getConvenance(convenance));

                //Si on a la convenance Aller ET Retour
                if (etape.gestionConvenance.isConvAller && etape.gestionConvenance.isConvRetour) {
                    //Si les dates de transport doivent être incluses dans l'OD
                    if (this.settings.isDateTransportIncluse) {
                        //On vide la liste des convenances, la seule qui va être possible, c'est Aller/Retour
                        etape._listeConvenances.clear();

                        //On retire la convenance déjà sélectionnée
                        setTimeout(() => etape.typeConvenance = null);
                    }

                    //On ajoute l'option Aller/Retour
                    etape._listeConvenances.add(TypeConvenance.getConvenance(TypeConvenance.AllerRetour));
                }

                //Si la date du transport doit être dans les dates de la mission (aka si on est hors des dates, la convenance est obligatoire).
                if (this.settings.isDateTransportIncluse) {
                    //On retire la possibilité de ne mettre aucune convenance
                    etape._listeConvenances.delete(TypeConvenance.getConvenance(TypeConvenance.Aucune));
                }

                //Si une convenance était déjà sélectionnée, mais que ce n'était pas celle qu'on vient de traiter
                if (!!etape.typeConvenance && etape.typeConvenance.valeur !== convenance) {
                    //On retire la convenance
                    setTimeout(() => {
                        //Cette condition sert à rouvrir une étape existante avec une convenance Aller/Retour
                        if (etape.typeConvenance?.valeur == TypeConvenance.AllerRetour && etape._listeConvenances.has(TypeConvenance.getConvenance(TypeConvenance.AllerRetour))) {
                            etape.typeConvenance = TypeConvenance.getConvenance(TypeConvenance.AllerRetour);
                        } else {
                            etape.typeConvenance = null
                        }
                    });
                }
            } else {//Si on est dans les dates de la mission

                //On met à jour la gestion des convenances
                etape.updateGestionConvenance(isDepart, false);

                //Si le jour est dans les dates de mission et qu'il y avait la possibilité de faire la convenance, on la retire.
                etape._listeConvenances.delete(TypeConvenance.getConvenance(convenance));

                //On retire la convenance Aller/Retour
                etape._listeConvenances.delete(TypeConvenance.getConvenance(TypeConvenance.AllerRetour));

                //S'il y a toujours une convenance de possible.
                if (etape.gestionConvenance.isConvAller || etape.gestionConvenance.isConvRetour) {
                    //On s'assure que la convenance opposée est bien présente (elle a pu être supprimée en cas d'aller-retour si isDateTransportIncluse)
                    etape._listeConvenances.add(TypeConvenance.getConvenance(etape.gestionConvenance.isConvAller ? TypeConvenance.Aller : TypeConvenance.Retour));
                }

                //Si le champ convenance était défini sur la convenance en cours de traitement ou Aller/Retour (et qu'il y a d'autres possibilités de convenance par exemple Aller ou Retour).
                if ((etape.typeConvenance?.valeur === convenance || etape.typeConvenance?.valeur === TypeConvenance.AllerRetour)
                    && (etape._listeConvenances.size > 1 || etape.listeConvenancesTriee[0]?.valeur !== TypeConvenance.Aucune)) {
                    //On retire la convenance pour obliger l'utilisateur à rechoisir
                    setTimeout(() => etape.typeConvenance = null);
                }
            }

            //S'il n'y a plus rien dans la liste des convenances (on est dans les bornes de la mission pour l'aller et le retour).
            if (etape._listeConvenances.size === 0) {
                //On remet la possibilité de n'avoir aucune convenance
                etape._listeConvenances.add(TypeConvenance.getConvenance(TypeConvenance.Aucune));
            }

            //S'il n'y a plus d'autre option possible que d'avoir aucune convenance, on renseigne cela sur l'étape
            if (etape._listeConvenances.size === 1 && etape.listeConvenancesTriee[0].valeur === TypeConvenance.Aucune) {
                //Et on présélectionne la convenance Aucune (on met un timeout sinon le set passe avant la modification de la liste convenance et ça plait pas au select).
                setTimeout(() => etape.typeConvenance = TypeConvenance.getConvenance(TypeConvenance.Aucune));
            }
        }

        //Lors de la modification de la date de départ d'un hébergement
        if (isDepart && this.niveauNature.nature === TypeNature.HEBERGEMENT && etape.jourDepartAller) {
            //Constructions des objets moment pour le calcul des nuitées
            let ret = moment(this.od.retour_le);
            let depart = moment({year: etape.jourDepartAller.year(), month: etape.jourDepartAller.month(), day: etape.jourDepartAller.date()})
            let retour = moment({year: ret.year(), month: ret.month(), day: ret.date()})

            //On recalcule le nombre maximal de nuits acceptées
            etape.maxNbNuits = retour.diff(depart, 'day');
        }
    }

    /**
     * Indique si la date et l'heure sont en dehors des dates de la mission
     *
     * @param dateOrigine   Date à tester
     * @param heure         Heure à tester
     */
    private isHorsMission(dateOrigine: moment.Moment, heure: string): boolean {
        let resultat: boolean = false;

        //Si on a bien une date de début
        if (dateOrigine) {
            //On clone les dates pour pas les impacter lors des tests
            let date: moment.Moment = dateOrigine.clone();
            let dateDepart: moment.Moment = moment(this.od.depart_le);
            let dateRetour: moment.Moment = moment(this.od.retour_le);

            //Si l'heure est renseignée et au format 00?00
            if (heure && (new RegExp('^\\d\\d.\\d\\d$')).test(heure)) {
                //On met les heures dans les dates qui correspondent pour les tester
                dateDepart.hours(parseInt(this.od.heureDepart.substr(0, 2)));
                dateDepart.minutes(parseInt(this.od.heureDepart.substr(3, 2)));
                dateRetour.hours(parseInt(this.od.heureRetour.substr(0, 2)));
                dateRetour.minutes(parseInt(this.od.heureRetour.substr(3, 2)));

                date.hours(parseInt(heure.substr(0, 2)));
                date.minutes(parseInt(heure.substr(3, 2)));
            }

            //Si la date (avec ou sans les heures, ça dépend) est avant le début, ou après la fin de la mission
            if (date.isBefore(moment(dateDepart)) || date.isAfter(moment(dateRetour))) {
                resultat = true;
            }
        }

        return resultat;
    }

    /**
     * Récupère la date maximale pour une étape
     *
     * @param etape         Etape à traiter
     * @param isAller       true si c'est l'aller qu'on traite
     * @param canConvenance true si la notion de convenance est à prendre en compte (false pour les documents)
     */
    getMaxDateRange(etape: SaisieEtapeDTO, isAller: boolean, canConvenance: boolean = true): moment.Moment {
        let result;

        //Si on peut faire de la convenance ou que la date du transport n'est pas bornée aux dates de la mission
        if (canConvenance || !this.settings.isDateTransportIncluse) {
            //La seule limite est le jour du retour (sauf pour le retour)
            result = isAller ? etape.jourDepartRetour : null;
        } else {
            //Si on ne peut pas déborder des dates de la mission, la borne max est le minimum entre la date de retour et celle de fin de mission
            if (etape.jourDepartRetour && isAller) {
                result = min(moment(this.od.retour_le), etape.jourDepartRetour);
            } else {
                //Si on peut déborder, la seule limite est la date de retour
                result = moment(this.od.retour_le);
            }
        }

        return result;
    }

    /**
     * Récupère la date minimale pour une étape
     *
     * @param etape         Etape à traiter
     * @param isAller       true si c'est l'aller qu'on traite
     * @param canConvenance true si la notion de convenance est à prendre en compte (false pour les documents)
     */
    getMinDateRange(etape: SaisieEtapeDTO, isAller: boolean, canConvenance: boolean = true): moment.Moment {
        let result;

        //Si on peut faire de la convenance ou que la date du transport n'est pas bornée aux dates de la mission
        if (canConvenance || !this.settings.isDateTransportIncluse) {
            //La seule limite est le jour du départ (sauf pour le départ lui-même)
            result = isAller ? null : etape.jourDepartAller;
        } else {
            //Si on ne peut pas déborder des dates de la mission, la borne min est le maximum entre la date de départ et celle de début de mission (sauf pour l'aller lui-même)
            if (etape.jourDepartAller && !isAller) {
                result = max(moment(this.od.depart_le), etape.jourDepartAller);
            } else {
                //Si on peut déborder, la seule limite est la date de départ
                result = moment(this.od.depart_le);
            }
        }

        return result;
    }

    /**
     * Vérifie que la date de départ de l'étape soit valide
     * @param etape Etape à vérifier
     */
    isDateDepartInvalid(etape: SaisieEtapeDTO): boolean {
        let minDate = this.getMinDateRange(etape, true, false);
        let maxDate = this.getMaxDateRange(etape, true, false);

        return !!etape.carteTravel && [TypeChampsCarte.NUM_DU_AU, TypeChampsCarte.NUM_DU_AU_DE_A].includes(etape.carteTravel.typeChamp) && etape.jourDepartAller == null//Si la date est obligatoire mais nulle
            || etape.jourDepartAller != null && (minDate != null && etape.jourDepartAller.isBefore(minDate) || maxDate != null && etape.jourDepartAller.isAfter(maxDate));//Si on a une date, elle doit être dans les bornes (si on a des bornes)
    }

    /**
     * Reset des champs de document offline en fonction du contexte
     * @param etape                 Etape concernée par le reset
     * @param isTypeDocumentChange  Indique si le reset fait suite au changement du type de document
     */
    resetInfosDocument(etape: SaisieEtapeDTO, isTypeDocumentChange: boolean = false) {
        //On va reset les champs qui disparaissent
        if (isTypeDocumentChange) {
            //On supprime la carte de référence
            etape.carteTravel = null;
            etape.destination = null;

            //On reset les champs qui disparaissent suite au changement de type du document
            if (etape.typeDocument === TypePresta.AUTRE) {
                etape.renouvellement = false;
                etape.origine = null;
                etape.jourDepartAller = null;
            } else {
                //Cas spécial des Visa
                if (etape.typeDocument === TypePresta.DOCUMENT) {
                    etape.origine = null;
                }

                //Si on est pas en presta Autre
                if (etape.jourDepartAller == null) {
                    //S'il n'y a pas de jour de départ, on le met par défaut à la date de début de l'OD
                    etape.jourDepartAller = moment(this.od.depart_le);
                }

                if (etape.typeDocument === TypePresta.DOCUMENT) {
                    etape.renouvellement = false;
                }
            }
        } else {
            //Ici, c'est un changement de carte, on ne s'occupe pas des types AUTRE et DOCUMENT
            if (etape.carteTravel.typeChamp === TypeChampsCarte.NUM_DU_AU) {
                etape.destination = null;
            }
        }

        //On recalcule les champs obligatoires
        etape.isRemarqueRequired = etape.typeDocument === TypePresta.AUTRE;
        etape.isOrigineRequired = etape.carteTravel != null && etape.carteTravel.typeChamp === TypeChampsCarte.NUM_DU_AU_DE_A || etape.typeDocument === TypePresta.DOCUMENT;
        etape.isDestinationRequired = etape.carteTravel != null && etape.carteTravel.typeChamp === TypeChampsCarte.NUM_DU_AU_DE_A;
        etape.isJourDepartRequired = etape.carteTravel != null && [TypeChampsCarte.NUM_DU_AU, TypeChampsCarte.NUM_DU_AU_DE_A].includes(etape.carteTravel.typeChamp);
    }

    /**
     * Récupère la clé de traduction d'une prestation
     * @param etape Étape concernée
     */
    getCleTradPresta(etape: SaisieEtapeDTO): string {
        return TypePresta.cleTraduction(etape.typeDocument);
    }

    /**
     * Suppression d'une étape de l'OD
     */
    deleteEtape(): void {
        //Suppression de l'étape existante
        this.odService.deleteEtape(this.etapeExistante.getIdEtape()).subscribe(() => {
            this.enregistrementEffectue.emit(null);
        });
    }

    /**
     *  Méthode appelée lors de la modification du lieu d'origine d'une étape.
     *
     * @param etape Étape modifiée
     * @param index Index de l'étape mise à jour
     */
    onOrigineChanged(etape: SaisieEtapeDTO, index: number): void {
        //Si on est sur une location de véhicule et qu'il y a un lieu d'origine, mais pas de destination
        if (this.niveauNature.nature === TypeNature.VOITURE_DE_LOCATION && etape.destination == null && etape.origine != null) {
            //On détermine la destination au même endroit que l'origine
            let val = Object.assign({}, etape.origine);

            //On met à jour la destination
            etape.destination = val;

            //On envoie la valeur à l'autocomplete pour qu'il soit au courant qu'il y a bien une sélection
            this.listeAutocompleteDestination$.get(index).next(val);
        }
    }

    /**
     * Méthode qui s'occupe de reset la date et l'heure de retour si besoin
     *
     * @param etape Étape concernée par le reset
     */
    resetDateHeureRetour(etape: SaisieEtapeDTO): void {
        if (etape.typeDepartRetour == TypeDepart.AUCUN) {
            etape.jourDepartRetour = null;
            etape.heureDepartRetour = null;
        }
    }

    /**
     * Renvoie le nombre de nuits d'un hébergement
     *
     * @param etape Étape concernée
     */
    getNbNuits(etape: SaisieEtapeDTO): number {
        if (etape.jourDepartAller != null && etape.jourDepartRetour != null) {
            return etape.jourDepartRetour.diff(etape.jourDepartAller, 'day');
        } else {
            return 0;
        }
    }

    /**
     * Indique si le bouton supprimer doit être présent
     */
    canSupprimer(): boolean {
        return this.etapeExistante //Si ce n'est pas une création d'étape
            && (this.sbt.typeAiguillage !== TypeAiguillage.ONLINE && !this.hasProposition || this.sbt.typeAiguillage !== TypeAiguillage.OFFLINE && this.etapeExistante.booked === false) //Si on est en OFF et qu'il n'y a pas de propositions, ou si on est en ON et que l'étape n'est pas réservée
            && this.dvActions.canSupprimer.possible;// Si on a le droit de supprimer l'étape
    }

    /**
     * Change de SBT, pour passer du ONLINE au OFFLINE et inversement
     *
     * @param sbt Sbt sélectionné
     */
    switchSbt(sbt: SynchroSBTConfigUser) {
        //On récupère l'aiguillage actuel
        const aiguillage = this.sbt.typeAiguillage;

        //On applique le nouveau SBT
        this.sbt = sbt;

        //Si on était en OFFLINE
        if (aiguillage === TypeAiguillage.OFFLINE) {
            //On supprime les étapes qui ne sont pas la première (pas de multi-étape en ONLINE)
            this.listeEtapes.splice(1);
        } else {
            //Si on passe en OFFLINE, on charge le spécifique SBT
            this.loadSpecifSbt();
        }

        //On met à jour le SBT de l'étape en cours de traitement
        this.listeEtapes[0].sbt = sbt;
        this.listeEtapes[0].nature = sbt.idNature;
    }

    /**
     * Indique s'il faut afficher le bouton de changement de SBT
     */
    showBoutonSwitchSbt(): boolean {
        //Vrai si on n'est pas sur une étape déjà existante, et qu'il y a plusieurs aiguillages disponibles
        return !this.etapeExistante && this.niveauNature.listeSbt.length > 1;
    }

    /**
     * Renvoie la liste des SBT disponibles pour changement
     */
    getListeSbtForButton(): Array<SynchroSBTConfigUser> {
        //Récupère la liste des SBT disponibles dans l'aiguillage inverse de l'actuel
        return this.niveauNature.listeSbt.filter(sbt => sbt.typeAiguillage !== this.sbt.typeAiguillage);
    }

    /**
     * Renvoie le libelle de la nature du SBT pour affichage dans le bouton, s'il est nécessaire
     *
     * @param sbt SBT concerné
     */
    getLibelleNatureSbt(sbt: SynchroSBTConfigUser): string {
        //S'il y a plusieurs SBT disponibles pour changement, on renvoie le libelle de la nature
        return this.getListeSbtForButton().length > 1 ? ' - ' + this.translateService.instant(TypeNature.traduction(sbt.idNature)) : '';
    }
}