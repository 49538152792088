import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {first} from "rxjs/operators";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {ReferentielDto} from "@domain/admin/referentiels/referentielDto";

/**
 * Service de gestion des référentiels
 */
@Injectable()
export class ReferentielsService {
    /**
     * Constructeur
     *
     * @param http pour permettre de réaliser les requêtes
     */
    constructor(private http: HttpClient) {};

    /**
     * Chargement d'un référentiel
     *
     * @param referentiel Informations du référentiel à récupérer (id et type suffisent)
     */
    loadReferentiel(referentiel: ReferentielDto): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Referentiel/loadReferentiel`,referentiel).pipe(first());
    }

    /**
     * Sauvegarde d'un référentiel
     *
     * @param referentiel Référentiel à créer ou à modifier
     */
    saveReferentiel(referentiel: ReferentielDto): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Referentiel/saveReferentiel`,referentiel).pipe(first());
    }

    /**
     * Suppression d'un référentiel
     *
     * @param referentiel Référentiel à supprimer
     */
    deleteReferentiel(referentiel: ReferentielDto): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Referentiel/deleteReferentiel`,referentiel).pipe(first());
    }
}