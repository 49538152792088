import {NgModule} from "@angular/core";
import {MatDividerModule} from "@angular/material/divider";
import {Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {TranslateModule} from '@ngx-translate/core';
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {LoginService} from "@share/login/login.service";
import {ShareModule} from "@share/share.module";
import {ProfilService} from "../../../profil/profil.service";
import {MatRadioModule} from "@angular/material/radio";
import {ConditionsAlertesComponent} from "./alertes/conditions-alertes.component";
import {ConditionsVisibiliteComponent} from "./conditions-visibilite/conditions-visibilite.component";
import {WorkflowConditionsComponent} from "./workflow-conditions.component";

export const workflowConditionsRoutes : Routes = [
    {
        path: 'Alertes',
        component: ConditionsAlertesComponent,
        canActivate: [AdminGuardProvider]
    }, {
        path: 'Conditions',
        component: ConditionsVisibiliteComponent,
        canActivate: [AdminGuardProvider]
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'Alertes'
    }, {
        path: '**',
        redirectTo: 'Alertes'
    }
];

@NgModule({
    imports: [ShareModule, PreviewAlerteModule, MatDividerModule, TranslateModule, MatRadioModule],
    declarations: [
        WorkflowConditionsComponent,ConditionsAlertesComponent,ConditionsVisibiliteComponent
    ],
    entryComponents: [],
    exports: [
        ConditionsAlertesComponent,ConditionsVisibiliteComponent
    ],
    providers: [ProfilService, LoginService]
})
export class WorkflowConditionsModule {
}
