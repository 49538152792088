import {Injectable} from '@angular/core';
import {HttpErrorResponse,HttpEvent,HttpHandler,HttpInterceptor,HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import {Store} from '@ngrx/store';

import {AppState} from '@domain/appstate';
import {of,throwError} from "rxjs";
import {SESSION_EXPIRED} from "@reducers/session";
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";

@Injectable()
export class DefaultRequestInterceptor implements HttpInterceptor {
	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private router: Router) {
	}

	intercept(request: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
		//Interception des erreurs lors de la réponse du serveur
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				const delog: boolean = error.url.includes('?') && error.url.split('?')[1].split('&').map(param => {
					const paramValue = param.split('=');
					return paramValue.length == 2 && paramValue[0] === 'error' && paramValue[1] === 'deLog';
				}).includes(true);

				//Vérification du code d'erreur 401 Unauthorized utilisé pour notifier que la session (serveur) est expirée
				if ((delog || error.status === 401) && !this.router.isActive('/Login',false)) {
					//Demande du reset de la session (client) via le store
					this.store.dispatch({
						type: SESSION_EXPIRED
					});

					//Retour d'une valeur nulle pour catcher l'erreur et éviter d'afficher le message d'erreur générique
					return of(null);
				} else {
					//Renvoi de l'erreur
					return throwError(error);
				}
			})
		)
	}
}
