import {Component,Input,OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from "@angular/router";
import {OMPService} from "@components/omp/omp.service";
import {AlerteComponent} from '@components/workflow/alerte.component';
import {AppState} from '@domain/appstate';
import {ListViewItem} from '@domain/common/list-view/list-view-item';
import {ListeOd} from '@domain/od/liste-od';
import {SettingsODState} from '@domain/settings/settings';
import {TypeProfil} from '@domain/user/user';
import {TypePortee} from '@domain/workflow/workflow';
import {environment} from '@environments/environment';
import {Store} from '@ngrx/store';
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {first} from "rxjs/operators";

@Component({
	host: {'data-test-id': 'od-list-item'},
	templateUrl: './od-list-item.component.html'
})
export class OdListItemComponent extends ListViewItem<ListeOd> implements OnInit {

	/** Elément courant */
	@Input() data: ListeOd;

	/** Paramétrage */
	settings: SettingsODState;

	/** Profil de l'utilisateur connecté **/
	fonction: TypeProfil;

	/** Accès à l'enum dans la vue */
	readonly TypeProfil = TypeProfil;

	/** URL de base */
	baseUrl: string = environment.baseUrl;

	/**
	 * Constructeur
	 */
	constructor(protected router: Router,
				protected store: Store<AppState>,
				protected matDialog: MatDialog,
				protected ompService: OMPService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit(): void {
		//Sélection du paramétrage
		this.store.select(state => state.settings?.[TypePortee.OD]).pipe(filterFirstNotNull()).subscribe(settings => this.settings = settings);

		//Sélection du profil de l'utilisateur
		this.store.select(state => state?.session?.user?.fonction).pipe(first()).subscribe(fonction => this.fonction = fonction);
	}

	/**
	 * Ouverture d'un élément
	 */
	navigateTo(): void {
		//Navigation vers l'élément
		this.router.navigate(['OD',this.data?.getId()]);
	}

	/**
	 * Ouverture d'un OMP
	 */
	openOmp(idOmp: number): void {
		//Navigation vers l'élément
		this.ompService.navigateToOMP(idOmp);
	}

	/**
	 * Affichage de la popup de la liste des alertes
	 */
	showListeAlertes(): void {
		//Affichage de la popup de la liste des alertes
		this.matDialog.open(AlerteComponent,{
			data: {
				alertes: this.data.listeAlertes.listeAlertes
			}
		});
	}

	/** Getter pour le mode Collaborateur */
	get isCollabMode(): boolean {
		return this.fonction == TypeProfil.COLLABORATEUR;
	}
}
