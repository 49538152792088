<form autocomplete="off" #saisieForm="ngForm">
    <div class="row">
        <div class="col-md-6">
            <div class="row" [ngClass]="{ 'has-error': rue.invalid }">
                <label class="col-md-5" [ngClass]="{'required': idPortee !== TypePortee.ADM}"><span [translate]="'lieu.numeroEtVoie'"></span></label>
                <div class="col-md-7">
                    <mat-form-field class="d-flex">
                        <input matInput [(ngModel)]="localisation.rue" #rue="ngModel" (ngModelChange)="setAdresseModifiee()" name="rue" maxlength="2000" autocomplete="off"
                               [required]="idPortee !== TypePortee.ADM"/>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <label class="col-md-5"><span [translate]="'lieu.codePostal'"></span></label>
                <div class="col-md-7">
                    <mat-form-field class="d-flex">
                        <input matInput [(ngModel)]="localisation.codePostal" (ngModelChange)="setAdresseModifiee();" name="codePostal" maxlength="2000" autocomplete="off" />
                    </mat-form-field>
                </div>
            </div>
            <!-- Le choix  de la visibilté est possible dans le cas d'une saisie d'adresse pro/perso sur un profil user, par un admin ou sous-admin -->
            <div class="row" [hidden]="idPortee !== TypePortee.ADM || !typeAdresse">
                <label class="col-md-5" translate>lieu.visibilite.title</label>
                <div class="col-md-7 d-flex flex-row align-items-center">
                    <mat-checkbox color="primary" [(ngModel)]="isAdressePublique" name="adressePublique" [disabled]="!localisation.rue" (click)="isVisibiliteDefinie=true"></mat-checkbox>
                    <span class="mb-2">&nbsp;{{ 'lieu.visibilite.label' | translate }}</span>
                    <mat-icon class="ml-2 mb-2" matSuffix [matTooltip]="'lieu.visibilite.tooltip' | translate" matTooltipPosition="right">info</mat-icon>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row" [ngClass]="{ 'has-error': inputVille.invalid }">
                <label class="col-md-5 required"><span [translate]="'lieu.ville'"></span></label>
                <div class="col-md-7">
                    <mat-form-field class="d-flex">
                        <input matInput #inputVille="ngModel" [(ngModel)]="localisation.ville" (ngModelChange)="setAdresseModifiee();" name="ville" maxlength="2000" autocomplete="off" required />
                    </mat-form-field>
                </div>
            </div>
            <div class="row" [ngClass]="{ 'has-error': autocompletePays.invalid }">
                <label class="col-md-5 required"><span [translate]="'lieu.pays'"></span></label>
                <div class="col-md-7">
                    <autocomplete type="geographie" ngDefaultControl #autocompletePays="ngModel" name="saisiePays" required="true"
                                  [filter]="{ listeTypes: ['PAYS'] }" [(ngModel)]="saisiePays" floatLabel="never"
                                  (onSelect)="onPaysChange($event);"></autocomplete>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 text-right">
            <button mat-flat-button color="primary" (click)="validerSaisie()" [disabled]="isLoading || isFormSaisieInvalid">
                <span *ngIf="!isLoading" translate>global.actions.valider</span>
                <mat-spinner class="m-5" diameter="28" *ngIf="isLoading"></mat-spinner>
            </button>
        </div>
    </div>
</form>
