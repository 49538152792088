import {NgModule} from '@angular/core';
import {StatutApplicationComponent} from "./statut-application.component";
import {StatutWorkflowService} from "@services/admin/statut-workflow/statut-workflow.service";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressBarModule} from "@angular/material/progress-bar";

@NgModule({
    declarations: [StatutApplicationComponent],
    imports: [CommonModule,TranslateModule,MatIconModule,MatProgressBarModule],
    exports: [StatutApplicationComponent],
    providers: [StatutWorkflowService]
})
export class StatutApplicationModule {}
