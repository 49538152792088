import {NgModule} from '@angular/core';

import {WorkflowService} from './workflow.service';
import {WorkflowActionComponent} from './workflow-action.component';
import {AlerteComponent} from './alerte.component';
import {ShareComponentModule} from '../../share/component/share-component.module';
import {NotificationComponent} from './notification.component';
import {StatutComponent} from './statut.component';
import {ShareModule} from "../../share/share.module";
import {MatExpansionModule} from "@angular/material/expansion";
import {CollabListComponent} from "./collab-list/collab-list.component";
import {CollabListItemComponent} from "./collab-list/collab-list-item.component";
import {ReportListComponent} from "@components/workflow/report-list/report-list.component";
import {ReportingModule} from "@components/reporting/reporting.module";

@NgModule({
    imports: [ShareComponentModule, ShareModule, MatExpansionModule,ReportingModule],
    declarations: [WorkflowActionComponent,AlerteComponent,NotificationComponent,StatutComponent,CollabListComponent,CollabListItemComponent,ReportListComponent],
    exports: [WorkflowActionComponent, NotificationComponent, StatutComponent, AlerteComponent],
    providers: [WorkflowService]
})
export class WorkflowModule {}
