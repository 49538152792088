import {Component,Input,OnDestroy,OnInit} from '@angular/core';
import {ListView} from "@domain/common/list-view";
import {ProfilDonneesCompteBancaireListItemComponent} from "@components/profil/donnees/compteBancaire/profil-donnees-compte-bancaire-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {CompteBancaire} from '@domain/profil/compteBancaire';
import {BehaviorSubject,combineLatest,Subscription} from "rxjs";
import {SettingsGlobalState} from "@domain/settings/settings";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import * as settingsActions from "@reducers/settings";
import {MatDialog} from "@angular/material/dialog";
import {ProfilDonneesAddCompteBancaireComponent} from "@components/profil/donnees/compteBancaire/profil-donnees-add-compte-bancaire.component";
import {CartePaiement} from "@domain/profil/cartePaiement";
import {ProfilDonneesCartePaiementListItemComponent} from "@components/profil/donnees/cartePaiement/profil-donnees-carte-paiement-list-item.component";
import {ProfilService} from "@components/profil/profil.service";
import {filter,first} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {ProfilDonneesAddCartePaiementComponent} from "@components/profil/donnees/cartePaiement/profil-donnees-add-carte-paiement.component";
import {EntrepriseUtilisateursService} from "@components/admin/entreprise/utilisateurs/entreprise-utilisateurs.service";
import {TypeCompteBancaire} from "@domain/comptabilite/compte-bancaire";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";
import {ProfilAlertes} from "@domain/profil/profilAlertes";
import {TypeSaisie} from "@domain/profil/profilVoyageur";
import {User} from "@domain/user/user";
import {TypeFormulaire} from "@domain/profil/DocumentAlerte";
import {DatePipe} from "@angular/common";
import * as moment from "moment";
import {Moment} from "moment";

/**
 * Onglet "Informations bancaires" de l'écran de consultation d'un utilisateur
 */
@Component({
	host: {'data-test-id': 'user-infos-bancaires'},
	selector: 'user-infos-bancaires',
	templateUrl: './user-infos-bancaires.component.html'
})
export class UserInfosBancairesComponent implements OnInit,OnDestroy {
	/** Utilisateur courant */
	@Input() user: User;

	/** L'utilisateur a-t-il une ligne dans ns_collab */
	@Input() isCollabInit: boolean;

	/** Paramètres globaux */
	settings: BehaviorSubject<SettingsGlobalState> = new BehaviorSubject<SettingsGlobalState>(undefined);

	/** Souscription aux paramètres */
	subscription: Subscription = new Subscription();

	/** Liste des comptes bancaires */
	listeComptesBancaires: ListView<CompteBancaire,ProfilDonneesCompteBancaireListItemComponent>;

	/** Liste des cartes de paiement */
	listeCartesPaiement: ListView<CartePaiement,ProfilDonneesCartePaiementListItemComponent>;

	/** Liste des alertes du profil */
	listeAlertes: Alerte[] = [];

	/** Niveau d'alerte du profil */
	niveauAlerteProfil: NiveauAlerte;

	/** Import de l'énum pour le DOM */
	readonly NiveauAlerte = NiveauAlerte;

	/**
	 * Constructeur
	 *
	 * @param store le store de l'application
	 * @param translateService le moteur de traduction
	 * @param matDialog le gestionnaire de popin
	 * @param profilService le service de gestion du profil utilisateur
	 * @param toastrService le toaster
	 * @param userService le service de gestion de l'utilisateur
	 * @param datePipe le pipe de gestion des dates
	 */
	constructor(
		private store: Store<AppState>,
		private translateService: TranslateService,
		private matDialog: MatDialog,
		private profilService: ProfilService,
		private toastrService: ToastrService,
		private userService: EntrepriseUtilisateursService,
		private datePipe: DatePipe
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Chargement des paramètres
		this.store.dispatch({
			type: settingsActions.LOAD_SETTINGS,
			payload: ['Global']
		});

		//Souscription aux paramètres
		this.subscription = this.store.select((state: AppState) => state.settings?.['Global'])
			.subscribe((settings: SettingsGlobalState) => this.settings.next(settings));

		//On attend que les paramètres soient chargés
		this.settings.pipe(filter(settings => !!settings)).subscribe(() => {
			//Initialisation de la liste des comptes bancaires
			this.listeComptesBancaires = new ListView<CompteBancaire,ProfilDonneesCompteBancaireListItemComponent>({
				uri: `/controller/CompteBancaireAdmin/listeCompte?idCollab=${this.user.idUser}`,
				title: this.translateService.instant('profilUser.compteBancaire.title'),
				component: ProfilDonneesCompteBancaireListItemComponent,
				isSimple: true,
				mapResult: (result: Result) => result?.data?.listeCompte,
				listeActions: [{
					icon: 'add',
					onPress: () => this.createCompteBancaire(),
					disabled: !this.isCollabInit,
					tooltip: !this.isCollabInit ? this.translateService.instant('admin.entreprise.utilisateurs.detail.errorCollab') : null
				}],
				extraOptions: {
					open: (compte: CompteBancaire) => this.openCompteBancaire(Object.assign({},compte))
				}
			});

			//Initialisation de la liste des cartes de paiement
			this.listeCartesPaiement = new ListView<CartePaiement,ProfilDonneesCartePaiementListItemComponent>({
				uri: `/controller/CarteBancaireAdmin/listeCartePaiement/${this.user.idUser}`,
				title: this.translateService.instant('profilUser.cartePaiement.title'),
				component: ProfilDonneesCartePaiementListItemComponent,
				isSimple: true,
				mapResult: (result: Result) => result?.data?.listeCartePaiement,
				listeActions: [{
					icon: 'add',
					onPress: () => this.openCartePaiement(Object.assign({},new CartePaiement(this.user.idUser))),
					disabled: !this.isCollabInit,
					tooltip: !this.isCollabInit ? this.translateService.instant('admin.entreprise.utilisateurs.detail.errorCollab') : null
				}],
				extraOptions: {
					open: (carte: CartePaiement) => this.openCartePaiement(carte),
					settings: this.settings.getValue().profilAlerteConfig
				}
			});
		});

		//Attente du chargement des listes pour charger les alertes
		combineLatest([this.listeComptesBancaires.loaded,this.listeCartesPaiement.loaded])
			.pipe(first())
			.subscribe(() => this.refreshAlertes());
	}

	/**
	 * Création d'un compte bancaire
	 */
	createCompteBancaire(): void {
		//Appel au service
		this.userService.loadDefaultCompte()
			.pipe(first())
			.subscribe((result: Result) => {
				//Vérification du result
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Initialisation d'un compte bancaire avec les infos par défaut
					let compte: CompteBancaire = new CompteBancaire();
					compte.typeCompte = TypeCompteBancaire.IBAN;
					compte.beneficiaire = this.user.nom + " " + this.user.prenom;
					compte.idPays = result.data.pays.id;
					compte.devise = result.data.devise.code;

					//Ouverture de ce compte
					this.openCompteBancaire(compte);
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Ouverture d'un compte bancaire pour modification
	 *
	 * @param compte le compte bancaire
	 */
	openCompteBancaire(compte: CompteBancaire): void {
		//Ouverture de la popin
		this.matDialog.open(ProfilDonneesAddCompteBancaireComponent,{
			data: {
				compte: compte,
				compteParDefaut: this.listeComptesBancaires.data?.listeResultats.find(c => c.defaut),
				settings: this.settings.getValue(),
				idUser: this.user.idUser
			},
			width: '80%'
		}).afterClosed().subscribe((refresh: boolean) => {
			//S'il est nécessaire de rafraîchir
			if (refresh) {
				//Abonnement à l'évènement de fin de rafraichissement de la liste
				this.listeComptesBancaires.loaded
					.pipe(first())
					.subscribe(() => {
						//Rafraichissement des alertes
						this.refreshAlertes();
					});

				//Déclenchement du rafraîchissement de la liste
				this.listeComptesBancaires.refresh();
			}
		})
	}

	/**
	 * Ouverture d'une carte de paiement pour modification
	 *
	 * @param carte la carte de paiement
	 */
	openCartePaiement(carte: CartePaiement): void {
		//Chargement de la carte de paiement
		this.profilService.loadCartePaiement(carte,this.user.idUser)
			.pipe(first())
			.subscribe((result: Result) => {
				//Vérification du résultat
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Ouverture de la popin
					this.matDialog.open(ProfilDonneesAddCartePaiementComponent,{
						data: {
							listeContrat: result.data.listeContrat,
							listeSynchro: result.data.listeSynchro,
							carte: result.data.cartePaiement ?? carte,
							configCompte: this.settings.getValue().configCompte,
							idUser: this.user.idUser
						},
						width: '80%'
					}).afterClosed().subscribe((refresh: boolean) => {
						//S'il est nécessaire de rafraîchir
						if (refresh) {
							//Abonnement à l'évènement de fin de rafraichissement de la liste
							this.listeCartesPaiement.loaded
								.pipe(first())
								.subscribe(() => {
									//Rafraichissement des alertes
									this.refreshAlertes();
								});

							//Rafraîchissement de la liste
							this.listeCartesPaiement.refresh();
						}
					});
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Rafraîchissement des alertes
	 */
	refreshAlertes(): void {
		//Initialisation
		this.listeAlertes = [];
		this.niveauAlerteProfil = null;

		//Chargement des alertes
		this.profilService.loadProfilAlertes(this.user.idUser)
			.pipe(first())
			.subscribe((result: Result) => {
				//Vérification du result
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Gestion des alertes à afficher
					this.handleAlertesAff(result.data as ProfilAlertes);
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Gestion de l'affichage des alertes
	 *
	 * @param profilAlertes les alertes du profil
	 */
	handleAlertesAff(profilAlertes: ProfilAlertes): void {
		//Réinitialisation des niveaux d'alertes des listes
		this.listeComptesBancaires.alertLevel = undefined;
		this.listeCartesPaiement.alertLevel = undefined;

		if (profilAlertes?.saisieObligatoire?.listeSaisieManquante) {
			//On filtre la liste pour ne garder que les alertes sur les comptes bancaires
			profilAlertes.saisieObligatoire.listeSaisieManquante = profilAlertes.saisieObligatoire.listeSaisieManquante.filter(saisie => saisie === TypeSaisie.COMPTE_BANCAIRE);
		}

		if (profilAlertes?.listeDocumentEchu) {
			//On filtre la liste pour ne garder que les alertes sur les cartes bancaires
			profilAlertes.listeDocumentEchu = profilAlertes.listeDocumentEchu.filter(doc => doc.typeFormulaire === TypeFormulaire.CARTE_PAIEMENT);
		}

		if (profilAlertes?.listeDocumentEcheance) {
			//On filtre la liste pour ne garder que les alertes sur les cartes bancaires
			profilAlertes.listeDocumentEcheance = profilAlertes.listeDocumentEcheance.filter(doc => doc.typeFormulaire === TypeFormulaire.CARTE_PAIEMENT);
		}

		//Gestion des saisies manquantes et des documents échus
		if (profilAlertes?.saisieObligatoire?.listeSaisieManquante?.length || profilAlertes?.listeDocumentEchu?.length) {
			//Définition du niveau d'alerte
			this.niveauAlerteProfil = Math.max(this.niveauAlerteProfil ?? NiveauAlerte.ERROR,NiveauAlerte.ERROR);

			//S'il y a des saisies manquantes
			if (profilAlertes?.saisieObligatoire?.listeSaisieManquante?.length) {
				//Passage de la liste des comptes bancaires en erreur
				this.listeComptesBancaires.alertLevel = NiveauAlerte.ERROR;

				//Parcours des saisies manquantes
				for (const saisie of (profilAlertes?.saisieObligatoire?.listeSaisieManquante ?? [])) {
					//Ajout de l'alerte
					this.listeAlertes.push(new Alerte({
						titre: null,
						message: this.translateService.instant("profil.alerte.obligatoire." + saisie),
						niveau: NiveauAlerte.ERROR,
					}));
				}
			}

			//S'il y a des documents échus
			if (profilAlertes?.listeDocumentEchu?.length) {
				//Passage de la liste des cartes bancaires en erreur
				this.listeCartesPaiement.alertLevel = NiveauAlerte.ERROR;

				//Parcours des documents échus
				for (const document of (profilAlertes?.listeDocumentEchu ?? [])) {
					//Récupération de la date
					const dateEcheance: Date = new Date(document.dateEcheance);

					//Ajout de l'alerte
					this.listeAlertes.push(new Alerte({
						titre: null,
						message: this.translateService.instant("profil.alerte.document." + document.typeDocument)
							+ this.translateService.instant("profil.alerte.echu." + document.typeFormulaire,{
								libelle: document.libelle,
								numero: document.numero,
								date: this.datePipe.transform(dateEcheance,'shortDate')
							}),
						niveau: NiveauAlerte.ERROR
					}));
				}
			}

		}

		//Gestion des documents arrivant à échéance
		if (profilAlertes?.listeDocumentEcheance?.length) {
			//Définition du niveau d'alerte
			this.niveauAlerteProfil = Math.max(this.niveauAlerteProfil ?? NiveauAlerte.WARNING,NiveauAlerte.WARNING);

			//Définition du niveau d'alerte de la liste des cartes bancaires
			this.listeCartesPaiement.alertLevel = Math.max(this.listeCartesPaiement.alertLevel ?? NiveauAlerte.WARNING,NiveauAlerte.WARNING);

			//Parcours des documents arrivant à échéance
			for (const document of profilAlertes?.listeDocumentEcheance) {
				//Définition de la date du jour
				const today: Moment = moment();

				//Récupération de la date
				const dateEcheance: Moment = moment(document.dateEcheance);

				//Ajout de l'alerte
				this.listeAlertes.push(new Alerte({
					titre: null,
					message: this.translateService.instant("profil.alerte.document." + document.typeDocument)
						+ this.translateService.instant("profil.alerte.echeance." + document.typeFormulaire,{
							libelle: document.libelle,
							numero: document.numero,
							date: this.datePipe.transform(dateEcheance.toDate(),'shortDate'),
							nbJours: dateEcheance.diff(today,'days') + 1
						}),
					niveau: NiveauAlerte.WARNING
				}));
			}
		}
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy(): void {
		//Désouscription à l'abonnement
		this.subscription.unsubscribe();
	}
}
