/**
 * Types de comparaison
 */
export enum TypeComparaison {
    /** Enumération **/
    LIKE,
    EQUAL,
    NOT_EQUAL,
    GREATER_EQUAL,
    LESS_EQUAL,
    BETWEEN,
    IN,
    NOT_IN
}
