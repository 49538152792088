<h1 mat-dialog-title>
    <span [translate]="'admin.ongletOutils.cadreMouvementWf.titre'"></span>
    <span mat-dialog-close=true><i class="zmdi zmdi-close"></i></span>
</h1>

<mat-dialog-content>
    <form #mouvementWfForm="ngForm">
        <fieldset>
            <div class="row">
                <custom-input ngDefaultControl libelle="admin.ongletOutils.cadreMouvementWf.popup.action" customType="select" [selectOptions]="listeActions"
                              optionValue="type" optionDisplay="libelle" [(ngModel)]="action" name="action" required="true"></custom-input>

                <ng-container *ngIf="(action === TypeAction[TypeAction.VALIDER] || action === TypeAction[TypeAction.INVALIDER]) && data.typePortee !== TypePortee.OD && data.typePortee !== TypePortee.OT">
                    <custom-input ngDefaultControl name="traitements" postLibelle="admin.ongletOutils.cadreMouvementWf.popup.traitementsComptable"
                                  customType="checkbox" [ngModel]="checkTraitements" [(customModel)]="checkTraitements"
                                  [postTooltip]="'admin.ongletOutils.cadreMouvementWf.popup.traitementsTooltip' | translate" rCol="6"></custom-input>
                </ng-container>
            </div>
            <div class="row">
                <custom-input ngDefaultControl name="statut" idName="code" [(ngModel)]="statut" customType="autocomplete"
                              libelle="admin.ongletOutils.cadreMouvementWf.popup.statut"
                              autocompleteType="statut" optionDisplay="libelle" [autocompleteFilter]="{}" required="true"></custom-input>
            </div>
            <div class="row">
                <custom-input ngDefaultControl name="remarque"
                              [(ngModel)]="remarque"
                              customType="textarea" libelle="admin.ongletOutils.cadreMouvementWf.popup.remarque" rCol="10"></custom-input>
            </div>
        </fieldset>
    </form>
</mat-dialog-content>

<div mat-dialog-actions>
    <button mat-stroked-button class="m-r-10" mat-dialog-close=true>{{ "global.actions.annuler" | translate }}</button>
    <button mat-flat-button color="primary" (click)="openPopupConfirmation()" [disabled]="mouvementWfForm.invalid">{{"global.actions.confirmer" | translate}}</button>
</div>

<ng-template #messageConfirmation>
<span [translate]="'admin.ongletOutils.cadreMouvementWf.popup.messageConfirmation'"></span><br>
    <br>
<div class="bold">
    <span [translate]="'admin.ongletOutils.cadreMouvementWf.popup.confirmationAction'" [translateParams]="{action: libelleAction}"></span><br>
    <ng-container *ngIf="action === TypeAction[TypeAction.VALIDER] || action === TypeAction[TypeAction.INVALIDER]">
        <span [translate]="'admin.ongletOutils.cadreMouvementWf.popup.confirmationTraitements'" [translateParams]="{check: traitementsSelected}"></span><br>
    </ng-container>
    <span [translate]="'admin.ongletOutils.cadreMouvementWf.popup.statutConfirmation'" [translateParams]="{libelle: statut.libelle}"></span><br>
</div>
    <br>
<span [translate]="'admin.ongletOutils.cadreMouvementWf.popup.messageWarning'" class="error"></span>
</ng-template>