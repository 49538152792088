import {AutocompleteOptions} from '../autocomplete';
import {Population} from "@domain/workflow/population";
import {TranslateService} from '@ngx-translate/core';
import {Filter} from '@domain/common/list-view'
import {SearchType} from '@domain/common/list-view/sorting';

/**
 * Options des populations
 */
export default class PopulationOptions implements AutocompleteOptions {
	/**
	 * Définition de l'URL
	 */
	url(): string {
		return "/controller/Population/getPopulations";
	}

	/**
	 * Affichage d'un élément
	 *
	 * @param item la population
	 */
	displayItem(item: Population): string {
		return item?.libelle;
	}

	/**
	 * Filtres disponibles
	 *
	 * @param translateService le moteur de traduction
	 */
	listeFilters(translateService: TranslateService): Array<Filter> {
		//Liste des filtres
		return [
			{
				title: translateService.instant('filter.reference'),
				clef: 'reference',
				isDefault: true
			},{
				title: translateService.instant('filter.libelle'),
				clef: 'libelle',
				isDefault: true
			}
		];
	}

	/**
	 * Ordre de tri des résultats
	 */
	getOrderBy(): string {
		return "libelle";
	}

	/** Mode de recherche */
	searchType = SearchType.CONTAINS;
}