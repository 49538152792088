import {Component,Input,OnInit} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";
import {TranslateService} from "@ngx-translate/core";
import {ListeAlertes} from "@domain/common/alerte/listeAlertes";
import {DematService} from "@services/admin/parametre/demat/demat.service";
import {ActivatedRoute,Router} from "@angular/router";
import {getDematIconClass,getStatutArchivageIcon,StatutArchivage} from "@domain/comptabilite/statut-archivage";
import {LotArchivage} from "@domain/demat/lot-archivage";

/**
 * Composant d'affichage d'un lot de la liste des lots comptables.
 *
 * @author François Turin
 * @date 27/06/2023
 */
@Component({
	host: {'data-test-id': 'archivage-lot-list-item'},
	templateUrl: './archivage-lot-list-item.component.html'
})
export class ArchivageLotListItemComponent extends ListViewItem<any> implements OnInit {
	protected readonly StatutArchivage = StatutArchivage;

	/** Lot courant */
	@Input() data: LotArchivage;

	/** Nom de l'icône du statut d'archivage */
	statutArchivageIcon: string;

	/** Nom de la classe css de l'icône du statut d'archivage */
	statutArchivageClass: string;

	/**
	 * Constructeur.
	 *
	 * @param router Router de l'application
	 * @param route route courante
	 * @param tranlsateService service de traduction
	 * @param dematService service de gestion de la dématérialisation
	 */
	constructor(private router: Router,
				private route: ActivatedRoute,
				private tranlsateService: TranslateService,
				private dematService: DematService) {
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de l'icône et la classe CSS en fonction du statut d'archivage
		if (this.data.statutArchivage) {
			this.statutArchivageIcon = getStatutArchivageIcon(this.data.statutArchivage);
			this.statutArchivageClass = getDematIconClass(this.data.statutArchivage);
		}

		//Ajout de l'alerte si le nb de PJ archivées n'est pas bon
		if ((this.data.nbPJArchivees ?? 0) < this.data.nbPJTotal) {
			this.data.listeAlertes = new ListeAlertes();
			this.data.listeAlertes.add(new Alerte({
				niveau: NiveauAlerte.ERROR,
				message: this.tranlsateService.instant("admin.parametres.demat.archivage.liste.alertePJTooltip"
				)
			}));
		}
	}

	/**
	 * Indique si le lot peut être archivé.
	 */
	isArchivageAutorise(): boolean {
		return this.dematService.isArchivageAutorise(this.data);
	}

	/**
	 * Indique si le lot est lié à une période budgétaire postérieure au 01/01/2019.
	 */
	isDateLimiteDepassee(): boolean {
		return this.dematService.isDateLimiteDepassee(this.data);
	}

	/**
	 * Tooltip de l'avatar de sélection.
	 */
	get avatarTooltip(): string {
		return this.isDateLimiteDepassee() ? "" : "admin.parametres.demat.archivage.snchroInterdite";
	}

	/**
	 * Ouverture du détail d'archivage d'un lot.
	 */
	goToDetailLot(): void {
		this.dematService.isInDetail = true;
		this.router.navigate(["Lots",{outlets: {detail_lot: [this.data.idLot]}}],{relativeTo: this.route});
	}

	/**
	 * Tooltip de l'icône de dématérialisation.
	 */
	get dematTooltip(): string {
		switch (this.data.statutArchivage) {
			case StatutArchivage.EN_ATTENTE:
				return "admin.parametres.demat.archivage.synchro.enAttente";
			case StatutArchivage.SYNCHRONISE:
				return "admin.parametres.demat.archivage.synchro.synchronise";
			case StatutArchivage.ECHEC:
				return "admin.parametres.demat.archivage.synchro.echec";
			case StatutArchivage.PARTIEL:
				return "admin.parametres.demat.archivage.synchro.partiel";
			default:
				return "";
		}
	}
}
