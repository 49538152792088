import {NgModule} from '@angular/core';

import {CoreComponentsModule} from './core-components.module';

import {PleaseWaitModule} from './please-wait/please-wait.module';
import {InputNumberModule} from '../directive/number/input-number.module';
import {AutocompleteModule} from './autocomplete/autocomplete.module';
import {ListViewModule} from './list-view/list-view.module';
import {ConfirmModule} from './confirmation/confirm.module';
import {FloatingButtonModule} from './floating-button/floating-button.module';
import {RowDataModule} from "./row-data/row-data.module";
import {CustomInputModule} from './custom-input/custom-input.module';
import {AutoFocusModule} from '../directive/autofocus/autofocus.module';
import {PageHeaderModule} from './page-header/page-header.module';
import {FileUploaderModule} from '../directive/file-uploader/file-uploader.module';
import {ThumbModule} from './thumb/thumb.module';
import {ImageViewerModule} from './image-viewer/image-viewer.module';
import {TooltipModule} from '../directive/tooltip/tooltip.module';
import {FillingRowModule} from "./filling-row/filling-row.module";
import {PatternModule} from './pattern/pattern.module';
import {ValidatorModule} from './../directive/validator/validator.module';
import {AdresseModule} from './adresse/adresse.module';
import {CardAlerteModule} from "@share/component/card-alerte/card-alerte.module";
import {AffichageHierarchiqueModule} from "@share/component/affichage-hierarchique/affichage-hierarchique.module";
import {AdminOutilsModule} from "@share/component/outils/admin-outils.module";

@NgModule({
    imports: [CoreComponentsModule
        , AutocompleteModule, ListViewModule, PleaseWaitModule, ConfirmModule, InputNumberModule, FloatingButtonModule, AutoFocusModule, PageHeaderModule, CardAlerteModule, FileUploaderModule, ThumbModule, ImageViewerModule, RowDataModule, TooltipModule, PatternModule, ValidatorModule, AdresseModule, AffichageHierarchiqueModule, AdminOutilsModule
    ],
    declarations: [],
    exports: [CoreComponentsModule
        , AutocompleteModule, ListViewModule, PleaseWaitModule, ConfirmModule, InputNumberModule, FloatingButtonModule, AutoFocusModule, PageHeaderModule, FileUploaderModule, ThumbModule, ImageViewerModule, RowDataModule, PatternModule
        ,CustomInputModule,AutocompleteModule,ListViewModule,PleaseWaitModule,ConfirmModule,InputNumberModule,FloatingButtonModule,AutoFocusModule,CardAlerteModule,FileUploaderModule,ThumbModule,ImageViewerModule, TooltipModule, ValidatorModule, AdresseModule, AffichageHierarchiqueModule,
        FillingRowModule
    ],
})
export class ShareComponentModule {}