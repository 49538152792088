<div class="with-floating-button">
    <div class="content">
        <page-header [title]="'admin.maintenance.logs.title' | translate" [listeItems]="listeTabItems"></page-header>
        <form #form="ngForm">
            <serveur-centralisation [configLoging]="configLoging"></serveur-centralisation>
            <fichier-logs [configLoging]="configLoging"></fichier-logs>
        </form>
        <floating-button [listeActions]="listeActions"></floating-button>
    </div>
</div>
