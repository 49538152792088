<div class="lvi-content">
    <div class="avatar">
        <span [translate]="'admin.bibliotheque.geographie.territoires.avatar'"></span>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="goToDetailTerritoire()">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.code">
                <strong [translate]="'admin.bibliotheque.geographie.territoire.code'"></strong>
                <span>{{ data.code }}</span>
            </li>
            <li>
                <strong [translate]="'admin.bibliotheque.geographie.territoire.visibilite'"></strong>
                <span *ngIf="portees.length === 0" [translate]="'admin.bibliotheque.geographie.territoires.tousModules'"></span>
                <span *ngIf="portees.length > 0"><span *ngFor="let portee of portees; let index = index">{{portee | translate}}<span *ngIf="index !== portees.length -1">, </span></span></span>
            </li>
            <li>
                <strong [translate]="'admin.bibliotheque.geographie.territoire.actif'"></strong>
                <span>{{ (data.actif ? 'admin.bibliotheque.geographie.territoires.isActif' : 'admin.bibliotheque.geographie.territoires.notActif') | translate }}</span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button (click)="deleteTerritoire()" [matTooltip]="'global.actions.supprimer' | translate">
            <i class="nio icon-suppression"></i>
        </button>
    </div>
</div>