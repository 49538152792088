<div class="page-header" [ngClass]="{'success': niveauAlerte == niveauAlerteEnum.SUCCESS, 'warning': niveauAlerte == niveauAlerteEnum.WARNING,'error': niveauAlerte == niveauAlerteEnum.ERROR,'square' : isSquareTab,'profil-user' : niveauAlerte == niveauAlerteEnum.PROFIL_USER,'info' : niveauAlerte == niveauAlerteEnum.NO_CONTROL, 'rgpd' : niveauAlerte == niveauAlerteEnum.RGPD }">
    <div class="d-flex flex-row">
        <mat-card [class]="{'square' : isSquareTab}">
            <span class="left" *ngIf="title">
                <i class="nio icon-transfere clickable" (click)="goBack()" *ngIf="!isPending && (canGoBack || goBackUrl)"></i>
                <mat-spinner *ngIf="isPending" diameter="32" style="margin-right: 10px" [matTooltip]="'global.loading' | translate"></mat-spinner>
                <span>{{ title }}</span>
            </span>
            <mat-tab-group #groupe #matTabGroup animationDuration="0ms" (selectedTabChange)="onSelectedTabChange($event)" [(selectedIndex)]="selectedIndex">
                <mat-tab *ngFor="let item of (listeItems | filterBy:isVisible())" [label]="item.libelle"></mat-tab>
            </mat-tab-group>
            <span class="right" *ngIf="extraInfo" [ngClass]="{ 'clickable': canExtraInfoClick }" matTooltip="{{extraInfoTooltip}}" matTooltipPosition="left" (click)="onExtraInfoClick.emit()">
                <div [hidden]="!statutArchivage || !settings?.dematProbante" class="demat-icon-container" [ngClass]="getDematIconClass()" [matTooltip]="getDematTooltip()" matTooltipPosition="left">
                    <mat-icon class="demat-icon material-icons-outlined">{{ statutArchivageIcon }}</mat-icon>
                </div>
                <mat-icon [hidden]="!canExtraInfoClick">manage_search</mat-icon>
                <span>{{ extraInfo }}</span>
            </span>
        </mat-card>
    </div>
    <div class="alerte-container" *ngIf="listeAlertes?.length">
        <div *ngFor="let alerte of listeAlertes">
            <ng-template [ngTemplateOutlet]="alerte.template"></ng-template>
        </div>
    </div>
</div>