import {TranslateService} from '@ngx-translate/core';
import {Filter} from '@domain/common/list-view';

import {AutocompleteOptions} from '../autocomplete';

/**
 * Options des services
 */
export default class ServiceOptions implements AutocompleteOptions {
	/**
	 * Récupération de l'URL
	 */
	url(filter: any) {
		//URL
		return `/controller/AnalytiqueJson/listerOrga?idCollab=${filter?.idCollab || ''}`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) {
		return (item?.numService ? `${item.numService} - ` : '') + (item?.libelle || '') + (item?.code ? ` (${item.code})` : '');
	}

	/**
	 * Filtres disponibles
	 */
	listeFilters(translateService: TranslateService): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			title: translateService.instant('filter.libelle'),
			clef: 'libelle',
			isDefault: true
		},{
			title: translateService.instant('filter.numeroService'),
			clef: 'numService',
			isDefault: true
		}]
	}
}
