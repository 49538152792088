import {NgModule} from '@angular/core';
import {LotListComponent} from './list/lot-list.component';
import {ComptabiliteService} from "./comptabilite.service";
import {LotListItemComponent} from './list/lot-list-item.component';
import {ShareModule} from "../../share/share.module";
import {WorkflowModule} from "../workflow/workflow.module";
import {AnalytiqueModule} from "../analytique/analytique.module";
import {ReferentielExterneModule} from "../referentiel-externe/referentiel-externe.module";
import {DocumentModule} from "../document/document.module";
import {LotComponent} from './lot.component';
import {LotAddObjectComponent} from "./add/lot-add-object.component";
import {LotGeneraliteComponent} from "./generalite/lot-generalite.component";
import {LotAddNdfItemComponent} from './add/lot-add-ndf-item.component';
import {LotAddFactureItemComponent} from './add/lot-add-facture-item.component';
import {LotAddAvanceItemComponent} from './add/lot-add-avance-item.component';
import {LotAddObjectItemComponent} from "./add/lot-add-object-item.component";


@NgModule({
    imports: [ShareModule, WorkflowModule, AnalytiqueModule, ReferentielExterneModule, DocumentModule],
    declarations: [LotListComponent, LotListItemComponent, LotComponent, LotGeneraliteComponent, LotAddObjectComponent, LotAddNdfItemComponent, LotAddFactureItemComponent, LotAddAvanceItemComponent, LotAddObjectItemComponent],
    entryComponents: [LotListComponent],
    providers: [ComptabiliteService]
})
export class ComptabiliteModule {
}
