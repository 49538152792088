import {TypeFilter} from './type-filter';
import {FilterValue} from './filter-value';
import {TypeComparaison} from './type-comparaison';

/**
 * Classe représentant un filtre
 */
export class Filter {
    /* Données */
    clef: string;
    /** Clef qui, si renseignée, se substitue à la clef originale "clef" pour la recherche textuelle depuis la barre de recherche */
    clefSearchbar?: string;
    title?: string;
    valeur?: string;
    displayedValeur?: string;
    /** Indicateur d'exclusion des heures (dans le cas d'un filtre de type DATE) */
    ignoreHeures?: boolean;
    min?: number;
    max?: number;
    listeValues?: FilterValue[];
    isDefault?: boolean;
    type?: string = TypeFilter[TypeFilter.STRING];
    typeComparaison?: string = TypeComparaison[TypeComparaison.LIKE];
    isSelected?: boolean;
    isFromRule?: boolean;
    dateDebut?: Date;
    dateFin?: Date;
    hiddenChip?: boolean;
    changeMethod?: (filter: Filter) => any;
    selectMethod?: (filter: Filter) => any;
    removeMethod?: (filter: Filter) => any;
    loading?: boolean;

    /** Filtre à choix multiple */
    multiple?: boolean;

    /** Valeur du filtre au chargement de la liste. Le type Array n'est supporté que pour les filtres à choix multiples (propriété multiple = true) */
    onloadValue?: string | string[];

    //Liste des objets pour une comparaison de type IN
    listeObjects?: any[];
}
