import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TranslateService} from "@ngx-translate/core";
import {filter,first} from "rxjs/operators";
import {CategorieVoyageur} from "@domain/travel/categorie-voyageur";
import {ReferentielCategorieVoyageurComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/categorie-voyageur/referentiel-categorie-voyageur.component";
import {RefSbt} from "@domain/travel/ref-sbt";
import {TravelHubSBTConfigUsed} from "@domain/travel/travel-hub-sbt-config-used";
import {TravelHubSbtConfigUsedCategorie} from "@domain/travel/travel-hub-sbt-config-used-categorie";

/**
 * Popup de détails d'un référentiel de type Catégorie Voyageur
 *
 * @author Laurent Convert
 * @date 22/11/2023
 */
@Component({
    host: {'data-test-id': 'referentiel-categorie-voyageur-add'},
    templateUrl: './referentiel-categorie-voyageur-add.component.html',
    styleUrls: ['./referentiel-categorie-voyageur-add.component.scss']
})
export class ReferentielCategorieVoyageurAddComponent implements OnInit {
    /** La communauté en cours de visualisation */
    categorieVoyageur: CategorieVoyageur;

    /** Indicateur de chargement en cours */
    isLoading: boolean = true;

    /** Indicateur de suppression en cours */
    isDeleting: boolean = false;

    /** Indicateur d'enregistrement en cours */
    isSaving: boolean = false;

    /** Indicateur de traitement en cours (enregistrement ou suppression) */
    get isProcessing(): boolean {
        return this.isSaving || this.isDeleting;
    }

    /**
     * Constructeur
     *
     * @param data Données transmises à l'ouverture de la popup
     * @param matDialogRef Référence de la popup
     * @param confirmService Service de confirmation
     * @param translateService Service de traduction
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: {
                    referentiel: CategorieVoyageur,
                    sbtConfigUsed: TravelHubSBTConfigUsed,
                    listeSBTConfigUsed: Array<TravelHubSbtConfigUsedCategorie>,
                    sbt: RefSbt,
                    parent: ReferentielCategorieVoyageurComponent
                },
                private matDialogRef: MatDialogRef<ReferentielCategorieVoyageurAddComponent>,
                private confirmService: ConfirmService,
                private translateService: TranslateService
    ) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        this.categorieVoyageur = new CategorieVoyageur(this.data.referentiel);

        this.isLoading = false;
    }

    /**
     * Suppression de la catégorie voyageur en cours d'affichage
     */
    deleteCategorieVoyageur(): void {
        //Affichage de la confirmation de suppression
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe({
                next: () => {
                    //Suppression en cours
                    this.isDeleting = true;

                    //Suppression
                    this.data.parent.deleteReferentiel(this.categorieVoyageur).pipe(first()).subscribe(isSuccess => {
                        //Vérification du succès de la suppression
                        if (isSuccess) {
                            //Fermeture de la popup
                            this.matDialogRef.close(true);
                        } else {
                            //Erreur lors de suppression, fin du traitement
                            this.isDeleting = false;
                        }
                    });
                }
            });
    }

    /**
     * Suppression de la catégorie voyageur en cours d'affichage
     */
    saveCategorieVoyageur(): void {
        //Enregistrement en cours
        this.isSaving = true;

        //Enregistrement
        this.data.parent.saveReferentiel(this.categorieVoyageur).pipe(first()).subscribe(isSuccess => {
            //Vérification du succès de l'enregistrement
            if (isSuccess) {
                //Fermeture de la popup
                this.matDialogRef.close(true);
            } else {
                //Erreur lors d'enregistrement, fin du traitement
                this.isSaving = false;
            }
        });
    }
}