<div class="document-viewer">
    <div class="switcher">
        <div class="switcher-inner" [ngClass]="{ active: currentIndex == idxDocument }" *ngFor="let document of listeDocuments; let idxDocument = index">
            <div class="content" *ngIf="IMAGE_TYPES.indexOf(document.contentType) != -1" [ngStyle]="{ 'background-image': 'url(' + getUrlForDocument(document) + ')' }"></div>
            <div class="content" *ngIf="IMAGE_TYPES.indexOf(document.contentType) == -1">
                <mat-icon>picture_as_pdf</mat-icon>
                <span>{{ document.libelle || document.fichier }}</span>
            </div>
        </div>
        <div class="btn-container">
            <ul>
                <li>
                    <button mat-icon-button [disabled]="currentIndex == 0" (click)="currentIndex = currentIndex - 1;">
                        <mat-icon>arrow_back</mat-icon>
                    </button>
                </li>
                <li>
                    <button mat-icon-button (click)="showDocument()">
                        <mat-icon>search</mat-icon>
                    </button>
                </li>
                <li>
                    <button mat-icon-button [disabled]=" listeDocuments == null || currentIndex == listeDocuments.length - 1" (click)="currentIndex = currentIndex + 1;">
                        <mat-icon>arrow_forward</mat-icon>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</div>