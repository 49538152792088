import {Component,OnInit} from '@angular/core';
import {MontantPipe} from "@share/pipe/montant";
import {ListeDepenseFrais} from "@domain/ndf/liste-depense-frais";
import {MatDialog} from '@angular/material/dialog';
import {AlerteComponent} from '@components/workflow/alerte.component';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import roundHalfEven from "round-half-even";

@Component({
	host: {'data-test-id': 'ndf-frais-list-item'},
	templateUrl: './ndf-frais-list-item.component.html',
	styleUrls: ['./ndf-frais-list-item.component.scss']
})
export class NDFFraisListItemComponent extends ListViewItem<ListeDepenseFrais> implements OnInit {
	/** Montant remboursable convertit, utilisé quand on a une devise différente */
	montantRemboursableConverti: number;

	/**
	 * Constructeur
	 */
	constructor(private montantPipe: MontantPipe,
	            private matDialog: MatDialog) {
		super();
	}

	/**
     * Initialisation
     */
    ngOnInit(): void {
        //Si la devise du frais n'est pas la même que celle de la NDF
        //On gère le cas particulier avec devise saisie != devise utilisateur
        if (this.data.operation && this.data.operation.devise?.code !== this.extraOptions.codeDeviseNdf) {
			this.montantRemboursableConverti = roundHalfEven(this.data.operation.cours * this.data.montantRemboursable,2);
		}
    }

	/**
	 * Fonction de calcul du montant remboursable affiché dans la liste du frais
	 */
	getMontantRemboursable(): string {
		//Si la devise du frais est la même que celle de la NDF on doit avoir un montantRemboursableConverti null
		if (this.montantRemboursableConverti == null) {
			//On renvoie le montant remboursable dans sa devise d'origine
			return this.montantPipe.transform(this.data.montantRemboursable,this.data.operation.devise.code);
		} else {
			//Sinon on renvoie convertit le montant remboursable de la devise de la NDF
			return this.montantPipe.transform(this.montantRemboursableConverti,this.extraOptions.codeDeviseNdf);
		}
	}

	/**
	 * Affichage de la popup de la liste des alertes
	 */
	showListeAlertes(): void {
		//Affichage de la popup de la liste des alertes
		this.matDialog.open(AlerteComponent,{
			data: {
				alertes: this.data.listeAlertes.listeAlertes
			}
		});
	}

	/**
	 * Retourne la devise de la dépense ou du frais
	 */
	getDeviseCode(): string {
		return this.data.operation?.devise?.code ?? this.data.deviseCodeFrais
	}
}
