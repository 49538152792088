import {Injectable,Pipe,PipeTransform} from '@angular/core';

/**
 * Classe permettant de formatter un User avec un nom, prénom et matricule
 */
@Injectable({providedIn: 'root'})
@Pipe({
    name: 'user'
})
export class UserPipe implements PipeTransform {

    transform(value: { nom: string, prenom: string, matricule?: string }): string {
        return UserPipeTransform(value);
    }

}

/**
 * Fonction de formatage d'un objet User / UserDto sous la forme : "nom prénom (matricule)"
 * @param value L'utilisateur à formater
 */
export function UserPipeTransform(value: { nom: string,prenom: string,matricule?: string }): string {
    if (value != null) {
        return (`${value.nom?.toUpperCase() ?? ''} ${value.prenom ?? ''}` + (value.matricule ? ` (${value.matricule})` : ``)).trim();
    } else {
        return "";
    }
}