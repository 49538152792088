import { Component } from "@angular/core";

/**
 * Onglet conditions de visibilité
 *
 * @author Guillaume TRAVOT
 * @date 21/11/2023
 */
@Component({
    host: {'data-test-id': 'conditions-visibilite'},
    templateUrl: './conditions-visibilite.component.html'
})
export class ConditionsVisibiliteComponent {}
