<h1 mat-dialog-title>
    <span [translate]="montant.idMontant > 0 ? 'admin.entreprise.analytique.enveloppes.montants.modif' : 'admin.entreprise.analytique.enveloppes.montants.ajout'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>

<form #form="ngForm" *ngIf="montant && listeExercice">
    <div mat-dialog-content>
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="exercice"
                    name="exercice"
                    customType="objectselect"
                    [(ngModel)]="montant.exercice"
                    libelle="admin.entreprise.analytique.enveloppes.exercice"
                    optionValue="idExercice"
                    optionDisplay="libelle"
                    lCol="3" rCol="4"
                    [isSelectAvecVide]="false"
                    [selectOptions]="listeExercice"
                    [readonly]="montant.montantEngage > 0"
                    [required]="true">
            </custom-input>
        </div>
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="dateDebut"
                    name="dateDebut"
                    [(ngModel)]="montant.periodeDebut"
                    customType="objectselect"
                    libelle="admin.entreprise.analytique.enveloppes.debut"
                    optionValue="idPeriode"
                    optionDisplay="libelle"
                    lCol="3" rCol="4"
                    [isSelectAvecVide]="true"
                    [selectOptions]="montant.exercice.listePeriode"
                    [readonly]="montant.montantEngage > 0"
                    [required]="true">
            </custom-input>
        </div>
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="dateFin"
                    name="dateFin"
                    [(ngModel)]="montant.periodeFin"
                    customType="objectselect"
                    libelle="admin.entreprise.analytique.enveloppes.fin"
                    optionValue="idPeriode"
                    optionDisplay="libelle"
                    lCol="3" rCol="4"
                    [isSelectAvecVide]="true"
                    [selectOptions]="montant.exercice.listePeriode"
                    [readonly]="montant.montantEngage > 0"
                    [required]="true">
            </custom-input>
        </div>
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="montant"
                    name="montant"
                    [(ngModel)]="montant.montant"
                    [min]="montant.montantEngage || 0"
                    customType="montant"
                    libelle="admin.entreprise.analytique.enveloppes.montant"
                    lCol="3" rCol="4"
                    [postTooltipNio]="!isValidMontant() ? {content: 'admin.entreprise.analytique.enveloppes.montantError' | translate,icon: 'warning'} : null"
                    [required]="true">
            </custom-input>
        </div>
        <div class="row">
            <custom-input
                    *ngIf="montant.montantEngage > 0"
                    ngDefaultControl
                    id="montantEngage"
                    name="montantEngage"
                    [(ngModel)]="montant.montantEngage"
                    [montantDevise]="montant.codeDevise"
                    customType="montant"
                    libelle="admin.entreprise.analytique.enveloppes.montantEngage"
                    lCol="3" rCol="4"
                    readonly="true">
            </custom-input>
        </div>
    </div>
    <div class="d-flex flex-row-reverse">
        <button mat-flat-button color="primary" (click)="saveMontant()" [disabled]="form.invalid || !isValidMontant()">
            <span [translate]="montant.idMontant > 0 ? 'global.actions.enregistrer' : 'global.actions.ajouter'"></span>
            <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
        </button>
    </div>
</form>
