import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject,Observable,Subscription} from "rxjs";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {first,map} from "rxjs/operators";
import {MaintenanceParam} from "@domain/admin/maintenance/maintenance-param";
import {MaintenanceTask,MaintenanceTaskResult} from "@domain/admin/maintenance/maintenance-task";
import {IStatutApplication,KeyStatutApplication,LISTE_STATUTS_APPLICATION} from "@domain/admin/statut-application/statut-application";
import {Migration} from "@domain/admin/maintenance/migration";
import {ListeAlertes} from "@domain/common/alerte/listeAlertes";

/**
 * Service de gestion des migrations
 */
@Injectable()
export class MigrationsService {
    /** Map de stockage des tâches listées */
    private _mapMigrations: Map<string,TaskStatutHandler> = new Map<string,TaskStatutHandler>();

    /** Set de stockage des tâches en cours */
    private _setMigrationsEnCours: Set<string> = new Set<string>();

    /** Maintenance en cours */
    private _isMaintenanceEnCours: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    get isMaintenanceEnCours$(): Observable<boolean> { return this._isMaintenanceEnCours.asObservable(); }

    /** Statut de l'application */
    private _appStatut: BehaviorSubject<AppStatut> = new BehaviorSubject<AppStatut>(undefined);
    get appStatut$(): Observable<AppStatut> { return this._appStatut.asObservable(); }

    /** Flag du rafraichissement automatique */
    private isAutoRefreshOnly: boolean = false;

    /** Liste des alertes à afficher */
    listeAlertes: ListeAlertes;

    /**
     * Constructeur
     */
    constructor(private http: HttpClient) {}

    /**
     * Récupération des détails d'une migration
     *
     * @param idUpgrade ID de la migration à récupérer
     */
    getMigrationDetails(idUpgrade: number): Observable<Migration> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Maintenance/loadBDDUpgrade/${idUpgrade}`,null).pipe(first(),map(result => new Migration(result?.data?.migration)));
    }

    /**
     * Récupération des paramètres
     */
    getParam(): Observable<MaintenanceParam> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/Maintenance/maintenanceParam`).pipe(first(),map(result => new MaintenanceParam(result?.data?.maintenanceParam)));
    }

    /**
     * Exécute la mise à jour (asynchrone) de la bdd et/ou le lancement des tâches de maintenance.
     *
     * @param isUpdateBdd   demande de mise à jour de la base de données si true
     * @param isUpdateApp   demande de mise à jour de l'application si true
     */
    executeManualUpdate(isUpdateBdd: boolean = false,isUpdateApp: boolean = false): Observable<void> {
        return this.http.post<void>(`${environment.baseUrl}/controller/Maintenance?action=majForcee&update_bdd=${isUpdateBdd}&update_app=${isUpdateApp}`,null).pipe(first(),map(() => this._isMaintenanceEnCours.next(true)));
    }

    /**
     * Lancement de la mise à jour de la base de données
     */
    upgradeBdd(): Observable<void> {
        return this.http.post<void>(`${environment.baseUrl}/controller/Maintenance?action=majBdd`,null).pipe(first(),map(() => this._isMaintenanceEnCours.next(true)));
    }

    /**
     * Enregistrement des nouveaux paramètres
     *
     * @param maintenanceParam  paramètres à enregistrer
     */
    saveMaintenanceParam(maintenanceParam: MaintenanceParam): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Maintenance/saveMaintenanceParam`,maintenanceParam).pipe(first());
    }

    /**
     * Exécution d'un lot de tâches
     *
     * @param listeTask  liste des tâches à éxécuter
     */
    executeTasks(listeTask: MaintenanceTask[] = []): Observable<Result> {
        //Initialisation
        const listTaskName: string[] = [];

        //Parcours des tâches à exécuter
        for (const task of listeTask) {
            //Ajout de la tâche dans la map d'écoute si nécessaire
            if (!this._setMigrationsEnCours.has(task.taskName)) { this._setMigrationsEnCours.add(task.taskName); }

            //Ajout à la liste pour la requête backend
            listTaskName.push(task.taskName);
        }

        //Parcours des tâches affichées
        for (const taskStatutHandler of this._mapMigrations.entries()) {
            //Si la tâche ne fait pas partie de la sélection à exécuter
            if (!listeTask.some(task => task.taskName == taskStatutHandler[0])) {
                //Désactivation de la tâche
                taskStatutHandler[1].next({
                    isActive: false,
                    isRunning: false,
                    progression: 0,
                    isSuccess: undefined
                } as TaskStatut);
            }
        }

        //Lancement de la tâche
        return this.http.post<Result>(`${environment.baseUrl}/controller/Maintenance/executeAdminTasks`,listTaskName).pipe(first(),map((result) => {
            //Maintenance en cours
            this._isMaintenanceEnCours.next(true);

            //On laisse le temps au thread Java de se lancer puis on vérifie le statut de l'application
            setTimeout(() => this.checkAppliStatut(), 1000);

            //Retour du résultat
            return result;
        }));
    }

    /**
     * Récupération des logs pour une tâche
     *
     * @param idTask    ID de la tâche concernée
     */
    getLog(idTask: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Maintenance/consultTask/${idTask}/log`,null).pipe(first());
    }

    /**
     * Inscription d'une tâche dans la liste
     *
     * @param subscriberName        composant souscripteur
     * @param task                  tâche à suivre
     * @param callbackStatut        fonction à exécuter lors d'une mise à jour du statut
     * @param callbackMaintenance   fonction à exécuter lors d'une mise à jour de la maintenance
     */
    registrerTask(subscriberName: string, task: MaintenanceTask, callbackStatut: (statut: TaskStatut) => any, callbackMaintenance: (isMaintenanceEnCours: boolean) => any): void {
        //Recherche de la tâche pour voir si elle existe déjà
        let taskStatutHandler: TaskStatutHandler = this._mapMigrations.get(task.taskName);

        //Initialisation de la tâche si elle n'a pas été trouvée
        if (!taskStatutHandler) { taskStatutHandler = new TaskStatutHandler(task.taskName, { isActive: false, isRunning: false, progression: 0 } as TaskStatut, this._mapMigrations); }

        //Abonnement aux mises à jour
        taskStatutHandler.subscribeTask(subscriberName, callbackStatut, callbackMaintenance, this.isMaintenanceEnCours$);
    }

    /**
     * Désabonnement des mises à jour d'une tâche
     *
     * @param subscriberName        composant souscripteur
     * @param taskName              nom de la tâche
     */
    unregisterTask(subscriberName: string, taskName: string): void {
        //Désabonnement et suppression de la tâche si elle existe
        this._mapMigrations.get(taskName)?.unsubscribeTask(subscriberName);
    }

    /**
     * Retourne la liste des tâches en cours d'exécution
     */
    getListeActiveMigrations(): string[] {
        //Liste des tâches actives
        return Array.from(this._setMigrationsEnCours);
    }

    /**
     * Vérification du statut détaillé de l'application
     *
     * @param isAutoRefresh     true si rafraichissement automatique
     */
    checkAppliStatut(isAutoRefresh: boolean = false): void {
        //Si le rafraichissement automatique n'est pas déjà en cours ou que l'appel est un rafraichissement automatique
        if (!this.isAutoRefreshOnly || isAutoRefresh) {
            //Blocage du flag de rafraichissement automatique
            this.isAutoRefreshOnly = true;

            this.http.post<Result>(`${environment.baseUrl}/controller/Maintenance/checkAppliStatut`,Array.from(this._setMigrationsEnCours)).pipe(first()).subscribe((result: Result) => {
                //Récupération des variables
                const listeTachesAExecuter: string[] = result?.data?.listeTachesAExecuter || [];
                const listeTachesEnCours: string[] = result?.data?.listeTachesEnCours || [];
                const listeTachesProgression: number[] = result?.data?.listeTachesProgression || [];
                const listeTachesResultat: MaintenanceTaskResult[] = result?.data?.listeTachesResultat || [];
                const listeTachesDate: string[] = result?.data?.listeTachesDate || [];
                const listeTachesTerminees: string[] = result?.data?.listeTachesTerminees || [];
                const activeProgression: number = this._appStatut?.getValue()?.progression || 0;
                const newProgression: number = result?.data?.progression;
                const progression: number = (newProgression > activeProgression || activeProgression == 100) ? newProgression : activeProgression;
                let index: number = 0;

                //Parcours des tâches
                for (const tache of listeTachesAExecuter) {
                    //Récupération du handler de statut
                    const taskStatutHandler: TaskStatutHandler = this._mapMigrations.get(tache);

                    //Tâche en cours
                    const isRunning: boolean = !!(listeTachesEnCours.length && listeTachesEnCours.indexOf(tache) != -1);

                    //Tâche terminée
                    const isTerminee: boolean = !!(listeTachesTerminees.length && listeTachesTerminees.indexOf(tache) != -1);

                    //Si la tâche est gérée
                    if (taskStatutHandler) {
                        //Emission de la nouvelle progression de la tâche
                        taskStatutHandler.next({
                            isActive: this._isMaintenanceEnCours.getValue(),
                            isRunning: isRunning,
                            progression: isTerminee ? 100 : listeTachesProgression[index],
                            isSuccess: listeTachesResultat[index] === undefined ? undefined : listeTachesResultat[index] === MaintenanceTaskResult.SUCCESS,
                            date: new Date(listeTachesDate[index])
                        } as TaskStatut);
                    }

                    //Ajout de la tâche dans la map d'écoute si nécessaire
                    if (!this._setMigrationsEnCours.has(tache)) { this._setMigrationsEnCours.add(tache); }

                    //Incrément
                    index++;
                }

                //Mise à jour du statut de l'application
                this._appStatut.next({
                    etape: result?.data?.etape,
                    nbDone: result?.data?.nbDone,
                    nbTotal: result?.data?.nbTotal,
                    progression: progression,
                    versionBdd: result?.data?.versionBdd,
                    buildBdd: result?.data?.buildBdd,
                    versionAppli: result?.data?.versionAppli,
                    buildAppli: result?.data?.buildAppli,
                    isBddUpToDate: result?.data?.isBddUpToDate && result?.data?.isFinished,
                    isMigrationAuto: result?.data?.isMigrationAuto,
                    applicationStatut: LISTE_STATUTS_APPLICATION.get(result?.data?.applicationStatut),
                    isMaintenanceEnCours: !result?.data?.isFinished,
                    isBddUpgradeError: result?.data?.isBddUpgradeError,
                    isMigrationError: result?.data?.isMigrationError
                } as AppStatut);

                //Si la maintenance n'est pas terminée
                if (!result?.data?.isFinished || result?.data?.applicationStatut == KeyStatutApplication.LOCKED) {
                    //Rafraichissement de la maintenance en cours (en fonction du statut)
                    this._isMaintenanceEnCours.next(result?.data?.applicationStatut != KeyStatutApplication.LOCKED);

                    //On réessaye dans une seconde
                    setTimeout(() => this.checkAppliStatut(true), 1000);
                } else {
                    //Sinon purge des tâches à écouter
                    for (const tache of listeTachesAExecuter) {
                        //Suppression de la liste des tâche en cours
                        this._setMigrationsEnCours.delete(tache);
                    }

                    //Fin de la maintenance en cours
                    this._isMaintenanceEnCours.next(false);

                    //Déblocage du flag de rafraichissement automatique
                    this.isAutoRefreshOnly = false;
                }
            });
        }
    }
}

/**
 * Classe de gestion du statut d'une tâche
 */
class TaskStatutHandler {
    /** Nom de la tâche */
    private readonly _taskName: string;

    /** Statut de la tâche */
    private _statut: BehaviorSubject<TaskStatut>;

    /** Liste des souscriptions */
    private _subscriptionsMap: Map<string,Subscription[]> = new Map<string, Subscription[]>();

    /** Référence à la Map parente */
    private _mapMigrationsReference: Map<string,TaskStatutHandler>;

    /**
     * Constructeur
     */
    constructor(taskName: string, statut: TaskStatut, map: Map<string,TaskStatutHandler>) {
        //Nom de la tâche
        this._taskName = taskName;

        //Initialisation du statut
        this._statut = new BehaviorSubject<TaskStatut>(statut);

        //Référence à la Map
        this._mapMigrationsReference = map;

        //Ajout de la tâche à la Map
        this._mapMigrationsReference.set(this._taskName,this);
    }

    /**
     * Récupération de la valeur en cours
     */
    getValue(): TaskStatut {
        //Retour de la valeur du subject
        return this._statut.getValue();
    }

    /**
     * Souscription aux mises à jour
     *
     * @param subscriberName            composant souscripteur
     * @param callback                  fonction à exécuter lors d'une mise à jour
     * @param callbackMaintenance       fonction à exécuter lors d'une mise à jour de la maintenance
     * @param maintenanceObservable     observable pour les mises à jour de la maintenance
     */
    subscribeTask(subscriberName: string, callback: (statut: TaskStatut) => any, callbackMaintenance: (isMaintenanceEnCours: boolean) => any, maintenanceObservable: Observable<boolean>): void {
        //Recherche du souscripteur
        let subscriptions: Subscription[] = this._subscriptionsMap.get(subscriberName);

        //Si le souscripteur n'a pas été trouvé, alors on en crée un vide
        if (!subscriptions) { subscriptions = []; }

        //Ajout de la souscription au statut
        subscriptions.push(this._statut.asObservable().subscribe(callback));

        //Ajout de la souscription à la maintenance
        subscriptions.push(maintenanceObservable.subscribe(callbackMaintenance));

        //Mise à jour de la Map
        this._subscriptionsMap.set(subscriberName,subscriptions);
    }

    /**
     * Mise à jour de la progression de la tâche
     *
     * @param statut    nouveau statut de la tâche
     */
    next(statut: TaskStatut): void {
        //Emission de la nouvelle progression
        this._statut.next(statut);
    }

    /**
     * Désabonnement complet du statut
     *
     * @param subscriberName            composant souscripteur
     */
    unsubscribeTask(subscriberName: string): void {
        //Recherche du souscripteur
        const subscriptions: Subscription[] = this._subscriptionsMap.get(subscriberName);

        //Si le souscripteur a été trouvé
        if (subscriptions) {
            //Parcours des souscriptions
            for (const subscription of subscriptions) {
                //Désabonnement
                subscription.unsubscribe();
            }

            //Suppression dans la Map des souscriptions
            this._subscriptionsMap.delete(subscriberName);

            //S'il n'y a plus aucune souscription
            if (!this._subscriptionsMap.size) {
                //Suppression du statut dans la Map
                this._mapMigrationsReference.delete(this._taskName);
            }
        }
    }
}

/**
 * Classe de stockage du statut d'une tâche
 */
export class TaskStatut {
    /** Tâche en attente */
    isActive: boolean;

    /** Tâche en cours */
    isRunning: boolean;

    /** Avancement de la tâche */
    progression: number;

    /** Succès de la tâche */
    isSuccess: boolean;

    /** Date de la tâche */
    date: Date;
}

/**
 * Classe de stockage du statut de l'application
 */
export class AppStatut {
    /** Etape de mise à jour de la BDD */
    etape: EtapeMajBdd;

    /** Nombre effectué */
    nbDone: number;

    /** Nombre total */
    nbTotal: number;

    /** Progression */
    progression: number;

    /** BDD à jour */
    isBddUpToDate: boolean;

    /** Migration automatique */
    isMigrationAuto: boolean;

    /** Version de l'application */
    versionAppli: string;

    /** Version de la BDD */
    versionBdd: string;

    /** Build de l'application */
    buildAppli: string;

    /** Build de la BDD */
    buildBdd: string;

    /** Statut de l'application */
    applicationStatut: IStatutApplication;

    /** Maintenance en cours */
    isMaintenanceEnCours: boolean;

    /** Erreur sur les tâches de maintenance */
    isMigrationError: boolean;

    /** Erreur sur la mise à jour BDD */
    isBddUpgradeError: boolean;
}

/**
 * Enum représentant l'étape de mise à jour de la BDD
 */
export enum EtapeMajBdd {
    UPGRADE_ETAPE_BUILD = 1,
    UPGRADE_ETAPE_COMPARE = 2,
    UPGRADE_ETAPE_GENERATE_SQL = 3,
    UPGRADE_ETAPE_EXECUTE = 4
}