import { Component,Input } from '@angular/core';

import { environment } from '../../environments/environment';
import { ImageViewerService } from '../../share/component/image-viewer/image-viewer.service';
import { IMAGE_TYPES } from './document';

@Component({
    selector: 'document-viewer',
    templateUrl: './document-viewer.component.html'
})
export class DocumentViewerComponent {
    /** Liste des documents **/
    @Input() listeDocuments: Array<any>;

    /** Alignement à droite de l'image */
    @Input() isAlignRight: boolean = false;

    /** Index courant **/
    currentIndex: number = 0;

    /** Types d'image **/
    readonly IMAGE_TYPES: Array<string> = IMAGE_TYPES;

    /**
     * Constructeur
     */
    constructor(private imageViewerService: ImageViewerService) {

    }

    /**
     * Récupération de l'URL de lecture d'un document
     */
    getUrlForDocument(document: any): string {
        //Génération de l'URL
        return `${environment.baseUrl}/controller/Document/readDocument/${document.idDocument}`;
    }

    /**
     * Affichage du document
     */
    showDocument() {
        //Vérification du type de fichiers
        if (this.IMAGE_TYPES.indexOf(this.listeDocuments[this.currentIndex].contentType) != -1) {
            //Visualisation de l'image
            this.imageViewerService.show(`${environment.baseUrl}/controller/Document/readDocument/${this.listeDocuments[this.currentIndex].idDocument}`, this.isAlignRight);
        } else {
            //Ouverture du document
            window.open(`${environment.baseUrl}/controller/Document/readDocument/${this.listeDocuments[this.currentIndex].idDocument}`);
        }
    }
}