import {AfterViewInit,Component,ContentChildren,EventEmitter,Input,OnDestroy,OnInit,Output,QueryList,ViewChild} from '@angular/core';
import {MatTabChangeEvent,MatTabGroup} from '@angular/material/tabs';
import {PageHeaderItem} from './page-header';
import {NiveauAlerte} from "@domain/common/alerte/alerte";
import {Observable,Subscription} from "rxjs";
import {PreviewAlerteComponent} from "@share/component/alerte/preview-alerte.component";
import {getStatutArchivageIcon,StatutArchivage} from '@domain/comptabilite/statut-archivage';
import {TranslateService} from '@ngx-translate/core';
import {SettingsGlobalState} from '@domain/settings/settings';
import {AppState} from '@domain/appstate';
import {Store} from '@ngrx/store';
import {ActivatedRoute,Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {Session} from "@domain/security/session";
import {isRouteAllowed} from "@core/security/role-admin-helpers";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";

@Component({
    selector: 'page-header',
    templateUrl: './page-header.component.html'
})
export class PageHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
    /** Titre **/
    @Input() title: string;

    /** Information supplémentaire */
    @Input() extraInfo?: string;

    /** Tooltip d'information supplémentaire */
    @Input() extraInfoTooltip?: string;

    /** Statut d'archivage */
    @Input() statutArchivage?: StatutArchivage;

    /** Liste des éléments **/
    @Input() listeItems: Array<PageHeaderItem>;

    /** Niveau maximal des alertes **/
    @Input() niveauAlerte: NiveauAlerte = null;

    @Input() goBackUrl: string;

    /** Observable contenant le numéro de l'onglet sélectionné */
    @Input() selectedTab?: Observable<number>;

    /** Indique si les onglets doivent être carrés */
    @Input() isSquareTab?: boolean = false;

    /** Action en cours **/
    @Input() isPending: boolean;

    /** Paramètres */
    @Input() settings?: SettingsGlobalState;

    /** Interception d'un changement d'onglet **/
    @Output() onSelectedItemChange: EventEmitter<PageHeaderItem> = new EventEmitter<PageHeaderItem>();

    /** Déclenchement d'un retour en arrière **/
    @Output() onGoBack: EventEmitter<any> = new EventEmitter<any>();

    /** Déclenchement d'un clic sur les informations supplémentaires **/
    @Output() onExtraInfoClick: EventEmitter<any> = new EventEmitter<any>();

    /** Liste des alertes **/
    @ContentChildren(PreviewAlerteComponent) listeAlertes: QueryList<PreviewAlerteComponent>;

    /** Groupe d'onglets */
    @ViewChild('matTabGroup') tabGroupe: MatTabGroup;

    /** Indicateur d'affichage du bouton de retour **/
    public canGoBack: boolean = false;

    /** Indicateur de gestion de clic sur les informations supplémentaires **/
    public canExtraInfoClick: boolean = false;

    /** Enumération des niveaux d'alerte */
    public niveauAlerteEnum: typeof NiveauAlerte = NiveauAlerte;

    /** Index de l'onglet à afficher au chargement, 0 par défaut */
    @Input() selectedIndex: number;

    /** Nom de l'icône du statut d'archivage du lot comptable */
    statutArchivageIcon: string;

    /** On stocke la souscription pour pouvoir unsub */
    private souscription: Subscription;

    /** Session */
    private session: Session;

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private translateService: TranslateService,
        private activatedRoute: ActivatedRoute
    ) { }

    /**
     * Initialisation
     */
    ngOnInit() {

        if (!this.selectedIndex) {
            //Recherche de l'onglet à afficher au chargement (le 1er si non spécifié)
            this.selectedIndex = Math.max(0,this.listeItems?.findIndex(tab => tab?.selected == true));
        }

        //Sélection de l'onglet
        this.onSelectedItemChange.emit(this.listeItems?.[this.selectedIndex]);

        //Vérification de la présence d'observateur sur le bouton de retour
        this.canGoBack = this.onGoBack.observers.length > 0;

        //Vérification de la présence d'observateur sur le clic des informations supplémentaires
        this.canExtraInfoClick = this.onExtraInfoClick.observers.length > 0;

        //Vérificaiton de la présence des paramètres nécessaires pour déterminer quelle icone afficher
        if (!!this.statutArchivage) {
            this.statutArchivageIcon = getStatutArchivageIcon(this.statutArchivage);
        }

        //Récupération de la session
        this.store.select(state => state.session).pipe(filterFirstNotNull()).subscribe((session: Session) => this.session = session);
    }

    ngAfterViewInit(): void {
        //Si on fournit un observable pour l'index de l'onglet
        if (this.selectedTab) {
            //On souscrit à l'observable
            this.souscription = this.selectedTab.subscribe(tab => {
                //Lors de la modification de l'index via le parent, on met à jour l'index de l'onglet ici aussi
                this.tabGroupe.selectedIndex = tab;
            });
        }
    }

    /** Destruction du composant */
    ngOnDestroy() {
        //Si on a souscrit
        if (this.souscription) {
            //On désouscrit
            this.souscription.unsubscribe();
        }
    }

    /**
     * Filtrage des éléments visibles
     */
    isVisible(): (i: PageHeaderItem) => boolean {
        return  (i: PageHeaderItem) => {
            return isRouteAllowed(this.router,this.session,i.url);
        };
    }

    /**
     * Change l'onglet affiché
     *
     * @param code Code de l'onglet à afficher
     */
    changeTab(code: string) {
        let targetTab: number = this.listeItems.findIndex((tab) => tab.code === code);

        if (targetTab >= 0) {
            this.tabGroupe.selectedIndex = targetTab;
        }
    }

    /**
     * Changement d'onglet
     */
    onSelectedTabChange(event: MatTabChangeEvent) {
        //Déclenchement de l'évènement
        this.onSelectedItemChange.emit(this.listeItems[event.index]);
    }

    /**
     * Navigation vers l'url de retour
     */
    goBack(): void {
        if (this.canGoBack) {
            this.onGoBack.emit();
        } else if (this.goBackUrl) {
            this.router.navigate([this.goBackUrl]);
        }
    }

    /**
     * Permet de définir quelle classe appliquer (couleur) sur l'icone de dématérialisation
     */
    getDematIconClass() {
        switch (this.statutArchivage) {
            case StatutArchivage.PARTIEL:
                return 'bg-warning';
            case StatutArchivage.ECHEC:
                return 'bg-danger';
            case StatutArchivage.EN_ATTENTE:
                return 'bg-info';
            case StatutArchivage.SYNCHRONISE:
                return 'bg-success';
            default:
                return '';
        }
    }

    /**
     * Permet de définir quel tooltip afficher sur l'icone de dématérialisation
     */
    getDematTooltip() {
        switch (this.statutArchivage) {
            case StatutArchivage.EN_ATTENTE:
                return this.translateService.instant("lot.synchro.enAttente");
            case StatutArchivage.SYNCHRONISE:
                return this.translateService.instant("lot.synchro.lotSynchronise");
            case StatutArchivage.ECHEC:
                return this.translateService.instant("lot.synchro.echec");
            case StatutArchivage.PARTIEL:
                return this.translateService.instant("lot.synchro.partiel");
            default:
                return "";
        }
    }
}
