import {Component,Input,OnInit} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {ParamConnexionUsed} from "@domain/travel/param-connexion-used";
import {ParamConnexionComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/parametres/param-connexion/param-connexion.component";
import {filter,finalize,first} from "rxjs/operators";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TranslateService} from "@ngx-translate/core";
import {TransmisPour,TypeParamConnexion} from '@domain/travel/param-connexion';
import {AdminTravelhubService} from "@components/admin/voyages/travelhub/admin-travelhub.service";
import {Alerte} from "@domain/common/alerte/alerte";

/**
 * Ligne du cadre "Paramètres de connexion"
 *
 * @author Laurent Convert
 * @date 05/12/2023
 */
@Component({
	host: {'data-test-id': 'param-connexion-list-item'},
	templateUrl: './param-connexion-list-item.component.html'
})
export class ParamConnexionListItemComponent extends ListViewItem<ParamConnexionUsed> implements OnInit {
	/* Déclaration pour accès dans le template */
	public readonly TransmisPour = TransmisPour;
	public readonly TypeParamConnexion = TypeParamConnexion;

	/** Options supplémentaires */
	@Input() public extraOptions: {
		parent: ParamConnexionComponent
	};

	/** Valeur du paramètre */
	valeur: string;

	/** Icône déterminée en fonction du paramètre */
	icone: string;

	/** Alerte indiquant que le paramètre est obligatoire et qu'il est manquant */
	alerteParamObligatoireManquant: Alerte;

	/**
	 * Constructeur
	 *
	 * @param pleaseWaitService Service d'affichage du loading
	 * @param confirmService
	 * @param translateService Service de traduction
	 * @param adminTravelhubService
	 */
	constructor(private pleaseWaitService: PleaseWaitService,
				private confirmService: ConfirmService,
				private translateService: TranslateService,
				private adminTravelhubService: AdminTravelhubService
	) {
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de la valeur
		this.valeur = this.adminTravelhubService.getValeurForParamConnexionValue(this.data);

		//Recherche de l'alerte indiquant que le paramètre est obligatoire et qu'il est manquant
		this.alerteParamObligatoireManquant = this.data.listeAlertes?.listeAlertes?.find(alerte => alerte.message === 'admin.voyages.travelhub.sbtConfig.parametres.paramConnexion.paramObligatoireManquant');

		//Cas d'un paramètre obligatoire non renseigné
		if (this.alerteParamObligatoireManquant != null) {
			//Le type est déterminé en fonction du choix de l'utilisateur, tant que cela n'a pas été fait, on affiche une icône d'erreur générique
			this.icone = `<span class="material-symbols-rounded">priority_high</span>`;
		} else if (this.data.paramConnexion?.automatique) {
			//Icône "automatique"
			this.icone = `<span class="material-symbols-rounded">autorenew</span>`;
		} else {
			//Icône en fonction du type
			this.icone = this.adminTravelhubService.getIconeForParamConnexionValue(this.data);
		}
	}

	/**
	 * Suppression du paramètre
	 */
	deleteParamConnexion(): void {
		//Affichage de la confirmation de suppression
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe(() =>{
				//Modale de chargement
				let pleaseWaitRef = this.pleaseWaitService.show();

				//Suppression
				this.extraOptions.parent.deleteParamConnexion(this.data)
					.pipe(first(),finalize(() => pleaseWaitRef.close()))
					.subscribe();
			});
	}
}