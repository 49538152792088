<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'warning': data.listeAlertes?.niveau == 1,'danger': data.listeAlertes?.niveau == 2 }"
         [nSelector]="{liste: liste, item: data, isNotSelectable: fonction === TypeProfil.ADMINISTRATEUR}">
        <i class="nio icon-ordre_de_mission_permanent" style="vertical-align: middle;"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="navigateTo()">
                {{ data.idOMPermanent || ('omp.liste.numeroNonDefini' | translate) }} - {{ data.objet || ('omp.liste.objetNonDefini' | translate) }}
            </a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="!isCollabMode"><strong [translate]="'omp.liste.collaborateur'"></strong>{{ data.getCollaborateur() }} - {{ data.user?.matricule }}</li>
            <li><strong [translate]="'omp.liste.type'"></strong>{{ data.typeEntite }}</li>
            <li *ngIf="data.dateDebut"><strong [translate]="'omp.liste.dateDebut'"></strong>{{ data.dateDebut | date:'shortDate' }}</li>
            <li *ngIf="data.dateFin"><strong [translate]="'omp.liste.dateFin'"></strong>{{ data.dateFin | date:'shortDate' }}</li>
            <li *ngIf="data.location"><strong [translate]="'omp.liste.libelleVille'"></strong>
                <span *ngIf="data.pays?.code2" class="flag-icon mr-1" [ngClass]="'flag-icon-'+data.pays.code2.toLowerCase()"></span>
                {{ data.location }}
            </li>
            <li *ngIf="!isCollabMode && data.getRolesCount() > 1" [matTooltip]="data.getRoles()"><strong [translate]="'facture.liste.roles'"></strong>{{ data.getRolesCount() }}</li>
            <li *ngIf="!isCollabMode && data.getRolesCount() == 1"><strong [translate]="'facture.liste.role'"></strong>{{ data.getRoles() }}</li>
            <li *ngIf="data.listeAlertes" (click)="showListeAlertes()" class="clickable"
                [ngClass]="{'back-danger' : data.listeAlertes.niveau == 2, 'back-warning' : data.listeAlertes.niveau == 1}">
                <strong [translate]="'workflow.alerte.title'"></strong><span>{{ ('workflow.alerte.niveau.' + data.listeAlertes.niveau | translate) }}</span>
            </li>
        </ul>
    </div>
    <div class="info">
        <div>
            <statut [statut]="data.statut"></statut>
        </div>
    </div>
</div>