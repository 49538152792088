import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {first,map} from 'rxjs/operators';

import {Result} from '@domain/common/http/result';
import {Ndf} from '@domain/ndf/ndf';
import {ZoneUtilisateur} from '@domain/zone-utilisateur/zone-utilisateur';
import {environment} from '@environments/environment';
import {ContextDocument} from "@domain/document/context-document";
import {IObjetWorkflowService} from '../workflow/objet-workflow.service';
import {Document} from "@domain/document/document"
import {Vehicule} from "@domain/vehicule/vehicule";
import {CompteurDepense} from "@domain/ik/compteur-depense";
import {CalculIkDetail} from "@domain/ik/calcul-ik-detail";
import {ListeDepenseFrais} from "@domain/ndf/liste-depense-frais";

@Injectable()
export class NDFService implements IObjetWorkflowService<Ndf> {

    /**
     * Constructeur
     */
    constructor(private http: HttpClient) {}

    /**
     * Chargement d'une note de frais
     * @param idNDF ID de la note de frais
     */
    load(idNDF: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/NDF/loadNDF/${idNDF}/detail`,null);
    }

    /**
     * Création d'une note de frais
     * @param object Paramètres de la NDF à créer
     */
    create({ idOd,idOMPermanent,idTypeEntite,idCollab }: { idOd?: number,idOMPermanent?: number,idTypeEntite: number,idCollab?: number }): Observable<number> {
        let params: URLSearchParams = new URLSearchParams();

        //Ajout des paramètres
        params.append('idOd',(idOd ?? 0).toString());
        params.append('idOmPermanent',(idOMPermanent ?? 0).toString());
        params.append('idTypeEntite',idTypeEntite.toString());
        if (idCollab) {
            params.append('idCollab', idCollab.toString());
        }

        //Récupération de la taxe applicable
        return this.http.post<Result>(`${environment.baseUrl}/controller/NDF/createNDF`,params.toString(),{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        }).pipe(
            first(),
            map(result => result?.data?.idNdf)
        );
    }

    /**
     * Enregistrement de la note de frais
     * @param ndf NDF à sauvegarder
     */
    save(ndf: Ndf): Observable<Result> {
        //Enregistrement de la dépense
        return this.http.put<Result>(`${environment.baseUrl}/controller/NDF/saveHeader/${ndf.idNDF}`,ndf);
    }

    /**
     * Traitement OCR d'un document
     * Pour enregistrer un justificatif générant une nouvelle dépense renseigner idNDF
     * Pour historiser un justificatif ocr renseigner idDepense
     * @param document Le justificatif envoyé
     * @param isOCR Indique si le frais est généré par OCR depuis un justificatif
     * @param idNDF L'id de la NDF à renseigner pour générer un nouveau frais dans la liste des frais
     * @param idDepense l'id de la dépense à renseigner pour créer un historique ocr
     */
    processJustificatif(document: Document | Array<Document>, isOCR: boolean = false, idNDF: number = null, idDepense: number = null): Observable<Result> {
        let listeDocuments: Array<Document>;

        //Envoi d'une liste de documents
        if (Array.isArray(document)) {
            listeDocuments = document;
        } else {
            //Envoi d'un seul document
            listeDocuments = [document]
        }

        //Traitement OCR du document
        return this.http.post<Result>(`${environment.baseUrl}/controller/NDF/processJustificatif?idNDF=${idNDF ?? ''}&idDepense=${idDepense ?? ''}&isOCR=${isOCR ?? ''}`,listeDocuments).pipe(first());
    }

    /**
     * Récupération du dernier numéro de justificatif pour une note de frais
     */
    getLastNumJustificatif(idNDF: number): Observable<number> {
        //Récupération du dernier numéro de justificatif
        return this.http.post<Result>(`${environment.baseUrl}/controller/NDF/getLastNumJustificatif/${idNDF}`,null).pipe(
            map(result => result?.data?.lastNumJustificatif)
        );
    }

    /**
     * Chargement d'un frais ou d'une dépense (déterminé suivant l'identifiant de l'objet passé en paramètre)
     *
     * @param depenseFrais Dépense / frais à charger
     */
    loadDepenseFrais(depenseFrais: any): Observable<Result> {
        //Vérification de la présence de l'identifiant d'un frais
        if (depenseFrais.idFrais) {
            //Retour du frais
            return this.http.post<Result>(`${environment.baseUrl}/controller/NDF/loadFrais/${depenseFrais.idFrais}`,null);
        } else {
            //Ce n'est pas un frais, donc retour d'une dépense
            return this.http.post<Result>(`${environment.baseUrl}/controller/NDF/loadDepense/${depenseFrais.idDepense}`,null);
        }
    }

    /**
     * Enregistrement d'une dépense
     * @param idNDF l'id de la note de frais lié à la dépense
     * @param depense La dépense sauvegardée
     * @param idFrais l'id du frais dans le cas où la dépense est issue d'un frais généré par ocr
     * @param libelleTrajet libellé du trajet si existant
     */
    saveDepense(idNDF: number,depense: any,idFrais: number,libelleTrajet: string = null)
        : Observable<{ depense: any,montantDepense: number,montantARembourser: number,montantRemboursable: number,codeErreur: number }> {
        //Enregistrement de la dépense
        return this.http.put<Result>(
            `${environment.baseUrl}/controller/NDF/saveDepense?idNDF=${idNDF}&idFrais=${idFrais ?? 0}${!!libelleTrajet ? '&libelleTrajet=' + libelleTrajet : ''}`
            ,depense)
            .pipe(map(result => result?.data));
    }

    /**
     * Suppression d'une dépense
     */
    deleteDepense(idNDF: number,idDepense: number): Observable<Result> {
        //Suppression de la dépense
        return this.http.delete<Result>(`${environment.baseUrl}/controller/NDF/deleteDepense/${idNDF}/${idDepense}`);
    }

    /**
     * On supprime le frais en le basculant dans la liste global
     * @param idNDF     id de la ndf
     * @param idFrais   id du frais
     * @return observable de result
     */
    unlinkFraisWithNdf(idNDF: number,idFrais: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/NDF/unlinkFraisWithNdf/${idNDF}/${idFrais}`, null);
    }

    /**
     * Suppression des frais et des dépenses en masse
     *
     * @param idNDF         id de la ndf
     * @param listeFrais    Liste des frais/dépenses à supprimer
     * @return observable de result
     */
    supprimerFraisMasse(idNDF: number,listeFrais: Array<ListeDepenseFrais>): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/NDF/supprimerFraisMasse/${idNDF}`,{
            listeIdFrais: listeFrais.filter(frais => !frais.idDepense).map(frais => frais.idFrais),
            listeIdDepense: listeFrais.filter(frais => !!frais.idDepense).map(frais => frais.idDepense)
        });
    }

    /**
     * Récupération des zones utilisateurs
     */
    loadZoneUtilisateur(idNDF: number, idPrestation: number, idDepense: number): Observable<{listeZu: ZoneUtilisateur[]}> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/NDF/loadZU/${idNDF}/${idPrestation ?? 0}/${idDepense ?? 0}`,null).pipe(
            map((result: Result) => result?.data)
        );
    }

    /**
     * Récupération de la liste des documents en attentes d'enregistrement lors de l'enverture d'un frais qui n'est pas encore une dépense
     * 
     * @param   idFrais L'id du frais
     * @returns La liste des documents
     */
    loadPendingFraisDocumentToLink(idFrais: number): Observable<Result> {
        return this.http.post<Result>(
            `${environment.baseUrl}/controller/Document/listeDocument?contexte_courant=${ContextDocument.FRAIS}&id_objet=${idFrais}&context_document=${ContextDocument.FRAIS}`, null);
    }

    /**
     * Envoi du rejet
     * @param idNdf id de la ndf
     * @param idFrais  id du frais
     * @param motif motif du rejet
     * @returns observable de result
     */
    rejeterFrais(idNdf:number, idFrais: number, motif: string): Observable<Result> {
        const data: {motif: string, listeDepense: number[]} = {
            motif: motif,
            listeDepense: [idFrais]
        }        

        return this.http.post<Result>(
            `${environment.baseUrl}/controller/NDF/rejetFrais/${idNdf}`, data);
    }

    /**
     * Méthode de chargement du détail du calcul d'une IK
     *
     * @param idDepense l'ID de la dépense
     * @param idVehicule l'ID du véhicule
     */
    loadDetailCalculIk(idDepense: number, idVehicule: number): Observable<{ vehicule: Vehicule, compteurDepense: CompteurDepense, listeDetailBareme: CalculIkDetail[] }> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/IK/showCalculFrais?idDepense=${idDepense}&idVehicule=${idVehicule}`, null).pipe(
            map((result: Result) => result.data)
        );
    }
}
