<mat-card>
    <mat-card-title>
        <span
                [class.success]="ndf.lastWorkflowHistoriqueActionAlerte?.niveau == NiveauAlerte.NO_CONTROL"
                [class.error]="ndf.lastWorkflowHistoriqueActionAlerte?.niveau == NiveauAlerte.ERROR">
            {{ ndf.typeEntite.libelle }}
            <proprietaire [proprietaireUser]="ndf.user"></proprietaire>
        </span>
    </mat-card-title>
    <mat-card-content>
        <card-alerte *ngIf="ndf.lastWorkflowHistoriqueActionAlerte" [niveau]="ndf.lastWorkflowHistoriqueActionAlerte.niveau">
            <preview-alerte [alerte]="ndf.lastWorkflowHistoriqueActionAlerte"></preview-alerte>
        </card-alerte>
        <form autocomplete="off" name="form_generalites">
            <fieldset [disabled]="!canModifier">
                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-4"><span translate="ndf.generalites.objet"></span></label>
                            <div class="col-md-6">
                                <mat-form-field class="d-flex">
                                    <input matInput name="objet" [(ngModel)]="ndf.objet"/>
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="ndf.omPermanent" class="row">
                            <!-- Lien vers l'od permanent lié -->
                            <label class="col-md-4"><span [translate]="'ndf.generalites.omp'"></span></label>
                            <div class="col-md-6">
                                <span [nioTooltip]="ompTooltip" [nioTooltipPosition]="'track'">
                                    <span>{{ ndf.omPermanent.idOMPermanent }} - {{ ndf.omPermanent.objet }} </span>
                                    <a [routerLink]="" (click)="navigateToOMP()">
                                        <span class="material-icons align-middle">open_in_new</span>
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="ndf.od" class="row">
                            <!-- Lien vers l'od lié -->
                            <label class="col-md-4"><span [translate]="'ndf.generalites.od'"></span></label>
                            <div class="col-md-6">
                                <span [nioTooltip]="odTooltip" [nioTooltipPosition]="'track'">
                                    <span>{{ ndf.od.idOd }} - {{ ndf.od.objet }} </span>
                                    <a [routerLink]="" (click)="navigateToOD()">
                                        <span class="material-icons align-middle">open_in_new</span>
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-md-4"><span translate="ndf.generalites.montantDepense"></span></label>
                            <div class="col-md-6">
                                <div class="d-flex">
                                    <span>{{ ndf.montantDepenses | montant:ndf.devise.code }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="listeComptesBancaires?.length">
                            <label class="col-md-4"><span translate="ndf.generalites.compteBancaire"></span></label>
                            <div class="col-md-6">
                                <mat-form-field class="d-flex">
                                    <mat-select name="compteBancaire" [(ngModel)]="ndf.compte" [compareWith]="compareComptesBancaires" required="true" [disabled]="!canModifier || isCompteBancaireDisabled">
                                        <mat-option *ngFor="let compteBancaire of listeComptesBancaires" [value]="compteBancaire">{{ compteBancaire.libelle }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row" *ngIf="settings.isPeriode">
                            <label class="col-md-4"><span translate="ndf.generalites.moisImputation"></span></label>
                            <div class="col-md-4">
                                <mat-form-field class="d-flex">
                                    <mat-select name="periode" [(ngModel)]="ndf.periode" [compareWith]="comparePeriodes" [required]="settings.isPeriodeObligatoire" [disabled]="!canModifier">
                                        <mat-option *ngFor="let periode of settings.listePeriodes" [value]="periode">{{ periode.libelle }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-md-4"><span translate="ndf.generalites.montantRemboursable"></span></label>
                            <div class="col-md-6">
                                <div class="d-flex">
                                    <span>{{ ndf.montantRemboursable | montant:ndf.devise.code }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-md-4"><span translate="ndf.generalites.montantARembourser"></span></label>
                            <div class="col-md-6">
                                <div class="d-flex">
                                    <span>{{ ndf.montantARembourser | montant:ndf.devise.code }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Zones utilisateurs-->
                <zone-utilisateur #zu="zu" [listeZus]="ndf.listeZU" [listeZuSaisies]="ndf.listeZUSaisies" [readonly]="!canModifier" canListeZuChange="true"></zone-utilisateur>

                <div class="row">
                    <label class="col-md-2 align-items-start p-t-15"><span translate="ndf.generalites.remarque"></span></label>
                    <div class="col-md-10">
                        <mat-form-field class="d-flex">
                            <textarea matInput rows="3" name="remarque" [(ngModel)]="ndf.remarque"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </fieldset>
        </form>
    </mat-card-content>
</mat-card>

<!-- Template du tooltip d'information de l'OMP -->
<ng-template #ompTooltip>
    <form autocomplete="off" class="nio-tooltip-details">
        <table>
            <tr>
                <td><label translate>omp.generalites.destination</label></td>
                <td>{{ ndf.omPermanent.ville ? ndf.omPermanent.ville.libelle + ', ' + ndf.omPermanent.pays?.libelle : ndf.omPermanent?.pays?.libelle }}</td>
            </tr>
            <tr>
                <td><label translate>omp.generalites.debut</label></td>
                <td>{{ ndf.omPermanent.dateDebut | date:'shortDate' }} {{ ndf.omPermanent.heureDebut }}</td>
            </tr>
            <tr>
                <td><label translate>omp.generalites.fin</label></td>
                <td>{{ ndf.omPermanent.dateFin | date:'shortDate' }} {{ ndf.omPermanent.heureFin }}</td>
            </tr>
        </table>
    </form>
</ng-template>

<!-- Template du tooltip d'information de l'OD -->
<ng-template #odTooltip>
    <form autocomplete="off" class="nio-tooltip-details">
        <table>
            <tr>
                <td><label translate>od.generalites.destination</label></td>
                <td>{{ ndf.od.ville ? ndf.od.ville.libelle + ', ' + ndf.od.pays?.libelle : ndf.od.pays?.libelle }}</td>
            </tr>
            <tr>
                <td><label translate>od.generalites.debutMission</label></td>
                <td>{{ saisieTemps.debutForfaitInitial | date:'short' }}</td>
            </tr>
            <tr>
                <td><label translate>od.generalites.finMission</label></td>
                <td>{{ saisieTemps.finForfaitInitiale | date:'short' }}</td>
            </tr>
            <tr *ngIf="saisieTemps.saisie && saisieTemps.debutForfait">
                <td><label translate>od.generalites.debutSaisie</label></td>
                <td>{{ saisieTemps.debutForfait | date:'short' }}</td>
            </tr>
            <tr *ngIf="saisieTemps.saisie && saisieTemps.finForfait">
                <td><label translate>od.generalites.finSaisie</label></td>
                <td>{{ saisieTemps.finForfait | date:'short' }}</td>
            </tr>
        </table>
    </form>
</ng-template>
