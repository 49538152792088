import {Component,Input,OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from "@angular/router";
import {Store} from '@ngrx/store';
import {AppState} from '@domain/appstate';
import {ListViewItem} from '@domain/common/list-view/list-view-item';
import {ListeNdf} from '@domain/ndf/liste-ndf';
import {TypeProfil} from '@domain/user/user';
import {environment} from '@environments/environment';
import {AlerteComponent} from '@components/workflow/alerte.component';
import {first} from "rxjs/operators";

@Component({
    host: {'data-test-id': 'ndf-list-item'},
    templateUrl: './ndf-list-item.component.html'
})
export class NdfListItemComponent extends ListViewItem<ListeNdf> implements OnInit {
    /** Elément courant */
    @Input() data: ListeNdf;

    /** Profil de l'utilisateur connecté **/
    fonction: TypeProfil;

    /** Accès à l'enum dans la vue */
    readonly TypeProfil = TypeProfil;

    /** URL de base */
    baseUrl: string = environment.baseUrl;

    /**
     * Constructeur
     */
    constructor(protected router: Router,protected store: Store<AppState>,protected matDialog: MatDialog) {
        //Héritage
        super();
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Sélection du profil de l'utilisateur
        this.store.select(state => state.session.user.fonction).pipe(first()).subscribe(fonction => this.fonction = fonction);
    }

    /**
     * Ouverture d'un élément
     */
    navigateTo(): void {
        //Navigation vers l'élément
        this.router.navigate(['NDF',this.data?.getId()]);
    }

    /**
     * Ouverture d'un OD
     */
    openOd(idOd: number): void {
        //Navigation vers l'élément
        this.router.navigate(['OD',idOd]);
    }

    /**
     * Ouverture d'un OMP
     */
    openOmp(idOmp: number): void {
        //Navigation vers l'élément
        this.router.navigate(['OMP',idOmp]);
    }

    /**
     * Affichage de la popup de la liste des alertes
     */
    showListeAlertes(): void {
        //Affichage de la popup de la liste des alertes
        this.matDialog.open(AlerteComponent,{
            data: {
                alertes: this.data.listeAlertes.listeAlertes
            }
        });
    }

    /** Getter pour le mode Collaborateur */
    get isCollabMode(): boolean {
        return this.fonction == TypeProfil.COLLABORATEUR;
    }
}
