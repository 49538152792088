import {Component,Inject} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {TravelHubConfig} from "@domain/travel/travel-hub-config";
import {TypeCodeErreur} from "@domain/common/http/result";
import {AdminTravelhubService} from "@components/admin/voyages/travelhub/admin-travelhub.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {filter,finalize} from "rxjs/operators";

/**
 * Composant de création d'une configuration TravelHub
 *
 * @author Laurent Convert
 * @date 03/11/2023
 */
@Component({
    host: {'data-test-id': 'travelhub-config-add'},
    templateUrl: './travelhub-config-add.component.html'
})
export class TravelhubConfigAddComponent {
    /** Configuration en cours de création / modification */
    config: TravelHubConfig;

    /** Indicateur d'enregistrement en cours */
    isSaving: boolean = false;

    /** Indicateur de suppression en cours */
    isDeleting: boolean = false;

    /** Indicateur du test de la configuration en cours */
    isTesting: boolean = false;

    /** Indicateur de traitement en cours (enregistrement ou suppression) */
    get isProcessing(): boolean {
        return this.isSaving || this.isDeleting || this.isTesting;
    }

    /**
     * Constructeur
     *
     * @param data Données passées à la popup
     * @param matDialogRef Référence vers la popup
     * @param adminTravelhubService Service de gestion du module d'administration du TravelHub
     * @param translateService Service de traduction
     * @param toastrService Service de notification
     * @param confirmService popup de confirmation
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            config: TravelHubConfig,
            configSource: TravelHubConfig
        },
        private matDialogRef: MatDialogRef<TravelhubConfigAddComponent>,
        private adminTravelhubService: AdminTravelhubService,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private confirmService: ConfirmService
    ) {
        this.config = data.config;
    }

    /**
     * Suppression de la configuration
     */
    deleteConfig(): void {
        //Affichage de la confirmation de suppression
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe(() => {
                //Suppression en cours
                this.isDeleting = true;

                //Suppression
                this.adminTravelhubService.deleteConfig(this.config.idConfig)
                    .pipe(finalize(() => this.isDeleting = false))
                    .subscribe(result => {
                        if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
                            //Message de succès
                            this.toastrService.success(this.translateService.instant('global.success.suppression'));

                            //Fermeture de la popup
                            this.matDialogRef.close(true);
                        } else {
                            //Message d'erreur
                            TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
                        }
                    },() => {
                        //Message d'erreur
                        TypeCodeErreur.showError(TypeCodeErreur.ERROR_DELETE,this.translateService,this.toastrService);
                    });
            });
    }

    /**
     * Enregistrement de la configuration
     */
    saveConfig(): void {
        const messages: Array<string> = [];

        //Enregistrement en cours
        this.isSaving = true;

        //Enregistrement soit à partir d'une configuration existante, soit from scratch
        (this.data.configSource != null ? this.adminTravelhubService.copyConfig(this.config,this.data.configSource) : this.adminTravelhubService.saveConfig(this.config))
            .pipe(finalize(() => this.isSaving = false))
            .subscribe(result => {
                if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
                    //Récupération du résultat sur l'impact des configurations SBT
                    const listeSBTResult: {nbConfigCreated: number,nbConfigUpdated: number,nbConfigDisabled: number} = result.data.listeSBTResult;

                    //Vérification des configurations SBT impactées
                    if (listeSBTResult.nbConfigCreated == 0 && listeSBTResult.nbConfigUpdated == 0 && listeSBTResult.nbConfigDisabled == 0) {
                        //Aucune configuration rattachée
                        this.toastrService.warning(this.translateService.instant('admin.voyages.travelhub.travelhub.message.aucuneConnexionRattachee'));
                    } else if (listeSBTResult.nbConfigDisabled > 0) {
                        //Nombre de configurations désactivées
                        messages.push(this.translateService.instant(`admin.voyages.travelhub.travelhub.message.configurationsDesactivees.${listeSBTResult.nbConfigDisabled > 1 ? 'plural':'one'}`,{nb: listeSBTResult.nbConfigDisabled}));

                        //Nombre de configurations créées / mise à jour
                        messages.push(this.translateService.instant(`admin.voyages.travelhub.travelhub.message.configurationsCrees.${listeSBTResult.nbConfigCreated > 1 ? 'plural':'one'}`,{nb: listeSBTResult.nbConfigCreated}));
                        messages.push(this.translateService.instant(`admin.voyages.travelhub.travelhub.message.configurationsMiseAjour.${listeSBTResult.nbConfigUpdated > 1 ? 'plural':'one'}`,{nb: listeSBTResult.nbConfigUpdated}));

                        //Message
                        this.toastrService.warning(messages.join("\n"));
                    } else {
                        //Nombre de configurations créées / mise à jour
                        messages.push(this.translateService.instant(`admin.voyages.travelhub.travelhub.message.configurationsCrees.${listeSBTResult.nbConfigCreated > 1 ? 'plural':'one'}`,{nb: listeSBTResult.nbConfigCreated}));
                        messages.push(this.translateService.instant(`admin.voyages.travelhub.travelhub.message.configurationsMiseAjour.${listeSBTResult.nbConfigUpdated > 1 ? 'plural':'one'}`,{nb: listeSBTResult.nbConfigUpdated}));

                        //Message
                        this.toastrService.success(messages.join("\n"));
                    }

                    //Fermeture de la popup
                    this.matDialogRef.close(true);
                } else {
                    const listeExistingConfig: Array<any> = result.data.listeExistingConfig;

                    //Vérification de la liste des configurations existantes
                    if (listeExistingConfig?.length > 0) {
                        //Parcours de la liste
                        listeExistingConfig.forEach(item => {
                            //Ajout à la liste des erreurs
                            messages.push(item.libelle);
                        });

                        //Message d'échec
                        this.toastrService.error(messages.join("\n"),this.translateService.instant("admin.voyages.travelhub.travelhub.message.erreurConflit"));
                    } else {
                        //Message d'échec
                        TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
                    }
                }
            },() => {
                //Message d'erreur
                TypeCodeErreur.showError(TypeCodeErreur.ERROR_SAVE,this.translateService,this.toastrService);
            });
    }

    /**
     * Test de la connexion au service avec la configuration en cours
     */
    testConfig(): void {
        //Test de la connexion en cours
        this.isTesting = true;

        //Test de la connexion
        this.adminTravelhubService.testConfig(this.config,this.data.configSource)
            .pipe(finalize(() => this.isTesting = false))
            .subscribe(result => {
                //Vérification du résultat de la connexion au service
                if (result.data.isOk === true) {
                    //Message de succès de la connexion
                    this.toastrService.success(this.translateService.instant('admin.voyages.travelhub.travelhub.message.testOk'));
                } else {
                    //Message d'échec de la connexion
                    this.toastrService.error(this.translateService.instant('admin.voyages.travelhub.travelhub.message.testKo'));
                }
            },() => {
                //Message d'erreur générique
                TypeCodeErreur.showError(null,this.translateService,this.toastrService);
            });
    }

}