import {NgModule} from '@angular/core';

import {ShareModule} from '@share/share.module';
import {WorkflowModule} from '../workflow/workflow.module';
import {NdfListComponent} from './ndf-list/ndf-list.component';
import {NdfListItemComponent} from './ndf-list/ndf-list-item/ndf-list-item.component';
import {NDFComponent} from './ndf.component';
import {NDFGeneralitesComponent} from './ndf-details/ndf-generalites/ndf-generalites.component';
import {NDFGenerationFraisComponent} from './ndf-details/ndf-generalites/ndf-generation-frais/ndf-generation-frais.component';
import {NDFCreationComponent} from './ndf-creation/ndf-creation.component';
import {NDFService} from './ndf.service';
import {NDFFraisListComponent} from './ndf-details/ndf-frais/ndf-frais-list/ndf-frais-list.component';
import {NDFFraisListItemComponent} from './ndf-details/ndf-frais/ndf-frais-list/ndf-frais-list-item/ndf-frais-list-item.component';
import {NDFFraisAddComponent} from './ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add.component';
import {AnalytiqueModule} from '../analytique/analytique.module';
import {ReferentielExterneModule} from '../referentiel-externe/referentiel-externe.module';
import {DocumentModule} from '../document/document.module';
import {FicheCollaborateurModule} from '../fiche-collaborateur/fiche-collaborateur.module';
import {ZoneUtilisateurModule} from '../zone-utilisateur/zone-utilisateur.module';
import {ParticipantsModule} from "../participants/participants.module";
import {BudgetModule} from "../budget/budget.module";
import {NDFFraisAddMesFraisComponent} from './ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-mes-frais.component';
import {NdfFraisAddMesFraisItemComponent} from './ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-mes-frais-item/ndf-frais-add-mes-frais-item.component';
import {NdfFraisAddMesFraisService} from './ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-mes-frais.service';
import {NDFFraisAddMotifComponent} from './ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-motif/ndf-frais-add-motif.component';
import {PreviewAlerteModule} from '@share/component/alerte/preview-alerte.module';
import {ProprietaireModule} from '@share/component/proprietaire/proprietaire.module';
import {MontantPipe} from '@share/pipe/montant';
import {PopupDetailCalculIkComponent} from './popup-detail-calcul-ik/popup-detail-calcul-ik.component';
import {CompteurDepenseListItemComponent} from './popup-detail-calcul-ik/list-items/compteur-depense-list-item.component';
import {DetailCalculIkListItemComponent} from './popup-detail-calcul-ik/list-items/detail-calcul-ik-list-item.component';
import {NDFCreationItemComponent} from "@components/ndf/ndf-creation/ndf-creation-item/ndf-creation-item.component";
import {NDFCreationListComponent} from "@components/ndf/ndf-creation/ndf-creation-item/ndf-creation-list.component";
import {NdfFraisAddTrajetComponent} from './ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-trajet/ndf-frais-add-trajet.component';
import {TrajetListItemComponent} from "@components/ndf/ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-trajet/trajet-list-item.component";
import {TrajetService} from "@components/ndf/ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-trajet/trajet.service";
import {TrajetRenommerComponent} from "@components/ndf/ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-trajet/trajet-renommer.component";
import {NDFFraisAddTourneeComponent} from "@components/ndf/ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-tournee/ndf-frais-add-tournee.component";
import {MatStepperModule} from "@angular/material/stepper";
import {TourneeModule} from "@components/ndf/ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-tournee/tournee.module";
import {AdminWorkflowModule} from "@components/admin/workflow/admin-workflow.module";
import {TooltipPrestationModule} from "@share/directive/tooltip/prestation/tooltip-prestation.module";
import {AdminOutilsModule} from "@share/component/outils/admin-outils.module";

@NgModule({
	imports: [ShareModule,WorkflowModule,AnalytiqueModule,ReferentielExterneModule,DocumentModule,FicheCollaborateurModule,ZoneUtilisateurModule,ParticipantsModule,BudgetModule,PreviewAlerteModule,ProprietaireModule,AdminOutilsModule,AdminWorkflowModule,MatStepperModule,TourneeModule,TooltipPrestationModule],
	declarations: [NdfListComponent,NdfListItemComponent,NDFComponent,NDFGeneralitesComponent,NDFGenerationFraisComponent,NDFCreationComponent,NDFCreationItemComponent,NDFCreationListComponent,
        NDFFraisListComponent,NDFFraisListItemComponent,NDFFraisAddComponent,NDFFraisAddMesFraisComponent,NdfFraisAddMesFraisItemComponent,NDFFraisAddMotifComponent,PopupDetailCalculIkComponent,
        CompteurDepenseListItemComponent,DetailCalculIkListItemComponent,NdfFraisAddTrajetComponent,NdfFraisAddTrajetComponent,TrajetListItemComponent,TrajetRenommerComponent,
        NDFFraisAddTourneeComponent],
    entryComponents: [NdfListItemComponent,NDFCreationItemComponent],
    exports: [NdfListComponent],
    providers: [NDFService,NdfFraisAddMesFraisService,MontantPipe,TrajetService]
})
export class NDFModule {
}
