import {ListItem} from "@domain/common/list-view/list-item";
import {FactureTypeAnomalie} from "@domain/facture/facture-type-anomalie";
import {ListeAlertes} from "@domain/common/alerte/listeAlertes";
import {NiveauAlerte} from "@domain/common/alerte/alerte";
import {TypeFacture} from "./type-facture";

/**
 * Représente une facture en anomalie (rejets de facture)
 *
 * @author Laurent Convert
 * @date 03/07/2023
 */
export class FactureErreurImport implements ListItem {
	idErreurImport: number;
	numeroFacture: string;
	nomVoyageur: string;
	fournisseur: string;
	montant: number;
	dateFacture: Date;
	numError: FactureTypeAnomalie;
	refCollab: string;
	devise: string;
	masque: boolean;

	idFournisseur: number;
	sens: TypeFacture;
	typeCharge: string;
	idOd: number;
	idTypeEntite: number;

	/* Front only */
	listeAlertes: ListeAlertes;
	isSelected: boolean;


	/**
	 * Constructeur
	 *
	 * @param dto DTO
	 */
	constructor(dto?: any | FactureErreurImport) {
		//Vérification du passage d'un dto
		if (dto) {
			//Copie des informations
			Object.assign(this,dto);

			//Construction de la liste des alertes
			this.buildListeAlertes();
		}
	}

	getKey(): number {
		return this.idErreurImport;
	}

	/** Formatage du collab */
	get voyageurFormate(): String {
		return [this.nomVoyageur,this.refCollab].join(' / ')
	}

	/**
	 * Construction de la liste des alertes
	 */
	buildListeAlertes() {
		const listeAlertes = new ListeAlertes();
		let niveauErreur: NiveauAlerte;

		if (this.numError !== FactureTypeAnomalie.SUCCESS) {
			//Vérification du type d'erreur
			switch (this.numError) {
				//Facture en doublon
				case FactureTypeAnomalie.ERR_DOUBLON:
				//Facture déjà comptabilisée
				case FactureTypeAnomalie.ERR_DEJA_COMPTABILISE:
				//Aucune prestation trouvée pour le type charge
				case FactureTypeAnomalie.ERR_NO_PRESTATION:
				//Le statut de la facture ne permet pas l'intégration
				case FactureTypeAnomalie.ERR_STATUT:
					niveauErreur = NiveauAlerte.ERROR;
					break;
				//Collaborateur ne correspondant pas à celui de la mission
				case FactureTypeAnomalie.COHODMAT:
				//Collaborateur et mission introuvable
				case FactureTypeAnomalie.ODMATINEX:
				//Collaborateur introuvable
				case FactureTypeAnomalie.MATINEX_AND_OD_NOT_USED:
				case FactureTypeAnomalie.MATINEX_AND_ODOK:
				case FactureTypeAnomalie.COLLABNOTDEFINED:
				//Mission introuvable
				case FactureTypeAnomalie.ODINEX_AND_MAT_NOT_USED:
				case FactureTypeAnomalie.ODINEX_AND_MATOK:
				//Existant pour un autre ordre de mission
				case FactureTypeAnomalie.ERR_BAD_OD_4_FACTURE:
				//Type charge absent / inconnu
				case FactureTypeAnomalie.NOCHARGE:
					niveauErreur = NiveauAlerte.WARNING;
					break;
			}
		}

		//Vérification du code d'erreur
		if (!!niveauErreur) {
			//Ajout de l'alerte à la liste
			listeAlertes.add({
				niveau: niveauErreur,
				titre: 'facture.releve.anomalie.alerte.' + FactureTypeAnomalie[this.numError] + '.title',
				message: 'facture.releve.anomalie.alerte.' + FactureTypeAnomalie[this.numError] + '.message'
			});
		} else { //Legacy
			//Ajout de l'alerte à la liste
			listeAlertes.add({
				niveau: NiveauAlerte.ERROR,
				titre: 'facture.releve.anomalie.anomalie.' + FactureTypeAnomalie[this.numError]
			});
		}

		//Mise à jour de la liste dans le composant avec celle construite
		this.listeAlertes = listeAlertes;
	}

}

