import {AfterViewChecked,AfterViewInit,Component,EventEmitter,Inject,OnDestroy,OnInit,ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialog,MatDialogRef} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {filter,finalize,first} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {forkJoin,Subscription} from 'rxjs';
import * as moment from 'moment';

import {SettingsNFState} from '@domain/settings/settings';
import {NDFService} from '../../../ndf.service';
import {Genre,ModeParticipants,Prestation,TypePrestation} from '@domain/prestation/prestation';
import {Result,TypeCodeErreur} from '@domain/common/http/result';
import {ConfirmService} from '@share/component/confirmation/confirm.service';
import {AppState} from '@domain/appstate';
import {SaisieState} from '@domain/saisie/saisie';
import {SettingsService} from '../../../../settings/settings.service';
import {DocumentListComponent} from "../../../../document/document-list.component";
import {User} from "@domain/user/user";
import {PrestTaux} from "@domain/prestation/prest-taux";
import {FraisService} from "../../../../frais/frais.service";
import {LienParticipant} from "@domain/ndf/lien-participant";
import {ParticipantsPopinComponent} from "../../../../participants/popin/participants-popin.component";
import {ZoneUtilisateurComponent} from '../../../../zone-utilisateur/zone-utilisateur.component';
import {TypeEntiteNF} from "@domain/typeentite/typeEntite";
import {LieuDepense} from "@domain/typeentite/typeEntiteParam";
import {Omp} from "@domain/omp/omp";
import {Od} from "@domain/od/od";
import {Ville} from "@domain/geographie/ville";
import {Pays} from "@domain/geographie/pays";
import {TypePortee} from "@domain/workflow/workflow";
import {TypeGeographie} from "@domain/geographie/typeGeographie";
import {NgModel} from "@angular/forms";
import * as typeEntiteActions from "../../../../../reducers/type-entite";
import {GeographieView} from "@domain/geographie/geographieView";
import {TypeParticipant} from "@domain/participant/participant-avec-favoris";
import {ContextDocument} from "@domain/document/context-document"
import {NDFFraisAddMesFraisComponent} from './ndf-frais-add-mes-frais/ndf-frais-add-mes-frais.component';
import {IntegrationMesFrais} from '@domain/ndf/integration-mes-frais';
import {Document} from "@domain/document/document";
import {NDFFraisAddMotifComponent} from './ndf-frais-add-mes-frais/ndf-frais-add-motif/ndf-frais-add-motif.component';
import {MatTabGroup} from "@angular/material/tabs";
import {FraisErreur} from "@domain/ndf/frais-erreur"
import {FrequenceAutorisee} from "@domain/ndf/frequence-autorisee";
import {NiveauAutorisation} from "@domain/vehicule/autorisation";
import {PopupDetailCalculIkComponent} from "@components/ndf/popup-detail-calcul-ik/popup-detail-calcul-ik.component";
import {ODService} from "@components/od/od.service";
import {SaisieTemps} from "@domain/od/saisie-temps";
import {Ndf} from "@domain/ndf/ndf";
import {ListeTrajet,TrajetType} from "@domain/Trajet/ListeTrajet";
import {NDFFraisAddTourneeComponent} from "@components/ndf/ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-tournee/ndf-frais-add-tournee.component";
import {Tournee} from "@domain/Tournee/tournee";
import {TourneeService} from "@components/ndf/ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-tournee/tournee.service";
import {TypeDistance} from '@domain/prestation/unite';
import {Page} from "@domain/common/http/list-result";
import {TypeBareme} from "@domain/prestation/TypeBareme"
import {TrajetService} from "@components/ndf/ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-trajet/trajet.service";
import {TrajetRenommerComponent} from "@components/ndf/ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-trajet/trajet-renommer.component";
import {AdresseService} from "@share/component/adresse/adresse.service";
import {TooltipPrestationComponent} from "@share/directive/tooltip/prestation/tooltip-prestation.component";


@Component({
    host: {'data-test-id': 'ndf-frais-add'},
    templateUrl: './ndf-frais-add.component.html',
    styles: [
        '::ng-deep #fraisAddForm .mat-tab-body-content { padding-top: 10px; }'
    ]
})
export class NDFFraisAddComponent implements OnInit,AfterViewInit,AfterViewChecked,OnDestroy {
    /** Définition de la classe pour l'utilisation dans la page HTML */
    ContextDocument = ContextDocument;
    NdfFraisAddTab = NdfFraisAddTab;
    TypeDistance = TypeDistance;
    TooltipPrestationComponent = TooltipPrestationComponent;

    /* Déclaration pour accès dans le template */
    Genre = Genre;
    TypeParticipant = TypeParticipant;
    TypePortee = TypePortee
    TypeBareme = TypeBareme;

    /** Indicateur d'enregistrement en cours */
    isSaving: boolean = false;

    /** Indicateur de suppression en cours */
    isDeleting: boolean = false;

    /** Indicateur de rejet en cours */
    isRejecting: boolean = false;

    /** Indicateur d'ajout à la note en cours */
    isAddingToNote: boolean = false;

    /** Cours taux */
    coursTaux: number

    /** Indicateur de traitement en cours (enregistrement ou suppression ou rejet du frais) */
    get isProcessing(): boolean {
        return this.isSaving || this.isDeleting || this.isRejecting || this.isAddingToNote;
    }

    /** Indicateur de chargement en cours */
    isLoading: boolean = false;

    /** Indique si la liste est vide */
    isListeEmpty: boolean = false;

    /** Types de prestation **/
    TypePrestation = TypePrestation;

    /** Liste des participants (invités) */
    listeParticipants: Array<LienParticipant>;

    /** Modes de gestion des participants */
    ModeParticipants = ModeParticipants;

    /** Informations de saisie */
    saisieState: SaisieState = null;

    /** Liste des souscriptions */
    private listeSouscriptions: Array<Subscription> = new Array<Subscription>();

    /** Montant unitaire modifiable (pour les prestations de type forfait) */
    montantUnitaire: number | string = 0;

    /** Utilisateur connecté */
    user: User;

    /** Type entité de la ndf */
    typeEntite: TypeEntiteNF;

    /** Types de lieu autorisés */
    listeTypeGeo = new Array<TypeGeographie>();

    /** Référence vers le composant de la liste des documents */
    @ViewChild('listeDocument',{ static: true })
    listeDocument?: DocumentListComponent;

    /** Référence vers l'autocomplete du lieu */
    @ViewChild('geographie')
    geographie: NgModel;

    /** Composant enfant zone utilisateur */
    @ViewChild(ZoneUtilisateurComponent) childZoneUtilisateurComponent: ZoneUtilisateurComponent;

    /** Évènement de validation de la liste des participants */
    eventSaveParticipant: EventEmitter<Array<LienParticipant>> = new EventEmitter<Array<LienParticipant>>();

    /** Onglet courant */
    currentTab: NdfFraisAddTab = NdfFraisAddTab.saisieFrais;

    /** Montant saisie à l'initialisation de l'objet */
    montantInit: number = 0;

    /** Cumul restant à afficher */
    cumulRestant: string;

    /** Composant enfant zone utilisateur */
    @ViewChild(NDFFraisAddMesFraisComponent) childNDFFraisAddMesFraisComponent: NDFFraisAddMesFraisComponent;

    /** Composant onglets */
    @ViewChild("matTabGroup",{ static: true }) matTabGroup: MatTabGroup;

    /** True si une erreur de frequence est levé */
    hasFrequenceAutoriseeError = false;

    /** Indique si le frais en cours de visualisation provient du calcul des IJ */
    isIJ: boolean = false;

    /** Indemnité de l'IJ */
    indemniteIJ: PrestTaux;

    /** Import de l'énum pour le DOM */
    niveauAutorisation: typeof NiveauAutorisation = NiveauAutorisation;

    /** La saisie des temps */
    saisieTemps: SaisieTemps;

    /** Indique si la prestation actuelle est une ik */
    isIk: boolean = false;

    /** Mémorise l'état de validité sur le nombre de PJ au chargement */
    wasPjValid: boolean;

    /** Flag indiquant que les PJ ont changé et que la liste des ndf doit être rechargée */
    needRefreshAfterPjChanged: boolean = false;

    /** Indique si la quantité est bloquée par la selection d'un trajet admin */
    isQuantiteBlockedByAdminTrajet = false;

    /** Indique si peut afficher la checkbox retenir trajet */
    canShowRetenirTrajet = false;

    /** Indique si l'user souhaite retenir un trajet */
    isRetenirTrajet = false;

    /** Données transmises lors de la fermeture manuelle de la popup */
    get dialogCloseResult() {
        return {
            //Rechargement de la liste dû à l'ajout / suppression d'une PJ
            refresh: this.needRefreshAfterPjChanged,
            //Rechargement des alertes si le contrôle sur la PJ obligatoire a changé
            refreshAlertsAndSyncWF: this.data.frais.prestation?.pieceJointeObligatoire && this.wasPjValid !== this.isPJValid()
        };
    }

    /**
     * Constructeur
     */
    constructor(private ndfService: NDFService,
                private store: Store<AppState>,
                private toastrService: ToastrService,
                private translateService: TranslateService,
                private confirmService: ConfirmService,
                private settingsService: SettingsService,
                public fraisService: FraisService,
                private odService: ODService,
                private tourneeService: TourneeService,
                private trajetService: TrajetService,
                private adressService: AdresseService,
                private matDialogRef: MatDialogRef<NDFFraisAddComponent,{ refresh: boolean,message?: string }>,
                private matDialog: MatDialog,
                @Inject(MAT_DIALOG_DATA) public data: { frais: any,ndf: Ndf,settings: SettingsNFState,canModifier: boolean }) {
    }

    /**
     * Initialisation
     */
    ngOnInit() {//Vérification du mode édition / visualisation
        if (this.data.frais.idDepense || this.data.frais.idFrais) {
            //Chargement
            this.isLoading = true;

            //Si on a une devise spécifique pour le frais, on l'initialise sur l'objet frais
            if (this.data.frais.deviseCodeFrais != null) {
                this.data.frais.operation.devise = {
                    code: this.data.frais.deviseCodeFrais
                }
            }

            //Initialisation de l'onglet initial
            this.matTabGroup.selectedIndex = this.currentTab;

            //Chargement de l'objet
            this.ndfService.loadDepenseFrais(this.data.frais)
                .pipe(first(),finalize(() => this.isLoading = false ))
                .subscribe((result: Result) => {
                        //Vérification du chargement
                        if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                            //Copie de l'objet chargé dans celui déjà initialisé
                            Object.assign(this.data.frais,result.data.depenseFrais);

                            //On met à jour le cours pour le calcul du forfait et cumul
                            //On ne peut pas directement utiliser le taux de l'opération, car celui-ci n'est pas forcément le taux à partir de la devise entreprise
                            //Alors que le montant du forfait est de la devise entreprise
                            this.updateCoursTaux(this.data.frais.operation.devise.code);

                            //Chargement des référentiels et autres données du formulaire
                            this.loadOnInit();
                        } else {
                            //Message d'erreur
                            this.toastrService.error(this.translateService.instant('global.errors.chargement'));

                            //Fermeture de l'écran
                            this.matDialogRef.close({refresh: false});
                        }
                    },
                    () => {
                        //Message d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.chargement'));

                        //Fermeture de l'écran
                        this.matDialogRef.close({refresh: false});
                    });
        } else {
            //En création, chargement des référentiels et autres données du formulaire
            this.loadOnInit();
        }

        //On récupère la saisie des temps
        if (this.data.ndf?.od != null) {
            this.odService.loadSaisieTemps(this.data.ndf.od.idOd).subscribe((saisie) => {
                this.saisieTemps = saisie;
            });
        }
    }

    /**
     * Après l'initialisation de la vue
     */
    ngAfterViewChecked(): void {
        //Permet de positionner l'indicateur du matTabGroup sur l'onglet sélectionné
        this.matTabGroup.realignInkBar();
    }

    /**
     * Après l'initialisation du composant et des composants enfants
     */
    ngAfterViewInit() {
        //Vérification de la présence du composant d'upload de document (absent en mode lecture seule)
        if (this.listeDocument.documentUploaderComponent) {
            //Abonnements aux évènements d'upload de document
            this.listeSouscriptions.push(
                //Rechargement de la liste nécessaire après un ajout ou une suppression de document
                this.listeDocument.documentUploaderComponent.onDocumentUploaded.subscribe(() => this.needRefreshAfterPjChanged = true)
            );

            //Abonnements aux évènements de suppression de document
            this.listeSouscriptions.push(
                //Rechargement de la liste nécessaire après un ajout ou une suppression de document
                this.listeDocument.documentUploaderComponent.onDocumentRemoved.subscribe(() => this.needRefreshAfterPjChanged = true)
            );
        }
    }

    /**
     * Chargement des référentiels et autres données du formulaire
     */
    private loadOnInit() {
        let ville: Ville;
        let pays: Pays;
        let mission: Od | Omp;

        //Chargement du type entité avec les paramètres de la portée de l'objet
        this.store.dispatch({
            type: typeEntiteActions.FIND_TYPE_ENTITE,
            payload: {
                idTypeEntite: this.data.ndf.typeEntite.idTypeEntite,
                portee: TypePortee.NF
            }
        });

        //Récupération de l'utilisateur connecté
        this.store.select(state => state.session.user).pipe(first()).subscribe(user => this.user = user);

        //Récupération du type entité
        this.store.select(state => state.typeEntite[TypePortee.NF])
            //On prend le premier tel que non null
            .pipe(first((value => !!value)))
            .subscribe(typeEntite => {
                //Récupération du type entité
                this.typeEntite = typeEntite;

                //Construction de la liste des types de lieu autorisés
                if (typeEntite.typeEntiteParam) {
                    this.listeTypeGeo = !typeEntite.typeEntiteParam.gestionVilleNF ? [TypeGeographie.PAYS]
                        : typeEntite.typeEntiteParam.villeObligatoireNF ? [TypeGeographie.VILLE]
                        : [TypeGeographie.PAYS,TypeGeographie.VILLE];
                }

                //En création, initialisation de la géographie avec les paramètres du type entité ou de l'OM/OMP parent
                if (!this.data.frais.idDepense) {
                    //Type entité configuré avec une ville / un pays par défaut
                    if (typeEntite.typeEntiteParam?.lieuDepense == LieuDepense.LIEU_DEPENSE_DEFAUT) {
                        //Vérification de la ville par défaut
                        if (typeEntite.typeEntiteParam.villeDefault) {
                            //Récupération de la ville par défaut
                            ville = typeEntite.typeEntiteParam.villeDefault;
                        //Vérification du pays par défaut
                        } else if (typeEntite.typeEntiteParam.paysDefault) {
                            //Récupération du pays par défaut
                            pays = typeEntite.typeEntiteParam.paysDefault;
                        }
                    //Type entité configuré pour initialisation du lieu avec la destination principale de la mission
                    } else if (typeEntite.typeEntiteParam?.lieuDepense == LieuDepense.LIEU_DEPENSE_OM) {
                        //Récupération de la mission indistinctement du type OD / OMP
                        mission = this.data.ndf.od ?? this.data.ndf.omPermanent;

                        //Vérification de la ville sur la mission
                        if (mission?.ville) {
                            //Récupération de la ville de la mission
                            ville = mission?.ville;
                        //Vérification du pays sur la mission
                        } else if (mission?.pays) {
                            //Récupération du pays de la mission
                            pays = mission?.pays;
                        }
                    }

                    //Vérification de la ville ou du pays pour initialisation du lieu
                    if (!!ville || !!pays) {
                        //Initialisation du lieu
                        this.data.frais.geographie = {
                            id: ville ? ville.idVille : pays?.idPays,
                            type: ville ? TypeGeographie.VILLE : pays ? TypeGeographie.PAYS : null,
                            libelle: ville ? ville.libelle : pays?.libelle,
                            ville: ville,
                            pays: ville ? ville.pays : pays,
                            regionAdmin: ville ? ville.regionAdmin : null,
                            departementAdmin: ville ? ville.departementAdmin : null,
                        } as GeographieView;
                    }
                }
            });

        //Vérification de la présence d'une IJ
        this.isIJ = this.data.frais.numIndemnite != 0;

        //Met à jour isIk
        this.updateIsIk(this.data.frais.prestation);

        //Edition d'une dépense existante ou d'un frais
        if (this.data.frais.idDepense > 0 || this.data.frais.idFrais > 0) {
            //Le pays est récupéré sur la dépense ou sur le frais
            const currentPays = this.data.frais.operation?.pays ?? this.data.frais?.paysFrais;

            //Définition de la géographie
            this.data.frais.geographie = (this.data.frais.ville?.idVille || currentPays?.idPays) && {
                id: this.data.frais.ville?.idVille || currentPays?.idPays,
                type: this.data.frais.ville && TypeGeographie.VILLE || currentPays && TypeGeographie.PAYS,
                libelle: this.data.frais.ville?.libelle || currentPays?.libelle,
                ville: this.data.frais.ville,
                pays: currentPays,
                regionAdmin: this.data.frais.ville ? this.data.frais.ville.regionAdmin : null,
                departementAdmin: this.data.frais.ville ? this.data.frais.ville.departementAdmin : null,
            };
        }

        //Définition de la présence d'un justificatif
        this.data.frais.isJustificatif = this.data.frais.justificatif?.numJustificatif > 0;
        this.data.frais.previousNumJustificatif = this.data.frais.isJustificatif && this.data.frais.justificatif.numJustificatif || null;

        //Ecoute de la saisie
        this.listeSouscriptions.push(
            //Souscription pour observer la nécessité de mettre à jour la combo de sélection des indemnités
            this.store.select(state => state.saisie).subscribe((saisieState) => {
                let prestTaux: PrestTaux;

                //Récupération de l'état courant des informations de saisie
                this.saisieState = saisieState;

                //Vérification de la présence des informations requises
                if (this.saisieState?.tauxPrestation) {
                    //Si le frais est associé à une indemnité (objet FraisMission) on recherche le taux (objet PrestTaux)
                    //Sinon sélection de l'indemnité par défaut
                    prestTaux = this.findTaux(this.data.frais.taux ? this.data.frais.taux.idTaux : 0);

                    //Dans le cas des IJ
                    if (this.isIJ) {
                        //Récupération de l'indemnité associée ou celle par défaut
                        this.indemniteIJ = prestTaux;
                    } else if (prestTaux) {
                        this.data.frais.taux = this.fraisService.prestTauxToFraisMission(prestTaux);
                    }
                }

                //Mise à jour du cumul
                this.updateCumul();
            })
        );

        //Mise à jour du taux de prestation
        this.refreshTauxPrestation();

        //Mise à jour des taxes
        this.data.frais.saisieTaxe = this.data.frais.listeTaxes?.length && {
            listeTaux: this.data.frais.listeTaxes
        } || null;

        //Rafraichissement de la taxe liée à la prestation
        this.refreshTaxeForPrestation();

        //Initialisation du montant unitaire
        this.montantUnitaire = this.data.frais.ftaux || 0;

        //Initialisation de la liste des participants avec des instances de la classe
        this.listeParticipants = this.data.frais.listeParticipants = this.data.frais.listeParticipants?.map(lien => new LienParticipant(lien)) ?? [];

        //Ajout de la souscription à la liste pour être supprimée lors de la destruction du composant
        this.listeSouscriptions.push(
            //Souscription à l'évènement de validation des participants dans la popin
            this.eventSaveParticipant.subscribe((listeParticipantsFromPopin: Array<LienParticipant>) => {
                //Vidage de la liste
                this.listeParticipants.splice(0);

                //Ajout des participants à la liste
                this.listeParticipants.push(...listeParticipantsFromPopin);
            })
        );

        //Chargement des documents en attente de lien
        this.initFraisPendingDocumentToLink();

        //Pour un frais ocr il faut forcer la saisie du justificatif
        if (this.data.frais.idFrais > 0) {
            this.onJustificatifChange(true);
            this.data.frais.isJustificatif = true;
        }

        //Si la dépense contient un véhicule
        if (this.data.frais.vehicule) {
            //On le rattache à la prestation qu'elle contient
            this.data.frais.prestation.vehicule = this.data.frais.vehicule;
        }

        //On initialise la valeur saisie avant modification
        this.montantInit = this.data.frais.montant;

        //Récupération de l'état de validité sur le nombre de PJ au chargement
        this.wasPjValid = !this.data.frais.prestation?.pieceJointeObligatoire || this.data.frais.nbPJ > 0;
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Suppression des souscriptions
        this.listeSouscriptions.forEach(s => s.unsubscribe());
    }

    /**
     * Comparaison de devises
     */
    compareDevises(d1: any,d2: any): boolean {
        //Comparaison des codes
        return d1?.code && d2?.code && d1.code == d2.code;
    }

    /**
     * Activation de la présence d'un justificatif
     */
    onJustificatifChange(checked: boolean) {
        //Vérification de l'état de la case à cocher
        if (checked && !this.data.frais.justificatif?.numJustificatif) {
            //Vérification de l'existence d'un numéro de justificatif précédent
            if (this.data.frais.previousNumJustificatif) {
                //Rétablissement du numéro de justificatif
                this.data.frais.justificatif.numJustificatif = this.data.frais.previousNumJustificatif;
            } else {
                //Chargement du dernier numéro de justificatif pour la note de frais
                this.ndfService.getLastNumJustificatif(this.data.ndf.idNDF).pipe(first()).subscribe(numJustificatif => {
                    //Mise à jour du numéro de justificatif si nécessaire
                    this.data.frais.justificatif.numJustificatif = numJustificatif;
                });
            }
        } else if (!checked && this.data.frais.justificatif?.numJustificatif) {
            //Retrait du justificatif
            this.data.frais.justificatif = {};
        }
    }

    /**
     * Changement de lieu de départ ou d'arrivée
     */
    onDepartArriveeChange(event: any) {
        //Calcul de la distance (si possible)
        this.updateDistance();

        //On a changé l'adresse, on peut afficher le bouton retenir projet
        this.canShowRetenirTrajet = true;

    }

    /**
     * Activation du mode Aller/Retour pour le calcul des distances
     */
    onAllerRetourChange(checked: boolean) {
        //Si on ne peut pas afficher 'retenir trajet', on est sur un trajet sélectionné
        if (!this.canShowRetenirTrajet) {
            //Ce sont des données de trajet, on recalcule à la main pour ne pas modifier le calcul
            if (checked) {
                this.data.frais.quantite = this.data.frais.quantite * 2;
            } else {
                this.data.frais.quantite = this.data.frais.quantite / 2;
            }
        } else {
            //On est sur des localisations saisies on lance le calcul
            this.fraisService.findDistance(this.data.frais.localisationDepart,this.data.frais.localisationArrivee,checked).subscribe({
                next: distance => {
                    //Mise à jour de la quantité
                    this.data.frais.quantite = distance;
                }
            });
        }
    }

    /**
     * Changement de prestation
     */
    onPrestationChange(prestation: any) {
        //Contrôle de la prestation par rapport au pays
        if (prestation?.idPays > 0 && this.data.frais.geographie?.pays && prestation.idPays != this.data.frais.geographie?.pays.idPays) {
            this.data.frais.geographie = null;
        }

        //Reset de l'erreur de fréquence
        this.hasFrequenceAutoriseeError = false;

        //Reset du bloquage quantité
        this.isQuantiteBlockedByAdminTrajet = false;

        //Reset de l'indemnité
        this.data.frais.taux = null;

        //Reset du montant si l'ancienne ou si la nouvelle prestation est une ik
        if (this.isIk || prestation?.bareme) {
            this.data.frais.montant = null;
        }

        //Reset de la tournée
        delete this.data.frais.tournee;

        //Si l'ancienne prestation est une IK mais pas la nouvelle
        if (this.isIk && (prestation == null || !prestation.bareme)) {
            //On supprime les localisations
            delete this.data.frais.localisationDepart;
            delete this.data.frais.localisationArrivee;
        }

        //Et on met à jour la variable isIk avec la nouvelle prestation
        this.updateIsIk(prestation);

        //Mise à jour de la quantité
        this.fraisService.onPrestationChangeUpdateQuantite(prestation,this.data.frais);

        //Vérification du type de prestation pour retirer le justificatif
        if (prestation?.type == TypePrestation.FORFAIT && !prestation?.justificatifOBligatoire && !prestation?.justificatifDefaut) {
            //Retrait du justificatif
            this.data.frais.justificatif = {};

            //Annulation de la saisie d'un justificatif
            this.data.frais.isJustificatif = false;
        }

        //Vérification de la présence d'un justificatif par défaut
        if (prestation?.justificatifDefaut) {
            //Saisie par défaut d'un justificatif
            this.data.frais.isJustificatif = true;

            //Mise à jour du numéro de justificatif
            this.onJustificatifChange(true);
        }

        //Initialisation du nombre d'invités externes à 0 dans le cas où le référentiel est désactivé
        if (prestation?.genre == Genre.RECEPTION && !this.data.settings.isReferentielParticipantsExternes && !this.data.frais.nbInvites) {
            this.data.frais.nbInvites = 0;
        }

        //Initialisation du montant unitaire
        this.montantUnitaire = this.data.frais.prestation?.montant;

        //Si la prestation comporte un véhicule
        if (prestation?.vehicule) {
            //On le rattache au frais
            this.data.frais.vehicule = prestation.vehicule;
        } else {
            //On le supprime s'il y en avait un
            delete this.data.frais.vehicule;
        }

        //Rafraichissement du taux de prestation si nécessaire
        this.refreshTauxPrestation();

        //Rafraichissement de la taxe liée à la prestation
        this.refreshTaxeForPrestation();

        //Rafraichissement de la zone utilisateur
        this.refreshZoneUtilisateur();
    }

    /**
     * Changement de géographie
     */
    onGeographieChange(geographie: any) {
        //Rafraichissement du taux de prestation si nécessaire
        this.refreshTauxPrestation();

        //Rafraichissement de la taxe liée à la prestation
        this.refreshTaxeForPrestation();

        //On contrôle la validité de la prestation déjà saisie
        if (this.data.frais.prestation != null && this.data.frais.prestation.idPrestation !== 0) {
            this.fraisService.findPrestation(
                this.data.frais.pays?.idPays || this.data.frais.geographie?.pays?.idPays,
                this.data.ndf.typeEntite.idTypeEntite,
                TypePortee.NF,
                this.data.ndf.idNDF,
                this.data.frais.prestation.idPrestation).subscribe( (page: Page<Prestation>)=> {
                //Si on n'a pas de résultat c'est que la prestation n'est pas valide
                if (page.listeResultats.length === 0) {
                    //On supprime la prestation
                    delete this.data.frais.prestation;
                }
            });
        }
    }

    /**
     * Changement de date
     */
    onDateChange() {
        //Rafraichissement du taux de prestation si nécessaire
        this.refreshTauxPrestation();

        //Rafraichissement de la taxe liée à la prestation
        this.refreshTaxeForPrestation();

        //Dans le cas d'une prestation de type barème
        if (this.data.frais.prestation?.bareme) {
            //On supprime le montant du frais. Il sera calculé à l'enregistrement
            delete this.data.frais.montant;
        }
    }

    /**
     * Fonction appelée à la modification de la quantité
     */
    onQuantiteChange(): void {
        //Dans le cas d'une prestation de type barème
        if (this.data.frais.prestation?.bareme) {
            //On supprime le montant du frais. Il sera calculé à l'enregistrement
            delete this.data.frais.montant;
        }
    }

    /**
     * Retourne le montant calculé d'une prestation de type forfait.
     * Si la prestation n'est pas de ce type, retourne undefined.
     */
    getMontantForfait(): number {
         //Vérification de la présence d'une IJ
         if (this.isIJ) {
            //Retour de la valeur calculé automatiquement lors de la création de l'IJ
            return this.fraisService.getValueAsNumber(this.montantUnitaire);
        } else if (this.data.frais.prestation?.type == TypePrestation.FORFAIT) {
            //Vérification du type de prestation Forfait
            //Si c'est une prestation de type barème
            if (this.data.frais.prestation?.bareme) {
                //On renvoie le montant s'il a déjà été calculé ou zéro
                return this.data.frais.montant ?? 0;
            } else {
                //Calcul
                return (this.fraisService.getValueAsNumber(this.data.frais.quantite) || 0) * (this.getTauxForPrestation() || 0);
            }
        } else {
            return undefined;
        }
    }

    /**
     * Fonction d'ouverture de la popup de détail du calcul d'une IK
     */
    viewDetailCalculIk(): void {
        //Ouverture de la popup
        this.matDialog.open(PopupDetailCalculIkComponent,{
            data: {
                idDepense: this.data.frais.idDepense,
                idVehicule: this.data.frais.vehicule.idPa
            }
        });
    }

    /**
     * Handler du bouton enregistrer
     */
    onSave() {
        //Si on doit retenir le trajet, on ouvre une popin pour le changement de nom
        if (this.isRetenirTrajet) {
            const formatedLibelle = this.adressService.getVilleFromAdress(this.data.frais.localisationDepart)
                + ' / ' + this.adressService.getVilleFromAdress(this.data.frais.localisationArrivee);

            this.matDialog.open(TrajetRenommerComponent,{
                minWidth: '700px',
                data: {
                    libelle: formatedLibelle
                },
            }).afterClosed().subscribe((libelle: string) => {
                //Si on a un libellé saisi, c'est que l'user n'a pas annulé
                if (!!libelle) {
                    //On sauvegarde
                    this.saveDepense(libelle)
                }
            });
        } else {
            //On sauvegarde directement
            this.saveDepense()
        }
    }

    /**
     * Enregistrement de la dépense
     */
    saveDepense(libelleTrajet: string = null) {
        //Enregistrement en cours
        this.isSaving = true;

        //Contrôle la validité des champs, bloque la sauvegarde si ce n'est pas le cas.
        if (!this.isValid()) {
            this.toastrService.error(this.translateService.instant('global.errors.formInvalid'));
            return;
        }

        //Reset de l'erreur de fréquence
        this.hasFrequenceAutoriseeError = false;

        //Mise à jour de la dépense
        this.data.frais.operation.pays = this.data.frais.geographie.pays;
        this.data.frais.ville = this.data.frais.geographie.ville || null;

        //Cas d'une prestation de type forfait (hors barème, car dans ce cas le calcul est fait côté back)
        if (this.data.frais.prestation.type === TypePrestation.FORFAIT && !this.data.frais.prestation?.bareme) {
            //On récupère le montant unitaire qui servira à calculer le montant côté back
            this.data.frais.montant = this.getMontantForfait();
        }

        //Mise à jour de la taxe de la dépense
        this.data.frais.saisieTaxe = this.data.frais.saisieTaxe && {
            ...this.data.frais.saisieTaxe,
            updateMontants: true,
            listeTaxes: this.data.frais.saisieTaxe.listeTaux.filter(taux => taux.montantBase)
        } || null;

        //On met à jour les ZU avant la sauvegarde
        this.data.frais.listeZU = this.childZoneUtilisateurComponent.getListeZoneUtilisateursBeforeSave();

        //On supprime le véhicule de la prestation pour ne pas avoir d'erreur de désérialisation (le véhicule étant stocké dans la dépense directement)
        if (this.data.frais.prestation.vehicule) {
            delete this.data.frais.prestation.vehicule;
        }

        //Enregistrement de la dépense
        this.ndfService.saveDepense(this.data.ndf.idNDF,this.data.frais,this.data.frais.idFrais,libelleTrajet)
            .pipe(first())
            .subscribe({
                next: data => {
                    //Vérification de l'enregistrement
                    if (data.depense) {
                        //Mise à jour des montants de la NDF
                        this.data.ndf.montantDepenses = data.montantDepense;
                        this.data.ndf.montantRemboursable = data.montantRemboursable;
                        this.data.ndf.montantARembourser = data.montantARembourser;

                        //S'il y a des documents en attente de liaison avec l'objet
                        if (this.listeDocument?.hasPendingDocumentToLink()) {
                            const observables = [];
                            //Appel pour link les documents
                            observables.push(this.listeDocument.linkAllPendingDocument(data.depense.idDepense, ContextDocument.NDF_V));

                            const justificatifs: Document[] = this.filterDocumentAndProcessJustificatif(this.listeDocument.listeDocumentToLink);
                            if(justificatifs.length > 0) {
                                //Si on a des justificatifs à envoyer
                                //Appel pour traiter les justificatifs avec l'id dépense
                                observables.push(this.ndfService.processJustificatif(justificatifs,false,this.data.ndf.idNDF,data.depense.idDepense));
                            }

                            forkJoin(observables).subscribe((res) => {
                                //Vérification du succès de l'opération
                                //On ne contrôle pas l'intégration des justificatifs, car sur cet écran, on peut enregistrer des documents qui ne sont pas scannable par l'ocr
                                if (res[0]) {
                                    //Fermeture de l'écran
                                    this.matDialogRef.close({refresh: data != null || this.needRefreshAfterPjChanged});
                                } else {
                                    //Message d'erreur
                                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                                }
                            });
                        } else {
                            //Fermeture de l'écran
                            this.matDialogRef.close({refresh: data != null || this.needRefreshAfterPjChanged});
                        }
                    } else {
                        if (!data.codeErreur) {
                            //Message d'erreur générique
                            this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                        } else if (data.codeErreur == FraisErreur.ANOMALIE_PERIODE) {
                            //Affichage du message d'erreur
                            this.toastrService.error(this.translateService.instant('ndf.frais.erreur.periode'));
                        } else if (data.codeErreur == FraisErreur.ANOMALIE_OM_DATE) {
                            //Affichage du message d'erreur
                            this.toastrService.error(this.translateService.instant('ndf.frais.erreur.omDate'));
                        } else if (data.codeErreur == FraisErreur.ANOMALIE_QUANTITE_AUTORISEE ) {
                            //On récupère la fréquence
                            const frequenceAutorisee = this.data.frais.prestation.frequenceAutorisee;

                            //On change le libellé de la période
                            let periode = frequenceAutorisee == FrequenceAutorisee.JOUR
                                ? 'ndf.frais.erreur.quantiteJ'
                                : frequenceAutorisee == FrequenceAutorisee.SEMAINE
                                    ? 'ndf.frais.erreur.quantiteS'
                                    : frequenceAutorisee == FrequenceAutorisee.ANNEE
                                        ? 'ndf.frais.erreur.quantiteA'
                                        : 'ndf.frais.erreur.quantiteM';
                            //Affichage du message d'erreur
                            this.toastrService.error(this.translateService.instant('ndf.frais.erreur.quantite') + this.translateService.instant(periode));

                            this.hasFrequenceAutoriseeError = true;
                        } else if (data.codeErreur == FraisErreur.ANOMALIE_MONTANT_NEGATIF) {
                            //Affichage du message d'erreur
                            this.toastrService.error(this.translateService.instant('ndf.frais.erreur.montantNegatif'));
                        } else if (data.codeErreur == FraisErreur.ANOMALIE_DEPENSE_FUTURE) {
                            //Affichage du message d'erreur
                            this.toastrService.error(this.translateService.instant('ndf.frais.erreur.depenseFuture'));
                        } else if (data.codeErreur == FraisErreur.ANOMALIE_OM_PERMANENT_DATE) {
                            //Affichage du message d'erreur
                            this.toastrService.error(this.translateService.instant('ndf.frais.erreur.omPermanentDate'));
                        } else {
                            //Message d'erreur
                            this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                        }
                        //Fin de l'enregistrement
                        this.isSaving = false;
                    }
                },
                error: () => {
                    //Message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                    //Fin de l'enregistrement
                    this.isSaving = false;
                }
        });
    }

    /**
     * Suppression de la dépense
     */
    deleteDepense() {
        //Message de confirmation
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                //Suppression en cours
                this.isDeleting = true;

                //Si on est sur une dépense
                if (this.data.frais.idDepense) {
                    //Suppression de la dépense
                    this.ndfService.deleteDepense(this.data.ndf.idNDF,this.data.frais.idDepense).pipe(first(),finalize(() => {
                        this.isDeleting = false;
                    })).subscribe({
                        next: result => {
                            //Vérification de l'enregistrement
                            if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                                //Mise à jour des montants de la NDF
                                this.data.ndf.montantDepenses = result.data.montantDepense;
                                this.data.ndf.montantRemboursable = result.data.montantRemboursable;
                                this.data.ndf.montantARembourser = result.data.montantARembourser;

                                //Fermeture de l'écran
                                this.matDialogRef.close({refresh: true,message: 'global.success.suppression'});
                            } else
                                //Message d'erreur
                                this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                        }
                    });
                } else {
                    //Si on est sur un frais
                    //Suppression du link pour rebascule dans la liste globale
                    this.ndfService.unlinkFraisWithNdf(this.data.ndf.idNDF,this.data.frais.idFrais).pipe(first(),finalize(() => {
                        this.isDeleting = false;
                    })).subscribe({
                        next: result => {
                            //Vérification de l'enregistrement
                            if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                                //Fermeture de l'écran
                                this.matDialogRef.close({refresh: true,message: 'global.success.suppression'});
                            } else
                                //Message d'erreur
                                this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                        }
                    });
                }
            }
        });
    }

    /**
     * Rafraichissement du taux de prestation
     */
    refreshTauxPrestation() {
        this.fraisService.refreshTauxPrestation(
            this.data.frais.prestation,
            this.data.frais.date,
            this.data.frais.geographie?.pays,
            this.data.frais.geographie?.ville,
            this.data.ndf.idNDF
        );
    }

    /**
     * Changement de devise
     */
    onDeviseChange(change: MatSelectChange) {
        this.updateCoursTaux(change?.value?.code);
    }

    /**
     * Mise à jour du cours du taux
     * @param codeDevise Code de la devise à mettre à jour
     * */
    updateCoursTaux(codeDevise: string) {
        //On met à jour la devise, on renseigne la devise entreprise qui est la devise de référence à un taux à 1
        this.fraisService.findCoursForDevise(
            codeDevise,
            this.data.frais.date,
            this.data.settings.deviseEntreprise,
            1
        ).subscribe(cours => {
            this.coursTaux = cours;
            this.updateCumul();
        });
    }

    /**
     * Récupération du taux de la prestation
     */
    getTauxForPrestation(): number {
        return this.fraisService.getTauxForPrestation(
            this.saisieState,
            this.data.settings,
            this.data.frais.operation?.devise?.code,
            this.coursTaux,
            this.data.frais.taux
        );
    }

    /** Met à jour le cumul */
    updateCumul(): void {
        const tauxForPrestation = this.getTauxForPrestation();

        this.cumulRestant =  this.fraisService.getCumulRestant(
            this.saisieState,
            this.data.settings,
            this.data.frais.operation?.devise?.code,
            this.coursTaux,
            tauxForPrestation,
            this.montantInit
        );
    }

    /**
     * Retourne le taux correspondant à un id.
     *
     * @param idTaux Identifiant du taux. Si vide/undefined/null, retourne le taux qui sera utilisé "par défaut"
     */
    findTaux(idTaux?: number): PrestTaux {
        return this.fraisService.findTaux(this.saisieState,idTaux);
    }

    /**
     * Vérification de la taxe saisie
     */
    isTaxeValid(): boolean {
        let isValid: boolean = false;
        let montant: number;
        let somme: number;

        //Lecture du montant de la dépense
        montant = this.data.frais.montant || 0;

        //Vérification de la présence d'une taxe
        if (this.data.frais.saisieTaxe) {
            //Vérification du signe des montants saisis
            isValid = !this.data.frais.saisieTaxe.listeTaux.filter(t => t.montantBase).some(t => t.montantBase >= 0 && montant < 0 || t.montamontantBasent < 0 && montant >= 0);

            //Vérification de la validité de la saisie
            if (isValid) {
                //Calcul de la somme
                somme = this.data.frais.saisieTaxe.listeTaux.filter(t => t.montantBase).reduce((somme,taux) => somme + Number(taux.montantBase) + 100 * (Math.abs(taux.montantBase) - 0.01) / taux.taux,0);

                //Vérification de la somme
                isValid = somme <= Math.abs(montant);
            }
        } else {
            //Taxe valide car absente
            isValid = true;
        }

        return isValid;
    }

    /**
     * Rafraichissement de la taxe liée à la prestation
     */
    refreshTaxeForPrestation() {
        //Vérification de la prestation
        if (this.data.frais.prestation?.idAppTva && this.data.frais.geographie?.pays?.idPays) {
            //Recherche de la taxe
            this.settingsService.findTaxeForVilleAndPays({
                idDomaine: this.data.frais.prestation.idAppTva,
                idPays: this.data.frais.geographie.pays.idPays,
                idVille: this.data.frais.geographie.ville?.idVille,
                date: this.data.frais.date || moment().toDate()
            }).subscribe({
                next: taxe => {
                    //Vérification de la présence de taxe sur la dépense
                    if (this.data.frais.listeTaxes?.length) {
                        //Parcours des taux
                        this.data.frais.listeTaxes.forEach(tauxSaisi => {
                            let idxTaux;

                            //Recherche du taux dans la liste de ceux paramétrés
                            idxTaux = taxe.listeTaux.findIndex(taux => taux.taux == tauxSaisi.taux);

                            //Vérification de la position
                            if (idxTaux != -1)
                                //Mise à jour du taux
                                taxe.listeTaux[idxTaux] = {
                                    ...taxe.listeTaux[idxTaux],
                                    idSaisie: tauxSaisi.idSaisie,
                                    montant: tauxSaisi.montant,
                                    montantBase: tauxSaisi.montantBase,
                                    montantHt: tauxSaisi.montantHt,
                                    montantHtBase: tauxSaisi.montantHtBase,
                                    montantTtc: tauxSaisi.montantTtc,
                                    montantTtcBase: tauxSaisi.montantTtcBase
                                };
                        });
                    }

                    //Mise à jour de la taxe sur la dépense
                    this.data.frais.saisieTaxe = taxe;
                }
            });
        } else {
            //Retrait de la taxe
            this.data.frais.saisieTaxe = null;
        }
    }

    /**
     * Calcul du montant de taxe pour un taux
     */
    computeTaxeMontant(taux) {
        //Vérification du montant
        if (!taux.montantBase) {
            //Calcul du montant
            taux.montantBase = (this.data.frais.montant || 0) * (1 - 1 / (1 + taux.taux / 100));
        } else {
            //Remise à zéro du montant
            taux.montantBase = null;
        }
    }

    /**
     * Affichages des invités
     */
    showListeParticipants() {
        //Ouverture de la popup
        this.matDialog.open(ParticipantsPopinComponent,{
            data: {
                listeParticipant: [...this.data.frais.listeParticipants], // Passage d'une copie
                idObjet: this.data.frais.idDepense ?? 0,
                idNDF: this.data.ndf.idNDF,
                idPrestation: this.data.frais.prestation?.idPrestation,
                settings: this.data.settings,
                idTypeEntite: this.data.ndf.typeEntite?.idTypeEntite,
                idUser: this.user.idUser,
                idUserObjet: this.data.ndf.idUser,
                modeParticipant: this.data.frais.prestation?.modeParticipant ?? ModeParticipants.TOUS,
                contexte: 'NDF_V',
                canModifier: this.data.canModifier,
                eventSaveParticipant: this.eventSaveParticipant
            },
            minWidth: 1000,
            maxWidth: 1200,
            maxHeight: 800
        });
    }

    /**
     * Dénombrement des invités par type
     */
    countInvitesForType(typeInvite: TypeParticipant): number {
        //Retour du nombre d'invités
        return (this.data.frais.listeParticipants as Array<LienParticipant>)?.filter(p => typeInvite == TypeParticipant.INTERNE && p.user || typeInvite == TypeParticipant.EXTERNE && p.participantExterne)?.length || 0;
    }

    /**
     * Vérifie la validité du nombre de participants si prestation "Réception", sinon retourne True
     */
    isInvitesValid(): boolean {
        return this.data.frais.prestation?.genre == Genre.RECEPTION ? this.data.frais.listeParticipants?.length + (this.data.frais.nbInvites ?? 0) + (this.data.frais.collaborateurAbsent ? 0 : 1) > 0 : true;
    }

    /**
     * Vérifie si le justificatif est présent si obligatoire
     */
    isJustifValid(): boolean {
        return !this.data.frais.prestation?.justificatifObligatoire || this.data.frais.isJustificatif;
    }

    /**
     * Vérifie si une pièce jointe est présente si obligatoire
     */
    isPJValid(): boolean {
        return !this.data.frais.prestation?.pieceJointeObligatoire || this.listeDocument?.liste?.data?.nbObjetsTotal > 0;
    }

    /**
     * Vérifie si les données sont valides
     *
     * @return True si valide, False sinon
     */
    isValid(): boolean {
        return this.childZoneUtilisateurComponent.isValid();
    }

    /**
     * Rafraichissement des zones utilisateurs.
     */
    refreshZoneUtilisateur(): void {
        if (this.data.frais.prestation != null) {
            this.ndfService.loadZoneUtilisateur(this.data.ndf.idNDF, this.data.frais.prestation.idPrestation, this.data.frais.idDepense)
                .pipe(first()).subscribe(data => {
                this.data.frais.listeZU = data.listeZu;
            });
        }
    }

    /**
     * Vérifie si le frais est dans les dates de l'OM où OMP
     * Note : Conditionne l'obligation de renseigner la remarque si le frais est hors mission
     */
    isFraisHorsDateOM(): boolean {
        //On réalise le contrôle quand on a une date de frais et une date sur un objet od ou omp
        if (this.data.frais?.date == null
            || (this.data.ndf?.omPermanent == null && ( this.data.ndf?.od == null || !this.data.settings.isCheckDateOm))) {
            return false;
        }

        let isFraisHorsDateOM: boolean = false;
        const dateFrais: moment.Moment = moment(this.data.frais.date);

        //S'il y a une date sur le Frais et que l'option est activée dans la configuration pour l'om ou qu'on a un omp
        if (dateFrais.isValid()) {
            let depart;
            let retour;

            //Si on doit regarder sur l'omp
            if (this.data.ndf?.omPermanent) {
                if (this.data.ndf.omPermanent.dateDebut != null && this.data.ndf.omPermanent.dateFin != null) {
                    depart = moment(this.data.ndf.omPermanent.dateDebut);
                    retour = moment(this.data.ndf.omPermanent.dateFin);
                } else {
                    //On est sur un omp mais on n'a pas de date, pas de contrôle
                    return false;
                }
            //On regarde sur l'od
            } else {
                //On regarde si saisie des temps mission
                if (this.saisieTemps?.debutReel != null && this.saisieTemps?.finReel != null) {
                    depart = moment(this.saisieTemps.debutReel);
                    retour = moment(this.saisieTemps.finReel);
                } else if (this.saisieTemps?.debutForfait != null && this.saisieTemps?.finForfait != null) {
                    //Saisie des temps forfait
                    depart = moment(this.saisieTemps.debutForfait);
                    retour = moment(this.saisieTemps.finForfait);
                } else if (this.data.ndf.od.depart_le != null && this.data.ndf.od.retour_le != null) {
                    //Pas de saisie de temps, on regarde directement sur l'od
                    depart = moment(this.data.ndf.od.depart_le);
                    retour = moment(this.data.ndf.od.retour_le);

                    //On ajoute les heures si nécessaire
                    if (this.data.ndf.od.heureDepart != null) {
                        depart = depart.add(moment.duration(this.data.ndf.od.heureDepart))
                    }
                    if (this.data.ndf.od.heureRetour) {
                        retour =retour.add(moment.duration(this.data.ndf.od.heureRetour));
                    }
                }
            }

            //Si on a bien des dates, on vérifie si on est hors mission
            if (depart != null && retour != null && depart.isValid() && retour.isValid()) {
                //Si on n'est pas sur une IJ
                if (this.data.frais.numIndemnite == 0) {
                    //Si le frais n'est pas dans les dates de la mission
                    if (dateFrais.isBefore(depart, 'day') || dateFrais.isAfter(retour, 'day')) {
                        //On indique que le frais est hors mission
                        isFraisHorsDateOM = true;
                    }
                } else {
                    if (dateFrais.isBefore(depart) || dateFrais.isAfter(retour)) {
                        //On indique que le frais est hors mission
                        isFraisHorsDateOM = true;
                    }
                }
            }
        }

        return isFraisHorsDateOM;
    }

    /**
     * Vérifie si le lieu de la dépense est valide et correspond au type attendu
     */
    isGeographieValid(): boolean {
        return !this.geographie?.invalid && this.listeTypeGeo.includes(this.data.frais?.geographie?.type);
    }

    /**
     * Renvoie true si l'objet est un objet frais dans le back ou false si l'objet est un objet depense dans le back
     * */
    isFrais() {
        return this.data.frais.idFrais != null && this.data.frais.idFrais > 0;
    }

    /**
     * Initialisation des documents en attentes de lien
     */
    initFraisPendingDocumentToLink() {
        //Si la l'objet est un frais
        if(this.isFrais()) {
            this.ndfService.loadPendingFraisDocumentToLink(this.data.frais.idFrais).pipe(first()).subscribe(
                (res) => {
                    if(res.codeErreur == TypeCodeErreur.NO_ERROR) {
                        //On ajoute les documents dans les documents en attentes de lien
                        res.data.listeDocument.data.forEach((d) => {
                            this.listeDocument.listeDocumentToLink.push(d);
                        })
                    }
                    else {
                        this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                    }
                }
            );
        }
    }

    /**
     * Mise à jour de l'onglet sélectionné
     */
    changeTab(tabGroup: MatTabGroup, tabIndex: NdfFraisAddTab) {
        tabGroup.selectedIndex = tabIndex;
    }

    /**
     * Mise à jour du suivi de l'onglet sélectionné
     */
    tabChanged(tabIndex: NdfFraisAddTab) {
        this.currentTab = tabIndex;
    }

    /**
     * Selection de frais dans la liste mes frais
     */
    selectMesFrais() {
        //Début du traitement
        this.isAddingToNote = true;

        //Envoi de l'évent de sélection à l'élément enfant
        this.childNDFFraisAddMesFraisComponent.select()
            .pipe(first(),finalize(() => {
                //Fin du traitement
                this.isAddingToNote = false;
                this.matDialogRef.close({refresh: true});
            }))
            .subscribe({
                next: (integrationMesFrais: IntegrationMesFrais) => {
                    //Contruction du message à afficher
                    const message = this.translateService.instant('ndf.frais.mesFrais.resutatIntegration')
                        + '<br />' + integrationMesFrais.nbDepenseOK + ' ' + this.translateService.instant('ndf.frais.mesFrais.resultatCorrect')
                        + '<br />' + integrationMesFrais.nbDepenseKO + ' ' + this.translateService.instant('ndf.frais.mesFrais.resultatAnomalie');

                    //Affichage d'un message de succès même si des anomalies sont présentes
                    this.toastrService.success(message,'', { enableHtml : true });
                },
                error: () => {
                    //Affichage d'un message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                }
            });
    }

    /**
     * Rejette le frais courant
     */
    rejeterFrais(): void {
        //Ouverture du dialogue de saisie du motif
        this.matDialog.open(NDFFraisAddMotifComponent).afterClosed().subscribe({
            next: (motif: string) => {
                //Si Le motif a été validé on continue le rejet
                if (motif != null) {
                    //Le dialogue de confirmation a été validé on lance le rejet
                    this.isRejecting = true;

                    this.ndfService.rejeterFrais(this.data.ndf.idNDF, this.data.frais.idDepense, motif)
                        .pipe(first(),finalize(() => {
                            //Fin du traitement
                            this.isRejecting = false;
                            this.matDialogRef.close({refresh: true,  message: 'ndf.frais.retourRejet'});
                        }))
                        .subscribe({
                            next: (result: Result) => {
                               if (result.codeErreur === TypeCodeErreur.ERROR_PERMISSION) {
                                    //On met une erreur de permission
                                    this.toastrService.error(this.translateService.instant('global.errors.permission'));
                                } else if (result.codeErreur !== TypeCodeErreur.NO_ERROR) {
                                    //On met une erreur d'enregistrement
                                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                                }
                            },
                            error: () => {
                                this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                            }
                        })
                }
            }
        });
    }

    /**
     * Filtre les documents qui ont déjà été envoyés via le process justificatif
     *
     * @param document  Le ou les documents
     * @returns         La liste des documents
     */
    filterDocumentAndProcessJustificatif(document: Document | Document[]): Document[] {
        let listeDocuments: Array<Document>;

        //Envoi d'une liste de documents
        if (Array.isArray(document)) {
            listeDocuments = document.filter(d => d.saveFile != null);
        } else if(document.saveFile != null) {
            //Envoi d'un seul document
            listeDocuments = [document];
        }

        return listeDocuments;
    }

    /**
     * Evenement qui indique si la liste est vide
     * @param isListeEmpty indicateur qui indique si la liste est vide
     */
    onListeEmpty(isListeEmpty: boolean) {
        this.isListeEmpty = isListeEmpty;
    }

    /**
     * Renvoie true si il faut afficher le bouton ajouter note
     */
    canAjouterNote(): boolean {
        return this.isCreation() && this.currentTab == NdfFraisAddTab.mesFrais
    }

    /**
     * Renvoie true si on est en création
     */
    isCreation(): boolean {
        return !(this.data.frais.idDepense > 0 || this.data.frais.idFrais > 0)
    }

    /**
     * Dans le cas d'une prestation d'IK, on vérifie si le véhicule associé dispose d'une autorisation de circuler pour le jour de la prestation
     *
     * @return true si le véhicule n'est pas autorisé à circuler, false sinon
     */
    hasNotAutorisationCirculer(): boolean {
        return this.data.settings.isAutorisationVehiculeActif
            && this.data.settings.niveauControleAutorisationVehiculeNdf !== NiveauAutorisation.NON_CONTROLE
            && this.data.frais.date
            && this.data.frais.vehicule
            && !this.data.frais.vehicule.listeAutorisation.some(a => moment(a.dateDebut).isSameOrBefore(this.data.frais.date,'day') && moment(a.dateFin).isSameOrAfter(this.data.frais.date,'day'));
    }

    /**
     * Définit si la quantité est modifiable
     */
    isQuantiteModifiable(): boolean {
        //Si la quantité de la prestation est modifiable et pas bloqué pas l'admin trajet et qu'on n'est pas sur une tournée
        return this.data.frais.prestation?.quantiteModifiable && !this.isQuantiteBlockedByAdminTrajet && this.data.frais.tournee == null;
    }

    /**
     * Mise à jour d'isIK
     * @param prestation la prestation à controler
     */
    updateIsIk(prestation: any): void {
        //Contrôle si la prestation est une ik
        this.isIk = prestation?.bareme;
    }

    /**
     * Indique s'il faut afficher l'onglet trajet
     */
    isShowTrajets(): boolean {
        return this.data?.frais?.prestation?.geolocalisation;
    }

    /**
     * Handler qui permet de gérer le trajet et de retourner sur le premier onglet de saisie
     * Appelé par l'onglet trajet
     */
    selectTrajet(listeTrajet: ListeTrajet): void {
        //L'user a sélectionné un trajet, on cache la checkbox
        this.canShowRetenirTrajet = false;

        //Si c'est une tournée
        if (listeTrajet.type === TrajetType.TRAJET_TOURNEE) {
            this.isLoading = true;
            //On récupère la tournée
            this.tourneeService.load(listeTrajet.id, this.data.frais.prestation.idPrestation)
                .pipe(first(),finalize(() => this.isLoading = false))
                .subscribe( (result: Result) => {
                    if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                       this.selectTournee(result.data.tournee);
                    } else {
                        this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                    }
                });
        } else {
            //Si c'est un trajet, on enlève la tournée
            this.data.frais.tournee = null;

            //On met à jour les champs localisation
            this.data.frais.localisationDepart = listeTrajet.adresseDepart;
            this.data.frais.localisationArrivee = listeTrajet.adresseArrivee;

            //On indique si c'est un aller/retour
            this.data.frais.allerRetour = listeTrajet.allerRetour;

            //Si c'est un trajet admin
            if (listeTrajet.idUser == null) {
                //On ne recalcule pas les km
                this.data.frais.quantite = listeTrajet.distance;

                //On bloque la quantité
                this.isQuantiteBlockedByAdminTrajet = true;
            } else {
                //Si c'est un trajet user, on recalcul les km
                this.updateDistance();
            }

            this.onQuantiteChange();
        }

        //On retourne sur le premier onglet
        this.changeTab(this.matTabGroup,NdfFraisAddTab.saisieFrais);
    }

    /**
     * Suite à la sélection d'une tournée
     * @param tournee
     */
    selectTournee(tournee: Tournee) {
        //On supprime l'id pour ne pas rattacher une tournée perso
        tournee.id = null;

        //On assigne les informations de la tournée
        this.data.frais.tournee =  tournee;

        this.data.frais.quantite = tournee.distance;

        this.onQuantiteChange();
    }

    /**
     * Met à jour la distance dans le modèle
     */
    updateDistance(): void {
        this.fraisService.findDistance(this.data.frais.localisationDepart,this.data.frais.localisationArrivee,this.data.frais.allerRetour).subscribe({
            next: distance => {
                //Mise à jour de la quantité
                this.data.frais.quantite = distance || null;
            }
        });
    }

    /**
     * Ouvre la popin tournée
     */
    openPopinTournee(): void  {
        const data = {
            unite: this.data.frais.prestation.unite,
            tournee: this.data.frais.tournee,
            localisationDepart: this.data.frais.localisationDepart,
            localisationArrivee: this.data.frais.localisationArrivee,
            allerRetour: this.data.frais.allerRetour,
            etapesPersonnelles:  this.data.frais.prestation.etapesPersonnelles,
            gestionTempsGlobale:  this.data.frais.prestation.gestionTempsGlobale,
            gestionTempsDetaillee:  this.data.frais.prestation.gestionTempsDetaillee,
            quantiteModifiable: this.data.frais.prestation.quantiteModifiable
        }

        //Ouverture de la popin
        this.matDialog.open(NDFFraisAddTourneeComponent,{data,width: '1200px',}).afterClosed().subscribe({
            next: (tournee: Tournee) => {
                //Si on a valider une tournée
                if (tournee != null) {
                    this.selectTournee(tournee);
                }
            }
        });
    }

    /**
     * Indique si on peut afficher une tournée
     * */
    canShowTournee() {
        return this.data.frais.prestation?.geolocalisation && this.data.frais.prestation?.gestionEtapes;
    }
}

/**
 * Enum pour les onglets add frais
 */
enum NdfFraisAddTab {
    saisieFrais = 0,
    mesFrais = 1,
    pieceJointe = 2
}

/**
 * Enum pour les onglets add frais quand on est sur un frais
 */
enum NdfFraisAddTabFrais {
    saisieFrais = 0,
    pieceJointe = 2
}
