/**
 * Compte comptable
 */
export class Compte {
	numero: string;
	libelle: string;
	type: TypeCompte;

	constructor(dto?: Compte) {
		if (dto) {
			Object.assign(this,dto);
		}
	}

	/**
	 * Retourne une chaîne formatée pour l'affichage textuel du compte.<br>
	 * Forme : <numéro> - <libellé>
	 */
	get libelleForDisplay(): String {
		return [this.numero,this.libelle].join(' - ');
	}
}

/**
 * Énumération des types de comptes
 */
export enum TypeCompte {
	NON_DEFINI,
	COMPTE_TIERS,
	COMPTE_TAXE,
	COMPTE_BANQUE,
	COMPTE_CHARGE,
	PERTE_GAIN_CHANGE
}