import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

import {LoginService} from '@share/login/login.service';
import {Session} from "@domain/security/session";
import * as sessionActions from "@reducers/session";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {TypeProfil} from "@domain/user/user";

/**
 * Gardien pour l'accès aux composants nécessitant une authentification
 */
@Injectable()
export class LoggedGuardProvider implements CanActivate {
	/**
	 * Constructeur
	 */
	constructor(private router: Router, private loginService: LoginService, private store: Store<AppState>) {}

	/**
	 * Activation du composant.
	 *
	 * @param route route qu'on veut atteindre, dont on vérifie le droit d'activation
	 */
	async canActivate(route: ActivatedRouteSnapshot) {
		let isLogged: boolean;
		let session: Session;

		//Récupération de la session
		session = this.loginService.getSession();

		//Si on a le paramètre de requête indiquant une "connexion en tant que"
		if (session?.isLogged && route.queryParamMap.get('connectAs') != null) {
			//On supprime le flag isAdmin, sinon on serait redirigé automatiquement vers la page de login local
			session.isAdmin = false;

			//Suppression du user puisqu'il va être rechargé suite au SESSION_FULFILLED. Si on ne le fait pas, des subscribe vont se déclencher inutilement
			session.user = null;

			//Activation du flag isConnectAs, principalement pour désactiver l'affichage du profil
			session.isConnectAs = true;

			//dispatch session
			this.store.dispatch({
				type: sessionActions.SESSION_FULFILLED,
				payload: session
			});

			isLogged = true;
		} else {
			if (session?.isLogged && !(session.isAdmin && route.data?.isAdminForbidden)) {
				isLogged = true;
			} else if (session?.isLogged && (session.isAdmin || session.user?.fonction === TypeProfil.SOUS_ADMINISTRATEUR)) {
				this.router.navigate(['Admin']);
			} else if (session?.isAdmin) { //L'admin n'a rien à faire là : on le renvoie vers la page de login local forcé
				//Suppression de la redirection éventuelle
				if (session) session.redirect = null;

				//Redirection vers l'écran de connexion
				this.router.navigate(['SSO/LoginLocal']);
			} else if (session?.isPasswordExpired) {
				//L'user tente d'accéder à une page avec un login périmé on applique le plan "You shall not pass"
				this.router.navigate(['/Login']);
			} else {
				//Récupération de la connexion
				isLogged = session != null && session.isLogged;

				if (!isLogged) {
					//Redirection vers l'écran de connexion
					this.router.navigate(['/Login']);
				}
			}
		}

		//Vérification de la connexion
		return isLogged;
	}
}
