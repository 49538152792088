import {Component,OnInit} from '@angular/core';
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {UserDto} from "@domain/user/user-dto";
import {UtilisateursInternesListItemComponent} from "@components/admin/entreprise/utilisateurs/utilisateurs-internes-list/utilisateurs-internes-list-item/utilisateurs-internes-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {TypeProfil} from "@domain/user/user";
import {BehaviorSubject} from 'rxjs';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {Router} from "@angular/router";

/**
 * Liste des utilisateurs internes
 */
@Component({
	host: {'data-test-id': 'utilisateurs-internes-list'},
	selector: 'utilisateurs-internes-list',
	templateUrl: './utilisateurs-internes-list.component.html'
})
export class UtilisateursInternesListComponent implements OnInit {
	/** Liste des utilisateurs */
	listeUsers: ListView<UserDto,UtilisateursInternesListItemComponent>;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 * @param router le routeur Angular
	 */
	constructor(
		private translateService: TranslateService,
		private router: Router
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation de la liste
		this.listeUsers = new ListView<UserDto,UtilisateursInternesListItemComponent>({
			uri: '/controller/User/listeUsers',
			title: this.translateService.instant('admin.entreprise.utilisateurs.internes.title'),
			component: UtilisateursInternesListItemComponent,
			defaultOrder: 'nom,prenom',
			listeFilters: [
				{
					title: this.translateService.instant('admin.entreprise.utilisateurs.internes.identifiant'),
					clef: 'nomAcces',
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				},{
					title: this.translateService.instant('admin.entreprise.utilisateurs.internes.nom'),
					clef: 'nom',
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				},{
					title: this.translateService.instant('admin.entreprise.utilisateurs.internes.prenom'),
					clef: 'prenom',
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				},{
					title: this.translateService.instant('admin.entreprise.utilisateurs.internes.matricule'),
					clef: 'matricule',
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				},{
					title: this.translateService.instant('admin.entreprise.utilisateurs.internes.serviceAffectation'),
					clef: 'orga.numService,orga.libelle',
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				},{
					title: this.translateService.instant('admin.entreprise.utilisateurs.internes.profils'),
					clef: 'fonctions',
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					multiple: true,
					listeValues: [
						{
							code: '' + TypeProfil.COMPTABLE,
							libelle: TypeProfil.getLibelle(this.translateService,TypeProfil.COMPTABLE)
						},{
							code: '' + TypeProfil.RESPONSABLE,
							libelle: TypeProfil.getLibelle(this.translateService,TypeProfil.RESPONSABLE)
						},{
							code: '' + TypeProfil.COLLABORATEUR,
							libelle: TypeProfil.getLibelle(this.translateService,TypeProfil.COLLABORATEUR)
						},{
							code: '' + TypeProfil.ASSISTANT,
							libelle: TypeProfil.getLibelle(this.translateService,TypeProfil.ASSISTANT)
						},{
							code: '' + TypeProfil.SOUS_ADMINISTRATEUR,
							libelle: TypeProfil.getLibelle(this.translateService,TypeProfil.SOUS_ADMINISTRATEUR)
						},{
							code: '' + TypeProfil.FOURNISSEUR,
							libelle: TypeProfil.getLibelle(this.translateService,TypeProfil.FOURNISSEUR)
						}
					]
				},{
					title: this.translateService.instant('admin.entreprise.utilisateurs.internes.actif'),
					clef: 'isActif',
					type: TypeFilter[TypeFilter.BOOLEAN],
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					isDefault: false,
				}
			]
		});

		//Définition des colonnes de tri
		this.listeUsers.columns = [
			{key: 'idUser',title: 'admin.entreprise.utilisateurs.internes.identifiant'},
			{key: 'matricule',title: 'admin.entreprise.utilisateurs.internes.matricule'},
			{key: 'nom',title: 'admin.entreprise.utilisateurs.internes.nom'},
			{key: 'prenom',title: 'admin.entreprise.utilisateurs.internes.prenom'},
		];

		//Persistance des filtres
		this.listeUsers.isPersistFilters = true;
		this.listeUsers.className = 'UtilisateursInternesListComponent';

		//Ajout des actions possibles
		this.listeActions.next([
			{
				type: TypeAction.PRIMARY,
				icone: 'nio icon-ajouter',
				libelle: 'global.actions.creer',
				doAction: () => this.createUser()
			}
		]);
	}

	/**
	 * Appel à la création d'un utilisateur
	 */
	createUser() {
		this.router.navigate(['Admin/Entreprise/Utilisateurs/User',-1]);
	}
}
