import { Component } from "@angular/core";

/**
 * Onglet conditions alertes
 *
 * @author Guillaume TRAVOT
 * @date 21/11/2023
 */
@Component({
    host: {'data-test-id': 'conditions-alertes'},
    templateUrl: './conditions-alertes.component.html'
})
export class ConditionsAlertesComponent {}
