import {Component,ContentChild,EventEmitter,Input,OnInit,Output,ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {AppState} from "@domain/appstate";
import {Page} from '@domain/common/http/list-result';
import {Filter,ListView} from '@domain/common/list-view';
import {ListItem} from '@domain/common/list-view/list-item';
import {ListViewItem} from '@domain/common/list-view/list-view-item';
import {SearchType,Sorting} from "@domain/common/list-view/sorting";
import {SessionStorageService} from "@domain/common/services/session-storage.service";
import {TypeProfil} from "@domain/user/user";
import {Store} from "@ngrx/store";
import * as settingsActions from "@reducers/settings";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {BehaviorSubject,Subscription} from 'rxjs';
import {finalize,first} from "rxjs/operators";
import {ListViewFooterComponent} from './list-view-footer.component';
import {ListViewHeaderComponent} from './list-view-header.component';
import {ListViewSearchComponent} from "./list-view-search.component";
import {ListViewService} from './list-view.service';

@Component({
    host: {'data-test-id': 'list-view'},
    selector: 'list-view',
    templateUrl: './list-view.component.html'
})
export class ListViewComponent<T extends ListItem, K extends ListViewItem<T>> implements OnInit {
    /** Liste à afficher */
    @Input() liste: ListView<T, K>;

    /** Booléen pour indiquer si le surlignement de la ligne se fait quand la souris passe dessus */
    @Input() hover: boolean = true;

	/** La liste est-elle le contenu d'une popin à elle-même ? Créé un overflow-y sur le listview-body */
	@Input() popinList: boolean = false;

	/** Header */
	@ContentChild(ListViewHeaderComponent)
	header: ListViewHeaderComponent;

    /** Footer */
    @ContentChild(ListViewFooterComponent)
    footer: ListViewFooterComponent;

    /** Composant enfant de recherche / filtres */
    @ViewChild(ListViewSearchComponent, {static: false})
    search: ListViewSearchComponent<T, K>;

    /** Fin du DOM atteinte */
    endReached: boolean;

    /** Évènement de fin de chargement des données */
    @Output()
    onLoadEnd: EventEmitter<void> = new EventEmitter<void>();

    /** Profil de l'utilisateur connecté */
    fonction: TypeProfil;

    /** Pour utilisation dans HTML */
    typeProfil: typeof TypeProfil = TypeProfil;

    /**
     * Constructeur
     */
    constructor(
        private listViewService: ListViewService<T,K>,
        private sessionStorageService: SessionStorageService,
        private store: Store<AppState>,
        private router: Router
    ) {
        //Binding
        this.toggleItem = this.toggleItem.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onEndReached = this.onEndReached.bind(this);
    }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Vérification de la liste
        if (this.liste) {
            //Initialisation des spécificités de la liste
            this.liste.initListCore && this.liste.initListCore();

            //Chargement du paramétrage
            this.store.dispatch({
                type: settingsActions.LOAD_SETTINGS,
                payload: ['Global']
            });

            //Binding de l'ajout d'éléments
            this.liste.addItem = this.addItem.bind(this);

            //Binding de la suppression d'élément
            this.liste.removeItem = this.removeItem.bind(this);

            //Binding de la suppression d'élément
            this.liste.clear = this.clear.bind(this);

            //Initialisation de la précédente taille de la liste
            this.liste.previousLength = -1;

            //Initialisation de l'indicateur du chargement de nouvelles données
            this.liste.hasNewData = true;

            //Sélection du profil de l'utilisateur
            this.store.select(state => state.session.user).pipe(filterFirstNotNull()).subscribe(user => {
                //Définition du type de profil
                this.fonction = user.fonction;

                //Initialisation des filtres
                this.initFilters();

                //Activation du tri et de la persistance
                this.initSort();

                //Mise à jour des filtres sélectionnés au chargement
                this.initFiltresOnload();

                //Dans le cas d'une liste simple (pas de searchspec, de filtre, de tri)
                if (this.liste.isSimple) {
                    //Chargement de la liste
                    this.loadSimpleList();

                    //Binding des fonctions
                    this.liste.refresh = this.loadSimpleList.bind(this);

                } else if (!this.liste.isFrontendList) { //Vérification de l'usage du backend pour la liste
                    //Rafraichissement de la liste
                    this.refreshData();

                    //Binding des fonctions
                    this.liste.refresh = this.refreshData.bind(this);

                    //Chargement des données annexes
                    this.loadAnnexData();
                } else {
                    //Dans le cas d'une liste locale, initialisation avec une liste vide ou les données passées au constructeur
                    this.liste.data = Object.assign({}, {
                        listeResultats: [],
                        numPage: 0,
                        nbPagesTotal: 0,
                        nbObjetsTotal: 0,
                        nbObjetsParPage: 10,
                        nbObjetsDansPage: 0,
                    }, this.liste.data);

                    //Mise à jour de la pagination s'il y a déjà des éléments à l'initialisation
                    if (this.liste.data.listeResultats.length > 0) {
                        //Incrément de la pagination
                        this.liste.updatePagination$.next();
                    }

                    //Déclenchement de l'évènement de fin de chargement des données
                    this.onLoadEnd.emit();
                }
            });
        }
    }

    /**
     * Initialisation du tri
     */
    private initSort(): void {
        //Détection du paramètre de persistence
        if (this.liste?.isPersistFilters && !this.liste?.isDashboardList && this.liste?.className) {
            //Récupération d'éventuelles données préchargées
            let defaultOrder = this.sessionStorageService.fetch(this.liste.className, 'defaultOrder_' + this.fonction.toString()) as string;
            let search = this.sessionStorageService.fetch(this.liste.className, 'search_' + this.fonction.toString()) as string;

            //Stockage des valeurs nettoyées (après suppression des quotes inutiles qui font tout planter) ou rien
            defaultOrder = defaultOrder ? defaultOrder.replace(/"/g, "") : undefined;
            search = search ? search.replace(/"/g, "") : undefined;

            //Création du tri
            this.liste.sorting = new Sorting(this.liste.columns ? this.liste.columns : [], this.liste.defaultOrder, (search ? search as SearchType : undefined));

            //Mise à jour des valeurs
            if (defaultOrder) {
                this.liste.sorting.parseExistingOrder(defaultOrder);
                this.liste.defaultOrder = defaultOrder;
            }
        }
    }

    /**
     * Initialisation des filtres de la liste
     */
    private initFilters(): void {
        let listeFilters: Array<Filter>;
        let listeSelectedFilters: Array<Filter>;

        //Détection du paramètre de persistence
        if (this.liste?.isPersistFilters && this.liste?.className) {
            //Récupération d'éventuelles données préchargées
            const strFilters = this.sessionStorageService.fetch(this.liste.className, 'listeFilters_' + this.fonction.toString());
            const strSelectedFilters = this.sessionStorageService.fetch(this.liste.className, 'listeSelectedFilters_' + this.fonction.toString());

            //Vérifie si on ne veut initialiser que les colonnes de la liste
            let initOnlyColumns: boolean = false;

            //En cas de présence de données préchargées
            if (strFilters && strSelectedFilters && !this.liste?.isDashboardList) {
                //Construction des listes de filtres à partir des données sérialisées
                listeFilters = JSON.parse(strFilters) as Filter[];
                listeSelectedFilters = JSON.parse(strSelectedFilters) as Filter[];

                //Récupération des méthodes
                this.liste.listeFilters.forEach(filter => this.recoverFilterMethods(filter,listeFilters));
                this.liste.listeSelectedFilters.forEach(filter => this.recoverFilterMethods(filter,listeSelectedFilters));

                //Récupération des données préchargées
                this.liste.listeFilters = listeFilters;
                this.liste.listeSelectedFilters = listeSelectedFilters;

                //Si on a récupéré des filtres, on ne veut init que les colonnes
                initOnlyColumns = true;
            } else {
                //Appel de la méthode spécifique
                this.liste.initFilters && this.liste.initFilters();
            }

            //Initialisation spécifique
            this.liste.initListSpecific && this.liste.initListSpecific(initOnlyColumns);
        }
    }

    /**
     * Récupération des différentes méthodes définies sur le filtre à la construction de la liste
     *
     * @param filterFromListOrigin Filtre original défini à l'initialisation de la liste
     * @param listFilterFromJson Liste des filtres sérialisés
     */
    private recoverFilterMethods(filterFromListOrigin: Filter,listFilterFromJson: Array<Filter>): void {
        //Recherche du filtre correspondant à partir de ceux qui ont été sérialisés en JSON
        let search = listFilterFromJson.find(f => f.clef === filterFromListOrigin.clef);

        //Si le filtre a été trouvé
        if (search != null) {
            //Récupération des différentes méthodes définies sur le filtre à la construction de la liste
            search.changeMethod = filterFromListOrigin.changeMethod;
            search.selectMethod = filterFromListOrigin.selectMethod;
            search.removeMethod = filterFromListOrigin.removeMethod;
        }
    }

    /**
     * Chargement des données annexes
     */
    loadAnnexData(): void {
        //Uniquement si la liste est concernée
        if (this.liste.annexData) {
            //Initialisation
            let subscription: Subscription;

            //Chargement des données annexes
            subscription = this.listViewService.loadAnnexData(this.liste).subscribe(() => {
                //Suppression de la souscription
                subscription && subscription.unsubscribe();
            });
        }
    }

    /**
     * Rafraîchissement de la liste
     */
    refreshData(): void {
        //Initialisation
        let subscription: Subscription;

        //Vidage de la liste
        this.liste.data = null;

        //Suppression des actions de masse
        this.liste.nbSelectedItems = 0;

        //Indicateur de chargement actif
        this.liste.isLoading = true;

        //Purge des données préchargées
        this.liste.isPreloading = false;
        this.liste.preloadedData = new BehaviorSubject<Page<T>>(undefined);

        //Chargement de la liste
        subscription = this.listViewService.loadData(this.liste, this.liste.uri, 0, this.liste.nbObjetsParPage, this.liste.defaultOrder, this.liste.listeStaticFilters, this.liste.listeSelectedFilters, this.fonction).pipe(first()).subscribe((data) => {
            //Mise à jour de la liste
            this.liste.data = data;

            //Vérification du rafraichissement
            this.liste.onRefresh && this.liste.onRefresh(this.liste, data);

            //Sauvegarde des paramètres appliqués
            this.listViewService.backupListeParams(this.liste, this.fonction);

            //Pagination
            this.liste.updatePagination$.next();

            //Suppression de la souscription
            subscription && subscription.unsubscribe();

            //Affichage de la fin de liste
            this.refreshEnd();

            //Indicateur de chargement inactif
            this.liste.isLoading = false;

            //Déclenchement de l'évènement de fin de chargement des données
            this.onLoadEnd.emit();
            this.liste.loaded.next();

            //Préchargement des données suivantes
            this.listViewService.preloadNextData(this.liste, this.liste.uri, 0, this.liste.nbObjetsParPage, this.liste.defaultOrder, this.liste.listeStaticFilters, this.liste.listeSelectedFilters, this.fonction);
        });
    }

    /**
     * Appel au composant de barre de recherche pour qu'il rafraîchisse l'affichage de la pagination
     */
    public refreshListPagination() {
        this.search.refreshListPagination();
    }

    /**
     * Chargement d'une liste simple à l'initialisation
     */
    private loadSimpleList() {
        //Début du chargement de la liste
        this.liste.isLoading = true;

        //Vidage de la liste
        this.liste.data = null;

        //Chargement de la liste
        this.listViewService.loadSimpleList(this.liste, this.liste.uri)
            .pipe(first(), finalize(() => this.liste.isLoading = false))
            .subscribe(data => {
                //Dans le cas d'une liste locale, initialisation avec une liste vide
                this.liste.data = {
                    listeResultats: data,
                    numPage: 0,
                    nbPagesTotal: 0,
                    nbObjetsTotal: data.length,
                    nbObjetsParPage: data.length,
                    nbObjetsDansPage: data.length,
                };

                //Déclenchement de l'évènement de fin de chargement des données
                this.onLoadEnd.emit();
                this.liste.loaded.next();
            });
    }

    /**
     * Ajout d'un ou plusieurs éléments à la liste
     *
     * @param item objet(s) concerné(s)
     * @param append Ajout du ou des éléments à la fin de la liste. False par défaut, dans ce cas ils sont ajoutés au début
     */
    addItem(item: T | T[], append?: boolean): void {
        //On s'assure que le tableau est initialisé
        this.liste.data.listeResultats = this.liste.data.listeResultats || [];

        //Vérification du type de paramètre
        if (!Array.isArray(item)) {
            item = [item];
        }

        //Ajout de l'élément en début de liste
        item.forEach(elt => {
            if (append) {
                this.liste.data.listeResultats.push(elt);
            } else {
                this.liste.data.listeResultats.unshift(elt);
            }
        });

        //Incrément de la pagination
        this.updatePagination(this.liste.data, item.length);

        //Pagination
        this.liste.updatePagination$.next();
    }

    /**
     * Suppression d'un élément de la liste
     *
     * @param item objet concerné
     */
    removeItem(item: T): boolean {
        //Ajout de l'élément en début de liste
        const indexItemToRemove = this.liste.data.listeResultats.findIndex(elt => elt.getKey() === item.getKey());

        //Si l'élément a été trouvé
        if (indexItemToRemove !== -1) {
            //Suppression de la liste des résultats
            this.liste.data.listeResultats.splice(indexItemToRemove, 1);

            //Décrément de la pagination
            this.updatePagination(this.liste.data, -1);

            //Pagination
            this.liste.updatePagination$.next();

            //Element trouvé et supprimé : succès
            return true;
        }

        //Element non supprimé, car non trouvé
        return false;
    }

    /**
     * Suppression de tous les éléments de la liste
     */
    clear(): void {
        //Suppression de la liste des résultats
        const count = this.liste.data.listeResultats.splice(0).length;

        //Décrément de la pagination
        this.updatePagination(this.liste.data,-count);

        //Pagination
        this.liste.updatePagination$.next();
    }

    /**
     * Mise à jour de la pagination
     * @param page page
     * @param increment valeur d'incrément
     */
    private updatePagination(page: Page<T>, increment: number): void {
        //Incrément
        if (increment > 0) {
            //Incrément de la page si besoin
            if (page.nbObjetsDansPage < page.nbObjetsParPage || !page.nbObjetsParPage) {
                page.nbObjetsDansPage += increment;
            }

            //Incrément du total
            page.nbObjetsTotal += increment;
        }
        //Décrément
        else if (increment < 0) {
            //Décrément de la page si besoin
            if ((page.nbObjetsDansPage > page.nbObjetsParPage) || (page.nbObjetsTotal <= page.nbObjetsParPage) || !page.nbObjetsParPage) {
                page.nbObjetsDansPage += increment;
            }

            //Décrément du total
            page.nbObjetsTotal += increment;
        }
    }

    /**
     * Affichage ou masquage de l'élément
     * @param data objet concerné
     */
    toggleItem(data: T): void {
        //Initialisation
        let type: T;

        //Récupération d'un élément
        type = new this.liste.factory();

        //Vérification de la présence d'une clé
        if (type.getKey()) {
            //Affichage ou masquage du composant
            data.isDisplayed = !data.isDisplayed;
        } else {
            //Suppression de l'élément
            this.liste.data.listeResultats.splice(this.liste.data.listeResultats.findIndex(d => d === data), 1);
        }
    }

    /**
     * Écoute de la suppression d'un élément
     * @param data objet concernée
     */
    onRemove(data: T) {
        //Initialisation
        let idx: number;

        //Récupération de l'index de l'élément
        idx = this.liste.data.listeResultats.findIndex(d => d === data);

        //Vérification de la présence de l'élément
        if (idx > -1) {
            //Suppression de l'élément
            this.liste.data.listeResultats.splice(idx, 1);
        }

        //Décrément de la pagination
        this.updatePagination(this.liste.data, -1);

        //Pagination
        this.liste.updatePagination$.next();
    }

    /**
     * Détection du scroll en bout de liste
     */
    onEndReached(): void {
        //Initialisation
        let subscription: Subscription;
        let listeResultats: T[];

        //Vérification de la position
        if (this.liste.data && !this.liste?.isLoading && !this.liste.isTotalementChargee()) {
            //Chargement en cours
            this.liste.isLoading = true;

            //Chargement de la liste ou récupération des données préchargées
            subscription = this.listViewService.consumePreloadedDataOrLoadIt(this.liste, this.liste.uri, this.liste.data.numPage + 1, this.liste.nbObjetsParPage, this.liste.defaultOrder, this.liste.listeStaticFilters, this.liste.listeSelectedFilters, this.fonction).subscribe((data) => {
                //Récupération de l'ancien contenu
                listeResultats = this.liste.data.listeResultats;

                //Mise à jour du contenu
                this.liste.data = data;

                //Concaténation des listes
                this.liste.data.listeResultats = [...listeResultats, ...this.liste.data.listeResultats];

                //On vérifie qu'il y a plus de données dans la liste qu'au précédent chargement
                this.liste.hasNewData = this.liste.previousLength < this.liste.data.listeResultats.length;

                //On met à jour la longueur de la liste avant de la recharger (pour vérifier qu'elle augmente bien, sinon c'est qu'on est au bout)
                this.liste.previousLength = this.liste.data.listeResultats.length;

                //Vérification du rafraichissement
                this.liste.onRefresh && this.liste.onRefresh(this.liste, data);

                //Pagination
                this.liste.updatePagination$.next();

                //Suppression de la souscription
                subscription && subscription.unsubscribe();

                //Chargement fini
                this.liste.isLoading = false;

                this.onLoadEnd.emit();

                //Affichage de la fin de liste
                this.refreshEnd();
            });
        }
    }

    /**
     * Affichage de la fin de liste
     */
    refreshEnd(): void {
        this.endReached = (this.liste?.data?.numPage >= (this.liste?.data?.nbPagesTotal - 1));
    }

    /**
     * Initialisation des filtres sélectionnés au chargement de la liste
     */
    private initFiltresOnload() {

        if (this.liste?.className) {
            const listeFilters = this.sessionStorageService.fetch(this.liste.className, 'listeFilters_' + this.fonction.toString());
            const listeSelectedFilters = this.sessionStorageService.fetch(this.liste.className, 'listeSelectedFilters_' + this.fonction.toString());

            //Récupération de la liste des filtres à initialiser
            let listeFiltresOnload: Array<Filter> = this.liste.listeFilters.filter(f => !!f.onloadValue);

            if (listeFiltresOnload?.length > 0 && !listeFilters && !listeSelectedFilters) {
                //Parcours des filtres
                for (let filter of listeFiltresOnload) {
                    //Marquage du filtre comme sélectionné
                    filter.isSelected = true;

                    //Dans le cas d'un filtre multiple
                    if (filter.multiple) {
                        //Filtrage des valeurs du filtre à sélectionner
                        for (let lv of filter.listeValues?.filter(f => (Array.isArray(filter.onloadValue) ? filter.onloadValue : [filter.onloadValue]).includes(f.code))) {
                            //Initialisation de la liste
                            filter.listeObjects = filter.listeObjects ?? [];

                            //Si la liste des valeurs sélectionnées ne contient pas déjà cette valeur
                            if (!filter.listeObjects.includes(lv.code)) {
                                //Ajout du code de la valeur à la liste des valeurs sélectionnées
                                filter.listeObjects.push(lv.code);
                            }
                        }
                    } else {
                        //Si ce n'est pas multiple, seul le type chaîne est géré
                        if (typeof filter.onloadValue == 'string') {
                            //Mise à jour de la valeur du filtre avec la valeur récupérée en session storage (si elle existe) ou la valeur par défaut
                            filter.valeur = filter.valeur ?? filter.onloadValue;
                        } else {
                            //Du coup si ce n'est pas une chaîne on affiche une erreur dans la console...
                            console.error(filter.clef + ' : valeur de type chaîne attendue !');
                        }
                    }
                }

                //Mise à jour des filtres sélectionnés sur la liste
                this.listViewService.refreshListeSelectedFilters(this.liste);
            }
        }
    }

    /**
     * Définis si la liste contient le filtre "à traiter" ou non
     */
    isListeATraiter(): boolean {
        return this.liste.listeSelectedFilters.some(filter => filter.valeur == "#aTRAITER");
    }

    /**
     * Supprime le filtre à traiter pour afficher tous les éléments
     */
    viewAll(): void {
        //Dé selection du filtre "à traiter"
        this.liste.listeFilters.map(f => {
            if (f.valeur == "#aTRAITER") {
                f.isSelected = false;
            }
        });

        //Dans le cas d'une liste dashboard
        if (this.liste.isDashboardList) {
            //Mise à jour des filtres sélectionnés
            this.listViewService.refreshListeSelectedFilters(this.liste);

            //On sauvegarde les filtres
            this.listViewService.backupListeParams(this.liste,this.fonction,true);

            //Redirection vers la liste workflow
            this.router.navigate([this.liste.redirect]);
        } else {
            //Rafraichissement des filtres et de la liste
            this.search.updateListeFilters();
        }
    }

    /**
     * Indique si on doit afficher le scroll infinie
     * @return true si on a un scroll infini
     */
    hasInfiniteScroll(): boolean {
        //Si on a forcé le paramétrage de l'infinite scroll
        if (this.liste.isInfiniteScroll != null) {
            return this.liste.isInfiniteScroll;
        }

        //On renvoie les valeurs historiques qui modifient également d'autres éléments que le scroll infini
        return (!this.liste.isLocal || this.liste.isFrontendList) && !this.liste.isSimple && !this.liste.isDashboardList;
    }
}
