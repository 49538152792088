<form autocomplete="off" name="form_zu" #zuForm="ngForm">
    <div class="row ">
        <div class="col-md-6" *ngFor="let zu of listeZuModels; let index = index">
            <div class="row" *ngIf="zu.typeChamp != TypeZoneUtilisateur.CHECKBOX">
                <!-- Champ texte -->
                <custom-input *ngIf="zu.typeChamp == TypeZoneUtilisateur.TEXTE" ngDefaultControl
                    [(ngModel)]="zu.saisieTexte" customType="textarea" [name]="'zu_'+zu.idZU" [libelle]="zu.libelle"
                    [required]="zu.obligatoire" lCol="4" [rCol]="getColSize(zu)"
                    [readonly]="readonly" [maxlength]="200">
                </custom-input>

                <!-- champ montant -->
                <custom-input *ngIf="zu.typeChamp == TypeZoneUtilisateur.MONTANT" ngDefaultControl
                    [(ngModel)]="zu.saisieMontant" customType="montant" [name]="'zu_'+zu.idZU" [libelle]="zu.libelle"
                    [required]="zu.obligatoire" lCol="4" [rCol]="getColSize(zu)"
                    [readonly]="readonly">
                </custom-input>

                <!-- champ date -->
                <custom-input *ngIf="zu.typeChamp == TypeZoneUtilisateur.DATE" ngDefaultControl
                    [ngModel]="zu.saisieDate" [(customModel)]="zu.saisieDate" customType="date" [name]="'zu_'+zu.idZU" [libelle]="zu.libelle"
                    [required]="zu.obligatoire" lCol="4" [rCol]="getColSize(zu)"
                    [readonly]="readonly">
                </custom-input>

                <!-- champ heure -->
                <custom-input *ngIf="zu.typeChamp == TypeZoneUtilisateur.HEURE" ngDefaultControl
                    [(ngModel)]="zu.saisieHeure" customType="heure" [name]="'zu_'+zu.idZU" [libelle]="zu.libelle"
                    [required]="zu.obligatoire" lCol="4" [rCol]="getColSize(zu)"
                    [readonly]="readonly">
                </custom-input>

                <!-- champ liste unique -->
                <custom-input *ngIf="zu.typeChamp == TypeZoneUtilisateur.LISTE_UNIQUE" ngDefaultControl
                    [(ngModel)]="zu.zuSelect" [name]="'zu_'+zu.idZU" [libelle]="zu.libelle" [required]="zu.obligatoire"
                    optionValue="idZURef" optionDisplay="libelle" customType="objectselect" [isSelectAvecInput]="false"
                    [selectOptions]="zu.listeReferentiel" lCol="4" [rCol]="getColSize(zu)" 
                    [isSelectAvecVide]="true"
                    [readonly]="readonly">
                </custom-input>

                <!-- champ liste unique autre -->
                <custom-input *ngIf="zu.typeChamp == TypeZoneUtilisateur.LISTE_UNIQUE_AUTRE" ngDefaultControl
                              [(ngModel)]="zu.zuSelect" [name]="'zu_'+zu.idZU" [libelle]="zu.libelle" [required]="zu.obligatoire"
                              optionValue="idZURef" optionDisplay="libelle" customType="objectselect" [isSelectAvecInput]="true"
                              [selectInput]="zu.autreSelect" [selectOptions]="zu.listeReferentiel" lCol="4" [rCol]="getColSize(zu)"
                              [isSelectAvecVide]="true" [readonly]="readonly"
                              [maxlength]="200">
                </custom-input>

                <!-- champ liste multiple -->
                <custom-input *ngIf="zu.typeChamp == TypeZoneUtilisateur.LISTE_MULTIPLE" ngDefaultControl
                    [(ngModel)]="zu.listeZUSelects" [name]="'zu_'+zu.idZU" [libelle]="zu.libelle"
                    [required]="zu.obligatoire" optionValue="idZURef" optionDisplay="libelle" customType="multiselect"
                    [isSelectAvecInput]="false" [selectOptions]="zu.listeReferentiel" lCol="4" [rCol]="getColSize(zu)"
                    [readonly]="readonly">
                </custom-input>

                <!-- champ liste multiple autre -->
                <custom-input *ngIf="zu.typeChamp == TypeZoneUtilisateur.LISTE_MULTIPLE_AUTRE" ngDefaultControl
                              [(ngModel)]="zu.listeZUSelects" [name]="'zu_'+zu.idZU" [libelle]="zu.libelle"
                              [required]="zu.obligatoire" optionValue="idZURef" optionDisplay="libelle" customType="multiselect"
                              [isSelectAvecInput]="true" [selectInput]="zu.autreSelect" [selectOptions]="zu.listeReferentiel"
                              lCol="4" [rCol]="getColSize(zu)"
                              [maxlength]="200"
                              [readonly]="readonly">
                </custom-input>

                <!-- Icone tooltip -->
                <div class="col-md-1 px-2" *ngIf="zu.description">
                    <mat-icon class="py-3" *ngIf="zu.description" [matTooltip]="zu.description" inline="true">
                        error_outline
                    </mat-icon>
                </div>
            </div>

            <!-- Champ case à cocher -->
            <!-- Ce champ ne fait pas partie des customs inputs -->
            <div class="row" *ngIf="zu.typeChamp == TypeZoneUtilisateur.CHECKBOX">
                <div class="col-md-4">
                    <label class="p-t-15" [class.text-danger]="zu.obligatoire && !zu.saisieCheck">
                        <span>{{zu.libelle}}</span>
                        <span *ngIf="zu.obligatoire">*</span>
                    </label>
                </div>
                <div class="col-md-8">
                    <div class="">
                        <mat-checkbox class="mt-3" color="primary" [name]="'zu_'+zu.idZU" [(ngModel)]="zu.saisieCheck" [disabled]="readonly"></mat-checkbox>
                        <!-- Icone tooltip -->
                        <mat-icon class="ml-3" *ngIf="zu.description" [matTooltip]="zu.description" inline="true">
                            error_outline
                        </mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>