import {NgModule} from '@angular/core';


import {ShareModule} from '../../share/share.module';
import {VehiculeComponent} from './vehicule.component';
import {VehiculeListComponent} from './list/vehicule-list.component';
import {VehiculeListItemComponent} from './list/vehicule-list-item.component';
import {VehiculeService} from "./vehicule.service";
import {DocumentModule} from '../document/document.module';
import {VehiculeDocumentsComponent} from './document/vehicule-documents.component';
import {VehiculeAddDocumentComponent} from './document/vehicule-add-document.component';
import {VehiculeCarteGriseComponent} from './document/carte-grise/vehicule-carte-grise.component';
import {VehiculeAutorisationComponent} from './document/autorisation/vehicule-autorisation.component';
import {VehiculeAssuranceComponent} from './document/assurance/vehicule-assurance.component';
import {VehiculeRejetComponent} from './vehicule-rejet.component';
import {VehiculeHistoriqueDistanceComponent} from './vehicule-historique-distance.component';
import {VehiculeListAlerteComponent} from './list/vehicule-list-alerte.component';
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";


@NgModule({
    imports: [ShareModule, DocumentModule, PreviewAlerteModule],
    declarations: [VehiculeComponent, VehiculeListComponent, VehiculeListItemComponent, VehiculeDocumentsComponent, VehiculeAddDocumentComponent, VehiculeCarteGriseComponent, VehiculeAutorisationComponent, VehiculeAssuranceComponent, VehiculeRejetComponent, VehiculeHistoriqueDistanceComponent, VehiculeListAlerteComponent],
    entryComponents: [VehiculeListItemComponent],
    providers: [VehiculeService]
})
export class VehiculeModule {}
