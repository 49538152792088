import {Component,Input,OnDestroy,OnInit,TemplateRef,ViewChild} from '@angular/core';
import {Result,TypeCodeErreur} from '@domain/common/http/result';
import {EntrepriseUtilisateursService} from "@components/admin/entreprise/utilisateurs/entreprise-utilisateurs.service";
import {finalize,first,switchMap} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {InfoVoyageur} from '@domain/profil/infoVoyageur';
import {ProfilService} from "@components/profil/profil.service";
import {ListView} from "@domain/common/list-view";
import {Telephone} from "@domain/profil/telephone";
import {ProfilDonneesTelephoneListItemComponent} from "@components/profil/donnees/telephone/profil-donnees-telephone-list-item.component";
import {MatDialog} from "@angular/material/dialog";
import {ProfilDonneesAddTelephoneComponent} from "@components/profil/donnees/telephone/profil-donnees-add-telephone.component";
import * as _ from 'lodash';
import {TypeContact} from "@domain/profil/typeContact";
import {Document} from "@domain/profil/document";
import {ProfilDonneesDocumentListItemComponent} from "@components/profil/donnees/document/profil-donnees-document-list-item.component";
import {ProfilDonneesAddDocumentComponent} from "@components/profil/donnees/document/profil-donnees-add-document.component";
import {BehaviorSubject,combineLatest,forkJoin,Observable,of,Subscription} from 'rxjs';
import {SettingsGlobalState} from '@domain/settings/settings';
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import * as settingsActions from "@reducers/settings";
import {CarteVoyage} from "@domain/profil/carteVoyage";
import {ProfilDonneesCarteVoyageListItemComponent} from "@components/profil/donnees/carteVoyage/profil-donnees-carte-voyage-list-item.component";
import {ProfilDonneesAddCarteVoyageComponent} from "@components/profil/donnees/carteVoyage/profil-donnees-add-carte-voyage.component";
import {AdresseListItem} from "@domain/profil/adresse";
import {ProfilDonneesAdresseListItemComponent} from "@components/profil/donnees/adresse/profil-donnees-adresse-list-item.component";
import {ConfigAlerte} from '@domain/profil/configAlerte';
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";
import {ProfilAlertes} from "@domain/profil/profilAlertes";
import {DatePipe} from "@angular/common";
import {TypeFormulaire} from "@domain/profil/DocumentAlerte";
import {ProfilConnexion,ProfilVoyageur,ResultSynchro,TypeSaisie} from '@domain/profil/profilVoyageur';
import {SynchroSBTConfigUser} from '@domain/voyage/travel/synchro-sbt-config-user';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {NgForm} from '@angular/forms';
import {RLS} from "@domain/travelhub/rls";
import {CategorieVoyageur} from "@domain/travelhub/categorie-voyageur";
import {Communaute} from "@domain/travelhub/communaute";
import {ProfilDonneesContactUrgenceListItemComponent} from "@components/profil/donnees/contactUrgence/profil-donnees-contact-urgence-list-item.component";
import {ProfilDonneesAddContactUrgenceComponent} from "@components/profil/donnees/contactUrgence/profil-donnees-add-contact-urgence.component";
import {ProfilDonneesAddAdresseComponent} from "@components/profil/donnees/adresse/profil-donnees-add-adresse.component";
import {TypePortee} from "@domain/workflow/workflow";
import {TypeLieu} from "@domain/lieu/typeLieu";

/**
 * Onglet "Profil" de l'écran de consultation d'un utilisateur
 */
@Component({
	host: {'data-test-id': 'user-profil'},
	selector: 'user-profil',
	templateUrl: './user-profil.component.html'
})
export class UserProfilComponent implements OnInit,OnDestroy {
	/** ID de l'utilisateur courant */
	@Input() idUser: number;

	/** L'utilisateur a-t-il une ligne dans ns_collab */
	@Input() isCollabInit: boolean;

	/** Souscription aux observables */
	listeSubscription: Array<Subscription> = new Array<Subscription>();

	/** Subjects pour la détection et le stockage des différents paramètres requis dans la page */
	mainSettings: BehaviorSubject<SettingsGlobalState> = new BehaviorSubject<SettingsGlobalState>(undefined);

	/** Liste des profils de connexion disponibles */
	listeProfilConnexion: ProfilConnexion[];

	/** Identifiant du profil de connexion sélectionné */
	idProfilConnexionSelected: number;

	/** Map contenant les paramètres des SBT avec l'ID du SBT en clé */
	mapPvParams: Map<number,PvParams> = new Map();

	/** Liste des paramètres des SBT (fait pour éviter l'erreur front object has changed...) */
	listePvParams: Array<PvParams> = new Array<PvParams>();

	/** Informations voyageur */
	infoVoyageur: InfoVoyageur;

	/** Niveau d'alerte du cadre "Informations voyageur" */
	infoVoyageurAlertLevel: NiveauAlerte;

	/** Liste des informations de contact */
	listeTelephones: ListView<Telephone,ProfilDonneesTelephoneListItemComponent>;

	/** Liste des adresses */
	listeAdresses: ListView<AdresseListItem,ProfilDonneesAdresseListItemComponent>;

	/** Liste des documents */
	listeDocuments: ListView<Document,ProfilDonneesDocumentListItemComponent>;

	/** Liste des cartes d'abonnement et de fidelité */
	listeCartesVoyage: ListView<CarteVoyage,ProfilDonneesCarteVoyageListItemComponent>;

	/** Liste des contacts d'urgence */
	listeContactUrgence: ListView<Telephone,ProfilDonneesContactUrgenceListItemComponent>;

	/** Configuration des alertes */
	configAlerte: ConfigAlerte;

	/** Liste des alertes du profil */
	listeAlertes: Alerte[] = [];

	/** Niveau d'alerte du profil */
	niveauAlerteProfil: NiveauAlerte;

	/** Information sur le profil voyageur */
	profilVoyageur: ProfilVoyageur;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Indicateur de sauvegarde en cours */
	isSaving: boolean = false;

	/** Formulaire du DOM */
	@ViewChild('formInfoVoyageur') formInfoVoyageur: NgForm;

	/** Popup de synchronisation du profil */
	@ViewChild('synchroTemplate') synchroTemplate: TemplateRef<any>;

	/** Import de l'énum pour le DOM */
	readonly NiveauAlerte = NiveauAlerte;

	/** Visibilité du profil voyageur */
	@Input() isDroitProfilVoyageur: boolean;

	/** Visibilité des contacts d'urgence */
	@Input() isDroitContactUrgence: boolean;

	/** Visibilité des adresses */
	@Input() isDroitAdresse: boolean;

	/** Visibilité des documents d'identité */
	@Input() isDroitIdentite: boolean;

	/** Visibilité des cartes d'abonnement */
	@Input() isDroitCarteAbonnement: boolean;

	/**
	 * Constructeur
	 *
	 * @param userService le service de gestion des utilisateurs
	 * @param translateService le moteur de traduction
	 * @param toastrService le toaster
	 * @param profilService le service de gestion du profil
	 * @param matDialog le service de gestion des popups
	 * @param store le store de l'appli
	 * @param datePipe le pipe de gestion des dates
	 */
	constructor(
		private userService: EntrepriseUtilisateursService,
		private translateService: TranslateService,
		private toastrService: ToastrService,
		private profilService: ProfilService,
		private matDialog: MatDialog,
		private store: Store<AppState>,
		private datePipe: DatePipe
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Chargement du paramétrage global
		this.store.dispatch({
			type: settingsActions.LOAD_SETTINGS,
			payload: ['Global']
		});
		this.listeSubscription.push(this.store.select(state => state.settings?.['Global']).subscribe(settings => this.mainSettings.next(settings)));

		//Si on a le droit de gérer le profil voyageur
		if (this.isDroitProfilVoyageur) {
			//Chargement de la liste des profils de connexion
			this.profilService.getListeProfilConnexion()
				.pipe(first())
				.subscribe((result: Result) => {
					//Vérification du result
					if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
						//Récupération des données du result
						let profilConnexion: ProfilConnexion = new ProfilConnexion();
						profilConnexion.idProfilConnexion = 0;
						profilConnexion.libelle = this.translateService.instant('global.input.choisissez')
						this.listeProfilConnexion = [profilConnexion,...result.data.listeProfilConnexion.data];

						//Chargement du profil voyageur
						this.loadProfilVoyageur();
					} else {
						//Gestion de l'erreur
						TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
					}
				});
		}

		//Chargement des numéros de téléphones
		this.listeTelephones = new ListView<Telephone,ProfilDonneesTelephoneListItemComponent>({
			uri: `/controller/Profil/getListeTelephone?idUser=${this.idUser}`,
			title: this.translateService.instant('profilUser.infoContact.title'),
			component: ProfilDonneesTelephoneListItemComponent,
			listeActions: [{
				icon: "add",
				onPress: () => this.openTelephone(new Telephone()),
				disabled: !this.isCollabInit,
				tooltip: !this.isCollabInit ? this.translateService.instant('admin.entreprise.utilisateurs.detail.errorCollab') : null
			}],
			isSimple: true,
			mapResult: (result: Result) => result?.data?.listeTelephone,
			extraOptions: {
				open: (telephone: Telephone) => this.openTelephone(telephone)
			}
		});

		//Chargement de la liste des adresses
		this.listeAdresses = new ListView<AdresseListItem,ProfilDonneesAdresseListItemComponent>({
			uri: `/controller/AdresseAdmin/getListeAdresseForUser?idUser=${this.idUser}`,
			title: this.translateService.instant('profilUser.adresses.title'),
			component: ProfilDonneesAdresseListItemComponent,
			listeActions: [{
				icon: "add",
				onPress: () => this.openAdresse(new AdresseListItem()),
				disabled: !this.isCollabInit,
				tooltip: !this.isCollabInit ? this.translateService.instant('admin.entreprise.utilisateurs.detail.errorCollab') : null
			}],
			isSimple: true,
			mapResult: (result: Result) => result?.data?.listeAdresse,
			extraOptions: {
				open: (adresse: AdresseListItem) => this.openAdresse(adresse)
			}
		});

		//Chargement de la liste des documents
		this.listeDocuments = new ListView<Document,ProfilDonneesDocumentListItemComponent>({
			uri: `/controller/Profil/listeDocument?idUser=${this.idUser}`,
			title: this.translateService.instant('profilUser.document.title'),
			component: ProfilDonneesDocumentListItemComponent,
			listeActions: [{
				icon: "add",
				onPress: () => this.openProfilDocument(new Document()),
				disabled: !this.isCollabInit,
				tooltip: !this.isCollabInit ? this.translateService.instant('admin.entreprise.utilisateurs.detail.errorCollab') : null
			}],
			isSimple: true,
			mapResult: (result: Result) => result?.data?.listeDocument,
			extraOptions: {
				open: (document: Document) => this.openProfilDocument(document),
				settings: this.mainSettings.getValue().profilAlerteConfig
			}
		});

		//Chargement de la liste des cartes d'abonnement et de fidélité
		this.listeCartesVoyage = new ListView<CarteVoyage,ProfilDonneesCarteVoyageListItemComponent>({
			uri: `/controller/Profil/listeCarte?idUser=${this.idUser}`,
			title: this.translateService.instant('profilUser.carteAbonnement.title'),
			component: ProfilDonneesCarteVoyageListItemComponent,
			listeActions: [{
				icon: "add",
				onPress: () => this.openCarteVoyage(new CarteVoyage()),
				disabled: !this.isCollabInit,
				tooltip: !this.isCollabInit ? this.translateService.instant('admin.entreprise.utilisateurs.detail.errorCollab') : null
			}],
			isSimple: true,
			mapResult: (result: Result) => result?.data?.listeCarte,
			extraOptions: {
				open: (carte: CarteVoyage) => this.openCarteVoyage(carte),
				settings: this.mainSettings.getValue().profilAlerteConfig
			}
		});

		//Définition de la liste des contacts d'urgence
		this.listeContactUrgence = new ListView<Telephone,ProfilDonneesContactUrgenceListItemComponent>({
			uri: `/controller/Profil/listeContactUrgence?idUser=${this.idUser}`,
			title: this.translateService.instant('profilUser.contactUrgence.title'),
			component: ProfilDonneesContactUrgenceListItemComponent,
			listeActions: [{
				icon: "add",
				onPress: () => this.openContactUrgence(new Telephone()),
				disabled: !this.isCollabInit,
				tooltip: !this.isCollabInit ? this.translateService.instant('admin.entreprise.utilisateurs.detail.errorCollab') : null
			}],
			isSimple: true,
			mapResult: (result: Result) => {
				return result?.data?.listeContact
			},
			extraOptions: {
				open: (telephone) => this.openContactUrgence(telephone)
			}
		});

		//Gestion des alertes une fois que toutes les listes sont chargées
		combineLatest([
			this.listeAdresses.loaded,
			this.listeTelephones.loaded,
			this.listeDocuments.loaded,
			this.listeCartesVoyage.loaded,
			this.listeContactUrgence.loaded
		].filter(o => !!o)).pipe(first()).subscribe(() => this.refreshAlertes());

		//Charge les infos pour la synchro du profil voyageur
		this.loadSynchroProfilVoyageur();

		//Définition des actions possibles
		this.listeActions.next([
			{
				type: TypeAction.PRIMARY,
				icone: 'nio icon-sauvegarde',
				libelle: 'global.actions.enregistrer',
				doAction: () => this.saveInfoVoyageur(),
				isDisabled: () => this.formInfoVoyageur?.invalid || this.isSaving || !this.isCollabInit,
				tooltip: () => !this.isCollabInit ? this.translateService.instant('admin.entreprise.utilisateurs.detail.errorCollab') : null
			}
		]);
	}

	/**
	 * Chargement du profil voyageur
	 */
	loadProfilVoyageur(): void {
		//Appel au service
		this.userService.getProfilVoyageur(this.idUser)
			.pipe(first())
			.subscribe((result: Result) => {
				//Vérification du result
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Récupération des infos voyageur
					this.infoVoyageur = result.data.informationsVoyageur;

					//Récupération de la ville correspondant à l'ID afin de peupler l'autocomplete de la ville de naissance
					if (result.data.villeNaissance) {
						this.profilService.getEntiteGeoForIdVille(result.data.villeNaissance.id).pipe(first()).subscribe(result => {
							this.infoVoyageur.villeNaissance = result.data?.geographie?.ville;
						});
					}

					this.onVilleNaissanceChange();

					//On charge les infos pour le profil de connexion du voyageur
					this.loadInfosProfilConnexion(result.data.profilConnexion?.idProfilConnexion);
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Chargement des informations liées au profil de connexion
	 *
	 * @param idProfilConnexion Identifiant du profil de connexion concerné
	 */
	loadInfosProfilConnexion(idProfilConnexion?: number) {
		//On s'assure que les objets contenant les paramètres sont vides
		this.mapPvParams.clear();
		this.updateListePvParams();

		//Si un profil de connexion a été défini
		if (!!idProfilConnexion && idProfilConnexion > 0) {
			//Chargement des paramètres du profil voyageur
			forkJoin([
				this.profilService.getListeCommunaute(this.idUser,idProfilConnexion).pipe(first()),
				this.profilService.getListeCategorieVoyageur(this.idUser,idProfilConnexion).pipe(first()),
				this.profilService.getListeRLS(this.idUser,idProfilConnexion).pipe(first())
			]).pipe(first()).subscribe(([commuResult,categVoyageurResult,rlsResult]: Result[]) => {
				//Traitement des communautés
				this.processCommunautes(commuResult);

				//Traitement des catégories voyageur
				this.processCategoriesVoyageur(categVoyageurResult);

				//Traitement des RLS
				this.processRls(rlsResult);

				//Récupération du profil de connexion sélectionné. On le fait après le traitement des paramètres car idProfilConnexionSelected conditionne l'affichage du form
				this.idProfilConnexionSelected = idProfilConnexion;
			});
		} else {
			//Si aucun profil de connexion n'est défini
			//On crée un result bidon pour que les éléments ci-dessous puissent être traités correctement
			let newResult: Result = {
				codeErreur: TypeCodeErreur.NO_ERROR,
				data: {}
			}

			//On met un profil inexistant pour laisser l'admin choisir
			this.idProfilConnexionSelected = 0;

			//Traitement des communautés
			this.processCommunautes(newResult);

			//Traitement des catégories voyageur
			this.processCategoriesVoyageur(newResult);

			//Traitement des RLS
			this.processRls(newResult);
		}
	}

	/** Mise à jour de la liste des Paramètres des SBT depuis la map des paramètres */
	updateListePvParams() {
		//On récupère les valeurs
		this.listePvParams = Array.from(this.mapPvParams.values());
	}

	/**
	 * Traitement des communautés
	 *
	 * @param result le result du chargement des infos relatives aux communautés
	 */
	processCommunautes(result: Result): void {
		//Vérification du result
		if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
			//S'il y a des communautés
			if (result.data.listeCommunauteSBT?.length) {
				//On parcourt les communautés
				result.data.listeCommunauteSBT.forEach(listeCommuSBT => {
					//On récupère la liste des communautés
					let listeCommunaute: Communaute[] = listeCommuSBT.listeCommunauteTrans.map((commu: Communaute) => {
						return {
							...commu,
							libelle: commu.libelle ? (commu.libelle + ' (' + commu.valeur + ')') : commu.valeur
						}
					});

					//Si la map contient déjà des infos pour ce SBT
					if (this.mapPvParams.has(listeCommuSBT.idSBTConfigUsed)) {
						//On ajoute juste la liste des communautés
						this.mapPvParams.get(listeCommuSBT.idSBTConfigUsed).listeCommunaute = listeCommunaute;
					} else {
						//Si la map ne contient pas d'infos pour ce SBT, on rajoute le SBT
						let pvParam: PvParams = new PvParams(listeCommuSBT.idSBTConfigUsed,listeCommuSBT.libelle,listeCommunaute)
						this.mapPvParams.set(listeCommuSBT.idSBTConfigUsed,pvParam);
					}
				});
			}

			//On s'assure que la liste contenant les params est à jour
			this.updateListePvParams();
		} else {
			//Gestion de l'erreur
			TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
		}
	}

	/**
	 * Traitement des catégories voyageur
	 *
	 * @param result le result du chargement des infos relatives aux catégories voyageur
	 */
	processCategoriesVoyageur(result: Result): void {
		//Vérification du result
		if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
			//S'il y a des catégories voyageurs
			if (result.data.listeCategorieVoyageur?.length) {
				//On parcourt les catégories voyageur
				result.data.listeCategorieVoyageur.forEach(listeCategSBT => {
					//On récupère la liste des catégories voyageur
					let listeCatVoyageur: CategorieVoyageur[] = listeCategSBT.listeCategorieVoyageurTrans.map((categ: CategorieVoyageur) => {
						return {
							...categ,
							libelle: categ.libelle ? (categ.libelle + ' (' + categ.code + ')') : categ.code
						}
					});

					//Si la map contient déjà des infos pour ce SBT
					if (this.mapPvParams.has(listeCategSBT.idSBTConfigUsed)) {
						//On ajoute juste la liste des Catégories voyageur
						this.mapPvParams.get(listeCategSBT.idSBTConfigUsed).listeCategorieVoyageur = listeCatVoyageur;
					} else {
						//Si la map ne contient pas d'infos pour ce SBT, on rajoute le SBT
						let pvParam: PvParams = new PvParams(listeCategSBT.idSBTConfigUsed,listeCategSBT.libelle,[],[],listeCatVoyageur)
						this.mapPvParams.set(listeCategSBT.idSBTConfigUsed,pvParam);
					}
				});
			}

			//On s'assure que la liste contenant les params est à jour
			this.updateListePvParams();
		} else {
			//Gestion de l'erreur
			TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
		}
	}

	/**
	 * Traitement des RLS
	 *
	 * @param result le result du chargement des infos relatives aux RLS
	 */
	processRls(result: Result): void {
		//Vérification du result
		if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
			//S'il y a des RLS
			if (result.data.listeRLSSBT?.length) {
				//On parcourt les RLS
				result.data.listeRLSSBT.forEach(listeRLSSBT => {
					//On récupère la liste des RLS
					let listeRLS: RLS[] = listeRLSSBT.listeRLSTrans.map((rls: RLS) => {
						return {
							...rls,
							libelle: rls.libelle ? (rls.libelle + ' (' + rls.valeur + ')') : rls.valeur
						}
					});

					//Si la map contient déjà des infos pour ce SBT
					if (this.mapPvParams.has(listeRLSSBT.idSBTConfigUsed)) {
						//On ajoute juste la liste des RLS
						this.mapPvParams.get(listeRLSSBT.idSBTConfigUsed).listeRLS = listeRLS;
					} else {
						//Si la map ne contient pas d'infos pour ce SBT, on rajoute le SBT
						let pvParam: PvParams = new PvParams(listeRLSSBT.idSBTConfigUsed,listeRLSSBT.libelle,[],listeRLS)
						this.mapPvParams.set(listeRLSSBT.idSBTConfigUsed,pvParam);
					}
				});
			}

			//On s'assure que la liste contenant les params est à jour
			this.updateListePvParams();
		} else {
			//Gestion de l'erreur
			TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
		}
	}

	/**
	 * Fonction appelée au clic sur le bouton de synchronisation du profil voyageur
	 */
	onClickSynchronize(): void {
		//Ouverture de la popup de synchronisation en cours
		const matDialogRef = this.matDialog.open(this.synchroTemplate,{
			disableClose: true,
			width: '600px',
			position: {
				top: '100px'
			},
			role: 'alertdialog'
		});

		//Début de la synchronisation
		this.profilService.syncProfilVoyageurCollab(this.idUser)
			.pipe(first())
			.subscribe((result: Result) => {
				//Si l'utilisateur a désactivé l'accès à ses données personnelles
				if (result?.codeErreur === 901) {
					//Message de warning RGPD
					this.toastrService.warning(this.translateService.instant('profilUser.infoPerso.synchroPV.synchroImpossibleRGPD'),
						this.translateService.instant('profilUser.infoPerso.synchroPV.synchroImpossibleRGPDTitle'),
						{toastClass: "ngx-toastr toast-rgpd"});

					//Fermeture de la popup dans 1s, parce que faut laisser un peu le temps de l'admirer
					setTimeout(() => matDialogRef.close(),1000);
				} else if (result?.data.listeSynchro) {
					//Fermeture de la popup dans 1s
					setTimeout(() => matDialogRef.close(),1000);

					//Mise à jour de la liste des synchronisations
					this.profilVoyageur.listeAllSynchro = result.data.listeSynchro;

					//Mise à jour de la liste des synchronisations distinctes par SBT
					this.profilVoyageur.listeSynchro = this.getListeSynchro(result.data.listeSynchro);

					//On gère les toasts de la synchro
					this.profilService.resultSynchroToast(<ResultSynchro[]>result.data.listeResultSynchro);

					//Mise à jour de la liste de documents et des cartes de voyage, car leur statut peut changer après synchro
					this.listeDocuments.refresh();
					this.listeCartesVoyage.refresh();

					//Rafraichissement des alertes
					this.refreshAlertes();
				}
			});
	}

	/**
	 * Appelé au changement de profil de connexion
	 */
	onProfilConnexionChange(): void {
		//Récupération du profil de connexion sélectionné par son ID
		let profilSelectionne: ProfilConnexion = this.listeProfilConnexion.find(profil => profil.idProfilConnexion === this.idProfilConnexionSelected);

		//Suppression du profil sélectionné pour le recharger par la suite
		delete this.idProfilConnexionSelected;

		//On recharge les informations liées au profil qui vient de changer
		this.loadInfosProfilConnexion(profilSelectionne.idProfilConnexion);
	}

	/**
	 * Enregistrement des communautés
	 */
	saveCommunautes(): Observable<Result> {
		//On récupère la liste des ID des communautés sélectionnées
		let listeIdCommu: number[] = [];
		for (let pvParam of this.mapPvParams.values()) {
			if (pvParam.idCommunauteSelected) {
				listeIdCommu.push(pvParam.idCommunauteSelected);
			}
		}

		//Enregistrement de la communauté
		return this.profilService.saveCommunaute(this.idUser,listeIdCommu)
			.pipe(first());
	}

	/**
	 * Enregistrement des catégories voyageur
	 */
	saveCategoriesVoyageur(): Observable<Result> {
		//On récupère la liste des ID des catégories voyageur sélectionnées
		let listeIdCategorieVoyageurSelected: number[] = [];
		for (let pvParam of this.mapPvParams.values()) {
			if (pvParam.idCategorieVoyageurSelected) {
				listeIdCategorieVoyageurSelected.push(pvParam.idCategorieVoyageurSelected);
			}
		}

		//Enregistrement de la catégorie voyageur
		return this.profilService.saveCategorie(this.idUser,listeIdCategorieVoyageurSelected)
			.pipe(first());
	}

	/**
	 * Enregistrement des RLS
	 */
	saveRls(): Observable<Result> {
		//On récupère la liste des ID des RLS sélectionnées
		let listeIdRlsSelected: number[] = [];
		for (let pvParam of this.mapPvParams.values()) {
			if (pvParam.idRlsSelected) {
				listeIdRlsSelected.push(pvParam.idRlsSelected);
			}
		}

		//Enregistrement du RLS
		return this.profilService.saveRLS(this.idUser,listeIdRlsSelected)
			.pipe(first());
	}

	/**
	 * Mise à jour de l'ID du pays de nationalité
	 */
	onPaysNationaliteChange(): void {
		this.infoVoyageur.idPaysNationalite = this.infoVoyageur.paysNationalite ? this.infoVoyageur.paysNationalite.id : null;
	}

	/**
	 * Mise à jour de l'ID de la ville de naissance
	 */
	onVilleNaissanceChange(): void {
		this.infoVoyageur.idVilleNaissance = this.infoVoyageur.villeNaissance ? this.infoVoyageur.villeNaissance.id : null;
	}

	/**
	 * Appel au changement de la checkbox ville inconnue
	 */
	onVilleInconnueChange(): void {
		//Si l'on coche ville inconnue
		if (!this.infoVoyageur.newVille) {
			//Suppression des champs ville, codePostal et paysNaissance
			delete this.infoVoyageur.ville;
			delete this.infoVoyageur.codePostal;
			delete this.infoVoyageur.paysNaissance;
		} else {
			//Sinon si l'on décoche ville inconnue, suppression du champ ville naissance
			delete this.infoVoyageur.villeNaissance;
		}
	}

	/**
	 * Mise à jour de l'ID du pays de naissance
	 */
	onPaysNaissanceChange(): void {
		this.infoVoyageur.idPaysNaissance = this.infoVoyageur.paysNaissance ? this.infoVoyageur.paysNaissance.id : null;
	}

	/**
	 * Ouverture de la popin d'ajout/modification d'un numéro de téléphone
	 *
	 * @param telephone le numéro de téléphone
	 */
	openTelephone(telephone: Telephone) {
		//Ouverture de la popin
		this.matDialog.open<ProfilDonneesAddTelephoneComponent,any,boolean>(ProfilDonneesAddTelephoneComponent,{
			data: {
				telephone: _.cloneDeep(telephone),
				mobileActif: this.listeTelephones.data.listeResultats.filter(value => value.type == TypeContact.MOBILE && value.actif).length > 0,
				proActif: this.listeTelephones.data.listeResultats.filter(value => value.type == TypeContact.TELEPHONE_PROFESSIONNEL && value.actif).length > 0,
				persoActif: this.listeTelephones.data.listeResultats.filter(value => value.type == TypeContact.TELEPHONE_PERSONNEL && value.actif).length > 0,
				idUser: this.idUser
			},
			width: '80%'
		}).afterClosed().subscribe((refresh: boolean) => {
			//Vérification de la nécessité de rafraîchir les données
			if (refresh) {
				//Charge les infos pour la synchro du profil voyageur
				this.loadSynchroProfilVoyageur();

				//Abonnement sur l'évènement de fin de rafraichissement de la liste
				this.listeTelephones.loaded.pipe(first()).subscribe(() => {
					//Rafraichissement des alertes
					this.refreshAlertes();
				});

				//Déclenchement du rafraichissement de la liste
				this.listeTelephones.refresh();
			}
		});
	}

	/**
	 * Ouverture de la popin d'ajout/modification d'une adresse
	 *
	 * @param adresse l'adresse
	 */
	openAdresse(adresse: AdresseListItem): void {
		//Ouverture de la popin
		this.matDialog.open<ProfilDonneesAddAdresseComponent,any,boolean>(ProfilDonneesAddAdresseComponent,{
			data: {
				adresse: {...adresse},
				listeAdresse: this.listeAdresses.data.listeResultats,
				settings: this.mainSettings.getValue(),
				idPortee: TypePortee.ADM,
				typeLieu: TypeLieu.TYPE_LIEU_RESIDENCE_FAMILIALE,
				idUser: this.idUser
			},
			width: '80%'
		}).afterClosed().subscribe((refresh: boolean) => {
			//Vérification de la nécessité de rafraîchir
			if (refresh) {
				//Charge les infos pour la synchro du profil voyageur
				this.loadSynchroProfilVoyageur();

				//Abonnement sur l'évènement de fin de rafraichissement de la liste
				this.listeAdresses.loaded.pipe(first()).subscribe(() => {
					//Rafraîchissement des alertes
					this.refreshAlertes();
				});

				//Déclenchement du rafraichissement de la liste
				this.listeAdresses.refresh();
			}
		});
	}

	/**
	 * Ouverture de la popin d'ajout/modification d'un document
	 *
	 * @param document le document
	 */
	openProfilDocument(document: Document) {
		//Ouverture de la popin
		this.matDialog.open<ProfilDonneesAddDocumentComponent,any,boolean>(ProfilDonneesAddDocumentComponent,{
			data: {
				document: _.cloneDeep(document),
				settings: this.mainSettings.getValue(),
				infoSociete: this.infoVoyageur,
				idUser: this.idUser
			},
			width: '80%'
		}).afterClosed().subscribe((refresh: boolean) => {
			//Vérification de la nécessité de rafraîchir les données
			if (refresh) {
				//Charge les infos pour la synchro du profil voyageur
				this.loadSynchroProfilVoyageur();

				//Abonnement sur l'évènement de fin de rafraichissement de la liste
				this.listeDocuments.loaded.pipe(first()).subscribe(() => {
					//Rafraichissement des alertes
					this.refreshAlertes();
				});

				//Déclenchement du rafraichissement de la liste
				this.listeDocuments.refresh();
			}
		});
	}

	/**
	 * Ouverture de la popin d'ajout/modification d'une carte de voyage
	 *
	 * @param carte la carte de voyage
	 */
	openCarteVoyage(carte: CarteVoyage) {
		//Ouverture de la popin
		this.matDialog.open<ProfilDonneesAddCarteVoyageComponent,any,boolean>(ProfilDonneesAddCarteVoyageComponent,{
			data: {
				carte: _.cloneDeep(carte),
				settings: this.mainSettings.getValue(),
				idUser: this.idUser
			},
			width: '80%'
		}).afterClosed().subscribe((refresh: boolean) => {
			//Vérification de la nécessité de rafraîchir les données
			if (refresh) {
				//Charge les infos pour la synchro du profil voyageur
				this.loadSynchroProfilVoyageur();

				//Abonnement sur l'évènement de fin de rafraichissement de la liste
				this.listeCartesVoyage.loaded.pipe(first()).subscribe(() => {
					//Rafraichissement des alertes
					this.refreshAlertes();
				});

				//Déclenchement du rafraichissement de la liste
				this.listeCartesVoyage.refresh();
			}
		});
	}

	/**
	 * Ouverture de la fenêtre d'ajout/édition des contacts d'urgence
	 * @param telephone Téléphone
	 */
	private openContactUrgence(telephone: Telephone) {
		const contactActifListe = this.listeContactUrgence.data.listeResultats.filter(value => value.type == "CONTACT_URGENCE" && value.actif);
		const emailActifListe = this.listeContactUrgence.data.listeResultats.filter(value => value.type == "EMAIL_ASSISTANT" && value.actif);

		this.matDialog.open(ProfilDonneesAddContactUrgenceComponent,{
			data: {
				telephone: {...telephone},
				idContactActif: contactActifListe.length == 0 ? 0 : contactActifListe[0].id,
				idEmailActif: emailActifListe.length == 0 ? 0 : emailActifListe[0].id,
				idUser: this.idUser
			},
			width: '80%'
		}).afterClosed().subscribe({
			next: refresh => {
				if (refresh) {
					//Charge les infos pour la synchro du profil voyageur
					this.loadSynchroProfilVoyageur();

					//Abonnement sur l'évènement de fin de rafraichissement de la liste
					this.listeContactUrgence.loaded.pipe(first()).subscribe(() => {
						//Rafraichissement des alertes
						this.refreshAlertes();
					});

					//Déclenchement du rafraichissement de la liste
					this.listeContactUrgence.refresh();
				}
			}
		});
	}

	/**
	 * Rafraîchissement des alertes
	 */
	refreshAlertes(): void {
		//Initialisation
		this.listeAlertes = [];
		this.niveauAlerteProfil = null;

		//Récupération des alertes et de leur configuration
		combineLatest([this.profilService.loadProfilAlertes(this.idUser),this.profilService.loadConfigAlerte(this.idUser)])
			.pipe(first())
			.subscribe(res => {
				//Récupération de la config
				this.configAlerte = res[1].data as ConfigAlerte;

				//Gestion des alertes à afficher
				this.handleAlertesAff(res[0].data as ProfilAlertes);

				//Gestion des alertes de liste
				this.handleAlertesListes(res[0].data as ProfilAlertes);
			});
	}

	/**
	 * Gestion de l'affichage des alertes
	 *
	 * @param profilAlertes les alertes du profil
	 */
	handleAlertesAff(profilAlertes: ProfilAlertes): void {
		if (profilAlertes?.saisieObligatoire?.listeSaisieManquante) {
			//On filtre la liste des saisies manquantes pour supprimer les alertes qui ne seront pas affichées dans cet onglet
			profilAlertes.saisieObligatoire.listeSaisieManquante = profilAlertes.saisieObligatoire.listeSaisieManquante.filter(saisie => ![TypeSaisie.COMPTE_BANCAIRE,TypeSaisie.VEHICULE].includes(saisie));
		}

		if (profilAlertes?.listeDocumentEchu) {
			//On filtre les listes pour ne garder que les alertes sur les pièces d'identité et les cartes d'abonnement et de fidélité
			profilAlertes.listeDocumentEchu = profilAlertes.listeDocumentEchu.filter(doc => [TypeFormulaire.PIECE_IDENTITE,TypeFormulaire.CARTE_ABO_FID].includes(doc.typeFormulaire));
		}

		if (profilAlertes?.listeDocumentEcheance) {
			//On filtre les listes pour ne garder que les alertes sur les pièces d'identité et les cartes d'abonnement et de fidélité
			profilAlertes.listeDocumentEcheance = profilAlertes.listeDocumentEcheance.filter(doc => [TypeFormulaire.PIECE_IDENTITE,TypeFormulaire.CARTE_ABO_FID].includes(doc.typeFormulaire));
		}

		//Gestion des documents échus
		if (profilAlertes?.saisieObligatoire?.listeSaisieManquante?.length || profilAlertes?.listeDocumentEchu?.length) {
			//Définition du niveau d'alerte
			this.niveauAlerteProfil = Math.max(this.niveauAlerteProfil ?? NiveauAlerte.ERROR,NiveauAlerte.ERROR);

			//Traitement des alertes bloquantes de documents manquants
			if (profilAlertes?.saisieObligatoire?.listeSaisieManquante?.length) {
				//Parcours des saisies manquantes
				for (const saisie of profilAlertes?.saisieObligatoire?.listeSaisieManquante) {
					//Ajout de l'alerte
					this.listeAlertes.push(new Alerte({
						titre: null,
						message: this.translateService.instant("profil.alerte.obligatoire." + saisie),
						niveau: NiveauAlerte.ERROR,
					}));
				}
			}

			//Traitement des alertes bloquantes de documents échus
			if (profilAlertes?.listeDocumentEchu?.length) {
				//Parcours des documents échus
				for (const document of profilAlertes?.listeDocumentEchu) {
					//Récupération de la date
					const dateEcheance: Date = new Date(document.dateEcheance);

					//Ajout de l'alerte
					this.listeAlertes.push(new Alerte({
						titre: null,
						message: this.translateService.instant("profil.alerte.document." + document.typeDocument)
							+ this.translateService.instant("profil.alerte.echu." + document.typeFormulaire,{
								libelle: document.libelle,
								numero: document.numero,
								date: this.datePipe.transform(dateEcheance,'shortDate')
							}),
						niveau: NiveauAlerte.ERROR
					}));
				}
			}
		}

		//Gestion des documents arrivant à échéance
		if (profilAlertes?.listeDocumentEcheance?.length) {
			//Définition du niveau d'alerte
			this.niveauAlerteProfil = Math.max(this.niveauAlerteProfil ?? NiveauAlerte.WARNING,NiveauAlerte.WARNING);

			//Parcours des documents arrivant à échéance
			for (const document of profilAlertes?.listeDocumentEcheance) {
				//Définition de la date du jour
				const now: Date = new Date();

				//Récupération de la date
				const dateEcheance: Date = new Date(document.dateEcheance);

				//Ajout de l'alerte
				this.listeAlertes.push(new Alerte({
					titre: null,
					message: this.translateService.instant("profil.alerte.document." + document.typeDocument)
						+ this.translateService.instant("profil.alerte.echeance." + document.typeFormulaire,{
							libelle: document.libelle,
							numero: document.numero,
							date: this.datePipe.transform(dateEcheance,'shortDate'),
							nbJours: Math.ceil(Math.abs(dateEcheance.valueOf() - now.valueOf()) / (1000 * 60 * 60 * 24))
						}),
					niveau: NiveauAlerte.WARNING
				}));
			}
		}

		//Gestion de l'alerte informant que le profil voyageur doit être synchronisé
		if (this.configAlerte?.lienProfilConnexion?.updated && profilAlertes?.saisieObligatoire?.profilVoyageurValide) {
			//Définition du niveau d'alerte
			this.niveauAlerteProfil = Math.max(this.niveauAlerteProfil ?? NiveauAlerte.WARNING,NiveauAlerte.WARNING);

			//Ajout de l'alerte
			this.listeAlertes.push(new Alerte({
				titre: null,
				message: this.translateService.instant("profil.alerte.synchro"),
				niveau: NiveauAlerte.WARNING
			}));
		}
	}

	/**
	 * Gestion des niveaux d'alertes des listes
	 *
	 * @param profilAlertes les alertes du profil
	 */
	handleAlertesListes(profilAlertes: ProfilAlertes) {
		//Initialisation des alertes des listes
		this.infoVoyageurAlertLevel = undefined;
		this.listeTelephones.alertLevel = undefined;
		this.listeAdresses.alertLevel = undefined;
		this.listeDocuments.alertLevel = undefined;
		this.listeCartesVoyage.alertLevel = undefined;
		this.listeContactUrgence.alertLevel = undefined;

		//Traitement des alertes de type WARNING des documents arrivants à échéance
		if (profilAlertes?.listeDocumentEcheance?.length) {
			//Parcours des documents arrivants à échéance
			for (const document of profilAlertes.listeDocumentEcheance) {
				switch (document.typeFormulaire) {
					case TypeFormulaire.PIECE_IDENTITE:
						this.listeDocuments.alertLevel = NiveauAlerte.WARNING;
						break;
					case TypeFormulaire.CARTE_ABO_FID:
						this.listeCartesVoyage.alertLevel = NiveauAlerte.WARNING;
						break;
				}
			}
		}

		//Traitement des alertes de type ERROR des documents échus
		if (profilAlertes?.listeDocumentEchu?.length) {
			//Parcours des documents échus
			for (const document of profilAlertes.listeDocumentEchu) {
				switch (document.typeFormulaire) {
					case TypeFormulaire.PIECE_IDENTITE:
						this.listeDocuments.alertLevel = NiveauAlerte.ERROR;
						break;
					case TypeFormulaire.CARTE_ABO_FID:
						this.listeCartesVoyage.alertLevel = NiveauAlerte.ERROR;
						break;
				}
			}
		}

		//Traitement des alertes de type ERROR des documents manquants
		if (profilAlertes?.saisieObligatoire?.listeSaisieManquante?.length) {
			//Parcours des saisies manquantes
			for (const saisie of profilAlertes.saisieObligatoire.listeSaisieManquante) {
				switch (saisie) {
					case TypeSaisie.INFORMATION_VOYAGEUR:
						this.infoVoyageurAlertLevel = NiveauAlerte.ERROR;
						break;
					case TypeSaisie.TELEPHONE:
						this.listeTelephones.alertLevel = NiveauAlerte.ERROR;
						break;
					case TypeSaisie.ADRESSE:
						this.listeAdresses.alertLevel = NiveauAlerte.ERROR;
						break;
					case TypeSaisie.PIECE_IDENTITE:
						this.listeDocuments.alertLevel = NiveauAlerte.ERROR;
						break;
					case TypeSaisie.CONTACT_URGENCE:
						this.listeContactUrgence.alertLevel = NiveauAlerte.ERROR;
						break;
				}
			}
		}
	}

	/**
	 * Charge les infos pour la synchro du profil voyageur
	 */
	loadSynchroProfilVoyageur(): void {
		//Si on a le droit de gérer le profil voyageur
		if (this.isDroitProfilVoyageur) {
			//Chargement des infos pour la synchro du profil voyageur
			this.profilService.getSynchroProfilVoyageur(this.idUser)
				.pipe(first())
				.subscribe((result: Result) => {
					//Récupération du profil voyageur
					this.profilVoyageur = result.data;

					//Vérification de la présence du profil voyageur
					if (this.profilVoyageur) {
						//Mise à jour de la liste des synchronisations
						this.profilVoyageur.listeAllSynchro = result.data.listeSynchro;

						//Mise à jour de la liste des synchronisations distinctes par SBT
						this.profilVoyageur.listeSynchro = this.getListeSynchro(result.data.listeSynchro);
					}
				});
		}
	}

	/**
	 * Renvoie la liste des synchros uniques par SBT.
	 *
	 * @param listeSynchro liste de toutes les synchros
	 * @return la liste des synchros uniques
	 */
	getListeSynchro(listeSynchro: SynchroSBTConfigUser[]): SynchroSBTConfigUser[] {
		return listeSynchro.filter((synchro,index,array) => {
			return array.findIndex(s => s.idSBTConfig === synchro.idSBTConfig) === index;
		});
	}

	/**
	 * Sauvegarde des infos voyageur
	 */
	saveInfoVoyageur(): void {
		//Sauvegarde en cours
		this.isSaving = true;

		//On mappe la variable nom dans nomUsage
		this.infoVoyageur.nomUsage = this.infoVoyageur.nom;

		//Création des enregistrements à effectuer
		const forks: Observable<Result>[] = [
			this.saveCommunautes(),
			this.saveCategoriesVoyageur(),
			this.saveRls(),
			this.profilService.updateInfosProfilUser(this.infoVoyageur,this.idUser)
		];

		//Enregistrement du profil de connexion
		this.profilService.saveProfilConnexion(this.idUser,this.idProfilConnexionSelected).subscribe(result => {
			//Si on n'a pas d'erreur
			if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
				//On lance le reste des enregistrements
				forkJoin(forks)
					.pipe(first(),finalize(() => this.isSaving = false))
					.subscribe((results: Result[]) => {
						//On regarde si on a une erreur sur un enregistrement
						const resultWithError: Result = results.find(result => result.codeErreur !== TypeCodeErreur.NO_ERROR);

						//Si on n'a pas d'erreur
						if (resultWithError == null) {
							//Notification de succès
							this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

							//Rafraîchissement des alertes
							this.refreshAlertes();
						} else {
							//Gestion de l'erreur
							TypeCodeErreur.showError(resultWithError.codeErreur,this.translateService,this.toastrService);
						}
					});
			}
		});
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy(): void {
		//Désabonnements
		this.listeSubscription.forEach(sub => sub.unsubscribe());
	}
}

/**
 * Classe des paramètres du Profil Voyageur
 */
class PvParams {
	/** Identfiant du SBT concerné */
	public idSbt: number;

	/** Libellé du SBT */
	public libelle: string;

	/** Identifiant de la communauté sélectionnée pour ce SBT */
	public idCommunauteSelected;

	/** Identifiant du RLS sélectionné pour ce SBT */
	public idRlsSelected;

	/** Identifiant de la catégorie voyageur sélectionnée pour ce SBT */
	public idCategorieVoyageurSelected;

	/** Liste des communautés disponibles pour le SBT */
	private _listeCommunaute: Communaute[];

	/** Liste des RLS disponibles pour le SBT */
	private _listeRLS: RLS[];

	/** Liste des catégories voyageur disponibles pour le SBT */
	private _listeCategorieVoyageur: CategorieVoyageur[];


	/**
	 * Constructeur
	 *
	 * @param id						Id du SBT
	 * @param libelle					Libellé du SBT
	 * @param listeCommunautes			Liste des communautés disponibles pour le SBT
	 * @param listeRLS					Liste des RLS disponibles pour le SBT
	 * @param listeCategorieVoyageur	Liste des catégories voyageurs disponibles pour le SBT
	 */
	constructor(id: number,libelle: string,listeCommunautes: Communaute[] = [],listeRLS: RLS[] = [],listeCategorieVoyageur: CategorieVoyageur[] = []) {
		this.idSbt = id;
		this.libelle = libelle;

		this._listeCommunaute = listeCommunautes;
		//Recherche de la communauté sélectionnée
		this.updateCommunauteSelected();

		this._listeRLS = listeRLS;
		//Recherche du RLS sélectionné
		this.updateRlsSelected();

		this._listeCategorieVoyageur = listeCategorieVoyageur;
		//Recherche de la catégorie voyageur sélectionnée
		this.updateCategVoyageurSelected();
	}

	/** Met à jour la communauté selectionnée en fonction de la liste des communautés disponibles */
	updateCommunauteSelected(): void {
		//Recherche de la communauté sélectionnée
		this.idCommunauteSelected = this._listeCommunaute.find(commu => commu.used)?.idCommunaute;

		//Si aucune communauté sélectionnée, on recherche s'il n'y en pas une par défaut
		if (!this.idCommunauteSelected) {
			this.idCommunauteSelected = this._listeCommunaute.find(commu => commu.defaut)?.idCommunaute;
		}
	}

	/** Met à jour le RLS selectionné en fonction de la liste des RLS disponibles */
	updateRlsSelected(): void {
		this.idRlsSelected = this._listeRLS.find(rls => rls.used)?.idRLS;

		//Si aucun RLS sélectionné, on recherche s'il n'y en a pas un par défaut
		if (!this.idRlsSelected) {
			this.idRlsSelected = this._listeRLS.find(rls => rls.defaut)?.idRLS;
		}
	}

	/** Met à jour la catégorie voyageur selectionnée en fonction de la liste des catégories voyageur disponibles */
	updateCategVoyageurSelected(): void {
		this.idCategorieVoyageurSelected = this._listeCategorieVoyageur.find(categ => categ.used)?.idCategorie;

		//Si aucune catégorie voyageur sélectionnée, on recherche s'il n'y en a pas une par défaut
		if (!this.idCategorieVoyageurSelected) {
			this.idCategorieVoyageurSelected = this._listeCategorieVoyageur.find(categ => categ.defaut)?.idCategorie;
		}
	}

	/** Liste des communautés disponibles pour le SBT */
	get listeCommunaute(): Communaute[] {return this._listeCommunaute;}

	/** Liste des RLS disponibles pour le SBT */
	get listeRLS(): RLS[] {return this._listeRLS;}

	/** Liste des Catégories voyageur disponibles pour le SBT */
	get listeCategorieVoyageur(): CategorieVoyageur[] {return this._listeCategorieVoyageur;}

	set listeCommunaute(value: Communaute[]) {
		this._listeCommunaute = value;
		this.updateCommunauteSelected();
	}

	set listeRLS(value: RLS[]) {
		this._listeRLS = value;
		this.updateRlsSelected();
	}

	set listeCategorieVoyageur(value: CategorieVoyageur[]) {
		this._listeCategorieVoyageur = value;
		this.updateCategVoyageurSelected();
	}
}