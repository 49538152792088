import {Component,OnInit} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {TerritoirePerimetreItem} from "@domain/admin/bibliotheque/geographie/territoirePerimetreItem";
import {TypeGeoLocalisation} from "@domain/geographie/geoLocalisation";
import {TranslateService} from "@ngx-translate/core";
import {TypeCodeErreur} from '@domain/common/http/result';
import {ToastrService} from "ngx-toastr";
import {GeographieService} from "@services/admin/geographie/geographie.service";

/**
 * Composant d'un périmètre dans la liste des périmètres
 *
 * @author Angeline Ha
 * @date 02/11/2023
 */
@Component({
	host: {'data-test-id': 'territoire-perimetre-item'},
	templateUrl: './territoire-perimetre-item.component.html'
})
export class TerritoirePerimetreItemComponent extends ListViewItem<TerritoirePerimetreItem> implements OnInit {

	/** Libellé du type de périmètre */
	libelleType: string;

	/** Accès à l'enum dans la vue */
	readonly TypeGeoLocalisation = TypeGeoLocalisation;

	/**
	 * Constructeur
	 *
	 * @param translateService  Service de traduction
	 * @param geographieService Service des territoires
	 * @param toastrService		Service de gestion des toasts
	 */

	constructor(private translateService: TranslateService,
				private geographieService: GeographieService,
				private toastrService: ToastrService) {
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit(): void {
		this.typeIntToLibelle();
	}

	/**
	 * Récupère de libellé du type de périmètre à partir de l'identifiant du type
	 */
	typeIntToLibelle() {
		switch (this.data.type) {
			case TypeGeoLocalisation.PAYS:
				this.libelleType = this.translateService.instant('admin.bibliotheque.geographie.territoire.perimetre.pays');
				break;
			case TypeGeoLocalisation.REGION:
				this.libelleType = this.translateService.instant('admin.bibliotheque.geographie.territoire.perimetre.region');
				break;
			case TypeGeoLocalisation.DEPARTEMENT:
				this.libelleType = this.translateService.instant('admin.bibliotheque.geographie.territoire.perimetre.departement');
				break;
			case TypeGeoLocalisation.VILLE:
				this.libelleType = this.translateService.instant('admin.bibliotheque.geographie.territoire.perimetre.ville');
				break;
			case TypeGeoLocalisation.ZONE:
				this.libelleType = this.translateService.instant('admin.bibliotheque.geographie.territoire.perimetre.zone');
				break;

		}
	}

	/**
	 * Suppression d'un périmètre
	 */
	deletePerimetre() {
		//Suppression du périmètre
		this.geographieService.deleteGeoLocalisation(this.data.idGeoLocalisation).subscribe({
			next: data => {
				//Vérification de la suppression
				if (data.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Refresh la liste des périmètres
					this.geographieService.refreshListeGeoLocalisations();

					//Toast de succès
					this.toastrService.success(this.translateService.instant('global.success.suppression'));

				} else {
					//Toast d'erreur
					this.toastrService.error(this.translateService.instant('global.errors.suppression'));
				}
			}
		});
	}
}
