<mat-card>
    <mat-card-title>
        <span translate>admin.voyages.travelhub.sbtConfig.details.title</span>
    </mat-card-title>
    <mat-card-content>
        <fieldset>
            <div class="row">
                <!-- Libellé -->
                <custom-input ngDefaultControl id="libelle" name="libelle"
                        customType="input" libelle="admin.voyages.travelhub.sbtConfig.libelle"
                        [(ngModel)]="sbtConfigUsed.libelle"
                        [required]="true">
                </custom-input>

                <!-- Portail -->
                <custom-input ngDefaultControl id="portail" name="portail"
                        customType="input" libelle="admin.voyages.travelhub.sbtConfig.portail"
                        [ngModel]="sbtConfigUsed.sbtConfig != null ? portail : '-'"
                        [readonly]="true">
                </custom-input>
            </div>
            <div class="row">
                <!-- Configuration Travel Hub -->
                <custom-input ngDefaultControl id="configTravelhub" name="configTravelhub"
                        customType="objectselect" libelle="admin.voyages.travelhub.sbtConfig.configTravelhub"
                        [ngModel]="sbtConfigUsed.sbtConfig?.config"
                        optionValue="idConfig"
                        optionDisplay="libelle"
                        [selectOptions]="listeConfigTH"
                        (onChange)="onChangeConfigTH($event)"
                        [required]="true">
                </custom-input>

                <!-- Agence -->
                <custom-input ngDefaultControl id="agence" name="agence"
                        customType="input" libelle="admin.voyages.travelhub.sbtConfig.agence"
                        [ngModel]="sbtConfigUsed.sbtConfig != null ? sbtConfigUsed.sbtConfig.agence : '-'"
                        [readonly]="true">
                </custom-input>
            </div>
            <div class="row">
                <!-- Configuration SBT -->
                <custom-input #sbtConfigInput
                        [lClass]="!sbtConfigUsed.sbtConfig?.numSBT ? 'has-error' : ''"
                        [matError]="!sbtConfigUsed.sbtConfig?.numSBT ? ('admin.voyages.travelhub.sbtConfig.message.synchroSbtRequise' | translate) : ''"
                        ngDefaultControl id="configSbt" name="configSbt"
                        customType="objectselect" libelle="admin.voyages.travelhub.sbtConfig.configSbt"
                        [(ngModel)]="sbtConfig"
                        optionValue="idSBTConfig"
                        optionDisplay="code"
                        [selectOptions]="listeConfigSBTFiltered"
                        (onChange)="onChangeConfigSBT()"
                        [postTooltipNio]="{content: 'admin.voyages.travelhub.sbtConfig.tooltip.synchroConfigTH' | translate ,icon: 'sync',onClick: synchronizeDetail.bind(this)}"
                        [required]="true">
                </custom-input>

                <!-- Type -->
                <custom-input ngDefaultControl id="type" name="type"
                        customType="input" libelle="admin.voyages.travelhub.sbtConfig.type"
                        [ngModel]="sbtConfigUsed.sbtConfig != null ? adminTravelhubService.getTypeConfig(sbtConfigUsed.sbtConfig) : '-'"
                        [readonly]="true">
                </custom-input>
            </div>
            <div class="row">
                <!-- Date de dernière synchronisation -->
                <custom-input ngDefaultControl id="dateDerniereSynchro" name="dateDerniereSynchro"
                        customType="input" libelle="admin.voyages.travelhub.sbtConfig.dateDerniereSynchro"
                        [ngModel]="(sbtConfigUsed.sbtConfig?.dateUpdated | date:'short') || '-'"
                        [readonly]="true">
                </custom-input>

                <!-- Prestations -->
                <label class="col-md-2">{{ 'admin.voyages.travelhub.sbtConfig.prestations' | translate }}</label>
                <div class="col-md-4 col-info-prestations">
                    <span>{{ sbtConfigUsed.sbtConfig != null ? ('admin.voyages.travelhub.sbtConfig.prestationsAutorisees.' + (prestationsAutorisees.length > 1 ? 'plural' : 'one') | translate:{nb: prestationsAutorisees.length}) : '-' }}</span>
                    <mat-icon class="material-icons-outlined ml-1" [nioTooltip]="sbtConfigUsed.sbtConfig != null ? tooltipPrestations: null"
                        [nioTooltipOptions]="{isDisabled: prestationsAutorisees.length === 0}">info</mat-icon>
                </div>
            </div>
            <!-- Date de création -->
            <div *ngIf="sbtConfigUsed.idSBTConfigUsed > 0" class="row">
                <custom-input ngDefaultControl id="dateCreation" name="dateCreation"
                        customType="input" libelle="admin.voyages.travelhub.sbtConfig.dateCreation"
                        [ngModel]="sbtConfigUsed.dateCreation | date:'short'"
                        [readonly]="true">
                </custom-input>
            </div>
        </fieldset>
    </mat-card-content>
</mat-card>

<!-- Tooltip de détails de la liste des prestations autorisées -->
<ng-template #tooltipPrestations>
    <form class="nio-tooltip-details">
        <div class="row">
            <div class="col bold c-primary text-center">{{ 'admin.voyages.travelhub.sbtConfig.prestations' | translate }}</div>
        </div>
        <hr/>
        <div class="col">
            <ul>
                <li *ngFor="let presta of prestationsAutorisees">{{ getPrestationLibelle(presta) }}</li>
            </ul>
        </div>
    </form>
</ng-template>