<div mat-dialog-content>
    <form autocomplete="off" #analytiqueAddForm="ngForm">
        <div class="row" *ngIf="isShowVentilation" [ngClass]="{ 'has-error': !isVentilationValid() }" >
            <label class="col-md-2 required"><span [translate]="'analytique.ventilation'"></span></label>
            <div class="col-md-3">
                <mat-form-field class="d-flex">
                    <input matInput name="ventilation" #ventilation="ngModel" [(ngModel)]="data.analytique.pourcentage" [errorStateMatcher]="errorStateMatcherVentilation" [nNumber]="precision" required/>
                    <span matSuffix>&#160;%</span>
                </mat-form-field>
            </div>
        </div>
        <div class="row" [ngClass]="{ 'has-error': service.invalid }">
            <label class="col-md-2 required"><span [translate]="'analytique.service'"></span></label>
            <div class="col-md-10">
                <autocomplete type="service" name="service" #service="ngModel"
                      [filter]="{ idTypeEntite: data.typeEntite.idTypeEntite }" [(ngModel)]="data.analytique.orga" ngDefaultControl
                      [required]="true" (onSelect)="resetDossier(); resetAxe5(); resetAxe6()">
                </autocomplete>
            </div>
        </div>
        <div class="row" *ngIf="data.typeEntite.codeProjet" [ngClass]="{ 'has-error': dossier.invalid }">
            <label class="col-md-2" [ngClass]="{ 'required': data.typeEntite.codeProjetObligatoire }"><span [translate]="'analytique.dossier'"></span></label>
            <div class="col-md-10">
                <autocomplete type="dossier" name="dossier" #dossier="ngModel"
                    [filter]="{ idTypeEntite: data.typeEntite.idTypeEntite,idOrga: data.analytique.orga?.idOrga,idMIB: data.idMIB }"
                    [(ngModel)]="data.analytique.dossier" ngDefaultControl [required]="data.typeEntite.codeProjetObligatoire"
                    (onSelect)="resetAxe5(); resetAxe6()"
                    (ngModelChange)="onAutocompleteAnalytiqueChanged($event, TypeEntiteAxe.Dossier)"
                    [reinitListeObs]="reinitListeDossier$.asObservable()">>
                </autocomplete>
            </div>
        </div>
        <div class="row" *ngIf="data.typeEntite.axe5" [ngClass]="{ 'has-error': axe5.invalid }">
            <label class="col-md-2" [ngClass]="{ 'required': data.typeEntite.axe5Obligatoire }"><span>{{ data?.settings?.libelleAxe5 || ('analytique.axe5' | translate) }}</span></label>
            <div class="col-md-10">
                <autocomplete type="axe5" name="axe5" #axe5="ngModel"
                    [filter]="{ idTypeEntite: data.typeEntite.idTypeEntite,idOrga: data.analytique.orga?.idOrga,idDossier: data.analytique.dossier?.idDossier,idMIB: data.idMIB }"
                    [(ngModel)]="data.analytique.axe5" ngDefaultControl [required]="data.typeEntite.axe5Obligatoire"
                    (onSelect)="resetAxe6()"
                    (ngModelChange)="onAutocompleteAnalytiqueChanged($event, TypeEntiteAxe.Cinq)"
                    [reinitListeObs]="reinitListeAxe5$.asObservable()">
                </autocomplete>
            </div>
        </div>
        <div class="row" *ngIf="data.typeEntite.axe6" [ngClass]="{ 'has-error': axe6.invalid }">
            <label class="col-md-2" [ngClass]="{ 'required': data.typeEntite.axe6Obligatoire }"><span>{{ data?.settings?.libelleAxe6 || ('analytique.axe6' | translate) }}</span></label>
            <div class="col-md-10">
                <autocomplete type="axe6" name="axe6" #axe6="ngModel"
                    [filter]="{ idTypeEntite: data.typeEntite.idTypeEntite,idOrga: data.analytique.orga?.idOrga,idDossier: data.analytique.dossier?.idDossier,idAxe5: data.analytique.axe5?.idAxe5,idMIB: data.idMIB }"
                    [(ngModel)]="data.analytique.axe6" ngDefaultControl
                    [required]="data.typeEntite.axe6Obligatoire"
                    (ngModelChange)="onAutocompleteAnalytiqueChanged($event, TypeEntiteAxe.Six)"
                    [reinitListeObs]="reinitListeAxe6$.asObservable()">
                </autocomplete>
            </div>
        </div>
        <div class="row has-error mb-2" *ngIf="isRepartitionAnalytiqueExists()">
            <div class="col-md-10 offset-md-2 danger" translate>analytique.repartitionDoublon</div>
        </div>
        <ng-container *ngIf="data.typeEntite.field5 && getFieldParam('OPERATION_1') as operation">
            <div class="row" [ngClass]="{ 'has-error': operation1.invalid }">
                <label class="col-md-2" [ngClass]="{ 'required': data.typeEntite.field5Obligatoire || operation.obligatoire }"><span>{{ operation.libelle }}</span></label>
                <div class="col-md-3">
                    <mat-form-field class="d-flex">
                        <input matInput name="operation_1" #operation1="ngModel" [(ngModel)]="data.analytique.code1" [minlength]="operation.tailleMinimale" [maxlength]="operation.masque.length" [pattern]="getPatternFor(operation)" [required]="data.typeEntite.field5Obligatoire || operation.obligatoire"/>
                        <mat-icon matSuffix [matTooltip]="'analytique.ajout.operationDescription' | translate:{ masque: operation.masque }">info</mat-icon>
                    </mat-form-field>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="data.typeEntite.field6 && getFieldParam('OPERATION_2') as operation">
            <div class="row" [ngClass]="{ 'has-error': operation2.invalid }">
                <label class="col-md-2" [ngClass]="{ 'required': data.typeEntite.field6Obligatoire || operation.obligatoire }"><span>{{ operation.libelle }}</span></label>
                <div class="col-md-3">
                    <mat-form-field class="d-flex">
                        <input matInput name="operation_2" #operation2="ngModel" [(ngModel)]="data.analytique.code2" [minlength]="operation.tailleMinimale" [maxlength]="operation.masque.length" [pattern]="getPatternFor(operation)" [required]="data.typeEntite.field6Obligatoire || operation.obligatoire"/>
                        <mat-icon matSuffix [matTooltip]="'analytique.ajout.operationDescription' | translate:{ masque: operation.masque }">info</mat-icon>
                    </mat-form-field>
                </div>
            </div>
        </ng-container>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button color="warn" (click)="deleteAnalytique()" *ngIf="data.idxAnalytique >= 0">
        <span translate>global.actions.supprimer</span>
    </button>
    <button mat-flat-button color="primary" [disabled]="!analytiqueAddForm.valid || !isAnalytiqueValid()" [mat-dialog-close]="data.analytique">
        <span translate>global.actions.enregistrer</span>
    </button>
</div>
