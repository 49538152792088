import {Component,OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ListView} from "@domain/common/list-view";
import {DematService} from "@services/admin/parametre/demat/demat.service";
import {LotArchivage} from "@domain/demat/lot-archivage";
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {ArchivageLotListItemComponent} from "@components/admin/parametre/demat/archivage/lot-list/lot-list-item/archivage-lot-list-item.component";
import {filter,finalize,first} from "rxjs/operators";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";

/**
 * Composant de la liste des lots comptables.
 *
 * @author François Turin
 * @date 27/06/2023
 */
@Component({
	host: {'data-test-id': 'archivage-lot-list'},
	templateUrl: './archivage-lot-list.component.html',
	selector: 'demat-archivage'
})
export class ArchivageLotListComponent implements OnInit {
	/** Liste des lots */
	liste: ListView<LotArchivage,ArchivageLotListItemComponent>;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/**
	 * Indique si le routing de détail a été activé.
	 */
	get isInDetail() {
		return this.dematService.isInDetail;
	}

	constructor(private translateService: TranslateService,
				private toastrService: ToastrService,
				private confirmService: ConfirmService,
				private pleaseWaitService: PleaseWaitService,
				private dematService: DematService) {
		//Obtention de la liste des lots depuis le service
		this.liste = this.dematService.listeLots;
	}

	ngOnInit() {
		//Définition de la liste des actions
		this.listeActions.next([{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-archive',
			libelle: 'admin.parametres.demat.archivage.liste.actions.synchroniser',
			tooltip: () => 'admin.parametres.demat.archivage.liste.actions.tooltipSynchroniser',
			doAction: () => this.archiverLots(),
			isVisible: () => this.liste.nbSelectedItems > 0
		},{
			type: TypeAction.SECONDARY,
			icone: 'nio icon-tout_selectionner',
			libelle: 'liste.actions.selectionnerElementsAffiches',
			doAction: () => this.liste.selectAll(true),
			isVisible: () => this.liste.nbSelectedItems > 0
		},{
			type: TypeAction.SECONDARY,
			icone: 'nio icon-annuler_selection',
			libelle: 'liste.actions.toutDeselectionner',
			doAction: () => this.liste.selectAll(false),
			isVisible: () => this.liste.nbSelectedItems > 0
		}]);
	}

	/**
	 * Archivage en masse des lots sélectionnés.
	 */
	archiverLots(): void {
		//S'il y a bien des éléments sélectionnés
		if (this.liste.nbSelectedItems > 0) {
			//Ouverture de la popup de confirmation
			this.confirmService.showConfirm(this.translateService.instant('admin.parametres.demat.archivage.confirmerSynchroLots')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
				next: () => {
					let waitDialogRef = this.pleaseWaitService.show();

					//On envoie la demande de synchronisation
					this.dematService.archiverLots(this.liste.listeObjetsSectionnes)
						.pipe(first(),finalize(() => {
							waitDialogRef.close();
						}))
						.subscribe(result => {
							//Refresh de la liste
							this.liste.refresh();

							//S'il n'y a pas d'erreur
							if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('admin.parametres.demat.archivage.synchroDermarree'));
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('admin.parametres.demat.archivage.synchroErreur'));
							}
						})
				}
			});
		}
	}
}
