import { ListeAlertes } from "../common/alerte/listeAlertes";
import { ListItem } from "../common/list-view/list-item";
import { User } from "../user/user";
import { Statut } from "../workflow/statut";
import { AbstractObjetWorkflow } from "../workflow/abstract-objet-workflow";
import { TypePortee } from "../workflow/workflow";
import { MapAction } from "../workflow/mapAction";
import { TranslateService } from "@ngx-translate/core";

/**
 * Modèle représentant une avance listée
 */
export class ListeAvance extends AbstractObjetWorkflow implements ListItem {
    idAvance: number;
	libelleObjet: string;
	statut: Statut;
	idOd: number;
	user: User;
	nature: string;
	montant: number;
	montantLettre: number;
	devise: string;
	dateStatut: Date;
	listeAlertes: ListeAlertes;
	listeHistoWorkflow: MapAction;

    constructor(dto: any) {
        super();
        if (dto) {
            this.idAvance = dto.idAvance;
            this.libelleObjet = dto.libelleObjet;
            this.statut = dto.statut as Statut;
            this.idOd = dto.idOd;
            this.user = dto.user as User;
            this.nature = dto.nature;
            this.montant = dto.montant;
            this.montantLettre = dto.montantLettre;
            this.devise = dto.devise;
            if (dto.dateStatut) { this.dateStatut = new Date(dto.dateStatut) };
            this.listeAlertes = dto.listeAlertes as ListeAlertes;
            if (dto.listeHistoWorkflow) { this.listeHistoWorkflow = new MapAction(dto.listeHistoWorkflow); }
        }
    }

    /** Indicateur d'affichage de l'élément */
    isDisplayed?: boolean;

    /** Indicateur de sélection de l'élément */
    isSelected?: boolean;

    /**
     * Récupération de l'identifiant de l'objet
     * @returns l'identifiant de l'objet
     */
    getKey(): number {
        return this.idAvance;
    }

    getId(): number {
        return this.idAvance;
    }

    getPortee(): TypePortee {
        return TypePortee.AV;
    }

    getListeAlertes(): ListeAlertes {
        return this.listeAlertes;
    }

    getMapAction(): MapAction {
        return this.listeHistoWorkflow;
    }

    setListeAlertes(listeAlertes: ListeAlertes): void {
        this.listeAlertes = listeAlertes;
    }

    /**
     * Getter pour le collaborateur
     */
    getCollaborateur(): String {
        if (!this.user) { return null; }
        return (this.user.nom ? this.user.nom + ' ' : '')
                + (this.user.prenom ? this.user.prenom : '');
    }

    /**
     * Getter pour la liste de rôles format texte
     */
    getRoles(): string {
        return this.getMapAction()?.roles?.map(r => r.libelle).join("\n");
    }

    /**
     * Getter pour le nombre de rôles
     */
    getRolesCount(): number {
        return this.getMapAction()?.roles?.length;
    }

    /**
     * Getter pour l'ID Collab
     */
    getIdCollab(): number {
        return this.user?.idUser;
    }

    /**
     * Getter pour l'intitulé de l'objet
     * @param translateService service de traduction
     */
    getLibelleObjet(translateService: TranslateService): string {
        return '' + (this.idAvance || translateService.instant('avance.liste.numeroNonDefini')) + ' - ' + (this.libelleObjet || translateService.instant('avance.liste.objetNonDefini'));
    }

    /**
     * Getter pour la validité de l'objet
     */
    isValid(): boolean {
        //Hardcodé en attendant l'implémentation des données calculées
        return true;
    }

    /**
     * Getter pour le montant à afficher
     */
    get montantTotal(): number {
        return (this.montant ? this.montant : 0) + (this.montantLettre ? this.montantLettre : 0);
    }
}