/** Classe des Rôles Admin */
export class RoleAdmin {
    /** Identifiant du rôle */
    idRole: number;

    /** Droit associé */
    droit: DroitAdmin;

    /**
     * Constructeur
     *
     * @param idRole	Identifiant du rôle à copier
     * @param droit		Libellé du droit à affecter au rôle
     */
    constructor(idRole: number,droit: string) {
        this.idRole = idRole;
        this.droit = droit as DroitAdmin;
    }
}

/**
 * Enum des droits possibles pour un sous-admin
 */
export enum DroitAdmin {
    DROIT_ANALYTIQUE_DOSSIER = "analytique_dossier",
    DROIT_ANALYTIQUE_AXE_5 = "analytique_axe_5",
    DROIT_ANALYTIQUE_AXE_6 = "analytique_axe_6",
    DROIT_USER_GESTION = "user_gestion",
    DROIT_USER_ANALYTIQUE = "user_analytique",
    DROIT_USER_REFERENTIEL = "user_referentiel",
    DROIT_USER_ALERTE_PROFIL = "user_alerte_profil",
    DROIT_POPULATION_GESTION = "population_gestion",
    DROIT_POPULATION_ADMIN = "population_admin",
    DROIT_HABILITATION_GESTION = "habilitation_gestion",
    DROIT_BUDGET_ENVELOPPE = "budget_enveloppe",
    DROIT_COMPTE_GESTION = "compte_gestion",
    DROIT_IJ_GESTION = "indemnite_journaliere",
    DROIT_MOTIF_GESTION = "motif_gestion",
    DROIT_DEVISE_TAUX_CHANGE = "devise_taux_change",
    DROIT_CONFIG_MOBILE = "config_mobile",
    DROIT_IMPORT_FACTURE = "import_facture",
    DROIT_RELANCE_GESTION = "relance",
    DROIT_REPORTING = "reporting",
    DROIT_PROFIL_VOYAGEUR_GESTION = "profil_voyageur_gestion",
    DROIT_PV_CONTACT_URGENCE = "pv_contact_urgence",
    DROIT_PV_GESTION_DOCUMENT = "pv_gestion_document",
    DROIT_PV_CARTES_ABONNEMENT = "pv_cartes_abonnement",
    DROIT_ENTREPRISE_FOURNISSEUR = "entreprise_fournisseur",
    DROIT_MOBILE_GESTION = "mobile_gestion",
    DROIT_RGPD_GESTION = "rgpd_gestion",
    DROIT_USER_INFOS_BANCAIRES = "user_infos_bancaires",
    DROIT_VEHICULE_GESTION = "vehicule_gestion",
    DROIT_ORGANIGRAMME = "organigramme",
    DROIT_LICENCE = "licence",
    DROIT_COMPTA_EXERCICE = "exercices_periodes",
    DROIT_REF_GEOGRAPHIE = "ref_geo",
    DROIT_INTERNATIONALISATION = "traductions",
    DROIT_PRESTATION_NDF = "ref_ndf",
    DROIT_GESTION_TAXE = "taxes",
    DROIT_TRAVEL_REFERENTIEL = "ref_pv",
    DROIT_GESTION_FRAIS_AGENCE = "frais_agence",
    DROIT_GESTION_TOLERANCE = "tolerance",
    DROIT_CONTROLE_WORKFLOW = "controle_workflow",
    DROIT_INTERFACE_COMPTE_API = "comptes_api",
    DROIT_INTERFACE_REF = "ref_interfaces",
    DROIT_FRAIS_PRESTATION = "ref_prestations",
    DROIT_SUIVI_FLUX_EXPORT = "suivi_exports",
    DROIT_DEMATERIALISATION = "demat",
    DROIT_GESTION_EXTERNE = "externes",
    DROIT_MODE_REMBOURSEMENT = "modes_remboursement",
    DROIT_COMPTES_VERROUILLES = "comptes_verrouilles",
    DROIT_VISUALISATION_AVANCES = "visu_objet_wf_avances",
    DROIT_VISUALISATION_RELEVES_FACTURES = "visu_objet_wf_releves_factures",
    DROIT_VISUALISATION_NDF = "visu_objet_wf_ndf",
    DROIT_VISUALISATION_MISSIONS = "visu_objet_wf_missions"
}
