import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {UserDto} from "@domain/user/user-dto";
import {TypeProfil} from "@domain/user/user";
import {Router} from "@angular/router";

/**
 * Item de la liste des utilisateurs internes
 */
@Component({
	host: {'data-test-id': 'utilisateurs-internes-list-item'},
	selector: 'utilisateurs-internes-list-item',
	templateUrl: './utilisateurs-internes-list-item.component.html'
})
export class UtilisateursInternesListItemComponent extends ListViewItem<UserDto> {
	/**
	 * Constructeur
	 *
	 * @param router le routeur Angular
	 */
	constructor(
		private router: Router
	) {
		super();
	}

	/**
	 * Fonctions de transformation des fonctions de l'utilisateur en un libellé
	 */
	getProfils(): string {
		let profils: string[] = [];

		for (const f of this.data.fonctions.split(' ')) {
			if (f === TypeProfil.COMPTABLE.valueOf().toString()) {
				profils.push("CPT");
			} else if (f === TypeProfil.RESPONSABLE.valueOf().toString()) {
				profils.push("RSP");
			} else if (f === TypeProfil.COLLABORATEUR.valueOf().toString()) {
				profils.push("COL");
			} else if (f === TypeProfil.ASSISTANT.valueOf().toString()) {
				profils.push("AST");
			} else if (f === TypeProfil.SOUS_ADMINISTRATEUR.valueOf().toString()) {
				profils.push("SSA");
			} else if (f === TypeProfil.FOURNISSEUR.valueOf().toString()) {
				profils.push("FRN");
			}
		}

		return profils.join(' ');
	}

	/**
	 * Fonction appelée au clic sur un utilisateur
	 */
	navigateTo(): void {
		//Navigation vers l'utilisateur
		this.router.navigate(['Admin/Entreprise/Utilisateurs/User',this.data.idUser]);
	}
}