import {AfterViewInit, Component, ElementRef, EventEmitter, Injectable, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import {DateRange, MAT_DATE_RANGE_SELECTION_STRATEGY, MatDateRangePicker, MatDateRangeSelectionStrategy,} from '@angular/material/datepicker';
import * as moment from "moment";
import {Subscription} from "rxjs";

/**
 * Stratégie de gestion du range de sélection de la date.<br>
 * Celui ci est customisé pour pouvoir utiliser un range date picker sans avoir à gérer la date de fin
 */
@Injectable()
export class ZeroDayRangeSelectionStrategy<D> implements MatDateRangeSelectionStrategy<D> {
    constructor(private _dateAdapter: DateAdapter<D>) {
    }

    /**
     * Fin de sélection d'une date
     *
     * @param date Date sélectionnée
     */
    selectionFinished(date: D | null): DateRange<D> {
        return this._createZeroDayRange(date);
    }

    /**
     * Création de la prévisualisation
     *
     * @param activeDate Date active
     */
    createPreview(activeDate: D | null): DateRange<D> {
        return this._createZeroDayRange(activeDate);
    }

    /**
     * Gestion du date range avec un écart de 0 entre les dates
     *
     * @param date date de début
     */
    private _createZeroDayRange(date: D | null): DateRange<D> {
        if (date) {
            return new DateRange<D>(date, date);
        }

        return new DateRange<D>(null, null);
    }
}

/**
 * Composant custom pour afficher un range date picker<br>
 * ça permet de mettre en avant une période dans le calendrier
 *
 * @author Laurent SCIMIA
 * @date 30/03/2022
 */
@Component({
    host: {'data-test-id': 'date-range-custom'},
    selector: 'date-range-custom',
    templateUrl: './date-range-custom.component.html',
    styleUrls: ['./date-range-custom.component.scss'],
    providers: [
        {
            provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
            useClass: ZeroDayRangeSelectionStrategy,
        }
    ],
})
export class DateRangeCustomComponent implements OnInit, AfterViewInit, OnDestroy {

    /** Gestion de la date en interne au composant */
    private innerDate: moment.Moment;

    /** Date en input/output */
    @Input()
    get maDate(): moment.Moment {
        return this.innerDate;
    }
    /** Mise à jour de la date en input */
    @Output() maDateChange: EventEmitter<moment.Moment> = new EventEmitter<moment.Moment>();

    /** surcharge du setter de la date pour gérer l'output */
    set maDate(val: moment.Moment) {
        this.innerDate = val;
        this.maDateChange.emit(val);
    }

    /** Date de début du range de comparaison pour le rangeDate */
    @Input() rangeDeb: Date | moment.Moment;

    /** Date de fin du range de comparaison pour le rangeDate */
    @Input() rangeEnd: Date | moment.Moment;

    /** Date d'ouverture du datepicker (aujourd'hui par défaut) */
    @Input() dateStart?: Date | moment.Moment = moment();

    /** True si la valeur est obligatoire */
    @Input() required?: boolean = false;

    /** Date minimale pour la sélection */
    @Input() minDate?: moment.Moment

    /** Date maximale pour la sélection */
    @Input() maxDate?: moment.Moment

    /** true si le datepicker doit être désactivé (false par défaut) */
    @Input() disabled?: boolean = false;

    /** Évènement émis lors de la modification du modèle associé */
    @Output() dateChange: EventEmitter<moment.Moment> = new EventEmitter<moment.Moment>();

    /** Souscription pour résilier à la destruction du composant */
    private souscription: Subscription;

    /** Référence du datepicker */
    @ViewChild(MatDateRangePicker) picker: MatDateRangePicker<any>;

    /** Label du date picker */
    @Input() label: string;

    /**
     * Constructeur
     *
     * @param el Référence au DOM pour manipulation de ce dernier
     */
    constructor(private el: ElementRef) {
    }

    /** Initialisation du composant */
    ngOnInit() {
        //On s'assure que les dates du range soient du type moment (pck le datepicker est paramétré pour gérer du moment)
        this.rangeDeb = moment(this.rangeDeb);
        this.rangeEnd = moment(this.rangeEnd);

        //On récupère l'élément DOM contenant l'input de la date de fin
        let endWrapper = this.el.nativeElement.querySelectorAll('div.mat-date-range-input-end-wrapper');

        //On récupère l'élément DOM contenant la séparation entre la date de début et la date de fin
        let dateSep = this.el.nativeElement.querySelectorAll('span.mat-date-range-input-separator');

        //On supprime les éléments DOM non voulus
        for (let i = 0; i < dateSep.length; i++) {
            dateSep[i].remove();
            endWrapper[i].remove();
        }
    }

    /** Après la mise à jour de la vue */
    ngAfterViewInit() {
        //On s'assure qu'a chaque ouverture du picker, on se positionne sur la date sélectionnée s'il y en a une
        this.souscription = this.picker.openedStream.subscribe(() => {
            if (this.maDate) {
                this.picker.startAt = this.maDate;
            }
        });
    }

    /** Destruction du composant */
    ngOnDestroy() {
        //On dé-souscrit à un abonnement
        this.souscription?.unsubscribe();
    }
}
