import {Component,Input,OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {LotArchivage} from "@domain/demat/lot-archivage";
import {HistoriqueLotListItemComponent} from "@components/admin/parametre/demat/archivage/lot-detail/detail-archivage/historique-list/historique-list-item/historique-lot-list-item.component";
import {NdfDemat} from "@domain/demat/ndf-demat";
import {NdfDematListItemComponent} from "@components/admin/parametre/demat/archivage/lot-detail/detail-archivage/ndf-list-item/ndf-demat-list-item.component";
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {ToastrService} from "ngx-toastr";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {DematService} from "@services/admin/parametre/demat/demat.service";
import {filter,finalize,first} from "rxjs/operators";
import {TypeCodeErreur} from "@domain/common/http/result";

/**
 * Composant de l'historique d'archivage d'un lot comptable.
 *
 * @author François Turin
 * @date 28/09/2023
 */
@Component({
	host: {'data-test-id': 'historique-archivage'},
	templateUrl: './historique-archivage.component.html',
	selector: 'historique-archivage'
})
export class HistoriqueArchivageComponent implements OnInit {
	/** Id du lot */
	@Input()
	idLot: number;

	/** Liste des archivages d'un lot */
	listeSynchro: ListView<LotArchivage,HistoriqueLotListItemComponent>;

	/** Liste des ndf d'un lot */
	listeNdf: ListView<NdfDemat,NdfDematListItemComponent>;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	constructor(private translateService: TranslateService,
				private toastrService: ToastrService,
				private confirmService: ConfirmService,
				private pleaseWaitService: PleaseWaitService,
				private dematService: DematService) {
	}

	/**
	 * Initialisation du composant.
	 */
	ngOnInit() {
		let lot: LotArchivage;
		let isSynchroPossible: boolean = false;

		//Recherche du lot affiché dans la liste des lots à partir de son ID
		lot = this.dematService.listeLots.data?.listeResultats.find(lot => lot.idLot == this.idLot);

		if (lot) {
			//On détermine si le lot peut être archivé
			isSynchroPossible = this.dematService.isArchivageAutorise(lot);
		}

		//Définition de la liste des actions
		this.listeActions.next([{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-archive',
			libelle: 'admin.parametres.demat.archivage.liste.actions.synchroniser',
			tooltip: () => 'admin.parametres.demat.archivage.liste.actions.' + (this.dematService.isDematPresent ? 'tooltipSynchroniser' : 'tooltipNoDemat'),
			doAction: () => this.archiverLot(),
			isVisible: () => true,
			isDisabled: () => !(this.dematService.isDematPresent && isSynchroPossible)
		}]);

		//Définition de la liste des archivages du lot
		this.listeSynchro = new ListView<LotArchivage,HistoriqueLotListItemComponent>({
			uri: `/controller/DematHubConfig/detailLot/${this.idLot}/historique`,
			title: this.translateService.instant('admin.parametres.demat.archivage.detail.listeHistorique.title'),
			component: HistoriqueLotListItemComponent,
			isFilter: false,
			isSimple: true
		});

		//Définition de la liste des notes de frais du lot
		this.listeNdf = new ListView<NdfDemat,NdfDematListItemComponent>({
			uri: `/controller/DematHubConfig/detailLot/${this.idLot}/listeNdf`,
			title: this.translateService.instant('admin.parametres.demat.archivage.detail.listeNdf.title'),
			component: NdfDematListItemComponent,
			isFilter: true,
			defaultOrder: "numero",
			listeFilters: [
				{
					clef: 'numero',
					title: this.translateService.instant('admin.parametres.demat.archivage.liste.numero'),
					type: TypeFilter[TypeFilter.STRING],
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					clef: 'proprietaire',
					title: this.translateService.instant('admin.parametres.demat.archivage.liste.proprietaire'),
					type: TypeFilter[TypeFilter.STRING],
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					clef: 'uidArchive',
					title: this.translateService.instant('admin.parametres.demat.archivage.liste.uidDocumentArchive'),
					type: TypeFilter[TypeFilter.STRING],
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				},{
					clef: 'nbPJ',
					title: this.translateService.instant('admin.parametres.demat.archivage.liste.nbPJTotal'),
					type: TypeFilter[TypeFilter.LONG],
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					isDefault: false
				},{
					clef: 'nbPJIgnorees',
					title: this.translateService.instant('admin.parametres.demat.archivage.liste.nbPJIgnorees'),
					type: TypeFilter[TypeFilter.LONG],
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					isDefault: false
				}
			]
		});
	}

	/**
	 * Déclenche la synchronisation du lot.
	 */
	archiverLot(): void {
		//Ouverture de la popup de confirmation
		this.confirmService.showConfirm(this.translateService.instant('admin.parametres.demat.archivage.confirmerSynchroLot')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
			next: () => {
				let waitDialogRef = this.pleaseWaitService.show();

				//On envoie la demande de synchronisation
				this.dematService.archiverLot(this.idLot)
					.pipe(first(),finalize(() => {
						waitDialogRef.close();
					}))
					.subscribe(result => {
						//Refresh des listes
						this.refreshListes();

						//S'il n'y a pas d'erreur
						if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
							//Message d'information
							this.toastrService.success(this.translateService.instant('admin.parametres.demat.archivage.synchroDermarree'));
						} else {

							//Message d'erreur
							this.toastrService.error(this.translateService.instant('admin.parametres.demat.archivage.synchroErreur'));
						}
					})
			}
		});
	}

	/**
	 * Rechargement des listes après déclenchement de la synchro.
	 */
	private refreshListes() {
		this.listeSynchro.refresh();
		this.listeNdf.refresh();
	}
}
