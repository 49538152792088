<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'warning': data.listeAlertes?.niveau == 1,'danger': data.listeAlertes?.niveau == 2 }"
         [nSelector]="{liste: liste, item: data, isNotSelectable: fonction === TypeProfil.ADMINISTRATEUR}">
        <i class="nio icon-ordre_de_mission" style="vertical-align: middle;"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="navigateTo()">
                {{ data.idOd || ('od.liste.numeroNonDefini' | translate) }} - {{ data.objet || ('od.liste.objetNonDefini' | translate) }}
            </a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.idOmPermanent" (click)="openOmp(data.idOmPermanent)" style="cursor:pointer;">
                <strong [translate]="'od.liste.omp'"></strong>
                {{ data.idOmPermanent }}
                <mat-icon color="primary" style="font-size:1.1em;">open_in_new</mat-icon>
            </li>
            <li *ngIf="!isCollabMode"><strong [translate]="'od.liste.collaborateur'"></strong>{{ data.getCollaborateur() }} - {{ data.user?.matricule }}</li>
            <li><strong [translate]="'od.liste.type'"></strong>{{ data.typeEntite }}</li>
            <li *ngIf="data.depart_le"><strong [translate]="'od.liste.depart_le'"></strong>{{ data.depart_le | date:'shortDate' }}</li>
            <li *ngIf="data.retour_le"><strong [translate]="'od.liste.retour_le'"></strong>{{ data.retour_le | date:'shortDate' }}</li>
            <li *ngIf="data.location" [matTooltip]="data.location + ', ' + data.pays.libelle.toUpperCase()">
                <strong [translate]="'od.liste.libelleVille'"></strong>
                <span *ngIf="data.pays?.code2" class="flag-icon mr-1" [ngClass]="'flag-icon-'+data.pays.code2.toLowerCase()"></span>
                {{ data.location }}
            </li>
            <li *ngIf="data.dateLimiteApprobation"><strong [translate]="'od.liste.limiteApprobation'"></strong>{{ data.dateLimiteApprobation | date:'shortDate' }}</li>
            <li *ngIf="!isCollabMode && data.getRolesCount() > 1" [matTooltip]="data.getRoles()"><strong [translate]="'facture.liste.roles'"></strong>{{ data.getRolesCount() }}</li>
            <li *ngIf="!isCollabMode && data.getRolesCount() == 1"><strong [translate]="'facture.liste.role'"></strong>{{ data.getRoles() }}</li>
            <li *ngIf="data.listeAlertes" (click)="showListeAlertes()" class="clickable"
                [ngClass]="{'back-danger' : data.listeAlertes.niveau == 2, 'back-warning' : data.listeAlertes.niveau == 1}">
                <strong [translate]="'workflow.alerte.title'"></strong><span>{{ ('workflow.alerte.niveau.' + data.listeAlertes.niveau | translate) }}</span>
            </li>
        </ul>
    </div>
    <div class="info">
        <div>
            <statut [statut]="data.statut"></statut>
            <div *ngIf="settings?.deviseEntreprise" class="text-right text-secondary font-weight-bold">
                {{ data.montantProposition | montant:settings.deviseEntreprise }}
            </div>
        </div>
    </div>
</div>
