<div [class.with-floating-button]="!fromAdmin">
    <div class="content" *ngIf="vehicule">
        <page-header [title]="'vehicule.title' | translate:{immatriculation: vehicule.immatriculation}"
                     [extraInfo]="getStatusLibelle(vehicule.statut)" [extraInfoTooltip]="motifRejet"
                     [listeItems]="listeTabItems"
                     [niveauAlerte]="listeAlertes?.niveau"
                     (onSelectedItemChange)="onSelectedItemChange($event)"
                     (onGoBack)="onGoBack()"
                     [isPending]="isSaving">
            <preview-alerte #alerte *ngFor="let alerte of listeAlertes?.listeAlertes" [alerte]="alerte"></preview-alerte>
        </page-header>
        <div *ngIf="selectedItem?.code == 'GENERALITES'">
            <mat-card>
                <mat-card-title>
                    <span class="left" [translate]="'vehicule.formulaire.label'"></span>
                </mat-card-title>
                <mat-card-content>
                    <form autocomplete="off" name="form_vehicule">
                        <fieldset>
                            <div class="row align-items-center">
                                <custom-input #immatComponent
                                              [(ngModel)]="vehicule.immatriculation"
                                              (onChange)="vehicule.immatriculationChanged = true"
                                              (input)="formaterImmatriculation()"
                                              customType="input-obfuscated"
                                              libelle="vehicule.liste.immatriculation"
                                              maxlength="20"
                                              [postTooltip]="canModifier ? ('vehicule.formulaire.immatTooltip' | translate) : undefined"
                                              [canReadClear]="canReadClear"
                                              (onReadClearRequest)="getPlaqueImmatriculationVehiculeNonObfusquee()"
                                              name="immatriculation"
                                              ngDefaultControl
                                              [required]="true"
                                              [disabled]="!canModifier">
                                </custom-input>
                                <label class="col-md-2 text-right"><span translate>vehicule.formulaire.actif</span></label>
                                <div class="col-md-4">
                                    <mat-checkbox [(ngModel)]="vehicule.actif" [disabled]="!fromProfil" color="primary"
                                                  name="actif">{{'vehicule.formulaire.actifDescriptif' | translate}}</mat-checkbox>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <label class="col-md-2 text-right required"><span [translate]="'vehicule.formulaire.marque'"></span></label>
                                <div class="col-md-4">
                                    <mat-form-field class="d-flex">
                                        <input [(ngModel)]="vehicule.marque" matInput name="marque" required [disabled]="!canModifier" />
                                    </mat-form-field>
                                </div>
                                <label class="col-md-2 text-right required"><span [translate]="'vehicule.liste.puissanceFiscale'"></span></label>
                                <div class="col-md-4">
                                    <mat-form-field class="d-flex">
                                        <mat-select [(ngModel)]="vehicule.puissanceFiscale" [compareWith]="comparePuissanceFiscale" [disabled]="!canModifier" name="compteBancaire" required>
                                            <mat-option *ngFor="let puissanceFiscale of listePuissance" [value]="puissanceFiscale">{{ puissanceFiscale.libelle }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                            </div>
                            <div class="row align-items-center">
                                <label class="col-md-2 text-right required"><span [translate]="'vehicule.formulaire.modele'"></span></label>
                                <div class="col-md-4">
                                    <mat-form-field class="d-flex">
                                        <input [(ngModel)]="vehicule.modele" matInput name="modele" required [disabled]="!canModifier" />
                                    </mat-form-field>
                                </div>
                                <label class="col-md-2 text-right"><span [translate]="'vehicule.formulaire.historiqueDistance'"></span></label>
                                <div class="col-md-4" (click)="openHistoriqueDistance()">
                                    <i class="nio icon-loupe"></i>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <label class="col-md-2 text-right"><span [translate]="'vehicule.formulaire.assurance'"></span></label>
                                <div class="col-md-4">
                                    <mat-checkbox [(ngModel)]="vehicule.assurePro" [disabled]="!canModifier" color="primary" name="assure">
                                        {{'vehicule.formulaire.assuranceDescriptif' | translate}}
                                    </mat-checkbox>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </mat-card-content>
            </mat-card>
            <vehicule-documents #vehiculeDocuments (onDocumentUploaded)="onDocumentUploaded($event)" *ngIf="vehicule.idPa" [canModifier]="canModifier" [settings]="this.settings" [vehicule]="vehicule" [isResponsableVehicule]="isResponsableVehicule" [canGestionDocuments]="canGestionDocuments"></vehicule-documents>
        </div>
        <div *ngIf="selectedItem?.code == 'COMPLEMENTS'">
            <document-list *ngIf="vehicule.idPa"
                           [context]="'PROFIL_VEHICULE'" [idObjetForPJ]="vehicule.idCollab"
                           [idObjet]="vehicule.idPa" [settings]="settings" [canAddDocument]="false" [canDeleteDocument]="false"
                           [uri]="'/controller/Document/searchVehiculeDocuments?id_vehicule='">
            </document-list>
        </div>
    </div>
    <please-wait class="content" *ngIf="!vehicule"></please-wait>
    <floating-button [listeActions]="listeActions" [isPending]="isSaving"></floating-button>
</div>
