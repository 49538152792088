<h1 mat-dialog-title>
    <span translate>ndf.frais.title</span>
    <span [mat-dialog-close]="dialogCloseResult"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <please-wait [hidden]="!isLoading"></please-wait>
    <form autocomplete="off" [hidden]="isLoading" id="fraisAddForm" #fraisAddForm="ngForm">
        <mat-tab-group #matTabGroup dynamicHeight="dynamicHeight" animationDuration="500ms" (selectedIndexChange)="tabChanged($event)">
            <mat-tab label="{{ 'ndf.frais.tabs.saisieFrais' | translate }}">
                <fieldset [disabled]="!data.canModifier">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row" [ngClass]="{ 'has-error': date.invalid }">
                                <label class="col-md-4 required">
                                    <span translate>ndf.frais.date</span>
                                </label>
                                <div class="col-md-8">
                                    <date-range-custom
                                        #date="ngModel"
                                        [ngModel]="data.frais.date"
                                        [(maDate)]="data.frais.date"
                                        name="date" ngDefaultControl
                                        (maDateChange)="onDateChange();"
                                        [disabled]="!data.canModifier || isIJ"
                                        [rangeDeb]="data.ndf.od?.depart_le"
                                        [rangeEnd]="data.ndf.od?.retour_le"
                                        [required]="true"
                                        notNullValidator>
                                    </date-range-custom>
                                </div>
                            </div>
                            <div class="row" [ngClass]="{ 'has-error': !isGeographieValid() }">
                                <label class="col-md-4 required">
                                    <span translate>ndf.frais.villePays</span>
                                </label>
                                <div class="col-md-8">
                                    <autocomplete type="geographie" name="geographie" #geographie="ngModel" ngDefaultControl floatLabel="never"
                                                  [(ngModel)]="data.frais.geographie"
                                                  [filter]="{ listeTypes: listeTypeGeo,contexte: 'NDF' }"
                                                  [label]="'ndf.frais.villePays' | translate"
                                                  [disabled]="!data.canModifier || !typeEntite?.typeEntiteParam?.destinationModifiableNF || isIJ"
                                                  [required]="true"
                                                  (onSelect)="onGeographieChange($event)"></autocomplete>
                                </div>
                            </div>
                            <div class="row" [ngClass]="{ 'has-error': prestation.invalid || hasFrequenceAutoriseeError}">
                                <label class="col-md-4 required">
                                    <span translate>ndf.frais.prestation</span>
                                </label>
                                <div class="col-md-8">
                                    <autocomplete type="prestationFrais" name="prestation" ngDefaultControl floatLabel="never"
                                                  #prestation="ngModel" #prestationTooltip="nioTooltip"
                                                  [(ngModel)]="data.frais.prestation"
                                                  [filter]="{ idPays: data.frais.pays?.idPays || data.frais?.geographie?.pays?.idPays,idTypeEntite: data.ndf.typeEntite.idTypeEntite,typeObjet: TypePortee.NF,idObjet: data.ndf.idNDF }"
                                                  [label]="'ndf.frais.prestation' | translate"
                                                  [disabled]="!data.canModifier || isIJ"
                                                  [required]="true"
                                                  (onSelect)="onPrestationChange($event)"
                                                  [nioTooltip]="TooltipPrestationComponent"
                                                  [nioTooltipContext]="{ prestation: data.frais.prestation }"
                                                  [nioTooltipDisabled]="!(data.frais.prestation?.id > 0)"
                                                  [nioTooltipPosition]="'bottom'"
                                                  [nioTooltipOffset]="-10"
                                                  [nioTooltipWidth]="'host'"
                                    ></autocomplete>
                                    <mat-error *ngIf="hasFrequenceAutoriseeError" class="error">
                                        <span translate>ndf.frais.erreur.errorQuantite</span>
                                    </mat-error>
                                    <mat-error *ngIf="hasNotAutorisationCirculer()" [ngClass]="{ 'error': data.settings.niveauControleAutorisationVehiculeNdf === niveauAutorisation.BLOQUANT, 'warning': data.settings.niveauControleAutorisationVehiculeNdf === niveauAutorisation.NON_BLOQUANT }">
                                        <span [translate]="'ndf.frais.erreur.autorisationCirculer'" [translateParams]="{ date: data.frais.date | date:'shortDate' }"></span>
                                    </mat-error>
                                </div>
                            </div>
                            <!-- Sélection d'une indemnité si la NDF est liée à un OD -->
                            <div class="row" *ngIf="(!!data.ndf.od && !!data.frais.prestation && saisieState?.tauxPrestation?.allTaux?.length > 0) || (isIJ && saisieState?.tauxPrestation?.taux)">
                                <label class="col-md-4">
                                    <span translate>ndf.frais.indemnite</span>
                                </label>
                                <div class="col-md-8">
                                    <div class="d-flex">
                                        <mat-form-field [floatLabel]="'never'">
                                            <mat-label>{{ 'od.frais.frais.creation.fraisPrev.aucuneIndemnite' | translate }}</mat-label>
                                            <mat-select #selectTaux *ngIf="!isIJ" name="taux" [(ngModel)]="data.frais.taux" [compareWith]="fraisService.compareTaux" [disabled]="!data.canModifier"
                                                        [nioTooltip]="tooltipTaux" [nioTooltipContext]="{ taux: findTaux(data.frais.taux?.idTaux) }" [nioTooltipDisabled]="!data.frais.taux" nioTooltipPosition="right">
                                                <mat-option *ngIf="!saisieState?.tauxPrestation?.allTaux?.length" [value]="null">{{ 'od.frais.frais.creation.fraisPrev.aucuneIndemnite' | translate }}</mat-option>
                                                <mat-option [value]="fraisService.prestTauxToFraisMission(taux)" *ngFor="let taux of saisieState?.tauxPrestation?.allTaux"
                                                            [nioTooltip]="tooltipTaux" [nioTooltipContext]="{ taux: taux }" nioTooltipPosition="right"
                                                            [nioTooltipDisabled]="!selectTaux.panelOpen">
                                                    {{ taux.libelleSpecial != null &&  taux.libelleSpecial != '' ?  taux.libelleSpecial : taux.libelleTerritoire }}
                                                </mat-option>
                                            </mat-select>
                                            <input *ngIf="isIJ" matInput name="tauxIJ" [value]="indemniteIJ?.libelleTerritoire + (indemniteIJ?.libelleSpecial ? ' (' + indemniteIJ?.libelleSpecial + ')' : '')" [disabled]="true">
                                            <mat-icon matSuffix *ngIf="isIJ" [nioTooltip]="tooltipTaux" [nioTooltipContext]="{ taux: indemniteIJ }" nioTooltipPosition="track" style="cursor: help;">info_outline</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="data.frais.prestation && data.frais.prestation.type !== undefined">
                                <div class="offset-md-4 col-md-8">
                                    <span *ngIf="data.frais.prestation.typeBareme !== TypeBareme.BAREME_SNCF && (data.frais.prestation.type == TypePrestation.REEL || data.frais.prestation.idPrestation == saisieState?.tauxPrestation?.idPrestation)"
                                        [innerHtml]="'ndf.prestation.typeRemboursement.' + TypePrestation[data.frais.prestation.type]
                                            | translate:{ montant: (getTauxForPrestation() | montant:this.data.frais.operation?.devise?.code), unite: data.frais.prestation.unite?.libelle }">
                                    </span>
                                    <span *ngIf="data.frais.prestation.typeBareme === TypeBareme.BAREME_SNCF && (data.frais.prestation.type == TypePrestation.REEL || data.frais.prestation.idPrestation == saisieState?.tauxPrestation?.idPrestation)"
                                          translate>
                                        ndf.frais.baremeSNCF
                                    </span>
                                    <mat-icon *ngIf="cumulRestant != ''" [nioTooltip]="'ndf.prestation.plafondCumule' | translate: { montant: cumulRestant }">error_outline</mat-icon>
                                </div>
                            </div>
                            <div *ngIf="(data.frais.prestation?.geolocalisation && !canShowTournee())
                                            || (canShowTournee() && data.frais.tournee == null)">
                                <div class="row" [ngClass]="{ 'has-error': depart.invalid }">
                                    <label class="col-md-4 required">
                                        <span translate>ndf.frais.depart</span>
                                    </label>
                                    <div class="col-md-8">
                                        <adresse
                                                name="depart" #depart="ngModel" [(ngModel)]="data.frais.localisationDepart" (onSelect)="onDepartArriveeChange($event)"
                                                required [disabled]="!data.canModifier || isIJ" [isSelectedValueRequired]="true"
                                        ></adresse>
                                    </div>
                                </div>
                                <div class="row" [ngClass]="{ 'has-error': arrivee.invalid }">
                                    <label class="col-md-4 required">
                                        <span translate>ndf.frais.arrivee</span>
                                    </label>
                                    <div class="col-md-8">
                                        <adresse
                                                name="arrivee" #arrivee="ngModel" [(ngModel)]="data.frais.localisationArrivee" (onSelect)="onDepartArriveeChange($event)"
                                                required [disabled]="!data.canModifier || isIJ" [isSelectedValueRequired]="true"
                                        ></adresse>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4 offset-md-4">
                                        <span class="d-flex">
                                            <mat-checkbox name="isAllerRetour" color="primary" [(ngModel)]="data.frais.allerRetour" (change)="onAllerRetourChange($event.checked)"
                                                          [disabled]="!data.canModifier || isIJ">
                                                <span translate>ndf.frais.allerRetour</span>
                                                </mat-checkbox>
                                        </span>
                                    </div>
                                    <div class="col-md-4">
                                        <span *ngIf="canShowRetenirTrajet && isShowTrajets() && data.frais.prestation.saveTrajet" class="d-flex">
                                            <mat-checkbox name="isRetenirTrajet" color="primary" [(ngModel)]="isRetenirTrajet">
                                                <span translate>ndf.frais.retenirTrajet</span>
                                            </mat-checkbox>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!--Affiche de l'input tournée-->
                            <div *ngIf="canShowTournee() && data.frais.tournee != null">
                                <div class="row mt-3">
                                    <label class="col-md-4 required">
                                        <span translate>ndf.frais.tournee</span>
                                    </label>
                                    <div class="col-md-8 clickable" (click)="openPopinTournee()">
                                        <div class="d-flex flex-row">
                                            <span>{{data.frais.tournee?.listeEtapes?.length}}</span>&nbsp;
                                            <span translate>ndf.frais.etapeRenseigne</span>&nbsp;
                                            <mat-icon class="mt-0 c-primary">search_outline</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="data.frais.prestation?.genre == Genre.RECEPTION">
                                <div class="row" *ngIf="data.frais.prestation?.participation" [ngClass]="{ 'has-error': !isInvitesValid() }">
                                    <label class="col-md-4">
                                        <span translate>ndf.frais.participation</span>
                                    </label>
                                    <div class="col-md-4">
                                        <span class="d-flex">
                                            <mat-checkbox name="isCollaborateurAbsent" color="primary" [(ngModel)]="data.frais.collaborateurAbsent" [disabled]="!data.canModifier || isIJ"><span translate="ndf.frais.participationInformation"></span></mat-checkbox>
                                        </span>
                                    </div>
                                </div>
                                <div class="row" [ngClass]="{ 'has-error': !isInvitesValid() }">
                                    <label class="col-md-4">
                                        <span translate>ndf.frais.invites</span>
                                    </label>
                                    <div class="col-md-8">
                                        <span class="r-flex align-items-center">
                                            <span class="mr-1 pb-2" *ngIf="data.frais.prestation?.modeParticipant != ModeParticipants.EXTERNES">{{ 'ndf.frais.invite.nbInternes.'+(countInvitesForType(TypeParticipant.INTERNE) > 1 ? 'plural' : 'one') | translate:{ count: countInvitesForType(TypeParticipant.INTERNE) } }}</span>
                                            <div class="r-flex align-items-center" *ngIf="data.settings.isReferentielParticipantsExternes;else noRefExterne">
                                                <span class="pb-2" *ngIf="data.frais.prestation?.modeParticipant != ModeParticipants.INTERNES">{{ 'ndf.frais.invite.nbExternes.'+(countInvitesForType(TypeParticipant.EXTERNE) > 1 ? 'plural' : 'one') | translate:{ count: countInvitesForType(TypeParticipant.EXTERNE) } }}</span>
                                                <mat-icon class="clickable align-middle ml-1 pb-2" style="font-size: 1.5em; height: auto !important;;" (click)="showListeParticipants()">open_in_new</mat-icon>
                                            </div>
                                            <ng-template #noRefExterne>
                                                <span class="pb-2"><mat-icon class="clickable align-middle" style="font-size: 1.5em; height: auto !important;" (click)="showListeParticipants()">open_in_new</mat-icon></span>
                                                <div class="r-flex pl-4">
                                                    <mat-form-field class="d-flex" style="width: 100px;">
                                                        <input name="nbExternes" matInput #nbExternes="ngModel" [(ngModel)]="data.frais.nbInvites" nNumber [min]="0" [disabled]="!data.canModifier || isIJ" />
                                                        <span class="ml-2" matSuffix>{{ 'ndf.frais.invite.externes.'+(data.frais.nbInvites > 1 ? 'plural' : 'one') | translate }}</span>
                                                    </mat-form-field>
                                                </div>
                                            </ng-template>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="prestation?.model?.libelleField1" class="row" [ngClass]="{ 'has-error': field1.invalid }">
                                <label class="col-md-4" [ngClass]="{ 'required': prestation.model?.field1Obligatoire }">
                                    <span>{{ prestation?.model?.libelleField1 }}</span>
                                </label>
                                <div class="col-md-8">
                                    <mat-form-field class="d-flex">
                                        <input name="field1" matInput #field1="ngModel" [(ngModel)]="data.frais.field1" [required]="prestation.model?.field1Obligatoire" />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div *ngIf="prestation?.model?.libelleField2" class="row" [ngClass]="{ 'has-error': field2.invalid }">
                                <label class="col-md-4" [ngClass]="{ 'required': prestation.model?.field2Obligatoire }">
                                    <span>{{ prestation?.model?.libelleField2 }}</span>
                                </label>
                                <div class="col-md-8">
                                    <mat-form-field class="d-flex">
                                        <input name="field2" matInput #field2="ngModel" [(ngModel)]="data.frais.field2" [required]="prestation.model?.field2Obligatoire" />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row" [ngClass]="{ 'has-error': data.frais.prestation?.justificatifObligatoire && !data.frais.justificatif?.numJustificatif }">
                                <label class="col-md-4">
                                    <span translate>ndf.frais.justificatif</span>
                                </label>
                                <div class="col-md-1">
                                    <div class="mt-3">
                                        <mat-checkbox 
                                            name="isJustificatif" color="primary" [(ngModel)]="data.frais.isJustificatif" 
                                            (change)="onJustificatifChange($event.checked)" 
                                            [disabled]="data.frais.prestation?.type == TypePrestation.FORFAIT && !data.frais.prestation?.justificatifObligatoire && !data.frais.prestation?.justificatifDefaut"
                                            [matTooltip]="'ndf.frais.presenceJustificatif' | translate"
                                        ></mat-checkbox>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <mat-form-field class="d-flex">
                                        <span matPrefix translate="ndf.frais.numero"></span>
                                        <input name="justificatif" matInput #justificatif="ngModel" [(ngModel)]="data.frais.justificatif.numJustificatif" nNumber [disabled]="!data.frais.isJustificatif" readonly/>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-1">
                                    <a *ngIf="!isPJValid()" (click)="changeTab(matTabGroup,NdfFraisAddTab.pieceJointe);">
                                        <mat-icon class="mt-3 error" [matTooltip]="'ndf.frais.pjObligatoire' | translate">error_outline</mat-icon>
                                    </a>
                                </div>
                            </div>
                            <div class="row" [ngClass]="{ 'has-error': enseigne.invalid }">
                                <label class="col-md-4" [ngClass]="{ 'required': prestation.model?.libelleJustificatifObligatoire }">
                                    <span>{{ prestation?.model?.libelleJustificatif || ('ndf.frais.enseigne' | translate) }}</span>
                                </label>
                                <div class="col-md-8">
                                    <mat-form-field class="d-flex">
                                        <input name="enseigne" matInput #enseigne="ngModel" [(ngModel)]="data.frais.justificatif.libelle" [disabled]="!data.frais.isJustificatif" [required]="prestation.model?.libelleJustificatifObligatoire" />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <custom-input lCol="4"
                                              ngDefaultControl
                                              name="quantite"
                                              customType="number"
                                              [nbDecimales]="2"
                                              libelle="ndf.frais.quantite"
                                              [suffix]="data.frais.prestation?.unite?.libelle"
                                              [(ngModel)]="data.frais.quantite"
                                              [required]="data.frais.prestation?.quantiteObligatoire"
                                              [readonly]="!isQuantiteModifiable()"
                                              (onChange)="onQuantiteChange()">
                                </custom-input>
                            </div>
                            <div class="row"
                                    [ngClass]="{ 'has-error': montant.invalid }"
                                    *ngIf="data.frais.prestation?.type != TypePrestation.FORFAIT || data.frais.prestation?.idAppTva > 0">
                                <label class="col-md-4 required">
                                    <span [translate]="'ndf.frais.'+(data.frais.saisieTaxe && data.frais.isJustificatif ? 'montantTTC' : 'montant')"></span>                                    
                                </label>
                                <div class="col-md-8">
                                    <div class="d-flex">
                                        <mat-form-field>
                                            <input name="montant" matInput #montant="ngModel" [(ngModel)]="data.frais.montant" nNumber="2" autocomplete="off" class="m-r-10" required min="0"/>
                                            <mat-icon *ngIf="data.frais.abattement != null &&  data.frais.abattement > 0" class="c-warning" [matTooltip]="'ndf.frais.abattement' | translate: {montant: data.frais.abattement}" inline="true">
                                                error_outline
                                            </mat-icon>
                                            <mat-select name="devise" [(ngModel)]="data.frais.operation.devise" [compareWith]="compareDevises" [disabled]="!data.canModifier || isIJ" (selectionChange)="onDeviseChange($event)">
                                                <mat-option *ngFor="let devise of data.settings.listeDevises" [value]="devise">{{ devise.code }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row p-3" *ngIf="data.frais.prestation?.type == TypePrestation.FORFAIT && !data.frais.prestation?.idAppTva">
                                <label class="col-md-4">
                                    <span translate>ndf.frais.montant</span>
                                </label>
                                <div class="col-md-4">
                                    <div class="d-flex">
                                        <mat-form-field appearance="none">
                                            <input matInput class="text-right" [value]="getMontantForfait() | number:'1.2-2'" [readOnly]="data.frais.prestation?.bareme && (!data.frais.idDepense || !data.frais.montant)" readonly />
                                            <mat-icon *ngIf="data.frais.abattement != null &&  data.frais.abattement > 0" class="c-warning w-25 pl-2" [matTooltip]="'ndf.frais.abattement' | translate: {montant: data.frais.abattement}" inline="true">
                                                error_outline
                                            </mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-3 p-l-0">
                                    <div class="d-flex">
                                        <mat-form-field *ngIf="!data.frais.prestation?.bareme">
                                            <mat-select name="devise" [(ngModel)]="data.frais.operation.devise" [compareWith]="compareDevises" [disabled]="!data.canModifier || isIJ" (selectionChange)="onDeviseChange($event)">
                                                <mat-option *ngFor="let devise of data.settings.listeDevises" [value]="devise">{{ devise.code }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field *ngIf="data.frais.prestation?.bareme" appearance="none">
                                            <input name="devise" matInput [(ngModel)]="data.frais.operation.devise.code" [disabled]="!data.frais.idDepense || !data.frais.montant" readonly>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-1 p-0" *ngIf="data.frais.prestation?.bareme">
                                    <mat-icon class="mt-3 c-primary" *ngIf="!data.frais.idDepense || !data.frais.montant" [matTooltip]="'ndf.frais.info.montantCalculePostEnregistrement' | translate">info_outline</mat-icon>
                                    <a (click)="viewDetailCalculIk()" *ngIf="data.frais.idDepense && data.frais.montant && data.frais.prestation.typeBareme === TypeBareme.BAREME_FISCAL" [matTooltip]="'ndf.frais.info.viewDetailCalculIk' | translate">
                                        <mat-icon class="mt-3 c-primary">search_outline</mat-icon>
                                    </a>
                                </div>
                            </div>
                            <div *ngIf="(data.frais.saisieTaxe != null && (!data.frais.saisieTaxe.productionJustificatif || ( data.frais.isJustificatif && data.frais.prestation)))">
                                <div class="row" *ngFor="let taux of data.frais.saisieTaxe.listeTaux; let idxTaux = index;" [ngClass]="{ 'has-error': !isTaxeValid() }">
                                    <label class="col-md-4">
                                        <span translate="ndf.frais.taux" [translateParams]="{ taux: taux.taux | number:'1.2-2' }"></span>
                                    </label>
                                    <div class="col-md-4">
                                        <div class="d-flex">
                                            <mat-form-field>
                                                <input name="taux_{{ idxTaux }}" matInput [(ngModel)]="taux.montantBase" nNumber="2" [readonly]="!data.frais.prestation?.saisieTaxe || !taux.montantBase"/>
                                                <button matSuffix mat-icon-button (click)="computeTaxeMontant(taux)">
                                                    <mat-icon *ngIf="taux.montantBase">close</mat-icon>
                                                    <mat-icon *ngIf="!taux.montantBase">edit</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Tournée création-->
                            <div class="row" *ngIf="canShowTournee() && data.frais.tournee == null">
                                <label class="col-md-4">
                                    <span translate>ndf.frais.tournee</span>
                                </label>
                                <div class="col-md-7 clickable d-flex flex-row pr-0" (click)="openPopinTournee()">
                                    <span translate>ndf.frais.cliqueTournee</span>
                                    <mat-icon class="mt-0 c-primary">search_outline</mat-icon>
                                </div>
                                <div class="col-md-1 pl-0">
                                    <mat-icon class="m-0 material-icons-outlined" [matTooltip]="'ndf.frais.tooltipTournee' | translate">info</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <!-- Zones utilisateurs-->
                    <zone-utilisateur #zu="zu" [listeZus]="data.frais.listeZU" [listeZuSaisies]="data.frais.listeZUSaisies" 
                            [readonly]="!data.canModifier" [canListeZuChange]="true">
                    </zone-utilisateur>
    
                    <div class="row" [ngClass]="{ 'has-error': remarque.invalid }">
                        <label class="col-md-2" [ngClass]="{ 'required': prestation?.model?.remarqueObligatoire || isFraisHorsDateOM() }"
                               [matTooltip]="(data.ndf.omPermanent ? 'ndf.frais.erreur.omPermanentDate' : 'ndf.frais.erreur.omDate') | translate"
                               [matTooltipDisabled]="!isFraisHorsDateOM()">
                            <span>{{ prestation?.model?.libelleRemarque || ('ndf.frais.remarque' | translate) }}</span>
                        </label>
                        <div class="col-md-10">
                            <mat-form-field class="d-flex">
                                <textarea
                                    matInput rows="3" #remarque="ngModel" name="remarque"
                                    [(ngModel)]="data.frais.commentaire"
                                    [required]="prestation?.model?.remarqueObligatoire || isFraisHorsDateOM()">
                                </textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </fieldset>
            </mat-tab>

            <!-- Onglet Mes Frais -->
             <mat-tab *ngIf="isCreation()" label="{{ 'ndf.frais.tabs.mesFrais' | translate }}">
                 <ndf-frais-add-frais [idNDF]="this.data.ndf.idNDF" (onListeEmpty)="onListeEmpty($event)"></ndf-frais-add-frais>
             </mat-tab>

            <!-- Onglet trajets -->
            <mat-tab *ngIf="isShowTrajets()" label="{{ 'ndf.frais.tabs.trajets' | translate }}">
                <ndf-frais-add-trajet (selectTrajet)="selectTrajet($event)" [idPrest]="data.frais.prestation.idPrestation"></ndf-frais-add-trajet>
            </mat-tab>

             <!-- Onglet Pièces jointes -->
             <mat-tab label="{{ 'ndf.frais.tabs.piecesJointes' | translate }}">
                <document-list #listeDocument 
                    [idObjet]="isFrais() ? data.frais.idFrais : data.frais.idDepense" 
                    [idObjetForPJ]="isFrais() ? data.frais.idFrais : (data.frais.idDepense || 0)" 
                    [idCollaborateur]="data.ndf.idUser"
                    [canAddDocument]="data.canModifier"
                    [context]="isFrais() ? ContextDocument.FRAIS : ContextDocument.NDF_V"
                    [isUploadToPending]="isCreation()"
                    [settings]="data.settings"
                    [errorAlreadyUsedMessage]="'ndf.frais.erreur.errorAlreadyUsedMessage'"
                    [canDeleteDocument]="data.canModifier && data.frais.idDepense > 0">
                </document-list>
             </mat-tab>
        </mat-tab-group>
    </form>
</div>
<div mat-dialog-actions *ngIf="!isLoading">
    <ng-container *ngIf="data.canModifier">
        <button mat-stroked-button color="warn" (click)="deleteDepense()" [disabled]="isProcessing" *ngIf="data.frais.idDepense || data.frais.idFrais">
            <span *ngIf="!isDeleting" translate>global.actions.supprimer</span>
            <mat-spinner class="m-5" diameter="28" *ngIf="isDeleting"></mat-spinner>
        </button>
        <!-- Bouton d'enregistrement de l'onglet saisie de frais  -->
        <button *ngIf="!canAjouterNote()" mat-flat-button color="primary" (click)="onSave()"
                [disabled]="isProcessing || fraisAddForm.invalid || !isTaxeValid() || !isJustifValid() || !isPJValid() || !isInvitesValid() || !isGeographieValid() || (hasNotAutorisationCirculer() && data.settings.niveauControleAutorisationVehiculeNdf === niveauAutorisation.BLOQUANT)">
            <span *ngIf="!isSaving" translate>global.actions.enregistrer</span>
            <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
        </button>
        <!-- Bouton de validation pour l'onglet mes frais -->
        <button *ngIf="canAjouterNote()" mat-flat-button color="primary" [disabled]="isProcessing || isListeEmpty" (click)="selectMesFrais()">
            <span *ngIf="!isAddingToNote" translate>ndf.frais.ajouterALaNote</span>
            <mat-spinner class="m-5" diameter="28" *ngIf="isAddingToNote"></mat-spinner>
        </button>
    </ng-container>

    <!-- Bouton de rejet de frais -->
    <button *ngIf="data.ndf.canRejeter() && data.settings.isFraisRefuses && !data.frais.rejete" [disabled]="isProcessing" mat-stroked-button color="warn" (click)="rejeterFrais()">
        <span *ngIf="!isRejecting" translate>ndf.frais.rejeterFrais</span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isRejecting"></mat-spinner>
    </button>
</div>

<!-- Tooltip d'information sur le taux spécial survolé -->
<ng-template #tooltipTaux let-taux="taux">
    <form autocomplete="off" class="nio-tooltip-details">
        <table>
            <tr *ngIf="taux.libelleSpecial">
                <td><label translate>frais.tooltipTaux.libelle</label></td>
                <td>{{ taux.libelleSpecial }}</td>
            </tr>
            <tr *ngIf="taux.libelleTerritoire">
                <td><label translate>frais.tooltipTaux.lieu</label></td>
                <td>{{ taux.libelleTerritoire }}</td>
            </tr>
            <tr>
                <td><label translate>frais.tooltipTaux.periode</label></td>
                <td>{{ fraisService.getPeriode(taux) }}</td>
            </tr>
            <tr>
                <td><label translate>frais.tooltipTaux.montant</label></td>
                <td>{{ taux.montant | montant:taux.devise }}</td>
            </tr>
        </table>
    </form>
</ng-template>
