<mat-card id="bloc-generalites">
    <mat-card-title>
        <span [class.success]="facture.lastWorkflowHistoriqueActionAlerte?.niveau == NiveauAlerte.NO_CONTROL" [class.error]="facture.lastWorkflowHistoriqueActionAlerte?.niveau == NiveauAlerte.ERROR">{{ facture.typeEntite.libelle }}
            <proprietaire *ngIf="facture.user" [proprietaireUser]="facture.user"></proprietaire>
        </span>
        <span *ngIf="[TypeProfil.COMPTABLE,TypeProfil.ADMINISTRATEUR].includes($any(user.fonction))" class="actions clickable" (click)="backToReleve()" [matTooltip]="'facture.liste.retourReleve' | translate">
            <mat-icon>description</mat-icon>
        </span>
    </mat-card-title>
    <mat-card-content>
        <card-alerte *ngIf="facture.lastWorkflowHistoriqueActionAlerte" [niveau]="facture.lastWorkflowHistoriqueActionAlerte.niveau">
            <preview-alerte [alerte]="facture.lastWorkflowHistoriqueActionAlerte"></preview-alerte>
        </card-alerte>
        <form autocomplete="off" #form="ngForm" name="form_generalites">
            <fieldset [disabled]="!canModifier">
                <!-- On attend que les listes aient été initialisées puis on les envoie au composant -->
                <filling-row *ngIf="listeTemplatesGauche.length > 0 || listeTemplatesDroite.length > 0" [listeGauche]="listeTemplatesGauche"
                             [listeDroite]="listeTemplatesDroite"></filling-row>

                <!-- Zone prédéfinie -->
                <zone-predefinie [listeFieldParam]="listeFieldParam" [fieldContainer]="fieldContainer"></zone-predefinie>

                <!-- Zones utilisateurs-->
                <zone-utilisateur #zu="zu" [listeZus]="facture.listeZU" [listeZuSaisies]="facture.listeZUSaisies" [readonly]="!canModifier"></zone-utilisateur>

                <!-- Champ remarque -->
                <div class="row">
                    <custom-input ngDefaultControl name="remarque" customType="textarea" libelle="facture.generalites.remarque" rCol="10"
                                  [(ngModel)]="facture.commentaire" [disabled]="!canModifier"></custom-input>
                </div>
            </fieldset>
        </form>
    </mat-card-content>
</mat-card>

<!-- Numéro -->
<ng-template #tplNumero>
    <custom-input ngDefaultControl name="numero" customType="input" libelle="facture.generalites.numero" [disabled]="!canModifier || releve.importe"
                  [(ngModel)]="facture.numero" [required]="true"></custom-input>
</ng-template>

<!-- OD lié (suivant le type de réconciliation du relevé) -->
<ng-template #tplOd>
    <ng-container *ngIf="!canModifier">
        <label class="col-md-2" [class.text-muted]="!canModifier"><span [translate]="'facture.generalites.od'"></span></label>
        <div class="col-md-4 r-flex mb-2 align-self-center">
            <span [nioTooltip]="tplOdTooltip" [nioTooltipPosition]="'track'">
                <span>{{ facture.od.idOd }} - {{ facture.od.objet }}</span>
                <a [routerLink]="" (click)="navigateToOd()" *ngIf="facture.od">
                    <span class="material-icons align-middle">open_in_new</span>
                </a>
            </span>
        </div>
    </ng-container>
    <ng-container *ngIf="canModifier">
        <custom-input ngDefaultControl name="od" libelle="facture.generalites.od" lCol="2" rCol="3"
            customType="autocomplete" autocompleteType="od" [autocompleteFilter]="{portee: this.facture.getPortee()}" optionDisplay="idOd" [idName]="'idOd'"
            [disabled]="!canModifier" [(ngModel)]="od" [required]="true" (onChange)="odChanged(); resetAutocomplete.emit();" [reinitListeObs]="resetAutocomplete">
        </custom-input>
        <div class="col-md-1 r-flex mb-2 align-self-center">
            <span [nioTooltip]="tplOdTooltip" [nioTooltipPosition]="'track'">
                <a [routerLink]="" (click)="navigateToOd()" *ngIf="facture.od">
                    <span class="material-icons align-middle">open_in_new</span>
                </a>
            </span>
        </div>
    </ng-container>
</ng-template>

<!-- Collaborateur lié (suivant le type de réconciliation du relevé) -->
<ng-template #tplCollab>
    <custom-input ngDefaultControl name="collab" libelle="facture.generalites.collaborateur" lCol="2" rCol="4"
                  customType="autocomplete" autocompleteType="collab" [autocompleteFilter]="{portee: this.facture.getPortee()}"
                  optionDisplay="nomPrenomMatricule" [idName]="'idUser'"
                  [disabled]="!canModifier" [(ngModel)]="collaborateur" [required]="true" (onChange)="userChanged(); resetAutocomplete.emit();" [reinitListeObs]="resetAutocomplete"></custom-input>
</ng-template>

<!-- Date -->
<ng-template #tplDate>
    <custom-input ngDefaultControl name="date" customType="date" libelle="facture.generalites.date" rCol="2" rClass="mr-auto"
        [disabled]="!canModifier || releve.importe" [ngModel]="facture.date" [(customModel)]="facture.date" [required]="true" (customModelChange)="dateChanged($event)"></custom-input>
</ng-template>

<!-- Type de la facture -->
<ng-template #tplTypeFacture>
    <custom-input ngDefaultControl name="type_facture" customType="select" libelle="facture.generalites.typeFacture" rCol="2" rClass="mr-auto" optionValue="valeur" optionDisplay="libelle"
        [(ngModel)]="facture.type" [disabled]="!canModifier || releve.importe" [required]="true"
        [selectOptions]="listeTypeFacture"></custom-input>
</ng-template>

<!-- Template du tooltip d'information de l'OD -->
<ng-template #tplOdTooltip>
    <form autocomplete="off" class="nio-tooltip-details">
        <table>
            <tr>
                <td><label translate>od.generalites.destination</label></td>
                <td>{{ facture.od.ville ? facture.od.ville.libelle + ', ' + facture.od.pays?.libelle : facture.od.pays?.libelle }}</td>
            </tr>
            <tr>
                <td><label translate>od.generalites.debut</label></td>
                <td>{{ facture.od.depart_le | date:'shortDate' }} {{ facture.od.heureDepart }}</td>
            </tr>
            <tr>
                <td><label translate>od.generalites.fin</label></td>
                <td>{{ facture.od.retour_le | date:'shortDate' }} {{ facture.od.heureRetour }}</td>
            </tr>
        </table>
    </form>
</ng-template>

<!-- Fournisseur -->
<ng-template #tplFournisseur>
    <custom-input ngDefaultControl name="fournisseur" customType="input" libelle="facture.generalites.fournisseur" [readonly]="true" [disabled]="!canModifier"
                  [ngModel]="facture.libelleFournisseur"></custom-input>
</ng-template>

<!-- Numéro relevé -->
<ng-template #tplNumeroReleve>
    <custom-input ngDefaultControl name="numReleve" customType="input" libelle="facture.generalites.numeroReleve" [readonly]="true" [disabled]="!canModifier"
                  [ngModel]="releve.numero"></custom-input>
</ng-template>

<!-- Mois d'imputation -->
<ng-template #tplMoisImputation>
    <custom-input ngDefaultControl name="mois_imputation" customType="objectselect" libelle="facture.generalites.moisImputation" [disabled]="!canModifier || releve.importe || typeEntiteParamOd?.modeMIBFacture == ModeMIB.MIB_HERITE_NON_MODIFIABLE"
                  [(ngModel)]="facture.periode" [required]="true" lCol="2" rCol="2" rClass="mr-auto"
                  optionValue="idPeriode" optionDisplay="libelle" [selectOptions]="settings.listePeriodes"></custom-input>
</ng-template>

<!-- Montant -->
<ng-template #tplMontant>
    <custom-input ngDefaultControl name="montant" customType="montant" libelle="facture.generalites.montant"
                  [(ngModel)]="facture.montant" [montantDevise]="facture.devise" [listeDevise]="releve.importe ? null : this.settings.listeDevises" (onDeviseChange)="deviseChanged($event)"
                  [disabled]="true"></custom-input>
</ng-template>

<!-- Montant contrôle -->
<ng-template #tplMontantControle>
    <custom-input ngDefaultControl name="montantControle" customType="montant" libelle="facture.generalites.montantControle"
                  [(ngModel)]="facture.montantControle" [montantDevise]="facture.devise" [listeDevise]="this.settings.listeDevises" (onDeviseChange)="deviseChanged($event)"
                  [disabled]="!canModifier"></custom-input>
</ng-template>