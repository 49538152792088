import {Component} from '@angular/core';

/**
 * Iframe temporaire contenant la V9 des profils de connexion
 *
 * @author Laurent Convert
 * @date 21/03/2024
 */
@Component({
	host: {'data-test-id': 'iframe-profil'},
	selector: 'iframe-profil',
	templateUrl: './iframe-profil.component.html'
})
export class IframeProfilComponent {

	/** Utilisé pour attendre le chargement de la première frame avant de charger la seconde */
    //TODO: A supprimer quand l'implémentation v10 sera terminée
	isFirstLoaded: boolean = false;

	/**
	 * Méthode appelée lorsque l'iFrame a terminé un chargement
	 * TODO: A supprimer quand l'implémentation v10 sera terminée
	 */
	loaded() {
		//Si c'est le premier chargement
		if (!this.isFirstLoaded) {
			//Sinon basculement du flag
			this.isFirstLoaded = true;
		}
	}
}