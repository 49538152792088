import {NgModule} from "@angular/core";
import {Routes} from "@angular/router";
import {ShareModule} from "@share/share.module";
import {DevisesModule} from './devises/devises.module';
import {InternationalisationModule} from "./internationalisation/internationalisation.module";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {DeviseInfosComponent} from "@components/admin/bibliotheque/devises/devise-infos/devise-infos.component";
import {DevisesComponent} from "@components/admin/bibliotheque/devises/devises.component";
import {AdminBibliothequeComponent} from '@components/admin/bibliotheque/admin-bibliotheque.component';
import {LangueInfosComponent} from "@components/admin/bibliotheque/internationalisation/langue-infos/langue-infos.component";
import {InternationalisationComponent} from "@components/admin/bibliotheque/internationalisation/internationalisation.component";
import {GeographieComponent} from './geographie/geographie.component';
import {adminGeographieRoutes,GeographieModule} from "@components/admin/bibliotheque/geographie/geographie.module";
import {ReportingModule} from "@components/admin/bibliotheque/reporting/reporting.module";
import {ReportingInfosComponent} from "@components/admin/bibliotheque/reporting/reporting-infos/reporting-infos.component";
import {ReportingComponent} from "@components/admin/bibliotheque/reporting/reporting.component";
import {DroitAdmin} from "@core/security/droit-admin";
import {TypesEntitesComponent} from "@components/admin/bibliotheque/types-entites/types-entites.component";
import {BibliothequeNotificationsModule,bibliothequeNotificationsRoutes} from "@components/admin/bibliotheque/notifications/bibliotheque-notifications.module";
import {BibliothequeNotificationsComponent} from "@components/admin/bibliotheque/notifications/bibliotheque-notifications.component";
import {FrameComponent} from "@share/layout/frame.component";

export const adminBibliothequeRoutes: Routes = [
    {
        path: 'Devises/:idDevise',
        component: DeviseInfosComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_DEVISE_TAUX_CHANGE] }
    }, {
        path: 'Devises',
        component: DevisesComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_DEVISE_TAUX_CHANGE] }
    }, {
        path: 'Geographie',
        component: GeographieComponent,
        canActivate: [AdminGuardProvider],
        children: adminGeographieRoutes
    }, {
        path: 'Langue/:idLangue',
        component: LangueInfosComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_INTERNATIONALISATION] }
    }, {
        path: 'Langue',
        component: InternationalisationComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_INTERNATIONALISATION] }
    }, {
        path: 'Notification',
        component: BibliothequeNotificationsComponent,
        canActivate: [AdminGuardProvider],
        children: bibliothequeNotificationsRoutes
    }, {
        path: 'Reporting/:idReport',
        component: ReportingInfosComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_REPORTING] }
    }, {
        path: 'Reporting',
        component: ReportingComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_REPORTING] }
    }, {
        path: 'TypeEntite',
        component: TypesEntitesComponent,
        canActivate: [AdminGuardProvider]
    }, {
        path: 'Vehicules',
        component: FrameComponent, //TODO: Implémenter le vrai composant v10
        canActivate: [AdminGuardProvider]
    }, {
        path: 'ZonesUtilisateur',
        component: FrameComponent, //TODO: Implémenter le vrai composant v10
        canActivate: [AdminGuardProvider]
    }, {
        path: '',
        redirectTo: 'Devises',
        pathMatch: 'full'
    }, {
        path: '**',
        redirectTo: 'Devises'
    }
];

/**
 * Module des référentiels de la gestion des frais
 */
@NgModule({
    imports: [
        ShareModule,
        DevisesModule,
        GeographieModule,
        InternationalisationModule,
        ReportingModule,
        BibliothequeNotificationsModule
    ],
    declarations: [
        AdminBibliothequeComponent,
        TypesEntitesComponent,
    ],
    entryComponents: [],
    exports: [
        AdminBibliothequeComponent
    ],
    providers: []
})
export class AdminBibliothequeModule {
}
