<div class="with-floating-button">
    <div class="content" *ngIf="objetWorkflow">
        <page-header #pageHeaderComponent [title]="'omp.title' | translate:{ numero: objetWorkflow.idOMPermanent }" [extraInfo]="objetWorkflow.statut.libelle" [listeItems]="listeTabItems"
                     [niveauAlerte]="listeAlertes?.niveau"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()" (onExtraInfoClick)="showChainage()" [isPending]="isSaving">
            <preview-alerte #alerte *ngFor="let alerte of listeAlertes?.listeAlertes" [alerte]="alerte"></preview-alerte>
        </page-header>

        <div [hidden]="selectedItem?.code !== 'GENERALITES'">
            <omp-generalites [omp]="objetWorkflow" [canModifier]="canModifier" [settings]="settings" [typeEntiteParam]="typeEntiteParam"></omp-generalites>

            <omp-transports *ngIf="typeEntiteParam?.gestionTransportAutoriseOT" [omp]="objetWorkflow" [listeTransports]="objetWorkflow.listeTransports" [canModifier]="canModifier"
                            [listeVehicule]="listeVehicule"
                            [mapConfort]="mapConfort"></omp-transports>

            <omp-zones *ngIf="typeEntiteParam?.gestionDeplacement" [omp]="objetWorkflow" [typeEntiteParam]="typeEntiteParam" [canModifier]="canModifier"></omp-zones>

            <analytique
				#analytique="analytique"
                [listeAnalytiques]="objetWorkflow.listeVentilations" [precision]="0"
                [typeEntite]="objetWorkflow.typeEntite" [settings]="settings"
                [canModifier]="canModifier" [canSaisie]="canModifier"
                [preferenceAnalytiqueCount]="preferenceAnalytiqueCount"
                [idCollab]="objetWorkflow.getIdCollab()"
                (onModification)="buildListeAlertes()">
            </analytique>
        </div>

        <div *ngIf="selectedItem?.code == 'MISSIONS'">
            <omp-od-list [omp]="objetWorkflow" [typeEntiteParam]="typeEntiteParam" [canModifier]="canModifier || objetWorkflow?.canCompleter()"></omp-od-list>
        </div>

        <div *ngIf="selectedItem?.code == 'FRAIS'">
            <omp-ndf-list [omp]="objetWorkflow" [typeEntiteParam]="typeEntiteParam" [canModifier]="canModifier || objetWorkflow?.canCompleter()"></omp-ndf-list>
        </div>

        <div *ngIf="selectedItem.code == 'FICHECOLLABORATEUR'">
            <fiche-collaborateur [idCollaborateur]="idCollaborateur"></fiche-collaborateur>
        </div>

        <div *ngIf="selectedItem?.code == 'COMPLEMENTS'">
            <document-list
                    [idObjet]="objetWorkflow.idOMPermanent"
                    [idObjetForPJ]="objetWorkflow.idOMPermanent"
                    [idCollaborateur]="idCollaborateur"
                    [context]="'OM_PERMANENT'"
                    [settings]="settings"
                    [canAddDocument]="(canModifier || canCompleter) && settingsService.isPjParamForTypeEntite(settings,objetWorkflow.getTypeEntite().idTypeEntite)"
                    [canDeleteDocument]="(canModifier || canCompleter) && settingsService.isPjParamForTypeEntite(settings,objetWorkflow.getTypeEntite().idTypeEntite)"
            ></document-list>

            <referentiel-externe *ngIf="settings?.isReferentielExterneAvailable" [typeReferentiel]="portee" [idObject]="objetWorkflow.idOMPermanent"></referentiel-externe>
        </div>

        <div *ngIf="selectedItem?.code === OngletsAdmin.WORKFLOW">
            <onglet-workflow
                    [isObjetValide]="isValid()"
                    [portee]="TypePortee.OT"
                    [listeVentilations]="objetWorkflow.listeVentilations"
                    [typeEntite]="objetWorkflow.typeEntite"
                    [globalSettings]="settings"
                    [idObjet]="objetWorkflow.idOMPermanent">
            </onglet-workflow>
        </div>

        <div *ngIf="selectedItem?.isLoaded" [hidden]="selectedItem?.code != 'OUTILS'">
            <outils [idObjet]="objetWorkflow.idOMPermanent" [typePortee]="TypePortee.OT" [isObjetValide]="isValid()"></outils>
        </div>
    </div>
    <please-wait class="content" *ngIf="objetWorkflow === undefined"></please-wait>
    <floating-button [listeActions]="listeActions" [isPending]="isSaving"></floating-button>
</div>
