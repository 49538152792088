<mat-card *ngFor="let carteHierarchique of listeCartesHierarchiques">
    <mat-card-title class="clickable" (click)="carteHierarchique.foldUnfold()">
        <span>
            <i class="no-hover material-icons-outlined" [ngClass]="carteHierarchique.logo">{{carteHierarchique.isLogoMaterialIcon() ? carteHierarchique.logo : '' }}</i>
            &nbsp;
            <span [translate]="carteHierarchique.cleTradCarte"></span>
        </span>
        <span class="actions clickable">
                <mat-icon [hidden]="carteHierarchique.folded">unfold_less</mat-icon>
                <mat-icon [hidden]="!carteHierarchique.folded">unfold_more</mat-icon>
        </span>
    </mat-card-title>
    <mat-card-content>
        <table mat-table [dataSource]="carteHierarchique.listeArbresHierarchiques" multiTemplateDataRows>
            <!-- Colonnes d'avatar -->
            <ng-container matColumnDef="avatar">
                <th mat-header-cell *matHeaderCellDef class="avatar"></th>
                <td mat-cell *matCellDef="let element" class="avatar">
                    <span class="avatar-img clickable" (click)="switchCheckStatus(element)">
                        <i *ngIf="element.checkStatus() === CheckStatus.AUCUN" class="not-checked material-icons-outlined">check_box_outline_blank</i>
                        <i *ngIf="element.checkStatus() === CheckStatus.PARTIEL" class="material-icons-outlined">indeterminate_check_box</i>
                        <i *ngIf="element.checkStatus() === CheckStatus.TOUS" class="checked material-icons-outlined">check_box</i>
                    </span>
                </td>
            </ng-container>

            <!-- Colonnes du nom de la section -->
            <ng-container matColumnDef="objet">
                <th mat-header-cell *matHeaderCellDef class="objet carte">{{ column }}</th>
                <td mat-cell *matCellDef="let element" class="objet carte"><a (click)="element.folded = !element.folded;$event.stopPropagation()">{{ element.arbreTitleKey | translate }}</a></td>
            </ng-container>

            <!-- Colonnes en paramètre -->
            <ng-container *ngFor="let column of listeColonnes">
                <ng-container matColumnDef="{{column}}">
                    <th mat-header-cell *matHeaderCellDef class="autre entete">
                        <span class="header-detail">
                            <span>{{ getCleTraduction(column) | translate }}</span>
                            <mat-icon *ngIf="column == DetailName.FILTRE" class="info" [nioTooltip]="'workflow.roles.droits.tooltip.droitFiltre' | translate">info</mat-icon>
                        </span>
                    </th>
                    <td mat-cell *matCellDef="let element" class="autre">
                        <component-holder *ngIf="element.listeComposantsDetail && element.listeComposantsDetail[column]" [composant]="element.listeComposantsDetail[column]"
                                          [inputs]="element.listeInputsComposantsDetail[column]"></component-holder>
                    </td>
                </ng-container>
            </ng-container>

            <!-- Colonne étendre -->
            <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions" class="expand">&nbsp;</th>
                <td mat-cell *matCellDef="let element" class="expand">
                    <button mat-icon-button aria-label="expand row" (click)="element.folded = !element.folded;$event.stopPropagation()">
                        <mat-icon [hidden]="element.folded" class="arrow">keyboard_arrow_up</mat-icon>
                        <mat-icon [hidden]="!element.folded" class="arrow">keyboard_arrow_down</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- Contenu unfolded -->
            <ng-container matColumnDef="detail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="listeColonnesEtendues.length" [hidden]="element.folded">
                    <table mat-table [dataSource]="element.listeBranches">
                        <!-- Colonnes d'avatar -->
                        <ng-container matColumnDef="avatar">
                            <th mat-header-cell *matHeaderCellDef class="avatar ligne-hierarchique"></th>
                            <td mat-cell *matCellDef="let element" class="avatar ligne-hierarchique"><span class="ligne"></span></td>
                        </ng-container>

                        <!-- Colonnes du nom de la section -->
                        <ng-container matColumnDef="objet">
                            <th mat-header-cell *matHeaderCellDef class="objet">{{ column }}</th>
                            <td mat-cell *matCellDef="let element" class="objet">
                                <component-holder [composant]="element.itemTitleComponent" [inputs]="element.itemTitleInputs"></component-holder>
                            </td>
                        </ng-container>

                        <!-- Colonnes -->
                        <ng-container *ngFor="let column of listeColonnes">
                            <ng-container matColumnDef="{{column}}">
                                <th mat-header-cell *matHeaderCellDef aria-label="row actions" class="detail"></th>
                                <td mat-cell *matCellDef="let element" class="autre">
                                    <component-holder *ngIf="element.listeComposantsDetail && element.listeComposantsDetail[column]" [composant]="element.listeComposantsDetail[column]"
                                                      [inputs]="element.listeInputsComposantsDetail[column]"></component-holder>
                            </ng-container>
                        </ng-container>

                        <!-- Colonne étendre -->
                        <ng-container matColumnDef="expand">
                            <th mat-header-cell *matHeaderCellDef class="expand"></th>
                            <td mat-cell *matCellDef="let element" class="expand"></td>
                        </ng-container>

                        <tr mat-row *matRowDef="let element; columns: listeColonnesEtendues" class="detail-row"></tr>
                    </table>
                </td>
            </ng-container>

            <!-- Header Row -->
            <tr mat-header-row *matHeaderRowDef="listeColonnesEtendues; sticky: true"></tr>

            <!-- Ligne des parents hiérarchiques -->
            <tr mat-row *matRowDef="let element; columns: listeColonnesEtendues;" class="element-row"></tr>

            <!-- Ligne pour le sous tableau -->
            <tr mat-row *matRowDef="let row; columns: ['detail']"></tr>
        </table>
    </mat-card-content>
</mat-card>