/**
 * Mapping des routes admin temporaires en attendant la refonte des pages admin en V10.
 * La map permet de limiter un peu les bidouilles via l'URL tout en autorisant la compréhension de la route.
 * L'URL conserve une signification et les utilisateurs peuvent copier-coller en conservant la page si besoin.
 * TODO: A nettoyer pour chaque nouvelle page admin V10 et à supprimer une fois toutes les pages portées en V10
 * */
export const FRAME_ROUTES: Map<string,{ path: string, idParam?: boolean}> = new Map([
    ["/Admin/Entreprise/Populations", { path: "controller/Population?action=listePopulation" }],
    ["enveloppes", { path: "controller/Analytique?action=consult" }],
    ["externes", { path: "servlet/NDFServlet#/Externes/consult?tab=0" }],
    ["parametres1", { path: "servlet/NDFServlet#/Externes/consult?tab=1" }],
    ["parametres2", { path: "controller/Notification?action=liste&tab=id_bloc_profil_config" }],
    ["/Admin/Comptabilite/PlanComptable", { path: "servlet/NDFServlet#/Compte/listeCompte" }],
    ["/Admin/Bibliotheque/Geographie", { path: "controller/Geographie" }],
    ["motifs", { path: "servlet/NDFServlet#/WorkflowMotif" }],
    ["accueil", { path: "controller/Notification?action=liste&tab=id_bloc_messages_accueil" }],
    ["balises", { path: "controller/Notification?action=liste&tab=id_bloc_liste_balises" }],
    ["mails", { path: "servlet/NDFServlet#/Mail/configSMTP" }],
    ["messages", { path: "controller/Notification?action=liste&tab=id_bloc_liste_notifications" }],
    ["relances", { path: "controller/Notification?action=liste&tab=id_bloc_relances" }],
    ["/Admin/Bibliotheque/Reporting", { path: "servlet/NDFServlet#/Report/liste" }],
    ["typeEntite1", { path: "controller/TypeEntite?action=liste" }],
    ["typeEntite2", { path: "controller/Portee" }],
    ["/Admin/Bibliotheque/Vehicules", { path: "controller/VehiculeAdmin" }],
    ["/Admin/Bibliotheque/ZonesUtilisateur", { path: "controller/ZoneUtilisateur" }],
    ["rest", { path: "servlet/NDFServlet#/API/comptes" }],
    ["soap", { path: "servlet/NDFServlet#/Interfaces/Configuration?tab=0" }],
    ["clefs-api", { path: "servlet/NDFServlet#/Interfaces/Referentiels?tab=0" }],
    ["/Admin/Parametre/Authentification/ComptesVerrouilles", { path: "controller/AuthAdmin" }],
    ["/Admin/Parametre/Mobilite", { path: "servlet/NDFServlet#/MobileConfig/loadConfig" }],
    ["/Admin/Parametre/Ordonnanceur", { path: "servlet/NDFServlet#/Ordonnanceur/listeOrdo" }],
    ["/Admin/Parametre/Personnalisation", { path: "servlet/NDFServlet#/BoiteLien/listeLien" }],
    ["fournisseurs", { path: "servlet/NDFServlet#/Contrat/listeFournisseur" }],
    ["typesCharges", { path: "servlet/NDFServlet#/Interfaces/Referentiels?tab=2" }],
    ["tolerances", { path: "servlet/NDFServlet#/Interfaces/Configuration?tab=2" }],
    ["/Admin/Frais/Indemnites/Indemnites", { path: "servlet/NDFServlet#/IndemnitesJournalieres" }],
    ["fraisCartes1", { path: "servlet/NDFServlet#/Interfaces/Configuration?tab=1" }],
    ["fraisCartes2", { path: "controller/SuiviImportFacture?tab=id_bloc_suivi_ca" }],
    ["/Admin/Frais/Prestations", { path: "servlet/NDFServlet#/Prestation/listePrestation" }],
    ["/Admin/Frais/Taxes", { path: "servlet/NDFServlet#/Taxe/param" }],
    ["/Admin/Voyages/FraisAgence", { path: "servlet/NDFServlet#/FraisAgence" }],
    ["TravelHubProfil", { path: "servlet/NDFServlet#/TravelHub/param/5" }],
    ["alertes", { path: "servlet/NDFServlet#/Alerte/liste" }],
    ["conditions", { path: "servlet/NDFServlet#/Condition/liste" }],
    ["/Admin/Workflow/Construction", { path: "servlet/NDFServlet#/Workflow/liste" }],
    ["/Admin/Workflow/Controle", { path: "servlet/NDFServlet#/Workflow/controle" }],
    ["/Admin/Workflow/Motifs", { path: "servlet/NDFServlet#/WorkflowMotif" }],
    ["/Admin/Workflow/Roles", { path: "servlet/NDFServlet#/Role/liste" }],
    ["/Admin/Workflow/Statuts", { path: "servlet/NDFServlet#/Statut/liste/0" }],
    ["/Admin/Maintenance/CompteAdmin", { path: "servlet/NDFServlet#/Maintenance/CompteAdmin" }],
    ["/Admin/Maintenance/GestionLogs", { path: "servlet/NDFServlet#/Logging/configLogging" }],
    ["distances", { path: "controller/Geographie?tab_index=7"}]
]);
