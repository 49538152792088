<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'warning': data.listeAlertes?.niveau == 1,'danger': data.listeAlertes?.niveau == 2 }"
         [nSelector]="{liste: liste, item: data, isNotSelectable: fonction === TypeProfil.ADMINISTRATEUR}">
        <i class="nio icon-note_de_frais" style="vertical-align: middle;"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="navigateTo()">
                {{ data.numeroPiece || ('ndf.liste.numeroNonDefini' | translate) }} - {{ data.objet || ('ndf.liste.objetNonDefini' | translate) }}
            </a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.od" (click)="openOd(data.od)" style="cursor:pointer;">
                <strong [translate]="'ndf.liste.om'"></strong>
                    {{ data.od }}
                <mat-icon color="primary" style="font-size:1.1em;">open_in_new</mat-icon>
            </li>
            <li *ngIf="data.omPermanent" (click)="openOmp(data.omPermanent)" style="cursor:pointer;">
                <strong [translate]="'ndf.liste.omp'"></strong>
                    {{ data.omPermanent }}
                <mat-icon color="primary" style="font-size:1.1em;">open_in_new</mat-icon>
            </li>
            <li *ngIf="!isCollabMode"><strong [translate]="'ndf.liste.collaborateur'"></strong>{{ data.getCollaborateur() }} - {{ data.user?.matricule }}</li>
            <li><strong [translate]="'ndf.liste.type'"></strong>{{ data.typeEntite }}</li>
            <li><strong [translate]="'ndf.liste.dateStatut'"></strong>{{ data.dateMaj | date:'shortDate' }}</li>
            <li *ngIf="!isCollabMode && data.getRolesCount() > 1" [matTooltip]="data.getRoles()"><strong [translate]="'facture.liste.roles'"></strong>{{ data.getRolesCount() }}</li>
            <li *ngIf="!isCollabMode && data.getRolesCount() == 1"><strong [translate]="'facture.liste.role'"></strong>{{ data.getRoles() }}</li>
            <li *ngIf="data.listeAlertes" (click)="showListeAlertes()" class="clickable"
                [ngClass]="{'back-danger' : data.listeAlertes.niveau == 2, 'back-warning' : data.listeAlertes.niveau == 1}">
                <strong [translate]="'workflow.alerte.title'"></strong><span>{{ ('workflow.alerte.niveau.' + data.listeAlertes.niveau | translate) }}</span>
            </li>
        </ul>
    </div>
    <div class="info">
        <div>
            <statut [statut]="data.statut"></statut>
            <div class="text-right text-secondary font-weight-bold">
                {{ data.montantRemboursable | montant:data.devise.code }}
            </div>
        </div>
    </div>
</div>
