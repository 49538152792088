<div class="r-flex line-item-list">
    <div class="avatar">
        <i class="nio icon-ordre_de_mission"></i>
    </div>
    <div class="body">
        <span class="row">
            <span class="col-md-12">
                {{ options.displayItem(item) }}
            </span>
        </span>
        <span class="row complement">
            <span class="col-md-12">
                <strong translate>od.generalites.destination</strong>
                <span *ngIf="item.pays?.code2" class="flag-icon mr-1" [ngClass]="'flag-icon-'+item.pays.code2.toLowerCase()"></span>
                <span>{{ item.ville ? item.ville.libelle + ', ' + item.pays?.libelle : item.pays?.libelle }}</span>
            </span>
        </span>
    </div>
</div>
