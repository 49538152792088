import {ComponentType} from "@angular/cdk/overlay";
import {HttpClient,HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog,MatDialogRef} from "@angular/material/dialog";
import {NavigationExtras,Router} from "@angular/router";
import {NiveauNature} from "@components/od/detail/voyage/od-voyage.service";
import {AppState} from "@domain/appstate";
import {Result} from '@domain/common/http/result';
import {FacturePrev} from "@domain/factureprev/facture-prev";
import {FraisPrev} from "@domain/fraisprev/frais-prev";
import {EngagementsFactures} from "@domain/od/engagements/factures/engagements-factures";
import {EngagementsFrais} from "@domain/od/engagements/frais/engagements-frais";
import {EngagementsIndemnites} from "@domain/od/engagements/indemnites/engagements-indemnites";
import {IJDetail} from "@domain/od/engagements/indemnites/ij-detail";
import {RegleAttribution} from "@domain/od/engagements/indemnites/regle-attribution";
import {EngagementsResume} from "@domain/od/engagements/resume/engagements-resume";
import {OdTypeFacture} from "@domain/od/frais/od-type-facture";
import {OdTypeFrais} from "@domain/od/frais/od-type-frais";
import {LienOmUser} from "@domain/od/lien-om-user";
import {Od} from "@domain/od/od";
import {SaisieTemps} from "@domain/od/saisie-temps";
import {SaisieEtapeDTO} from "@domain/travel/saisie-etape-dto";
import {TypeEntiteParamOD} from "@domain/typeentite/typeEntiteParam";
import {User} from "@domain/user/user";
import {NatureVoyage} from "@domain/voyage/nature/nature-voyage";
import {TypeAiguillage} from "@domain/voyage/travel/constants";
import {SynchroSBTConfigUser} from "@domain/voyage/travel/synchro-sbt-config-user";
import {MapAction} from "@domain/workflow/mapAction";
import {TypePortee} from "@domain/workflow/workflow";
import {environment} from '@environments/environment';
import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import * as typeEntiteActions from "@reducers/type-entite";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {LayoutService} from "@share/layout/layout.service";
import {mapToObject} from "@share/utils/rxjs-custom-operator";
import * as moment from "moment";
import {ToastrService} from "ngx-toastr";
import {BehaviorSubject,Observable,of,Subject} from 'rxjs';
import {first,map} from 'rxjs/operators';
import {IObjetWorkflowService} from '../workflow/objet-workflow.service';
import {MapCreationParticipantType} from "./detail/voyage/participants/map-creation-participants.type";
import {OdParticipantsPopinEmissionComponent} from "./detail/voyage/participants/popin-emission/od-participants-popin-emission.component";
import {FactureService} from "@components/facture/facture.service";
import {Pays} from "@domain/geographie/pays";

@Injectable()
export class ODService implements IObjetWorkflowService<Od> {
	/**
	 * Contructeur
	 */
	constructor(private http: HttpClient,
				private router: Router,
				private matDialog: MatDialog,
				private toastrService: ToastrService,
				private translateService: TranslateService,
				private confirmService: ConfirmService,
				private pleaseWaitService: PleaseWaitService,
				private layoutService: LayoutService,
				private store: Store<AppState>,
				private factureService: FactureService) {
	}

	/**
	 * Navigue vers la page d'une mission
	 *
	 * @param idOd      Identifiant de la mission
	 * @param extras    Informations supplémentaires pour la navigation
	 */
	navigateToOD(idOd: number,extras?: NavigationExtras) {
		this.router.navigate(['OD',idOd],extras);
	}

	/**
	 * Chargement d'un OD
	 * @param idOD Identifiant de l'od
	 */
	load(idOD: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/OD/loadOD/${idOD}/detail`,null);
	}

	/**
	 * Création d'un OD
	 * @param object Paramètres de l'OD à créer
	 */
	create({idOMPermanent,idTypeEntite,idCollab}: { idOMPermanent?: number,idTypeEntite: number,idCollab?: number }): Observable<number> {
		let params: URLSearchParams = new URLSearchParams();

		//Ajout des paramètres
		params.append('idOmPermanent',(idOMPermanent || 0).toString());
		params.append('idTypeEntite',idTypeEntite.toString());
		if (idCollab) {
			params.append('idCollab',idCollab.toString());
		}

		//Création de l'OD
		return this.http.post<Result>(`${environment.baseUrl}/controller/OD/createOD`,params.toString(),{
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
			}
		}).pipe(
			first(),
			map(result => result?.data?.idOd)
		);
	}

	/**
	 * Enregistrement de l'OD
	 */
	save(od: Od): Observable<Result> {
		//Enregistrement de l'OD
		return this.http.put<Result>(`${environment.baseUrl}/controller/OD/saveHeader`,od);
	}

	/**
	 * Ajoute les participants à la liste des participants de la mission
	 *
	 * @param listeLienOmUser   Liste des participants à ajouter
	 * @param idOd              Identifiant de l'OD concerné
	 * @param idCollab          Identifiant du collaborateur de la mission
	 */
	ajouterParticipants(listeLienOmUser: LienOmUser[],idOd: number,idCollab: number): Observable<LienOmUser[]> {
		//Ajout des participants à la mission
		return this.http.put<LienOmUser[]>(`${environment.baseUrl}/controller/Participant/ajouterParticipants?idOd=${idOd}&idCollab=${idCollab}`,listeLienOmUser);
	}

	/**
	 * Retire un participant de la mission
	 * @param idLienOmuser Identifiant du participant à retirer
	 * @param idOd Mission à laquelle on retire le participant
	 */
	retirerParticipant(idLienOmuser: number,idOd: number): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Participant/retirerParticipant/${idLienOmuser}/${idOd}`);
	}

	/**
	 * Charge la liste des SBT pour la mission
	 *
	 * @param idOd Id de l'OD concerné
	 */
	loadListeSBT(idOd: number): Observable<Result> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/Travel/loadListeSBT/${idOd}`);
	}


	/**
	 * Charge la liste des fournisseurs disponibles pour la prestation
	 *
	 * @param typePresta Type du trajet (avion/train/...)
	 */
	loadFournisseurs(typePresta: string): Observable<Result> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/Travel/loadListeFournisseurs/${typePresta}`);
	}

	/**
	 * Charge la liste des classes disponibles pour la prestation
	 *
	 * @param typePresta Type du trajet (avion/train/...)
	 */
	loadClasses(typePresta: string): Observable<Result> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/Travel/loadListeClasses/${typePresta}`);
	}

	/**
	 * Ouvre la popup de sélection d'un trajet travel
	 *
	 * @param devise                    Devise de l'OD
	 * @param od                        L'OD modifié
	 * @param listeSbt                  Liste des SBT disponibles sur cet OD
	 * @param composant                 Le composant à ouvrir dans la popup (passé en param pour éviter les dépendances circulaires)
	 * @param isSbtDejaSynchro$         Indique si le SBT a déjà été synchronisé ou non (pour lancer la synchro qu'une seule fois)
	 * @param etape                     Etape existante à visualiser ou null
	 * @param hasProposition            Indique si l'OD a au moins une proposition
	 * @param hasEtape                  Indique si l'OD a déjà au moins une étape
	 * @param dvActions                 Segments de WF du DV
	 * @param canModifier               Indique si on a le droit de modifier le formulaire
	 * @param listeSbtParNature         Liste des SBT regroupés par nature
	 * @param isProfilVoyageurValide    Indique si le profil voyageur est valide
	 * @return {MatDialogRef<T, [boolean, number[], number]>}
	 */
	openPopupTravel<T>(devise: string,od: Od,listeSbt: Observable<SynchroSBTConfigUser[]>,listeSbtParNature: Observable<Array<NiveauNature>>,composant: ComponentType<T>,isSbtDejaSynchro$: BehaviorSubject<boolean>,hasProposition: boolean,hasEtape: boolean,dvActions: MapAction,canModifier: boolean,isProfilVoyageurValide: boolean,etape?: SaisieEtapeDTO): MatDialogRef<T,[boolean,number[],number]> {
		//On renvoie la popup du travel
		return this.matDialog.open<T,any,[boolean,number[],number]>(composant,{
			data: {
				devise: devise,
				od: od,
				etape: etape,
				listeSbt: listeSbt,
				listeParNature: listeSbtParNature,
				isSbtDejaSynchro$: isSbtDejaSynchro$,
				hasProposition: hasProposition,
				hasEtape: hasEtape,
				dvActions: dvActions,
				canModifier: canModifier,
				isProfilVoyageurValide
			},
			minWidth: 1000
		});

		//Si le profil voyageur n'est pas valide
		if (!isProfilVoyageurValide) {
			//On parcourt la liste des SBT
			listeSbt.pipe(first()).subscribe(listeSbt => {
				//S'il y a au moins un SBT Online de disponible
				if (listeSbt.some(sbt => sbt.typeAiguillage === TypeAiguillage.ONLINE)) {
					//On lance une alerte pour prévenir que le profil n'est pas valide
					this.toastrService.error(this.translateService.instant('od.voyage.travel.synchroImpossible'));
				}
			})
		}
	}

	/**
	 * Enregistre les étapes
	 *
	 * @param listeEtapes   Liste des étapes à enregistrer
	 * @param idOd          Id de l'OD sur lequel on ajoute les étapes
	 */
	saveListeEtapes(listeEtapes: Array<SaisieEtapeDTO>,idOd: number): Observable<Result> {
		return this.http.put<Result>(`${environment.baseUrl}/controller/Travel/saveListeEtapes/${idOd}`,listeEtapes);
	}

	/**
	 * Chargement des motifs possibles pour un véhicule
	 */
	loadVehiculeMotifs(): Observable<Result> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/Travel/loadVehiculeMotifs`);
	}

	/**
	 * Retourne le résumé des engagements d'un OD
	 *
	 * @param idOd Identifiant de l'OD
	 */
	getEngagementsResume(idOd: number): Observable<EngagementsResume> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/OD/${idOd}/getEngagementsResume`)
			.pipe(first(),map(result => result?.data?.engagementResume));
	}

	/**
	 * Retourne les engagements (frais) d'un OD
	 *
	 * @param idOd Identifiant de l'OD
	 */
	getEngagementsFrais(idOd: number): Observable<EngagementsFrais> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/OD/${idOd}/getEngagementsFrais`)
			.pipe(first(),map(result => result?.data?.engagementsFrais));
	}

	/**
	 * Retourne les engagements (factures) d'un OD
	 *
	 * @param idOd Identifiant de l'OD
	 */
	getEngagementsFacture(idOd: number): Observable<EngagementsFactures> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/OD/${idOd}/getEngagementsFacture`,)
			.pipe(first(),map(result => result?.data?.engagementsFacture));
	}

	/**
	 * Lance le calcul des indemnités
	 *
	 * @param idOd Identifiant de l'OD
	 */
	calculIndemnites(idOd: number): Observable<boolean> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/OD/${idOd}/calculIndemnites`,null)
			.pipe(first(),map(result => result?.data?.result));
	}

	/**
	 * Récupère les indemnités d'un OD
	 *
	 * @param idOd Identifiant de l'OD
	 */
	getIndemnites(idOd: number): Observable<EngagementsIndemnites> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/OD/${idOd}/getIndemnites`)
			.pipe(first(),map(result => result?.data));
	}

	/**
	 * Récupère la liste des détails d'indemnites
	 *
	 * @param idOd Identifiant de l'OD
	 */
	getDetailsIndemnites(idOd: number): Observable<{ listeFeries: Array<Date>,listeRegles: Array<RegleAttribution>,dateMin: Date,dateMax: Date }> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/OD/${idOd}/getDetailsIndemnites`)
			.pipe(first(),map(result => result?.data));
	}

	/**
	 * Enregistre les dégrèvements
	 *
	 * @param idOd Identifiant de l'OD
	 * @param listeDetails Liste des dégrèvements
	 */
	saveDetailsIndemnites(idOd: number,listeDetails: Array<IJDetail>): Observable<Result> {
		return this.http.put<Result>(`${environment.baseUrl}/controller/OD/${idOd}/saveDetailsIndemnites`,listeDetails)
			.pipe(first());
	}

	/**
	 * Modification du coefficient des IJ
	 *
	 * @param idOd Identifiant de l'OD
	 * @param coeffIj Nouveau coefficient
	 */
	changeCoeffIJ(idOd: number,coeffIj: number): Observable<Result> {
		//Ajout des paramètres
		const params: HttpParams = new HttpParams()
			.append('coeffIj',coeffIj.toString());

		//Envoi de la requête HTTP
		return this.http.put<Result>(`${environment.baseUrl}/controller/OD/${idOd}/changeCoeffIJ`,null,{
			params: params
		}).pipe(first());
	}

	/**
	 * Vérifie si le type entité autorise la création du type d'élément demandé
	 *
	 * @param od OD
	 * @param type Type de frais
	 * @param typeEntiteParam Le paramétrage du type entité
	 */
	canCreateElementFrais(od: Od,type: OdTypeFrais,typeEntiteParam: TypeEntiteParamOD): Observable<boolean> {
		switch (type) {
			case OdTypeFrais.frais_prev:
				return of(typeEntiteParam?.fraisPrev && od?.getMapAction()?.actionsSupplementaires?.canOdCreationFraisPrev?.possible);
			case OdTypeFrais.ndf:
				return od?.getMapAction()?.actionsSupplementaires?.canCreationNdf?.possible ?
					this.isUserCanCreateNDF(typeEntiteParam)
					: of(false);
			case OdTypeFrais.avance:
				return of(typeEntiteParam?.demandeAvance && od?.getMapAction()?.actionsSupplementaires?.canOdCreationAvance?.possible);
			default:
				return of(false);
		}
	}

	/**
	 * Vérifie si le type entité autorise la création d'un élément de type facture
	 *
	 * @param od OD
	 * @param type Type de facture
	 * @param typeEntiteParam Le paramétrage du type entité
	 */
	canCreateElementFacture(od: Od,type: OdTypeFacture,typeEntiteParam: TypeEntiteParamOD): boolean {
		switch (type) {
			case OdTypeFacture.facture_prev:
				return typeEntiteParam?.creationFacturePrev && od?.getMapAction()?.actionsSupplementaires?.canOdCreationFacturePrev?.possible;
			case OdTypeFacture.facture:
				return typeEntiteParam?.creationFacture;
			default:
				return false;
		}
	}

	/**
	 * Création d'une note de frais rattachée à la mission
	 *
	 * @param od Mission
	 */
	createNDF(od: Od): Observable<number> {
		//Ajout des paramètres
		let params: HttpParams = new HttpParams()
			.append('idOd',od.idOd.toString());

		//Envoi de la requête HTTP de création
		return this.http.put<Result>(`${environment.baseUrl}/controller/OD/createNDF`,null,{
			params: params
		}).pipe(
			first(),
			map(result => result?.data?.idNdf)
		);
	}

	/**
	 * Affichage d'une NDF
	 *
	 * @param ndf La NDF à afficher
	 */
	showNDF(ndf: { idNDF: number }): Promise<boolean> {
		return this.router.navigate(['NDF',ndf.idNDF]);
	}

	/**
	 * Affichage d'une avance
	 *
	 * @param avance L'avance à afficher
	 */
	showAvance(avance: { idAvance: number }): Promise<boolean> {
		return this.router.navigate(['Avance',avance.idAvance]);
	}

	/**
	 * Enregistrement d'un frais prévisionnel
	 *
	 * @param frais Le frais prévisionnel
	 */
	saveFraisPrev(frais: FraisPrev): Observable<any> {
		//Envoi de la requête HTTP de création
		return this.http.put<Result>(`${environment.baseUrl}/controller/OD/saveFraisPrev`,frais).pipe(
			first(),
			map(result => result?.data?.idFraisPrev)
		);
	}

	/**
	 * Suppression d'un frais prévisionnel
	 *
	 * @param frais Le frais prévisionnel
	 */
	deleteFraisPrev(frais: FraisPrev): Observable<boolean> {
		//Envoi de la requête HTTP de suppression
		return this.http.delete<Result>(`${environment.baseUrl}/controller/OD/deleteFraisPrev/${frais.idFraisPrev}`).pipe(
			first(),
			map(result => {
				return result?.codeErreur === 0;
			})
		);
	}

	/**
	 * Chargement d'un frais prévisionnel
	 *
	 * @param idFraisPrev Identifiant du frais prévisionnel
	 */
	loadFraisPrev(idFraisPrev: number): Observable<FraisPrev> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/OD/loadFraisPrev/${idFraisPrev}`,null).pipe(
			first(),
			map(result => result?.data.fraisPrev)
		);
	}

	/**
	 * Liste de tous les frais (ndf, avance, frais prévisionnel) associés à l'OD
	 *
	 * @param idOd Identifiant de l'OD
	 */
	getListeFrais(idOd: number): Observable<any> {
		return this.http.post<any>(`${environment.baseUrl}/controller/OD/${idOd}/filtreFrais`,null).pipe(
			first()
		);
	}

	/**
	 * Chargement d'une facture prévisionnelle
	 *
	 * @param idFacturePrev Identifiant de la facture prévisionnelle
	 */
	loadFacturePrev(idFacturePrev: number): Observable<FacturePrev> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/OD/loadFacturePrev/${idFacturePrev}`,null).pipe(
			first(),
			map(result => result?.data.facturePrev)
		);
	}

	/**
	 * Liste de toutes les factures (facture, factures prévisionnelles) associées à l'OD
	 *
	 * @param idOd Identifiant de l'OD
	 */
	getListeFactures(idOd: number): Observable<any> {
		return this.http.post<any>(`${environment.baseUrl}/controller/OD/${idOd}/filtreFactures`,null).pipe(
			first()
		);
	}

	/**
	 * Enregistrement d'une facture prévisionnelle
	 *
	 * @param facturePrev La facture prévisionnelle
	 */
	saveFacturePrev(facturePrev: FacturePrev): Observable<any> {
		//Envoi de la requête HTTP de création
		return this.http.put<Result>(`${environment.baseUrl}/controller/OD/saveFacturePrev`,facturePrev).pipe(
			first(),
			map(result => result?.data?.idFacturePrev)
		);
	}

	/**
	 * Suppression d'une facture prévisionnelle
	 *
	 * @param facturePrev La facture prévisionnelle
	 */
	deleteFacturePrev(facturePrev: FacturePrev): Observable<boolean> {
		//Envoi de la requête HTTP de suppression
		return this.http.delete<Result>(`${environment.baseUrl}/controller/OD/deleteFacturePrev/${facturePrev.idFacturePrev}`).pipe(
			first(),
			map(result => result?.codeErreur === 0)
		);
	}

	/**
	 * Charge les infos de saisie des temps pour un OD
	 *
	 * @param idOd Identifiant de l'od concerné
	 * @return la saisie
	 */
	loadSaisieTemps(idOd: number): Observable<SaisieTemps> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/OD/loadSaisieTemps/${idOd}`).pipe(
			first(),
			map(result => result?.data?.saisie));
	}

	/**
	 * Renvoie le nombre d'étapes de type transport, dans l'od
	 *
	 * @param idOd Identifiant de l'od dont on veut compter les étapes
	 * @return le nombre d'étapes dans l'od
	 */
	countEtapesTransport(idOd: number): Observable<Result> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/Travel/countEtapesTransport/${idOd}`).pipe(
			first());
	}

	/**
	 * Enregistre la saisie en paramètre
	 *
	 * @param idOd      Identifiant de l'od concerné
	 * @param saisie    Saisie à enregistrer
	 * @return true si tout s'est bien passé
	 */
	saveSaisieTemps(idOd: number,saisie: SaisieTemps): Observable<Result> {
		return this.http.put<Result>(`${environment.baseUrl}/controller/OD/saveSaisieTemps/${idOd}`,saisie).pipe(
			first());
	}

	/**
	 * Récupère la liste des participants pour lesquels on peut créer/émettre des OD
	 *
	 * @param idOd  Identifiant de l'od concerné
	 * @return {Observable<Result>} Résultat de la requête
	 */
	getListeParticipantsPourCreationOd(idOd: number): Observable<Result> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/Participant/getListeParticipantsPourCreationOd/${idOd}`).pipe(first());
	}


	/**
	 * Ouvre la popup de création/émission des OD pour les participants
	 *
	 * @param listeUsers            Liste des utilisateurs pour lesquels on peut créer des OD
	 * @param isEmissionAutorisee   true si on peut aussi émettre les OD
	 * @return {MatDialogRef<OdParticipantsPopinEmissionComponent, any>} référence de la popup ouverte
	 */
	openCreationParticipantOd(listeUsers: Array<User>,isEmissionAutorisee: boolean): MatDialogRef<OdParticipantsPopinEmissionComponent,MapCreationParticipantType> {
		return this.matDialog.open<OdParticipantsPopinEmissionComponent,any,MapCreationParticipantType>(OdParticipantsPopinEmissionComponent,{
				data: {
					listeUsers: listeUsers,
					isEmissionAutorisee: isEmissionAutorisee
				},
				minWidth: 600,
				maxWidth: 800
			}
		);
	}

	/**
	 * Chargement d'une étape
	 *
	 * @param idEtape Identifiant de l'étape à charger
	 *
	 * @returns {Observable<SaisieEtapeDTO>} L'étape voulue
	 */
	loadEtape(idEtape: number): Observable<SaisieEtapeDTO> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/Travel/loadEtape/${idEtape}`).pipe(
			first(),
			mapToObject<SaisieEtapeDTO>('etape',this.toastrService,this.translateService));
	}

	/**
	 * Méthode d'ouverture de la page de réservation ONLINE
	 *
	 * @param listeIdEtapesOnline   Liste des ID de(s) étape(s) (1 pour aller, 2 pour aller/retour) de la réservation
	 * @param idOd                  ID de l'OD concerné
	 * @param idSbtConfig           ID du SBT utilisé pour la réservation
	 *
	 * @return {Observable<void>} Indique quand la page de l'OD doit être rafraichie
	 */
	goToSbtOnline(listeIdEtapesOnline: number[],idOd: number,idSbtConfig: number): Observable<void> {
		let needRefresh = new Subject<void>();

		let waitRef = this.pleaseWaitService.show();

		this.http.post<Result>(`${environment.baseUrl}/controller/Travel/goToSbtOnline`,listeIdEtapesOnline)
			.pipe(first(),mapToObject<AccesSBT>('acces',this.toastrService,this.translateService))
			.subscribe(acces => {
				waitRef.close();

				this.openSBT(acces,idOd,idSbtConfig,needRefresh);
			});

		//On renvoie l'observable qui va indiquer quand on doit refresh la page de l'OD
		return needRefresh.asObservable();
	}

	/**
	 * Méthode de récupération des infos pour se connecter au portail du SBT<br>
	 * Suivi de la connexion au dit SBT
	 *
	 * @param idDossier Identifiant du dossier à consulter
	 * @param idOd      Identifiant de l'OD en cours
	 */
	goToPortail(idDossier: number,idOd: number): Observable<void> {
		let needRefresh = new Subject<void>();

		let waitRef = this.pleaseWaitService.show();

		//On récupère les informations de connexion
		this.http.get<Result>(`${environment.baseUrl}/controller/Travel/goToPortail/${idDossier}`)
			.pipe(first())
			.subscribe(result => {
				waitRef.close();

				//On connecte au SBT
				this.openSBT(result.data.acces,idOd,result.data.idSbtUsed,needRefresh);
			});

		//On renvoie un observable pour savoir quand refresh la page
		return needRefresh.asObservable();
	}

	/**
	 * Ouverture de la page du SBT
	 *
	 * @param acces         Informations de connexion
	 * @param idOd          Identifiant de l'OD en cours
	 * @param idSbtConfig   Identifiant du SBT a accéder
	 * @param needRefresh   Observable indiquant quand il faut refresh la page
	 */
	private openSBT<T>(acces: AccesSBT,idOd: number,idSbtConfig: number,needRefresh: Subject<T>) {
		let waitRef;

		//Si on a bien récupéré un accès
		if (!!acces && !!acces.url && !!acces.modePost) {
			//On ouvre un nouvel onglet blanc
			let winSBT = window.open('','popup_sbt');

			//Message de confirmation
			let confirmRef = this.confirmService.showConfirmDialog(
				this.translateService.instant('od.voyage.travel.reservationEnCours'),
				{type: 'ok',disableClose: true,buttons: {confirm: {libelle: 'global.actions.confirmer'}}}
			);

			//Après la confirmation, on refresh la page
			confirmRef.afterClosed().subscribe((confirm) => {
				if (confirm) {
					//On remet une popup de la patience
					waitRef = this.pleaseWaitService.show();

					this.http.post<Result>(`${environment.baseUrl}/controller/Travel/retrievePropositionNew/${idOd}`,idSbtConfig).pipe(first()).subscribe((result) => {
						if (result.codeErreur !== 0) {
							this.toastrService.error(this.translateService.instant('od.voyage.travel.echecReservation'));
						}

						waitRef.close();
						needRefresh.next();
						needRefresh.complete();
					});
				} else {
					needRefresh.next();
					needRefresh.complete();
				}
			});

			//On continue dans un timeout pour s'assurer que le winSBT est bien chargé
			setTimeout(() => {
				//Accès en mode URL
				if (acces.modePost === ModePost.URL) {
					//Ouverture directe de l'URL dans l'onglet
					winSBT.document.location.href = acces.url + (acces.parametres ? '?' + acces.parametres : '');
				} else if (acces.modePost === ModePost.HTML) {
					//Ecriture du flux dans la page (dans ce cas url contient du texte HTML)
					winSBT.document.write(acces.url);

					//Validation du formulaire (on soumet le formulaire contenu dans acces.url pour être redirigé vers le SBT)
					winSBT.document.forms[0].submit();
				} else {
					//Les autres cas d'accès ne sont plus supportés (Au dire de l'équipe TravelHub), donc on ferme tout
					if (winSBT) {
						winSBT.close();
					}
					confirmRef.close(false);

					//Et on met un message d'erreur
					this.toastrService.error(this.translateService.instant('od.voyage.travel.erreurPortail'));
				}
			});
		} else {
			//Si on a pas d'accès, c'est une erreur
			this.toastrService.error(this.translateService.instant('od.voyage.travel.erreurPortail'));

			//On demande quand même un refresh pck on a enregistré les étapes
			needRefresh.next();
			needRefresh.complete();
		}
	}

	/**
	 * Chargement des natures d'une proposition
	 *
	 * @param idProposition Identifiant de la proposition concernée
	 * @return {Observable<NatureVoyage[]>} Liste des natures associées
	 */
	loadNatures(idProposition: number): Observable<NatureVoyage[]> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Nature/loadNatures/${idProposition}`,null)
			.pipe(mapToObject<NatureVoyage[]>('listeNatures',this.toastrService,this.translateService));
	}

	/**
	 * Sélection d'une proposition
	 *
	 * @param idProposition Identifiant de la proposition choisie
	 */
	choisirProposition(idProposition: number): Observable<void> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Travel/choisirProposition/${idProposition}`,null)
			.pipe(first(),mapToObject<void>(null,this.toastrService,this.translateService));
	}

	/**
	 * Demande de modification des étapes
	 *
	 * @param idOd  Identifiant de l'OD concerné
	 */
	modifierProposition(idOd: number): Observable<void> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Travel/modifierProposition/${idOd}`,null)
			.pipe(first(),mapToObject<void>(null,this.toastrService,this.translateService));
	}

	/**
	 * Rejet de toutes les propositions
	 *
	 * @param idOd  Identifiant de l'OD concerné
	 * @param motif Motif de rejet
	 */
	rejeterTout(idOd: number,motif?: string): Observable<void> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Travel/rejeterTout/${idOd}`,motif)
			.pipe(first(),mapToObject<void>(null,this.toastrService,this.translateService));
	}

	/**
	 * Supprime l'étape
	 *
	 * @param idEtape Identifiant de l'étape à supprimer
	 */
	deleteEtape(idEtape: number): Observable<void> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Travel/deleteEtape/${idEtape}`)
			.pipe(first(),mapToObject<void>(null,this.toastrService,this.translateService));
	}

	/**
	 * Fait un dégrèvement en masse
	 *
	 * @param idOd Id de l'od concerné
	 * @param isUsed true si on dégrève, false si on rétablit les indemnités
	 * @param dateDebut Date de début pour le filtre des dégrèvements
	 * @param dateFin Date de fin pour le filtre des dégrèvements
	 * @param listeIndemnitesSelectionnees Liste des règles concernées par le dégrèvement
	 */
	doDegrevementMasse(idOd: number,isUsed: boolean,dateDebut: moment.Moment,dateFin: moment.Moment,listeIndemnitesSelectionnees: Array<RegleAttribution>): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/OD/${idOd}/doDegrevementMasse`,{
			dateDebut: dateDebut,
			dateFin: dateFin,
			listeIndemnitesSelectionnees: listeIndemnitesSelectionnees,
			used: isUsed
		}).pipe(first());
	}

	/**
	 * Annule le choix d'une proposition
	 *
	 * @param idProposition Proposition à ne plus choisir
	 */
	annulerChoixProposition(idProposition: number): Observable<void> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Travel/annulerChoixProposition/${idProposition}`,null)
			.pipe(first(),mapToObject<void>(null,this.toastrService,this.translateService));
	}

	/**
	 * Charge les infos de l'onglet voyage
	 *
	 * @param idOd Id de l'OD concerné
	 */
	loadVoyage(idOd: number): Observable<Result> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/Travel/loadVoyage/${idOd}`);
	}

	/**
	 * Vérifie si le paramétrage du type entité autorise la création d'une NDF sur un OD pour un utilisateur
	 *
	 * @param typeEntiteParamOD Paramétrage du type entité pour la portée OD
	 */
	isUserCanCreateNDF(typeEntiteParamOD: TypeEntiteParamOD): Observable<boolean> {
		//Si le paramétrage du type entité pour les OD n'autorise pas la création de NDF inutile d'aller plus loin
		if (!typeEntiteParamOD.creationNDF) {
			//Pas autorisé
			return of(false);
		}

		//Chargement du type entité pour la portée NF
		this.store.dispatch({
			type: typeEntiteActions.FIND_TYPE_ENTITE,
			payload: {
				idTypeEntite: typeEntiteParamOD.idTypeEntite,
				portee: TypePortee.NF
			}
		});

		//Récupération de l'utilisateur connecté
		return this.store.select(state => state.session.user)
			.pipe(first())
			.mergeMap(user => {
				//Récupération du type entité de la NDF
				return this.store.select(state => state.typeEntite[TypePortee.NF])
					//On attend que l'objet dans le store soit bien pour l'identifiant du type entité en cours (le retour du dispatch n'est potentiellement pas terminé et pour autant on a déjà une valeur dans le store correspondent à un autre type entité :/)
					.pipe(first(val => val?.idTypeEntite === typeEntiteParamOD.idTypeEntite))
					.mergeMap(typeEntite => {
						//Constitution de la liste des identifiants des rôles de l'utilisateur
						const listeIdsRoleUser: Array<number> = user?.roles?.roles?.map(role => role.idRole) ?? [];

						//Vérification de la restriction des rôles pour la portée de l'objet à créer
						const isValid = !typeEntite.typeEntiteParam.listeRole || typeEntite.typeEntiteParam.listeRole.length === 0 //Pas de restriction définie
							|| typeEntite.typeEntiteParam.listeRole.some(idRole => listeIdsRoleUser.includes(idRole)); //L'utilisateur possède au moins l'un des rôles restreints par le type entité

						//Retour de la valeur
						return of(isValid);
					});
			});

	}

	/**
	 * Récupération des pays à risque liés à l'OD
	 *
	 * @param idOd Id de l'OD concerné
	 */
	getPaysRisque(idOd: number) {
		return this.http.post<OdPaysRisque[]>(`${environment.baseUrl}/controller/OD/${idOd}/getPaysRisque`, null).pipe(first());
	}

	/**
	 * Recalcul des pays à risque liés à l'OD
	 *
	 * @param idOd Id de l'OD concerné
	 */
	updatePaysRisque(idOd: number) {
		return this.http.post<Result>(`${environment.baseUrl}/controller/OD/${idOd}/updateOdPaysRisque`,null).pipe(first());
	}
}


/** Type de données des accès au SBT */
export type AccesSBT = {
	url: string,
	parametres: string,
	modePost: ModePost
}

/** Type d'un pays à risque lié à un od */
export type OdPaysRisque = {
	code2: string,
	libelle: string,
	risque: number
}

/** Type d'appel du SBT */
export enum ModePost {
	URL = 'URL',
	FORM = 'FORM',
	HTML = 'HTML'
}
