import {NgModule} from '@angular/core';

import {ShareModule} from '@share/share.module';
import {WorkflowModule} from '../workflow/workflow.module';
import {OdListComponent} from './od-list/od-list.component';
import {OdListItemComponent} from './od-list/od-list-item/od-list-item.component';
import {ODComponent} from './detail/od.component';
import {ODGeneralitesComponent} from './detail/generalite/od-generalites.component';
import {OdGeneraliteFaitGenerateurComponent} from "./detail/generalite/fait-generateur/od-generalite-fait-generateur.component";
import {OdGeneralitesSaisieTempsComponent} from "./detail/generalite/saisie-temps/od-generalites-saisie-temps.component";
import {ODFraisComponent} from './detail/frais/od-frais.component';
import {ODVoyageComponent} from './detail/voyage/od-voyage.component';
import {ODCreationComponent} from './creation/od-creation.component';
import {ODCreationItemComponent} from './creation/od-creation-item.component';
import {ODVoyageItemComponent} from './detail/voyage/voyage/od-voyage-item.component';
import {ODPropositionItemComponent} from './detail/voyage/propositions/od-proposition-item.component';
import {ODPropositionnatureItemComponent} from './detail/voyage/propositions/od-proposition-nature-item.component';
import {OdPropositionDossierItemComponent} from "./detail/voyage/propositions/dossier/od-proposition-dossier-item.component";
import {ODService} from './od.service';
import {AnalytiqueModule} from '../analytique/analytique.module';
import {ReferentielExterneModule} from '../referentiel-externe/referentiel-externe.module';
import {DocumentModule} from '../document/document.module';
import {LieuModule} from "../lieu/lieu.module";
import {PopinMontant} from "./detail/voyage/propositions/montant/od-proposition-montant-popin.component";
import {OdFraisIndemnitesComponent} from "./detail/frais/indemnites/od-frais-indemnites.component";
import {OdFraisFraisComponent} from "./detail/frais/frais/od-frais-frais.component";
import {OdFraisFraisItemComponent} from "./detail/frais/frais/od-frais-frais-item.component";
import {OdFraisAddComponent} from "./detail/frais/frais/creation/od-frais-add.component";
import {OdFraisPrevisionnelAddComponent} from "./detail/frais/frais/creation/frais-previsionnel/od-frais-previsionnel-add.component";
import {OdFraisFacturesComponent} from "./detail/frais/factures/od-frais-factures.component";
import {OdFraisFacturesItemComponent} from "./detail/frais/factures/od-frais-factures-item.component";
import {OdFacturePrevisionnelleAddComponent} from "./detail/frais/factures/creation/facture-previsionnelle/od-facture-previsionnelle-add.component";
import {OdEngagementPipe} from "./detail/engagements/od-engagement.pipe";
import {OdEngagementsComponent} from "./detail/engagements/od-engagements.component";
import {OdEngagementsCardComponent} from "./detail/engagements/commun-card/od-engagements-card.component";
import {OdEngagementsResumeComponent} from "./detail/engagements/resume/od-engagements-resume.component";
import {OdEngagementsFacturesComponent} from "./detail/engagements/factures/od-engagements-factures.component";
import {OdEngagementsFraisComponent} from "./detail/engagements/frais/od-engagements-frais.component";
import {OdEngagementsIndemnitesComponent} from "./detail/engagements/indemnites/od-engagements-indemnites.component";
import {OdEngagementsIndemnitesItemComponent} from "./detail/engagements/indemnites/od-engagements-indemnites-item.component";
import {OdEngagementsIndemnitesDegrevementsComponent} from "./detail/engagements/indemnites/degrevements/od-engagements-indemnites-degrevements.component";
import {OdEngagementsIndemnitesDegrevementsItemComponent} from "./detail/engagements/indemnites/degrevements/od-engagements-indemnites-degrevements-item.component";
import {OdEngagementsIndemnitesHistoriqueComponent} from "./detail/engagements/indemnites/historique/od-engagements-indemnites-historique.component";
import {OdParticipantsPopinEmissionComponent} from "./detail/voyage/participants/popin-emission/od-participants-popin-emission.component";
import {OdParticipantPopinEmissionItemComponent} from "./detail/voyage/participants/popin-emission/item/od-participant-popin-emission-item.component";
import {ODParticipantsListComponent} from "./detail/voyage/participants/od-participants-list.component";
import {OdVoyageTravelPopupVoyageComponent} from "./detail/voyage/voyage/travel/popup/voyage/od-voyage-travel-popup-voyage.component";
import {OdVoyageTravelPopupTrajetComponent} from "./detail/voyage/voyage/travel/popup/trajet/od-voyage-travel-popup-trajet.component";
import {OdVoyageTravelPopupComponent} from "./detail/voyage/voyage/travel/popup/od-voyage-travel-popup.component";
import {FicheCollaborateurModule} from '../fiche-collaborateur/fiche-collaborateur.module';
import {MatStepperModule} from "@angular/material/stepper";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatDividerModule} from "@angular/material/divider";
import {ZoneUtilisateurModule} from '../zone-utilisateur/zone-utilisateur.module';
import {ParticipantsModule} from "../participants/participants.module";
import {BudgetModule} from "../budget/budget.module";
import {TriListeSbtPipe} from "./detail/voyage/voyage/travel/popup/voyage/tri-liste-sbt.pipe";
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {OdDegrevementsMasseComponent} from "@components/od/detail/engagements/indemnites/degrevements/masse/od-degrevements-masse.component";
import {ProprietaireModule} from "@share/component/proprietaire/proprietaire.module";
import {AdminWorkflowModule} from "@components/admin/workflow/admin-workflow.module";
import {AdminOutilsModule} from "@share/component/outils/admin-outils.module";

@NgModule({
    imports: [ShareModule, WorkflowModule, AnalytiqueModule, ReferentielExterneModule, DocumentModule, LieuModule, MatStepperModule, MatExpansionModule, MatDividerModule, FicheCollaborateurModule, ZoneUtilisateurModule, ParticipantsModule, BudgetModule, PreviewAlerteModule, ProprietaireModule, AdminWorkflowModule,AdminOutilsModule],
    declarations: [OdListComponent, OdListItemComponent, ODComponent, ODCreationComponent, ODCreationItemComponent, ODGeneralitesComponent, ODFraisComponent, ODVoyageComponent, ODVoyageItemComponent, ODPropositionItemComponent, ODPropositionnatureItemComponent, PopinMontant, OdFraisFacturesComponent, OdFraisIndemnitesComponent, OdFraisFacturesItemComponent,
        OdFacturePrevisionnelleAddComponent, OdFraisFraisComponent, OdFraisFraisItemComponent, OdFraisAddComponent, OdFraisPrevisionnelAddComponent, OdVoyageTravelPopupVoyageComponent, OdVoyageTravelPopupTrajetComponent,
        OdEngagementsComponent, OdEngagementPipe, OdEngagementsCardComponent, OdEngagementsResumeComponent, OdEngagementsFacturesComponent, OdEngagementsFraisComponent, OdVoyageTravelPopupComponent,
        OdEngagementsIndemnitesComponent, OdEngagementsIndemnitesItemComponent, OdEngagementsIndemnitesDegrevementsComponent, OdEngagementsIndemnitesDegrevementsItemComponent, OdEngagementsIndemnitesHistoriqueComponent,
        OdGeneraliteFaitGenerateurComponent, OdGeneralitesSaisieTempsComponent, OdGeneralitesSaisieTempsComponent, OdParticipantsPopinEmissionComponent, OdParticipantPopinEmissionItemComponent, ODParticipantsListComponent,
        OdPropositionDossierItemComponent, TriListeSbtPipe, OdDegrevementsMasseComponent
    ],
    entryComponents: [OdListItemComponent, ODCreationItemComponent],
    exports: [
        OdListComponent
    ],
    providers: [ODService, OdEngagementPipe, TriListeSbtPipe]
})
export class ODModule {
}
