<div class="m-l-20 m-r-20">
    <div class="alert clickable d-flex flex-row align-items-center justify-content-center m-t-20" 
            [fileUploader]="fileUploader" [settings]="settings" [idObjet]="idObjet" (onFilesDrop)="onFilesDrop($event)" (onAllItemsAdded)="onAllItemsAdded($event)">
        <i class="zmdi zmdi-hc-3x zmdi-download m-r-20"></i>
        <strong [translate]="title"></strong>
    </div>
    <div class="row">
        <div class="col-md-2" *ngFor="let file of fileUploader.queue; let idxFile = index;">
            <div class="thumbnail">
                <div class="content">
                    <thumb [file]="file"></thumb>
                </div>
                <mat-progress-bar mode="determinate" color="primary" [value]="file.progress | async"></mat-progress-bar>
                <div class="caption">
                    <h4>{{ file.name }}</h4>
                </div>
                <div *ngIf='file?.isUploaded' class="message-wrapper">
                    <i *ngIf="!file?.isKo" class="message-icone material-icons-outlined">check_circle</i>
                    <i *ngIf="file?.isKo" class="error-icone material-icons-outlined">highlight_off</i>
                </div>
                <div class="actions-wrapper">
                    <span class="actions">
                        <button *ngIf="!file?.isKo" mat-icon-button color="warn" (click)="fileUploader.removeItem(idxFile)">
                            <mat-icon>close</mat-icon>
                        </button>
                        <button *ngIf='!file?.isUploaded' mat-icon-button color="primary" (click)="uploadItem(idxFile)">
                            <mat-icon>done</mat-icon>
                        </button>
                        <!-- Si le fichier a été uploaded mais que la deuxième étape est ko on peut retenter la deuxième étape -->
                        <button *ngIf='file?.isUploaded && file?.isKo' mat-icon-button color="primary" (click)="reuploadItem(idxFile, file)">
                            <mat-icon>refresh</mat-icon>
                        </button>
                        <button *ngIf='file?.isUploaded && file?.isKo' mat-icon-button color="primary" (click)="deleteItem(idxFile, file)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
