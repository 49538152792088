import {Component,Input,OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../domain/appstate';

import {ListViewItem} from '../../../../domain/common/list-view/list-view-item';
import {ListeOmp} from '../../../../domain/omp/liste-omp';
import {TypeProfil} from '../../../../domain/user/user';
import {environment} from '../../../../environments/environment';
import {AlerteComponent} from '../../../workflow/alerte.component';
import {OMPService} from "../../omp.service";
import {first} from "rxjs/operators";

@Component({
    host: {'data-test-id': 'omp-list-item'},
    templateUrl: './omp-list-item.component.html'
})
export class OmpListItemComponent extends ListViewItem<ListeOmp> implements OnInit {
    /** Elément courant */
    @Input() data: ListeOmp;

    /** Profil de l'utilisateur connecté **/
    fonction: TypeProfil;

    /** Accès à l'enum dans la vue */
    readonly TypeProfil = TypeProfil;

    /** URL de base */
    baseUrl: string = environment.baseUrl;

    /**
     * Constructeur
     */
    constructor(protected ompService: OMPService,
                protected store: Store<AppState>,
                protected matDialog: MatDialog) {
        //Héritage
        super();
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Sélection du profil de l'utilisateur
        this.store.select(state => state.session.user.fonction).pipe(first()).subscribe(fonction => this.fonction = fonction);
    }

    /**
     * Ouverture d'un élément
     */
    navigateTo(): void {
        //Navigation vers l'élément
        this.ompService.navigateToOMP(this.data?.getId());
    }

    /**
     * Affichage de la popup de la liste des alertes
     */
    showListeAlertes(): void {
        //Affichage de la popup de la liste des alertes
        this.matDialog.open(AlerteComponent,{
            data: {
                alertes: this.data.listeAlertes.listeAlertes
            }
        });
    }

    /** Getter pour le mode Collaborateur */
    get isCollabMode(): boolean {
        return this.fonction == TypeProfil.COLLABORATEUR;
    }
}
