import {Component, Input} from '@angular/core';
import {FloatingButtonAction, TypeAction} from './floating-button';
import { BehaviorSubject } from "rxjs";

@Component({
    selector: 'floating-button',
    templateUrl: './floating-button.component.html',
    styleUrls: ['./floating-button.component.scss']
})
export class FloatingButtonComponent {
    /** Liste des actions */
    @Input() listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Action en cours **/
    @Input() isPending: boolean;

    /** Liste des types d'action **/
    TypeAction: typeof TypeAction = TypeAction;

    /** true si on doit afficher des border sur les boutons */
    isShowBorder: boolean = false;

    /** Indicateur d'ouverture du menu **/
    isOpened: boolean = false;

    /**
     * Exécution de l'action
     */
    doAction(action: FloatingButtonAction) {
        //Vérification que le bouton n'est pas désactivé et qu'aucune action est en cours avant de déclencher l'action
        if (!this.isDisabled(action) && !this.isPending) {
            //Déclenchement de l'action
            action.doAction();
        }
    }

    /**
     * Récupération du filtre
     */
    getFilterFor(typeAction: TypeAction) {
        //Vérification du type et de la visibilité de l'élément
        return (item: FloatingButtonAction) => item.type == typeAction && (!item.isVisible || item.isVisible());
    }

    /**
     * Vérifie si le bouton est désactivé
     *
     * @param action Action du bouton
     */
    isDisabled(action: FloatingButtonAction): boolean {
        return action.isDisabled && action.isDisabled();
    }

    /**
     * Retourne soit un tooltip particulier si défini, soit le libellé de l'action sinon.
     *
     * @param action Action du bouton
     */
    getTooltip(action: FloatingButtonAction): string {
        return action.tooltip ? action.tooltip() : (action.message ? action.message() : action.libelle);
    }
} 