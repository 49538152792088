import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {first} from "rxjs/operators";
import {Orga} from "@domain/admin/entreprise/analytique/orga";
import {Dossier} from "@domain/admin/entreprise/analytique/dossier";
import {FiltreAxeAnalytiqueDTO} from "@domain/admin/entreprise/analytique/FiltreAxeAnalytiqueDto";
import {Axe5} from "@domain/admin/entreprise/analytique/axe5";
import {Axe6} from "@domain/admin/entreprise/analytique/axe6";
import {EntrepriseParametres} from "@domain/admin/entreprise/analytique/parametres";
import {Enveloppe} from '@domain/admin/entreprise/analytique/enveloppe';
import {Analytique} from "@components/analytique/analytique";

/**
 * Service du menu Analytique
 */
@Injectable()
export class AnalytiqueService {
    /** Liste des services dépliés */
    private listeUnfoldedOrga = new Set<number>();

    /**
     * Constructeur
     *
     * @param http pour permettre de réaliser les requêtes
     */
    constructor(private http: HttpClient) { }

    /**
     * Retourne True si le service est déplié, False sinon
     *
     * @param idOrga Identifiant de l'orga
     */
    isOrgaUnfolded(idOrga: number): boolean {
        return this.listeUnfoldedOrga.has(idOrga);
    }

    /**
     * Met à jour la liste des services dépliés en fonction des paramètres
     *
     * @param idOrga identifiant de l'orga
     * @param isUnfolded True si déplié, False sinon
     */
    setOrgaFoldedUnfolded(idOrga: number,isUnfolded: boolean): void {
        //Si l'orga est déplié
        if (isUnfolded) {
            //Ajout à la liste pour mémorisation
            this.listeUnfoldedOrga.add(idOrga);
        } else {
            //Retrait de la liste mémorisée
            this.listeUnfoldedOrga.delete(idOrga);
        }
    }

    /**
     * Récupération des services enfants d'un service
     *
     * @param idOrga Identifiant du service dont on veut récupérer les sous services
     */
    getChild(idOrga: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Orga/showSousService?id_orga=` + idOrga, null).pipe(first());
    }

    /**
     * Récupération des données d'un service
     *
     * @param idOrga Identifiant du service dont on veut récupérer les informations
     */
    getOrga(idOrga: number): Observable<Result> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/Orga/getOrga/` + idOrga).pipe(first());
    }

    /**
     * Récupération du nombre d'utilisateurs affectés à un service donné
     *
     * @param idOrga Identifiant du service voulu
     */
    getNbUsers(idOrga: number): Observable<Result> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/Orga/getNbUsers/` + idOrga).pipe(first());
    }

    /**
     * Affichage des sous services d'un service donné
     *
     * @param idOrga Identifiant du service voulu
     */
    showSousService(idOrga: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Orga/showSousService?id_orga=` + idOrga, null).pipe(first());
    }

    /**
     * Enregistrement d'un service en base
     *
     * @param orga Service à enregistrer en base
     * @param logo fichier logo du service, peut être null
     */
    saveOrga(orga: Orga,logo: File): Observable<Result> {
        //Initialisation
        const formData: FormData = new FormData();

        //Ajout du logo
        if (logo) {
            formData.append('logo',logo);
        }
        formData.append('orga',new Blob([JSON.stringify(orga)],{type: "application/json"}));

        //Upload du fichier et sauvegarde des données
        return this.http.post<Result>(`${environment.baseUrl}/controller/Orga/saveOrga`,formData).pipe(first());
    }

    /**
     * Suppression d'un service en base
     *
     * @param idOrga Identifiant du service à supprimer
     */
    deleteOrga(idOrga: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Orga/deleteOrga?id_orga=` + idOrga, null).pipe(first());
    }

    /**
     * Récupération de tous les services
     */
    getAll(): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Orga`, null).pipe(first());
    }

    /**
     * Récupération d'un code projet
     *
     * @param idCodeProjet Identifiant du code projet à récupérer
     */
    getCodeProjet(idCodeProjet: number): Observable<Result> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/Analytique/consultDossier/` + idCodeProjet).pipe(first());
    }

    /**
     * Enregistrement en base d'un code projet
     *
     * @param dossier Code projet à enregistrer
     */
    saveCodeProjet(dossier: Dossier): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Analytique/saveDossierBody`, dossier).pipe(first());
    }

    /**
     * Suppression d'un code projet
     *
     * @param idDossier Identifiant du code projet à supprimer
     */
    deleteCodeProjet(idDossier: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Analytique/deleteDossier?id_doss=` + idDossier, []).pipe(first());
    }

    /**
     * Récupération de la liste des filtres pour un code projet
     *
     * @param dossier Code projet dont on veut récupérer les filtres
     */
    getListeFiltre(dossier: Dossier): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Analytique/getListeFiltreDossier`, dossier).pipe(first());
    }

    /**
     * Récupération de la liste des filtres pour un référentiel axe 5
     *
     * @param axe5 Axe 5 dont on veut récupérer les filtres
     */
    getListeFilteAxe5(axe5: Axe5): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Analytique/getListeFiltreAxe5`, axe5).pipe(first());
    }

    /**
     * Récupération de la liste des filtres pour un référentiel axe 6
     *
     * @param axe6 Axe 6 dont on veut récupérer les filtres
     */
    getListeFilteAxe6(axe6: Axe6): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Analytique/getListeFiltreAxe6`, axe6).pipe(first());
    }

    /**
     * Récupération de la liste des filtres disponibles
     *
     * @param typeFiltre Type des filtres à récupérer (Mot-clef, catégorie, ...)
     * @param valeur Valeur rentrée par l'utilisateur, pour filtrer la liste des filtres
     * @param idAxe Identifiant du code projet
     */
    getListeAddFiltre(typeFiltre: number, valeur: string, idAxe: number): Observable<Result> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/Analytique/getListeAddFiltre/` + typeFiltre + `/` + idAxe).pipe(first());
    }

    /**
     * Enregistrement en base d'un filtre
     *
     * @param filtre Filtre à enregistrer en base (contenant l'identifiant du code projet auquel il est attaché)
     * @param typeAxe Type de l'axe sélectionné (Codes projet, Axe 5, Axe 6, ...)
     */
    saveFiltre(filtre: FiltreAxeAnalytiqueDTO, typeAxe: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Analytique/saveFiltre?type_axe=` + typeAxe, filtre).pipe(first());
    }

    /**
     * Suppression d'un filtre en base
     *
     * @param filtre Filtre à supprimer en base (contenant l'identifiant du code projet auquel il est attaché)
     * @param typeAxe Type de l'axe sélectionné (Codes projet, Axe 5, Axe 6, ...)
     */
    deleteFiltre(filtre: FiltreAxeAnalytiqueDTO, typeAxe: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Analytique/deleteFiltre?type_axe=` + typeAxe, filtre).pipe(first());
    }

    /**
     *  Récupération d'une enveloppe
     *
     * @param idEnveloppe Identifiant de l'enveloppe à récupérer
     */
    getEnveloppe(idEnveloppe: number): Observable<Result> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/Budget/getEnveloppe/` + idEnveloppe).pipe(first());
    }

    /**
     * Récupération d'une enveloppe consommation
     *
     * @param idEnveloppe Identifiant de l'enveloppe à récupérer
     */
    getEnveloppeConsommation(idEnveloppe: number): Observable<Result> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/Budget/getEnveloppeConsommation/` + idEnveloppe).pipe(first());
    }

    /**
     * Enregistrement en base de l'enveloppe
     *
     * @param enveloppe Enveloppe à enregistrer en base
     */
    saveEnveloppe(enveloppe: Enveloppe): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Budget/saveEnveloppe`, enveloppe).pipe(first());
    }

    /**
     * Enregistrement en base de l'analytique
     * @param analytique
     */
    saveAnalytique(analytique: Analytique): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Budget/saveAnalytique`,analytique).pipe(first());
    }

    /**
     * Suppression d'une enveloppe
     *
     * @param idEnveloppe Identifiant de l'enveloppe à supprimer
     */
    deleteEnveloppe(idEnveloppe: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Budget/deleteEnveloppe/?idEnveloppe=` + idEnveloppe, null).pipe(first());
    }

    /**
     * Récupération d'un référentiel axe 5
     *
     * @param idAxe5 Identifiant de l'axe 5 à récupérer
     */
    getAxe5(idAxe5: number): Observable<Result> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/Analytique/consultAxe5/` + idAxe5).pipe(first());
    }

    /**
     * Enregistrement en base du référentiel axe 5
     *
     * @param axe5 Axe 5 à enregistrer en base
     */
    saveAxe5(axe5: Axe5): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Analytique/saveAxe5Body`,axe5);
    }

    /**
     * Suppression d'un référentiel axe 5
     *
     * @param idAxe5 Identifiant de l'axe 5 à supprimer
     */
    deleteAxe5(idAxe5: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Analytique/deleteAxe5?id_axe_5=` + idAxe5,null);
    }

    /**
     * Récupération d'un référentiel axe 6
     *
     * @param idAxe6 Identifiant de l'axe 6 à récupérer
     */
    getAxe6(idAxe6: number): Observable<Result> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/Analytique/consultAxe6/` + idAxe6).pipe(first());
    }

    /**
     * Enregistrement en base du référentiel axe 6
     *
     * @param axe6 Axe 6 à enregistrer en base
     */
    saveAxe6(axe6: Axe6): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Analytique/saveAxe6Body`,axe6);
    }

    /**
     * Suppression d'un référentiel axe 6
     *
     * @param idAxe6 Identifiant de l'axe 6 à supprimer
     */
    deleteAxe6(idAxe6: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Analytique/deleteAxe6?id_axe_6=` + idAxe6,null);
    }

    /**
     * Chargement des paramètres analytiques entreprise
     *
     */
    loadGeneralites(): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Analytique/loadGeneralites`, null).pipe(first());
    }

    /**
     * Sauvegarde des paramètres analytiques entreprise
     *
     */
    saveAnalytiqueParameters(parametres: EntrepriseParametres): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Analytique/saveAnalytiqueParameters`, parametres).pipe(first());
    }
}
