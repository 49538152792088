import {Component,OnInit,ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {VehiculeService} from "./vehicule.service";
import {filter,finalize,first} from "rxjs/operators";
import {ActivatedRoute,Router} from "@angular/router";
import {Status,Vehicule} from "@domain/vehicule/vehicule";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {SettingsVehiculeState} from "@domain/settings/settings";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {VehiculeRejetComponent} from "./vehicule-rejet.component";
import {VehiculeHistoriqueDistanceComponent} from "./vehicule-historique-distance.component";
import * as settingsActions from "../../reducers/settings";
import {TypePortee} from "@domain/workflow/workflow";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {PuissanceFiscale} from "@domain/vehicule/puissancefiscale";
import {TypeProfil,User} from "@domain/user/user";
import {ListeAlertes} from "@domain/common/alerte/listeAlertes";
import {VehiculeDocumentsComponent} from "./document/vehicule-documents.component";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import * as moment from "moment";
import {CustomInputComponent} from "@share/component/custom-input/custom-input.component";
import {BehaviorSubject} from "rxjs";
import {Onglets} from "@components/admin/entreprise/utilisateurs/user-detail/user-detail.component";

/**
 * Composant de gestion de véhicule
 */
@Component({
	host: {'data-test-id': 'vehicule'},
	templateUrl: './vehicule.component.html'
})
export class VehiculeComponent implements OnInit {
	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Indicateur de modification possible */
	canModifier: boolean = false;

	/** Possibilite de voir la valeur non obfusquee */
	canReadClear: boolean = false;

	/** Enregistrement en cours */
	isSaving: boolean = false;

	/** Liste des onglets */
	listeTabItems: Array<PageHeaderItem>;

	/** Onglet sélectionné */
	selectedItem: PageHeaderItem = null;

	/** Vehicule */
	vehicule: Vehicule;

	/** Plaque d'immatriculation en clair du vehicule */
	immatClair: string;

	/** Liste des alertes */
	listeAlertes: ListeAlertes;

	/** Paramétrage */
	settings: SettingsVehiculeState;

	/** Liste des puissances */
	listePuissance: Array<PuissanceFiscale>;

	/** Flag définissant si on est responsable */
	isResponsableVehicule: boolean;

	/** Booléen indiquant si on peut gérer les documents */
	canGestionDocuments: boolean;

	/** Motif de rejet du véhicule */
	motifRejet: string;

	/** Utilisateur connecté */
	user: User;

	/** Indique que le véhicule est ouvert depuis le profil de l'utilisateur */
	fromProfil: boolean = false;

	/** Indique que le véhicule est ouvert depuis l'admin */
	fromAdmin: boolean;

	/** Référence vers le composant des documents du véhicule */
	@ViewChild("vehiculeDocuments")
	vehiculeDocuments?: VehiculeDocumentsComponent;

	/** Référence vers le composant de l'immatriculation */
	@ViewChild("immatComponent")
	immatComponent: CustomInputComponent;

	/**
	 * Constructeur
	 *
	 * @param store le store de l'application
	 * @param confirmService le service de confirmation
	 * @param translateService le moteur de traduction
	 * @param vehiculeService le service de gestion des véhicules
	 * @param activatedRoute la route courante
	 * @param toastrService le toaster
	 * @param matDialog le gestionnaire de popin
	 * @param router le routeur Angular
	 */
	constructor(private store: Store<AppState>,
		private confirmService: ConfirmService,
		private translateService: TranslateService,
		private vehiculeService: VehiculeService,
		private activatedRoute: ActivatedRoute,
		private toastrService: ToastrService,
		private matDialog: MatDialog,
		private router: Router) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Chargement du paramétrage de la note de frais
		this.store.dispatch({
			type: settingsActions.LOAD_SETTINGS,
			payload: TypePortee.VP
		});

		//Vérification de l'URL pour déterminer si le véhicule est ouvert depuis le profil de l'utilisateur ou par le menu (en tant que Responsable)
		this.fromProfil = this.activatedRoute.snapshot.url[0].path.startsWith('Profil');

		//Vérification de l'URL pour déterminer si le véhicule est ouvert depuis l'admin - utilisateur
		this.fromAdmin = this.activatedRoute.snapshot.url[0].path === "User";

		//Sélection de l'utilisateur connecté
		this.store.select(state => state.session?.user).subscribe(user => this.user = user);

		//Sélection du paramétrage
		this.store.select(state => state.settings?.[TypePortee.VP]).pipe(filter(settings => !!settings),first()).subscribe(settings => {
			//Récupération du paramétrage
			this.settings = settings;

			//Récupération des paramètres de navigation
			this.activatedRoute.params.pipe(first()).subscribe(params => {
				//Chargement du véhicule
				this.loadVehicule(params.idPa);
			});
		});

		//Définition des onglets
		this.listeTabItems = [{
			code: 'GENERALITES',
			libelle: this.translateService.instant('vehicule.navigation.generalites')
		},{
			code: 'COMPLEMENTS',
			libelle: this.translateService.instant('vehicule.navigation.complements')
		}];
	}

	/**
	 * Méthode pour charger ou créer un véhicule
	 *
	 * @param idPa identifiant du véhicule à charger, si null la méthode crée un véhicule
	 */
	loadVehicule(idPa?: number): void {
		if (idPa && idPa != 0) {
			this.vehiculeService.loadVehicule(idPa).pipe(first()).subscribe({
				next: response => {
					//Si l'identifiant est renseigné, on récupère des données du véhicule depuis le serveur
					if (!response.data.vehicule) {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('global.errors.chargement'));
						this.onGoBack();
					}

					//Construction du véhicule
					this.vehicule = Object.assign(new Vehicule(),response.data.vehicule);

					//Réinitialisation du backup de l'immatriculation, pour le bon fonctionnement de l'input obfusqué
					setTimeout(() => {
						this.immatComponent?.initModelBackup();
					});

					//Récupération de la liste des puissances disponibles
					this.listePuissance = response.data.listePuissancefiscale;

					//On active le mode responsable uniquement dans le cas où on n'est pas sur le profil
					this.isResponsableVehicule = !this.fromProfil && response.data.isResponsableVehicule;

					//Vérification si le véhicule est rejeté afin de récupérer son motif de rejet
					if (this.vehicule.statut == Status.STATUT_REFUSE) {
						this.motifRejet = response.data.vehicule.vehiculeApprobation.motif;
					}

					//Le véhicule peut être modifié par son propriétaire si le statut est à brouillon ou refusé
					this.canModifier = !this.isResponsableVehicule && [Status.STATUT_BROUILLON,Status.STATUT_REFUSE].includes(this.vehicule.statut);

					//L'immatriculation du véhicule doit pouvoir être visible par le responsable, bien qu'obfusqué par défaut
					this.canReadClear = this.isResponsableVehicule;

					//Initialisation de la possibilité d'édition des documents
					// - Le responsable peut ajouter une autorisation de circuler si le véhicule lui a été soumis ou qu'il est déjà validé et que la gestion est activée dans la configuration
					// - Le propriétaire peut, si le véhicule n'est pas en cours de traitement par le responsable :
					//   - ajouter une assurance si la gestion est activée dans la configuration
					//   - ajouter une carte grise s'il n'y en a pas déjà une et que la gestion est activée dans la configuration
					this.canGestionDocuments = this.isResponsableVehicule ? (this.vehicule.statut != Status.STATUT_BROUILLON && this.settings.autorisationActif)
						: (this.vehicule.statut != Status.STATUT_NON_TRAITE && (this.settings.assuranceActif || this.vehicule.listeCarteGrise?.length == 0 && this.settings.carteGriseActif));

					//Récupération des alertes
					this.listeAlertes = this.vehiculeService.buildAlertes(this.vehicule,this.settings).toListeAlertes();

					//Récupération des actions possibles sur le véhicule
					//Utilisation d'un setTimeout pour éviter l'erreur dans la console JS lors de l'affichage du floating bouton qui intervient après le ngOnInit à cause du chargement asynchrone du véhicule
					setTimeout(() => {
						this.updateListeActions();
					});
				}
			});
		}
	}

	/**
	 * Fonction de comparaison des puissances fiscales
	 *
	 * @param pf1 première puissance
	 * @param pf2 seconde puissance
	 */
	comparePuissanceFiscale(pf1: any,pf2: any): boolean {
		return pf1?.id && pf2?.id && pf1.id == pf2.id;
	}

	/**
	 * Mise à jour de la liste des actions possibles
	 */
	updateListeActions(): void {
		//Vérification du mode responsable
		if (this.isResponsableVehicule) {
			//Actions disponibles pour l'approbateur depuis le menu
			this.listeActions.next([
				{
					type: TypeAction.PRIMARY,
					icone: 'nio icon-rejet',
					libelle: 'global.actions.rejeter',
					doAction: () => this.rejetVehicule(),
					isVisible: () => this.vehicule?.idPa != null && this.vehicule.statut == Status.STATUT_NON_TRAITE
				},{
					type: TypeAction.PRIMARY,
					icone: 'nio icon-validation',
					libelle: 'global.actions.valider',
					doAction: () => this.changeStatutVehicule(Status.STATUT_VALIDE),
					isVisible: () => this.vehicule?.idPa != null && [Status.STATUT_NON_TRAITE,Status.STATUT_REFUSE].includes(this.vehicule.statut)
				},{
					type: TypeAction.PRIMARY,
					icone: 'nio icon-rappeler',
					libelle: 'global.actions.invalider',
					doAction: () => this.changeStatutVehicule(Status.STATUT_NON_TRAITE,false),
					isVisible: () => this.vehicule?.idPa != null && Status.STATUT_VALIDE == this.vehicule.statut
				}
			]);
		} else {
			//Actions disponibles depuis le profil
			//Si le circuit d'approbation est activé, l'utilisateur peut émettre le véhicule pour approbation,
			//sinon l'utilisateur peut lui-même valider et modifier post-validation son véhicule
			this.listeActions.next([
				{
					type: TypeAction.PRIMARY,
					icone: 'nio icon-emission',
					libelle: 'vehicule.actions.soumettre',
					doAction: () => this.changeStatutVehicule(Status.STATUT_NON_TRAITE),
					isVisible: () => this.settings.approbationActif && [Status.STATUT_BROUILLON,Status.STATUT_REFUSE].includes(this.vehicule?.statut),
					isDisabled: () => !this.vehiculeDocuments?.isValidForApprobation()
				},{
					type: TypeAction.PRIMARY,
					icone: 'nio icon-rappeler',
					libelle: 'global.actions.modifier',
					doAction: () => this.changeStatutVehicule(Status.STATUT_BROUILLON),
					isVisible: () => !this.settings.approbationActif && this.vehicule?.idPa != null && [Status.STATUT_NON_TRAITE,Status.STATUT_VALIDE].includes(this.vehicule.statut)
				},{
					type: TypeAction.PRIMARY,
					icone: 'nio icon-validation',
					libelle: 'global.actions.valider',
					doAction: () => this.changeStatutVehicule(Status.STATUT_VALIDE),
					isVisible: () => !this.settings.approbationActif && this.vehicule?.idPa != null && [Status.STATUT_BROUILLON,Status.STATUT_NON_TRAITE].includes(this.vehicule.statut),
					isDisabled: () => !this.vehiculeDocuments?.isValidForApprobation(),
					tooltip: () => !this.vehiculeDocuments?.isValidForApprobation() ? "vehicule.liste.alerte.documentManquant" : null
				},{
					type: TypeAction.PRIMARY,
					icone: 'nio icon-sauvegarde',
					libelle: 'global.actions.enregistrer',
					doAction: () => this.save(),
					isVisible: () => true
				},{
					type: TypeAction.SECONDARY,
					icone: 'nio icon-suppression',
					libelle: 'global.actions.supprimer',
					doAction: () => this.deleteVehicule(),
					isVisible: () => [Status.STATUT_BROUILLON,Status.STATUT_REFUSE].includes(this.vehicule?.statut)
				}
			]);
		}
	}

	/**
	 * Enregistrement du véhicule
	 */
	save(): void {
		//Enregistrement en cours
		this.isSaving = true;

		//Enregistrement du véhicule
		this.vehiculeService.save(this.vehicule)
			.pipe(first(),finalize(() => this.isSaving = false))
			.subscribe({
				next: result => {
					//Vérification de l'enregistrement
					if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
						//Message d'information
						this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

						//Rechargement du véhicule
						this.loadVehicule(result.data.vehicule.idPa);
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
					}
				},
				error: () => {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
				}
			});
	}

	/**
	 * Suppression du véhicule
	 */
	deleteVehicule(): void {
		//Demande de confirmation
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe({
				next: () => {
					//Enregistrement en cours
					this.isSaving = true;

					//Suppression du véhicule
					this.vehiculeService.deleteVehicule(this.vehicule.idPa)
						.pipe(first(),finalize(() => this.isSaving = false))
						.subscribe({
							next: (result) => {
								//Vérification de la suppression
								if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
									//Message d'information
									this.toastrService.success(this.translateService.instant('global.success.suppression'));

									//Retour à la liste
									this.onGoBack();
								} else {
									//Message d'erreur
									this.toastrService.error(this.translateService.instant('global.errors.suppression'));
								}
							},
							error: () => {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('global.errors.suppression'));
							}
						});
				}
			});
	}

	/**
	 * Méthode pour changer le statut du véhicule
	 *
	 * @param statut Numéro du nouveau statut
	 * @param envoiMail Envoyer les notifications lors de la sauvegarde (true par défaut)
	 * @param motif Motif du rejet (Lorsque le véhicule est rejeté)
	 */
	async changeStatutVehicule(statut: Status,envoiMail: boolean = true,motif?: string) {
		let continueChangeStatut: boolean = true;

		//Validation d'un véhicule par un responsable avec les autorisations de circuler activées
		if (this.isResponsableVehicule && statut === Status.STATUT_VALIDE && this.settings.autorisationActif) {
			//Vérification de la présence d'au moins une autorisation en cours ou à venir
			continueChangeStatut = await new Promise<boolean>((resolve) => {
				//Affichage d'une popin de confirmation et récupération du choix
				this.confirmService.showConfirm(
					!this.vehicule.listeAutorisation.some(doc => moment().isSameOrBefore(doc.dateFin,'day')) ?
						this.translateService.instant('vehicule.formulaire.confirmNoAutorisation') :
						this.translateService.instant('vehicule.formulaire.confirmWithAutorisation')
				)
					.pipe(first())
					.subscribe(isConfirmed => resolve(isConfirmed));
			});
		} else if (!this.isResponsableVehicule && statut === Status.STATUT_NON_TRAITE) {
			//Sinon, en cas de soumission en validation d'un véhicule en tant que collaborateur
			//Création d'une promesse
			continueChangeStatut = await new Promise<boolean>(resolve => {
				//Message de confirmation
				this.confirmService.showConfirm(this.translateService.instant('vehicule.formulaire.confirmSendValidation'))
					.pipe(first())
					.subscribe(isConfirmed => resolve(isConfirmed));
			});
		}

		//Vérification de la poursuite du traitement
		if (continueChangeStatut) {
			//Enregistrement en cours
			this.isSaving = true;

			//Enregistrement
			this.vehiculeService.save(this.vehicule,envoiMail)
				.pipe(first(),finalize(() => this.isSaving = false))
				.subscribe({
					next: result => {
						if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
							//Enregistrement en cours (dans un setTimeout pour forcer le rafraichissement malgré le finalize précédent qui a modifié la valeur)
							setTimeout(() => {
								this.isSaving = true;
							});

							//Changement du statut
							this.vehiculeService.changeStatutVehicule(this.vehicule.idPa,statut,motif)
								.pipe(first(),finalize(() => this.isSaving = false))
								.subscribe({
									next: result => {
										//Vérification de l'enregistrement
										if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
											//Message d'information
											this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

											//Rechargement du véhicule
											this.loadVehicule(this.vehicule.idPa);
										} else {
											//Affichage d'un message d'erreur
											this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
										}
									},
									error: () => {
										//Affichage d'un message d'erreur
										this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
									}
								});
						}
					},
					error: () => {
						//Affichage d'un message d'erreur
						this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
					}
				});
		}
	}

	/**
	 * Ouverture de la Dialog de rejet
	 */
	rejetVehicule(): void {
		this.matDialog.open(VehiculeRejetComponent).afterClosed().subscribe({
			next: ({motif}) => {
				//Lors de la fermeture normale de la popup
				if (motif) {
					this.changeStatutVehicule(Status.STATUT_REFUSE,true,motif);
				}
			}
		})
	}

	/**
	 * Permet de récupérer la traduction du statut véhicule
	 *
	 * @param status Status
	 */
	getStatusLibelle(status: Status) {
		switch (status) {
			case Status.STATUT_NON_TRAITE:
				return this.translateService.instant('vehicule.statut.nonTraite');
			case Status.STATUT_VALIDE:
				return this.translateService.instant('vehicule.statut.valide');
			case Status.STATUT_REFUSE:
				return this.translateService.instant('vehicule.statut.refuse');
			case Status.STATUT_BROUILLON:
				return this.translateService.instant('vehicule.statut.brouillon');
			default:
				return "";
		}
	}


	/**
	 * Finalisation de l'upload d'un fichier
	 */
	onDocumentUploaded({result}): void {
		//Rechargement du véhicule
		this.loadVehicule(this.vehicule.idPa)
	}

	/**
	 * Ouvre la popup d'affichage de l'historique de distance
	 */
	openHistoriqueDistance(): void {
		this.matDialog.open(VehiculeHistoriqueDistanceComponent,{
			data: {
				listeCompteur: this.vehicule.listeCompteur
			}
		})
	}

	/**
	 * Retour arrière
	 */
	onGoBack(): void {
		//Navigation vers la liste
		if (this.user?.fonction == TypeProfil.RESPONSABLE && !this.fromProfil) {
			this.router.navigate(['ListeVehicule']);
		} else if (this.fromProfil) {
			this.router.navigate(['Profil'],{state: {'tab': 'DONNEES','section': '#listeVehicule .mat-card'}});
		} else if (this.fromAdmin) {
			this.router.navigate(['Admin/Entreprise/Utilisateurs/User',this.activatedRoute.snapshot.params.idUser],{state: {'tabToLoad': Onglets.VEHICULES}});
		}
	}

	/**
	 * Changement d'onglet
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem) {
		//Mise à jour de l'onglet sélectionné
		this.selectedItem = selectedItem;
	}

	/**
	 * Formate le numéro d'immatriculation en supprimant tout ce qui n'est pas alphanumérique, et en passant tout en majuscule.
	 */
	formaterImmatriculation() {
		this.vehicule.immatriculation = this.vehicule.immatriculation.replace(/[^a-zA-Z0-9]/g,'').toUpperCase();
	}

	/**
	 * Récupère le numéro d'immatriculation en clair
	 */
	getPlaqueImmatriculationVehiculeNonObfusquee() {
		if (!this.immatClair) {
			this.vehiculeService.getPlaqueImmatriculationVehiculeNonObfusquee(this.vehicule.idPa).subscribe(result => {
				if (result.data) {
					this.immatClair = result.data['immatriculation'];
					if (this.immatClair) {
						this.immatComponent.switchModelObfusque(this.immatClair,true);
					} else {
						this.toastrService.error(this.translateService.instant('vehicule.formulaire.errors.dechiffrerImmatImpossible'));
					}
				} else {
					this.toastrService.error(this.translateService.instant('vehicule.formulaire.errors.dechiffrerImmatImpossible'));
				}
			});
		} else {
			this.immatComponent.switchModelObfusque(this.immatClair,true);
		}
	}
}
