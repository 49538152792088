import {NgModule} from '@angular/core';
import {Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {ShareModule} from "@share/share.module";
import {ComptabiliteComptesBancairesComponent} from "app/components/admin/comptabilite/comptes-bancaires/comptabilite-comptes-bancaires.component";
import {AdminComptabiliteComponent} from "@components/admin/comptabilite/admin-comptabilite.component";
import {ComptabiliteComptesBancairesModule,comptabiliteComptesBancairesRoutes} from "@components/admin/comptabilite/comptes-bancaires/comptabilite-comptes-bancaires.module";
import {ComptabiliteExercicesPeriodesComponent} from 'app/components/admin/comptabilite/exercices-periodes/comptabilite-exercices-periodes.component';
import {comptabiliteExercicesPeriodesRoutes} from "@components/admin/comptabilite/exercices-periodes/comptabilite-exercices-periodes.module";
import {ComptabiliteModeRemboursementModule} from "@components/admin/comptabilite/mode-remboursement/comptabilite-mode-remboursement.module";
import {ComptabiliteModeRemboursementComponent} from "@components/admin/comptabilite/mode-remboursement/comptabilite-mode-remboursement.component";
import {IbanPipe,NgxIbanModule} from "ngx-iban";
import {DroitAdmin} from "@core/security/droit-admin";
import {FrameComponent} from "@share/layout/frame.component";

/**
 * Routes du module d'administration de la comptabilité
 */
export const adminComptabiliteRoutes: Routes = [
	{
		path: 'ComptesBancaires',
		component: ComptabiliteComptesBancairesComponent,
		canActivate: [AdminGuardProvider],
		children: comptabiliteComptesBancairesRoutes
	},{
		path: 'ExercicesPeriodes',
		component: ComptabiliteExercicesPeriodesComponent,
		canActivate: [AdminGuardProvider],
		children: comptabiliteExercicesPeriodesRoutes
	},{
		path: 'ModeRemboursement',
		component: ComptabiliteModeRemboursementComponent,
		canActivate: [AdminGuardProvider],
		data : { sousAdminCredentials: [DroitAdmin.DROIT_MODE_REMBOURSEMENT] }
	},{
		path: 'PlanComptable',
		component: FrameComponent, //TODO: Implémenter le vrai composant v10
		canActivate: [AdminGuardProvider],
		data: { sousAdminCredentials: [DroitAdmin.DROIT_COMPTE_GESTION] }
	},{
		path: '',
		redirectTo: 'ComptesBancaires',
		pathMatch: 'full'
	},{
		path: '**',
		redirectTo: 'ComptesBancaires'
	}
];

/**
 * Module d'administration de la comptabilité
 *
 * @author Alexis BOUVARD
 * @date 18/04/2023
 */
@NgModule({
	imports: [
		ShareModule,
		ComptabiliteComptesBancairesModule,
		ComptabiliteModeRemboursementModule,
		NgxIbanModule,
	],
	declarations: [
		AdminComptabiliteComponent,
	],
	providers: [IbanPipe]
})
export class AdminComptabiliteModule {
}
