<div class="lvi-content clickable" (click)="isOuvert = !isOuvert">
    <div class="avatar">
        <!-- Icone de la nature -->
        <span [ngSwitch]="nature.portee">
            <i *ngSwitchCase="'TT'" class="icon-train nio align-middle" [matTooltip]="nature.portee" [matTooltipDisabled]="false" matTooltipPosition="right"></i>
            <i *ngSwitchCase="'TA'" class="icon-avion nio align-middle" [matTooltip]="nature.portee" [matTooltipDisabled]="false" matTooltipPosition="right"></i>
            <i *ngSwitchCase="'TV'" class="icon-voiture nio align-middle" [matTooltip]="nature.portee" [matTooltipDisabled]="false" matTooltipPosition="right"></i>
            <i *ngSwitchCase="'HE'" class="material-icons-outlined align-middle" [matTooltip]="nature.portee" [matTooltipDisabled]="false" matTooltipPosition="right">hotel</i>
            <i *ngSwitchCase="'FA'" class="material-icons-outlined align-middle" [matTooltip]="nature.portee" [matTooltipDisabled]="false" matTooltipPosition="right">insert_drive_file</i>
            <i *ngSwitchDefault class="material-icons-outlined align-middle" [matTooltip]="nature.portee" [matTooltipDisabled]="false" matTooltipPosition="right">help_outline</i>
        </span>
    </div>
    <!-- Body pour les natures -->
    <div class="lvi-body">
        <div class="title row">
            <!-- Colonne départ -->
            <span class="col-md-6">
                <!-- Entête pour les natures autres qu'Hôtel ou pour les Hôtels ayant une ville et un pays -->
                <a [routerLink]="" *ngIf="nature.villeDebut?.libelle || nature.paysDebut?.libelle || nature.portee !== 'HE'">
                    <span class="flag-icon mr-1 col-md-6" [ngClass]="nature.paysDebut ? 'flag-icon-'+nature.paysDebut.code2.toLowerCase() : ''"></span>
                    {{ (nature.villeDebut?.libelle ? nature.villeDebut.libelle : ('od.voyage.travel.villeInconnue' | translate)) + ', ' + (nature.paysDebut?.libelle ? nature.paysDebut.libelle : ('od.voyage.travel.paysInconnu' | translate)) }}

                    <!-- Séparateur si on va afficher le nom de l'hôte -->
                    <ng-container *ngIf="nature.portee === 'HE'">&nbsp;-&nbsp;</ng-container>
                </a>
                <!-- Entête pour les natures de type Hôtel-->
                <a [routerLink]="" *ngIf="nature.portee === 'HE'">
                    <!-- On laisse le span du drapeau pour aligner avec ceux qui ont vraiment un drapeau (si on ne connait pas la ville et/ou le pays)-->
                    <span *ngIf="!nature.villeDebut?.libelle && !nature.paysDebut?.libelle" class="flag-icon mr-1 col-md-6"></span>
                    {{ nature.nomHotel }}
                </a>
            </span>
            <!-- Colonne arrivée -->
            <span class="col-md-6" *ngIf="nature.villeFin || nature.paysFin || !(['HE', 'FA'].includes(nature.portee))">
                <a [routerLink]="">
                    <span class="flag-icon mr-1 col-md-6" [ngClass]="'flag-icon-'+nature.paysFin?.code2.toLowerCase()"></span>
                    {{ (nature.villeFin?.libelle ? nature.villeFin.libelle : ('od.voyage.travel.villeInconnue' | translate)) + ', ' + (nature.paysFin?.libelle ? nature.paysFin.libelle : ('od.voyage.travel.paysInconnu' | translate)) }}
                </a>
            </span>
        </div>
        <!-- Bulles -->
        <ul class="lvi-attrs row">
            <!-- Colonne départ -->
            <span class="col-md-6">
                <li *ngIf="getLibelleLieuDepart()"><strong [translate]="'od.voyage.depart'"></strong>{{ getLibelleLieuDepart() }}</li>
                <li *ngIf="getLibelleHoraireDepart()"><strong [translate]="'od.voyage.debut'"></strong>{{ getLibelleHoraireDepart() }}</li>
                <li *ngIf="nature.portee == 'HE'"><strong [translate]="'od.voyage.fin'"></strong>{{ this.nature.dateFin | date:'shortDate' }}</li>
                <li *ngIf="nature.montant > 0"><strong [translate]="'od.voyage.montant'"></strong>{{ nature.montant | montant:deviseEntrep }}</li>
                <li *ngIf="nature.miscDescription"><strong [translate]="'od.voyage.travel.document'"></strong>{{ nature.miscDescription }}</li>
                <li *ngIf="nature.booked === false" class="back-danger"><strong [translate]="'od.voyage.etat'"></strong>{{ 'od.voyage.nonReservee' | translate }}</li>
                <!-- PVE -->
                <li *ngIf="!nature.conforme" class="back-danger"><strong [translate]="'od.voyage.pve'"></strong>{{ 'od.voyage.non' | translate }}</li>
            </span>
            <!-- Colonne arrivée -->
            <span class="col-md-6">
                <li *ngIf="getLibelleLieuArrivee()"><strong [translate]="'od.voyage.arrivee'"></strong>{{ getLibelleLieuArrivee() }}</li>
                <li *ngIf="nature.portee != 'HE' && getLibelleHoraireArrivee()"><strong [translate]="'od.voyage.fin'"></strong>{{ getLibelleHoraireArrivee() }}</li>
            </span>
        </ul>
    </div>

    <div class="lvi-actions" [ngClass]="{'two-button-margin':canGoToSbt}">
        <button mat-icon-button *ngIf="canGoToSbt" (click)="goToSbtOnline();$event.stopPropagation();">
            <mat-icon [ngClass]="!dossier.booked || !dossier.pve  ? 'c-danger' : 'c-primary'" style="font-size:2em;" matTooltipPosition="right" [nioTooltip]="portalButtonTooltip | translate">open_in_new</mat-icon>
        </button>
        <button mat-icon-button>
            <mat-icon *ngIf="isOuvert" color="primary" [matTooltip]="'od.voyage.replier' | translate">keyboard_arrow_up</mat-icon>
            <mat-icon *ngIf="!isOuvert" color="primary" [matTooltip]="'od.voyage.deplier' | translate">keyboard_arrow_down</mat-icon>
        </button>
    </div>
</div>

<!-- Cadre de détail de la prestation -->
<div class="p-20 prestation-box">
    <div class="prestation-content" [ngClass]="isOuvert ? 'open' : 'closed'">
        <form autocomplete="off" [ngSwitch]="nature.portee">
            <!-- Détail d'un Train -->
            <ng-container *ngSwitchCase="'TT'">
                <!-- PNR / Numéro du train -->
                <row-data [dataGauche]="{traduction: 'od.voyage.pnr', valeur:(nature.pnrDossier ? nature.pnrDossier : dossier.pnr) + ' (' + nature.pnr +')'}"
                          [dataDroite]="{traduction: 'od.voyage.numTrain', valeur:nature.numero}"></row-data>

                <!-- Transporteur / Voiture/Siège -->
                <row-data [dataGauche]="{traduction: 'od.voyage.transporteur', valeur:nature.compagnie}"
                          [dataDroite]="{traduction: 'od.voyage.siege', valeur:(nature.voiture ? nature.voiture + ' / ' : '') + (nature.place ? nature.place : '')}"></row-data>

                <!-- rien / Classe -->
                <row-data [isAffichageDouble]="true"
                          [dataDroite]="{traduction: 'od.voyage.classe', valeur:nature.classe}"></row-data>

                <!-- Remarque -->
                <row-data [dataGauche]="{traduction: 'od.voyage.remarque', valeur:nature.remarque}"></row-data>

                <!-- Commentaire agence -->
                <row-data [dataGauche]="{traduction: 'od.voyage.commentaireAgence', valeur:nature.commentaire}"></row-data>

                <!-- Remarque Conformité -->
                <row-data [dataGauche]="{traduction: 'od.voyage.remarqueConformite', valeur:nature.remarqueConformite}"></row-data>
            </ng-container>

            <!-- Détail d'un Avion -->
            <ng-container *ngSwitchCase="'TA'">
                <!-- PNR / Numéro de l'avion -->
                <row-data [dataGauche]="{traduction: 'od.voyage.pnr', valeur:(nature.pnrDossier ? nature.pnrDossier : dossier.pnr) + ' (' + nature.pnr +')'}"
                          [dataDroite]="{traduction: 'od.voyage.numVol', valeur:nature.numero}"></row-data>

                <!-- Transporteur / Classe -->
                <row-data [dataGauche]="{traduction: 'od.voyage.transporteur', valeur:nature.compagnie}"
                          [dataDroite]="{traduction: 'od.voyage.classe', valeur:nature.classe}"></row-data>

                <!-- Terminal départ / Terminal arrivée -->
                <row-data [dataGauche]="{traduction: 'od.voyage.terminalDepart', valeur:nature.terminalDepart}"
                          [dataDroite]="{traduction: 'od.voyage.terminalArrivee', valeur:nature.terminalArrivee}"></row-data>

                <!-- Remarque -->
                <row-data [dataGauche]="{traduction: 'od.voyage.remarque', valeur:nature.remarque}"></row-data>

                <!-- Commentaire agence -->
                <row-data [dataGauche]="{traduction: 'od.voyage.commentaireAgence', valeur:nature.commentaire}"></row-data>

                <!-- Remarque Conformité -->
                <row-data [dataGauche]="{traduction: 'od.voyage.remarqueConformite', valeur:nature.remarqueConformite}"></row-data>
            </ng-container>

            <!-- Détail d'un Hotel -->
            <ng-container *ngSwitchCase="'HE'">
                <!-- PNR / Adresse -->
                <row-data [dataGauche]="{traduction: 'od.voyage.pnr', valeur:(nature.pnrDossier ? nature.pnrDossier : dossier.pnr) + ' (' + nature.pnr + ')'}"
                          [dataDroite]="{traduction: 'od.voyage.adresse', valeur:(nature.rue + (nature.codePostal ? '-' + nature.codePostal : '') + (nature.villeDebut?.libelle ? '-' + nature.villeDebut.libelle : ''))}"></row-data>

                <!-- Fournisseur / Téléphone -->
                <row-data [dataGauche]="{traduction: 'od.voyage.fournisseur', valeur:nature.compagnie}"
                          [dataDroite]="{traduction: 'od.voyage.telephone', valeur:nature.telephone}"></row-data>

                <!-- Numéro de réservation / rien -->
                <row-data [dataGauche]="{traduction: 'od.voyage.numConfirm', valeur:nature.numeroReservation}"
                          [isAffichageDouble]="true"></row-data>

                <!-- Remarque -->
                <row-data [dataGauche]="{traduction: 'od.voyage.remarque', valeur:nature.remarque}"></row-data>

                <!-- Commentaire agence -->
                <row-data [dataGauche]="{traduction: 'od.voyage.commentaireAgence', valeur:nature.commentaire}"></row-data>

                <!-- Remarque Conformité -->
                <row-data [dataGauche]="{traduction: 'od.voyage.remarqueConformite', valeur:nature.remarqueConformite}"></row-data>
            </ng-container>

            <!-- Détail d'une Voiture -->
            <ng-container *ngSwitchCase="'TV'">
                <!-- PNR / Fournisseur -->
                <row-data [dataGauche]="{traduction: 'od.voyage.pnr', valeur:(nature.pnrDossier ? nature.pnrDossier : dossier.pnr) + ' (' + nature.pnr +')'}"
                          [dataDroite]="{traduction: 'od.voyage.fournisseur', valeur:nature.compagnie}"></row-data>

                <!-- N° de confirm / Catégorie -->
                <row-data [dataGauche]="{traduction: 'od.voyage.numConfirm', valeur:nature.numeroConfirmation}"
                          [dataDroite]="{traduction: 'od.voyage.categorie', valeur:nature.classe}"></row-data>

                <!-- Commentaire agence -->
                <row-data [dataGauche]="{traduction: 'od.voyage.commentaireAgence', valeur:nature.commentaire}"></row-data>

                <!-- Remarque Conformité -->
                <row-data [dataGauche]="{traduction: 'od.voyage.remarqueConformite', valeur:nature.remarqueConformite}"></row-data>
            </ng-container>

            <!-- Détail d'un truc Autre -->
            <ng-container *ngSwitchDefault>
                <!-- Type / Nature -->
                <row-data [dataGauche]="{traduction: 'od.voyage.commentaireAgence', valeur:nature.commentaire}"></row-data>

                <!-- Remarque -->
                <row-data [dataGauche]="{traduction: 'od.voyage.remarque', valeur:nature.remarque}"></row-data>
            </ng-container>
        </form>
    </div>
</div>