import { NgModule } from '@angular/core';

import { DocumentListComponent } from './document-list.component';
import { DocumentListItemComponent } from './document-list-item.component';
import { ListViewModule } from '../../share/component/list-view/list-view.module';
import { CoreComponentsModule } from '../../share/component/core-components.module';
import { FileUploaderModule } from '../../share//directive/file-uploader/file-uploader.module';
import { ThumbModule } from '../../share/component//thumb/thumb.module';
import { DocumentService } from './document.service';
import { DocumentViewerComponent } from './document-viewer.component';
import { DocumentUploaderComponent } from './document-uploader.component';

@NgModule({
    imports: [ListViewModule,CoreComponentsModule,FileUploaderModule,ThumbModule],
    declarations: [DocumentListComponent,DocumentListItemComponent,DocumentViewerComponent,DocumentUploaderComponent],
    entryComponents: [DocumentListItemComponent],
    exports: [DocumentListComponent,DocumentViewerComponent,DocumentUploaderComponent],
    providers: [DocumentService]
})
export class DocumentModule {}