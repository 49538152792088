import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {first, map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {Report, ReportFormat} from "@domain/reporting/report";
import {TypePortee} from "@domain/workflow/workflow";
import {ReportingComponent} from "@components/reporting/reporting.component";
import {MatDialog} from "@angular/material/dialog";

@Injectable()
export class ReportingService {

	/**
	 * Constructeur
	 * @param http 					httpClient
	 * @param translateService 		service de traduction
	 * @param matDialog				service de dialogue
	 */
	constructor(private http: HttpClient, private translateService: TranslateService, private matDialog: MatDialog) {

	}

	private listeLibelleGroupe = [
		{libelleGroupe: this.translateService.instant('report.liste.libelleGroupe.none'), codeGroupe: null},
		{libelleGroupe: this.translateService.instant('report.liste.libelleGroupe.ndf'), codeGroupe: "NDF"},
		{libelleGroupe: this.translateService.instant('report.liste.libelleGroupe.od'), codeGroupe: "OD"},
		{libelleGroupe: this.translateService.instant('report.liste.libelleGroupe.avance'), codeGroupe: "AV"},
		{libelleGroupe: this.translateService.instant('report.liste.libelleGroupe.facture'), codeGroupe: "FC"},
		{libelleGroupe: this.translateService.instant('report.liste.libelleGroupe.admin'), codeGroupe: "ADM"}
	]

	/**
	 * Chargement de la liste des rapports pour l'utilisateur courant.
	 */
	loadListe(): Observable<[Report]> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/ReportUser/listeReport`).pipe(
			map(
				(result: Result) => (result?.data.listeReport.map(
						//Ajout du libellé groupe
						report => ({...report, ...this.listeLibelleGroupe[report.groupeDefini]})
					)
				)
			)
		);
	}

	/**
	 * Chargement de la liste des rapports pour une portée et un type donnés.
	 *
	 * @param portee portee
	 * @param type type du rapport
	 */
	loadListeByPorteeAndType(portee: TypePortee, type: number): Observable<Result> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/ReportUser/listeReportByPorteeAndType?portee=${portee}&type=${type}`).pipe(
			map(
				(result: Result) => {
					result?.data.listeReport.forEach(
						//Ajout du libellé groupe pour les groupes standards
						report => report.libelleGroupe = this.listeLibelleGroupe[report.groupeDefini].libelleGroupe
					);

					return result;
				}
			)
		);
	}

	/**
	 * Chargement d'un rapport
	 * @param idReport identifiant du rapport
	 */
	loadReport(idReport: number): Observable<Report> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/ReportUser/loadReport/${idReport}`, null).pipe(
			map(result => result?.data?.report)
		);
	}

	/**
	 * Exécute et affiche le rapport d'extraction d'un objet workflow.
	 *
	 * @param idReport id du rapport à exécuter
	 * @param portee portée de l'objet
	 * @param idObjet id de l'objet worfklow
	 */
	executeReportWFO(idReport: number, portee: TypePortee, idObjet: number) {
		let callFunction = `${environment.baseUrl}/servlet/NDFServlet?action=Report&typeAction=62&id_report=${idReport}&id_portee=${portee}&id_objet=${idObjet}`;

		//Exécution du rapport et affichage dans une nouvelle fenêtre
		window.open(callFunction, '_blank');
	}

	/**
	 * Lancement d'un rapport
	 * @param report rapport
	 */
	executeReport(report: Report) {
		//Variables
		let callFunction = `${environment.baseUrl}/controller/ReportUser/printReport?`;
		let isFirstParameter = true;

		//Données passés pour l'impression du rapport
		let listeParam = [];

		//Ajout des paramètres standards
		listeParam.push({name: "id_report", value: report.id});
		listeParam.push({name: "type_export", value: report.typeExport});
		if (report.format) {
			listeParam.push({name: "format_export", value: report.format});
		}

		//Parcours des paramètres du rapport
		report.reportParamList.forEach(
			function (param) {
				switch (param.type) {
					case 2:
						//Pour les dates, changement de format
						listeParam.push({
							name: param.name,
							value: new Date(param.value).toLocaleDateString("fr")
						})
						break;
					case 1:
					case 3:
					case 4:
						//Dans le cas des paramètres à choix unique
						listeParam.push({
							name: param.name,
							value: param.value
						});
						break;
					case 5:
						//Dans le cas des paramètres à choix multiple, nous devons retravailler le resultat avant envoi
						listeParam.push({
							name: param.name,
							value: Object.keys(param.value).filter(function (obj) {
								return param.value[obj];
							})
						});
						break;
				}
			}
		);

		//Ajout des paramètres dans l'URL d'appel
		listeParam.forEach(function (param) {
			//Vérification du premier paramètre
			if (isFirstParameter) {
				//Ajout du paramètre dans l'URL
				callFunction += param.name + '=' + (Array.isArray(param.value) ? encodeURIComponent(param.value.join(':')) : encodeURIComponent(param.value));

				//Premier paramètre passé
				isFirstParameter = false;
			} else {
				//Ajout du paramètre dans l'URL
				callFunction += '&' + param.name + '=' + (Array.isArray(param.value) ? encodeURIComponent(param.value.join(':')) : encodeURIComponent(param.value));
			}
		})

		//Exécution du rapport
		window.open(callFunction, '_blank');
	}

	/**
	 * Affichage de la modal de paramétrage du rapport / Execution du rapport
	 *
	 * @param idReport identifiant du rapport
	 */
	executeReportById(idReport: number) {
		this.loadReport(idReport).pipe(first()).subscribe({
			next: report => {
				//Ajout du format et du type d'export
				report.format = report.pdf ? ReportFormat.PDF : report.csv ? ReportFormat.CSV : report.html ? ReportFormat.HTML : ReportFormat.EXCEL;
				report.typeExport = "1";

				//Si aucun paramètre à préciser, execution directe du rapport
				if (report.format === 4 && report.reportParamList?.length === 0) {
					this.executeReport(report)
				} else {
					//Affichage de la fenêtre d'exécution
					this.matDialog.open(ReportingComponent, {
						data: report,
					});
				}
			}
		});
	}
}
