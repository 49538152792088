import {Component} from '@angular/core';
import {AutocompleteLineItemComponent} from "../../../autocomplete-line-item.component";
import {Od} from "@domain/od/od";

/**
 * Composant d'affichage d'une ligne de l'autocomplete des missions dans le cas de la correction des rejets de facture
 *
 * @author Laurent Convert
 * @date 11/07/2023
 */
@Component({
	host: {'data-test-id': 'import-facture-mission-line-item'},
	templateUrl: './import-facture-mission.line-item.html'
})
export class ImportFactureMissionLineItem extends AutocompleteLineItemComponent<Od> {

	/**
	 * Constructeur
	 */
	constructor() {
		super();
	}

}