import {Injectable} from '@angular/core';
import {HttpClient,HttpParams} from '@angular/common/http';
import {Observable,of,Subject} from 'rxjs';
import {distinctUntilChanged,filter,finalize,first,map,switchMap,tap} from 'rxjs/operators';

import {Filter,ListView,TypeComparaison,TypeFilter} from '@domain/common/list-view';
import {environment} from '@environments/environment';
import {SearchSpec} from "@domain/common/list-view/searchSpec";
import {Result} from "@domain/common/http/result";
import {TranslateService} from '@ngx-translate/core';
import {emptyPage,Page} from '@domain/common/http/list-result';
import {DatePipe,DecimalPipe} from "@angular/common";
import {SearchType} from '@domain/common/list-view/sorting';
import {SessionStorageService} from "@domain/common/services/session-storage.service";
import {ListItem} from "@domain/common/list-view/list-item";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {CountStatus,nbObjetsParPageDefaut} from "@domain/common/list-view/list-view";
import {TypeProfil} from "@domain/user/user";
import {FilterDTO} from "@domain/common/list-view/filterDTO";

/**
 * Service de gestion des listes
 */
@Injectable()
export class ListViewService<T extends ListItem, K extends ListViewItem<T>> {
    /**
     * Constructeur
     */
    constructor(private http: HttpClient,
                private translateService: TranslateService,
                private sessionStorageService: SessionStorageService,
                private datePipe: DatePipe,
                private decimalPipe: DecimalPipe) {

    }

    /**
     * Chargement des données
     *
     * @param liste                 Liste de données
     * @param uri                   URL à appeler pour récupérer les données
     * @param numPage               Numéro de la page à afficher
     * @param nbObjetsParPage       Nombre d'objets à charger par page (forcé à 5 pour les listes du dashboard)
     * @param defaultOrder          Ordre par défaut à appliquer aux résultats de la requête
     * @param listeStaticFilters    Liste des filtres complémentaires pour le searchSpec
     * @param listeFilters          Liste des filtres pour le searchSpec
     * @param fonction              Fonction de l'utilisateur connecté
     * @return {Observable<Page<T>>}    Résultat de la requête
     */
    loadData(liste: ListView<T, K>, uri: string, numPage: number, nbObjetsParPage: number = nbObjetsParPageDefaut, defaultOrder: string, listeStaticFilters: Filter[], listeFilters: Filter[], fonction: TypeProfil): Observable<Page<T>> {
        const searchSpec: SearchSpec = this.buildSearchSpec(numPage, liste, nbObjetsParPage, defaultOrder, listeStaticFilters, listeFilters);

        //Si la recherche peut s'exécuter sans filtre, ou si au moins un filtre est renseigné
        if (liste.isSearchWhenNoFilter || listeFilters && listeFilters.length > 0) {
            //On lance la requête de chargement des données
            return this.doHttpPost(uri,liste,searchSpec,fonction).pipe(
                //On va switcher d'observable en fonction du résultat
                switchMap(result =>  {
                    //On stocke le résultat de la requête
                    const resultMappe = (liste.mapResult && liste.mapResult(result as Result) || result) as Page<T>;

                    //Si la liste n'a pas d'éléments et qu'elle est paramétrée pour ignorer les filtres si elle est vide
                    if (resultMappe && resultMappe.numPage == 0 && (!resultMappe.listeResultats || resultMappe.listeResultats.length == 0) && liste.removeFiltreOnceIfEmpty) {
                        //On supprime le flag
                        liste.removeFiltreOnceIfEmpty = false;

                        //On supprime les filtres
                        searchSpec.listeFilter.splice(0);
                        liste.listeSelectedFilters.splice(0);

                        //On relance la requête de liste sans filtre
                        return this.doHttpPost(uri,liste,searchSpec,fonction).pipe(map(result => (liste.mapResult && liste.mapResult(result as Result) || result) as Page<T>));
                    } else {
                        //Si on n'est pas dans le cas spécifique décrit ci-dessus, on renvoie simplement le résultat
                        return of(resultMappe);
                    }
                })
            );
        } else {
            //Sinon on renvoie une page vide
            return of(emptyPage<T>());
        }
    }


    /**
     * Exécution de la requête de la liste
     *
     * @param uri           URL à appeler
     * @param liste         Liste pour laquelle on cherche des résultats
     * @param searchSpec    Critères de tri et de filtre
     * @param fonction      Fonction de l'utilisateur connecté
     * @returns Observable de la page de résultat
     */
    private doHttpPost(uri: string,liste: ListView<T,K>,searchSpec: SearchSpec,fonction: TypeProfil): Observable<Result | Page<T>> {
        return this.http.post<Result | Page<T>>(environment.baseUrl + uri,liste.mapRequest ? liste.mapRequest(searchSpec) : searchSpec,{params: this.getRequestParams(uri,liste,fonction)});
    }

    /**
     * Récupère la liste des @RequestParam à envoyer
     *
     * @param uri       URI déjà crée pour appeler le controller
     * @param liste     Liste concernée
     * @param fonction  Fonction de l'utilisateur connecté
     * @returns {string} String contenant tous les params à ajouter à l'URL
     */
    getRequestParams(uri: string, liste: ListView<T, K>, fonction: TypeProfil): HttpParams {
        let params: HttpParams = new HttpParams();

        //Si la liste connait déjà son nombre d'objets total
        if (liste.countTotal >= 0) {
            //On envoie le count pour gagner du temps sur la requête (à condition que le controller java soit fait pour prendre le count en compte)
            params = params.append("count", String(liste.countTotal));
        }
        //Si l'utilisateur n'est pas sur un profil collaborateur (les collaborateurs n'ont pas le droit au count asynchrone), et qu'il y a une gestion du count
        else if (fonction !== TypeProfil.COLLABORATEUR && !!liste.loadCount) {
            //On indique qu'on ne veut pas que le count soit exécuté
            params = params.append("noCount", "");
        }

        return params;
    }

    /**
     * Préchargement des données suivantes de la liste
     *
     * @param liste                 Liste de données
     * @param uri                   URL à appeler pour récupérer les données
     * @param numPage               Numéro de la page à afficher
     * @param nbObjetsParPage       Nombre d'objets à charger par page (forcé à 5 pour les listes du dashboard)
     * @param defaultOrder          Ordre par défaut à appliquer aux résultats de la requête
     * @param listeStaticFilters    Liste des filtres complémentaires pour le searchSpec
     * @param listeFilters          Liste des filtres pour le searchSpec
     * @param fonction              Fonction de l'utilisateur connecté
     */
    preloadNextData(liste: ListView<T, K>, uri: string, numPage: number, nbObjetsParPage: number = nbObjetsParPageDefaut, defaultOrder: string, listeStaticFilters: Filter[], listeFilters: Filter[], fonction: TypeProfil): void {
        //S'il n'y a pas déjà de données préchargées et si la liste n'est pas déjà complètement chargée
        if (!liste.preloadedData.getValue() && !liste.isDashboardList && !liste.isPreloading && !liste.isTotalementChargee()) {
            //Indicateur de préchargement
            liste.isPreloading = true;

            //Chargement de la liste pour la page suivante
            this.loadData(liste, uri, numPage + 1, nbObjetsParPage, defaultOrder, listeStaticFilters, listeFilters, fonction).pipe(
                first(),
                finalize(() => {
                        //Indicateur de préchargement
                        liste.isPreloading = false;
                    }
                )).subscribe((data) => {
                //Préchargement des données
                liste.preloadedData.next(data);
            });
        }
    }

    /**
     * Sauvegarde des paramètres appliqués
     *
     * @param liste Liste de données
     * @param fonction Fonction active de l'utilisateur
     * @param force permet de forcer la sauvegarde des params de la liste
     */
    public backupListeParams(liste: ListView<T, K>, fonction: number, force?: boolean): void {
        //Détection du paramètre de persistence et protection contre l'absence de nom de classe
        if (liste?.isPersistFilters && liste?.className && !liste?.isDashboardList || force) {
            //Sauvegarde des filtres appliqués
            if (liste.listeFilters) {
                this.sessionStorageService.save(liste.className, 'listeFilters_' + fonction.toString(), liste.listeFilters);
            }
            if (liste.listeSelectedFilters) {
                this.sessionStorageService.save(liste.className, 'listeSelectedFilters_' + fonction.toString(), liste.listeSelectedFilters);
            }
            if (liste.sorting?.getFormattedSorting()) {
                this.sessionStorageService.save(liste.className, 'defaultOrder_' + fonction.toString(), liste.sorting.getFormattedSorting());
            }
            if (liste.sorting?.search) {
                this.sessionStorageService.save(liste.className, 'search_' + fonction.toString(), liste.sorting.search);
            }
        }
    }

    /**
     * Réinitialisation du LocalStorage de la liste
     *
     * @param liste Liste de données
     * @param fonction Fonction active de l'utilisateur
     */
    resetListeParams(liste: ListView<T, K>, fonction: number): void {
        //Purge de toutes les clés associées à la liste
        this.sessionStorageService.remove(liste.className, 'listeFilters_' + fonction.toString());
        this.sessionStorageService.remove(liste.className, 'listeSelectedFilters_' + fonction.toString());
        this.sessionStorageService.remove(liste.className, 'defaultOrder_' + fonction.toString());
        this.sessionStorageService.remove(liste.className, 'search_' + fonction.toString());
    }

    /**
     * Consommation des données préchargées ou chargement des données
     *
     * @param liste                 Liste de données
     * @param uri                   URL à appeler pour récupérer les données
     * @param numPage               Numéro de la page à afficher
     * @param nbObjetsParPage       Nombre d'objets à charger par page (forcé à 5 pour les listes du dashboard)
     * @param defaultOrder          Ordre par défaut à appliquer aux résultats de la requête
     * @param listeStaticFilters    Liste des filtres complémentaires pour le searchSpec
     * @param listeFilters          Liste des filtres pour le searchSpec
     * @param fonction              Fonction de l'utilisateur connecté
     *
     * @return {Observable<any>}    Résultat de la requête
     */
    consumePreloadedDataOrLoadIt(liste: ListView<T, K>, uri: string, numPage: number, nbObjetsParPage: number = nbObjetsParPageDefaut, defaultOrder: string, listeStaticFilters: Filter[], listeFilters: Filter[], fonction: TypeProfil): Observable<Page<T>> {
        //Si des données sont préchargées
        if (liste.preloadedData.getValue()) {
            //Création d'un Observable simple avec les données préchargées
            const preloadedData: Observable<Page<T>> = of(liste.preloadedData.getValue());

            //Purge des données préchargées après consommation
            liste.preloadedData.next(undefined);

            //Préchargement des données suivantes
            this.preloadNextData(liste, uri, numPage, nbObjetsParPage, defaultOrder, listeStaticFilters, listeFilters, fonction);

            //Retour des données préchargées sous forme d'Observable
            return preloadedData;
        }
        //Sinon si le préchargement a commencé
        else if (liste.isPreloading) {
            //Création d'un Subject dédié
            const preloadedData: Subject<Page<T>> = new Subject<Page<T>>();

            //Abonnement au préchargement
            liste.preloadedData.asObservable().pipe(filter(data => !!data), distinctUntilChanged(), first()).subscribe(data => {
                //Déclenchement de l'Observable dédié
                preloadedData.next(data);

                //Purge des données préchargées après consommation
                liste.preloadedData.next(undefined);
            });

            //Préchargement des données suivantes
            this.preloadNextData(liste, uri, numPage, nbObjetsParPage, defaultOrder, listeStaticFilters, listeFilters, fonction);

            //Retour de l'Observable de préchargement
            return preloadedData.asObservable().pipe(first());
        }
        //Sinon
        else {
            //Préchargement des données suivantes
            setTimeout(() => this.preloadNextData(liste, uri, numPage, nbObjetsParPage, defaultOrder, listeStaticFilters, listeFilters, fonction));

            //Retour de l'Observable de chargement de la liste
            return this.loadData(liste, uri, numPage, nbObjetsParPage, defaultOrder, listeStaticFilters, listeFilters, fonction).pipe(first());
        }
    }

    /**
     * Chargement des données annexes
     */
    loadAnnexData(liste: ListView<T, K>): Observable<void> {
        //Uniquement pour les listes concernées
        if (liste.annexData) {
            //Chargement des données annexes
            return this.http.get<Result>(environment.baseUrl + liste.annexData.uri).pipe(
                map(result => liste.annexData.onLoad(result))
            );
        }

        //Retour
        return;
    }

    /**
     * Mise à jour de la pagination
     * @param liste Liste affichée
     * @param fonction Fonction de l'utilisateur connecté
     * @returns{string} la valeur de pagination à afficher
     */
    getPagination(liste: ListView<T, K>, fonction: TypeProfil): string {
        //Avec des nombres le truthy/falsy n'est pas fiable, car 0 est une valeur pertinente
        if (!liste.data || liste.data.numPage == null || liste.data.nbObjetsParPage == null || liste.data.nbObjetsDansPage == null || (liste.data.nbObjetsTotal == null && liste.countTotal < 0)) {
            return "";
        }

        //Si on a une vraie pagination (pas le count forcé pour l'optimisation)
        if (fonction === TypeProfil.COLLABORATEUR || liste.loadCount?.loadStatus.getValue() === CountStatus.LOADED || liste.data.nbObjetsTotal !== 9000000000000000000 || liste.nbElementsCharges < liste.data.nbObjetsParPage) {
            //Pour le nombre d'éléments total, si le count est loadé, on prend sa valeur
            //Sinon, s'il y a moins d'éléments chargés que le max d'une page, on prend cette valeur
            //Sinon, on prend le nombre d'éléments total
            const nbTotal = liste.loadCount?.loadStatus.getValue() === CountStatus.LOADED ? liste.countTotal
                : liste.nbElementsCharges < liste.data.nbObjetsParPage ? liste.nbElementsCharges : liste.data.nbObjetsTotal;
            //Traduction de la pagination
            return this.translateService.instant('liste.pagination', {nbElements: liste.nbElementsCharges, nbTotal: nbTotal});
        } else {
            //Si on est ici, c'est qu'on n'a pas encore le nombre de pages, donc on met un message en conséquence
            return this.translateService.instant('liste.paginationSansCount', {nbElements: liste.nbElementsCharges});
        }
    }

    /**
     * Chargement d'une liste simple
     * @param liste définition de la liste
     * @param uri endpoint
     */
    loadSimpleList(liste: ListView<T, K>, uri: string): Observable<T[]> {
        //Chargement des données
        return this.http.post<Result>(environment.baseUrl + uri, null).pipe(
            map(result => (liste.mapResult && liste.mapResult(result) || result) as Array<T>)
        );
    }

    /**
     * Mise à jour de la liste des filtres sélectionnés
     *
     * @param liste La listeview concernée
     */
    refreshListeSelectedFilters(liste: ListView<T, K>) {
        //Suppression des filtres sélectionnés et recréation
        liste.listeSelectedFilters = liste.listeFilters.map(filter => {
            if (filter.isSelected) {
                //Initialisation
                let displayedValeur = '';

                //Gestion des listes de choix
                if (filter.listeValues) {
                    //Application du libellé à la place de la valeur
                    if (filter.multiple) {
                        displayedValeur = this.formatFilterValuesMultiple(filter);
                    } else {
                        displayedValeur = filter.listeValues.find(v => v.code == filter.valeur).libelle;
                    }
                } else {
                    //Vérification du type de filtre
                    if (filter.displayedValeur) {
                        //Définition de la valeur à afficher
                        displayedValeur = filter.displayedValeur;
                    } else if (filter.valeur) {
                        //Définition de la valeur à afficher
                        if (filter.type == TypeFilter[TypeFilter.BOOLEAN] && filter.valeur != '') {
                            displayedValeur = this.translateService.instant(`filter.valeurOuiNon.${filter.valeur}`);
                        } else {
                            displayedValeur = filter.listeValues?.length > 0 ? filter.listeValues.find(value => value.code == filter.valeur)?.libelle : filter.valeur;
                        }
                    } else if (filter.type == TypeFilter[TypeFilter.DATE]) {
                        //Définition de la date de début
                        displayedValeur = this.translateService.instant(`filter.valeur.${filter.typeComparaison}`, {min: this.datePipe.transform(filter.dateDebut, 'shortDate'), max: this.datePipe.transform(filter.dateFin, 'shortDate')});
                    } else if (filter.type == TypeFilter[TypeFilter.DECIMAL]) {
                        //Définition de la valeur décimale
                        displayedValeur = this.translateService.instant(`filter.valeur.${filter.typeComparaison}`, {min: this.decimalPipe.transform(filter.min, '1.2-2'), max: this.decimalPipe.transform(filter.max, '1.2-2')});
                    } else if (filter.type == TypeFilter[TypeFilter.LONG]) {
                        //Définition de la valeur entière
                        displayedValeur = this.decimalPipe.transform(filter.min, '1.0-0');
                    }
                }

                //Si le filtre comporte une methode de sélection
                if (filter.selectMethod) {
                    //Appel de la methode de sélection
                    filter.selectMethod(filter);
                }

                //Retour
                return {
                    ...filter,
                    displayedValeur,
                    valeur: filter.valeur ? (filter.typeComparaison == TypeComparaison[TypeComparaison.LIKE] ? `${filter.valeur}%` : `${filter.valeur}`) : null
                };
            } else {
                //Si le filtre comporte une methode de sélection
                if (filter.selectMethod) {
                    //Appel de la methode de sélection
                    filter.selectMethod(filter);
                }

                //Retour
                return filter;
            }
        }).filter(filter => filter.isSelected);
    }

    /**
     * Formatage de la valeur à afficher pour un filtre à sélection multiple.<br />
     * Format de sortie : Valeur 1 ... valeur N
     *
     * @param filter Le filtre (doit être multiple)
     * @param max Le nombre maximum N de valeurs à afficher (2 par défaut)
     */
    formatFilterValuesMultiple(filter: Filter, max: number = 2): string {
        let strLibelles: string = '';
        let listeLibelle: Array<string>;
        let listeValeur: Array<any>;

        //Vérification que le filtre est multiple
        if (filter.multiple) {
            //Vérification de la présence de valeurs
            if (filter.listeObjects) {
                //Liste des n valeurs à afficher
                listeValeur = filter.listeObjects.slice(0, max);

                //Construction de la liste des libellés pour chaque valeur à sélectionner
                listeLibelle = filter.listeValues.filter(value => listeValeur.includes(value.code)).map(fv => fv.libelle);

                //Formatage
                strLibelles = listeLibelle.join(", ") + (listeLibelle.length < filter.listeObjects?.length ? ' (+' + (filter.listeObjects.length - listeLibelle.length) + ')' : '');
            }

            //Retour de la valeur formatée
            return strLibelles;
        } else {
            //Filtre non multiple : déclenchement d'une erreur qui, si elle n'est pas interceptée, affichera un message de type ERROR dans la console
            throw `Le filtre ${filter.clef} n'est pas multiple !`;
        }
    }

    /**
     * Charge le nombre total d'éléments de la liste
     *
     * @param liste                 Liste de données
     * @returns {Observable<number>} Observable qui va indiquer la valeur du count ou -1 si le count asynchrone n'est pas géré
     */
    loadCount(liste: ListView<T, K>): Observable<number> {
        //Si la liste a bien une gestion du count asynchrone et qu'on n'est pas sur une liste du dashboard
        if (!!liste.loadCount && !liste.isDashboardList) {
            liste.loadCount.loadStatus.next(CountStatus.LOADING);

            //Définition de la pagination
            const searchSpec: SearchSpec = this.buildSearchSpec(0, liste, liste.nbObjetsParPage ?? nbObjetsParPageDefaut, liste.defaultOrder, liste.listeStaticFilters, liste.listeSelectedFilters);

            //Chargement des données
            return this.http.post<Result>(environment.baseUrl + liste.loadCount.uri, liste.mapRequest ? liste.mapRequest(searchSpec) : searchSpec).pipe(
                first(),
                map(result => liste.loadCount.onLoad(result)),//On récupère la valeur du count depuis le result
                tap(count => {
                    //Si on a un count cohérent, on met à jour le nombre de pages de la liste
                    if (count >= 0) {
                        liste.countTotal = count;
                        liste.loadCount.loadStatus.next(CountStatus.LOADED)
                    }
                })
            );
        }

        //Si on est ici, c'est qu'on ne gère pas le count, on renvoie -1
        return of(-1);
    }


    /**
     * Construit le SearchSpec des requêtes
     *
     * @param liste                 Liste de données
     * @param numPage               Numéro de la page à afficher
     * @param nbObjetsParPage       Nombre d'objets à charger par page (forcé à 5 pour les listes du dashboard)
     * @param defaultOrder          Ordre par défaut à appliquer aux résultats de la requête
     * @param listeStaticFilters    Liste des filtres complémentaires pour le searchSpec
     * @param listeFilters          Liste des filtres pour le searchSpec
     * @return {SearchSpec}    Résultat de la requête
     */
    private buildSearchSpec(numPage: number, liste: ListView<T, K>, nbObjetsParPage: number, defaultOrder: string, listeStaticFilters: Filter[], listeFilters: Filter[]): SearchSpec {
        let listeFilterDTO = new Array<FilterDTO>();

        //On regroupe tous les filtres et les convertit en DTO
        [...(listeStaticFilters || []), ...listeFilters].forEach(filter => listeFilterDTO.push(new FilterDTO(filter)));

        //Définition de la pagination
        const searchSpec: SearchSpec = {
            numPage: numPage,
            nbObjetsParPage: liste.isDashboardList ? 5 : nbObjetsParPage,
            defaultOrder: defaultOrder,
            listeFilter: listeFilterDTO
        };

        //Paramétrage du filtre textuel
        if ((liste?.sorting || liste?.extraOptions?.searchType != null) && searchSpec.listeFilter) {
            searchSpec.listeFilter.forEach(f => {
                //Si le filtre est de type like
                if (f.typeComparaison == "LIKE") {
                    //Si le tri est paramétré en 'commence par'
                    if (liste?.sorting?.search == SearchType.STARTS_WITH || liste?.extraOptions?.searchType == SearchType.STARTS_WITH) {
                        //Suppression du %
                        if (f.valeur?.startsWith('%')) {
                            f.valeur = f.valeur.substring(1);
                        }
                    }
                    //Sinon 'contient'
                    else {
                        //Ajout du %
                        if (!f.valeur?.startsWith('%')) {
                            f.valeur = '%' + f.valeur;
                        }
                    }
                }
            });
        }

        return searchSpec;
    }
}