import {Component, Input} from '@angular/core';
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {ListViewItem} from "../../../domain/common/list-view/list-view-item";
import {AppState} from "../../../domain/appstate";
import {TypePortee} from "../../../domain/workflow/workflow";
import {SettingsGlobalState} from "../../../domain/settings/settings";

@Component({
  templateUrl: './lot-list-item.component.html'
})
/**
 * Composant d'affichage de lot comptable en liste
 */
export class LotListItemComponent extends ListViewItem<any> {

  /** Elément courant */
  @Input() data: any;

  /** Paramétrage */
  settings : SettingsGlobalState;

  /**
   * Constructeur
   */
  constructor(private router: Router, private store: Store<AppState>) {
    //Héritage
    super();
  }

  ngOnInit(): void {
    //Sélection du paramétrage
    this.store.select(state => state.settings?.[TypePortee.CPT]).subscribe(settings => this.settings = settings);
  }

  /**
   * Navigation vers le lot comptable
   * @param idLot Identifiant du lot
   */
  navigateToLot(idLot: number) {
    //Navigation vers le lot
    this.router.navigate(['Lot',idLot]);
  }
}
