import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Result} from '@domain/common/http/result';
import {Geographie} from "@domain/geolocalisation/geographie";
import {GeolocalisationTool} from "@domain/geolocalisation/geolocalisationTool";
import {Localisation} from "@domain/geolocalisation/localisation";
import {SaveAdresseResult} from "@domain/geolocalisation/saveAdresseResult";
import {Adresse} from "@domain/profil/adresse";
import {TypeEntiteParamOD,TypeEntiteParamOT,TypeEntiteParamPRF} from "@domain/typeentite/typeEntiteParam";
import {TypePortee} from "@domain/workflow/workflow";
import {environment} from '@environments/environment';
import {Observable} from 'rxjs';
import {first,map} from 'rxjs/operators';

/**
 * Service de gestion des lieux OD
 */
@Injectable()
export class LieuService {
    /**
     * Constructeur
     */
    constructor(private http: HttpClient) {}

    /**
     * Chargement des adresses
     *
     * @param idPortee Identifiant de la portée
     * @param idObject Identifiant de l'objet
     */
    loadAdresses(idPortee: TypePortee,idObject: number): Observable<any> {
        let url: string;

        //Portée ot ou od
        if(idPortee == TypePortee.OT || idPortee == TypePortee.OD) {
            //Définition de l'url suivant la portée
            const controller = idPortee == TypePortee.OT ? 'OMPermanent' : 'OD';
            url = `${environment.baseUrl}/controller/${controller}/${idObject}/getListeLieux`;
        } else if(idPortee == TypePortee.PRF) {
            //Portée profil
            url = `${environment.baseUrl}/controller/ProfilUser/getAdresse`;
        } else if(idPortee == TypePortee.ADM) {
            //Portée adm
            url = `${environment.baseUrl}/controller/Adresse/searchAdresse`;
        }

        //Chargement du chainage
        return this.http.post<Result>(url,null).pipe(
            first(),
            map(result => result.data)
        );
    }

    /**
     * Récupération du mode de géolocalisation et du quota de licence associé
     */
    getGeolocalisationTool(): Observable<GeolocalisationTool> {
        //Interrogation et récupération du résultat
        return this.http.post<Result>(`${environment.baseUrl}/controller/Geolocalisation/getGeolocalisationTool`,null).pipe(
            first(),
            map(result => result.data)
        );
    }

    /**
     * Interrogation du controller de géolocalisation
     *
     * @param geolocalisation Objet contenant les données nécéssaires à la géolocalisation
     */
    getListeLocalisation(geolocalisation: Localisation): Observable<Array<Localisation>> {
        //Définition de l'url suivant la portée
        const url = `${environment.baseUrl}/controller/Geolocalisation/getListeLocalisation`;

        //Interrogation et Récupération du résultat
        return this.http.post<Result>(url,geolocalisation).pipe(
            first(),
            map(result => result.data.listeGeolocalisation)
        );
    }

    /**
     * Enregistrement d'une adresse à partir d'une géolocalisation
     *
     * @param geolocalisation Adresse géolocalisée à enregistrer en base
     */
    saveAdresse(geolocalisation: Adresse): Observable<SaveAdresseResult> {
        let result: SaveAdresseResult;

        //Enregistrement de l'adresse pour la géolocalisation en paramètre
        return this.http.put<Result>(`${environment.baseUrl}/controller/Adresse/saveAdresse`,geolocalisation).pipe(
            first(),
            map(data => {
                //Construction de l'objet de retour contenant l'adresse et l'entité géographique associée
                result = {
                    adresse: {
                        idAdresse: data.data?.idAdresse,
                        rue: data.data?.rue,
                        ville: data.data?.ville,
                        codePostal: data.data?.codePostal,
                        pays: data.data?.pays,
                        codePays: data.data?.codePays,
                    },
                    geographie: this.parseGeographie(data.data?.geographie)
                }

                return result;
            })
        );
    }

    /**
     * Construit un objet Geographie à partir des data passées en paramètre
     *
     * @param data retournées par le controller
     */
    parseGeographie(data: any): Geographie {
        return {
            id: data.id,
            type: data.type,
            code: data.code,
            libelle: data.libelle,
            entiteGeo: JSON.parse(data.entiteGeoJSON)
        };
    }

    /**
     * Retourne la concaténation des différents champs constituant une adresse à partir des champs d'un objet de type Localisation.
     *
     * @param localisation Objet localisation
     */
    getAdresseFromLocalisation(localisation: Localisation): string {
        return [(localisation.rue ? localisation.rue : null),
                (localisation.codePostal || localisation.ville ? ((localisation.codePostal || '').trimRight() + ' ' + (localisation.ville || '').trimLeft()).trim() : null),
                (localisation.pays ? localisation.pays : null)
        ].filter(v => !!v).join(", ");
    }

    /**
     * Vérifie si le type entité autorise la saisie manuelle d'une adresse
     *
     * @param typeEntiteParam Paramétrage du type entité
     * @param idPortee Portée de l'objet
     */
    isSaisieAutorisee(typeEntiteParam: TypeEntiteParamOT | TypeEntiteParamOD | TypeEntiteParamPRF,idPortee: TypePortee): boolean {
        return (typeEntiteParam &&
                ((idPortee == TypePortee.OT && (<TypeEntiteParamOT>typeEntiteParam).saisieAdresseOT) 
                || (idPortee == TypePortee.OD && (<TypeEntiteParamOD>typeEntiteParam).saisieAdresseOD)))
                || (idPortee == TypePortee.PRF)
                || (idPortee == TypePortee.ADM);
    }

    /**
     * Format l'adresse en string
     *
     * @param adresse Objet adresse qui sera formatée
     * @return le string formaté de l'adresse
     */
    formatAdressetoString(adresse: Adresse): string {
        return [adresse?.rue, adresse?.ville, adresse?.pays].filter(v => !!v).join(', ');
    }

    /**
     * Vérifie si des informations obligatoires sont manquantes sur une adresse
     *
     * @param adresse Adresse à vérifier
     */
    isAdresseIncomplete(adresse: Adresse): boolean {
        return !adresse.rue || !adresse.ville || !adresse.pays;
    }

}
