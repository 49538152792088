/**
 * Interface représentant un statut générique de l'application
 */
export interface IStatutApplication {
    statut: KeyStatutApplication|KeyStatutWorkflow;
    messagePrincipal?: string;
    messageSecondaire?: string;
    isLongPolling: boolean;
}

/**
 * Enum des clés de statuts de l'application
 */
export enum KeyStatutApplication {
    LANCEMENT = 'LANCEMENT',
    PRE_MIGRATION = 'PRE_MIGRATION',
    MIGRATION = 'MIGRATION',
    MAINTENANCE = 'MAINTENANCE',
    EN_LIGNE = 'EN_LIGNE',
    LOCKED = 'LOCKED'
}

/**
 * Enum des clés de statuts du Workflow
 */
export enum KeyStatutWorkflow {
    HISTO_WF_COUNTDOWN = 'HISTO_WF_COUNTDOWN',
    HISTO_WF_SYNCHRO = 'HISTO_WF_SYNCHRO',
    HISTO_WF_NONE = 'HISTO_WF_NONE'
}

/**
 * Liste des possibilités de statuts de l'application
 */
export const LISTE_STATUTS_APPLICATION: Map<KeyStatutApplication,IStatutApplication> = new Map([
    [
        KeyStatutApplication.LANCEMENT,{
        statut: KeyStatutApplication.LANCEMENT,
        messagePrincipal: 'admin.statut.application.lancement',
        messageSecondaire: null,
        isLongPolling: true
    }],[
        KeyStatutApplication.PRE_MIGRATION,{
        statut: KeyStatutApplication.PRE_MIGRATION,
        messagePrincipal: 'admin.statut.application.preMigration1',
        messageSecondaire: 'admin.statut.application.preMigration2',
        isLongPolling: true
    }],[
        KeyStatutApplication.MIGRATION,{
        statut: KeyStatutApplication.MIGRATION,
        messagePrincipal: 'admin.statut.application.migration1',
        messageSecondaire: 'admin.statut.application.migration2',
        isLongPolling: true
    }],[
        KeyStatutApplication.MAINTENANCE,{
        statut: KeyStatutApplication.MAINTENANCE,
        messagePrincipal: 'admin.statut.application.maintenance1',
        messageSecondaire: 'admin.statut.application.maintenance2',
        isLongPolling: true
    }],[
        KeyStatutApplication.EN_LIGNE,{
        statut: KeyStatutApplication.EN_LIGNE,
        messagePrincipal: null,
        messageSecondaire: null,
        isLongPolling: false
    }],[
        KeyStatutApplication.LOCKED,{
        statut: KeyStatutApplication.LOCKED,
        messagePrincipal: 'admin.statut.application.locked1',
        messageSecondaire: null,
        isLongPolling: false
    }]
]);

/**
 * Liste des possibilités de statuts du workflow
 */
export const LISTE_STATUTS_WORKFLOW: Map<KeyStatutWorkflow,IStatutApplication> = new Map([
    [
        KeyStatutWorkflow.HISTO_WF_COUNTDOWN,{
        statut: KeyStatutWorkflow.HISTO_WF_COUNTDOWN,
        messagePrincipal: 'admin.statut.workflow.countdown1',
        messageSecondaire: 'admin.statut.workflow.countdown2',
        isLongPolling: true,
        tempo: 0
    }],[
        KeyStatutWorkflow.HISTO_WF_SYNCHRO,{
        statut: KeyStatutWorkflow.HISTO_WF_SYNCHRO,
        messagePrincipal: 'admin.statut.workflow.synchro1',
        messageSecondaire: 'admin.statut.workflow.synchro2',
        isLongPolling: true
    }],[
        KeyStatutWorkflow.HISTO_WF_NONE,{
        statut: KeyStatutWorkflow.HISTO_WF_NONE,
        messagePrincipal: null,
        messageSecondaire: null,
        isLongPolling: false
    }]
]);
