import {NgModule} from "@angular/core";
import {Routes} from "@angular/router";
import {ShareModule} from "@share/share.module";
import {TravelhubComponent} from "./travelhub/travelhub.component";
import {AdminTravelhubComponent} from "@components/admin/voyages/travelhub/admin-travelhub.component";
import {AdminTravelhubService} from "@components/admin/voyages/travelhub/admin-travelhub.service";
import {SbtConfigComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config.component";
import {SbtConfigAddComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/sbt-config-add.component";
import {AdminTravelhubTravelhubModule} from "@components/admin/voyages/travelhub/travelhub/admin-travelhub-travelhub.module";
import {SbtConfigModule} from "@components/admin/voyages/travelhub/sbt-config/sbt-config.module";
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {IframeProfilComponent} from "./iframe-profil/iframe-profil.component";

export const adminTravelhubRoutes: Routes = [
	{
		path: 'Config',
		component: TravelhubComponent
	},{
		path: 'SbtConfig/:id/copy',
		component: SbtConfigAddComponent,
		data: {
			copy: true
		}
	},{
		path: 'SbtConfig/:id',
		component: SbtConfigAddComponent
	},{
		path: 'SbtConfig',
		component: SbtConfigComponent
	},{
		path: 'IframeProfil',
		component: IframeProfilComponent
	},{
		path: '',
		redirectTo: 'Config',
		pathMatch: 'full'
	},{
		path: '**',
		redirectTo: 'Config'
	}
];

/**
 * Module d'administration du module Voyages / TravelHub
 *
 * @author Laurent Convert
 * @date 02/11/2023
 */
@NgModule({
	imports: [ShareModule,AdminTravelhubTravelhubModule,SbtConfigModule,PreviewAlerteModule],
	declarations: [AdminTravelhubComponent,IframeProfilComponent],
	exports: [AdminTravelhubComponent],
	providers: [AdminTravelhubService]
})
export class AdminTravelhubModule {}