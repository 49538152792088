import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Vehicule} from "../../../../domain/vehicule/vehicule";
import {CarteGrise} from "../../../../domain/vehicule/cartegrise";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SettingsVehiculeState} from "../../../../domain/settings/settings";
import {VehiculeService} from "../../vehicule.service";
import {filter, first} from "rxjs/operators";
import {Result, TypeCodeErreur} from "../../../../domain/common/http/result";
import {TypeDocument} from "../../../../domain/vehicule/typeDocument";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmService} from "../../../../share/component/confirmation/confirm.service";
import {DocumentListComponent} from "../../../document/document-list.component";
import * as moment from "moment";
import {DateUtilsService} from "../../../../share/utils/date-utils/date-utils.service";

@Component({
    host: {'data-test-id': 'vehicule-carte-grise'},
    templateUrl: './vehicule-carte-grise.component.html'
})
/**
 * Composant de gestion des cartes grises pour les véhicules
 */
export class VehiculeCarteGriseComponent implements OnInit {
    /** Déclaration pour accès direct dans le template */
    DateUtilsService = DateUtilsService;

    /** Indicateur d'enregistrement en cours */
    isSaving: boolean = false;

    /** Carte grise */
    carteGrise: CarteGrise;

    /** Vehicule auquel est rattaché la carte grise */
    vehicule: Vehicule;

    /** Paramètres de la page de véhicule */
    settings: SettingsVehiculeState;

    /** Booléen pour savoir si on peut faire des modifications */
    canModifier: boolean = true;

    /** Date d'aujourd'hui pour les contrôles de date dans le formulaire */
    aujourdhui: moment.Moment = moment().startOf('day');

    /** Référence vers le composant de la liste des documents */
    @ViewChild('listeDocument')
    listeDocument?: DocumentListComponent;

    constructor(
        private dialogRef: MatDialogRef<VehiculeCarteGriseComponent>,
        private vehiculeService: VehiculeService,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private confirmService: ConfirmService,
        @Inject(MAT_DIALOG_DATA) public data: {
            vehicule: any,
            settings: SettingsVehiculeState,
            idDocument?: any,
            canModifier: boolean
        }) {
    }

    ngOnInit(): void {
        //Récupération des différentes données
        this.canModifier = this.data.canModifier;
        this.vehicule = this.data.vehicule;
        this.settings = this.data.settings;

        //Chargement de la carte grise en cas de modification
        if (this.data.idDocument != 0) {
            this.vehiculeService.loadVehiculeCarteGrise(this.data.idDocument).pipe(first()).subscribe(res => {
                this.carteGrise = Object.assign(new CarteGrise(),res.data.vehiculeCarteGrise);
            });
        } else {
            this.carteGrise = new CarteGrise();
            this.carteGrise.idPa = this.vehicule.idPa;
        }

    }

    /**
     * Sauvegarde de la carte grise
     */
    saveCarteGrise() {
        //Enregistrement en cours
        this.isSaving = true;

        this.vehiculeService.saveVehiculeDocument(this.carteGrise, TypeDocument.CARTE_GRISE).pipe(first()).subscribe({
            next: result => {
                //Vérification de l'enregistrement
                if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                    const data: {idCarteGrise: number} = result.data;

                    //Vérification de l'enregistrement
                    if (data.idCarteGrise) {
                        //S'il y a des documents en attente de liaison avec l'objet
                        if (this.listeDocument?.hasPendingDocumentToLink()) {
                            //Liaison des documents
                            this.listeDocument.linkAllPendingDocument(data.idCarteGrise).subscribe((success) => {
                                //Vérification du succès de l'opération
                                if (success) {
                                    //Message d'information
                                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                                    //Fermeture de l'écran
                                    this.dialogRef.close(true);
                                } else {
                                    //Message d'erreur
                                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                                }
                            });
                        } else {
                            //Message d'information
                            this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                            //Fermeture de l'écran
                            this.dialogRef.close(true);
                        }
                    } else {
                        //Message d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                    }
                } else {
                    //Message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                }
            },
            complete: () => {
                //Fin de l'enregistrement
                this.isSaving = false;
            }
        });
    }

    /**
     * Supression de la carte grise
     */
    deleteCarteGrise() {
        //Message de confirmation
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                this.vehiculeService.deleteVehiculeCarteGrise(this.carteGrise.id).subscribe({
                    next: (result: Result) => {
                        //Vérification du code erreur
                        if (result?.codeErreur == 0) {
                            //Affichage d'un message de succès
                            this.toastrService.success(this.translateService.instant('global.success.suppression'));
                            //Fermeture de la popup
                            this.dialogRef.close(true);
                        } else {
                            //Affichage d'un message d'erreur
                            this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                        }
                    },
                    error: () => {
                        //Affichage d'un message d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                    }
                });
            }
        });
    }

}
