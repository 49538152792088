<div class="with-floating-button">
    <div class="content" *ngIf="objetWorkflow">
        <page-header #pageHeaderComponent [title]="objetWorkflow.idFacture > 0 ? ('facture.title' | translate:{ numero: objetWorkflow.numero }) : ('facture.titleCreation' | translate)"
                     [extraInfo]="objetWorkflow.statut?.libelle" [listeItems]="listeTabItems" [niveauAlerte]="listeAlertes?.niveau"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()" (onExtraInfoClick)="showChainage()" [isPending]="isSaving || isLoading">
            <preview-alerte #alerte *ngFor="let alerte of listeAlertes?.listeAlertes" [alerte]="alerte" [translateAlerte]="!!alerte.traductionParams"></preview-alerte>
        </page-header>

        <!-- Contenu de l'onglet "Généralités" -->
        <div [hidden]="selectedItem?.code !== 'GENERALITES'">
            <!-- Encart  -->
            <facture-generalites [facture]="objetWorkflow" [releve]="releve" [idPeriodeCourante]="idPeriodeCourante" [listeFieldParam]="listeFieldParam"
                                 [user]="user" [canModifier]="canModifier" [settings]="$any(settings)" [typeEntiteParam]="typeEntiteParam"
                                 (isLoading)="this.isLoading = $event"
                                 (refreshListeFactureReference)="refreshListeFactureReference()"
                                 (updateAnalytique)="updateAnalytique(objetWorkflow,$event)"></facture-generalites>

            <!-- Liste des ventilations analytiques de la facture -->
            <analytique *ngIf="objetWorkflow.listeVentilations != null"
                #analytique="analytique"
                [listeAnalytiques]="objetWorkflow.listeVentilations"
                [typeEntite]="objetWorkflow.typeEntite" [settings]="settings"
                [canModifier]="canModifier" [canSaisie]="canModifierAnalytique"
                [preferenceAnalytiqueCount]="preferenceAnalytiqueCount"
                [idCollab]="objetWorkflow.getIdCollab()"
                (onModification)="buildListeAlertes()">
            </analytique>

            <!-- Cadre enveloppes budgétaires -->
            <suivi-budget *ngIf="objetWorkflow.idFacture > 0" [idTypeObjet]="TypePorteeInt.FC" [idObjet]="objetWorkflow.idFacture"></suivi-budget>
        </div>

        <!-- Contenu de l'onglet "Détail" -->
        <div *ngIf="secondaryTabsAllowed && selectedItem?.code == 'DETAIL'">
            <!-- Détail de la facture -->
            <facture-detail-list [facture]="objetWorkflow" [settings]="settings" [typeEntiteParam]="typeEntiteParam"
                                 [canModifier]="canModifier && !!releve && !releve.importe"
                                 (onListeChanged)="quickReload(objetWorkflow.idFacture)"
            ></facture-detail-list>
        </div>

        <!-- Contenu de l'onglet "Contentieux" -->
        <div *ngIf="secondaryTabsAllowed" [hidden]="selectedItem?.code !== 'CONTENTIEUX'">
            <!-- Encart "Contentieux" -->
            <facture-contentieux [facture]="objetWorkflow" [contentieux]="contentieux" [canModifier]="canModifier || canCompleter" (onDesactivationAlerte)="buildListeAlertes(objetWorkflow)"></facture-contentieux>
            <!-- Liste des factures de référence -->
            <facture-reference-list [facture]="objetWorkflow" [contentieux]="contentieux" [user]="user" [canModifier]="canModifier || canCompleter"></facture-reference-list>
        </div>

        <!-- Contenu de l'onglet "Compléments" -->
        <div *ngIf="secondaryTabsAllowed && selectedItem?.code == 'COMPLEMENTS'">
            <!-- Liste des documents associés à la facture -->
            <document-list
                    [idObjet]="objetWorkflow.idFacture"
                    [idObjetForPJ]="objetWorkflow.idFacture"
                    [idCollaborateur]="idCollaborateur"
                    [context]="'FACTURE'"
                    [settings]="settings"
                    [canAddDocument]="(canModifier || canCompleter) && settingsService.isPjParamForTypeEntite(settings,objetWorkflow.getTypeEntite().idTypeEntite)"
                    [canDeleteDocument]="(canModifier || canCompleter) && settingsService.isPjParamForTypeEntite(settings,objetWorkflow.getTypeEntite().idTypeEntite)"
            ></document-list>

            <!-- Liste des référentiels externes -->
            <referentiel-externe *ngIf="settings?.isReferentielExterneAvailable" [typeReferentiel]="portee" [idObject]="objetWorkflow.idFacture"></referentiel-externe>
        </div>

        <!-- Contenu de l'onglet "Workflow" -->
        <div *ngIf="selectedItem?.code === OngletsAdmin.WORKFLOW">
            <onglet-workflow
                    [isObjetValide]="isValid()"
                    [portee]="TypePortee.FC"
                    [listeVentilations]="objetWorkflow.listeVentilations"
                    [typeEntite]="objetWorkflow.typeEntite"
                    [globalSettings]="settings"
                    [idObjet]="objetWorkflow.idFacture">
            </onglet-workflow>
        </div>

        <!-- Contenu de l'onglet "Outils" -->
        <div *ngIf="selectedItem?.isLoaded" [hidden]="selectedItem?.code != 'OUTILS'">
            <outils [idObjet]="objetWorkflow.idFacture" [typePortee]="TypePortee.FC" [isObjetValide]="isValid()"></outils>
        </div>

    </div>
    <please-wait class="content" *ngIf="objetWorkflow === undefined"></please-wait>
    <floating-button [listeActions]="listeActions" [isPending]="isSaving || isLoading"></floating-button>
</div>

<!-- Template spécial pour l'alerte sur les écarts de montant -->
<ng-template #tplAlerteEcart let-ctx="ctx">
    <p>{{ 'facture.alerte.ecartMontant.message' | translate }}&nbsp;<a [routerLink]="" (click)="ctx?.action();"><strong><mat-icon class="align-middle">search</mat-icon>&nbsp;{{ 'facture.alerte.ecartMontant.action' | translate }}</strong></a></p>
</ng-template>

<!-- Template spécial pour l'alerte désactivée -->
<ng-template #tplAlerteDesactivee let-ctx="ctx">
    <p>{{ 'facture.alerte.alerteDesactivee.message' | translate }}&nbsp;<a [routerLink]="" (click)="ctx?.action();"><strong><mat-icon class="align-middle">search</mat-icon>&nbsp;{{ 'facture.alerte.alerteDesactivee.action' | translate }}</strong></a></p>
</ng-template>

<!-- Template pour l'affichage de la popin de détail de l'alerte sur les montants -->
<ng-template #tplAlerteMontant let-ctx="ctx">
    <form>
        <!-- Titre/Message (transverse au 2 alertes) -->
        <div class="row">
            <div class="col pb-1">{{ 'facture.liste.alerte.' + ctx.type + '.message' | translate }}</div>
        </div>
        <!-- Détail des montants de l'alerte de type 'factureMontantControle' -->
        <ng-container *ngIf="ctx.type === TypeAlerteEcartMontant.FACTURE_MONTANT_CONTROLE">
            <div class="row">
                <div class="col-md-auto">
                    <div class="row">
                        <div class="col-md-auto">{{ 'facture.liste.alerte.factureMontantControle.montantFacture' | translate }}</div>
                        <div class="col-md-auto text-right flex-grow-1">{{ ctx.objet.montant | montant:ctx.objet.devise }}</div>
                    </div>
                    <div class="row">
                        <div class="col-md-auto">{{ 'facture.liste.alerte.factureMontantControle.montantControle' | translate }}</div>
                        <div class="col-md-auto text-right flex-grow-1">{{ ctx.objet.montantControle | montant:ctx.objet.devise }}</div>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- Détail des montants de l'alerte de type 'factureEcartDevis' -->
        <ng-container *ngIf="ctx.type === TypeAlerteEcartMontant.FACTURE_ECART_DEVIS">
            <div class="row">
                <div class="col-md-auto">
                    <div class="row">
                        <div class="col-md-auto">{{ 'facture.liste.alerte.factureEcartDevis.montantFactures' | translate }}</div>
                        <div class="col-md-auto text-right flex-grow-1">{{ ctx.objet.montantTotal | montant:ctx.objet.devise }}</div>
                    </div>
                    <div class="row">
                        <div class="col-md-auto">{{ 'facture.liste.alerte.factureEcartDevis.montantDevis' | translate }}</div>
                        <div class="col-md-auto text-right flex-grow-1">{{ ctx.objet.montantDevis | montant:ctx.objet.devise }}</div>
                    </div>
                </div>
            </div>
        </ng-container>
    </form>
</ng-template>
