import {Component,OnInit,ViewChild} from "@angular/core";
import {ActivatedRoute,Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TranslateService} from "@ngx-translate/core";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {NgForm} from "@angular/forms";
import {Orga} from "@domain/admin/entreprise/analytique/orga";
import {AnalytiqueService} from "@services/admin/entreprise/analytique.service";
import {TypeCodeErreur} from "@domain/common/http/result";
import {
	AnalytiqueOrganigrammeOrgaListeUtilisateurComponent
} from "@components/admin/entreprise/analytique/organigramme/organigramme-infos/organigramme-liste-utilisateur/organigramme-liste-utilisateur.component";
import {filter,finalize} from "rxjs/operators";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import * as moment from "moment";
import {BehaviorSubject} from "rxjs";
import {TypePortee} from "@domain/workflow/workflow";
import {LieuProfil} from "@domain/lieu/lieuProfil";
import {LieuService} from "@components/lieu/lieu.service";
import {ContextDocument} from "@domain/document/context-document";
import {DocumentListComponent} from "@components/document/document-list.component";
import {SettingsGlobalState} from "@domain/settings/settings";

/**
 * Enum des différents onglets disponibles sur la page
 */
export enum OngletsOrganigramme {
	GENERALITES = "Généralités",
	SERVICES_LIES = "Services liés"
}

/**
 * Composant d'affichage du formulaire d'informations d'un service
 */
@Component({
	host: {'data-test-id': 'analytique-organigramme-infos'},
	templateUrl: './analytique-organigramme-infos.component.html'
})
export class AnalytiqueOrganigrammeInfosComponent implements OnInit {
	private readonly ERROR_AFFECTATION_PARENT: number = 902;

	/** Indique si le formulaire a été ouvert en création de service ou en modification */
	isCreation: boolean = false;

	/** Liste des différentes sous services du service sélectionné */
	listeSousServices: Orga[];

	/** Liste des différents onglets disponibles dans le sous-menu organigramme */
	listeTabItems: Array<PageHeaderItem> = [{
		code: OngletsOrganigramme.GENERALITES,
		libelle: this.translateService.instant('admin.entreprise.analytique.organigramme.onglets.generalites')
	}];

	/** Onglet courant */
	selectedItem: PageHeaderItem = this.listeTabItems[0];

	/** Date de clôture du service */
	dateCloture: Date;

	/** Adresse en cours de saisie */
	adresse: LieuProfil;

	/** Permet de savoir le nombre d'utilisateurs affectés à ce service */
	libelleUtilisateursAffectes: string;

	/** Organisation sélectionnée */
	orga: Orga;

	/** Formulaire de la page */
	@ViewChild('form') form: NgForm;

	/** Composant de liste des logos */
	@ViewChild('listeLogos') logoComponent: DocumentListComponent;

	/** Liste des logos */
	listeFichierLogo: any[];

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Référence vers l'enum pour l'utiliser dans le template */
	Onglets = OngletsOrganigramme;

	/** Pour permettre le pending sur le floating button */
	isSaving: boolean;

	/** Paramètres de la liste des logos */
	readonly settings: SettingsGlobalState = {
		listeExtensions: ["gif"]
	} as SettingsGlobalState;

	/**
	 * Constructeur
	 *
	 * @param activatedRoute Route d'accès à cette page
	 * @param confirmService Service de confirmation
	 * @param translateService Service de traduction
	 * @param matDialog Boîte de dialogue
	 * @param toastrService Service pour afficher les messages de succès, d'erreurs, ...
	 * @param analytiqueService Service de gestion des informations sur l'analytique
	 * @param router Router de l'application pour naviguer entre les pages
	 * @param lieuService Service de gestion des adresses / lieux
	 */
	constructor(private activatedRoute: ActivatedRoute,
				private confirmService: ConfirmService,
				private translateService: TranslateService,
				private matDialog: MatDialog,
				private toastrService: ToastrService,
				private analytiqueService: AnalytiqueService,
				private router: Router,
				private lieuService: LieuService) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de l'identifiant du service à afficher
		this.onInit(this.activatedRoute.snapshot.params.idService);
	}

	/**
	 * Méthode appelée lors de la suppression d'un logo dans l'uploader.
	 */
	documentRemoved() {
		//Suppression de la référence au logo dans l'orga
		this.orga.idLogo = null;
	}

	/**
	 * Méthode appelée lorsque l'identifiant contenu dans l'url a été récupéré
	 *
	 * @param idOrga Identifiant, du service, se trouvant dans l'url
	 */
	onInit(idOrga: string) {
		//On est en mode création si l'id indiqué dans l'url est -1 sinon on est en mode modification
		this.isCreation = idOrga === "-1";

		//Initialisation des actions possibles
		this.listeActions.next([{
			type: TypeAction.SECONDARY,
			icone: 'nio icon-sauvegarde',
			libelle: 'global.actions.enregistrer',
			doAction: () => this.save(),
		}]);

		//Si on est en mode création, alors on initialise un nouveau service
		if (this.isCreation) {
			//Initialisation du nouveau service
			this.orga = new Orga({
				code: "",
				idParent: 0,
				position: 0,
				numService: "",
				libelle: "",
				isActif: true,
				tsio: 0,
				imputable: true,
				affectable: true,
				etablissement: false,
				attribut1: "",
				attribut2: "",
				attribut3: "",
				attribut4: ""
			});

			//Mise en place de la phrase concernant le nombre d'utilisateurs affectés à ce service
			this.libelleUtilisateursAffectes = this.translateService.instant('admin.entreprise.analytique.organigramme.infos.nbUtilisateurs',{nbUsers: 0});
		} else {
			//Si on est en mode modification, alors on ajoute la possibilité de supprimer le service
			this.listeActions.next([
				...this.listeActions.getValue(),
				{
					type: TypeAction.SECONDARY,
					icone: 'nio icon-suppression',
					libelle: 'global.actions.supprimer',
					doAction: () => this.delete(),
				}
			])

			//Récupération de l'organisation à modifier
			this.analytiqueService.getOrga(Number(idOrga)).subscribe(data => {
				this.orga = new Orga(data.data.orga);

				//Récupération de la liste des sous services pour l'afficher dans l'onglet "Services liés" (si pas déjà affiché)
				this.analytiqueService.showSousService(Number(idOrga)).subscribe(data => {
					this.listeSousServices = data.data.listeSousServices;
					if (this.listeSousServices?.length > 0 && !this.listeTabItems.some(i => i.code === OngletsOrganigramme.SERVICES_LIES)) {
						this.listeTabItems.push({
							code: OngletsOrganigramme.SERVICES_LIES,
							libelle: this.translateService.instant('admin.entreprise.analytique.organigramme.onglets.servicesLies')
						});
					}
				});

				//Si l'orga a un parent, on affiche l'onglet Services liés (si pas déjà affiché)
				if (this.orga.parent && !this.listeTabItems.some(i => i.code === OngletsOrganigramme.SERVICES_LIES)) {
					this.listeTabItems.push({
						code: OngletsOrganigramme.SERVICES_LIES,
						libelle: this.translateService.instant('admin.entreprise.analytique.organigramme.onglets.servicesLies')
					});
				}

				//Initialisation du logo
				if (this.orga.nomLogo) {
					this.listeFichierLogo = [{
						libelle: this.orga.nomLogo,
						fichier: this.orga.nomLogo,
						idDocument: this.orga.idLogo,
						taille: this.orga.tailleLogo,
						contentType: "image/gif"
					}];
				}

				//Formatage de l'adresse si présente
				if (this.orga.adresse) {
					this.adresse = {...this.orga.adresse,...{adresse: this.lieuService.getAdresseFromLocalisation(this.orga.adresse)}};
				}

				//Récupération du nombre d'utilisateurs affectés à ce service
				this.analytiqueService.getNbUsers(this.orga.idOrga).subscribe(data => {
					this.libelleUtilisateursAffectes = this.translateService.instant('admin.entreprise.analytique.organigramme.infos.nbUtilisateurs',{nbUsers: data.data.nbUsers});
				});
			});
		}
	}

	/**
	 * Méthode appelée lors de la sauvegarde d'un service en base
	 */
	save() {
		//Si le formulaire est valide alors on enregistre le service
		if (this.form.valid) {
			this.isSaving = true;
			//Changement de l'idLoc du service pour l'enregistrement en base
			if (this.orga.adresse) {
				//Affectation à idLoc de l'id de l'adresse sélectionnée
				this.orga.idLoc = this.orga.adresse.idAdresse;
			}

			//Enregistrement du service en base
			this.analytiqueService.saveOrga(this.orga,this.logoComponent?.basicFile).subscribe(result => {
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Si aucune erreur n'est renvoyée, alors on affiche un message de succès
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Effacement du fichier fourni
					if (this.logoComponent) {
						this.logoComponent.basicFile = undefined;
					}

					//Navigation vers l'organigramme
					this.onInit(this.orga.idOrga.toString());
				} else if (result.codeErreur === TypeCodeErreur.ERROR_DOUBLON) {
					//Si une erreur doublon est renvoyée alors on affiche un message d'erreur
					this.toastrService.error(this.translateService.instant('admin.entreprise.analytique.organigramme.erreurDoublon'));
				} else if (result.codeErreur === this.ERROR_AFFECTATION_PARENT) {
					//Si une erreur est renvoyée avec le code 902 alors c'est qu'on affecte un parent à son enfant
					this.toastrService.error(this.translateService.instant('admin.entreprise.analytique.organigramme.erreurParent'));
				} else {
					//Si une erreur est renvoyée alors on affiche un message d'erreur
					this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
				}
			},finalize(() => this.isSaving = false));
		} else {
			//Si, au contraire, le formulaire n'est pas valide alors affichage d'un message disant que le formulaire n'est pas valide
			this.toastrService.error(this.translateService.instant('global.errors.formInvalid'));
		}

	}

	/**
	 * Méthode appelée lors de la suppression d'un service
	 */
	delete() {
		//Affichage de la popin de confirmation de suppression du service
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
			next: () => {
				//Si l'utilisateur a appuyé sur valider alors on supprime le service en base
				this.analytiqueService.deleteOrga(this.orga.idOrga).subscribe(data => {
					if (data.codeErreur === TypeCodeErreur.NO_ERROR) {
						//Si aucune erreur n'est renvoyée alors on affiche un message de succès
						this.toastrService.success(this.translateService.instant('global.success.suppression'));
						//Navigation vers l'organigramme
						this.router.navigate(['Admin/Entreprise/Analytique/Services']);
					} else {
						//Si une erreur renvoyée alors on affiche un message d'erreur
						this.toastrService.error(this.translateService.instant('global.errors.suppression'));
					}
				})
			}
		});
	}

	/**
	 * Mise à jour de l'adresse sur l'objet par celle sélectionnée
	 *
	 * @param adresse Adresse
	 */
	setAdresse(adresse: LieuProfil) {
		//Mise à jour de l'identifiant de l'adresse
		this.orga.idLoc = adresse.idLocalisation;

		//Suppression de l'objet qui ne sert plus à rien et qui n'a pas besoin d'être envoyé au back pour l'enregistrement
		//(l'affichage se base sur l'objet défini dans ce composant, et l'enregistrement sur l'id)
		this.orga.adresse = null;
	}


	/**
	 * Fonction de vidage du champ adresse
	 */
	clearAdresse = () => {
		this.orga.idLoc = null;
		this.orga.adresse = null;
		this.adresse = null;
	}

	/**
	 * Méthode appelée lors de l'ouverture de la liste des utilisateurs affectés à ce service
	 */
	openUsers() {
		//Ouverture de la boîte de dialogue pour afficher la liste des utilisateurs
		this.matDialog.open(AnalytiqueOrganigrammeOrgaListeUtilisateurComponent,{
			width: '80%',
			data: {
				idOrga: this.orga.idOrga
			}
		});
	}

	/**
	 * Changement d'onglet
	 *
	 * @param selectedItem Onglet sélectionné
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem): void {
		this.selectedItem = selectedItem;
	}

	/**
	 * Retour à la page précédente
	 */
	onGoBack(): void {
		this.router.navigateByUrl('Admin/Entreprise/Analytique/Services');
	}

	updateActif(event: moment.Moment) {
		let momentToday: moment.Moment;
		let dateCloture: moment.Moment;

		momentToday = moment().startOf('day');
		dateCloture = moment(this.orga.dateFin).startOf('day');

		if (dateCloture.isBefore(momentToday)) {
			//Demande de confirmation de désactivation de l'orga
			this.confirmDesactivation();
		}
	}

	/**
	 * Demande de confirmation de désactivation de l'orga
	 *
	 * @param isSetDateJour true si la date du jour doit être définie pour la date de clôture en cas de confirmation
	 */
	private confirmDesactivation(isSetDateJour: boolean = false) {
		//Affichage de la popin de confirmation de désactivation du service
		this.confirmService.showConfirm(this.translateService.instant('admin.entreprise.analytique.organigramme.desactivation'))
			.subscribe((isConfirmed) => {
				//Vérification de la confirmation du message
				if (isConfirmed) {
					//Confirmé : le service passe inactif
					this.orga.isActif = false;

					//Si on doit définir la date de fermeture
					if (isSetDateJour) {
						//On la définit à la date du jour
						this.orga.dateFin = new Date();
					}
				} else {
					//On s'assure que le service reste actif
					this.orga.isActif = true;

					//Annulation : suppression de la date de clôture
					this.orga.dateFin = null;
				}
			});
	}

	/**
	 * Méthode appelée au clic sur la checkbox de isActif
	 */
	actifChange() {
		//Si on active l'orga
		if (this.orga.isActif) {
			//On retire la date de fin
			this.orga.dateFin = null;
		} else {
			//Si on désactive l'orga, on demande confirmation
			this.confirmDesactivation(true);
		}
	}

	readonly TypePortee = TypePortee;
	readonly ContextDocument = ContextDocument;
}
