import {Injectable} from "@angular/core";
import {IObjetWorkflowService} from "../workflow/objet-workflow.service";
import {Facture} from "@domain/facture/facture";
import {Observable,of,throwError} from "rxjs";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {HttpClient,HttpParams} from "@angular/common/http";
import {first,switchMap} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {NavigationExtras,Router} from "@angular/router";
import {PeriodeDTO} from "@domain/exercice/periodeDTO";
import {LigneRapprochement} from "@domain/facture/ligne-rapprochement";
import {Field} from "@domain/settings/field";
import {FactureDepense} from "@domain/facture/facture-depense";
import {FactureEcartsComponent} from "@components/facture/detail/contentieux/ecarts/facture-ecarts.component";
import {TypeReconciliation} from "@domain/facture/releve-facture";
import {ComponentType} from "@angular/cdk/portal";
import {FactureContentieux} from "@domain/facture/facture-contentieux";
import {TypeProfil,User} from "@domain/user/user";

/**
 * Service de gestion des factures
 *
 * @author Laurent Convert
 * @date 11/01/2023
 */
@Injectable()
export class FactureService implements IObjetWorkflowService<Facture> {
	/**
     * Constructeur
	 */
    constructor(private http: HttpClient,private matDialog: MatDialog,private router:Router) {
	}

	/**
	 * Création d'une facture
	 *
	 * @param facture données nécessaires à la création
	 * @returns un Observable contenant l'ID de l'objet créé
	 */
	create(facture: any): Observable<number> {
		return undefined;
	}

	/**
	 * Chargement d'une facture
	 *
	 * @param idFacture Identifiant de la facture
	 * @returns un Observable contenant les données de l'objet non typées
	 */
	load(idFacture: number): Observable<Result> {
		//Ajout des paramètres
		let params: HttpParams = new HttpParams()
			.append('idFacture',idFacture.toString(10));

		//Récupération de la facture
		return this.http.post<Result>(`${environment.baseUrl}/controller/Facture/loadFacture`,null,{
			params: params
		}).pipe(first());
	}

	/**
	 * Chargement d'une facture pour création
	 *
	 * @param idReleve Identifiant du relevé de facture
	 * @param idTypeEntite Identifiant du type entité de la facture
	 * @returns un Observable contenant les données de l'objet non typées
	 */
	loadNewObjet(idReleve: number,idTypeEntite: number): Observable<Result> {
		//Ajout des paramètres
		let params: HttpParams = new HttpParams()
			.append('idFacture','0')
			.append('idReleve',idReleve.toString(10))
			.append('idTypeEntite',idTypeEntite.toString(10));

		//Récupération de la facture
		return this.http.post<Result>(`${environment.baseUrl}/controller/Facture/loadFacture`,null,{
			params: params
		}).pipe(first());
	}

	/**
	 * Enregistrement d'une facture
	 *
	 * @param facture Facture à mettre à jour
     * @param extraData Données non contenues dans l'objet
	 * @returns un Observable contenant le résultat de la sauvegarde
	 */
    save(facture: Facture,extraData: { isDisabledLitige: boolean,isDisabledAlerte: boolean }): Observable<Result> {
        //Ajout des paramètres HTTP requis pour l'affichage de la liste
        const params: HttpParams = new HttpParams()
            .append('isDisabledLitige',(extraData?.isDisabledLitige === true).toString())
            .append('isDisabledAlerte',(extraData?.isDisabledAlerte === true).toString());

		//Vérification du droit de modification sur la facture
		if (facture.canModifier()) {
			//Enregistrement de la facture
            return this.http.put<Result>(`${environment.baseUrl}/controller/Facture/save`,facture,{params: params}).pipe(first());
		} else if (facture.canCompleter()) {
			//Action WF de complétion
            return this.http.put<Result>(`${environment.baseUrl}/controller/Facture/${facture.idFacture}/contentieux`,facture,{params: params}).pipe(first());
		}
	}

	/**
	 * Récupération des ventilations et paramètres du type entité pour un ordre de mission
	 *
	 * @param idOd Identifiant de l'OD
	 */
	getMibAndVentilForOd(idOd: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Facture/getMibAndVentilForOd/${idOd}`,null).pipe(first());
	}

	/**
	 * Ouvre la popin de visualisation des écarts entre devis / facture
	 *
	 * @param facture La facture
	 * @param contentieux Le contentieux associé à la facture
     * @param factureEcartsComponent Le composant associé aux écarts (passage en paramètre pour éviter une référence cyclique)
	 */
    openEcarts(facture: Facture,contentieux: FactureContentieux,factureEcartsComponent: ComponentType<FactureEcartsComponent>) {
		//Ouverture de la popin
        this.matDialog.open(factureEcartsComponent,{
			data: {
				facture: facture,
				contentieux: contentieux
			}
		});
	}

	/**
	 * Affichage d'une facture
	 *
	 * @param idFacture Identifiant de la facture à afficher
	 */
	showFacture(idFacture: number) {
		//Navigation vers l'élément
		this.router.navigate(['Facture',idFacture]);
	}

	/**
	 * Récupération d'une période suite à une réconciliation à un OM dont le MIB est clôturé
	 *
	 * @param facture Facture
	 */
	getPeriodeOuverte(facture: Facture): Observable<PeriodeDTO> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Facture/getPeriodeOuverte?idPeriode=` + facture.od.periode.idPeriode,null)
            .map(result => result.data);
	}

	/**
	 * Récupération des informations liées au rapprochement d'une facture
	 *
	 * @param ligne Ligne de rapprochement source
	 */
	loadRapprochement(ligne: LigneRapprochement): Observable<Array<LigneRapprochement>> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Facture/loadRapprochement`,ligne)
			.pipe(switchMap(result => {
				//Vérification du résultat
                if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Envoi du résultat
					return of(result.data.listeLigneRapprochement);
				} else {
					//Propagation d'une erreur
					return throwError(result?.codeErreur);
				}
			}));
	}

	/**
	 * Chargement du détail d'une facture
	 *
	 * @param idDetFacture Identifiant de la ligne de détail
	 */
	loadFactureDepense(idDetFacture: number): Observable<Field[]> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Facture/loadFactureDepense/${idDetFacture}`,null)
			.pipe(switchMap(result => {
				//Vérification du résultat
                if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Envoi du résultat
					return of(result.data.listeFieldParam);
				} else {
					//Propagation d'une erreur
					return throwError(result?.codeErreur);
				}
			}));
	}

	/**
	 * Enregistrement d'une ligne de détail
	 *
	 * @param factureDepense La ligne de détail à enregistrer
	 * @return Le nouveau montant de la facture
	 */
	saveLigneDetail(factureDepense: FactureDepense): Observable<number> {
		return this.http.put<Result>(`${environment.baseUrl}/controller/Facture/saveLigneDetail`,factureDepense)
			.pipe(switchMap(result => {
				//Vérification du résultat
                if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Envoi du résultat
					return of(result.data.montant);
				} else {
					//Propagation d'une erreur
					return throwError(result?.codeErreur);
				}
			}));
	}

	/**
	 * Suppression d'une ligne de détail
	 *
	 * @param factureDepense La ligne de détail à supprimer
	 * @return Le nouveau montant de la facture
	 */
	deleteDetail(factureDepense: FactureDepense): Observable<number> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Facture/deleteDetail`,factureDepense)
			.pipe(switchMap(result => {
				//Vérification du résultat
                if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Envoi du résultat
					return of(result.data.montant);
				} else {
					//Propagation d'une erreur
					return throwError(result?.codeErreur);
				}
			}));
	}

	/**
	 * Affichage d'un relevé
	 *
	 * @param idReleve Identifiant du relevé à afficher
     * @param extras Paramètres supplémentaires pour la navigation
	 */
    showReleve(idReleve: number,extras?: NavigationExtras) {
		//Navigation vers l'élément
        this.router.navigate(['ReleveFacture',idReleve],extras);
    }

    /**
     * Détermine et retourne l'uri de la liste de recherche des factures de référence en fonction de la facture
     *
     * @param facture La facture
     * @param contentieux La contentieux de la facture
     */
    getUriSearchFactureReference(facture: Facture,contentieux: FactureContentieux): string {
        //Ajout des paramètres HTTP requis pour l'affichage de la liste
        let params: HttpParams = new HttpParams()
            .append('listeFactureReference',contentieux.listeFactureReference?.join(',') ?? '')
            .append('listeContentieuxReference',facture.listeContentieuxReference?.join(',') ?? '')
            .append('idFacture',facture.idFacture.toString(10));

        //Ajout de paramètres en fonction du type de réconciliation
        if (facture.factureReleve.typeReconciliation === TypeReconciliation.TYPE_RECONCILIATION_OM) {
            params = params.append('idOd',facture.od?.idOd?.toString(10));
        } else if (facture.factureReleve.typeReconciliation === TypeReconciliation.TYPE_RECONCILIATION_COLLAB) {
            params = params.append('idUser',facture.user.idUser.toString(10));
        }

        return '/controller/Facture/searchFactureReference?' + params.toString();
    }

    /**
     * Détermine et retourne l'uri de la liste des factures de référence en fonction de la facture
     *
     * @param facture La facture
     * @param contentieux Contentieux de la facture
     * @param user Utilisateur connecté
     */
    getUriListFactureReference(facture: Facture,contentieux: FactureContentieux,user: User): string {
        //Ajout des paramètres HTTP requis pour l'affichage de la liste
        let params: HttpParams = new HttpParams()
            .append('idFacture',facture.idFacture.toString(10));

        //Passage de la liste courante des factures de référence (seulement dans le cas du comptable, seul à pouvoir modifier la facture)
        if (user?.fonction === TypeProfil.COMPTABLE) {
            params = params
                //Ajout des identifiants des factures de référence "directes"
                .append('listeFactureReference',contentieux.listeFactureReference?.join(',') ?? '')
                //Ajout des identifiants des contentieux des factures de référence "inverses"
                .append('listeContentieuxReference',facture.listeContentieuxReference?.join(',') ?? '');
        }

        //Ajout de paramètres en fonction du type de réconciliation
        if (facture.factureReleve.typeReconciliation === TypeReconciliation.TYPE_RECONCILIATION_OM) {
            params = params.append('idOd',facture.od?.idOd?.toString(10));
        } else if (facture.factureReleve.typeReconciliation === TypeReconciliation.TYPE_RECONCILIATION_COLLAB) {
            params = params.append('idUser',facture.user.idUser.toString(10));
        }

        return '/controller/Facture/listeFactureReference?' + params.toString();
	}

}
