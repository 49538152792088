import {Directive, ViewContainerRef} from "@angular/core";
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";
import {AutocompleteComponent} from "./autocomplete.component";

/**
 * Validateur des champs autocomplete
 *
 * @author Laurent Convert
 * @date 21/04/2022
 */
@Directive({
    selector: '<autocomplete>',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: AutocompleteValidatorDirective,
        multi: true
    }]
})
export class AutocompleteValidatorDirective implements Validator {
    /** Composant Autocomplete associé */
    private autocomplete: AutocompleteComponent;

    /**
     * Constructeur
     */
    constructor(private view: ViewContainerRef) {
    }

    /**
     * Récupération du composant associé au control
     */
    ngAfterViewInit(): void {
        this.autocomplete = this.view.injector.get(AutocompleteComponent, null);
    }

    /**
     * Fonction de validation
     * @param control Le control associé à l'autocomplete
     */
    validate(control: AbstractControl) : ValidationErrors | null {
        //Cas particulier pour les custom-input de type autocomplete
        if (this.autocomplete?.fromCustomInput) {
            return this.autocomplete && control && (control.dirty || control.touched) && !!this.autocomplete.input?.ngControl?.control?.value ? { 'noSelection': true } : null;
        } else {
            //Test de validité de la valeur
            //Notes : - Inutile de tester la propriété "required", le test est fait automatiquement
            //        - Le ngModel prend la valeur de la frappe...
            //        - L'input a une 'value' uniquement à la frappe.
            //        - Lorsqu'une valeur est sélectionnée dans la liste, elle est affichée dans l'input mais il n'a plus de 'value'...
            //        -> Donc on teste cette 'value' pour vérifier qu'il est vide et donc qu'une valeur a été bien sélectionnée (alors que logiquement on devrait vérifier le ngModel, lequel devrait être vide tant qu'une valeur n'a pas été sélectionnée...)
            return this.autocomplete && control && (control.dirty || control.touched) && this.autocomplete.input.value ? { 'noSelection': true } : null;
        }
    }
}

