<div class="lvi-content">
    <div class="avatar" [nSelector]="{ liste: liste,item: data,isNotSelectable: !isArchivageAutorise() }" [ngClass]="{'danger' : data.listeAlertes}">
        <mat-icon [matTooltip]="avatarTooltip | translate" class="material-icons-outlined">inventory_2</mat-icon>
    </div>
    <br/>
    <div class="lvi-body">
        <div class="title">
            <strong>
                <a (click)="goToDetailLot()">{{ data.idLot }} - {{ data.libelle }}</a>
            </strong>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'admin.parametres.demat.archivage.liste.nbNdf'"></strong>{{ data.nbNdf }}</li>
            <li *ngIf="data.uidSessionArchivage"><strong [translate]="'admin.parametres.demat.archivage.liste.nbNdfArchivees'"></strong>{{ data.nbNdfArchivees }}</li>
            <li><strong [translate]="'admin.parametres.demat.archivage.liste.nbPJTotal'"></strong>{{ data.nbPJTotal }}</li>
            <li *ngIf="data.uidSessionArchivage"><strong [translate]="'admin.parametres.demat.archivage.liste.nbPJArchivees'"></strong>{{ data.nbPJArchivees }}</li>
            <li *ngIf="data.uidSessionArchivage"><strong [translate]="'admin.parametres.demat.archivage.liste.uidSessionArchivage'"></strong>{{ data.uidSessionArchivage }}</li>
            <li *ngIf="data.uidSessionArchivage"><strong [translate]="'admin.parametres.demat.archivage.liste.dateArchivage'"></strong>{{ data.dateArchivage | date:"shortDate"}}</li>
            <li *ngIf="data.listeAlertes" [ngClass]="{'back-danger' : data.listeAlertes.niveau === 2, 'back-warning' : data.listeAlertes.niveau === 1}">
                <strong [translate]="'workflow.alerte.title'"></strong><mat-icon [matTooltip]="data.listeAlertes.listeAlertes[0].message">comment</mat-icon>
            </li>
        </ul>
    </div>
    <div class="info" *ngIf="statutArchivageIcon">
        <div class="statut">
            <i class="material-icons-outlined" [ngClass]="statutArchivageClass" [matTooltip]="dematTooltip | translate">{{ statutArchivageIcon }}</i>
        </div>
    </div>
</div>
