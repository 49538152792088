import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog,MatDialogRef} from '@angular/material/dialog';
import {Observable,of,Subject} from 'rxjs';
import {catchError,first,map,mergeMap,take,tap} from 'rxjs/operators';

import {Result} from '@domain/common/http/result';
import {TypeAction,TypePortee} from '@domain/workflow/workflow';
import {environment} from '@environments/environment';
import {PleaseWaitDialogComponent} from '@share/component/please-wait/please-wait-dialog.component';
import {WorkflowActionComponent} from './workflow-action.component';
import {NotificationComponent} from './notification.component';
import {AlerteComponent} from './alerte.component';
import {PleaseWaitService} from '@share/component/please-wait/please-wait.service';
import {ListeAlertes} from "@domain/common/alerte/listeAlertes";
import {AbstractObjetWorkflow} from '@domain/workflow/abstract-objet-workflow';
import {IMapData} from "@domain/workflow/IMapData";
import {MapAction,WFAction} from '@domain/workflow/mapAction';
import {ConfirmService} from '@share/component/confirmation/confirm.service';
import {TranslateService} from '@ngx-translate/core';
import {ActionWorkflow} from "@domain/workflow/action-workflow";
import {SettingsWFObjectState} from "@domain/settings/settings";

@Injectable()
export class WorkflowService {
    /** Permet de lancer le recalcul des données wf depuis l'onglet wf (admin) */
    needRefresh$: Subject<void> = new Subject<void>();

    /** Association des actions aux caractéristiques Workflow */
    private mapActions = {
        [TypeAction.VALIDER]: {
            libelle: 'validation',
            cle: 'canValider'
        },
        [TypeAction.REJETER]: {
            libelle: 'rejet',
            cle: 'canRejeter'
        },
        [TypeAction.INVALIDER]: {
            libelle: 'invalidation',
            cle: 'canInvalider'
        },
        [TypeAction.ANNULER]: {
            libelle: 'annulation',
            cle: 'canAnnuler'
        },
        [TypeAction.MODIFIER]: {
            libelle: 'modification',
            cle: 'canModifier'
        },
        [TypeAction.SUPPRIMER]: {
            libelle: 'suppression',
            cle: 'canSupprimer'
        },
        [TypeAction.EMETTRE]: {
            libelle: 'emission',
            cle: 'canEmettre'
        },
        [TypeAction.ARCHIVER]: {
            libelle: 'archivage',
            cle: 'canArchiver'
        },
        [TypeAction.COMPTABILISER]: {
            libelle: 'comptabilisation',
            cle: 'canComptabiliser'
        },
        [TypeAction.RESTITUER]: {
            libelle: 'restitution',
            cle: 'canRestituer'
        },
        [TypeAction.CLOTURER]: {
            libelle: 'cloture',
            cle: 'canCloturer'
        },
        [TypeAction.ATTRIBUER]: {
            libelle: 'attribuer',
            cle: 'canAttribuer'
        }
    }

    /**
     * Constructeur
     */
    constructor(
        private http: HttpClient,
        private matDialog: MatDialog,
        private pleaseWaitService: PleaseWaitService,
        private confirmService: ConfirmService,
        private translateService: TranslateService) {
    }

    /**
     * Réalisation d'une action Workflow
     * @param idPortee la portée
     * @param typeAction le type d'action
     * @param item objet concerné
     * @param reloadMethod Méthode permettant de reload l'objet si nécessaire
     * @param mapDataSupp?? Données supplémentaires à envoyer
     * @returns{Observable<unknown> | Observable<false>} un Observable de booléen
     */
    doWorkflowAction(idPortee: TypePortee,typeAction: TypeAction,item: AbstractObjetWorkflow,reloadMethod: (id: number) => void,mapDataSupp?: IMapData): Observable<boolean> {
        //Initialisation
        const mapAction: MapAction = item.getMapAction();
        const action: WFAction = mapAction.getWFAction(typeAction);
        let isMotif: boolean;
        let isMotifObligatoire: boolean;
        const idObjet: number = item.getId();
        const libelle: string = this.getLibelleFor(typeAction);
        const getListeMotifs: () => Observable<any[]> = () => { return this.getListeMotifsFor(idPortee, typeAction) };

        //Vérification de l'action
        if (action) {
            //Vérification de la possibilité de définir un motif d'action
            isMotif = action.motifRejet || action.motifValidation;

            //Vérification de l'obligation de saisir d'un motif d'action
            isMotifObligatoire = (action.motifRejet && action.motifRejetObligatoire)
                                || (action.motifValidation && action.motifValidationObligatoire);

            //Affichage de la popup de confirmation/saisie du motif
            return this.matDialog.open(WorkflowActionComponent,{
                data: {
                    libelle,
                    getListeMotifs,
                    isMotif,
                    isMotifObligatoire,
                    listeItems: Array.of(item)
                },
                position: {
                    top: '100px'
                },
                hasBackdrop: true
            }).afterClosed().pipe(
                mergeMap((data: { isConfirmed: boolean,idMotif?: number,motif?: string }) => {
                    //Vérification de la confirmation de l'action
                    if (data.isConfirmed) {
                        //Vérification de la nécessité d'afficher la pop-up des approbateurs/destinataires
                        if (action.mail || action.choixApprobateur) {
                            //Affichage de la pop-up des approbateurs/destinataires
                            return this.matDialog.open(NotificationComponent,{
                                data: {
                                    idPortee,
                                    typeAction,
                                    mapAction,
                                    item,
                                    idObjet
                                },
                                position: {
                                    top: '100px'
                                },
                                maxWidth: '90vw',
                                hasBackdrop: true
                            }).afterClosed().pipe(
                                mergeMap((d: { approbateur?: string,destinatairesEmail?: string })=> {
                                    //Vérification du résultat
                                    if (d) {
                                        //Exécution de l'action WF
                                        return this.doWFAction(idPortee,typeAction,Array.of(item),data.idMotif,data.motif,d.approbateur,d.destinatairesEmail,mapDataSupp);
                                    } else {
                                        //On a enregistré donc on recharge l'objet quand même
                                        reloadMethod(idObjet);

                                        //Annulation de l'action par l'utilisateur
                                        return of(undefined);
                                    }
                                })
                            );
                        } else {
                            //Exécution de l'action Workflow
                            return this.doWFAction(idPortee,typeAction,Array.of(item),data.idMotif,data.motif,null,null,mapDataSupp);
                        }
                    } else {
                        //On a enregistré donc on recharge l'objet quand même
                        reloadMethod(idObjet);

                        //Annulation de l'action par l'utilisateur
                        return of(undefined);
                    }
                })
            );
        } else {
            //Echec du traitement
            return of(false);
        }
    }

    /**
     * Réalisation en masse d'une action Workflow
     * @param idPortee portée
     * @param typeAction type d'action
     * @param listeItems liste des objets concernés
     * @returns un Observable de booléen
     */
    async doWorkflowMasseAction(idPortee: TypePortee,typeAction: TypeAction,listeItems: Array<AbstractObjetWorkflow>): Promise<Observable<boolean>> {
        //Initialisation
        let listeFilteredValidItems: Array<AbstractObjetWorkflow> = [];
        let listeFilteredInvalidItems: Array<AbstractObjetWorkflow> = [];
        let isMessageConfirmed: boolean;
        let confirmMessage: string = '';
        let isMotif: boolean;
        let isMotifObligatoire: boolean;
        const libelle: string = this.getLibelleFor(typeAction);
        const getListeMotifs: () => Observable<any[]> = () => { return this.getListeMotifsFor(idPortee, typeAction) };

        //Filtrage des éléments
        for (const item of listeItems) {
            //Si l'action est possible sur l'élément
            if (item.getMapAction().getWFAction(typeAction).possible
                    && item.getMapAction().getWFAction(typeAction).possibleMasse
                    && item.isValid()
                    && (typeAction === TypeAction.SUPPRIMER ? !item.hasChilds() : true) //Suppression autorisée si pas d'enfant rattaché (OMP)
                    && (!item.getListeAlertes() || item.getListeAlertes().niveau != 2)) {
                listeFilteredValidItems.push(item);
            } else {
                //Pré-remplissage du message de confirmation
                confirmMessage += '> ' + item.getLibelleObjet(this.translateService);
                
                //Message particulier dans le cas d'une suppression autorisée par le WF, mais qui possède au moins un élément rattaché
                if (item.getMapAction().getWFAction(typeAction).possible
                    && item.getMapAction().getWFAction(typeAction).possibleMasse
                    && item.isValid()
                    && typeAction === TypeAction.SUPPRIMER
                    && item.hasChilds()) {
                        confirmMessage += ' : ' + this.translateService.instant('workflow.message.masse-confirm-object-has-child');
                }
                
                confirmMessage += '\r\n';

                //Filtrage
                listeFilteredInvalidItems.push(item);
            }
        }

        //Vérification de la présence d'éléments
        if (listeFilteredValidItems?.length) {
            //En cas d'éléments invalides
            if (listeFilteredInvalidItems.length) {
                confirmMessage = this.translateService.instant('workflow.message.masse-confirm-part-a') + "\r\n\r\n"
                                + confirmMessage + "\r\n"
                                + this.translateService.instant('workflow.message.masse-confirm-part-b.' + (listeFilteredValidItems.length > 1 ? 'plural' : 'one'), { count: listeFilteredValidItems.length });

                //Demande de confirmation
                isMessageConfirmed = await new Promise<boolean>((resolve) => {
                    this.confirmService.showConfirm(confirmMessage).subscribe(isConfirmed => resolve(isConfirmed));
                });

                //Retour en cas d'annulation par l'utilisateur
                if (!isMessageConfirmed) { return of(undefined); }
            }

            //Vérification de la possibilité de définir un motif d'action
            isMotif = listeFilteredValidItems.some(i => typeAction == TypeAction.REJETER && i.getMapAction()?.getWFAction(typeAction)?.motifRejet
                        || typeAction != TypeAction.REJETER && i.getMapAction()?.getWFAction(typeAction)?.motifValidation);

            //Vérification de l'obligation de saisir d'un motif d'action
            isMotifObligatoire = isMotif && listeFilteredValidItems.some(i => typeAction == TypeAction.REJETER && i.getMapAction()?.getWFAction(typeAction)?.motifRejetObligatoire
                                                || typeAction != TypeAction.REJETER && i.getMapAction()?.getWFAction(typeAction)?.motifValidationObligatoire);

            //Affichage de la popup de confirmation/saisie du motif
            return this.matDialog.open(WorkflowActionComponent,{
                data: {
                    libelle,
                    getListeMotifs,
                    isMotif,
                    isMotifObligatoire,
                    listeItems: listeFilteredValidItems
                },
                position: {
                    top: '100px' 
                },
                hasBackdrop: true
            }).afterClosed().pipe(
                mergeMap((data: { isConfirmed: boolean,idMotif?:number,motif?: string }) => {
                    //Vérification de la confirmation de l'action
                    if (data.isConfirmed) {
                        //Exécution de l'action Workflow
                        return this.doWFAction(idPortee,typeAction,listeFilteredValidItems,data.idMotif,data.motif,null,null);
                    } else {
                        //Pas d'action effectuée
                        return of(undefined);
                    }
                })
            );
        } else {
            //S'il n'y a que des éléments qui ne peuvent pas être traités on les affiche
            if ( listeFilteredInvalidItems.length > 0 ) {
                //Attente de la fermeture de la popin d'alerte
                await new Promise<void>((resolve) => {
                    this.confirmService.showConfirm(this.translateService.instant('workflow.message.masse-alert-all-invalid'), {type: 'ok'}).subscribe(() => resolve());
                });
    
                //Pas d'action effectuée
                return of(undefined);
            } else {
                //Pas d'action effectuée
                return of(undefined);
            }
        }
    }

    /**
     * Déclenchement d'une action workflow
     * @param idPortee portée
     * @param typeAction type d'action
     * @param listeItems liste d'objets concernés
     * @param idMotif ID du motif
     * @param motif motif
     * @param approbateur approbateur 
     * @param destinatairesEmail destinateur
     * @param mapDataSupp  Données supplémentaires à envoyer
     * @returns un Observable de booléen
     */
    doWFAction(idPortee: TypePortee,typeAction: TypeAction,listeItems: Array<AbstractObjetWorkflow>,idMotif: number,motif: string,approbateur: string,destinatairesEmail: string,mapDataSupp?:any): Observable<boolean> {
        //Initialisation
        let matDialogRef: MatDialogRef<PleaseWaitDialogComponent>;

        //Affichage de la popup d'attente
        matDialogRef = this.pleaseWaitService.show();

        //Réalisation de l'action
        return this.http.post<Result>(`${environment.baseUrl}/controller/WorkflowUser/doWFAction`,{
            action: typeAction,
            mapDataSupp: mapDataSupp,
            listeObjects: listeItems.map(i => ({
                idPortee,
                idObject: i.getId()
            })),
            idMotif,
            motif,
            approbateur,
            destinatairesEmail
        }).pipe(
            map(result => result?.codeErreur == 0 && result?.data?.nbSuccess > 0),
            tap(() => matDialogRef.close()),
            catchError(() => {
                //Fermeture de la popup
                matDialogRef.close();
                
                //Echec du traitement
                return of(false);
            })
        );
    }

    /**
     * Récupération du libellé d'un type d'action
     * @param typeAction type d'action
     * @returns le libellé
     */
    getLibelleFor(typeAction: TypeAction): string {
        //Retour du libellé
        return this.mapActions[typeAction].libelle;
    }

    /**
     * Récupération de la liste des motifs Workflow
     * @param idPortee la portée
     * @param typeAction type d'action
     * @returns un Observable contenant le tableau des motifs
     */
    getListeMotifsFor(idPortee: TypePortee,typeAction: TypeAction): Observable<Array<any>> {
        //Récupération de la liste des motifs pré-définis
        return this.http.post<Result>(`${environment.baseUrl}/controller/WorkflowUser/loadListeMotif`,{
            module: idPortee,
            idAction: typeAction
        }).pipe(
            take(1),
            map(result => result?.data?.listeMotif)
        );
    }

    /**
     * Affichage de la popup de la liste des alertes
     * @param idPortee la portée
     * @param idObjet ID de l'objet
     */
    showListeAlertes(idPortee: TypePortee,idObjet: number): void {
        //Préchargement de l'observable
        const retrieveListeAlertes: () => Observable<ListeAlertes> = () => { return this.retrieveListeAlertes(idPortee,idObjet) };
        
        //Affichage de la popup de la liste des alertes
        this.matDialog.open(AlerteComponent,{ 
            data: {
                retrieveListeAlertes
            } 
        });
    }

    /**
     * Récupération de la liste des alertes
     * @param idPortee la portée
     * @param idObjet ID de l'objet
     * @returns un Observable de la liste des alertes
     */
    retrieveListeAlertes(idPortee: TypePortee,idObjet: number): Observable<ListeAlertes> {
        //Récupération de la liste des alertes
        return this.http.get<Result>(`${environment.baseUrl}/controller/AlerteUser/${idPortee}/${idObjet}/listeAlertes`).pipe(
            map(result => result?.data?.listeAlertes)
        );
    }

    /**
     * Force l'exécution d'une synchro Workflow sur l'objet concerné
     *
     * @param portee portée de l'objet
     * @param idObjet Identifiant de l'objet
     */
    synchroWorkflow(portee: string,idObjet: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/WorkflowUser/synchroWorkflow/${portee}/${idObjet}`,null).pipe(first());
    }

    /**
     * Mouvement workflow par l'administrateur
     *
     * @param idObjet       Identifiant de l'objet
     * @param typePortee    Portée de l'objet
     * @param actionWf      Action workflow à effectuer
     * @return Result contenant le code erreur le cas échéant
     */
    doMouvementWf(idObjet: number, typePortee: TypePortee, actionWf: ActionWorkflow): Observable<Result> {
        return this.http.post<Result>(
            `${environment.baseUrl}/controller/Workflow/doMouvementWf/${typePortee}/${idObjet}`, actionWf).pipe(first());
    }
}
