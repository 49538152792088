import {Component} from '@angular/core';

/**
 * Iframe temporaire contenant la V9 des distances
 *
 * @author Angeline Ha
 * @date 22/03/2024
 */
@Component({
  host: {'data-test-id': 'iframe-distances'},
  selector: 'iframe-distances',
  templateUrl: './iframe-distances.component.html'
})
export class IframeDistancesComponent {
}