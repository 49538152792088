import { ZoneUtilisateurSaisie } from "./zone-utilisateur-saisie";
import { ZUReferentiel } from "./zuReferentiel";

/**
 * Classe répresantant une zone utilisateur
 */
export class ZoneUtilisateur {
    /* Données */
    //Id du champ
    idZU: number;
    //Libellé du champ
    libelle: string;
    //Référence du champ
    reference: string;
    //Type du champ
    typeChamp: TypeZoneUtilisateur;
    //Libellé du tooltip
    description?: string;
    //Champ obligatoire
    obligatoire: boolean;
    //Code du champ
    code?: string;

    //Affichage du référentiel des listes et de la saisie pour la liste multiple
    listeReferentiel?: ZUReferentiel[];

    //Saisie pour la liste unique
    idSaisieRef?: number;
    //Saisie autre pour liste unique et multiple
    saisieAutre?: boolean;
    //Saisie du texte
    saisieTexte?: string;
    //Saisie du montant
    saisieMontant?: number;
    //Saisie de la date
    saisieDate?: Date;
    //sSisie de l'heure
    saisieHeure?: string;
    //Saisie de la case à cocher
    saisieCheck?: boolean;
}

/**
 * Enumération représantant les types de champ
 */
export enum TypeZoneUtilisateur {
    NONE,
    TEXTE,
    CHECKBOX,
    MONTANT,
    DATE,
    LISTE_UNIQUE,
    LISTE_MULTIPLE,
    LISTE_UNIQUE_AUTRE,
    LISTE_MULTIPLE_AUTRE,
    HEURE
}