import {Injectable} from '@angular/core';
import {HttpClient,HttpParams} from "@angular/common/http";
import {Observable,of} from "rxjs";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {Avance} from '@domain/avance/avance';
import {IObjetWorkflowService} from '../workflow/objet-workflow.service';
import {first,map} from "rxjs/operators";
import {CompteBancaire} from "@domain/comptabilite/compte-bancaire";

@Injectable()
export class AvanceService implements IObjetWorkflowService<Avance> {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) {
	}

	/**
	 * Chargement d'une avance
	 * @param idAvance ID de l'avance
	 */
	load(idAvance: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Avance/loadAvance/${idAvance}`,null);
	}

	/**
	 * Création d'une avance
	 * @param object Paramètres de l'avance à créer
	 */
	create(objet: any): Observable<number> {
		//Pas de création d'avance
		return of(null);
	}

	/**
	 * Enregistrement de l'avance
	 * @param avance Avance à sauvegarder
	 */
	save(avance: Avance): Observable<Result> {
		let idCollab: Number = null;

		//Dans le cas d'une création d'avance
		if (avance.idAvance == 0 && !!avance.user) {
			//On récupère le collaborateur sélectionné, le cas échéant (cas de la création en profil RSP/AST/CPT)
			idCollab = avance.user.idUser;
		}

		//Suppression du champ user inutile
		avance.user = null;

		//Enregistrement de l'avance
		return this.http.put<Result>(`${environment.baseUrl}/controller/Avance/saveAvance` + (idCollab ? '?idCollab=' + idCollab : ''),avance);
	}

	/**
	 * Chargement des zones utilisateurs lors de la création de l'avance.
	 */
	loadZUAvance(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Avance/loadZUAvance`,null);
	}

	/**
	 * Récupération du montant du plafond de l'avance
	 *
	 * @param idOd Identifiant de l'OD lié à l'avance
	 * @param devise Code de la devise dans lequel retourner le montant
	 */
	getPlafondAvance(idOd: number,devise?: string): Observable<{ plafondAvance: number }> {
		//Paramètres HTTP
		let params: HttpParams = new HttpParams();

		//Vérification d'une devise
		if (devise) {
			//Ajout de la devise aux paramètres
			params = params.set('devise',devise);
		}

		return this.http.post<Result>(`${environment.baseUrl}/controller/Avance/getPlafondAvance/${idOd}`,null,{
			params: params
		}).pipe(first(),map(result => result?.data));
	}

	/**
	 * Chargement des comptes bancaires utilisables sur une avance pour un collaborateur donné.
	 *
	 * @param idCollab id du collaborateur
	 * @return les comptes bancaires trouvés
	 */
	listeComptesPourAvance(idCollab: number): Observable<{ comptes: CompteBancaire[] }> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Avance/listeComptesPourAvance?idCollab=${idCollab}`,null)
			.pipe(first(),map(result => result.data));
	}

}
