<h1 mat-dialog-title>
    <span translate>facture.releve.anomalie.correction.title</span>
    <span [mat-dialog-close]="reloadListeParent"><i class="zmdi zmdi-close"></i></span>
</h1>
<div *ngIf="factureErreur?.listeAlertes?.listeAlertes.length > 0" class="page-header" [ngClass]="{ 'warning': factureErreur.listeAlertes.niveau == NiveauAlerte.WARNING,'error': factureErreur.listeAlertes.niveau == NiveauAlerte.ERROR}">
    <card-alerte [niveau]="factureErreur.listeAlertes.niveau">
        <preview-alerte [alerte]="factureErreur.listeAlertes.listeAlertes[0]" [translateAlerte]="true"></preview-alerte>
    </card-alerte>
</div>
<please-wait *ngIf="isLoading"></please-wait>
<ng-container *ngIf="!isLoading">
    <div mat-dialog-content>
        <form #form="ngForm" autocomplete="off">
            <div class="row">
                <!-- Numéro de facture -->
                <label class="col-md-2"><span translate>facture.releve.anomalie.numFacture</span></label>
                <div class="col-md-3 value-for-label"><span>{{ factureErreur.numeroFacture }}</span></div>

                <!-- Fournisseur -->
                <label class="col-md-2"><span translate>facture.releve.anomalie.fournisseur</span></label>
                <div class="col-md-5 value-for-label"><span>{{ fournisseur?.raisonSociale || factureErreur.fournisseur }}</span></div>
            </div>
            <div class="row">
                <!-- Type (facture / avoir) -->
                <label class="col-md-2"><span translate>facture.releve.anomalie.type</span></label>
                <div class="col-md-3 value-for-label"><span>{{ (factureErreur.sens == TypeFacture.FAC ? 'facture.liste.typeFAC' : factureErreur.sens == TypeFacture.AVO ? 'facture.liste.typeAVO' : '?') | translate }}</span></div>
                <!-- Type charge -->
                <custom-input *ngIf="canModifier && isCorrectionTypeChargePossible; else tplTypeCharge" ngDefaultControl name="typeCharge" libelle="Type charge" lCol="2" rCol="5"
                              customType="autocomplete" autocompleteType="typeCharge"
                              [autocompleteFilter]="{isImportFacture: true,idFournisseur: factureErreur.idFournisseur,idTypeEntite: od?.typeEntite?.idTypeEntite,idCollab: collaborateur ? collaborateur?.idUser : null}" optionDisplay="idTypeCharge" [idName]="'idTypeCharge'"
                              [(ngModel)]="typeCharge" [required]="true"
                              [postTooltip]="'facture.releve.anomalie.tooltips.typeChargeFiltre' | translate"
                              (onChange)="typeChargeChanged()"></custom-input>
                <ng-template #tplTypeCharge>
                    <label class="col-md-2"><span translate>facture.releve.anomalie.typeCharge</span></label>
                    <div class="col-md-3 value-for-label"><span>{{ factureErreur.typeCharge }}</span></div>
                </ng-template>
            </div>
            <div class="row">
                <!-- Date -->
                <label class="col-md-2"><span translate>facture.releve.anomalie.date</span></label>
                <div class="col-md-3 value-for-label"><span>{{ factureErreur.dateFacture | date:'shortDate' }}</span></div>

                <!-- Collab -->
                <custom-input *ngIf="canModifier && isCorrectionMissionOuCollabPossible; else tplCollab" id="ci-collab"
                              ngDefaultControl name="collab" libelle="Collaborateur" lCol="2" rCol="5"
                              customType="autocomplete" autocompleteType="importFactureCollab"
                              [autocompleteFilter]="{}" optionDisplay="nomPrenomMatricule" [idName]="'idUser'"
                              [(ngModel)]="collaborateur" [required]="true" (onChange)="userChanged()"></custom-input>

                <ng-template #tplCollab>
                    <label class="col-md-2"><span translate>facture.releve.anomalie.collaborateur</span></label>
                    <div class="col-md-3 value-for-label"><span>{{ factureErreur.voyageurFormate }}</span></div>
                </ng-template>
            </div>
            <div class="row">
                <!-- Montant -->
                <label class="col-md-2"><span translate>facture.releve.anomalie.montant</span></label>
                <div class="col-md-3 value-for-label"><span>{{ factureErreur.montant | montant:factureErreur.devise }}</span></div>

                <!-- Mission -->
                <custom-input *ngIf="canModifier && isCorrectionMissionOuCollabPossible; else tplMission" ngDefaultControl name="od" libelle="Mission" lCol="2" rCol="5"
                              customType="autocomplete" autocompleteType="importFactureMission"
                              [autocompleteFilter]="{idCollab: collaborateur?.idUser}" optionDisplay="idOd" [idName]="'idOd'"
                              [(ngModel)]="od" [required]="true"
                              [postTooltip]="'facture.releve.anomalie.tooltips.collabFiltre' | translate"
                              (onChange)="odChanged()"></custom-input>

                <ng-template #tplMission>
                    <label class="col-md-2"><span translate>facture.releve.anomalie.mission</span></label>
                    <div class="col-md-3 value-for-label"><span>{{ od | od }}</span></div>
                </ng-template>
            </div>
        </form>
    </div>
    <div mat-dialog-actions>
        <button *ngIf="!factureErreur.masque" mat-stroked-button color="warn" (click)="masquer(true)" [disabled]="isProcessing">
            <span *ngIf="!isHiding" [translate]="'global.actions.masquer'"></span>
            <mat-spinner *ngIf="isHiding" class="m-5" diameter="28"></mat-spinner>
        </button>
        <button *ngIf="factureErreur.masque" mat-stroked-button color="primary" (click)="masquer(false)" [disabled]="isProcessing">
            <span *ngIf="!isHiding" [translate]="'global.actions.demasquer'"></span>
            <mat-spinner *ngIf="isHiding" class="m-5" diameter="28"></mat-spinner>
        </button>
        <button *ngIf="canModifier" mat-flat-button color="primary" (click)="save()" [disabled]="isProcessing || form?.invalid">
            <span *ngIf="!isSaving" [translate]="'global.actions.enregistrer'"></span>
            <mat-spinner *ngIf="isSaving" class="m-5" diameter="28"></mat-spinner>
        </button>
    </div>
</ng-container>
