import {Component,Inject,OnDestroy,OnInit,Type} from '@angular/core';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from "@angular/material/dialog";
import {CguComponent} from '@components/cgu/cgu.component';
import {CguService} from '@components/cgu/cgu.service';
import {AppState} from '@domain/appstate';
import {ListeAvance} from '@domain/avance/liste-avance';
import {Page} from '@domain/common/http/list-result';
import {Result} from '@domain/common/http/result';
import {Filter,ListView,TypeComparaison} from '@domain/common/list-view';
import {ListItem} from '@domain/common/list-view/list-item';
import {ListViewItem} from '@domain/common/list-view/list-view-item';
import {SessionStorageService} from '@domain/common/services/session-storage.service';
import {ListeFacture} from '@domain/facture/liste-facture';
import {ListeNdf} from '@domain/ndf/liste-ndf';
import {ListeOd} from '@domain/od/liste-od';
import {ListeOmp} from '@domain/omp/liste-omp';
import {SettingsWFObjectState} from "@domain/settings/settings";
import {TypeProfil,User} from '@domain/user/user';
import {AbstractObjetWorkflow} from '@domain/workflow/abstract-objet-workflow';
import {WFCreationPossible} from '@domain/workflow/wf-creation-possible';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {ListViewComponent} from "@share/component/list-view/list-view.component";
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject,Subject,Subscription} from "rxjs";
import {filter,first} from "rxjs/operators";
import {TypeAction as TypeActionWorkflow,TypePortee} from '../../domain/workflow/workflow';
import * as settingsActions from "../../reducers/settings";
import {CollabListComponent} from "./collab-list/collab-list.component";
import {WorkflowService} from './workflow.service';
import {Session} from '@domain/security/session';
import {UserDto} from "@domain/user/user-dto";

@Component({
	template: ''
})
export abstract class AbstractWorkflowListComponent<T extends AbstractObjetWorkflow & ListItem,S extends ListViewItem<T>> implements OnInit,OnDestroy {

	/** Profil de l'utilisateur connecté */
	fonction: TypeProfil;

	/** Données */
	liste: ListView<T,S>;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Créations possibles par l'utilisateur connecté */
	creationsPossibles: WFCreationPossible;

	/** Liste des filtres de base */
	stdFilters: Filter[];

	/** Indique si la liste a déjà été chargée ou si c'est sa première fois */
	isListInit = false;

	/** Référence éventuelle au composant liste (utilisé pour rafraichir les listes du dashboard) */
	listViewComponent?: ListViewComponent<T,S>;

	/** Souscription au changement d'utilisateur pour une suppression dans le ngOnDestroy */
	userSouscription: Subscription;

	/** Paramétrage */
	settings: SettingsWFObjectState;

	/** Évènement déclenché lors du retour de l'exécution d'une action WF */
	onActionWorkflowDone: Subject<{ typeAction: TypeActionWorkflow,success: boolean,liste: any }> = new Subject<{ typeAction: TypeActionWorkflow,success: boolean;liste: any }>();

	/** Indique la connexion en tant qu'admin **/
	protected isAdmin: boolean;

	/**
	 * Constructeur
	 *
	 * @param translateService Service de traduction
	 * @param store Store
	 * @param workflowService Service du workflow
	 * @param matDialog le composant des popups
	 * @param portee Portée
	 * @param _controllerUri URI du controller java
	 * @param title Titre de la liste
	 * @param defaultOrder le tri par défaut de la liste
	 * @param className nom de classe pour la persistence
	 * @param component Composant de la liste
	 * @param sessionStorageService Service de gestion du Session Storage
	 * @param cguService Service de gestion des CGU
	 * @param toastrService Service de gestion des toasts
	 */
	protected constructor(
		protected translateService: TranslateService,
		protected store: Store<AppState>,
		protected workflowService: WorkflowService,
		protected matDialog: MatDialog,
		protected portee: TypePortee,
		@Inject(String) protected _controllerUri: string,
		@Inject(String) protected title: string,
		@Inject(String) protected defaultOrder: string,
		@Inject(String) protected className: string,
		protected component: Type<S>,
		protected sessionStorageService: SessionStorageService,
		protected cguService: CguService,
		protected toastrService: ToastrService
	) {
		//Contrôles à l'initialisation
		if (!this.portee || !this.controllerUri || !this.title || !this.component || !this.defaultOrder || !this.className) {
			throw new Error("Elément manquant lors de l'initialisation du composant.");
		}
	}

	/**
	 * Initialisation
	 */
	async ngOnInit(): Promise<void> {
		//Récupération des settings propres au type d'objet
		await this.initSettings();

		//On enregistre les filtres de base pour pouvoir les remettre au besoin
		this.stdFilters = [{
			clef: 'statut.code',
			clefSearchbar: 'statut.libelle',
			title: this.translateService.instant('global.liste.filtres.statut'),
			isDefault: true,
			listeValues: [],
			loading: true
		}];

		//Sélection du profil de l'utilisateur
		this.userSouscription = this.store.select(state => state.session.user).pipe(filter(value => !!value)).subscribe(user => {
			//Définition du type de profil
			this.fonction = user.fonction;

			//Définition des créations possibles
			this.creationsPossibles = this.getWFCreationPossible(user);

			//Si c'est la première initialisation
			if (!this.isListInit) {
				//Initialisation de la liste
				this.initList();

				//On indique que la liste a déjà été init
				this.isListInit = true;
			} else {
				//Récupération des settings propres au type d'objet
				this.initSettings();

				//On recharge les filtres au cas où ce ne soit pas les mêmes suite au changement de profil
				this.initFilters();

				//Et on refresh la liste avec les nouveaux filtres
				this.listViewComponent?.refreshData();
			}
		});

		//Sélection de la session
		this.store.select<Session>(s => s.session).subscribe(session => {
				//Mémorisation de la session
				this.isAdmin = session.isAdmin;
			}
		)

		//Définition de la liste des actions possibles
		this.listeActions.next([{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-ajouter',
			libelle: 'global.actions.creer',
			doAction: () => this.fonction == TypeProfil.COLLABORATEUR || this.portee === TypePortee.FC ? this.create() : this.createWithCollab(),
			isVisible: () => !this.liste?.nbSelectedItems && this.creationsPossibles?.creationPossible && this.creationsPossibles?.creationPossible(this.portee)
		},{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-rappeler',
			libelle: [TypeProfil.COLLABORATEUR,TypeProfil.ASSISTANT].includes(this.fonction) ? 'global.actions.rappeler' : 'global.actions.invalider',
			doAction: () => this.doWorkflowAction(TypeActionWorkflow.INVALIDER),
			isVisible: () => this.liste?.data?.listeResultats?.some(i => i.isSelected && i.getMapAction()?.canInvalider?.possible && i.getMapAction()?.canInvalider?.possibleMasse && i.isValid())
		},{
			type: TypeAction.SECONDARY,
			icone: 'nio icon-suppression',
			libelle: 'global.actions.supprimer',
			doAction: () => this.doWorkflowAction(TypeActionWorkflow.SUPPRIMER),
			isVisible: () => this.liste?.data?.listeResultats?.some(i => i.isSelected && i.getMapAction()?.canSupprimer?.possible && i.getMapAction()?.canSupprimer?.possibleMasse && i.isValid() && !i.hasChilds())
		},{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-emission',
			libelle: 'global.actions.emettre',
			doAction: () => this.doWorkflowAction(TypeActionWorkflow.EMETTRE),
			isVisible: () => this.liste?.data?.listeResultats?.some(i => i.isSelected && i.getMapAction()?.canEmettre?.possible && i.getMapAction()?.canEmettre?.possibleMasse && i.isValid())
		},{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-annulation',
			libelle: 'global.actions.annuler',
			doAction: () => this.doWorkflowAction(TypeActionWorkflow.ANNULER),
			isVisible: () => this.liste?.data?.listeResultats?.some(i => i.isSelected && i.getMapAction()?.canAnnuler?.possible && i.getMapAction()?.canAnnuler?.possibleMasse && i.isValid())
		},{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-rejet',
			libelle: 'global.actions.rejeter',
			doAction: () => this.doWorkflowAction(TypeActionWorkflow.REJETER),
			isVisible: () => this.liste?.data?.listeResultats?.some(i => i.isSelected && i.getMapAction()?.canRejeter?.possible && i.getMapAction()?.canRejeter?.possibleMasse && i.isValid())
		},{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-validation',
			libelle: 'global.actions.valider',
			doAction: () => this.doWorkflowAction(TypeActionWorkflow.VALIDER),
			isVisible: () => this.liste?.data?.listeResultats?.some(i => i.isSelected && i.getMapAction()?.canValider?.possible && i.getMapAction()?.canValider?.possibleMasse && i.isValid())
		},{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-comptabilisation',
			libelle: 'global.actions.comptabiliser',
			doAction: () => this.comptabiliser(),
			isVisible: () => this.liste?.data?.listeResultats?.some(i => i.isSelected && i.getMapAction()?.canComptabiliser?.possible && i.getMapAction()?.canComptabiliser?.possibleMasse && i.isValid())
		},{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-octroi',
			libelle: 'global.actions.attribuer',
			doAction: () => this.doWorkflowAction(TypeActionWorkflow.ATTRIBUER),
			isVisible: () => this.liste?.data?.listeResultats?.some(i => i.isSelected && i.getMapAction()?.canAttribuer?.possible && i.getMapAction()?.canAttribuer?.possibleMasse && i.isValid())
		},{
			type: TypeAction.SECONDARY,
			icone: 'nio icon-tout_selectionner',
			libelle: 'liste.actions.selectionnerElementsAffiches',
			doAction: () => this.selectAll(true),
			isVisible: () => this.liste?.data?.listeResultats.some(i => i.isSelected)
		},{
			type: TypeAction.SECONDARY,
			icone: 'nio icon-annuler_selection',
			libelle: 'liste.actions.toutDeselectionner',
			doAction: () => this.selectAll(false),
			isVisible: () => this.liste?.data?.listeResultats.some(i => i.isSelected)
		}].filter(a => !!a));
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy(): void {
		/* Résiliation des abonnements */
		this.userSouscription?.unsubscribe();

		/** On indique aux éventuels observers que le subject est complete et qu'ils peuvent se désabonner */
		this.onActionWorkflowDone.complete();
	}

	/**
	 * Retourne l'Uri de chargement des objets de la liste
	 */
	protected get listeUri(): string {
		return this.controllerUri + "/listeObjets";
	}

	/**
	 * Retourne l'Uri de comptage des objets de la liste
	 */
	protected get countUri(): string {
		return this.controllerUri + "/countObjets";
	}

	/**
	 * Retourne l'Uri du controller
	 */
	protected get controllerUri(): string {
		return this._controllerUri;
	}

	/**
	 * Initialise les paramètres.
	 */
	private async initSettings(): Promise<void> {
		return new Promise<void>((resolve,reject) => {
			//Chargement du paramétrage de l'objet WF
			this.store.dispatch({
				type: settingsActions.LOAD_SETTINGS,
				payload: this.portee
			});

			//Sélection du paramétrage
			this.store.select(state => state.settings?.[this.portee]).pipe(filter(settings => !!settings)).subscribe(settings => {
				this.settings = settings;
				resolve();
			},error => reject());
		});
	}

	/**
	 * Initialisation de la liste
	 */
	private initList(): void {
		//Définition de la liste
		this.liste = new ListView<T,S>({
			uri: this.listeUri,
			title: this.translateService.instant(this.title),
			component: this.component,
			listeFilters: [...this.stdFilters],
			defaultOrder: this.defaultOrder,
			mapResult: (result: Result) => {
				//Extraction de l'objet concerné dans le résultat
				const page = result?.data?.result;

				//Typage des éléments de la liste
				if (page?.listeResultats) {
					page.listeResultats = page.listeResultats.map(r => {
						switch (this.portee) {
							case TypePortee.AV :
								return new ListeAvance(r);
							case TypePortee.FC :
								return new ListeFacture(r);
							case TypePortee.NF :
								return new ListeNdf(r);
							case TypePortee.OD :
								return new ListeOd(r);
							case TypePortee.OT :
								return new ListeOmp(r);
							default:
								throw new Error("Type non supporté.");
						}
					});

					//Traitement des alertes spécifiques
					this.initAlertSpecific(page.listeResultats);
				}

				//Retour
				return page as Page<T>;
			},
			loadCount: {
				uri: this.countUri,
				onLoad: (result: Result) => {
					if (result?.data?.count) {
						return result.data.count;
					}
					return -1;
				}
			}
		});

		//Méthode d'initialisation du cœur la liste
		this.liste.initListCore = () => this.initListCore();

		//Nom de la classe
		this.liste.className = this.className;

		//Liste avec persistence
		this.liste.isPersistFilters = true;

		//Méthode d'initialisation des filtres
		this.liste.initFilters = () => this.initFilters();

		//Méthode d'initialisation spécifique
		this.liste.initListSpecific = (initOnlyColumns?: boolean) => this.initListSpecific(initOnlyColumns);
	}

	/**
	 * Initialisation de la liste des alertes spécifiques
	 *
	 * @param listeResultats Liste de résultat de la liste
	 */
    protected initAlertSpecific(listeResultats: Array<T>) {}

	/**
	 * Initialisation des filtres de la liste
	 */
	private initFilters(): void {
		//Initialisation des filtres actifs
		this.liste.listeSelectedFilters = [];

		//Différentiation du profil collaborateur
		if (this.fonction != TypeProfil.COLLABORATEUR && this.fonction != TypeProfil.SOUS_ADMINISTRATEUR && !this.isAdmin) {
			this.liste.listeFilters = [
				...[
					{
						//Ajout du filtre sur le prénom
						clef: 'user.prenom',
						title: this.translateService.instant('global.liste.filtres.collaborateur.prenom'),
						isDefault: true,
						typeComparaison: TypeComparaison[TypeComparaison.LIKE]
					},{
						//Ajout du filtre sur le nom
						clef: 'user.nom',
						title: this.translateService.instant('global.liste.filtres.collaborateur.nom'),
						isDefault: true,
						typeComparaison: TypeComparaison[TypeComparaison.LIKE]
					},{
						//Ajout du filtre sur le rôle
						clef: '#WF_jRole.idRole',
						title: this.translateService.instant('global.liste.filtres.roles'),
						listeValues: [],
						loading: true
					}
				],
				...this.liste.listeFilters
			];

			//Ajout du filtre sur les actions
			this.liste.listeFilters.push(
				{
					clef: '#WF_jHistoWorkflow.idAction',
					title: this.translateService.instant('global.liste.filtres.actions.actions'),
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					valeur: '#aTRAITER',
					displayedValeur: this.translateService.instant('global.liste.filtres.actions.aTraiter'),
					isSelected: true,
					listeValues: [{
						code: '#aTRAITER',
						libelle: this.translateService.instant('global.liste.filtres.actions.aTraiter')
					},{
						code: '' + TypeActionWorkflow.VALIDER,
						libelle: this.translateService.instant('global.actions.valider')
					},{
						code: '' + TypeActionWorkflow.REJETER,
						libelle: this.translateService.instant('global.actions.rejeter')
					},{
						code: '' + TypeActionWorkflow.INVALIDER,
						libelle: this.translateService.instant('global.actions.invalider')
					},{
						code: '' + TypeActionWorkflow.ANNULER,
						libelle: this.translateService.instant('global.actions.annuler')
					},{
						code: '' + TypeActionWorkflow.SUPPRIMER,
						libelle: this.translateService.instant('global.actions.supprimer')
					},{
						code: '' + TypeActionWorkflow.EMETTRE,
						libelle: this.translateService.instant('global.actions.emettre')
					},{
						code: '' + TypeActionWorkflow.ARCHIVER,
						libelle: this.translateService.instant('global.actions.archiver')
					},{
						code: '' + TypeActionWorkflow.COMPTABILISER,
						libelle: this.translateService.instant('global.actions.comptabiliser')
					},{
						code: '' + TypeActionWorkflow.RESTITUER,
						libelle: this.translateService.instant('global.actions.restituer')
					},{
						code: '' + TypeActionWorkflow.MODIFIER,
						libelle: this.translateService.instant('global.actions.modifier')
					},{
						code: '' + TypeActionWorkflow.CLOTURER,
						libelle: this.translateService.instant('global.actions.cloturer')
					}]
				}
			);

			//Forçage du filtre A TRAITER par défaut
			this.liste.listeSelectedFilters = [
				...this.liste.listeSelectedFilters,
				...this.liste.listeFilters.filter(filter => filter.valeur == '#aTRAITER').map(filter => {
					//Application du libellé à la place de la valeur
					filter.displayedValeur = filter.listeValues.find(v => v.code == filter.valeur).libelle;

					//Retour
					return filter;
				})
			];
		} else {
			//Si on n'est pas dans un cas particulier, on rétablit les filtres de base
			this.liste.listeFilters = [...this.stdFilters];
		}

		//Si le paramétrage contient des filtres sur les types entités
		if (this.settings?.typeEntiteList?.length > 0) {
			this.liste.listeFilters.push(
				{
					//Ajout du filtre sur le type entité
					clef: 'typeEntite.code',
					clefSearchbar: 'typeEntite.libelle',
					title: this.translateService.instant('global.liste.filtres.typeEntite'),
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					listeValues: this.settings.typeEntiteList.map(typeEntite => {
						return {
							code: typeEntite.code,
							libelle: typeEntite.libelle
						};
					})
				}
			);
		}

		//Ajout du filtre sur les archives
		this.liste.listeFilters.push(
			{
				clef: 'statut.idEtat',
				title: this.translateService.instant('global.liste.filtres.archives'),
				typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
				valeur: '#ARCHIVES_Non',
				displayedValeur: this.translateService.instant('global.liste.filtres.archivesNon'),
				isSelected: true,
				listeValues: [{
					code: '#ARCHIVES_Non',
					libelle: this.translateService.instant('global.liste.filtres.archivesNon')
				},{
					code: '5',
					libelle: this.translateService.instant('global.liste.filtres.archivesOui')
				}],
				hiddenChip: true,
				changeMethod: this.archiveChange,
				selectMethod: this.archiveSelect
			}
		);

		//Forçage du filtre ARCHIVES_Non par défaut
		this.liste.listeSelectedFilters = [
			...this.liste.listeSelectedFilters,
			...this.liste.listeFilters.filter(filter => filter.valeur == '#ARCHIVES_Non').map(filter => {
				//Application du libellé à la place de la valeur
				filter.displayedValeur = filter.listeValues.find(v => v.code == filter.valeur).libelle;

				//Retour
				return filter;
			})
		];

		//Si le paramétrage contient des filtres sur les rôles
		if (this.settings.filtresRole) {
			//Recherche du filtre concerné
			const roleFilter: Filter = this.liste.listeFilters.find(f => f.clef == '#WF_jRole.idRole');

			//En cas de présence d'un filtre sur les roles
			if (roleFilter) {
				//Peuplement de la liste de valeurs
				roleFilter.listeValues = this.settings.filtresRole.map(role => {
					return {
						code: "" + role.idRole,
						libelle: role.libelle
					};
				});

				//Reset du statut de chargement
				roleFilter.loading = false;
			}
		}

		//Si le paramétrage contient des filtres sur les statuts
		if (this.settings.filtresStatut) {
			//Recherche du filtre concerné
			const statutFilter: Filter = this.liste.listeFilters.find(f => f.clef == 'statut.code');

			//En cas de présence d'un filtre sur les statuts
			if (statutFilter) {
				//Peuplement de la liste de valeurs
				statutFilter.listeValues = this.settings.filtresStatut.map(statut => {
					return {
						code: statut.code,
						libelle: statut.libelle
					};
				});

				//Reset du statut de chargement
				statutFilter.loading = false;
			}
		}
	}

	/**
	 * Spécificités intrinsèques de la liste héritière
	 *
	 * À implémenter si besoin (utilisé dans les dashboard list notamment)
	 */
    protected initListCore(): void { }

	/**
	 * Spécificités de la liste héritière
	 *
	 * @param initOnlyColumns True si on veut initialiser uniquement les colonnes de la liste (false par défaut)
	 */
	protected abstract initListSpecific(initOnlyColumns?: boolean): void;

	/**
	 * Réalisation de l'action Workflow
	 * @param typeAction le type d'action workflow
	 */
	protected async doWorkflowAction(typeAction: TypeActionWorkflow): Promise<void> {
		//Réalisation de l'action Workflow
		(await this.workflowService.doWorkflowMasseAction(this.portee,typeAction,this.liste.data.listeResultats.filter(i => i.isSelected)))
			.pipe(first())
			.subscribe({
				next: isSuccess => {
					//Vérification du résultat (si c'est undefined il n'y a rien à faire)
					if (isSuccess === true || isSuccess === false) {
						if (!isSuccess) {
							//Message d'erreur
							this.toastrService.error(this.translateService.instant('global.errors.actionWF'));
						}

						//Rafraichissement de la liste dans tous les cas car certains éléments ont potentiellement été traités même s'il y a une erreur
						this.liste.refresh();
					}

					//Émission de l'évènement de fin de l'exécution de l'action WF
					this.onActionWorkflowDone.next({typeAction: typeAction,success: isSuccess,liste: this.liste});
				},
				error: () => {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('global.errors.actionWF'));
				}
			});
	}

	/** Changement de la valeur du select sur le filtre "archivés" */
	private archiveChange = (filter: Filter) => {
		//Si la valeur est OUI
		if (filter.valeur == '5') {
			//Désélection du filtre d'actions
			this.liste.listeFilters.find(f => f.clef == '#WF_jHistoWorkflow.idAction').isSelected = false;
		}
	}

	/** Changement de l'état de sélection du filtre "archivés" */
	private archiveSelect = (filter: Filter) => {
		//Si le filtre est sélectionné
		if (filter.isSelected) {
			//Si la valeur est OUI
			if (filter.valeur == '5') {
				//Affichage de la bulle
				this.liste.listeFilters.find(f => f.clef == 'statut.idEtat').hiddenChip = false;

				//Désélection du filtre d'actions
				this.liste.listeSelectedFilters = this.liste.listeSelectedFilters.filter(f => f.clef != '#WF_jHistoWorkflow.idAction');
			}
			//Si la valeur est NON
			else {
				//Masquage de la bulle
				this.liste.listeFilters.find(f => f.clef == 'statut.idEtat').hiddenChip = true;
			}
		}
	}

	/**
	 * Création d'un objet.
	 *
	 * @param collab Collab pour lequel on crée l'objet WF (cas d'un assistant/responsable/comptable)
	 */
	protected abstract create(collab?: UserDto): void;

	/**
	 * Ouvre une popup de sélection d'un collaborateur, puis enchaine sur la procédure de création d'un objet.<br/>
	 */
	protected createWithCollab(): void {
		//Ouverture de la popup de sélection du collab
		this.matDialog
			.open<CollabListComponent,any,UserDto>(CollabListComponent,{
				data: {
					portee: this.portee
				}
			})
			.afterClosed().subscribe({
				next: collab => {
					//Si un collab a été choisi
					if (collab) {
						//On regarde si les cgu sont valides
						this.cguService.hasValidCgu(collab.idUser).subscribe({
							next: (hasValidCgu: boolean) => {
								if (hasValidCgu) {
									//À la fermeture de la popup, on appelle la méthode de création de l'objet WF
									this.create(collab);
								} else {
									//On affiche les cgu
									this.cguService.getLastCguVersion().subscribe({
										next: cgu => {
											//Affichage de la popup des CGU
											this.matDialog.open(CguComponent,{
												data: {
													cgu,
													isOther: true
												},
												hasBackdrop: true,
												disableClose: true,
												width: '98%'
											}).afterClosed().subscribe({
												next: isValidated => {
													//Vérification de la validation des CGU
													if (isValidated) {
														//Validation des CGU
														this.cguService.validateOrRejectCguByOther(true,collab.idUser).subscribe({
															//À la fermeture de la popup, on appelle la méthode de création de l'objet WF
															next: () => this.create(collab),
															error: () => //Message d'erreur
																this.toastrService.error(this.translateService.instant('global.errors.enregistrement'))
														});
													} else {
														//Rejet des CGU
														this.cguService.validateOrRejectCguByOther(false,collab.idUser).subscribe({
															error: () => {
																this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
															}
														});
													}
												}
											});
										}
									});
								}
							}
						});
					}
				}
			}
		);
	}

	protected comptabiliser(): void {
		return;
	}

	/**
	 * Sélection des éléments de la liste
	 *
	 * @param isSelect est-ce qu'on sélectionne ou désélectionne
	 */
	private selectAll(isSelect: boolean): void {
		this.liste?.selectAll(isSelect);
	}

	/**
	 * Retourne l'objet qui définit la possibilité de création d'objet
	 *
	 * @param user Utilisateur connecté
	 */
	protected getWFCreationPossible(user: User): WFCreationPossible {
		return new WFCreationPossible(user.creationsPossibles);
	}

	/**
	 * Rafraichissement de la liste
	 */
	refresh(): void {
		this.liste?.refresh();
	}

}
