import {ENTER} from '@angular/cdk/keycodes';
import {Component,ElementRef,Inject,Input,OnDestroy,OnInit,Optional,ViewChild} from '@angular/core';
import {MatChipInputEvent} from '@angular/material/chips';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject,Subscription} from 'rxjs';
import {NgForm} from "@angular/forms";
import {MatDialog} from '@angular/material/dialog';
import {Router} from "@angular/router";
import {AppState} from "@domain/appstate";
import {NiveauAlerte} from '@domain/common/alerte/alerte';
import {Filter,ListView,TypeComparaison,TypeFilter} from '@domain/common/list-view';
import {filterActionVisible} from "@domain/common/list-view/action";
import {ListItem} from '@domain/common/list-view/list-item';
import {CountStatus} from "@domain/common/list-view/list-view";
import {ListViewItem} from '@domain/common/list-view/list-view-item';
import {SearchType} from '@domain/common/list-view/sorting';
import {TypeProfil} from "@domain/user/user";
import {Store} from "@ngrx/store";
import * as settingsActions from "@reducers/settings";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {first} from "rxjs/operators";
import {ListViewService} from './list-view.service';
import {SortingComponent} from './sorting/sorting.component';
import {FloatingButtonService} from "@share/component/floating-button/floating-button.service";

@Component({
    host: {'data-test-id': 'list-view-search'},
    selector: 'list-view-search',
    templateUrl: './list-view-search.component.html',
    styleUrls: ['./list-view-search.component.scss']
})
export class ListViewSearchComponent<T extends ListItem,K extends ListViewItem<T>> implements OnInit, OnDestroy {

    /** Enum des statuts du count pour accès dans le template */
    readonly CountStatus: typeof CountStatus = CountStatus;

    /** Import type recherche */
    readonly SearchType = SearchType;

    NiveauAlerte = NiveauAlerte;

    /** Liste à afficher */
    @Input() liste: ListView<T,K>;

    /** Texte recherché */
    searchText: string = '';

    /** Visibilité des filtres */
    isFilterVisible: boolean = false;

    /** Liste des types de filtre */
    TypeFilter: typeof TypeFilter = TypeFilter;

    /** Liste des séparateurs */
    readonly listeSeparateurs = [ENTER] as const;

    /** Champ de saisie pour la recherche */
    @ViewChild('searchInput',{ static: true }) searchInput: ElementRef;

    /** Numéro de page */
    numPage: number = 0;

    /** Liste des types des comparaisons */
    listeTypesComparaison: Array<{ code:string, libelle:string }>;

    /** Filtre les actions suivant leur visibilité */
    filterActionVisible: Function = filterActionVisible;

    /** Pagination */
    pagination: BehaviorSubject<String> = new BehaviorSubject<String>(null);

    /** Profil de l'utilisateur connecté */
    fonction: TypeProfil;

    /** Liste des souscriptions de la page */
    listeSouscriptions: Array<Subscription> = [];

    /**
     * Constructeur
     *
     * @param listViewService       Service des listes
     * @param translateService      Service de traduction
     * @param store                 Store de l'application
     * @param matDialog             Service des boites de dialogue material
     * @param router                Service de routage
     * @param floatingButtonService Service de gestion des boutons flottants (pour être utilisé, il faut que la directive {@link FloatingButtonDirective} soit bien définie)
     */
    constructor(
        public listViewService: ListViewService<T,K>,
        private translateService: TranslateService,
        private store: Store<AppState>,
        private matDialog: MatDialog,
        private router: Router,
        @Optional() @Inject('FLOATING_BUTTON_SERVICE') private floatingButtonService: FloatingButtonService
    ) { }

    /**
     * Initialisation de la page
     */
    ngOnInit(): void {
        //Liste de types de comparaison
        this.listeTypesComparaison = [{
            code: 'EQUAL',
            libelle: this.translateService.instant('filter.rule.filterOperator.equal')
        },{
            code: 'GREATER',
            libelle: this.translateService.instant('filter.rule.filterOperator.superieure_a')
        },{
            code: 'LESS',
            libelle: this.translateService.instant('filter.rule.filterOperator.inferieure_a')
        },{
            code: 'BETWEEN',
            libelle: this.translateService.instant('filter.rule.filterOperator.compris')
        }];

        //Chargement du paramétrage
        this.store.dispatch({
            type: settingsActions.LOAD_SETTINGS,
            payload: ['Global']
        });

        //Sélection du profil de l'utilisateur
        this.store.select(state => state.session.user).pipe(filterFirstNotNull()).subscribe(user => {
            //Définition du type de profil
            this.fonction = user.fonction;
        });

        //On s'abonne aux demandes de pagination
        this.listeSouscriptions.push(this.liste.updatePagination$.subscribe(() => {
            setTimeout(() => {
                //Rechargement de la pagination au prochain cycle d'angular
                this.doUpdatePagination();
            });
        }));

        setTimeout(() => {
            //Rechargement de la pagination au prochain cycle d'angular
            this.doUpdatePagination();
        });
    }

    /**
     * Mise à jour de la pagination
     */
    private doUpdatePagination() {
        if (this.liste.showPagination) {
            this.pagination.next(this.listViewService.getPagination(this.liste, this.fonction));
        }
    }

    /**
     * Mise à jour de la pagination selon les données contenues dans la liste
     */
    refreshListPagination() {
        if (this.liste.showPagination) {
            setTimeout(() => {
                //Rechargement de la pagination au prochain cycle d'angular avec les nouvelles données dans la liste
                this.doUpdatePagination();
            });
        }
    }

    /**
     * Récupération du filtre pour les bulles
     */
    isChipVisible() {
        //Vérification du filtre et de la visibilité de l'élément
        return (item: Filter) => !item.hiddenChip;
    }

    /**
     * Changement de valeur sur un select
     * @param filter le filtre
     */
    filterSelectionChange(filter: Filter): void {
        //Si le filtre comporte une methode de MAJ
        if (filter.changeMethod) {
            //Emmission de la MAJ
            filter.changeMethod(filter);
        }
    }

    /**
     * Rafraîchissement de la liste
     * @param numPage numéro de page
     */
    refreshData(numPage: number = 0): void {
        //Initialisation
        let subscription: Subscription;

        //Vérification du numéro de page demandé
        if (numPage == 0) {
            //Remise à zéro du numéro de page courant
            this.numPage = 0;

            //On s'assure de supprimer le count précédent
            this.liste.countTotal = -1;

            //S'il y a une gestion du count
            if (this.liste.loadCount) {
                //On le reset
                this.liste.loadCount.loadStatus.next(CountStatus.NONE);
            }

            //On met à jour la pagination
            this.doUpdatePagination();
        }

        //Vidage de la liste
        this.liste.data = null;

        //Indicateur de chargement actif
        this.liste.isLoading = true;

        //Purge des données préchargées
        this.liste.isPreloading = false;
        this.liste.preloadedData = new BehaviorSubject<any>(undefined);

        //Chargement de la liste
        subscription = this.listViewService.loadData(this.liste,this.liste.uri,numPage,this.liste.nbObjetsParPage,this.liste.defaultOrder,this.liste.listeStaticFilters,this.liste.listeSelectedFilters, this.fonction).subscribe((data) => {
            //Mise à jour de la liste
            this.liste.data = data;

            //Rafraichissement des données
            this.liste.onRefresh && this.liste.onRefresh(this.liste, data);

            //Sauvegarde des paramètres appliqués
            this.listViewService.backupListeParams(this.liste, this.fonction);

            //Ré-initialisation de la longueur de la liste
            this.liste.previousLength = -1;

            //Pagination
            this.doUpdatePagination();

            //Suppression de la souscription
            subscription && subscription.unsubscribe();

            //Indicateur de chargement inactif
            this.liste.isLoading = false;

            //Préchargement des données suivantes
            this.listViewService.preloadNextData(this.liste, this.liste.uri, numPage, this.liste.nbObjetsParPage, this.liste.defaultOrder, this.liste.listeStaticFilters, this.liste.listeSelectedFilters, this.fonction);
        });
    }

    /**
     * Ajout d'un filtre
     * @param event événement
     */
    addFilter(event: MatChipInputEvent): void {
        //Initialisation
        let value: string;

        //Lecture de la valeur saisie
        value = (event.value || '').trim();
    
        //Vérification de la valeur
        if (value) {
            //Ajout du filtre
            this.liste.listeSelectedFilters.push({
                clef: this.liste.listeFilters.filter(f => f.isSelected || f.isDefault).map(f => f.clefSearchbar ?? f.clef).join(','),
                typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                valeur: `${value}%`,
                displayedValeur: value
            });

            //Rafraichissement de la liste
            this.refreshData();
        }
    
        //Suppression de la saisie
        event.input.value = null;
    }

    /**
     * Suppression d'un filtre
     * @param filter filtre à supprimer
     */
    removeFilter(filter: Filter) {
        let removeIndex: number;
        let unFilterParmiDautres: Filter;

        //Recherche du filtre dans la liste des filtres sélectionnés.
        //Il est possible d'avoir des doublons de clef, on recherche également avec la valeur.
        if ((removeIndex = this.liste.listeSelectedFilters.findIndex(f => f.clef == filter.clef && f.valeur == filter.valeur)) >= 0) {
            //Suppression du filtre
            this.liste.listeSelectedFilters.splice(removeIndex,1);
        }

        //Si la clef du filtre contient une virgule, c'est qu'on a tapé le filtre directement dans la barre de recherche.
        //On n'a donc pas besoin de désélectionner de filtre.
        if (filter.clef.indexOf(',') == -1) {
            //Recherche du filtre dans la liste de tous les filtres disponibles, on n'a pas de doublon dans ce cas.
            if ((unFilterParmiDautres = this.liste.listeFilters.find(f => f.clef == filter.clef))) {
                //Désélection du filtre
                unFilterParmiDautres.isSelected = false;

                //Si le filtre comporte une methode de suppression
                if (filter.removeMethod) {
                    //Appel de la methode avec le filtre
                    filter.removeMethod(unFilterParmiDautres);
                }
            }
        } else {
            //Si le filtre comporte une methode de suppression
            if (filter.removeMethod) {
                //Appel de la methode avec le filtre contenant les valeurs tapées dans la barre de recherche
                filter.removeMethod(filter);
            }
        }

        //Rafraichissement de la liste
        this.refreshData();
    }

    /**
     * Focus sur la barre de recherche
     */
    onSearchFocus() {
        if (!this.liste.isSimple && !this.liste.isDashboardList) {
            //Focus sur le champ de saisie
            this.searchInput?.nativeElement.focus();
        }
    }

    /**
     * Mise à jour des filtres quand on presse le bouton entrer dans un filtre
     *
     * @param form le formulaire pour contrôler qu'il est valide
     */
    onPressEnterInFilter(form: NgForm) {
        //On met à jour les filtres uniquement si la liste n'est pas en cours de chargement et si le formulaire est valide
        if (!this.liste.isLoading && form.valid) {
            this.updateListeFilters();
        }
    }

    /**
     * Mise à jour de la liste des filtres et Rafraichissement des données de la liste
     */
    updateListeFilters() {
        //Rafraichissement des filtres
        this.listViewService.refreshListeSelectedFilters(this.liste);

        //Rafraichissement de la liste
        this.refreshData();
    }

    /**
     * Réinitialisation des filtres
     */
    resetListeFilters() {
        //
        const listeSelectedFilters = this.liste.listeSelectedFilters?.filter(filter => !!filter.removeMethod);

        //Désélection des filtres visibles et réinitialisation de la valeur
        this.liste.listeFilters = this.liste.listeFilters.map(filter => ({
            ...filter,
            isSelected: false,
            valeur: null,
            min: null,
            dateDebut: null
        }));

        //Suppression des filtres sélectionnés
        this.liste.listeSelectedFilters = [];

        listeSelectedFilters.forEach(filter => {
            filter.removeMethod(this.liste.listeFilters.find(f => f.clef == filter.clef));
        });

        //Rafraichissement de la liste
        this.refreshData();
    }

    /**
     * Page précédente
     */
    goToPreviousPage(): void {
        //Vérification de la possibilité
        if (this.numPage > 0) {
            //Rafraichissement de la liste
            this.refreshData(--this.numPage);
        }
    }

    /**
     * Page suivante
     */
    goToNextPage(): void {
        //Vérification de la possibilité
        if (this.numPage < (this.liste.data.nbPagesTotal - 1)) {
            //Rafraichissement de la liste
            this.refreshData(++this.numPage);
        }
    }

    /**
     * Affichage de la popup de tri
     */
    showSortPopup(): void {
        //Affichage de la popup
        this.matDialog.open(SortingComponent,{
            data: {
                sorting: this.liste.sorting
            }
        }).afterClosed().subscribe({
            next: retour => {
                //Vérification
                if (retour) {
                    //Cas du reset
                    if (retour.reset) {
                        //Mise à jour du sorting
                        this.liste.defaultOrder = this.liste.sorting.reset();
                        //Rafraîchissement de la liste
                        this.updateListeFilters();
                    }
                    //Cas du nouveau tri
                    else if (retour.sorting) {
                        //Mise à jour du sorting
                        this.liste.defaultOrder = this.liste.sorting.getFormattedSorting();
                        //Rafraîchissement de la liste
                        this.updateListeFilters();
                    }
                }
            }
        });
    }

    /**
     * Redirige vers la liste correspondante (uniquement depuis une dashboard list)
     */
    goToList(): void {
        this.router.navigate([this.liste.redirect]);
    }

    /**
     * Demande de chargement du count
     */
    loadCount() {
        //Chargement du count
        this.listViewService.loadCount(this.liste).pipe(first()).subscribe(_ => {
            //Mise à jour de la pagination
            this.doUpdatePagination();
        })
    }

    /**
     * Reset les boutons flottants s'il y en a pour la liste
     */
    resetButtons(): void {
        //Si on a un service de gestion des boutons flottants
        if (!!this.floatingButtonService) {
            //On reset les boutons
            this.floatingButtonService.resetFloatingButtonActions();

            //On désélectionne la liste
            this.liste.selectAll(false);
        }
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy(): void {
        //Unsub de toutes les souscriptions
        this.listeSouscriptions.forEach(sub => sub.unsubscribe());
    }

    /**
     * Indication de l'affichage de la barre de recherche
     * @return true si on doit afficher la search bar
     */
    hasSearchBarVisible(): boolean {
        //Si on a spécifié qu'on devait afficher la search bar
        if (this.liste.isSearchBar != null) {
            return this.liste.isSearchBar;
        }
        //On renvoie les valeurs historiques qui modifient également d'autres éléments que la searchbar
        return !this.liste.isSimple && !this.liste.isDashboardList && !this.liste.isFrontendList
    }

    /**
     * Indique si on doit afficher le bouton pour le paramétrage des filtres
     * @return true si on doit afficher le bouton pour le paramétrage des filtres
     */
    hasFilterVisible(): boolean {
        //Si on a spécifié qu'on devait afficher le bouton de filtre
        if (this.liste.isFilterVisible != null) {
            return this.liste.isFilterVisible
        }

        //on renvoie les valeurs historiques
        return (!this.liste.isLocal || this.liste.isFilter) && !this.liste.isFrontendList && !this.liste.isSimple && !this.liste.isDashboardList
    }
}