import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {first,tap} from "rxjs/operators";
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {LotArchivage} from "@domain/demat/lot-archivage";
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {ArchivageLotListItemComponent} from "@components/admin/parametre/demat/archivage/lot-list/lot-list-item/archivage-lot-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {PieceJointeParam,TypeEntiteParam} from "@domain/admin/parametre/demat/piece-jointe-param";
import {TypeDocument} from "@domain/admin/parametre/demat/type-document";
import * as moment from "moment";
import {StatutArchivage} from "@domain/comptabilite/statut-archivage";

/**
 * Service de gestion de la dématérialisation à valeur probante.
 *
 * @author François Turin
 * @date 16/06/2023
 */
@Injectable()
export class DematService {
	/** Les lots dont la période est antérieure au 01/01/2019 ne doivent pas pouvoir être archivés */
	private static readonly dateLimiteArchivage: moment.Moment = moment('2019-01-01');

	/** Liste des lots */
	private _listeLots: ListView<LotArchivage,ArchivageLotListItemComponent>;

	/** Indique si une page de détail d'un lot a été ouverte */
	isInDetail: boolean = false;

	/** Indique si un lot est en attente de synchronisation */
	private _hasLotEnAttente: boolean = false;

	/** Indique si la licence ARCHIVAGE est présente */
	private _isDematPresent: boolean = false;

	/**
	 * Constructeur
	 *
	 * @param translateService Service de traduction
	 * @param http pour permettre de réaliser les requêtes
	 */
	constructor(private translateService: TranslateService,
				private http: HttpClient) {
	};

	/** Liste des lots */
	get listeLots() {
		if (!this._listeLots) {
			//Initialisation de la liste
			this.initListeLots();
		}

		return this._listeLots;
	}

	/**
	 * Définition de la liste des lots.
	 */
	private initListeLots() {
		//Définition de la liste
		this._listeLots = new ListView<LotArchivage,ArchivageLotListItemComponent>({
			uri: `/controller/DematHubConfig/listeLots`,
			title: this.translateService.instant('admin.parametres.demat.archivage.liste.title'),
			component: ArchivageLotListItemComponent,
			listeFilters: [{
				clef: 'idLot',
				title: this.translateService.instant('admin.parametres.demat.archivage.liste.identifiant'),
				type: TypeFilter[TypeFilter.LONG],
				typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
				isDefault: true
			},{
				clef: 'libelle',
				title: this.translateService.instant('admin.parametres.demat.archivage.liste.libelle'),
				type: TypeFilter[TypeFilter.STRING],
				isDefault: true
			},{
				clef: 'nbNdf',
				title: this.translateService.instant('admin.parametres.demat.archivage.liste.nbNdf'),
				type: TypeFilter[TypeFilter.LONG],
				typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
			},{
				clef: 'nbNdfArchivees',
				title: this.translateService.instant('admin.parametres.demat.archivage.liste.nbNdfArchivees'),
				type: TypeFilter[TypeFilter.LONG],
				typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
			},{
				clef: 'nbPJTotal',
				title: this.translateService.instant('admin.parametres.demat.archivage.liste.nbPJTotal'),
				type: TypeFilter[TypeFilter.LONG],
				typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
			},{
				clef: 'nbPJArchivees',
				title: this.translateService.instant('admin.parametres.demat.archivage.liste.nbPJArchivees'),
				type: TypeFilter[TypeFilter.LONG],
				typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
			},{
				clef: 'uidSessionArchivage',
				title: this.translateService.instant('admin.parametres.demat.archivage.liste.uidSessionArchivage'),
				type: TypeFilter[TypeFilter.STRING]
			},{
				clef: 'dateArchivage',
				title: this.translateService.instant('admin.parametres.demat.archivage.liste.dateArchivage'),
				type: TypeFilter[TypeFilter.DATE],
				typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
				ignoreHeures: true
			}],
			defaultOrder: "-idLot",
			mapResult: (result: Result) => {
				//Mise à jour de la notification de lots en attente d'archivage
				this.hasLotEnAttente = result.data?.hasLotEnAttente;

				//Extraction de l'objet concerné dans le résultat
				return result.data?.listeLots;
			}
		});
	}

	/**
	 * Permet d'afficher un message d'information, sous les onglets, si un lot est en attente de synchronisation.
	 *
	 * @param hasLotEnAttente true si un lot est en attente de synchro
	 */
	set hasLotEnAttente(hasLotEnAttente: boolean) {
		this._hasLotEnAttente = hasLotEnAttente;
	}

	/**
	 * @return true si un lot est en attente de synchronisation.
	 */
	get hasLotEnAttente(): boolean {
		return this._hasLotEnAttente;
	}

	get isDematPresent(): boolean {
		return this._isDematPresent;
	}

	/**
	 * Chargement du statut du module Dématérialisation.
	 */
	loadStatutDemat(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/DematHubConfig/consultStatut`,[])
			.pipe(first(),tap(result => {
				this._isDematPresent = !!result.data.licence.ARCHIVAGE;
			}));
	};

	/**
	 * Teste la connexion au DematHub et à Azure Blob Storage.
	 */
	tester(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/DematHubConfig/testConnexion`,[]).pipe(first());
	}

	/**
	 * Indique si le lot peut être archivé.
	 */
	isArchivageAutorise(lot: LotArchivage): boolean {
		return (!lot.statutArchivage || lot.statutArchivage === StatutArchivage.ECHEC) && this.isDateLimiteDepassee(lot);
	}

	/**
	 * Indique si le lot est lié à une période budgétaire postérieure au 01/01/2019.
	 */
	isDateLimiteDepassee(lot: LotArchivage): boolean {
		return moment(lot.dateDebut).isSameOrAfter(DematService.dateLimiteArchivage);
	}

	/**
	 * Envoi des lots pour Demat.
	 *
	 * @param listeLots liste des lots à synchroniser
	 */
	archiverLots(listeLots : LotArchivage[]): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/DematHubConfig/synchroniserLots`,listeLots.map(lot => lot.idLot)).pipe(first());
	}

	/**
	 * Envoi d'un lot pour Demat.
	 *
	 * @param idLot id du lot à synchroniser
	 */
	archiverLot(idLot: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/DematHubConfig/synchroniserLots`,[idLot]).pipe(first());
	}

	/**
	 * Charge la liste des extensions
	 */
	loadListeExtension(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/PieceJointe/showListeExtension`,[]).pipe(first());
	}

	/**
	 * Ajoute une extension
	 * @param extensionName le nom de l'extension
	 */
	addExtension(extensionName: string): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/PieceJointe/addExtension`, extensionName).pipe(first());
	}

	/**
	 * Supprime une extension
	 * @param idExtension l'id de l'extension
	 */
	deleteExtension(idExtension: number): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/PieceJointe/deleteExtension/${idExtension}`).pipe(first());
	}

	/**
	 * Chargement des paramètres des pièces jointes
	 */
	loadPieceJointeParams(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/PieceJointe/loadParams`,null).pipe(first());
	}

	/**
	 * Sauvegarde des paramètres des pièces jointes
	 */
	savePieceJointeParams(listePieceJointeParams: PieceJointeParam[], listePieceJointePath: string): Observable<Result> {
		const body = {
			pieceJointeParams: listePieceJointeParams,
			pieceJointeLocalPath: listePieceJointePath
		}

		return this.http.put<Result>(`${environment.baseUrl}/controller/PieceJointe/saveParams`,body).pipe(first());
	}

	/**
	 * Sauvegarde des types entités liés aux pièces jointes
	 */
	saveTypeEntiteParam(listeTypeEntiteParams: TypeEntiteParam[]) {
		return this.http.put<Result>(`${environment.baseUrl}/controller/PieceJointe/saveTypeEntite`,listeTypeEntiteParams).pipe(first());
	}

	/**
	 * Suppression d'un filtre pièce-jointe
	 */
	deleteFiltrePieceJointe(typeEntiteParam: TypeEntiteParam): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/PieceJointe/deleteTypeEntite/${typeEntiteParam.idPortee}/${typeEntiteParam.idTypeEntite}`).pipe(first());
    }

	/**
	 * Sauvegarde le typeDocument
	 * @param typeDocument le typeDocument à sauvegarder
	 * @return un observable avec un result
	 */
	saveTypeDocument(typeDocument: TypeDocument): Observable<Result> {
		return this.http.put<Result>(`${environment.baseUrl}/controller/PieceJointe/saveTypeDocument`, typeDocument) .pipe(first());
	}

	/**
	 * Sauvegarde le typeDocument
	 * @param id l'identifiant à effacer
	 * @return un observable avec un result
	 */
	deleteTypeDocument(id: number): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/PieceJointe/deleteTypeDocument/${id}`).pipe(first());
	}
}
