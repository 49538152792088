import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ProfilUser} from "../../../../domain/profil/profilUser";
import * as _ from "lodash";

/**
 * Composant de la popup de modification des infos personnelles
 */
@Component({
    host: {'data-test-id': 'profil-donnees-modify-infos-perso'},
    templateUrl: './profil-donnees-modify-infos-perso.component.html'
})
export class ProfilDonneesModifyInfosPersoComponent implements OnInit {

    /** Infos perso de l'utilisateur */
    profilUser: ProfilUser;

    /**
     * Constructeur
     *
     * @param data les infos perso avant modification
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: ProfilUser) { }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //On clone l'objet reçu en paramètres dans profilUser
        this.profilUser = _.cloneDeep(this.data);
    }

    /**
     * Mise à jour de l'ID Ville
     */
    onVilleNaissanceChange(): void {
        this.profilUser.infoVoyageur.idVilleNaissance = this.profilUser.infoVoyageur.villeNaissance ? this.profilUser.infoVoyageur.villeNaissance.id : null;
    }

    /**
     * Mise à jour de l'ID Pays de la nationalité
     */
    onPaysNationaliteChange(): void {
        this.profilUser.infoVoyageur.idPaysNationalite = this.profilUser.infoVoyageur.paysNationalite ? this.profilUser.infoVoyageur.paysNationalite.id : null;
    }

    /**
     * Mise à jour de l'ID Pays de naissance
     */
    onPaysNaissanceChange(): void {
        this.profilUser.infoVoyageur.idPaysNaissance = this.profilUser.infoVoyageur.paysNaissance ? this.profilUser.infoVoyageur.paysNaissance.id : null;
    }

}
