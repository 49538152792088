import {Component,OnInit} from '@angular/core';
import {MatDialog,MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {CguService} from '@components/cgu/cgu.service';
import {NDFCreationComponent} from '@components/ndf/ndf-creation/ndf-creation.component';
import {AbstractWorkflowListComponent} from '@components/workflow/abstract-workflow-list.component';
import {WorkflowService} from '@components/workflow/workflow.service';
import {AppState} from '@domain/appstate';
import {TypeComparaison,TypeFilter} from '@domain/common/list-view';
import {SessionStorageService} from '@domain/common/services/session-storage.service';
import {ListeNdf} from '@domain/ndf/liste-ndf';
import {Mission} from "@domain/ndf/mission";
import {TypeEntite} from "@domain/typeentite/typeEntite";
import {TypePortee} from '@domain/workflow/workflow';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {PleaseWaitDialogComponent} from '@share/component/please-wait/please-wait-dialog.component';
import {PleaseWaitService} from '@share/component/please-wait/please-wait.service';
import {ToastrService} from 'ngx-toastr';
import {NDFService} from '../ndf.service';
import {NdfListItemComponent} from './ndf-list-item/ndf-list-item.component';
import {ComptabiliteService} from "@components/comptabilite/comptabilite.service";
import {LotItemType} from "@domain/comptabilite/lot";
import {UserDto} from "@domain/user/user-dto";

@Component({
    selector: 'ndf-list',
    host: {'data-test-id': 'ndf-list'},
    templateUrl: './ndf-list.component.html'
})
export class NdfListComponent extends AbstractWorkflowListComponent<ListeNdf,NdfListItemComponent> implements OnInit {

    /** Visibilité de la liste d'actions */
    get isListeActionsVisible(): boolean {
        return this.listeActions?.getValue()?.some(l => l.isVisible()) || false;
    }

    /**
     * Constructeur
     */
    constructor(
        protected router: Router,
        protected matDialog: MatDialog,
        protected pleaseWaitService: PleaseWaitService,
        protected ndfService: NDFService,
        protected translateService: TranslateService,
        protected store: Store<AppState>,
        protected workflowService: WorkflowService,
        protected sessionStorageService: SessionStorageService,
        protected cguService: CguService,
        protected toastrService: ToastrService,
        protected comptabiliteService: ComptabiliteService
    ) {
        //Constructeur parent
        super(
            translateService,
            store,
            workflowService,
            matDialog,
            TypePortee.NF,
            '/controller/NDF',
            'ndf.liste.title',
            '-idNDF',
            'NdfListComponent',
            NdfListItemComponent,
            sessionStorageService,
            cguService,
            toastrService
        );
    }

    /**
     * Spécificités de la liste héritière
     *
     * @param initOnlyColumns True si on veut initialiser uniquement les colonnes de la liste (false par défaut)
     */
    protected initListSpecific(initOnlyColumns?: boolean): void {
        //Définition des colonnes de tri
        this.liste.columns = [
            { key: 'idNDF', title: 'ndf.liste.columns.idNDF' },
            { key: 'numeroPiece', title: 'ndf.liste.columns.numeroPiece' },
            { key: 'objet', title: 'ndf.liste.columns.objet' },
            { key: 'od.idOd', title: 'ndf.liste.columns.od' },
            { key: 'omp.idOMPermanent', title: 'ndf.liste.columns.omp' },
            { key: 'user.matricule', title: 'ndf.liste.columns.user.matricule' },
            { key: 'statut.idStatut', title: 'ndf.liste.columns.statut' }
        ];

        //Si on ne veut pas initialiser que les colonnes
        if (!initOnlyColumns) {
            //Ajout des filtres spécifiques
            this.liste.listeFilters = [
                ...[
                    {
                        //Ajout du filtre sur l'objet
                        clef: 'objet',
                        title: this.translateService.instant('ndf.liste.filtres.objet'),
                        isDefault: true,
                        typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                    }, {
                        //Ajout du filtre sur l'id de la ndf
                        clef: 'idNDF',
                        title: this.translateService.instant('ndf.liste.filtres.id'),
                        isDefault: true,
                        typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                    }, {
                        //Ajout du filtre sur le numéro
                        clef: 'numeroPiece',
                        title: this.translateService.instant('ndf.liste.filtres.numero'),
                        isDefault: true,
                        typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                    }, {
                        //Ajout du filtre sur l'OM
                        clef: '*od.idOd',
                        title: this.translateService.instant('ndf.liste.filtres.om'),
                        isDefault: true,
                        typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                    }, {
                        //Ajout du filtre sur l'OMP
                        clef: '*omPermanent.idOMPermanent',
                        title: this.translateService.instant('ndf.liste.filtres.omp'),
                        isDefault: true,
                        typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                    }, {
                        //Ajout du filtre sur la date de MAJ
                        clef: 'dateMaj',
                        title: this.translateService.instant('ndf.liste.filtres.dateStatut'),
                        type: TypeFilter[TypeFilter.DATE]
                    }, {
                        //Ajout du filtre sur le matricule
                        clef: 'user.matricule',
                        title: this.translateService.instant('ndf.liste.filtres.matricule'),
                        isDefault: true,
                        typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                    }, {
                        //Ajout du filtre sur le montant remboursable
                        clef: 'montantRemboursable',
                        title: this.translateService.instant('ndf.liste.filtres.montantRemboursable'),
                        isDefault: true,
                        type: TypeFilter[TypeFilter.DECIMAL]
                    }, {
                        //Ajout du filtre sur le montant à rembourser
                        clef: 'montantARembourser',
                        title: this.translateService.instant('ndf.liste.filtres.montantARembourser'),
                        isDefault: true,
                        type: TypeFilter[TypeFilter.DECIMAL]
                    }
                ],
                ...this.liste.listeFilters
            ];
        }
    }

    /**
     * Création d'un objet
     *
     * @param collab Collab pour lequel on crée l'objet WF (cas d'un assistant/responsable/comptable)
     */
    public create(collab?: UserDto): void {
        //Affichage de la popup de création
        this.matDialog.open(NDFCreationComponent, {
            data: {
                idCollab: collab?.idUser
            },
            minWidth: 500
        }).afterClosed().subscribe({
            next: (result: { mission?: Mission,typeEntite: TypeEntite }) => {
                if (result) {
                    let matDialogRef: MatDialogRef<PleaseWaitDialogComponent>;

                    //Affichage de la popup d'attente
                    matDialogRef = this.pleaseWaitService.show();

                    //Création de la note de frais
                    this.ndfService.create({
                        idOd: result.mission?.portee == TypePortee.OD ? result.mission.id : null,
                        idOMPermanent: result.mission?.portee == TypePortee.OT ? result.mission.id : null,
                        idTypeEntite: (result.mission != null ? result.mission : result).typeEntite.idTypeEntite,
                        idCollab: collab?.idUser
                    }).subscribe(idNDF => {
                        //Vérification de la création de la note de frais
                        if (idNDF)
                            //Redirection vers la note de frais créée
                            this.router.navigate(['NDF',idNDF]);

                        //Fermeture de la popup
                        matDialogRef.close();
                    });
                }
            }
        });
    }

    /**
     * Permet la création d'un lot comptable depuis la sélection
     */
    protected comptabiliser() {
        //Mise en session des items à ajouter au lot
        this.comptabiliteService.setItemsForLot(LotItemType.NDF,this.liste.data.listeResultats);

        //Création d'un nouveau lot
        this.router.navigate(['Lot', 0]);
    }
}
