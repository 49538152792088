/**
 * Classe de mapping du tri des listes
 */
export class Sorting {

    /**
     * Constructeur
     * @param columns objets bruts représentant les colonnes triables
     * @param defaultOrder Clé de tri pré-existante
     * @param search (optionnel) type de recherche, commence_par par défaut
     */
    constructor(columns: { key: string, title: string }[],defaultOrder: string,search?: SearchType) {
        //Initialisation
        this.columns = [];

        //Boucle
        for (const column of columns) {
            //Protection
            if (column.key && column.title) {
                //Insertion
                this.columns.push(new Colonne(column.key,column.title));
            }
        }

        //Sauvegarde de la clé de tri par défaut
        this.backupDefaultOrder = defaultOrder;

        //Traitement de la clé de tri pré-existante
        this.parseExistingOrder(defaultOrder);

        //Type de recherche
        if (search) { this.search = search; } 
    }

    /** Colonnes triables */
    columns: Colonne[];

    /** Clés de tri */
    key1: CleDeTri = new CleDeTri(undefined);
    key2: CleDeTri = new CleDeTri(undefined);
    key3: CleDeTri = new CleDeTri(undefined);

    /** Mapping */
    private readonly keys: Map<string,CleDeTri[]> = new Map([
        ['key1',[this.key2,this.key3]],
        ['key2',[this.key1,this.key3]],
        ['key3',[this.key1,this.key2]]
    ]);

    /** Type de recherche */
    search: SearchType = SearchType.STARTS_WITH;

    /** Sauvegarde de la clé de tri par défaut */
    private readonly backupDefaultOrder: string;

    /**
     * Retourne la liste des colonnes sélectionnables pour une clé donnée
     * @param key clé concernée
     */
    getSelectList(key: 'key1'|'key2'|'key3'): Colonne[] {
        //Contrôle
        if (!key) { throw new Error("Clé obligatoire"); }

        //Retour après filtrage
        return this.columns.filter(c => {
            const keyA = this.keys.get(key)[0];
            const keyB = this.keys.get(key)[1];
            if (keyA?.colonne?.key == c.key) { return false; }
            if (keyB?.colonne?.key == c.key) { return false; }
            return true;
        });
    }

    /**
     * Retourne la version formattée du tri à appliquer
     */
    getFormattedSorting(): string {
        //Initialisation
        let formatted: string = '';

        //Clé 1
        if (this.key1?.colonne) {
            if (this.key1.tri == Ordre.DESC) { formatted = '-'; }
            formatted += this.key1.colonne.key;

            //Clé 2
            if (this.key2?.colonne) {
                formatted += ',';
                if (this.key2.tri == Ordre.DESC) { formatted += '-'; }
                formatted += this.key2.colonne.key;

                //Clé 3
                if (this.key3?.colonne) {
                    formatted += ',';
                    if (this.key3.tri == Ordre.DESC) { formatted += '-'; }
                    formatted += this.key3.colonne.key;
                }
            }
        }

        //Retour
        return formatted != '' ? formatted : this.backupDefaultOrder;
    }

    /**
     * Parse la clé de tri pré-existante
     * @param existingOrder (optionnel) clé de tri pré-existante
     */
    parseExistingOrder(existingOrder?: string): void {
        if (existingOrder && existingOrder != '') {
            //Initialisation
            let isDesc: boolean;
            let theSplit: string;
            let colonne: Colonne;
            this.key1.colonne = undefined;
            this.key2.colonne = undefined;
            this.key3.colonne = undefined;
            this.key1.tri = Ordre.ASC;
            this.key2.tri = Ordre.ASC;
            this.key3.tri = Ordre.ASC;

            //Découpage
            const split: string[] = existingOrder.split(',');

            //Clé 1
            if (split.length) {
                isDesc = split[0].includes('-');
                theSplit = split[0].replace('-','');
                colonne = this.columns.find(c => c.key == theSplit);
                if (!colonne) { throw new Error("Colonne 1 invalide") }
                this.key1.colonne = colonne;
                if (isDesc) { this.key1.tri = Ordre.DESC; }
            }

            //Clé 2
            if (split.length > 1) {
                isDesc = split[1].includes('-');
                theSplit = split[1].replace('-','');
                colonne = this.columns.find(c => c.key == theSplit);
                if (!colonne) { throw new Error("Colonne 2 invalide") }
                this.key2.colonne = colonne;
                if (isDesc) { this.key2.tri = Ordre.DESC; }
            }

            //Clé 3
            if (split.length > 2) {
                isDesc = split[2].includes('-');
                theSplit = split[2].replace('-','');
                colonne = this.columns.find(c => c.key == theSplit);
                if (!colonne) { throw new Error("Colonne 3 invalide") }
                this.key3.colonne = colonne;
                if (isDesc) { this.key3.tri = Ordre.DESC; }
            }
        }
    }

    /**
     * Inversion du mode de recherche
     */
    toggleSearch(): void {
        if (!this.search || this.search == SearchType.STARTS_WITH) {
            this.search = SearchType.CONTAINS;
        } else {
            this.search = SearchType.STARTS_WITH;
        }
    }

    /**
     * Reset du tri par défaut
     */
    reset(): string {
        //Reset de l'objet
        this.parseExistingOrder(this.backupDefaultOrder);

        //Retour
        return this.backupDefaultOrder;
    }
}

/**
 * Classe de colonne
 */
export class Colonne {
    /**
     * Constructeur
     * @param key Clé
     * @param title Intitulé
     */
    constructor(key: string, title: string) {
        this.key = key;
        this.title = title;
    }

    //Valeurs
    key: string;
    title: string;
}

/**
 * Classe de clé de tri
 */
export class CleDeTri {

    /**
     * Constructeur
     * @param colonne Colonne concernée
     */
    constructor(colonne: Colonne) {
        this.colonne = colonne;
        this.tri = Ordre.ASC;
    }

    //Valeurs
    colonne: Colonne;
    tri: Ordre;

    /**
     * Inversion de l'ordre du tri
     */
    toggle(): void {
        if (!this.tri || this.tri == Ordre.ASC) {
            this.tri = Ordre.DESC;
        } else {
            this.tri = Ordre.ASC;
        } 
    }
}

/**
 * Enum d'ordre de tri
 */
export enum Ordre {
    ASC = 'ASC',
    DESC = 'DESC'
}

/**
 * Enum de type de recherche
 */
export enum SearchType {
    STARTS_WITH = 'STARTS_WITH',
    CONTAINS = 'CONTAINS'
}
