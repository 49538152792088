import {BehaviorSubject,Observable,of,ReplaySubject,Subject,Subscription} from "rxjs";
import {ACTIONS_WF_STATIQUES,TypeAction as TypeActionWorkflow,TypePortee} from "../../domain/workflow/workflow";
import {WorkflowService} from "./workflow.service";
import {ToastrService} from "ngx-toastr";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {TypeProfil,User} from "@domain/user/user";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {filter,finalize,first,map} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {TranslateService} from "@ngx-translate/core";
import {ListeAlertes} from "@domain/common/alerte/listeAlertes";
import {AlerteType} from "@domain/common/alerte/alerteType";
import {AbstractObjetWorkflow} from "@domain/workflow/abstract-objet-workflow";
import {AfterViewInit,Component,Inject,OnDestroy,OnInit,QueryList,ViewChildren} from "@angular/core";
import {ActivatedRoute,Navigation,Params,Router} from "@angular/router";
import {SettingsGlobalState} from "@domain/settings/settings";
import * as settingsActions from '../../reducers/settings';
import {IObjetWorkflowService} from "./objet-workflow.service";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {ChainageService} from "../chainage/chainage.service";
import {IMapData} from "@domain/workflow/IMapData";
import {AnalytiqueService} from "@components/analytique/analytique.service";
import {Alerte} from "@domain/common/alerte/alerte";
import {ReportingService} from "@components/reporting/reporting.service";
import {Report} from "@domain/reporting/report";
import {MatDialog} from "@angular/material/dialog";
import {ReportListComponent} from "@components/workflow/report-list/report-list.component";
import {WorkflowHistoriqueAction} from "@domain/chainage/workflowHistorique";
import {RoutingContext} from "@domain/common/routing/routing-context";
import {Location} from "@angular/common";
import {PageHeaderComponent} from "@share/component/page-header/page-header.component";

@Component({
	template: ''
})
export abstract class AbstractObjetWorkflowComponent<T extends AbstractObjetWorkflow,U extends SettingsGlobalState> implements OnInit, OnDestroy, AfterViewInit {
	/** Constante - Code du bouton d'action d'enregistrement */
	public readonly ACTION_ENREGISTRER = 'ENREGISTRER';

	/** Liste des onglets */
	listeTabItems: Array<PageHeaderItem>;

	/** Onglet sélectionné */
	protected _selectedItem: PageHeaderItem = null;

	/** Getter de l'onglet sélectionné */
	get selectedItem(): PageHeaderItem {
		return this._selectedItem;
	}

	/**
	 * Setter de l'onglet sélectionné.
	 * Il peut être utile de le surcharger pour gérer le chargement des onglets<br>
	 * <strong>ATTENTION</strong> : en cas de surcharge, il faut également surcharger le getter (standard typescript)
	 */
	set selectedItem(item: PageHeaderItem) {
		//Edition de la variable privée
		this._selectedItem = item;
	}

	/** Composant enfant PageHeader. Il y en a 1 seul, mais passer par un QueryList permet de subscribe aux changements. */
	@ViewChildren('pageHeaderComponent')
	pageHeaders: QueryList<PageHeaderComponent>

	/** Utilisateur connecté */
	user: User;

	/** Indique la connexion en tant qu'admin */
	protected isAdmin: boolean;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Liste des alertes de l'objet */
	listeAlertes: ListeAlertes = null;

	/** Indicateur de modification possible */
    get canModifier(): boolean { return this.objetWorkflow?.canModifier(); }

	/** Indicateur de complétion possible */
    get canCompleter(): boolean { return this.objetWorkflow?.canCompleter(); }

	/** Enregistrement en cours */
	isSaving: boolean = false;

	/** Chargement en cours */
	isLoading: boolean = false;

	/** Objet workflow concerné */
	objetWorkflow: T;

	/** Paramétrage */
	settings: U;

	/** Id collaborateur pour l'ouverture de la fiche collaborateur */
	idCollaborateur: number;

	/** Paramètres de navigation */
	routingContext: RoutingContext;

	/** State de la route */
	routeState: { [p: string]: any };

	previousNavigation: Navigation;

	/** Souscription au service workflow */
	subscription: Subscription;

	/**
	 * Constructeur
	 * @param translateService  Service de traduction
	 * @param store             Store
	 * @param toastrService     Service de toasts
	 * @param activatedRoute    Route active
	 * @param router            Router
	 * @param workflowService   Service du workflow
	 * @param chainageService   Service du chainage
	 * @param portee            Portée
	 * @param objetService      Service de la portée concernée
	 * @param analytiqueService Service de l'analytique
	 * @param reportingService  Service de gestion des rapports
	 * @param matDialog         Service de popup
	 * @param location			Location (URL)
	 */
	constructor(
		protected translateService: TranslateService,
		protected store: Store<AppState>,
		protected toastrService: ToastrService,
		protected activatedRoute: ActivatedRoute,
		protected router: Router,
		protected workflowService: WorkflowService,
		protected chainageService: ChainageService,
		protected analytiqueService: AnalytiqueService,
		protected reportingService: ReportingService,
		protected matDialog: MatDialog,
		protected location: Location,
		@Inject("TypePortee") protected portee: TypePortee,
		@Inject("IObjetWorkflowService") protected objetService: IObjetWorkflowService<T>,
	) {
		//Contrôles à l'initialisation
		if (!this.portee || !this.objetService) {
			throw new Error("Elément manquant lors de l'initialisation du composant.");
		}

		const currentNavigation = this.router.getCurrentNavigation();

		//Récupération du state de la route
		this.routeState = currentNavigation.extras?.state;
		this.previousNavigation = currentNavigation.previousNavigation;

		//Souscription au subject du service workflow indiquant s'il faut reload l'objet workflow actuel
		this.subscription = this.workflowService.needRefresh$?.subscribe(() => this.quickReload(this.objetWorkflow.getId()));
	}

	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
	}

	/**
	 * Initialisation
	 */
	async ngOnInit(): Promise<void> {
		//Sélection de l'utilisateur connecté
		this.store.select(state => state.session?.user).subscribe(user => this.user = user);
		this.store.select(state => state.session).subscribe(session => this.isAdmin = session.isAdmin);

		//Récupération des settings propres au type d'objet
		await this.initSettings();

		//Chargement de l'objet à l'initialisation
		this.loadObjetOnInit();

		//Définition de la liste des actions possibles
		this.listeActions.next([{
			type: TypeAction.SECONDARY,
			code: this.ACTION_ENREGISTRER,
			icone: 'nio icon-sauvegarde',
			libelle: 'global.actions.enregistrer',
            message: () => this.objetWorkflow?.getMapAction()?.canModifier?.message,
			doAction: () => this.save(),
			isVisible: () => this.objetWorkflow?.getMapAction()?.canModifier?.possible
		},{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-rappeler',
			libelle: [TypeProfil.COLLABORATEUR,TypeProfil.ASSISTANT].includes(this.user?.fonction) ? 'global.actions.rappeler' : 'global.actions.invalider',
            message: () => this.objetWorkflow?.getMapAction()?.canInvalider?.message,
			doAction: () => this.doWorkflowAction(TypeActionWorkflow.INVALIDER),
			isVisible: () => this.objetWorkflow?.getMapAction()?.canInvalider?.possible
		},{
			type: TypeAction.SECONDARY,
			icone: 'nio icon-suppression',
			libelle: 'global.actions.supprimer',
            message: () => this.objetWorkflow?.getMapAction()?.canSupprimer?.message,
			doAction: () => this.doWorkflowAction(TypeActionWorkflow.SUPPRIMER),
			isVisible: () => this.objetWorkflow?.getMapAction()?.canSupprimer?.possible
		},{
			type: TypeAction.SECONDARY,
			icone: 'nio icon-reporting',
			libelle: 'global.actions.extraction',
			doAction: () => this.listReports(),
			isVisible: () => this.objetWorkflow?.getId() > 0
		},{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-emission',
			libelle: 'global.actions.emettre',
            message: () => this.objetWorkflow?.getMapAction()?.canEmettre?.message,
			doAction: () => this.doWorkflowAction(TypeActionWorkflow.EMETTRE),
			isVisible: () => this.objetWorkflow?.getMapAction()?.canEmettre?.possible
		},{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-annulation',
			libelle: 'global.actions.annuler',
            message: () => this.objetWorkflow?.getMapAction()?.canAnnuler?.message,
			doAction: () => this.doWorkflowAction(TypeActionWorkflow.ANNULER),
			isVisible: () => this.objetWorkflow?.getMapAction()?.canAnnuler?.possible
		},{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-rejet',
			libelle: 'global.actions.rejeter',
            message: () => this.objetWorkflow?.getMapAction()?.canRejeter?.message,
			doAction: () => this.doWorkflowAction(TypeActionWorkflow.REJETER),
			isVisible: () => this.objetWorkflow?.getMapAction()?.canRejeter?.possible
		},{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-validation',
			libelle: 'global.actions.valider',
            message: () => this.objetWorkflow?.getMapAction()?.canValider?.message,
			doAction: () => this.doWorkflowAction(TypeActionWorkflow.VALIDER),
			isVisible: () => this.objetWorkflow?.getMapAction()?.canValider?.possible
		},{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-restitution',
			libelle: 'global.actions.restituer',
			message: () => this.objetWorkflow?.getMapAction()?.canRestituer?.message,
			doAction: () => this.doWorkflowAction(TypeActionWorkflow.RESTITUER),
			isVisible: () => this.objetWorkflow?.getMapAction()?.canRestituer?.possible
		},{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-archive',
			libelle: 'global.actions.archiver',
			message: () => this.objetWorkflow?.getMapAction()?.canArchiver?.message,
			doAction: () => this.doWorkflowAction(TypeActionWorkflow.ARCHIVER),
			isVisible: () => this.objetWorkflow?.getMapAction()?.canArchiver?.possible
		},{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-cloture',
			libelle: 'global.actions.cloturer',
			message: () => this.objetWorkflow?.getMapAction()?.canCloturer?.message,
			doAction: () => this.doWorkflowAction(TypeActionWorkflow.CLOTURER),
			isVisible: () => this.objetWorkflow?.getMapAction()?.canCloturer?.possible
		},{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-octroi',
			libelle: 'global.actions.attribuer',
			message: () => this.objetWorkflow?.getMapAction()?.canAttribuer?.message,
			doAction: () => this.doWorkflowAction(TypeActionWorkflow.ATTRIBUER),
			isVisible: () => this.objetWorkflow?.getMapAction()?.canAttribuer?.possible
		}]);
	}

	/**
	 * Interception de l'initialisation de la vue.
	 */
	ngAfterViewInit() {
		if (this.user.fonction !== TypeProfil.ADMINISTRATEUR) {
			//pas d'interception de clic pour l'administrateur même si l'objet n'est pas valide
			this.registerTabClick();
		}
	}

	registerTabClick(){};

	/**
	 * Initialisation des onglets
	 */
	initTabs(creation?: boolean): void {
		//Ajout de l'onglet workflow
		if (this.user.fonction === TypeProfil.ADMINISTRATEUR) {
			this.listeTabItems.push({
				code: OngletsAdmin.WORKFLOW,
				libelle: this.translateService.instant('admin.ongletWF.title'),
			},{
				code: OngletsAdmin.OUTILS,
				libelle: this.translateService.instant('admin.ongletOutils.title'),
			});
		}
	};


	/**
	 * Changement d'onglet
	 *
	 * @param selectedItem Onglet sélectionné
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem) {
		//Mise à jour de l'indicateur d'activation pour chaque onglet
		this.listeTabItems?.forEach(tab => {
			tab.selected = tab === selectedItem;
		});

		//Mise à jour de l'onglet sélectionné
		selectedItem.isLoaded = true;
		selectedItem.selected = true;
		this.selectedItem = selectedItem;
	}

	/**
	 * Sélection de l'onglet au chargement initial de l'objet soit à partir :<br/>
	 * - de la route (doit être déclaré dans le fichier routes.ts au format : /{portée}/{id}/{tab} <br/>
	 * - des paramètres HTTP (format /{portée}/{id}?tab=xxx <br/>
	 * Si les 2 sont fournis celui de la route est prioritaire.
	 * Si un onglet est ciblé mais qu'il n'existe pas, celui par défaut sera sélectionné.
	 * Note : Doit être exécuté avant le chargement du page-header sinon le contenu sera bien affiché mais le tab-header ne sera pas mis à jour...
	 */
	selectTabOnInit(): Observable<void> {
		//On attend que les onglets soient tous chargés
		return this.activatedRoute.queryParams.pipe(first())
			.map(params => {
				let defaultTab: PageHeaderItem;
				let selectedTab: PageHeaderItem;

				//Récupération de l'onglet depuis le state de la route ou des paramètres HTTP
				const indexOrCode = this.routeState?.tab ?? params?.tab;

				//Vérification du ciblage de l'onglet par son index ou son code
				if (!Number.isNaN(Number(indexOrCode)) && indexOrCode >= 0 && indexOrCode < this.listeTabItems?.length) {
					//Activation d'un onglet par son index
					selectedTab = this.listeTabItems[indexOrCode]
				} else if (typeof indexOrCode == 'string') {
					//Activation d'un onglet par son code
					selectedTab = this.listeTabItems?.find(tab => tab.code === indexOrCode);
				}

				//Si aucun onglet n'est sélectionné, celui par défaut est activé
				if (!selectedTab && (defaultTab = this.getDefaultTab())) {
					selectedTab = defaultTab;
				}

				//Si un onglet doit être activé
				if (selectedTab) {
					//Définition de l'onglet actif
					this.onSelectedItemChange(selectedTab);
				}
			});
	}

	/**
	 * Retourne l'onglet à afficher par défaut (à surcharger pour afficher un onglet particulier lors de l'ouverture d'un objet)
	 */
	getDefaultTab(): PageHeaderItem {
		return null;
	}

	/**
	 * Initialise les paramètres.
	 */
	private async initSettings(): Promise<void> {
		return new Promise<void>((resolve,reject) => {
			//Chargement du paramétrage de l'objet WF
			this.store.dispatch({
				type: settingsActions.LOAD_SETTINGS,
				payload: this.portee
			});

			//Sélection du paramétrage
			this.store.select(state => state.settings?.[this.portee]).pipe(filter(settings => !!settings)).subscribe(settings => {
				this.settings = settings;
				resolve();
			},error => reject());
		});
	}

	/**
	 * Charge l'objet au chargement de la page.
	 */
	private loadObjetOnInit(): void {
		//Récupération des paramètres de navigation
		this.activatedRoute.params.pipe(first()).subscribe(params => {
			//Initialisation du contexte de la consultation
			this.initRoutingContext(params);

			if (!this.routingContext || !this.routingContext.returnRoute) {
				throw new Error("Une erreur est survenue lors de l'initialisation du contexte de consultation du composant.");
			}

			//Chargement de l'objet
			this.loadObjet(params.id).pipe(first()).subscribe(
				(objetWorkflow: T) => {
					//Initialisation des onglets
					this.initTabs();

					//Activation de l'onglet à afficher
					this.selectTabOnInit().subscribe(() => {
						//Définition de l'objet dans le composant : déclenche l'affichage
						this.objetWorkflow = objetWorkflow;
					});
				},
				(err) => {
					if (TypeCodeErreur.isTypeCodeErreur(err)) {
						//On affiche le message d'erreur
						TypeCodeErreur.showError(err,this.translateService,this.toastrService);
					} else if (err !== -1) {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('global.errors.chargement'));
					}

					//On dit que l'objet est null comme ça on peut gérer l'affichage dans le HTML
					this.objetWorkflow = null;
				}
			);
		});
	}

	/**
	 * Charge l'objet.
	 *
	 * @param idObjet Identifiant de l'objet
	 * @return Observable<T> Un observable contenant l'objet chargé
	 */
	private loadObjet(idObjet: number): Observable<T> {
		//Résultat d'une capacité de 1 pour stocker l'objet chargé et permettre de le récupérer même en s'abonnant après le complete
		const result: ReplaySubject<T> = new ReplaySubject(1);

		//Si l'objet existe déjà
		if (idObjet > 0) {
			//Chargement de l'objet
			this.objetService.load(idObjet).subscribe({
				next: (resultat) => {
					if (resultat.codeErreur === TypeCodeErreur.NO_ERROR) {
						//Construction de l'objet
						const objetWorkflow = this.buildLoadedObjet(resultat.data);

						//Chargement de la dernière action workflow effectuée sur l'objet
						if (resultat.data.lastWorkflowHistoriqueAction) {
							objetWorkflow.lastWorkflowHistoriqueAction = new WorkflowHistoriqueAction(resultat.data.lastWorkflowHistoriqueAction);
						}

						//Chargement de l'ID Collaborateur
						this.idCollaborateur = objetWorkflow.getIdCollab();

						//Construction de la liste des alertes
						this.buildListeAlertes(objetWorkflow);

						//Emission
						result.next(objetWorkflow);
						result.complete();
					} else {
						//Erreur
						result.error(resultat.codeErreur);
					}
				},
				error: (err) => {
					//Erreur
					result.error(err);
				}
			});

			//Retour
			return result.asObservable();
		} else {
			//Construction d'un nouvel objet
			this.buildNewObjet().then((objet: T) => {
				//Envoi du nouvel objet
				result.next(objet);
				result.complete();
			}).catch((err) => {
				//Erreur
				result.error(err);
			});

			//Retour
			return result.asObservable();
		}
	}

	/**
	 * Construit l'objet à partir des données brutes non typées.
	 *
	 * @param data données brutes non typées
	 * @returns une instance de l'objet
	 */
	protected abstract buildLoadedObjet(data: any): T;

	/**
	 * Construit un nouvel objet.
	 *
	 * @returns une instance de l'objet
	 */
	protected abstract buildNewObjet(): Promise<T>;

	/**
	 * Enregistre l'objet en cours.
	 *
	 * @param isReload true si on a besoin de recharger l'objet (true par défaut)
	 */
	protected save(isReload = true): Observable<boolean> {
		//Initialisation
		const saveResult: Subject<boolean> = new Subject<boolean>();

		//Vérification de la validité du formulaire
		if (this.isValid()) {
			//Enregistrement en cours
			this.isSaving = true;

			//Enregistrement de l'objet
			this.saveObjet().pipe(first(),finalize(() => this.isSaving = false)).subscribe({
				next: result => {
					//Vérification de l'enregistrement
					if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
						//Message d'information
						this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

						//On récupère l'id du back si c'est un objet généré par le front (cas des avances et des factures)
						const objetId = this.objetWorkflow.getId() === 0 ? result.data.id : this.objetWorkflow.getId();

						//Si on a besoin d'un rechargement
						if (isReload) {
							//Rechargement de l'objet
							this.reloadObjetAfterSave(objetId).pipe(first()).subscribe({
								next: () => {
									saveResult.next(true);
									saveResult.complete();
								},
								error: () => {
									//Message d'erreur
									this.toastrService.error(this.translateService.instant('global.errors.chargement'));

									//Remontée l'échec du rechargement
									saveResult.next(false);
									saveResult.complete();
								}
							});
						} else {
							//Si on ne recharge pas, on envoie direct du producteur au consommateur
							saveResult.next(true);
							saveResult.complete();
						}
					} else {
						//Traitement de l'erreur
						this.onSaveError(result);

						//Remontée l'échec de l'enregistrement aux éventuels subscribers
						saveResult.next(false);
						saveResult.complete();
					}
				},
				error: () => {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));

					//Remontée l'échec de l'enregistrement aux éventuels subscribers
					saveResult.next(false);
					saveResult.complete();
				}
			});
		} else {
			//Message d'erreur
			this.toastrService.error(this.translateService.instant('global.errors.formInvalid'));

			//Remontée l'échec de l'enregistrement aux éventuels subscribers
			saveResult.next(false);
			saveResult.complete();
		}

		//Retour de l'observable sur l'enregistrement
		return saveResult.asObservable();
	}

	/**
	 * Vérifie si le formulaire est valide. Utilisé pour autoriser l'enregistrement d'un formulaire ou des traitements admin.
	 *
	 * @return boolean True si le formulaire est valide, False sinon
	 */
	abstract isValid(): boolean;

	/**
	 * Envoie l'objet au back pour enregistrement.
	 *
	 * @return Observable<Result> Le résultat de l'enregistrement
	 */
    protected saveObjet(): Observable<Result> {
		//Préparation et sauvegarde
		return this.objetService.save(this.beforeSaveObjet(this.objetWorkflow));
	}

	/**
	 * Prépare de l'objet avant sauvegarde. Ne fait rien par défaut.
	 *
	 * @param objet objet à préparer
	 * @returns objet préparé
	 */
	protected beforeSaveObjet(objet: T): T {
		return objet;
	}

	/**
	 * Exécution de l'enregistrement de l'objet suivant le type d'action workflow
	 *
	 * @return Promise<boolean> Le succès ou l'échec de l'enregistrement
	 */
	saveBeforeAction(typeAction: TypeActionWorkflow): Promise<boolean> {
		//On enregistre avant une action (si on a le droit de modification et que ce n'est pas une suppression), mais pas besoin de recharger l'objet
		return (this.canModifier && typeAction !== TypeActionWorkflow.SUPPRIMER ? this.save(false) : of(true)).toPromise();
	}

	/**
	 * Traite les erreurs lors de l'enregistrement de l'objet
	 *
	 * @param result Le résultat de l'enregistrement
	 */
	protected onSaveError(result: Result): void {
		if (result.codeErreur != TypeCodeErreur.NO_ERROR) {
			//On ajoute une alerte si existante
			if (result.data?.listeAlertes != null && result.data?.listeAlertes.length > 0) {
				result.data.listeAlertes.forEach((alerte: Alerte) => {
					//On regarde si le message d'erreur existe dans l'enum
					if (Object.values(AlerteType).includes(alerte.type as AlerteType)) {
						//On va chercher le message d'erreur lié au type
						this.toastrService.error(this.translateService.instant('workflow.alerte.toast.' + alerte.type));
					} else {
						//Toast générique
						this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
					}

					this.addAlerte(alerte);
				});
			} else {
				//Message d'erreur
				TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
			}
		}
	}

	/**
	 * Réalisation de l'action Workflow
	 */
	protected doWorkflowAction(typeAction: TypeActionWorkflow): void {
		//Lancement de l'enregistrement si nécessaire
		this.saveBeforeAction(typeAction)
			.then((isSuccess) => {
				//Vérification que l'enregistrement a réussi
				if (isSuccess) {
					//Début du traitement
					this.isSaving = true;

					//Vérification de la présence d'alertes bloquantes empêchant l'exécution de l'action workflow
					this.checkBeforeActionWorkflow(typeAction).pipe(first()).subscribe((isActionPossible) => {
						//Pas d'alerte bloquante, exécution de l'action workflow
						if (isActionPossible) {
							//On lance les éventuelles actions à faire avant le workflow
							this.doBeforeWorkflowAction(typeAction).pipe(first()).subscribe((mapData: IMapData) => {
								//Si c'est bon, on continue
								if (mapData.isOk) {
									//Réalisation de l'action Workflow
									this.workflowService.doWorkflowAction(this.portee,typeAction,this.objetWorkflow,(id: number) => this.quickReload(id),mapData)
										.pipe(first(),finalize(() => this.isSaving = false))
										.subscribe({
											next: isSuccess => {
												//Vérification du résultat
												if (isSuccess === true) {
													//Retour sur la liste
													this.onGoBack();
												} else if (isSuccess === false) {
													this.toastrService.error(this.translateService.instant('global.errors.actionWF'));

                                                    //Fin du traitement
                                                    this.isSaving = false;
												}
											},
											error: () => {
												this.toastrService.error(this.translateService.instant('global.errors.actionWF'));

                                                //Fin du traitement
                                                this.isSaving = false;
											}
										});
                                } else {
                                    //Fin du traitement
                                    this.isSaving = false;
								}
							});
						} else {
							//Fin du traitement
							this.isSaving = false;
						}
					});
				}
			});
	}

	/**
	 * Méthode qui appelle le reload et c'est tout
	 *
	 * @param id Id de l'objet à reload
	 */
	protected quickReload(id: number): void {
		this.reloadObjet(id).pipe(first()).subscribe();
	}

	/**
	 * Retourne la liste des actions levées par l'objet en faisant appel au service du workflow et met à jour la liste de l'objet.
	 * Peut être surchargé pour retourner la liste directement depuis l'objet (par exemple si le retour de l'enregistrement contient déjà la liste, inutile de faire appel au service).
	 * ex : return of(this.getObjet().getListeAlertes());
	 *
	 * @return Observable<ListeAlertes> La liste des alertes levées par l'objet
	 */
	protected getAlertesAfterSave(): Observable<ListeAlertes> {
		//Récupération de la liste depuis le service
		return this.workflowService.retrieveListeAlertes(this.portee,this.objetWorkflow?.getId()).pipe(
			map((listAlertes) => {
				//Mise à jour de la liste des alertes sur l'objet
				this.objetWorkflow?.setListeAlertes(listAlertes);

				this.buildListeAlertes(this.objetWorkflow);

				//Retourne la liste des alertes
				return listAlertes;
			})
		);

	}

	/**
	 * Fait appel au service du workflow pour récupérer la liste des alertes afin de vérifier si une action donnée est possible.
	 * Met à jour la liste des alertes de l'objet.
	 *
	 * @param typeAction Type d'action workflow à exécuter
	 */
	protected checkAlertesBeforeActionWorkflow(typeAction: TypeActionWorkflow): Observable<boolean> {
		//Si l'action n'est pas dans la liste des actions non bloquantes
		if (!ACTIONS_WF_STATIQUES.includes(typeAction)) {
			//Lors d'actions workflow, il faut vérifier les alertes bloquantes
			return this.getAlertesAfterSave().pipe(map(listeAlertes => {
				return !listeAlertes || listeAlertes.niveau <= 1;
			}))
		} else {
			//Pas de vérification nécessaire
			return of(true);
		}
	}

	/**
	 * Appelé avant d'exécuter une action workflow sur l'objet.
	 * Vérifie l'absence d'alertes bloquantes.
	 *
	 * @param typeAction Type d'action
	 */
	protected checkBeforeActionWorkflow(typeAction: TypeActionWorkflow): Observable<boolean> {
		//Vérifie les alertes suivant le type d'action
		return this.checkAlertesBeforeActionWorkflow(typeAction).pipe(
			first(),
			map((value: boolean) => {
				//La vérification a échouée
				if (!value) {
					//Affichage d'un message d'erreur
					this.toastrService.error(this.translateService.instant('global.errors.alertesBloquantes'));
				}

				//Retour du résultat de la vérification
				return value;
			})
		);
	}

	/**
	 * Recharge l'objet après un enregistrement.
	 *
	 * @param objetId Identifiant de l'objet
	 */
	protected reloadObjetAfterSave(objetId: any): Observable<T> {
		return this.loadObjet(objetId).pipe(first(),map(((objetWorkflow: T) => {
			//Mise à jour de l'objet
			this.objetWorkflow = objetWorkflow;

			return objetWorkflow;
		})));
	}

	/**
	 * Recharge l'objet.
	 *
	 * @param objetId Identifiant de l'objet
	 */
	reloadObjet(objetId: number): Observable<T> {
		//Début du rechargement
		this.isLoading = true;

		//Rechargement de l'objet
		return this.loadObjet(objetId)
			.pipe(first(),map((objetWorkflow) => {
					//Mise à jour de l'objet
					this.objetWorkflow = objetWorkflow;

					return objetWorkflow;
				}),
				//Fin du rechargement
				finalize(() => this.isLoading = false));
	}

	/**
	 * Retour arrière
	 */
	onGoBack(): void {
		//Navigation vers la route de retour
		this.router.navigate([this.routingContext.returnRoute],this.routingContext.extras);
	}

	/**
	 * Affiche le chainage de l'objet.
	 */
	showChainage() {
		//Affichage du chainage
		this.chainageService.showChainageForObject(this.portee,this.objetWorkflow.getId());
	}

	/**
	 * Action à effectuer avant l'action Workflow.
	 * Permet de brancher des actions supplémentaires non prévues par le workflow
	 *
	 * Si inutile, faire : return of({isOk: true});
	 *
	 * @param typeAction Type de l'action Workflow
	 */
	doBeforeWorkflowAction(typeAction: TypeActionWorkflow): Observable<IMapData> {
		return of({isOk: true});
	};

	/**
	 * Construit la liste des alertes qui sera affichée dans le page header
	 * Ajoute à la volée une alerte "hardcodée" dans le cas où l'analytique est invalide
	 */
	buildListeAlertes(objetWorkflow: T = this.objetWorkflow): void {
		//(re)Initialisation de la liste des alertes
		this.listeAlertes = new ListeAlertes(objetWorkflow.getListeAlertes());

		//Vérification de la présence d'analytique sur l'objet (ce n'est pas le cas pour les avances)
		if (objetWorkflow.getListeVentilation()) {
			//Vérification de la validité du cadre analytique
			if (!this.analytiqueService?.isValid(objetWorkflow.getListeVentilation(),objetWorkflow.getTypeEntite(),this.settings)) {
				//Si invalide, ajout de l'alerte à la liste
				this.listeAlertes.add(this.analytiqueService.buildAlerteAnalytiqueInvalid());
			}
		}
	}

	/**
	 * Affiche la liste des rapports d'extraction d'un objet workflow dans une popin.
	 * S'il existe un seul rapport, affiche directement ce rapport sans passer la popin.
	 */
	listReports(): void {
		//Chargement de la liste des rapports pour cette portée
		this.reportingService.loadListeByPorteeAndType(this.portee,1).pipe(first()).subscribe(result => {
			let listeReports: Report[] = result.data.listeReport;

			if (listeReports.length == 1) { //Si 1 seul rapport
				//Exécution et affichage du rapport
				this.reportingService.executeReportWFO(listeReports[0].idReport,this.portee,this.objetWorkflow.getId());
			} else { //Si plusieurs rapports
				//Ouverture de la popin avec la liste des rapports
				this.matDialog.open(ReportListComponent,{
					data: {
						listeReports: listeReports,
						portee: this.portee,
						idObjet: this.objetWorkflow.getId()
					},
					hasBackdrop: true,
				});
			}
		});
	}

	/**
	 * Ajoute une alerte
	 *
	 * @param alerte alerte à ajouter
	 */
	protected addAlerte(alerte: Alerte): void {
		this.listeAlertes.add(alerte);
	}

	/**
	 * Supprime une alerte
	 *
	 * @param title titre de l'alerte qui est utilisé comme clé
	 */
	protected removeAlerte(title: String) {
		const alerte = this.listeAlertes.listeAlertes.find((a: Alerte) => a.getKey() == title);
		this.listeAlertes.remove(alerte);
	}

	/**
	 * Cherche une alerte
	 *
	 * @param title titre de l'alerte qui est utilisé comme clé
	 */
	protected isAlerteExist(title: String): boolean {
		return this.listeAlertes.listeAlertes.some((a: Alerte) => a.getKey() == title);
	}

	/**
	 * Initialise le contexte de la consultation de l'objet courant
	 *
	 * @param activatedRouteParams query params de la route courante
	 */
	protected abstract initRoutingContext(activatedRouteParams: Params): void;
}

/**
 * Enum des différents onglets disponibles sur la page
 */
export enum OngletsAdmin {
	WORKFLOW = 'Workflow',
	OUTILS = 'OUTILS'
}
