import {AfterContentChecked,Component,EventEmitter,Input,OnChanges,OnInit,Output,ViewChild} from "@angular/core";
import {Localisation,Origine,Visibilite} from "@domain/geolocalisation/localisation";
import {TypeGeolocalisation} from "@domain/geolocalisation/typeGeolocalisation";
import {LieuGeolocalisationListComponent} from "../geolocalisation/lieu-geolocalisation-list.component";
import {NgForm,NgModel} from "@angular/forms";
import {GeolocalisationTool} from "@domain/geolocalisation/geolocalisationTool";
import {TranslateService} from "@ngx-translate/core";
import {LieuService} from "../../lieu.service";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {TypePortee} from "@domain/workflow/workflow";
import {finalize,first} from "rxjs/operators";
import {Adresse} from "@domain/profil/adresse";
import {TypeAdresse,TypeAdresseEnum} from "@domain/geolocalisation/type-adresse";

@Component({
    selector: 'localisation',
    host: {'data-test-id': 'localisation'},
    templateUrl: './lieu-localisation.component.html',
})
export class LieuLocalisationComponent implements OnInit,AfterContentChecked,OnChanges {
    /** Déclaration des enums pour le template */
    readonly TypePortee = TypePortee;

    /** Event émis lors de la sélection d'une localisation */
    @Output() onLocalisationSelected = new EventEmitter<Localisation>();

    /** Localisation saisie / géolocalisée */
    @Input() localisation: Localisation = {};

    /** Type de l'adresse saisie */
    @Input() typeAdresse: TypeAdresse;

    /** Adresse source */
    @Input() adresse: Adresse;

    /** Configuration de l'outil de géolocalisation */
    @Input() geolocalisationTool: GeolocalisationTool;

    /** Paramétrage du type entité de la mission permanente */
    @Input() typeEntiteParam: any;

    /** Portée de l'objet */
    @Input() idPortee: TypePortee;

    /** Propriétaire de l'objet */
    @Input() idUser: number;

    /** Modèle pour l'autocomplete du pays */
    saisiePays: any;

    /** Paramètre de configuration indiquant si la saisie d'une adresse est autorisée */
    isSaisieAutorisee: boolean = false;

    /** Flag permettant de fermer directement la popup lors d'un clic sur le bouton valider de l'onglet de saisie */
    isAdresseModifiee: boolean = false;

    /** Etat de validité du formulaire de saisie d'une adresse */
    isFormSaisieInvalid: boolean = false;

    /** Indique si l'adresse saisie doit être  publique (idUser == 0) ou privée (idUser != 0) */
    isAdressePublique: boolean = false;

    /** Indique si l'utilisateur a déjà cliqué sur la visibilité */
    isVisibiliteDefinie = false;

    /** Flag permettant de savoir si un chargement (ajax) est en cours */
    isLoading: boolean = false;

    /** Formulaire de saisie d'une adresse */
    @ViewChild("saisieForm")
    saisieForm: NgForm;

    /** Autocomplete du pays */
    @ViewChild("autocompletePays")
    autocompletePays: NgModel;

    constructor(private translateService: TranslateService,
                private lieuService: LieuService,
                private toastrService: ToastrService,
                private matDialog: MatDialog) {
    }

    ngOnInit(): void {
        //Initialisation du pays pour afficher la valeur dans l'autocomplete
        if (this.localisation?.pays) {
            this.saisiePays = {
                libelle: this.localisation.pays
            };
        }

        //Vérification de l'autorisation de saisie d'une adresse depuis la configuration du type entité
        this.isSaisieAutorisee = this.lieuService.isSaisieAutorisee(this.typeEntiteParam,this.idPortee);

        //L'adresse est publique si elle appartient à l'admin ou qu'elle n'a pas de rue
        if (this.localisation?.idUser == 0 || !this.localisation?.rue) {
            this.isAdressePublique = true;
        }
    }

    /**
     * Initialisation du form à partir d'une adresse
     *
     * @param adresse Adresse d'origine
     */
    initFromAdresse(adresse: Adresse) {
        //Copie des informations de l'adresse dans la localisation
        this.localisation = Object.assign({},adresse);

        //On conserve la référence vers l'adresse pour la mise à jour
        this.adresse = adresse;

        //Mise à jour du pays pour l'autocomplete
        this.saisiePays = {
            libelle: this.localisation.pays
        };
    }

    /**
     * Détection des modifications
     */
    ngOnChanges(): void {
        //Vérification de l'état de validité du formulaire de saisie
        this.isFormSaisieInvalid = this.saisieForm?.invalid;
    }

    /**
     * Post-chargement des composants
     */
    ngAfterContentChecked(): void {
        //Initialisation de l'état de validité du formulaire de saisie
        this.isFormSaisieInvalid = this.saisieForm?.invalid;
    }

    /**
     * Mise à jour de l'objet de saisie avec le pays sélectionné dans l'autocomplete
     *
     * @param geographie Objet Geographie sélectionné dans l'autocomplete
     */
    onPaysChange(geographie: any) {
        //Si le pays choisis est différent de la valeur initiale. Comparaison sur le code et non l'id car c'est la seule donnée enregistré sur le lieu
        if (this.localisation.codePays != geographie.pays.code3) {
            this.setAdresseModifiee();
        }

        //Mise à jour de l'objet
        this.localisation.idPays = geographie?.pays?.idPays;
        this.localisation.pays = geographie?.pays?.libelle;
        this.localisation.codePays = geographie?.pays?.code3;
    }

    /**
     * Vérification de l'activation de l'outil de géolocalisation
     */
    isGeoloc() {
        return this.geolocalisationTool && this.geolocalisationTool.geolocalisationTool != TypeGeolocalisation.DISABLED;
    }

    /**
     * Validation de la saisie d'une adresse.
     * Fait appel à la géolocalisation si active.
     */
    validerSaisie(): void {
        let geolocalisation: Localisation;

        //Vérification que l'utilisateur a modifié l'adresse initiale et que le type entité autorise la saisie d'une adresse
        if (this.isAdresseModifiee && this.isSaisieAutorisee) {
            //Vérification de l'activation de la géolocalisation
            if (this.isGeoloc()) {
                //Vérification du type de géolocalisation et que le quota de la licence Via Michelin n'est pas atteint
                if (this.geolocalisationTool.geolocalisationTool != TypeGeolocalisation.VIA_MICHELIN || !this.geolocalisationTool.quotaLicence.quotaAtteint) {
                    //Copie de l'objet
                    geolocalisation = {
                        ...this.localisation
                    };

                    //Vérification si l'outil de géoloc est Google Maps
                    if (this.geolocalisationTool.geolocalisationTool != TypeGeolocalisation.VIA_MICHELIN) {
                        //Pour Google Maps, on envoie toutes les infos dans le champ rue
                        geolocalisation.rue = geolocalisation.rue + " " + geolocalisation.codePostal + " " + geolocalisation.ville + " " + geolocalisation.pays
                    }

                    //Affichage de la popup
                    this.matDialog.open(LieuGeolocalisationListComponent,{
                        data: {
                            geolocalisation: geolocalisation,
                            adresse: this.adresse,
                            idUser:  this.isAdressePublique ? 0 : this.idUser,
                            idPortee: this.idPortee
                        },
                        minWidth: 600
                    }).afterClosed().subscribe({
                        next: data => {
                            //Vérification du résultat
                            if (data) {
                                this.onLocalisationSelected.emit(data.geolocalisation);
                            }
                        }
                    });
                } else {
                    //Toast pour notifier que le quota de la licence ViaMichelin est atteint
                    this.toastrService.error(this.translateService.instant('lieu.quotaGeolocAtteint'));
                }
            } else {
                const selectLocalisation = () => {
                    //Pas de géolocalisation => Création d'un lieu à partir des champs saisis
                    geolocalisation = {
                        ...this.localisation,
                        adresse: this.lieuService.getAdresseFromLocalisation(this.localisation)
                    };

                    //Sélection de l'adresse saisie
                    this.onLocalisationSelected.emit(geolocalisation);
                }

                //Dans le cas de la modification d'une adresse du profil ou de l'admin, on l'enregistre avant de continuer
                if ([TypePortee.PRF,TypePortee.ADM].includes(this.idPortee)) {
                    //Indicateur d'enregistrement en cours
                    this.isLoading = true;

                    //Création
                    if (!this.localisation || !this.localisation.id) {
                        //Initialisation de l'objet
                        this.localisation = Object.assign({},this.localisation, {
                                listeVisibilite: [this.idPortee == TypePortee.ADM ? Visibilite.FILTRE_V_ADM : Visibilite.FILTRE_V_PROFIL],
                                origine: this.idPortee == TypePortee.ADM ? Origine.ORIGINE_ADMIN : Origine.ORIGINE_USER
                            });
                    }

                    //Adresse publique/privée
                    this.localisation.idUser = this.isAdressePublique ? 0 : this.idUser;

                    //Enregistrement
                    this.lieuService.saveAdresse(this.localisation).pipe(first(),finalize(() => this.isLoading = false)).subscribe(res => {
                        //Mise à jour à partir du retour
                        this.localisation = {
                            id: res.geographie.id,
                            rue: res.geographie.entiteGeo?.libelle,
                            codePostal: res.geographie.entiteGeo?.codePostal,
                            ville: res.geographie.entiteGeo?.libelleVille,
                            pays: res.geographie.entiteGeo?.libellePays,
                            codePays: res.geographie.entiteGeo?.codePays2,
                            codePays3: res.geographie.entiteGeo?.codePays3
                        }

                        //Mise à jour de l'adresse d'origine à partir du retour
                        this.adresse = this.adresse ?? {};
                        this.adresse.rue = this.localisation.rue;
                        this.adresse.ville = this.localisation.ville;
                        this.adresse.codePostal = this.localisation.codePostal;
                        this.adresse.codePays = this.localisation.codePays3;
                        this.adresse.pays = this.localisation.pays;

                        //On continue avec l'adresse renseignée
                        selectLocalisation();
                    });
                } else {
                    //On continue avec l'adresse renseignée
                    selectLocalisation();
                }
            }
        } else {
            //Pas de modification, fermeture de la popup
            this.onLocalisationSelected.emit();
        }
    }

    /**
     * indique que l'adresse saisie a été modifiée
     */
    setAdresseModifiee(): void {
        this.isAdresseModifiee = true;

        //Si l'adresse n'a pas de rue, elle est forcément publique
        if (!this.localisation?.rue) {
            this.isAdressePublique = true;
        } else if (!this.isVisibiliteDefinie) {
            //Sinon, si elle n'a pas été forcée, elle est privée
            this.isAdressePublique = false;
        }
    }
}
