<div class="voyages-missions">
    <div class="content">
        <page-header #pageHeaderComponent [title]="'menu.admin.missions' | translate" (onSelectedItemChange)="onSelectedItemChange($event)" [listeItems]="listeTabItems">
        </page-header>

        <div *ngIf="isOdListLoaded" [hidden]="selectedItem?.code !== Tab.LISTEOD">
            <od-list remoteActions="true"></od-list>
        </div>
        <div *ngIf="isOmpListLoaded" [hidden]="selectedItem?.code !== Tab.LISTEOMP">
            <omp-list remoteActions="true"></omp-list>
        </div>
    </div>
</div>

