<div class="lvi-content">
    <div
        *ngIf="!isHistorique && !liste?.extraOptions?.isPopup"
        class="avatar"
        [class.success]="data.resultat === MaintenanceTaskResult.SUCCESS"
        [class.danger]="data.resultat === MaintenanceTaskResult.FAIL || data.resultat == null && !!data.date"
        [nSelector]="{ liste: liste, item: data }"
    >
        <i *ngIf="data.phase == MaintenanceTaskPhase.PRE_TASK" class="material-icons">keyboard_tab</i>
        <i *ngIf="!data.phase || data.phase == MaintenanceTaskPhase.POST_TASK" class="material-icons">start</i>
    </div>
    <div
        *ngIf="isHistorique || liste?.extraOptions?.isPopup"
        class="avatar"
        [class.success]="data.resultat === MaintenanceTaskResult.SUCCESS"
        [class.danger]="data.resultat === MaintenanceTaskResult.FAIL || data.resultat == null && !!data.date"
    >
        <i *ngIf="data.phase == MaintenanceTaskPhase.PRE_TASK" class="material-icons">keyboard_tab</i>
        <i *ngIf="!data.phase || data.phase == MaintenanceTaskPhase.POST_TASK" class="material-icons">start</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="openTask()"><span [matTooltip]="data.description" matTooltipPosition="right">{{ data.displayName }}</span></a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.maintenance.migrations.onglets.maintenance.typeTache.type'"></strong>
                <span>{{ ('admin.maintenance.migrations.onglets.maintenance.typeTache.' + (data.phase == MaintenanceTaskPhase.PRE_TASK ? 'preMigration' : 'postMigration')) | translate  }}</span>
            </li>
            <li *ngIf="data.date">
                <strong [translate]="'admin.maintenance.migrations.onglets.maintenance.filtres.date'"></strong>
                <span>{{ data.date | date: 'shortDate' }} {{ data.date | date: 'shortTime' }}</span>
            </li>
            <li *ngIf="!isHistorique">
                <strong [translate]="'admin.maintenance.migrations.onglets.maintenance.frequence.frequence'"></strong>
                <span>{{ ('admin.maintenance.migrations.onglets.maintenance.frequence.' + (data.frequence == MaintenanceTaskFrequence.ONCE ? 'once' : 'everyTime')) | translate }}</span>
            </li>
            <li *ngIf="data.version">
                <strong [translate]="'admin.maintenance.migrations.onglets.maintenance.version'"></strong>
                <span>{{ data.version }}</span>
            </li>
            <li *ngIf="data.build && data.build !== '00000'">
                <strong [translate]="'admin.maintenance.migrations.onglets.maintenance.build'"></strong>
                <span>{{ data.build }}</span>
            </li>
            <li [class.li-danger]="data.resultat === MaintenanceTaskResult.FAIL">
                <strong [translate]="'admin.maintenance.migrations.onglets.maintenance.statut.statut'"></strong>
                <!-- S'il y a un résultat d'exécution, on l'affiche. S'il n'y a pas de résultat mais qu'il y a une date d'exécution c'est que le serveur a planté pendant son exécution, donc ça sera marqué en erreur -->
                <span>{{ ('admin.maintenance.migrations.onglets.maintenance.statut.' + (data.resultat != null || data.date ? (data.resultat == MaintenanceTaskResult.SUCCESS ? 'succes' : 'echec') : 'aExecuter')) | translate  }}</span>
            </li>
        </ul>
    </div>
    <div *ngIf="!isHistorique && !liste?.extraOptions?.isPopup">
        <mat-icon *ngIf="!isMaintenanceEnCours && !isWorkflowEnCours" class="clickable primary material-symbols-rounded play-icon" [matTooltip]="'admin.maintenance.migrations.onglets.maintenance.lancerTache' | translate" matTooltipPosition="left" (click)="executeTask()">play_circle</mat-icon>
        <mat-icon *ngIf="isMaintenanceEnCours || isWorkflowEnCours" class="disabled-play-icon primary material-symbols-rounded play-icon">play_circle</mat-icon>
    </div>
</div>
<mat-progress-bar
        *ngIf="!isHistorique && taskStatut.isActive"
        [class.success-bar]="!taskStatut.isRunning && taskStatut.isSuccess === true"
        mode="buffer"
        [color]="!taskStatut.isRunning && taskStatut.isSuccess === false ? 'warn' : 'primary'"
        style="margin-top: 5px;"
        [value]="taskStatut?.progression"
        [bufferValue]="taskStatut.isRunning ? 0 : 100">
</mat-progress-bar>