<!-- Liste trajets -->
<div class="lvi-content" 
        [nioTooltip]="trajetTooltip"
        [nioTooltipPosition]="'track'"
        (click)="onSelect()">
    <!-- Avatar -->
    <div class="avatar">
        <i *ngIf="data.type === TrajetType.TRAJET_DISTANCE && !data.allerRetour" class="material-icons">trending_flat</i>
        <i *ngIf="data.type === TrajetType.TRAJET_DISTANCE && data.allerRetour" class="material-icons">swap_horiz</i>
        <i *ngIf="data.type === TrajetType.TRAJET_TOURNEE" class="material-icons">autorenew</i>
    </div>
    <!-- Body -->
    <div class="lvi-body">
        <div class="title">
            <a class="label" *ngIf="data.libelle" >{{ data.libelle }}</a>
            <a class="label" *ngIf="!data.libelle">{{ adressService.getVilleFromAdress(data.adresseDepart) }} / {{ adressService.getVilleFromAdress(data.adresseArrivee) }}
            </a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.distance">
                <strong [translate]="'ndf.trajet.distance'"></strong>
                <span>{{ data.distance | number }} {{ data.unite?.libelle != null ? data.unite.libelle : '' }}</span>
            </li>
            <li *ngIf="isAdminDistance()">
                <strong [translate]="'ndf.trajet.origine'"></strong>
                <span [translate]="'ndf.trajet.administrateur'"></span>
            </li>
            <li *ngIf="data.nombreEtape != null">
                <strong [translate]="'ndf.trajet.nombreEtape'"></strong>
                <span>{{ data.nombreEtape }}</span>
            </li>
        </ul>
    </div>
    <!-- Menu item -->
    <div class="lvi-actions"  >
        <button mat-icon-button [matMenuTriggerFor]="listeActions" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #listeActions="matMenu">
            <button mat-menu-item *ngIf="data.type === TrajetType.TRAJET_DISTANCE && !this.data.allerRetour" (click)="onActionAllerRetour()" translate>
                ndf.trajet.action.allerRetour
            </button>
            <button mat-menu-item *ngIf="data.type === TrajetType.TRAJET_DISTANCE && this.data.allerRetour" (click)="onActionAllerSimple()" translate>
                ndf.trajet.action.allerSimple
            </button>
            <button mat-menu-item *ngIf="data.type === TrajetType.TRAJET_DISTANCE && isAdminDistance()" (click)="onActionInverser()" translate>
                ndf.trajet.action.inverser
            </button>
            <button mat-menu-item *ngIf="data.type === TrajetType.TRAJET_TOURNEE || !isAdminDistance()" mat-menu-item (click)="onActionRenommer()" translate>
                ndf.trajet.action.renommer
            </button>
            <button mat-menu-item *ngIf="data.type === TrajetType.TRAJET_TOURNEE || !isAdminDistance()" mat-menu-item (click)="onActionSupprimer() " translate>
                ndf.trajet.action.supprimer
            </button>
        </mat-menu>
    </div>
</div>

<!-- Tooltip-->
<ng-template #trajetTooltip>
    <form autocomplete="off" class="nio-tooltip-details">
        <table>
            <tr>
                <td><label translate>ndf.trajet.type</label></td>
                <td *ngIf="data.type === TrajetType.TRAJET_DISTANCE">{{ (data.allerRetour ? 'ndf.trajet.allerRetour' : 'ndf.trajet.aller' ) | translate }}</td>
                <td *ngIf="data.type === TrajetType.TRAJET_TOURNEE" translate>ndf.frais.tournee</td>
            </tr>
            <tr *ngIf="data.adresseDepart">
                <td><label translate>ndf.trajet.depart</label></td>
                <td>{{ adressService.getVilleFromAdress(data.adresseDepart) }} {{ adressService.getPaysWithPays(data.adresseDepart) ? ' - ' + adressService.getPaysWithPays(data.adresseDepart) : ''  }}</td>
            </tr>
            <tr *ngIf="data.adresseArrivee">
                <td><label translate>ndf.trajet.arrivee</label></td>
                <td>{{ adressService.getVilleFromAdress(data.adresseArrivee) }} {{ adressService.getPaysWithPays(data.adresseArrivee) ? ' - ' + adressService.getPaysWithPays(data.adresseArrivee) : ''  }}</td>
            </tr>
        </table>
    </form>
</ng-template>