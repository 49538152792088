import {AutocompleteOptions} from "../autocomplete";
import {TranslateService} from "@ngx-translate/core";
import {UserPipeTransform} from "@share/pipe/user.pipe";
import {SearchType} from "@domain/common/list-view/sorting";
import {UserDto} from "@domain/user/user-dto";
import {Filter,TypeComparaison} from "@domain/common/list-view";
import {HttpParams} from "@angular/common/http";
import {ImportFactureCollabSearchItem} from "@share/component/autocomplete/options/import-facture-collab/search-item/import-facture-collab.search-item";
import {ImportFactureCollabLineItem} from "@share/component/autocomplete/options/import-facture-collab/line-item/import-facture-collab.line-item";
import {EntityAttribute} from "@share/component/autocomplete/options/entity-attribute";

/**
 * Options de l'autocomplete de recherche d'un collaborateur pour le traitement des rejets d'import de factures
 */
export default class ImportFactureCollabOptions implements AutocompleteOptions {
    /** Mode de recherche */
    searchType = SearchType.CONTAINS;

    /**
     * Affichage d'un élément
     */
    displayItem(item: UserDto): string {
        return UserPipeTransform(item);
    }

    /**
     * Composant d'affichage d'une ligne dans la popup de recherche
     */
    searchItemComponent() { return ImportFactureCollabSearchItem; }

    /**
     * Composant d'affichage d'une ligne de l'autocomplete
     */
    lineItemComponent() { return ImportFactureCollabLineItem; }

    /**
     * Filtres disponibles
     */
    listeFilters(translateService: TranslateService): Array<Filter> {
        //Retour de la liste des filtres
        return [{
            title: translateService.instant('filter.prenom'),
            clef: 'prenom',
            typeComparaison: TypeComparaison[TypeComparaison.LIKE],
            isDefault: true
        }, {
            title: translateService.instant('filter.nom'),
            clef: 'nom',
            typeComparaison: TypeComparaison[TypeComparaison.LIKE],
            isDefault: true
        }, {
            title: translateService.instant('filter.matricule'),
            clef: 'matricule',
            typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
            isDefault: true
        }, {
            title: translateService.instant('filter.societe'),
            clef: 'orga.libelle',
            typeComparaison: TypeComparaison[TypeComparaison.LIKE],
            isDefault: false
        }];
    }

    /**
     * Liste les propriétés de l'objet pour lesquelles il faut afficher un badge 'clé:valeur'
     */
    listDisplayedAttributes(item: UserDto): Array<EntityAttribute> {
        const listeAttributs: Array<EntityAttribute> = [];

        listeAttributs.push({
            //Service du collaborateur
            clef: 'analytique.service',
            valeur: `${item.orga?.numService} - ${item.orga?.libelle}`
        });

        return listeAttributs;
    }

    /**
     * Récupération de l'URL
     */
    url(filter?: {idOd: number}): string {
        //Construction des paramètres suivant le filter
        let params = new HttpParams();

        if (filter?.idOd) {
            params = params.append("idOd",filter.idOd.toString(10));
        }

        return `/controller/ImportFacture/searchCollab?` + params.toString();
    }

}
