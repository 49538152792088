<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'warning': data.listeAlertes?.niveau == 1,'danger': data.listeAlertes?.niveau == 2 }"
         [nSelector]="{liste: liste, item: data, isNotSelectable: fonction === TypeProfil.ADMINISTRATEUR}">
        <i class="nio icon-facture" style="vertical-align: middle;"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="navigateTo()">{{ data.getLibelleObjet(translateService) }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'facture.liste.fournisseur'"></strong>{{ data.factureReleve?.fournisseur?.raisonSociale }}</li>
            <li><strong [translate]="'facture.liste.date'"></strong>{{ data.date | date:'shortDate' }}</li>
            <li><strong [translate]="'facture.liste.type'"></strong>{{ ('facture.liste.type' + data.type) | translate }}</li>
            <li><strong [translate]="'facture.liste.reconciliation'"></strong>
                <ng-container *ngIf="data.factureReleve?.typeReconciliation == TypeReconciliation.TYPE_RECONCILIATION_COLLAB">{{ data.getCollaborateur() }}</ng-container>
                <ng-container *ngIf="data.factureReleve?.typeReconciliation == TypeReconciliation.TYPE_RECONCILIATION_OM && data.idOd > 0">
                    <span (click)="openOd(data.idOd)" class="clickable">
                        {{ 'facture.liste.om' | translate:{'idOd':data.idOd} }}&nbsp;<mat-icon color="primary" style="font-size:1.1em;">open_in_new</mat-icon>
                    </span>
                </ng-container>
            </li>
            <li *ngIf="!isCollabMode && data.factureReleve?.typeReconciliation == TypeReconciliation.TYPE_RECONCILIATION_OM"><strong [translate]="'facture.liste.collaborateur'"></strong>{{ data.getCollaborateur() }}</li>
            <li *ngIf="!isCollabMode && data.getRolesCount() > 1" [matTooltip]="data.getRoles()"><strong [translate]="'facture.liste.roles'"></strong>{{ data.getRolesCount() }}</li>
            <li *ngIf="!isCollabMode && data.getRolesCount() == 1"><strong [translate]="'facture.liste.role'"></strong>{{ data.getRoles() }}</li>
            <li *ngIf="data.contentieux?.litige || data.contentieux?.litigeLeve" [ngClass]="{'back-success' : data.contentieux.litigeLeve, 'back-danger' : data.contentieux.litige}">
                <strong [translate]="'facture.liste.litige'"></strong>
                {{ 'facture.liste.columns.contentieux.litige.' + (data.contentieux.litige ? 'ouvert' : 'ferme') | translate }}
            </li>
            <li *ngIf="data.listeAlertes" (click)="showListeAlertes()" class="clickable"
                [ngClass]="{'back-danger' : data.listeAlertes.niveau == 2, 'back-warning' : data.listeAlertes.niveau == 1}">
                <strong [translate]="'workflow.alerte.title'"></strong><span>{{ ('workflow.alerte.niveau.' + data.listeAlertes.niveau | translate) }}</span>
            </li>
        </ul>
    </div>
    <div class="info">
        <div>
            <statut [statut]="data.statut"></statut>
            <div class="text-right text-secondary font-weight-bold">
                {{ data.montant | montant:data.devise }}
            </div>
        </div>
    </div>
</div>