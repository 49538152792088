import {HttpClient,HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable,of} from 'rxjs';
import {first,map} from 'rxjs/operators';
import {TranslateService} from "@ngx-translate/core";

import {Result} from '@domain/common/http/result';
import {environment} from '@environments/environment';
import {Transport,TypeTransport} from "@domain/omp/transport";
import {OmZone} from "@domain/omp/omZone";
import {TypeOmZone} from "@domain/omp/typeOmZone";
import {Omp} from "@domain/omp/omp";
import {Router} from "@angular/router";
import {IObjetWorkflowService} from '../workflow/objet-workflow.service';
import {TypeEntiteParamOT} from "@domain/typeentite/typeEntiteParam";
import {TypePortee} from "@domain/workflow/workflow";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import * as typeEntiteActions from "@reducers/type-entite";
import {User} from "@domain/user/user";

@Injectable()
export class OMPService implements IObjetWorkflowService<Omp> {
    /**
     * Contructeur
     */
    constructor(private http: HttpClient,
                private translateService: TranslateService,
                private router: Router,
                private store: Store<AppState>) {
    }

    /**
     * Navigue vers la page d'une mission permanente
     *
     * @param idOMPermanent Identifiant de la mission permanente
     */
    navigateToOMP(idOMPermanent: number) {
        this.router.navigate(['OMP',idOMPermanent]);
    }

    /**
     * Chargement d'une mission permanente
     * @param idOMPermanent Identifiant de la mission permanente
     */
    load(idOMPermanent: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/OMPermanent/loadOMP/${idOMPermanent}`,null);
    }

    /**
     * Création d'une mission permanente.
     *
     * @param idTypeEntite Identifiant du type entité
     * @param idCollab id du collab pour lequel on crée l'OMP (cas d'un assistant/responsable/comptable)
     */
    create(idTypeEntite: number,idCollab?: number): Observable<number> {
        //Ajout des paramètres
        let params: HttpParams = new HttpParams();
        params = params.append('idTypeEntite',idTypeEntite.toString());

        if (idCollab) {
            params = params.append('idCollab', idCollab.toString());
        }

        //Envoi de la requête HTTP de création
        return this.http.put<Result>(`${environment.baseUrl}/controller/OMPermanent/createOMP`,null,{
            params: params
        }).pipe(
            first(),
            map(result => result?.data?.idOMPermanent)
        );
    }

    /**
     * Enregistrement de la mission permanente
     * @param omp Mission permanente à enregistrer
     */
    save(omp: Omp): Observable<Result> {
        //Enregistrement de la mission permanente
        return this.http.put<Result>(`${environment.baseUrl}/controller/OMPermanent/saveOMP`,omp);
    }

    /**
     * Retourne l'identifiant de l'exercice coresspondant à une date donnée
     *
     * @param date La date au format YYYY-MM-DD
     */
    getIdExerciceForDate(date:string): Observable<number> {
        //Ajout des paramètres
        let params: HttpParams = new HttpParams()
            .append('date',date);

        return this.http.get<number>(`${environment.baseUrl}/controller/Exercice/getIdExerciceForDate`,{
            params: params
        }).pipe(first());
    }

    /**
     * Retourne la traduction d'un type de transport
     *
     * @param typeTransport Type de transport
     */
    translateTypeTransport(typeTransport: TypeTransport): string {
        let typeSuffix;

        switch (typeTransport) {
            //Véhicule Personnel
            case TypeTransport.TYPE_PERSONNEL:
                typeSuffix = 'personnel';
                break;
            //Véhicule Administratif
            case TypeTransport.TYPE_ADMINISTRATIF:
                typeSuffix = 'administratif';
                break;
            //Avion
            case TypeTransport.TYPE_AVION:
                typeSuffix = 'avion';
                break;
            //Train
            case TypeTransport.TYPE_TRAIN:
                typeSuffix = 'train';
                break;
            //Autre
            case TypeTransport.TYPE_AUTRE:
                typeSuffix = 'autre';
                break;
            default:
                break;
        }

        //Retour de la traduction
        return this.translateService.instant('omp.transports.type.' + typeSuffix);
    }

    /**
     * Retourne la valeur affichée dans l'avatar de la liste des transports autorisés
     *
     * @param typeTransport Type de transport
     */
    getAvatarForTypeTransport(typeTransport: TypeTransport): string {
        switch (typeTransport) {
            //Véhicule personnel
            case TypeTransport.TYPE_PERSONNEL:
                return 'VEP';
            //Véhicule administratif
            case TypeTransport.TYPE_ADMINISTRATIF:
                return 'VEA';
            //Avion
            case TypeTransport.TYPE_AVION:
                return 'AVI';
            //Train
            case TypeTransport.TYPE_TRAIN:
                return 'TRA';
            //Autre
            case TypeTransport.TYPE_AUTRE:
                return 'AUT';
            default:
                return '';
        }
    }

    /**
     * Enregistrement d'un transport
     *
     * @param transport Transport à enregistrer
     */
    saveTransport(transport: Transport): Observable<Result> {
        //Enregistrement du transport
        return this.http.put<Result>(`${environment.baseUrl}/controller/OMPermanent/${transport.idOmPermanent}/saveTransport`,transport)
            .pipe(first());
    }

    /**
     * Suppression d'un transport
     *
     * @param transport Transport à supprimer
     */
    deleteTransport(transport: Transport): Observable<Result> {
        //Suppression du transport
        return this.http.delete<Result>(`${environment.baseUrl}/controller/OMPermanent/${transport.idOmPermanent}/deleteTransport/${transport.idTransport}`)
            .pipe(first());
    }

    /**
     * Retourne la valeur affichée dans l'avatar d'une zone de déplacement
     *
     * @param typeZone Type de zone
     */
    getAvatarForTypeZone(typeZone: TypeOmZone): string {
        switch (typeZone) {
            //Zone
            case TypeOmZone.TYPE_ZONE:
                return 'ZON';
            //Pays
            case TypeOmZone.TYPE_PAYS:
                return 'PAY';
            //Territoire
            case TypeOmZone.TYPE_TERRITOIRE:
                return 'TER';
            //Région
            case TypeOmZone.TYPE_REGION:
                return 'REG';
            //Département
            case TypeOmZone.TYPE_DEPARTEMENT:
                return 'DEP';
            //Ville
            case TypeOmZone.TYPE_VILLE:
                return 'VIL';
            default:
                return '';
        }
    }

    /**
     * Retourne la traduction d'un type de zone
     *
     * @param typeZone Type de zone
     */
    translateTypeZone(typeZone: TypeOmZone): string {
        let typeSuffix;

        switch (typeZone) {
            //Zone
            case TypeOmZone.TYPE_ZONE:
                typeSuffix = 'zone';
                break;
            //Pays
            case TypeOmZone.TYPE_PAYS:
                typeSuffix = 'pays';
                break;
            //Territoire
            case TypeOmZone.TYPE_TERRITOIRE:
                typeSuffix = 'territoire';
                break;
            //Région
            case TypeOmZone.TYPE_REGION:
                typeSuffix = 'region';
                break;
            //Département
            case TypeOmZone.TYPE_DEPARTEMENT:
                typeSuffix = 'departement';
                break;
            //Ville
            case TypeOmZone.TYPE_VILLE:
                typeSuffix = 'ville';
                break;
            default:
                break;
        }

        //Retour de la traduction
        return this.translateService.instant('omp.zone.type.' + typeSuffix);
    }

    /**
     * Enregistrement d'une zone de déplacement
     *
     * @param zone Zone de déplacement à enregistrer
     */
    saveZone(zone: OmZone): Observable<Result> {
        //Enregistrement de la zone de déplacement
        return this.http.put<Result>(`${environment.baseUrl}/controller/OMPermanent/${zone.idOmPermanent}/saveZone`,zone)
            .pipe(first());
    }

    /**
     * Suppression d'une zone de déplacement
     *
     * @param zone Zone de déplacement à supprimer
     */
    deleteZone(zone: OmZone): Observable<Result> {
        //Suppression de la zone
        return this.http.delete<Result>(`${environment.baseUrl}/controller/OMPermanent/${zone.idOmPermanent}/deleteZone/${zone.idOmZone}`)
            .pipe(first());
    }

    /**
     * Création d'un OD rattaché à l'OMP
     *
     * @param omp Mission permanente
     */
    createOD(omp: Omp): Observable<number> {
        //Ajout des paramètres
        let params: HttpParams = new HttpParams()
            .append('idOmPermanent',omp.idOMPermanent.toString());

        //Envoi de la requête HTTP de création
        return this.http.put<Result>(`${environment.baseUrl}/controller/OMPermanent/createOD`,null,{
            params: params
        }).pipe(
            first(),
            map(result => result?.data?.idOd)
        );
    }

    /**
     * Création d'une note de frais rattachée à l'OMP
     *
     * @param omp Mission permanente
     */
    createNDF(omp: Omp): Observable<number> {
        //Ajout des paramètres
        let params: HttpParams = new HttpParams()
            .append('idOmPermanent',omp.idOMPermanent.toString());

        //Envoi de la requête HTTP de création
        return this.http.put<Result>(`${environment.baseUrl}/controller/OMPermanent/createNDF`,null,{
            params: params
        }).pipe(
            first(),
            map(result => result?.data?.idNdf)
        );
    }

    /**
     * Transforme une heure au format xxHxx vers le format xx:xx
     *
     * @param time Chaîne de caractère représentant une heure
     */
    normalizeTime(time: string): string {
        //Vérification du format attendu
        if (time && time.indexOf('H') == 2) {
            //Transformation au format défini
            return time.substr(0,2) + ":" + time.substr(3);
        } else {
            //Sinon retour de la valeur initiale
            return time;
        }
    }
    
    /**
     * Vérifie si le paramétrage du type entité autorise la création d'un OD / une NDF sur un OMP pour un utilisateur
     *
     * @param typeEntiteParamOT Paramétrage du type entité pour la portée OT
     * @param porteeObjetCreation Portée du type d'objet à créer et lier sur l'OD (OD / NF)
     */
    isUserCanCreateForTypeEntite(typeEntiteParamOT: TypeEntiteParamOT,porteeObjetCreation: TypePortee.OD | TypePortee.NF): Observable<boolean> {
        //Vérification si le paramétrage du type entité pour la portée OT autorise ou non la création d'un objet pour la portée passée en paramètre
        if ((porteeObjetCreation == TypePortee.OD && !typeEntiteParamOT.creationOM) || (porteeObjetCreation == TypePortee.NF && !typeEntiteParamOT.creationNDFOT)) {
            //Pas autorisé
            return of(false);
        }
    
        //Chargement du type entité et du paramétrage associé à la portée de l'objet
        this.store.dispatch({
            type: typeEntiteActions.FIND_TYPE_ENTITE,
            payload: {
                idTypeEntite: typeEntiteParamOT.idTypeEntite,
                portee: porteeObjetCreation
            }
        });
    
        //Récupération de l'utilisateur connecté
        return this.store.select(state => state.session.user)
            .pipe(first())
            .mergeMap(user => {
                //Récupération du type entité
                return this.store.select(state => state.typeEntite[porteeObjetCreation])
                    //On attend que l'objet dans le store soit bien pour l'identifiant du type entité en cours (le retour du dispatch n'est potentiellement pas terminé et pour autant on a déjà une valeur dans le store correspondent à un autre type entité :/)
                    .pipe(first(val => val?.idTypeEntite === typeEntiteParamOT.idTypeEntite))
                    .mergeMap(typeEntite => {
                        //Vérification de la restriction des rôles pour la portée de l'objet à créer
                        return of(this.checkRoles(typeEntite.typeEntiteParam.listeRole,user));
                    });
            });
        
    }
    
    //Fonction de vérification de la restriction de rôle configurée sur le type entité par rapport aux rôles de l'utilisateur
    private checkRoles(listeRoleTypeEntite: Array<number>,user: User): boolean {
        //Constitution de la liste des identifiants des rôles de l'utilisateur
        const listeIdsRoleUser: Array<number> = user?.roles?.roles?.map(role => role.idRole) ?? [];
        
        return !listeRoleTypeEntite || listeRoleTypeEntite.length === 0 //Pas de restriction définie
            || listeRoleTypeEntite.some(idRole => listeIdsRoleUser.includes(idRole)); //L'utilisateur possède au moins l'un des rôles restreints par le type entité
    }

}
