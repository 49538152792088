import {Component,OnDestroy,OnInit} from "@angular/core";
import {TaskItemComponent} from "@components/admin/maintenance/migrations/maintenance/task-item.component";
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {TranslateService} from "@ngx-translate/core";
import {Sorting} from "@domain/common/list-view/sorting";
import {Page} from "@domain/common/http/list-result";
import {MaintenanceTask,MaintenanceTaskResult} from "@domain/admin/maintenance/maintenance-task";
import {MigrationsService} from "@services/admin/maintenance/migrations.service";
import {BehaviorSubject,Subscription} from "rxjs";
import {FloatingButtonAction, TypeAction} from "@share/component/floating-button/floating-button";
import {StatutWorkflowService} from "@services/admin/statut-workflow/statut-workflow.service";
import {ToastrService} from "ngx-toastr";
import {KeyStatutWorkflow} from "@domain/admin/statut-application/statut-application";
import {ActivatedRoute} from "@angular/router";
import {ListViewService} from "@share/component/list-view/list-view.service";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {Session} from "@domain/security/session";
import {ListeAlertes} from "@domain/common/alerte/listeAlertes";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";

/**
 * Onglet maintenance
 *
 * @author Guillaume TRAVOT
 * @date 01/02/2024
 */
@Component({
    host: {'data-test-id': 'maintenance'},
    templateUrl: './maintenance.component.html'
})
export class MaintenanceComponent implements OnInit,OnDestroy {
    /** Liste des tâches, pour utiliser le composant ListViewComponent */
    liste: ListView<MaintenanceTask, TaskItemComponent>;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Maintenance en cours */
    isMaintenanceEnCours: boolean = false;

    /** Workflow en cours */
    workflowEnCours: boolean = false;

    /** Souscriptions */
    souscriptions: Subscription[] = [];

    /**
     * Constructeur
     */
    constructor(
        protected translateService: TranslateService,
        private statutWorkflowService: StatutWorkflowService,
        private toastrService: ToastrService,
        private activatedRoute: ActivatedRoute,
        private migrationsService: MigrationsService,
        private listViewService: ListViewService<MaintenanceTask, TaskItemComponent>,
        private store: Store<AppState>
    ) { }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Sélection de la session
        this.store.select<Session>(s => s.session).subscribe(session => {
            //Création de la liste des tâches
            this.liste = new ListView<MaintenanceTask, TaskItemComponent>({
                uri: `/controller/Maintenance/listeTask`,
                title: this.translateService.instant('admin.maintenance.migrations.onglets.maintenance.listeTitle'),
                component: TaskItemComponent,
                mapResult: (result: Page<MaintenanceTask>): Page<MaintenanceTask> => {
                    //Transformation en instance de chaque item
                    result.listeResultats = result.listeResultats.map(t => new MaintenanceTask(t));

                    //Si aucune tâche n'est en échec
                    if (!result.listeResultats.some(task => task.resultat == undefined || task.resultat == MaintenanceTaskResult.FAIL)) {
                        //On crée une alerte indiquant que toutes les tâches de maintenance sont en succès
                        const listeAlertes = new ListeAlertes();
                        listeAlertes.niveau = NiveauAlerte.SUCCESS;
                        const alerte = new Alerte();
                        alerte.niveau = NiveauAlerte.SUCCESS;
                        alerte.message = this.translateService.instant("admin.maintenance.migrations.onglets.maintenance.tachesOK");
                        listeAlertes.listeAlertes = [alerte];

                        this.migrationsService.listeAlertes = listeAlertes;
                    }

                    //Retour
                    return result;
                },
                isFilter: true,
                removeFiltreOnceIfEmpty: true,
                nbObjetsParPage: 999999,
                defaultOrder: 'taskName',
                listeFilters: [
                    {
                        clef: 'taskName',
                        title: this.translateService.instant('admin.maintenance.migrations.onglets.maintenance.filtres.name'),
                        isDefault: true,
                        typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                    },{
                        clef: 'type',
                        title: this.translateService.instant('admin.maintenance.migrations.onglets.maintenance.typeTache.type'),
                        isDefault: false,
                        typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                        listeValues: [{
                            code: 'PRE_TASK',
                            libelle: this.translateService.instant('admin.maintenance.migrations.onglets.maintenance.typeTache.preMigration')
                        },{
                            code: 'POST_TASK',
                            libelle: this.translateService.instant('admin.maintenance.migrations.onglets.maintenance.typeTache.postMigration')
                        }]
                    },{
                        clef: 'frequence',
                        title: this.translateService.instant('admin.maintenance.migrations.onglets.maintenance.frequence.frequence'),
                        isDefault: false,
                        typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                        listeValues: [{
                            code: 'ONCE',
                            libelle: this.translateService.instant('admin.maintenance.migrations.onglets.maintenance.frequence.once')
                        },{
                            code: 'EVERY_TIME',
                            libelle: this.translateService.instant('admin.maintenance.migrations.onglets.maintenance.frequence.everyTime')
                        }]
                    },{
                        clef: 'version',
                        title: this.translateService.instant('admin.maintenance.migrations.onglets.maintenance.filtres.version'),
                        isDefault: false,
                        typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                    },{
                        clef: 'build',
                        title: this.translateService.instant('admin.maintenance.migrations.onglets.maintenance.filtres.build'),
                        isDefault: false,
                        typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                    },{
                        clef: 'date',
                        title: this.translateService.instant('admin.maintenance.migrations.onglets.maintenance.filtres.date'),
                        isDefault: false,
                        type: TypeFilter[TypeFilter.DATE]
                    },{
                        clef: 'resultat',
                        title: this.translateService.instant('admin.maintenance.migrations.onglets.maintenance.filtres.statut'),
                        isDefault: false,
                        typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                        listeValues: [{
                            code: '#aExecuter',
                            libelle: this.translateService.instant('admin.maintenance.migrations.onglets.maintenance.statut.aExecuter')
                        },{
                            code: '1',
                            libelle: this.translateService.instant('admin.maintenance.migrations.onglets.maintenance.statut.echec')
                        },{
                            code: '0',
                            libelle: this.translateService.instant('admin.maintenance.migrations.onglets.maintenance.statut.succes')
                        }]
                    }
                ]
            });

            //Filtre par défaut de la liste
            this.liste.listeSelectedFilters = [{
                clef: 'resultat',
                title: this.translateService.instant('admin.maintenance.migrations.onglets.maintenance.filtres.statut'),
                isDefault: false,
                typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                valeur: '#aExecuter',
                displayedValeur: this.translateService.instant('admin.maintenance.migrations.onglets.maintenance.statut.aExecuter')
            }];

            //Définition des colonnes de tri
            this.liste.columns = [
                { key: 'taskName', title: 'admin.maintenance.migrations.onglets.maintenance.filtres.name' },
                { key: 'type', title: 'admin.maintenance.migrations.onglets.maintenance.typeTache.type' },
                { key: 'frequence', title: 'admin.maintenance.migrations.onglets.maintenance.frequence.frequence' },
                { key: 'version', title: 'admin.maintenance.migrations.onglets.maintenance.filtres.version' },
                { key: 'build', title: 'admin.maintenance.migrations.onglets.maintenance.filtres.build' },
                { key: 'date', title: 'admin.maintenance.migrations.onglets.maintenance.filtres.date' },
                { key: 'resultat', title: 'admin.maintenance.migrations.onglets.maintenance.filtres.statut' }
            ];

            //Ajout du tri de la liste selon l'ordre voulu
            this.liste.sorting = new Sorting(this.liste.columns, "taskName");

            //Nom de la classe
            this.liste.className = 'MaintenanceComponent';

            //Liste sans persistence
            this.liste.isPersistFilters = false;

            //Si le forçage du filtre est demandé
            if (this.activatedRoute.snapshot.queryParams['forceAExecuter']) {
                //Purge des clés du Local Storage pour la liste
                this.listViewService.resetListeParams(this.liste,session.user.fonction);
            }

            //Abonnement à la même route avec le forçage du filtre demandé
            this.souscriptions.push(this.activatedRoute.queryParams.subscribe(queryParams => {
                //Si le forçage du filtre est demandé
                if (queryParams['forceAExecuter']) {
                    //Purge des clés du Local Storage pour la liste
                    this.listViewService.resetListeParams(this.liste,session.user.fonction);

                    //Forçage du filtre par défaut
                    this.liste.listeSelectedFilters = [{
                        clef: 'resultat',
                        title: this.translateService.instant('admin.maintenance.migrations.onglets.maintenance.filtres.statut'),
                        isDefault: false,
                        typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                        valeur: '#aExecuter',
                        displayedValeur: this.translateService.instant('admin.maintenance.migrations.onglets.maintenance.statut.aExecuter')
                    }];

                    //Rechargement de la liste
                    this.liste.refresh();
                }
            }));

            //Définition de la liste des actions possibles
            this.listeActions.next([{
                type: TypeAction.PRIMARY,
                icone: 'material-icons',
                iconeName: 'play_circle',
                libelle: 'admin.maintenance.migrations.onglets.maintenance.lancerTaches',
                doAction: () => this.executeTasks(),
                isVisible: () => !this.isMaintenanceEnCours && !this.workflowEnCours && this.liste?.data?.listeResultats?.some(i => i['isSelected'])
            }]);

            //Demande de vérification du statut détaillé de l'application
            this.migrationsService.checkAppliStatut();

            //Abonnement à la mise à jour du statut du Workflow
            this.souscriptions.push(this.statutWorkflowService.synchroWFStatut$.subscribe(statut => {
                //Mise à jour du statut Workflow
                this.workflowEnCours = statut?.statut != KeyStatutWorkflow.HISTO_WF_NONE;
            }));

            //Abonnement à la mise à jour de la maintenance en cours
            this.souscriptions.push(this.migrationsService.isMaintenanceEnCours$.subscribe(isMaintenanceEnCours => {
                //Mise à jour de la maintenance en cours
                this.isMaintenanceEnCours = isMaintenanceEnCours;
            }));
        });
    }

    /**
     * Exécution des tâches sélectionnées
     */
    executeTasks() {
        //Exécution des tâches sélectionnées
        this.migrationsService.executeTasks(this.liste.data.listeResultats.filter(i => i['isSelected'])).subscribe(result => {
                //Rafraichissement du statut de l'application
                this.migrationsService.checkAppliStatut();
            }, error => {
                //Message d'erreur
                this.toastrService.error(this.translateService.instant('global.errors.chargement'));
            }
        );
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy(): void {
        //Désabonnements
        this.souscriptions.forEach(souscription => souscription.unsubscribe());
    }
}