import {Component,EventEmitter,Input,OnDestroy,OnInit,Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject,Observable,Subscription} from 'rxjs';

import {ListView,TypeFilter} from '@domain/common/list-view';
import {SettingsNFState} from '@domain/settings/settings';
import {NDFFraisAddComponent} from '../ndf-frais-add/ndf-frais-add.component';
import {NDFFraisListItemComponent} from './ndf-frais-list-item/ndf-frais-list-item.component';
import {ListeDepenseFrais} from "@domain/ndf/liste-depense-frais";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {NDFService} from "@components/ndf/ndf.service";
import {filter,finalize,first} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {Ndf} from "@domain/ndf/ndf";

@Component({
    selector: 'ndf-frais-list',
    templateUrl: './ndf-frais-list.component.html'
})
export class NDFFraisListComponent implements OnInit, OnDestroy {
    /** Note de frais */
    @Input() ndf: Ndf;

    /** Paramètres */
    @Input() settings: SettingsNFState;

    /** Indicateur de modification */
    @Input() canModifier: boolean;   

    /** Lance l'output de mise à jour de alerte frais */
    @Output() checkFraisAlerte = new EventEmitter<string>();

    /** Données */
    liste: ListView<ListeDepenseFrais,NDFFraisListItemComponent>;

    /** Observable qui indique que la liste des frais doit être actualisée */
    @Input() fraisRefreshObservable: Observable<void>;

    /** Liste des souscriptions */
    private listeSouscriptions: Array<Subscription> = new Array<Subscription>();

    /**
     * Constructeur
     */
    constructor(private translateService: TranslateService,
                private matDialog: MatDialog,
                private confirmService: ConfirmService,
                private ndfService: NDFService,
                private toastrService: ToastrService,
                private pleaseWaitService: PleaseWaitService) {
    }

    /**
     * Initialisation
     */
    ngOnInit() {
        //Définition de la liste
        this.liste = new ListView<ListeDepenseFrais,NDFFraisListItemComponent>({
            uri: `/controller/NDF/filtreDepenses/${this.ndf.idNDF}`,
            title: this.translateService.instant('ndf.frais.title'),
            component: NDFFraisListItemComponent,
            defaultOrder: '-date,prestation.libelle',
            listeActionsSpecifiques: new BehaviorSubject<Array<FloatingButtonAction>>([
                {
                    type: TypeAction.SECONDARY,
                    icone: 'nio icon-suppression',
                    libelle: 'global.actions.supprimer',
                    doAction: () => this.supprimerFraisMultiple(),
                    isVisible: () => this.canModifier,
                },{
                    type: TypeAction.SECONDARY,
                    icone: 'nio icon-tout_selectionner',
                    libelle: 'liste.actions.selectionnerElementsAffiches',
                    doAction: () => this.liste.selectAll(true),
                },{
                    type: TypeAction.SECONDARY,
                    icone: 'nio icon-annuler_selection',
                    libelle: 'liste.actions.toutDeselectionner',
                    doAction: () => this.liste.selectAll(false)
                }]
            ),
            listeFilters: [{
                clef: 'prestation.libelle',
                title: this.translateService.instant('ndf.frais.prestation'),
                isDefault: true
            },{
                clef: 'date',
                type: TypeFilter[TypeFilter.DATE],
                typeComparaison: 'EQUAL',
                title: this.translateService.instant('ndf.frais.date')
            },{
                clef: 'montant',
                type: TypeFilter[TypeFilter.DECIMAL],
                typeComparaison: 'EQUAL',
                title: this.translateService.instant('ndf.frais.montant')
            }],
            listeActions: [{
                icon: 'add',
                isVisible: () => this.canModifier,
                onPress: () => this.showFrais()
            }],
            extraOptions: {
                canModifier: this.canModifier,
                showFrais: this.showFrais.bind(this),
                settings: this.settings,
                codeDeviseNdf: this.ndf.devise.code
            }
        });

        this.listeSouscriptions.push(
            this.fraisRefreshObservable.subscribe(() => {
                //On refresh la liste si on a l'évent correspondant
                this.liste.refresh();
            })
        );
    }

    /**
     * Suppression en masse des frais et/ou des dépenses sélectionnées
     */
    supprimerFraisMultiple(): void {
        //S'il y a bien des éléments sélectionnés
        if (this.liste.nbSelectedItems > 0) {
            //Message de confirmation
            this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
                next: () => {
                    var waitDialogRef = this.pleaseWaitService.show();

                    //On envoie la demande de suppression
                    this.ndfService.supprimerFraisMasse(this.ndf.idNDF,this.liste.listeObjetsSectionnes)
                        .pipe(first(),finalize(() => { waitDialogRef.close(); }))
                        .subscribe(result => {
                            //S'il n'y a pas d'erreur
                           if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                               //Mise à jour des montants de la NDF
                               this.ndf.montantDepenses = result.data.montantDepense;
                               this.ndf.montantRemboursable = result.data.montantRemboursable;
                               this.ndf.montantARembourser = result.data.montantARembourser;

                                //Refresh de la liste
                                this.liste.refresh();

                                //Emission de l'alerte pour forcer la mise à jour de l'alerte de frais
                                this.checkFraisAlerte.emit('global.success.suppression');
                            } else {
                                //Message d'erreur
                                this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                            }
                        })
                }
            });
        }
    }

    /**
     * Affichage/création d'un frais
     */
    showFrais(frais?: any) {
        //Affichage de la popup
        this.matDialog.open(NDFFraisAddComponent,{
            data: {
                frais: Object.assign({
                    idDepense: 0,
                    justificatif: {},
                    numIndemnite: 0,
                    operation: {
                        devise: this.settings.listeDevises?.[0]
                    }
                },frais),
                ndf: this.ndf,
                settings: this.settings,
                canModifier: this.canModifier
            },
            minWidth: 1000
        }).afterClosed().subscribe({
            next: (dialogResult: {refresh: boolean, refreshAlertsAndSyncWF?: boolean, message?: string}) => {
                //Vérification du résultat
                if (dialogResult?.refresh) {
                    //Rafraichissement de la liste
                    this.liste.refresh();

                    //Rafraichissement des alertes sauf si explicitement demandé d'ignorer
                    if (dialogResult.refreshAlertsAndSyncWF !== false) {
                        //Emission de l'alerte pour forcer la mise à jour de l'alerte de frais
                        this.checkFraisAlerte.emit(dialogResult?.message);
                    }
                }
            }
        })
    }

    /**
     * Destruction du composant
     */
     ngOnDestroy() {
        //Suppression des souscriptions
        this.listeSouscriptions.forEach(s => s.unsubscribe());
    }
}
