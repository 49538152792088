<h1 mat-dialog-title>
    <span *ngIf="isChoixApprobateur && isMail" [translate]="'workflow.notification.title.approbateursDestinataires'"></span>
    <span *ngIf="isChoixApprobateur && !isMail" [translate]="'workflow.notification.title.approbateurs'"></span>
    <span *ngIf="!isChoixApprobateur && isMail" [translate]="'workflow.notification.title.destinataires'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form autocomplete="off" #notificationForm="ngForm">
        <div *ngIf="!isLoading">
            <fieldset>
                <mat-tab-group #tabGroup dynamicHeight="dynamicHeight" animationDuration="500ms">
                    <mat-tab *ngIf="isChoixApprobateur" [label]="'workflow.notification.approbateursTitle' | translate">
                        <mat-accordion [displayMode]="'flat'" [multi]="true">
                            <mat-expansion-panel *ngFor="let mail of listeApprobateurs; let index = index;" [expanded]="true">
                                <mat-expansion-panel-header *ngIf="listeApprobateurs.length > 1">
                                    <mat-panel-title>{{ ('portee.' + mail.typeObjet | translate) + ' ' + mail.idObjet }}</mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="listview local">
                                    <div class="listview-body">
                                        <div (click)="onApprobateurChange(user,mail)" *ngFor="let user of mail.approbateurs" class="listview-item clickable">
                                            <div class="lvi-content">
                                                <div class="avatar">
                                                    <mat-icon>{{ user == selectedApprobateur ? 'done' : 'person'}}</mat-icon>
                                                </div>
                                                <div class="lvi-body">
                                                    <div class="title">
                                                        <span>{{ user | user }}</span>
                                                    </div>
                                                    <ul class="lvi-attrs">
                                                        <li><strong [translate]="'workflow.notification.priorite.title'"></strong><span
                                                                [translate]="'workflow.notification.priorite.'+user.prioriteKey"></span></li>
                                                        <li><strong [translate]="'workflow.notification.role'"></strong><span>{{ user.libelleRole }}</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="mail.approbateurs.length == 0" class="alert alert-danger">
                                            <h4 [translate]="'workflow.notification.alerte.title.warning'" class="alert-heading"></h4>
                                            <p [translate]="'workflow.notification.alerte.message.approbateur'"></p>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </mat-tab>
                    <mat-tab *ngIf="isMail" [label]="'workflow.notification.destinatairesTitle' | translate">
                        <mat-accordion [displayMode]="'flat'" [multi]="true">
                            <mat-expansion-panel *ngFor="let mail of listeDestinataires; let index = index;" [expanded]="true">
                                <mat-expansion-panel-header *ngIf="listeDestinataires.length > 1">
                                    <ng-container *ngTemplateOutlet="title;context: {count: countSelection(mail.destinataires)}"></ng-container>
                                    <ng-template #title let-count="count">
                                        <mat-panel-title>
                                            {{ ('portee.' + mail.typeObjet | translate) + ' ' + mail.idObjet + ' - ' + mail.titreMail + ' - ' + mail.libelleRole }}
                                            <mat-icon *ngIf="isMailObligatoire && !hasSelection(mail.destinataires)" class="error" [nioTooltip]="'workflow.notification.tips.noDestinataire' | translate">error_outline</mat-icon>
                                            <span *ngIf="hasSelection(mail.destinataires)" class="compteur" [nioTooltip]="'workflow.notification.tips.nbDestinataires.' + (count > 1 ? 'plural' : 'one') | translate:{count: count}">{{ count }}</span>
                                        </mat-panel-title>
                                    </ng-template>
                                </mat-expansion-panel-header>
                                <div class="listview local">
                                    <div class="listview-body">
                                        <div (click)="!mail.isAuto && onDestinataireChange(user,mail)" *ngFor="let user of mail.destinataires" class="listview-item" [ngClass]="{'clickable': !mail.isAuto}">
                                            <div class="lvi-content">
                                                <div class="avatar">
                                                    <mat-icon>{{ user.selected ? 'done' : 'person'}}</mat-icon>
                                                </div>
                                                <div class="lvi-body">
                                                    <div class="title">
                                                        <span>{{ user | user }}</span>
                                                    </div>
                                                    <ul class="lvi-attrs">
                                                        <li><strong [translate]="'workflow.notification.priorite.title'"></strong><span
                                                                [translate]="'workflow.notification.priorite.'+user.prioriteKey"></span></li>
                                                        <li><strong [translate]="'workflow.notification.role'"></strong><span>{{ user.libelleRole }}</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="mail.destinataires.length == 0" [ngClass]="{ 'alert-danger': mail.obligatoire,'alert-warning': !mail.obligatoire }" class="alert">
                                            <h4 [translate]="'workflow.notification.alerte.title.'+(mail.obligatoire ? 'warning' : 'info')" class="alert-heading"></h4>
                                            <p [translate]="'workflow.notification.alerte.message.'+(mail.isAuto ? 'auto' : 'manuel')"></p>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </mat-tab>
                </mat-tab-group>
            </fieldset>
        </div>
        <please-wait *ngIf="isLoading"></please-wait>
    </form>
</div>
<div mat-dialog-actions align="end" *ngIf="!isLoading">
    <button mat-stroked-button class="m-r-10" mat-dialog-close>{{ 'global.actions.annuler' | translate }}</button>
    <button mat-flat-button color="primary" [disabled]="isLoading || !isValid()" (click)="sendMail()">{{ 'global.actions.confirmer' | translate }}</button>
</div>
