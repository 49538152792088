<h1 mat-dialog-title>
    <span>{{ 'facture.ecarts.title' | translate:{idOd: data.facture.od.idOd} }}&#160;{{ data.facture.user | user }}</span>
    <span [mat-dialog-close]="null"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content id="facture-ecarts">
    <please-wait *ngIf="isLoading"></please-wait>
    <ng-container *ngIf="!isLoading" [ngTemplateOutlet]="tplBody" [ngTemplateOutletContext]="{listeLigneRapprochement: listeRapprochementNature}"></ng-container>
</div>
<ng-template #tplBody let-listeLigneRapprochement="listeLigneRapprochement">
    <div *ngIf="!isLoading" class="listview">
        <div class="listview-body">
            <div *ngFor="let ligneRapprochement of castArrayIn(listeLigneRapprochement,LigneRapprochement);" class="listview-item row">
                <div class="col-md-12">
                    <div class="lvi-content" [ngClass]="{'clickable':ligneRapprochement.level != Level.LIGNE_FACTURE}" (click)="toggle(ligneRapprochement);">
                        <div class="avatar">
                            <span *ngIf="ligneRapprochement.level == Level.NATURE" [innerHTML]="TypeNature.icone(ligneRapprochement.nature)"></span>
                            <span *ngIf="ligneRapprochement.level == Level.FACTURE">
                                {{ TypeFact.icone(ligneRapprochement.montantFacture < 0 ? TypeFact.AVOIR : TypeFact.FACTURE, translateService) }}
                            </span>
                            <span *ngIf="ligneRapprochement.level == Level.LIGNE_FACTURE" [innerHTML]="TypeNature.icone(ligneRapprochement.nature)"></span>
                        </div>
                        <div class="lvi-body">
                            <div class="title">
                                <a *ngIf="ligneRapprochement.level == Level.NATURE">
                                    {{ TypeNature.traduction(ligneRapprochement.nature) | translate }}
                                    <span *ngIf="isShowTooltip(ligneRapprochement)" class="material-icons-outlined" [nioTooltip]="tplTooltip"
                                          [nioTooltipContext]="{'ligneRapprochement':ligneRapprochement}">info</span>
                                </a>
                                <a *ngIf="ligneRapprochement.level == Level.FACTURE">{{ ligneRapprochement.reference }}{{ ligneRapprochement.reference2 ? ' (' + ligneRapprochement.reference2 + ')' : ''}}</a>
                                <a *ngIf="ligneRapprochement.level == Level.LIGNE_FACTURE">{{ ligneRapprochement.reference }}</a>
                            </div>
                            <ul class="lvi-attrs">
                                <li *ngIf="ligneRapprochement.level === Level.NATURE"><strong
                                        [translate]="'facture.ecarts.devis'"></strong>{{ ligneRapprochement.montantDevis | montant:data.facture.devise}}</li>
                                <li><strong [translate]="'facture.ecarts.facture'"></strong>{{ ligneRapprochement.montantFacture | montant:data.facture.devise}}</li>
                                <li *ngIf="ligneRapprochement.level === Level.NATURE">
                                    <strong [translate]="'facture.ecarts.ecart'"></strong>
                                    {{ ligneRapprochement.montantEcart | montant:data.facture.devise}}
                                    <span *ngIf="getPourcentEcart(ligneRapprochement)">
                                        {{ " (" + (getPourcentEcart(ligneRapprochement)) + "%)" }}
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div *ngIf="ligneRapprochement.level != Level.LIGNE_FACTURE" class="lvi-actions">
                            <button mat-icon-button>
                                <mat-icon *ngIf="ligneRapprochement.unfolded" color="primary">keyboard_arrow_up</mat-icon>
                                <mat-icon *ngIf="!ligneRapprochement.unfolded" color="primary">keyboard_arrow_down</mat-icon>
                            </button>
                        </div>
                    </div>
                    <!-- Cadre de détail -->
                    <ng-container *ngIf="ligneRapprochement.level != Level.LIGNE_FACTURE && ligneRapprochement.unfolded">
                        <please-wait *ngIf="ligneRapprochement.childs === undefined" [small]="true"></please-wait>
                        <ng-container *ngIf="ligneRapprochement.childs !== undefined"
                                      [ngTemplateOutlet]="tplBody"
                                      [ngTemplateOutletContext]="{listeLigneRapprochement: ligneRapprochement.childs}"></ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #tplTooltip let-ligneRapprochement="ligneRapprochement">
    <form autocomplete="off" class="nio-tooltip-details" style="max-width: 450px;">
        <strong class="justify-content-center" translate>facture.ecarts.tooltip.header</strong><br/>
        <table class="my-1">
            <tr>
                <td class="pr-1"><label translate>facture.ecarts.tooltip.montantFacture</label></td>
                <td class="text-right">{{ ligneRapprochement.montantFacture | montant:data.facture.devise }}</td>
                <td *ngIf="ligneRapprochement.montantDevis != 0 && ligneRapprochement.montantEcart !== null"></td>
            </tr>
            <tr>
                <td class="pr-1"><label translate>facture.ecarts.tooltip.montantDevis</label></td>
                <td class="text-right">{{ ligneRapprochement.montantDevis | montant:data.facture.devise }}</td>
                <td *ngIf="ligneRapprochement.montantDevis != 0 && ligneRapprochement.montantEcart !== null"></td>
            </tr>
            <tr>
                <td class="pr-1"><label translate>facture.ecarts.tooltip.ecart</label></td>
                <td class="text-right">{{ ligneRapprochement.montantEcart | montant:data.facture.devise }}</td>
                <td class="text-right" *ngIf="getPourcentEcart(ligneRapprochement)">
                    {{ " (" + (getPourcentEcart(ligneRapprochement)) + "%)" }}
                </td>
            </tr>
        </table>
        <strong>
            <i class="material-icons-outlined align-middle">info</i> {{ 'facture.ecarts.tooltip.tolerance' | translate }}
            {{ (ligneRapprochement.tolerance.tolerance * 100) | number:'1.2-2' }} %
            <span *ngIf="ligneRapprochement.tolerance.tolerance !== null && ligneRapprochement.tolerance.toleranceMontant !== null">
            {{ 'facture.ecarts.tooltip.ou' | translate }}
            </span>
            <span *ngIf="ligneRapprochement.tolerance.toleranceMontant !== null">
                {{ ligneRapprochement.tolerance.toleranceMontant | montant:data.facture.devise }}
            </span>
        </strong>
    </form>
</ng-template>