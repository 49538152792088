import {HttpClient} from '@angular/common/http';
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {environment} from '../../environments/environment';
import {Result} from '../../domain/common/http/result';
import {TypeAction, TypePortee} from '../../domain/workflow/workflow';
import {MatTabGroup} from "@angular/material/tabs";
import {MapAction} from '../../domain/workflow/mapAction';
import {Approbateur, Destinataire, WFUser} from "../../domain/workflow/evenement";
import {finalize, first} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {NotificationDestinataire} from "../../domain/workflow/notification-destinataire";
import {AbstractObjetWorkflow} from "../../domain/workflow/abstract-objet-workflow";

@Component({
    selector: 'notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
    /** Indicateur de chargement **/
    isLoading: boolean = false;

    /** Indicateur de choix approbateur */
    isChoixApprobateur: boolean;

    /** Indicateur d'émission de mail **/
    isMail: boolean;

    /** Identifiant de l'utilisateur **/
    idUser: number;

    /** Liste des destinataires */
    listeDestinataires: Array<NotificationDestinataire> = null;

    /** Liste des approbateurs **/
    listeApprobateurs: Array<Approbateur> = null;

    /** Approbateur sélectionné **/
    selectedApprobateur: WFUser;

    /** Indicateur de mail obligatoire **/
    isMailObligatoire: boolean = false;

    /** Présélection des destinataires **/
    isPreselectionDestinataire: boolean = false;

    /** Destinataires automatiquement sélectionnés **/
    listeDestinatairesSelectedFromApprobateur: Array<WFUser> = new Array<WFUser>();

    /** Tabs */
    @ViewChild(MatTabGroup)
    tabGroup: MatTabGroup;

    /**
     * Constructeur
     */
    constructor(private http: HttpClient,
                private matDialogRef: MatDialogRef<NotificationComponent,{ approbateur: string,destinatairesEmail: string }>,
                private toastrService: ToastrService,
                private translateService: TranslateService,
                @Inject(MAT_DIALOG_DATA) public data: { idPortee: TypePortee,typeAction: TypeAction,mapAction: MapAction,item: AbstractObjetWorkflow,idObjet: number }
    ) {
        //Initialisation des données
        this.isChoixApprobateur = this.data?.mapAction?.getWFAction(this.data?.typeAction).choixApprobateur;
        this.isMail = this.data?.mapAction?.getWFAction(this.data?.typeAction).mail

        //Binding
        this.sendMail = this.sendMail.bind(this);
    }

    /**
     * Initialisation
     */
    ngOnInit() {
        //Chargement en cours
        this.isLoading = true;

        //Chargement des approbateurs
        this.http.post<Result>(`${environment.baseUrl}/controller/Mail/${this.data.idPortee}/${this.data.idObjet}/getApprobateurs/${this.data.typeAction}`,{
            isChoixApprobateur: this.isChoixApprobateur,
            isMail: this.isMail
        }).pipe(first(),finalize(() => this.isLoading = false )).subscribe({
            next: (result) => {
                const data = result?.data as {
                    isPreselectionDestinataire: boolean,
                    idUser: number,
                    approbateurs: Array<Approbateur>,
                    destinataires: Array<Destinataire>,
                };

                //Définition de l'identifiant de l'utilisateur
                this.idUser = data?.idUser;
                
                //Vérification du choix de l'approbateur
                if (this.isChoixApprobateur) {
                    //Formattage des approbateurs
                    this.listeApprobateurs = this.formatApprobateurs(data?.approbateurs,data?.idUser);
                }

                //Vérification de l'envoi de mail
                if (this.isMail) {
                    //Formatage des destinataires
                    this.listeDestinataires = this.formatDestinataires(data?.destinataires,data?.idUser,data?.isPreselectionDestinataire);
                }

                //Vérification de la sélection obligatoire d'un destinataire
                this.isMailObligatoire = data?.destinataires?.some(d => d.obligatoire);

                //Présélection des destinataires
                this.isPreselectionDestinataire = data?.isPreselectionDestinataire;

            },
            error: () => {
                //Message d'erreur
                this.toastrService.error(this.translateService.instant('global.errors.chargement'));
            }
        });
    }

    /**
     * Formatage des destinataires
     */
    formatDestinataires(destinataires: Array<Destinataire>,idUser: number,isPreselectionDestinataire: boolean): Array<NotificationDestinataire> {
        let listeDestinataires: Array<NotificationDestinataire> = new Array<NotificationDestinataire>();

        //Parcours des destinataires
        destinataires.forEach(destinataire => {
            //Vérification de la présence de mail
            if (destinataire.mail) {
                //Parcours des mails
                destinataire.mail.forEach(mail => {
                    let listeRolesDestinataires: Array<WFUser> = new Array<WFUser>();
                    let isAuto: boolean;
                    
                    //Mail automatique
                    isAuto = !mail.manuel;

                    //Vérification du nombre de destinataires
                    if (mail.users?.length >= 1) {
                        //Parcours des destinataires du mail
                        mail.users.forEach((user) => {
                            //Ajout du libellé en fonction de la priorité
                            if (user.idUser === idUser) {
                                //Sélection du créateur de l'objet par défaut
                                user.selected = true;
                                user.creator = true;

                                //Définition de la priorité
                                user.prioriteKey = 'createur';
                            } else if (user.priorite === 1) {
                                //Définition de la priorité
                                user.prioriteKey = 'titulaire';
                                
                                //Vérification de la nécessité de sélectionner le titulaire
                                if (isPreselectionDestinataire)
                                    //Sélection du titulaire
                                    user.selected = true;
                            } else {
                                //Définition de la priorité
                                user.prioriteKey = 'suppleant';
                            }

                            //Vérification de l'utilisateur et du rôle
                            if (!listeRolesDestinataires.some(value => value.idUser === user.idUser && value.idRole === user.idRole)) {
                                //Ajout du destinataire
                                listeRolesDestinataires.push(user);
                            }
                        });
                    }

                    //Vérification du mode
                    if (isAuto) {
                        //Sélection de tous les destinataires possibles en mode automatique
                        listeRolesDestinataires.forEach(user => user.selected = true);
                    } else {
                        //Tri des destinataires
                        listeRolesDestinataires.sort((a,b) => a.creator ? -1 : b.creator ? 1 : a.priorite == 1 && b.priorite == 1 ? a.nom.localeCompare(b.nom) === 0 ? a.prenom.localeCompare(b.prenom) === 0 ? a.matricule.localeCompare(b.matricule) : a.prenom.localeCompare(b.prenom) : a.nom.localeCompare(b.nom) : a.priorite === 1 ? -1 : 0);
                    }

                    //Ajout du destinataire
                    listeDestinataires.push({
                        isAuto: isAuto,
                        destinataires: listeRolesDestinataires,
                        titreMail: mail.titre,
                        idRole: mail.idRole,
                        libelleRole: mail.libelleRole,
                        principal: destinataire.principal,
                        idObjet: destinataire.idObjet,
                        isMail: destinataire.isMail,
                        typeObjet: destinataire.typeObjet,
                        obligatoire: destinataire.obligatoire
                    } as NotificationDestinataire);
                });
            }

        });

        return listeDestinataires;
    }

    /**
     * Formatage des approbateurs
     */
	formatApprobateurs(approbateurs: Array<Approbateur>,idUser: number): Array<Approbateur> {
        let listeApprobateurs: Array<Approbateur>;
        
        //Définition de la liste des approbateurs
        listeApprobateurs = approbateurs;

        //Vérification des approbateurs
        if (approbateurs) {
            //Parcours des approbateurs
            listeApprobateurs.forEach((approbateur) => {
                //Initialisation de l'objet
                approbateur.approbateurs = new Array<WFUser>();

                //Suppression de l'utilisateur courant de la liste et ajout des utilisateurs dans la liste principale
                approbateur.users.forEach(user => {
                    //Suppression de l'utilisateur courant si l'auto-validation est interdite
                    if (user.idUser !== idUser || user.isAutoValidationAllowed) {
                        //Ajout du libellé de priorité
                        if (user.isDelegue) {
                            //Suppléant
                            user.prioriteKey = 'suppleant';
                            user.idPriorite = 2;
                        } else {
                            //Titulaire
                            user.prioriteKey = 'titulaire';
                            user.idPriorite = 1;
                        }

                        //Vérification de l'utilisateur et du rôle
                        if (!approbateur.approbateurs.some(value => value.idUser === user.idUser && value.idRole === user.idRole && value.isDelegue === user.isDelegue)) {
                            //Ajout de l'approbateur
                            approbateur.approbateurs.push(user);
                        }
                    }
                });

                //Tri des approbateurs
                approbateur.approbateurs.sort((a,b) => !a.isDelegue && !b.isDelegue ? a.nom.localeCompare(b.nom) === 0 ? a.prenom.localeCompare(b.prenom) === 0 ? a.matricule.localeCompare(b.matricule) : a.prenom.localeCompare(b.prenom) : a.nom.localeCompare(b.nom) : !a.isDelegue ? -1 : 0);
            });
        }

        return listeApprobateurs;
    }

    /**
     * Validation de la saisie de données
     */
    isValid(): boolean {
        let isValid: boolean = true;

        //Vérification de la sélection d'au moins un approbateur si le choix approbateur est activé et s'il y a des approbateurs sélectionnables
        if (this.isChoixApprobateur && this.listeApprobateurs && this.listeApprobateurs.length > 0) {
            //Vérification de la sélection d'un approbateur
            isValid = isValid && !!this.selectedApprobateur;
        }

        //Vérification de la sélection d'un destintaire si il existe des destinataires sélectionnables et si les mails sont obligatoires
        if (this.isMailObligatoire && this.listeDestinataires && this.listeDestinataires.length > 0) {
            //Parcours des destinataires
            this.listeDestinataires.forEach(d => {
                //Vérification de la sélection d'un destinataire
                isValid = isValid && d.destinataires?.length > 0 && this.hasSelection(d.destinataires) || false;
            });
        }

        return isValid;
    }

    /**
     * Renvoi True si au moins un destinataire est sélectionné, False sinon.
     *
     * @param destinataires Liste des destinataires sélectionnables
     */
    hasSelection(destinataires: Array<WFUser>): boolean {
        return destinataires.some(dest => dest.selected);
    }

    /**
     * Retourne le nombre de destinataires sélectionnés.
     *
     * @param destinataires Liste des destinataires sélectionnables
     */
    countSelection(destinataires: Array<WFUser>): number {
        return destinataires.filter(dest => dest.selected).length;
    }

    /**
     * Selection automatique de l'approbateur comme destinataire
     */
    onApprobateurChange(approbateur: WFUser,listeApprobateurs: Approbateur) {

        //Sélection de l'approbateur
        this.selectedApprobateur = approbateur;

        //Vérification de l'absence de préselection des destinataires
        if (!this.isPreselectionDestinataire) {
            //Déselection des destinataires que l'on avait activé via l'approbateur
            this.listeDestinatairesSelectedFromApprobateur.forEach(previousDest => {
                //Parcours de la liste des destinataires
                this.listeDestinataires.filter(d => !d.isAuto).forEach(destinataire => {
                    //Parcours des utilisateurs et déselection
                    destinataire.destinataires.filter(user => user.idUser === previousDest.idUser && !user.creator).forEach(user => user.selected = false);
                });
            });

            //Remise à zéro de la liste des destinataires sélectionnés lors de la sélection de l'approbateur
            this.listeDestinatairesSelectedFromApprobateur.splice(0);

            //Sélection des nouveaux destinataires en fonction de l'approbateur
            this.listeDestinataires.filter(destinataire => destinataire.typeObjet === listeApprobateurs.typeObjet && destinataire.idObjet === listeApprobateurs.idObjet && !destinataire.isAuto).forEach(destinataire => {
                //Sélection des utilisateurs correspondants à l'approbateur
                destinataire.destinataires.filter(user => user.idUser === approbateur.idUser).forEach(user => {
                    //Sélection de l'utilisateur
                    user.selected = true;

                    //Ajout à la liste des destinataires automatiquement sélectionnés
                    this.listeDestinatairesSelectedFromApprobateur.push(user);
                });
            });
        }

        //Changement d'onglet
        window.setTimeout(() => {
            this.tabGroup.selectedIndex = 1;
        },250);
    }

    /**
     * Sélection d'un destinataire
     */
    onDestinataireChange(destinataire: WFUser,notificationDestinataire: NotificationDestinataire) {
        if (!notificationDestinataire.isAuto) {
            destinataire.selected = !destinataire.selected;
        }
    }

    /**
     * Emission de la notification pour l'action Workflow
     */
    sendMail() {
        let approbateur: string = null;
        let destinataireInput: string = '';
        let approbateurInput: string = '';

        //Saisie des données dans le formulaire de la page
        if (this.isChoixApprobateur) {
            //Récupération de l'identifiant de l'approbateur
            approbateur = this.selectedApprobateur && String(this.selectedApprobateur.idUser);
            
            //Définition de l'approbateur formatté
            approbateurInput = `${this.selectedApprobateur.idRole};${this.selectedApprobateur.idUser};${this.selectedApprobateur.idPriorite}`;
        }

        //Préparation du mail
        if (this.isMail) {       
            //Parcours des destinataires
            this.listeDestinataires.forEach(destinataire => {
                //Vérification de l'approbateur
                if (approbateur) {
                    //Ajout de l'objet destinataire de l'approbateur au retour
                    destinataireInput += `¶${destinataire.typeObjet};${approbateurInput}`;
                }

                //Ajout des utilisateurs sélectionnés au retour
                destinataire.destinataires.filter(user => user.selected).forEach(user => destinataireInput += `¶${destinataire.typeObjet};${user.idRole};${user.idUser};${user.priorite}`);
            });

            //Formatage de la chaîne de retour
            destinataireInput += destinataireInput.length > 0 && destinataireInput.slice(-1) !== '¶' ? '¶' : '';
        }

        //Fermeture de l'écran
        this.matDialogRef.close({ approbateur: approbateur,destinatairesEmail: destinataireInput });
    }
}
