import {Directive, ElementRef, forwardRef, HostListener, Input} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {FormControl, FormGroupDirective, NG_VALUE_ACCESSOR, NgForm} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {MAT_INPUT_VALUE_ACCESSOR} from '@angular/material/input';

export class InputNumberErrorStateMatcher implements ErrorStateMatcher {
	/**
	 * Vérification de l'état du champ
	 */
	isErrorState(control: FormControl | null,form: FormGroupDirective | NgForm | null): boolean {
		//Vérification de la présence d'erreurs
		return control && control.invalid && (control.dirty || control.touched);
	}
}

/**
 * Gestionnaire d'erreur pour la saisie d'un nombre borné
 */
export class InputRangeErrorStateMatcher implements ErrorStateMatcher {
	/** Valeur courante */
	private value: number | null;

	/**
	 * Constructeur
	 *
	 * @param min Borne mininum
	 * @param max born maximum
	 */
	constructor(private readonly min: number | null,private readonly max: number | null) {
	}

	/**
	 * Vérification de l'état du champ
	 */
	isErrorState(control: FormControl | null,form: FormGroupDirective | NgForm | null): boolean {
		let isError: boolean;

		//Vérification de la présence d'erreurs
		isError = control && control.invalid;

		//S'il n'y a pas encore d'erreur on vérifie la borne
		if (!isError && (this.min !== null || this.max !== null)) {
			//Récupération et parse de la valeur
			this.value = typeof control.value === 'string' ? Number(control.value.replace(/[^,\d.-]/g,'').replace(/[,]/g,'.')) : control.value;

			//Vérification que la valeur est bien un nombre
			if (!isNaN(this.value)) {
				//Vérification borne inférieure et supérieure
				isError = (this.min !== null && this.value < this.min) || (this.max !== null && this.value > this.max)
			} else {
				isError = true;
			}
		}

		return isError;
	}
}

@Directive({
    selector: '[matInput][nNumber]',
    providers: [{
        provide: MAT_INPUT_VALUE_ACCESSOR,
        useExisting: InputNumberDirective
    },{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputNumberDirective),
        multi: true
    },{
		provide: ErrorStateMatcher,
		useClass: InputNumberErrorStateMatcher
	}]
})
export class InputNumberDirective {
	/** Nombre de décimales **/
	@Input() nNumber?: number = 0;

	/** Valeur actuelle **/
	private _value: string | null;

	/**
	 * Constructeur
	 */
	constructor(private elementRef: ElementRef<HTMLInputElement>,private decimalPipe: DecimalPipe) {
		//Ajout de la classe
		elementRef.nativeElement.classList.add('text-right');
	}

	/**
	 * Lecture de la valeur
	 */
	get value(): string | null {
		//Retour de la valeur
		return this._value;
	}

	/**
	 * Définition de la valeur
	 */
	@Input('value')
	set value(value: string | null) {
		//Mise à jour de la valeur
		this._value = value || null;

		//Formattage de la valeur
		this.formatValue(value);
	}

	/**
	 * Formattage de la valeur
	 */
	private formatValue(value: string | null) {
		let formatedValue = '';

		if (value !== null && value !== undefined) {
			const replacedValue = value.toString().replace(/[,]/g,'.');

			if (!isNaN(Number(replacedValue))) {
				//Définition de la valeur
				formatedValue = this.decimalPipe.transform(replacedValue, `1.${this.nNumber || 0}-${this.nNumber || 0}`);
			}
		}

		this.updateElementInput(formatedValue);

		//Déclenchement de la modification
		this.onChange(formatedValue ? Number(formatedValue.replace(/[^,\d.-]/g,'').replace(/[,]/g,'.')) : null);
	}

	/**
	 * Déformattage de la valeur
	 */
	private unFormatValue() {
		let value;

		//Lecture de la valeur saisie
		value = this.elementRef.nativeElement.value;
		
		//Remplacement des caractères non numériques
		this._value = value.replace(/[^,\d.-]/g,'').replace(/[.]/g,',');

		//Vérification de la valeur à afficher
		this.updateElementInput(value ? this._value : '');
	}

	/**
	 * Met à jour l'élément html avec la valeur
	 * */
	private updateElementInput(value) {
		this.elementRef.nativeElement.value = value;
	}

	/**
	 * Interception d'un changement de valeur
	 */
	@HostListener('input',['$event.target.value'])
	onInput(value) {
		//Remplacement des caractères non numériques
		this._value = value.replace(/[^,\d.-]/g,'').replace(/[.]/g,',');

		//Met à jour l'élément avec la nouvelle valeur formatée
		this.updateElementInput(this._value);

		//Déclenchement de la modification
		this.onChange(this._value);
	}

	/**
	 * Interception de la sortie du champ
	 */
	@HostListener('blur')
	onBlur() {
		//Formattage de la valeur
		this.formatValue(this._value);
	}

	/**
	 * Interception d'un focus sur le champ
	 */
	@HostListener('focus')
	onFocus() {
		//Déformattage de la valeur
		this.unFormatValue();
	}

	/**
	 * Ecriture de la valeur
	 */
	writeValue(value: any) {
		//Définition de la valeur
		this._value = value;

		//Formattage de la valeur
		this.formatValue(this._value);
	}

	/**
	 * Enregistrement d'un changement de valeur
	 */
	registerOnChange(fn: (value: any) => void) {
		//Définition de la fonction
		this.onChange = fn;
	}

	/**
	 * Interception d'un appui sur le composant
	 */
	registerOnTouched() {}

	/**
	 * Changement de valeur
	 */
	onChange(value: any): void {}
}