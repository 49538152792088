import {NgModule} from "@angular/core";
import {AdminOutilsComponent} from "./admin-outils.component";
import {GestionBudgetaireComponent} from './gestion-budgetaire/gestion-budgetaire.component';
import {NiveauxRisqueComponent} from './niveaux-risque/niveaux-risque.component';
import {MouvementWfComponent} from './mouvement-wf/mouvement-wf.component';
import {PopupMouvementWfComponent} from './mouvement-wf/popup-mouvement-wf/popup-mouvement-wf.component';
import {PopupDetailPaysRisqueComponent} from './niveaux-risque/popup-detail-pays-risque/popup-detail-pays-risque.component';
import {CoreComponentsModule} from "@share/component/core-components.module";
import {CustomInputModule} from "@share/component/custom-input/custom-input.module";

/**
 * Module de gestion des outils
 */
@NgModule({
	declarations: [
		AdminOutilsComponent,
		GestionBudgetaireComponent,
		NiveauxRisqueComponent,
		MouvementWfComponent,
		PopupMouvementWfComponent,
		PopupDetailPaysRisqueComponent
	],
	exports: [
		AdminOutilsComponent
	],
	imports: [
		CoreComponentsModule,
		CustomInputModule
	]
})
export class AdminOutilsModule {
}