import {ChangeDetectorRef,Component,OnDestroy,OnInit} from '@angular/core';
import {StatutWorkflowService} from "@services/admin/statut-workflow/statut-workflow.service";
import {IStatutApplication,KeyStatutApplication,KeyStatutWorkflow} from "@domain/admin/statut-application/statut-application";
import {Subscription} from "rxjs";
import {AppStatut,MigrationsService} from "@services/admin/maintenance/migrations.service";
import {MatDialog} from "@angular/material/dialog";
import {MigrationBddPopupComponent} from "@components/admin/maintenance/migrations/migration-bdd-popup.component";
import {ListeMigrationPopupComponent} from "@components/admin/maintenance/migrations/liste-migration-popup.component";
import {Router} from "@angular/router";

/**
 * Composant de gestion du statut de l'application
 */
@Component({
    selector: 'statut-application',
    templateUrl: './statut-application.component.html',
    styleUrls: ['./statut-application.component.scss']
})
export class StatutApplicationComponent implements OnInit,OnDestroy {
    /** Clés de statut de l'application */
    protected readonly KeyStatutApplication = KeyStatutApplication;

    /** Statut de l'application */
    appStatut: AppStatut;

    /** Statut du Workflow */
    synchroWfStatut: IStatutApplication;

    /** Souscriptions */
    souscriptions: Subscription[] = [];

    /** Progression du Workflow */
    progressionWf: number = 0.00;

    /** Mode de la progress bar du workflow */
    modeProgressBarWf: "buffer" | "indeterminate" = "buffer";

    /** Constante pour la durée de temporisation avant synchro workflow */
    static readonly TOTAL_TEMPO: number = 300000;

    /**
     * Constructeur
     */
    constructor(
        private statutWorkflowService: StatutWorkflowService,
        private migrationsService: MigrationsService,
        protected matDialog: MatDialog,
        private ref: ChangeDetectorRef,
        private router: Router) {}

    /**
     * Initialisation du composant
     */
    ngOnInit(): void  {
        //Rafraichissement du statut de l'application
        this.migrationsService.checkAppliStatut();

        //Rafraichissement du statut Workflow
        this.statutWorkflowService.loadSynchroWFStatut();

        //Abonnement à la mise à jour du statut du Workflow
        this.souscriptions.push(this.statutWorkflowService.synchroWFStatut$.subscribe(statut => {
            //Mise à jour du statut
            this.synchroWfStatut = statut;

            //Si retour en none
            if (statut?.statut == KeyStatutWorkflow.HISTO_WF_NONE) {
                //Réinitialisation du statut de l'application
                this.appStatut = {
                    etape: undefined,
                    nbDone: 0,
                    nbTotal: 0,
                    progression: 0
                } as AppStatut;
            }

            //Gestion de la tempo si besoin
            this.handleTempoWorkflow();

            //Détection forcée des changements du composant
            this.ref.detectChanges();
        }));

        //Abonnement à la mise à jour du statut de l'application
        this.souscriptions.push(this.migrationsService.appStatut$.subscribe(statut => {
            //Mise à jour du statut
            this.appStatut = statut;

            //Si retour en ligne
            if (statut?.applicationStatut?.statut == KeyStatutApplication.EN_LIGNE) {
                //Réinitialisation du statut de l'application
                this.appStatut = {
                    etape: undefined,
                    nbDone: 0,
                    nbTotal: 0,
                    progression: 0
                } as AppStatut;
            }

            //Si l'application est en erreur
            if (statut?.applicationStatut?.statut == KeyStatutApplication.LOCKED) {
                //Si l'erreur concerne la BDD
                if (statut?.isBddUpgradeError) {
                    //Application du message secondaire approprié
                    this.appStatut.applicationStatut.messageSecondaire = 'admin.statut.application.locked3';
                } else if (statut?.isMigrationError) {
                    //Sinon si elle concerne les tâches de migration, alors message secondaire correspondant
                    this.appStatut.applicationStatut.messageSecondaire = 'admin.statut.application.locked2';
                }
            }

            //Détection forcée des changements du composant
            this.ref.detectChanges();
        }));
    }

    /**
     * Navigation vers la page de maintenance des migrations ou popup pour la BDD
     */
    goToMaintenance(): void {
        //Si l'application est en cours de migration de la BDD
        if (this.appStatut?.applicationStatut?.statut == KeyStatutApplication.PRE_MIGRATION || this.appStatut?.applicationStatut?.statut == KeyStatutApplication.MIGRATION) {
            //Ouverture de la popup de migration BDD
            this.matDialog.open(MigrationBddPopupComponent, {
                minWidth: 600,
                maxWidth: 600,
                minHeight: 200
            });
        } else if (this.appStatut?.applicationStatut?.statut == KeyStatutApplication.LOCKED) {
            //Sinon si l'application est bloquée, on vérifie si c'est une erreur de BDD
            if (this.appStatut.isBddUpgradeError) {
                //Navigation vers l'historique
                this.router.navigate(['Admin/Maintenance/Migrations/Historique']);
            } else if (this.appStatut.isMigrationError) {
                //Sinon si c'est une erreur de migration, navigation vers la liste de tâches avec forçage du filtre par défaut
                this.router.navigate(['Admin/Maintenance/Migrations/Maintenance'], { queryParams: { forceAExecuter: true } });
            }
        } else {
            //Ouverture de la popup avec la liste des migrations
            this.matDialog.open(ListeMigrationPopupComponent, {
                minWidth: 1200,
                maxWidth: 1200,
                minHeight: 100,
                maxHeight: 800
            });
        }
    }

    /**
     * Clic sur le bouton d'annulation de la tempo Workflow
     */
    handleSynchroWFClick(): void {
        //Forçage de la progression à 100%
        this.progressionWf = 100;

        //Emission du signal pour shunter la temporisation
        this.statutWorkflowService.handleSynchroWFClick();
    }

    /**
     * Gestion de la temporisation pour la synchronisation du Workflow
     */
    handleTempoWorkflow(): void {
        //Si le statut est de type COUNTDOWN
        if (this.synchroWfStatut?.statut == KeyStatutWorkflow.HISTO_WF_COUNTDOWN) {
            //Adaptation du mode de la progress bar
            this.modeProgressBarWf = "buffer";

            //Calcul du pourcentage d'avancement de la tempo
            this.progressionWf = (StatutApplicationComponent.TOTAL_TEMPO - this.synchroWfStatut['tempo']) * 100 / StatutApplicationComponent.TOTAL_TEMPO;
        } else {
            //Sinon adaptation du mode de la progress bar
            this.modeProgressBarWf = "indeterminate";
        }
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy(): void {
        //Désabonnements
        this.souscriptions.forEach(souscription => souscription.unsubscribe());
    }
}
