import {RefSbt} from "@domain/travel/ref-sbt";

/**
 * Types d'aiguillage
 */
export enum TypeAiguillage {
    ONLINE = 0,
    OFFLINE = 1,
    INTERNE = 2
}

/**
 * Version littérale des types d'aiguillage
 */
export enum TypeAiguillageLiteral {
    ONLINE = 'ONLINE',
    OFFLINE = 'OFFLINE',
    INTERNE = 'INTERNE'
}

/**
 * Extension des types d'aiguillage
 */
export namespace TypeAiguillageLiteral {
    /**
     * Méthode permettant de s'assurer que deux aiguillages sont égaux
     *
     * @param aiguillage        Type d'aiguillage numérique
     * @param aiguillageLiteral Type d'aiguillage littéral
     */
    export function equals(aiguillage: TypeAiguillage, aiguillageLiteral: TypeAiguillageLiteral): boolean {
        //Si les deux aiguillages sont null, ils sont égaux
        if (aiguillage === null && aiguillageLiteral === null) {
            return true;
        }

        //On compare cas par cas
        return aiguillage === TypeAiguillage.ONLINE && aiguillageLiteral === TypeAiguillageLiteral.ONLINE
            || aiguillage === TypeAiguillage.OFFLINE && aiguillageLiteral === TypeAiguillageLiteral.OFFLINE
            || aiguillage === TypeAiguillage.INTERNE && aiguillageLiteral === TypeAiguillageLiteral.INTERNE;
    }

    /**
     * Récupère un TypeAiguillage à partir de son équivalent literal
     *
     * @param aiguillageLiteral Aiguillage recherché (INTERNE par défaut)
     */
    export function getTypeAiguillage(aiguillageLiteral: TypeAiguillageLiteral): TypeAiguillage {
        let aiguillage: TypeAiguillage;

        //On récupère l'aiguillage
        switch (aiguillageLiteral) {
            case TypeAiguillageLiteral.ONLINE:
                aiguillage = TypeAiguillage.ONLINE;
                break;
            case TypeAiguillageLiteral.OFFLINE:
                aiguillage = TypeAiguillage.OFFLINE;
                break;
            case TypeAiguillageLiteral.INTERNE:
            default:
                aiguillage = TypeAiguillage.INTERNE;
                break;
        }

        return aiguillage;
    }
}

/** Énumération des types de nature */
export enum TypeNature {
    AVION_TRAIN = 0,
    HEBERGEMENT = 1,
    VOITURE_DE_LOCATION = 2,
    VOITURE_DE_SOCIETE = 3,
    VOITURE_PERSONNELLE = 4,
    TAXI = 5,
    AUTRE = 6,
    AVION = 7,
    TRAIN = 8,
    DOCUMENT = 9
}

/**
 * On crée un namespace avec le même nom que l'énumération pour lui implémenter des fonctionnalités supplémentaires
 */
export namespace TypeNature {
    /**
     * Méthode qui revoie le libelle de traduction correspondant au TypeNature en paramètre
     */
    export function traduction(typeNature: TypeNature): string {
        switch (typeNature) {
            case this.AVION_TRAIN:
                return 'od.voyage.travel.avionTrain';
            case this.HEBERGEMENT:
                return 'od.voyage.travel.hebergement';
            case this.VOITURE_DE_LOCATION:
                return 'od.voyage.travel.voitureLoc';
            case this.VOITURE_DE_SOCIETE:
                return 'od.voyage.travel.voitureSoc';
            case this.VOITURE_PERSONNELLE:
                return 'od.voyage.travel.voiturePerso';
            case this.TAXI:
                return 'od.voyage.travel.taxi';
            case this.AUTRE:
                return 'od.voyage.travel.autre';
            case this.AVION:
                return 'od.voyage.travel.avion';
            case this.TRAIN:
                return 'od.voyage.travel.train';
            case this.DOCUMENT:
                return 'od.voyage.travel.document';
        }
    }
}

/**
 * Représente le type de configuration SBT
 */
export enum TypeConfig {
    OFFLINE,
    ONLINE
}

/**
 * Extension des types de configuration SBT
 */
export namespace TypeConfig {
    export function traduction(typeAiguillage: TypeConfig): string {
        switch (typeAiguillage) {
            case TypeConfig.ONLINE:
                return 'travel.typeAiguillage.online';
            case TypeConfig.OFFLINE:
                return 'travel.typeAiguillage.offline';
        }
    }
}

/**
 * Liste des SBT disponibles
 */
export const listeSBT: Array<RefSbt> = [
    {
        num_sbt: 1,
        libelle: 'AMI'
    }, {
        num_sbt: 2,
        libelle: 'KDS'
    }, {
        num_sbt: 3,
        libelle: 'Egencia'
    }, {
        num_sbt: 4,
        libelle: 'Traveldoo'
    }, {
        num_sbt: 5,
        libelle: 'GetThere'
    }, {
        num_sbt: 6,
        libelle: 'NOTS'
    }, {
        num_sbt: 7,
        libelle: 'GTMS'
    }, {
        num_sbt: 8,
        libelle: 'Clipper'
    }, {
        num_sbt: 9,
        libelle: 'ELT'
    }, {
        num_sbt: 10,
        libelle: 'EgenciaOff'
    }, {
        num_sbt: 11,
        libelle: 'HRS'
    }, {
        num_sbt: 12,
        libelle: 'Cytric'
    }, {
        num_sbt: 13,
        libelle: 'Maya'
    }, {
        num_sbt: 14,
        libelle: 'PortailSncf'
    }, {
        num_sbt: 15,
        libelle: 'AmadeusOffline'
    }, {
        num_sbt: 16,
        libelle: 'Cds'
    }, {
        num_sbt: 17,
        libelle: 'Carbookr'
    }, {
        num_sbt: 18,
        libelle: 'KdsNeo'
    }, {
        num_sbt: 19,
        libelle: 'TestOnline'
    }, {
        num_sbt: 20,
        libelle: 'Egencia2'
    }
];
