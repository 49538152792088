<div class="lvi-content">
    <div class="avatar">
        <i class="nio icon-profil"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a (click)="navigateTo()">{{ data.nom }} {{ data.prenom }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.internes.identifiant'"></strong>
                <span>{{ data.nomAcces }}</span>
            </li>
            <li *ngIf="data.matricule">
                <strong [translate]="'admin.entreprise.utilisateurs.internes.matricule'"></strong>
                <span>{{ data.matricule }}</span>
            </li>
            <li *ngIf="data.orga">
                <strong [translate]="'admin.entreprise.utilisateurs.internes.serviceAffectation'"></strong>
                <span>{{ data.orga.numService }} - {{ data.orga.libelle }}</span>
            </li>
            <li *ngIf="data.fonctions">
                <strong [translate]="'admin.entreprise.utilisateurs.internes.profils'"></strong>
                <span>{{ getProfils() }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.internes.actif'"></strong>
                <span [translate]="data.actif ? 'confirmation.oui' : 'confirmation.non'"></span>
            </li>
        </ul>
    </div>
</div>