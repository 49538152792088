import {Component,Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {SettingsGlobalState} from '@domain/settings/settings';
import {TypeEntite} from '@domain/typeentite/typeEntite';
import {Analytique} from '../analytique';

/**
 * Popin principal qui permet d'afficher la saisie ou la sélection de l'analytique
 */
@Component({
    host: {'data-test-id': 'analytique-popin'},
    selector: 'analytique-popin',
    templateUrl: './analytique-popin.component.html',
    styles: [
        ':host ::ng-deep .mat-tab-body-content { padding-top: 10px; padding-bottom: 10px; }'
    ]
})
export class AnalytiquePopinComponent {
    
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            idMIB: number,
            listeAnalytique: Array<Analytique>,
            analytique: Analytique,
            idxAnalytique: number,
            typeEntite: TypeEntite,
            settings: SettingsGlobalState,
            precision: number,
            isShowSaisie: boolean,
            isShowSelect: boolean,
            isShowVentilation: boolean,
            isShowTab: boolean,
            idCollab: number
        }) { }
}
