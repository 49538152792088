import {User} from "../user/user";

/**
 * Classe représentant les autorisations pour les véhicules
 */
export class Autorisation {
    id?: number;
    idVehiculeAutorisation?: number;
    idPa: number;
    dateDebut?: Date;
    dateFin?: Date;
    remarque?: string;
    dateApplication?: Date;
    nbPJ?: number;
    user?: User;

    constructor() {
        this.id = 0;
        this.idVehiculeAutorisation = 0;
    }
}

/**
 * Niveau d'autorisation d'un véhicule dans la NDF ou l'OD
 */
export enum NiveauAutorisation {
    NON_CONTROLE,
    NON_BLOQUANT,
    BLOQUANT
}
